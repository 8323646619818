import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "../Css/Dashboard.css";
import "../Css/Bucket30days.css";
import "../Css/SettingPage.css";
import "../Css/HelpSupport.css";
import SideBar from "./SideBar";
import CircleCheck from "../../assets/images/circle_check.svg";
import NotificationsImg from "../../assets/images/notification.svg";
import SideBarControl from "../../assets/images/rightLeftSIdebar.png";
import UserImgPrf from "../../assets/images/userimg45.svg";
import adm_img from "../../assets/images/adm_img.png";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import Success from "../../assets/images/success_gif.gif";
import Technotask from "../../assets/images/TechnotaskLogo12.png";
import Logout from "../../assets/images/giflogout.gif";
import CenterNavbar from "./CenterNavbar";
import { Helmet } from "react-helmet";

import {
  server_post_data,
  server_get_data_master,
  URL_FOR_TICKETS,
  URL_FOR_TICKET_CONVERSATION,
} from "../../KeyConnection/KeysService"; // Adjust the path as needed

function ReplyToTicket() {
  const { ticketId, ticketStatus } = useParams();
  const [isLoading, setLoading] = useState(false);
  const [overviewShow, setOverviewShow] = useState(true); // Default to 'true'
  const [debtorProfile, setdebtorProfile] = useState(false);

  const [email, setEmail] = useState("");
  const [ticketType, setTicketType] = useState("");
  const [priority, setPriority] = useState("High");
  const [ticketBody, setTicketBody] = useState("");

  const [ticketConversation, setTicketConversation] = useState([]);

  useEffect(() => {
    getTicketConversation(URL_FOR_TICKET_CONVERSATION + ticketId);
  }, []);

  const getTicketConversation = async (url_for) => {
    setLoading(true);
    try {
      const response = await server_get_data_master(url_for);
      const data = await response.json();
      if (data.status === "error") {
        alert("Error fetching data");
      } else {
        let ticketsArr = [];
        console.log(data.data);
        for (let i = 0; i < data.data.length; i++) {
          let ticket = data.data[i];

          // convert time into required format
          const timestampString = ticket.created_at;
          const date = new Date(timestampString);
          // Date formatting options
          const dateFormatOptions = {
            year: "numeric",
            month: "long",
            day: "numeric",
          };

          // Time formatting options
          const timeFormatOptions = {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
            timeZone: "IST", // Adjust this based on your actual timezone
          };

          // Format date and time separately
          const formattedDate = date.toLocaleDateString(
            "en-US",
            dateFormatOptions
          );

          const formattedTime = new Intl.DateTimeFormat(
            "en-US",
            timeFormatOptions
          ).format(date);

          // creating formated ticket object
          const obj = {
            uuid_id: ticket.uuid_id,
            ticketNo: ticket.ticket_id,
            ticketDate: formattedDate,
            ticketTime: formattedTime,
            ticketBody: ticket.ticket_body,
          };
          ticketsArr.push(obj);
        }
        console.log(ticketsArr);
        setTicketConversation(ticketsArr);
      }
    } catch (error) {
      console.error(error);
      // setshowLoaderWebsite(false);
    }
    setLoading(false);
  };

  const addTicketToConversation = async (url_for, uuid_id) => {
    setLoading(true);
    try {
      const body_data = JSON.stringify({
        uuid_id: uuid_id,
        priority_status: priority,
        ticket_body: ticketBody,
      });
      const response = await server_post_data(url_for, body_data);
      const data = await response.json();
      console.log(data);

      await getTicketConversation(url_for + uuid_id);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  // Function to handle form submission
  const handleFormSubmit = async () => {
    // Perform actions with form data, e.g., send ticket
    await addTicketToConversation(URL_FOR_TICKET_CONVERSATION, ticketId);
    console.log("Form submitted", { email, ticketType, priority, ticketBody });
    setShowModal(true);
    setEmail("");
    setTicketType("");
    setPriority("High");
    setTicketBody("");
  };

  const [showModal, setShowModal] = useState(false);
  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className="dashboard">
      <Helmet>
        <title>Farida User - Reply to Ticket</title>
        <meta
          name="description"
          content="AI and Global Reach: The Key to Scalable Solutions in Customer
          Experience"
        />
      </Helmet>
      <div className="dashboard_container">
        <div className={isLoading ? "loader-overlay show" : "loader-overlay"}>
          <div className="loader23"></div>
        </div>
        <div className="dashboard_wrapper">
          <SideBar
            overviewShow={overviewShow}
            setOverviewShow={setOverviewShow}
            debtorProfile={debtorProfile}
            setdebtorProfile={setdebtorProfile}
          />
          <div id="content">
            <CenterNavbar />
            <div className="Suppotr_container">
              <div className="Suppotr_wrapper">
                <div className="newticket-container">
                  {ticketConversation.map((ticket, idx) => {
                    return (
                      <div className="ticketReplyDiv" key={ticket.uuid_id}>
                        <div className="ticketsListItemHead">
                          <div className="ticketsListItemHeadLeft">
                            {/* <div className="NewTicket"></div> */}
                            <p>{ticket.ticketNo}</p>
                          </div>
                          <div className="ticketsListItemHeadRight">
                            <div className="ticketTime">
                              <p>
                                Posted on {ticket.ticketDate} at{" "}
                                {ticket.ticketTime}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="ticketDetails">
                          <div className="ticketDetailsContainer">
                            {idx === 0 && <h6>{ticket.ticketBody}</h6>}
                            {idx > 0 && <p>{ticket.ticketBody}</p>}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  {ticketStatus !== "ResolvedTicket" && (
                    <>
                      <div className="newticket_head newticket_head2">
                        <h5>Reply To Ticket</h5>
                      </div>
                      <div className="newTicketform">
                        <div className="row m-0">
                          {/* <div className="col-md-4">
                        <div className="form-row">
                          <label>Customer Email</label>
                          <br />
                          <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Type Email"
                          />
                        </div>
                      </div> */}

                          {/* <div className="col-md-4">
                        <div className="form-row">
                          <label>Request Ticket Type</label>
                          <br />
                          <select
                            value={ticketType}
                            onChange={(e) => setTicketType(e.target.value)}
                          >
                            <option value=" ">Choose Type</option>
                            <option value="Bug">Bug</option>
                            <option value="Feature Request">
                              Feature Request
                            </option>
                          </select>
                        </div>
                      </div> */}

                          {/* <div className="col-md-4">
                        <div className="form-row">
                          <label>Status</label>
                          <br />
                          <select
                            value={priority}
                            onChange={(e) => setPriority(e.target.value)}
                          >
                            <option value="High">High</option>
                            <option value="Medium">Medium</option>
                            <option value="Low">Low</option>
                          </select>
                        </div>
                      </div> */}
                        </div>
                        <div className="form-row" style={{ padding: "0 12px" }}>
                          <label>Ticket Body</label>
                          <br />
                          <textarea
                            rows="7"
                            value={ticketBody}
                            onChange={(e) => setTicketBody(e.target.value)}
                          />
                        </div>
                      </div>
                      <div
                        className="newticketSubmitBtn"
                        style={{ padding: "0 12px" }}
                      >
                        <button onClick={handleFormSubmit}>Reply</button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="brandingContainer">
              <div>
                <h5>Powered by</h5>
                <img src={Technotask} alt="Logo" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        id="myModal"
        show={showModal}
        onHide={closeModal}
        centered
        backdrop="static"
      >
        <Modal.Body className="modal_body">
          <div className="success_img d-flex justify-content-center">
            <img src={Success} alt="icon" />
          </div>
          <p>
            Your reply has been send successfully. Click on the button below to
            see your tickets.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Link to="/burjeel_help_support">
            <div className="btn go_to_login">View Tickets</div>
          </Link>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ReplyToTicket;
