import React, { useState, useEffect, useRef, useCallback } from "react";
import "../Css/NafiyaScreen.css";
import moreThan from "../assets/images/moreThan.svg";
import MicIcon from "../assets/images/mic.gif";
import Micload from "../assets/images/dots_mic.gif";
import speakerGIf from "../assets/images/audioWave.gif";
import { uid } from "uid";
import {
  URL_FOR_LINK,
  THANKYOU_LINK,
  THANKYOU_MSG,
  keywords_confirm,
  keyword_agree,
  keywords_hello,
  server_post_data,
} from "../KeyConnection/KeysService"; // Adjust the path as needed
import {
  validateWebsite,
  changelanguage,
} from "../CommonJquery/CommonJquery.js";
import { Unity, useUnityContext } from "react-unity-webgl";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import LoadrerTechnoTask from "./LoaderAnimation";
import { Link } from "react-router-dom";

let language_for = "en-XA"; //english
let target_language = "en-XA"; //english
let language_for_final = "en-XA"; //english
let language_no = 0;
let old_text = "";
// Variables for server communication and flags
let start_server = true;
let start_server_for_curl = true;
let start_server_for_curl_done = false;
let pop_up_timing = 0;
let uid_for_person = "";
let back_flag_backend = "";
let front_website_word = "";

let curl_data_get = null;
let IsConfirm = false;

let flag_for_start = "1";
let play_mic = "0";
///
let recognition = null;

//timer flag
let isTimerRunning = false;
let start_audio = true;
let audio_off_final = false;

//Question for first and second
let Question_First = "";
let Question_Second = "";
let Question_Temp = "";

//Answer for first and second
let Answer_First = "";
let Answer_Second = "";
let Answer_Temp = "";
let Answer_final = "";

function NafiyaScreen() {
  const audioRef = useRef(null);
  // State variables to manage the visibility of popups
  const [showPopups, setShowPopups] = useState({
    popup1: false,
    popup2: false,
    popup3: false,
  });
  // State variable to manage the visibility of the main popup container
  const [showPopupsDiv, setShowPopupsDiv] = useState(true);
  // State variable to manage the visibility of the main popup container
  const [languageBtn, setLanguageBtn] = useState(true);

  // State variable to manage the visibility of the main popup container
  const [websitename, setWebsiteName] = useState("");

  // State variable to manage the visibility of the domain confirmation popup
  const [domainPopUp, setDomainPopUp] = useState(false);
  const [IsAgree, setIsAgree] = useState(false);
  const [IsDisAgree, setIsDisAgree] = useState(false);
  // State variable to manage the visibility of the Loading
  const [showLoader, setshowLoader] = useState(false);
  const [showSpeaker, setshowSpeaker] = useState(false);
  const [showMic, setshowMic] = useState(false);

  const [timer, setTimer] = useState(30);

  const { finalTranscript, resetTranscript } = useSpeechRecognition();

  const { unityProvider, sendMessage, addEventListener } = useUnityContext({
    loaderUrl: "/unity_files/build.loader.js",
    dataUrl: "/unity_files/build.data",
    frameworkUrl: "/unity_files/build.framework.js",
    codeUrl: "/unity_files/build.wasm",
    webglContextAttributes: {
      preserveDrawingBuffer: true,
    },
  });

  useEffect(() => {
    let interval;
    if (isTimerRunning) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
        console.log(timer);
      }, 1000);
    } else {
      clearInterval(interval);
    }
    //unity function
    addEventListener("GameOver", handleEndAudiounity);

    // Check if the timer has reached 0
    if (timer < 2 && isTimerRunning) {
      handleTimerComplete();
    }
    return () => {
      clearInterval(interval);
    };
  }, [
    timer,
    isTimerRunning,
    IsAgree,
    IsDisAgree,
    showLoader,
    showSpeaker,
    showMic,
    domainPopUp,
    recognition,
  ]);

  const start_with_language = (language_word, no_langaus, ttsVoice_no) => {
    language_no = no_langaus;
    if (language_word === "") {
      setShowPopupsDiv(true);
      setDomainPopUp(false);
      setShowPopups({
        popup1: false,
        popup2: false,
        popup3: false,
      });
      setLanguageBtn(true);
      isTimerRunning = false;
    } else {
      language_for = language_word;
      target_language = language_word;
      first_time_function();
    }
  };

  const first_time_function = () => {
    uid_for_person = uid(25);
    setTimeout(() => {
      setShowPopupsDiv(true);
      //loader,mic,specker
      show_loader_mic_speaker(true, false, false);
      setShowPopups((prevState) => ({
        ...prevState,
        popup1: true,
      }));
      ConfirmDomain(false);
    }, 2000);
  };
  // Function to start the timer
  const startTimer = () => {
    isTimerRunning = true;
    setTimer(30); // Reset the timer to 30 seconds when starting
  };

  // Function to perform an action when the timer completes
  const handleTimerComplete = () => {
    isTimerRunning = false;
    flag_for_start = "50";
    // Do something when the timer completes
    console.log("stop complete");
    start_audio = true;
    endAudio(THANKYOU_LINK, THANKYOU_MSG, true);
  };

  const webkitSpeechRecognition = () => {
    isTimerRunning = true;
    SpeechRecognition.startListening({
      continuous: true,
      language: target_language,
    });
  };

  const stopwebkitSpeechRecognition = () => {
    isTimerRunning = false;
    SpeechRecognition.stopListening();
    resetTranscript();
    clearTimeout(null); // Clear the timeout if recognition is manually stopped
  };

  /// Function to handle user's confirmation of the website domain
  const ConfirmDomain = (flag_true) => {
    let laguage_word = "";
    let website_vaild = true;
    if (flag_true) {
      front_website_word = websitename;

      laguage_word = keyword_agree[language_no];
      website_vaild = validateWebsite(front_website_word);
    } else {
      front_website_word = "";
      laguage_word = keywords_hello[language_no];
    }
    if (website_vaild) {
      startSpeechRecognition(laguage_word);
    }
  };

  // Function to start speech recognition and handle user input
  const startSpeechRecognition = (word) => {
    if (word !== "") {
      let word_correct = false;
      let website_vaild = true;
      if (flag_for_start === "1") {
        // Handle specific words for the initial state
        setLanguageBtn(false);
        const lowercaseWord = word.toLowerCase();
        word_correct = keywords_hello.some((keyword) =>
          lowercaseWord.includes(keyword)
        );
      } else if (
        flag_for_start === "2" ||
        flag_for_start === "6" ||
        flag_for_start === "14" ||
        flag_for_start === "15"
      ) {
        // Handle other cases where word_correct is set to true based on conditions
        if (word !== "" && old_text !== word) {
          word_correct = true;
        }
      } else if (flag_for_start === "10") {
        // Handle another case

        const lowercaseWord = word.toLowerCase();
        word_correct = keywords_confirm.some((keyword) =>
          lowercaseWord.includes(keyword)
        );
        website_vaild = validateWebsite(front_website_word);
      }

      if (word_correct && website_vaild) {
        old_text = word;
        conversationalTalk(word, flag_for_start);
      }
    }
  };

  //when audio video middleplay play middle script data
  const middle_audio_video_link = (
    currentAudioTime,
    audioDuration,
    finish_loop
  ) => {
    // Calculate the delay to execute the code block 3 seconds before the audio ends
    let delay = Number(pop_up_timing);
    if (delay === 0) {
      delay = Math.max(0, audioDuration - 7);
    }
    if ((currentAudioTime > delay || delay < 1) && finish_loop) {
      finish_loop = false;
      if (flag_for_start === "2") {
        setShowPopups({
          popup1: false,
          popup2: true,
          popup3: true,
        });
      } else if (
        flag_for_start === "3" ||
        flag_for_start === "4" ||
        flag_for_start === "6"
      ) {
        setShowPopups({
          popup1: false,
          popup2: false,
          popup3: false,
        });
        setShowPopupsDiv(false);
      } else if (flag_for_start === "10") {
        setShowPopupsDiv(true);
        setDomainPopUp(true);
      } else if (flag_for_start === "14") {
        setDomainPopUp(false);
      }
    }
  };

  //when audio video end play end script data
  const end_audio_video_link = (end_audio) => {
    // When audio ends, set someone_speaking to true and enable speech recognition
    start_server = true;
    if (end_audio) {
      //window.location.reload();
    } else {
      let show_speech = false;
      if (play_mic === "1") {
        show_speech = true;
      }

      if (flag_for_start === "14" && start_server_for_curl) {
        start_server_for_curl_done = true;
        server_get_data("from Audio", curl_data_get);
      } else if (flag_for_start === "14") {
        //loader,mic,specker
        show_loader_mic_speaker(true, false, false);
      } else {
        console.log("shubham jain");
        //loader,mic,specker
        show_loader_mic_speaker(false, show_speech, false);
      }
    }
  };

  // Function to handle user input and start server communication
  const question_histry = (userMessage_send) => {
    let send_data = "0~@~0";
    if (Question_First === "") {
      Question_First = userMessage_send;
    } else if (Question_Second === "") {
      Question_Second = userMessage_send;
    } else {
      Question_Temp = Question_Second;
      Question_First = Question_Temp;
      Question_Second = userMessage_send;
    }
    send_data = Question_First + "~@~" + Question_Second;
    return send_data;
  };

  // Function to handle user input and start server communication
  const answer_histry = (userMessage_send) => {
    let send_data = "0~@~0";
    if (Answer_First === "") {
      Answer_First = userMessage_send;
    } else if (Answer_Second === "") {
      Answer_Second = userMessage_send;
    } else {
      Answer_Temp = Answer_Second;
      Answer_First = Answer_Temp;
      Answer_Second = userMessage_send;
    }
    send_data = Answer_First + "~@~" + Answer_Second;
    return send_data;
  };

  // Function to handle user input and start server communication
  const conversationalTalk = (userMessage, flag) => {
    stopwebkitSpeechRecognition();

    if (start_server) {
      //loader,mic,specker
      show_loader_mic_speaker(true, false, false);
      start_server = false;

      let question_bank = question_histry(userMessage);
      let splitQuestions = question_bank.split("~@~");

      // Send a request to the server with user input
      let lauage_word = changelanguage(language_for);
      let lauage_target = changelanguage(target_language);
      const body_data = JSON.stringify({
        content_txt: userMessage,
        language_used: lauage_word,
        content_language: lauage_target,
        uid_id: uid_for_person,
        front_website: front_website_word,
        question_1: splitQuestions[0],
        question_2: splitQuestions[1],
        answer_1: Answer_final[0],
        answer_2: Answer_final[1],
        front_flag: flag,
      });

      console.log(body_data);
      server_post_data(URL_FOR_LINK, body_data)
        .then((response) => response.json())
        .then((data) => {
          //loader,mic,specker
          show_loader_mic_speaker(false, false, true);
          server_get_data("normal", data);
        })
        .catch((error) => {
          console.error("Error:", error);
          //loader,mic,specker
          show_loader_mic_speaker(false, false, true);
          webkitSpeechRecognition();
          start_server = true;
        });
    }
  };
  // when user curl is done or not
  const send_active_curl = async (userMessage, flag) => {
    if (start_server_for_curl) {
      start_server_for_curl = false;
      let question_bank = question_histry(userMessage);
      let splitQuestions = question_bank.split("~@~");
      // Send a request to the server with user input
      let lauage_word = changelanguage(language_for);
      let lauage_target = changelanguage(target_language);
      const body_data = JSON.stringify({
        content_txt: userMessage,
        language_used: lauage_word,
        content_language: lauage_target,
        uid_id: uid_for_person,
        front_website: userMessage,
        question_1: splitQuestions[0],
        question_2: splitQuestions[1],
        answer_1: Answer_final[0],
        answer_2: Answer_final[1],
        front_flag: flag,
      });

      console.log(body_data);
      await server_post_data(URL_FOR_LINK, body_data)
        .then((response) => response.json())
        .then((data) => {
          if (flag_for_start === "14" && !start_server_for_curl_done) {
            server_get_data("from server", data);
          } else {
            start_server_for_curl = true;
            curl_data_get = data;
          }
        })
        .catch((error) => {
          console.error("curl Error:", error);
        });
    }
  };

  const show_loader_mic_speaker = (for_loader, for_mic, for_speaker) => {
    setshowLoader(for_loader);
    setshowMic(for_mic);
    setshowSpeaker(for_speaker);

    if (for_mic === true) {
      startTimer();
      webkitSpeechRecognition();
    }
  };

  // Function to handle server response
  const server_get_data = (where_from, data) => {
    //technotask
    console.log(where_from, data);
    pop_up_timing = 0;
    flag_for_start = "150";
    if (data.for_front_flag) {
      flag_for_start = data.for_front_flag;
    }

    if (flag_for_start === "6") {
      target_language = language_for_final;
    }
    let answer_bank = answer_histry(data.speak_txt);
    Answer_final = answer_bank.split("~@~");

    back_flag_backend = data.back_flag;
    front_website_word = data.front_website;
    setWebsiteName(front_website_word);
    play_mic = data.play_mic;
    let audio_file = data.file_path;
    let audio_text = data.speak_txt;
    pop_up_timing = data.pop_up_timing;
    audio_off_final = false;
    setIsAgree(false);
    setIsDisAgree(false);
    if (back_flag_backend === "1") {
      setShowPopups((prevState) => ({
        ...prevState,
        popup2: true,
      }));
    } else if (back_flag_backend === "3") {
      setIsAgree(true);
    } else if (back_flag_backend === "11" || back_flag_backend === "15") {
      setShowPopupsDiv(false);
    } else if (back_flag_backend === "50") {
      //stop the converstion
      audio_off_final = true;
      audio_file = THANKYOU_LINK;
    }
    if (flag_for_start === "14") {
      send_active_curl(front_website_word, flag_for_start);
    }
    start_audio = true;
    endAudio(audio_file, audio_text, audio_off_final);
  };

  // Function to handle input change
  const handleInputChange = (event) => {
    // Update the websitename state when the input value changes
    setWebsiteName(event.target.value);
    front_website_word = event.target.value;
  };

  //shubham jain  code

  let currentAudioTime = 0;
  let audioDuration = 0;
  let timerInterval;
  // Function to play audio and trigger speech recognition when it ends
  const endAudio = async (musicLink, music_text, end_audio) => {
    if (start_audio) {
      start_audio = false;
      audioDuration = 0;
      if (end_audio === false) {
        // try {
        //   audioRef.current.src = musicLink;
        //   await audioRef.current.addEventListener("canplaythrough", () => {
        //     audioDuration = audioRef.current.duration;
        //     currentAudioTime = audioRef.current.duration;
        //     sendMessage("PlayAudio", "PlayMyAudio", musicLink);
        //     handleTimer();
        //   });
        // } catch {
        //   //error
        //   sendMessage("PlayAudio", "PlayMyAudio", musicLink);
        //   handleTimer();
        // }
        sendMessage("PlayAudio", "PlayMyAudio", musicLink);
        handleTimer();
      }
    }
  };

  const handleTimer = () => {
    clearInterval(timerInterval); // Clear any existing interval to prevent overlapping intervals

    timerInterval = setInterval(() => {
      if (currentAudioTime < 1) {
        clearInterval(timerInterval); // Stop the interval when the timer reaches 0
        console.log("Final function and exit.");
        return;
        // Call your final function or perform other exit actions here
      } else {
        console.log(currentAudioTime, audioDuration, true);
        middle_audio_video_link(currentAudioTime, audioDuration, true);
        currentAudioTime--;
      }
    }, 1000); // Update the timer every 1 second
  };

  //unity function
  // Define an event listener function
  const handleEndAudiounity = useCallback((userName, score) => {
    console.log(userName);
    console.log(score);
    if (score > 99) {
      end_audio_video_link(audio_off_final);
    }
  }, []);
  //unity function

  const [showLoaderTechnotask, setshowLoaderTechnotask] = useState(true);

  // Use useEffect to hide the loader after 5 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      setshowLoaderTechnotask(false);
    }, 5000);

    return () => clearTimeout(timer); // Clear the timeout if the component unmounts
  }, []);

  return (
    <div className="screen_container">
      <div className="screen_wrapper col-lg-10 mx-auto">
        <div className="policies_screen col-lg-10 mx-auto">
          <div className="policies_screen_row row m-0">
            {showLoaderTechnotask && (
              <div className="loadeTechno">
                <LoadrerTechnoTask />
              </div>
            )}
            {showPopupsDiv && (
              <div className="policies_popup_container col-xl-5 col-lg-6 col-md-7 col-11 mx-auto">
                {languageBtn && (
                  <div className="langScreen m-auto">
                    <div className="langText">
                      <p>
                        Greetings! I'm Isabella. To begin our conversation, please
                        choose a language.
                      </p>
                      <h6>Please Select Language to Start Conversation </h6>
                    </div>
                    <div className="lang_btn_divs">
                      <button
                        onClick={() => start_with_language("en-XA", 0, "")}
                      >
                        <span className="button__label">English</span>
                      </button>
                      {/* hi-IN   1*/}
                      <button
                        onClick={() => start_with_language("hi-XA", 1, "")}
                      >
                        <span className="button__label">हिंदी</span>
                      </button>
                      {/* ar-SA   2*/}
                      <button
                        onClick={() => start_with_language("ar-XA", 2, "")}
                      >
                        <span className="button__label">عربي</span>
                      </button>
                    </div>
                  </div>
                )}
                {showPopups.popup1 && (
                  <div className="permision_popUp popUp_">
                    <h6>
                      FOR ME TO WORK BEST, I'D LIKE TO BE ABLE TO SEE YOU AND
                      HEAR YOUR VOICE.
                    </h6>
                    <p>
                      This will be just like a video call where we can talk face
                      to face. We don't record audio or video.
                    </p>
                    <h6 className="m-0">What shall we talk about?</h6>
                  </div>
                )}
                {showPopups.popup2 && (
                  <div className="privacy_popUp popUp_">
                    <h6>Privacy Policy</h6>
                    <p>Understanding how Technotask uses your data</p>
                    <hr />
                    <Link to="/privacy_policy"><button>View Page</button></Link>
                  </div>
                )}
                {showPopups.popup3 && (
                  <div className="agree_popUp popUp_">
                    <button
                      className={`acceptBtn ${IsAgree ? "acceptSelected" : ""}`}
                      onClick={() =>
                        conversationalTalk("I Agree", flag_for_start)
                      }
                    >
                      <span>I Agree</span>
                      <span>
                        <img src={moreThan} alt="icon" />
                      </span>
                    </button>
                    <button
                      className={`rejectBtn ${
                        IsDisAgree ? "rejectSelected" : ""
                      }`}
                      onClick={() =>
                        conversationalTalk(
                          "Not This Time Thanks",
                          flag_for_start
                        )
                      }
                    >
                      <span>Not, this time. Thanks</span>
                      <span>
                        <img src={moreThan} alt="icon" />
                      </span>
                    </button>
                  </div>
                )}
                {domainPopUp && (
                  <div className="domain_popUp agree_popUp popUp_ m-auto">
                    <h6>PLEASE CONFIRM YOUR WEBSITE DOMAIN</h6>
                    <div className="input_confirm">
                      <div className="domain_input">
                        <input
                          type="text"
                          value={websitename}
                          onChange={handleInputChange} // Add onChange event handler
                        />
                      </div>
                      <button
                        className={`acceptBtn ${
                          IsConfirm ? "acceptSelected" : ""
                        }`}
                        onClick={() => ConfirmDomain(true)}
                      >
                        <span>I Agree</span>
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-check-lg"
                            viewBox="0 0 16 16"
                          >
                            <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                          </svg>
                        </span>
                      </button>
                    </div>
                  </div>
                )}
              </div>
            )}
            <div className="nafiaImg col-lg-4 col-md-5 col-sm-5 col-8 m-auto-left-right-bottom">
              <Unity
                unityProvider={unityProvider}
                style={{ display: "block" }}
              />
            </div>
            <div hidden>
              <audio
                ref={audioRef}
                // autoPlay
                controls
              >
                <source type="audio/mpeg" />
                Your browser does not support the audio element.
              </audio>
              <div hidden>{startSpeechRecognition(finalTranscript)}</div>
            </div>
            <div className={`loader_speaker ${showSpeaker ? "loading" : ""}`}>
              <img
                src={speakerGIf}
                className="speaker_load_image left_icon_show"
                alt="nafia"
              />
            </div>
            <div className={`loader_mic_load left_icon_show ${showLoader ? "loading" : ""}`}>
              <img src={Micload} className="mic_load_image" alt="nafia" />
            </div>
            <div className={`loader_mic left_icon_show ${showMic ? "loading" : ""}`}>
              <img src={MicIcon} className="mic_image" alt="nafia" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NafiyaScreen;
