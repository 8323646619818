import React, { useState, useEffect, useRef } from "react";
import "../../Dasboard/Css/Dashboard.css";
import "../../Dasboard/Css/Bucket30days.css";
import "../../Dasboard/Css/SettingPage.css";
import "../../Dasboard/Css/HelpSupport.css";
import "../../Dasboard/Css/SideBar.css";
import "../../Dasboard/Css/HomePageNafia.css";
import "../../Dasboard/Css/KUKnowledgeBase.css";
import "./../Dasboard/Css/ApexChart.css";
import NafiaSideBar from "./NafiaSideBar.js";
import Calender from "../../assets/images/calendar12.svg";
import ReactApexChart from "react-apexcharts";
import { Helmet } from "react-helmet";
import Layer from "../../assets/images/Layer.png";
import Layer2 from "../../assets/images/Layer2.png";
import Layer3 from "../../assets/images/Layer3.png";
import FilterSvg from "../../assets/images/Funnel.svg";
import DownloadBtn from "../../assets/images/downloadsvg.svg";
import Technotask from "../../assets/images/TechnotaskLogo12.png";
import { Modal, Button } from "react-bootstrap";
import $ from "jquery";
import moment from "moment";
import "moment/locale/en-au";
import "daterangepicker";
import NafiaUserCenterNav from "./NafiaUserCenterNav.js";
import {
  server_get_data_master,
  NAFIA_POSITIVE_NEGATIVE,
  NAFIA_USER_LIST,
  URL_GET_NAFIA_CONVR,
} from "../../KeyConnection/KeysService";

function CreateNewTicket() {
  const [overviewShow, setOverviewShow] = useState(true); // Default to 'true'
  const [debtorProfile, setdebtorProfile] = useState(false);

  const [showListView, setShowListView] = useState(false);
  const toggleListView = () => {
    setShowListView(!showListView);
  };

  const ListView = ({ items }) => {
    return (
      <ul>
        {items.map((item, index) => (
          <ul key={index}>
            <a href={`${item.links}`}>{item.pages}</a>
          </ul>
        ))}
      </ul>
    );
  };
  const items = [
    {
      pages: ["Last Month"],
    },
    {
      pages: ["Month to Date"],
    },
    {
      pages: ["Last 3 Months"],
    },
    {
      pages: ["Last 6 Months"],
    },
    {
      pages: ["Year to Date"],
    },
    {
      pages: ["Lifetime"],
    },
  ];

  const [AmraadminData, setdataFromAmra] = useState([]);
  const inputRef = useRef(null);

  const [isLoading, setLoading] = useState(false);
  const [companyID, setCompanyID] = useState(
    localStorage.getItem("company_id")
  );
  const [botID, setBotID] = useState(1);
  const [nafiaPosNeg, setNafiaPosNegData] = useState({
    positive_negative: {
      positive_count: 0,
      neutral_count: 0,
      negative_count: 0,
    },
  });
  const [nafiaUserInteraction, setNafiaUserInteraction] = useState([
    {
      serial: "",
      user_id: "",
      user_name: "",
      interaction_date: "",
      startTime: "",
      endTime: "",
      // endTime: "NA",
      duration_min: "",
      duration_sec: "",
      // duration: "NA",
    },
  ]);
  const [chartState, setChartState] = useState({
    series: [5647, 3125, 127],
    options: {
      plotOptions: {
        pie: {
          donut: {
            size: "75%",
            labels: {
              show: true,
              name: {
                show: true,
              },
              value: {
                show: false,
              },
              total: {
                show: true,
                label: "Interaction",
                color: "#000",
                fontWeight: "bold",
                fontSize: "16px", // Increase the font size for the total label
              },
            },
          },
        },
      },
      chart: {
        type: "donut",
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 150, // Adjust the width for a smaller size in mobile view
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
      colors: [
        "rgba(68, 183, 27, 1)",
        "rgba(245, 158, 11, 1)",
        "rgba(255, 15, 15, 1)",
      ],
      labels: ["Positive", "Neutral", "Negative"],
      legend: {
        show: true,
        position: "bottom",
        horizontalAlign: "center", // Center the legend horizontally
        offsetY: 8, // Adjust the vertical offset to add margin
        markers: {
          width: 12,
          height: 12,
          shape: "rectangle", // Change the shape to square
        },
        itemMargin: {
          horizontal: 8, // Adjust the horizontal margin between legend items
        },
      },
    },
  });

  const showNafiadata = async (URL_FOR, dateRange, company_id, bot_id) => {
    setLoading(true);
    let target_url = `${URL_FOR}${dateRange.startDate}/${dateRange.endDate}/${company_id}/${bot_id}/`;
    try {
      const res = await server_get_data_master(target_url);
      const data = await res.json();
      console.log(data.data);
      setNafiaPosNegData(data.data);
      setChartState({
        ...chartState,
        series: [
          data.data.positive_negative.positive_count,
          data.data.positive_negative.neutral_count,
          data.data.positive_negative.negative_count,
        ],
      });
    } catch (err) {
      alert("Something went wrong! Try again later");
      console.log(err);
    }
  };

  const showNafiaRecentInteractions = async (
    URL_FOR,
    dateRange,
    company_id,
    bot_id
  ) => {
    setLoading(true);
    let target_url = `${URL_FOR}${dateRange.startDate}/${dateRange.endDate}/${company_id}/${bot_id}/`;
    console.log(target_url);
    try {
      const res = await server_get_data_master(target_url);
      const data = await res.json();
      console.log(data);
      const interaction_arr = [];

      data.data.forEach(async (interaction, idx) => {
        const url = URL_GET_NAFIA_CONVR + interaction.session_id;
        const response = await fetch(url);
        const data = await response.json();
        const chat = data.data;
        let start_date = new Date(chat[0].created_at);
        let end_date = new Date(chat[chat.length - 1].created_at);
        // Date formatting options
        const dateFormatOptions = {
          year: "numeric",
          month: "long",
          day: "numeric",
        };
        // Time formatting options
        const timeFormatOptions = {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
          timeZone: "IST", // Adjust this based on your actual timezone
        };
        // Format date and time separately
        const formattedDate_start = start_date.toLocaleDateString(
          "en-US",
          dateFormatOptions
        );
        const formattedTime_start = new Intl.DateTimeFormat(
          "en-US",
          timeFormatOptions
        ).format(start_date);

        const formattedTime_end = new Intl.DateTimeFormat(
          "en-US",
          timeFormatOptions
        ).format(end_date);

        let timeDiff = Math.abs(end_date - start_date);
        let diffSeconds = Math.ceil(timeDiff / 1000);

        let minutes = Math.floor(diffSeconds / 60);
        let seconds = diffSeconds % 60;

        const obj = {
          serial: idx + 1,
          user_id: interaction.session_id,
          user_name: "NA",
          interaction_date: formattedDate_start,
          startTime: formattedTime_start,
          endTime: formattedTime_end,
          // endTime: "NA",
          duration_min: minutes,
          duration_sec: seconds,
          // duration: "NA",
        };

        interaction_arr.push(obj);
        setNafiaUserInteraction([...interaction_arr]);
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleDateRangeChange = (start, end, label) => {
    // Callback function for handling date range changes
    let startDate = start.format("DD-MM-YYYY").toString();
    let endDate = end.format("DD-MM-YYYY").toString();

    let dates = {
      startDate: start.format("YYYY-MM-DD").toString(),
      endDate: end.format("YYYY-MM-DD").toString(),
    };
    console.log(dates);
    showNafiadata(NAFIA_POSITIVE_NEGATIVE, dates, companyID, botID);
    showNafiaRecentInteractions(NAFIA_USER_LIST, dates, companyID, botID);
  };

  useEffect(() => {
    // Call the function to fetch data when the component mounts
    // $(document).ready(function () {

    // });

    const datepicker = $(inputRef.current);
    datepicker.daterangepicker(
      {
        timePicker: true,
        startDate: moment().subtract(7, "days").startOf("day"), // Set start date to 7 days ago
        endDate: moment().add(2, "days").endOf("day"), // Set end date to today
        locale: {
          format: "DD/MM/YYYY",
        },
      },
      handleDateRangeChange
    );

    // Call handleDateRangeChange on component load
    const startDate = datepicker.data("daterangepicker").startDate;
    const endDate = datepicker.data("daterangepicker").endDate;
    handleDateRangeChange(startDate, endDate);
  }, []);

  const [callRecords, setCallRecords] = useState([
    {
      type: "content",
      text: "hello",
    },
    {
      type: "response",
      text: "hello, am I speaking with Fathima",
    },
  ]);

  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => {
    setShowModal(false); // Close the modal
  };

  const showNafiaConversation = async (customer_id) => {
    console.log("interaction getting fetched");
    const url = URL_GET_NAFIA_CONVR + customer_id;
    try {
      const response = await fetch(url);
      const data = await response.json();
      const chat = data.data;
      const conversation = chat.map((message, idx) => {
        console.log(message.content_source);
        return {
          user: message.content_source,
          bot: message.response_source,
        };
      });
      console.log(conversation);
      setCallRecords(conversation);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setShowModal(true);
  };

  const [selectedtime, setSelectedselectedtime] = useState(null);
  const timeList = [
    { name: "This Week", code: "This Week" },
    { name: "Last Month", code: "Last Month" },
    { name: "last 3 Months ", code: "last 3 Months " },
    { name: "last 6 Months ", code: "last 6 Months " },
    { name: "last Year", code: "last Year" },
    { name: "Lifetime", code: "Lifetime" },
  ];

  const dataTableRef = useRef(null);

  useEffect(() => {
    // Initialize DataTable
    $("#myTable").DataTable();
    // if (nafiaUserInteraction && nafiaUserInteraction.length > 0) {
    //   // Destroy the DataTable if it already exists
    //   if ($.fn.DataTable.isDataTable("#myTable")) {
    //     $("#myTable").DataTable().destroy();
    //   }

    //   // Initialize DataTable
    //   $("#myTable").DataTable();
    // }
    // console.log(nafiaUserInteraction);
  }, []);

  return (
    <div className="dashboard">
      <Helmet>
        <title>Nafia User Dashboard</title>
        <meta
          name="description"
          content="AI and Global Reach: The Key to Scalable Solutions in Customer
          Experience"
        />
      </Helmet>
      <div className="dashboard_container">
        <div className="dashboard_wrapper">
          <NafiaSideBar
            overviewShow={overviewShow}
            setOverviewShow={setOverviewShow}
            debtorProfile={debtorProfile}
            setdebtorProfile={setdebtorProfile}
          />
          <div id="content">
            <NafiaUserCenterNav />
            <div className="overviewContaINER">
              <div className="overviewHead overviewHeaddp ">
                <h4>Keyword Analytics</h4>
                <div className="overviewHeadLeft">
                  <div className="overviewHeadLeftContn">
                    <div className="date_filter_div">
                      <div className="date_filter">
                        <div className="calender_icon">
                          <label
                            style={{ cursor: "pointer" }}
                            htmlFor="datepicker"
                          >
                            <img src={Calender} alt="Image" />
                          </label>
                        </div>
                        <input
                          id="datepicker"
                          className="form-control form-control-solid"
                          placeholder="Pick date range"
                          ref={inputRef}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="valueCards">
              <div className="valueCardsrow row m-0 ">
                <div className="valueCardpad col-xl-4 col-lg-4 d-flex align-items-center">
                  <div className="valueCardCallviw w-100">
                    <div id="chart-container" className="custom-apex-chart">
                      <ReactApexChart
                        options={chartState.options}
                        series={chartState.series}
                        type="donut"
                      />
                    </div>
                  </div>
                </div>
                <div className="row m-0 col-xl-8 col-lg-8 p-0">
                  <div className="valueCardpad col-lg-4 ">
                    <div className="valueCardCallview caRDS interactionCards">
                      <div className="inContent">
                        <p>Positive Interaction</p>
                        <div className="imgLayer">
                          <img src={Layer} />
                        </div>
                        <h1>{nafiaPosNeg.positive_negative.positive_count}</h1>
                      </div>
                    </div>
                  </div>
                  <div className="valueCardpad col-lg-4">
                    <div className="valueCardCallview caRDS interactionCards">
                      <div className="inContent">
                        <p>Neutral Interaction</p>
                        <div className="imgLayer">
                          <img src={Layer2} />
                        </div>
                        <h1>{nafiaPosNeg.positive_negative.neutral_count}</h1>
                      </div>
                    </div>
                  </div>
                  <div className="valueCardpad col-lg-4">
                    <div className="valueCardCallview caRDS interactionCards">
                      <div className="inContent">
                        <p>Negative Interaction </p>
                        <div className="imgLayer">
                          <img src={Layer3} />
                        </div>
                        <h1>{nafiaPosNeg.positive_negative.negative_count}</h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="datatableCOntainerDiv mb-3">
              <div className="datatableCOntainerHead">
                <h4>Recent Interaction</h4>
                {/* <div className="filterBtnDivs">
                  <button>
                    <p>Filter</p>
                    <img src={FilterSvg} alt="icon" />
                  </button>
                </div> */}
              </div>
              <div className="amradataTable">
                <div className="amradataTable_container amradataTable_container2">
                  <table id="myTable" className="display" ref={dataTableRef}>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>User ID</th>
                        <th>Name</th>
                        <th>Interaction Date</th>
                        <th>Start Time</th>
                        <th>End Time</th>
                        <th>Total Duration</th>
                        <th>CRM Note</th>
                      </tr>
                    </thead>
                    <tbody>
                      {nafiaUserInteraction.map((item, index) => (
                        <tr key={index}>
                          <td>
                            <div className="tableData1 tableData12">
                              <p>{item.serial}</p>
                            </div>
                          </td>
                          <td>
                            <div className="tableData1 tableData12">
                              <p>{item.user_id}</p>
                            </div>
                          </td>
                          <td>
                            <div className="tableData1 tableData12">
                              <p style={{ color: "#000" }}>{item.user_name}</p>
                            </div>
                          </td>
                          <td>
                            <div className="tableData1 tableData12">
                              <p>{item.interaction_date}</p>
                            </div>
                          </td>
                          <td>
                            <div className="tableData1 tableData12">
                              <p>{item.startTime}</p>
                            </div>
                          </td>
                          <td>
                            <div className="tableData1 tableData12">
                              <p>{item.endTime}</p>
                            </div>
                          </td>
                          <td>
                            <div className="tableData1 tableData12">
                              {item.duration_min === 0 && (
                                <p>{`${item.duration_sec} sec`}</p>
                              )}
                              {item.duration_min !== 0 && (
                                <p>{`${item.duration_min} min ${item.duration_sec} sec`}</p>
                              )}
                            </div>
                          </td>
                          <td>
                            <div className="downloadCRmDIv">
                              <img
                                src={DownloadBtn}
                                alt="icon"
                                onClick={() =>
                                  showNafiaConversation(item.user_id)
                                }
                              />
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="brandingContainer">
              <div>
                <h5>Powered by</h5>
                <img src={Technotask} alt="Logo" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showModal} onHide={handleCloseModal}>
        {/* Modal content */}
        <Modal.Header closeButton>
          <Modal.Title>Interaction Transcript</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {callRecords.map((record, index) => (
              <div key={index}>
                <p
                  style={{
                    textAlign: "flex-end",
                    margin: "7px",
                    float: "left",
                  }}
                  className="leftTextbg"
                >
                  {record.user}
                </p>
                <br></br>
                <p
                  style={{
                    textAlign: "flex-endstart",
                    margin: "7px",
                    float: "right",
                  }}
                  className="rightTextbg"
                >
                  {record.bot}
                </p>
              </div>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default CreateNewTicket;
