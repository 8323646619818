import React, { useState, useEffect } from "react";
import "../Css/Dashboard.css";
import "../Css/Bucket30days.css";
import SideBar from "./SideBar";
import Expand1 from "../../assets/images/expand 1.svg";
import MoreIcon from "../../assets/images/more_vertical.svg";
import { Chart as ChartJS, registerables } from "chart.js";
import "react-circular-progressbar/dist/styles.css";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import Right from "../../assets/images/right45.svg";
import OrngImg from "../../assets/images/orangeProfile.svg";
import ProgressBar from "./ProgressBar";
import RightArrow from "../../assets/images/rgtarow.svg";
import SearchIcon from "../../assets/images/searchIcon.svg";
import calendericon from "../../assets/images/calicon2.png";
import { Modal, Button } from "react-bootstrap";
import MyChart from "./Mychart";
import Technotask from "../../assets/images/TechnotaskLogo12.png";
import DebtorProfileModal from "./DebtorProfileModal";
import { Helmet } from "react-helmet";
import CenterNavbar from "./CenterNavbar";
import {
  csv_amra_user_upload,
  city_list,
  get_debtor,
  server_post_data_shubham,
  get_all_Debtorprofile,
  API_LINK_KEY,
} from "../../KeyConnection/KeysService.js";
import { Link } from "react-router-dom";

function DebtorProfile60() {
  const [isLoading, setLoading] = useState(false);
  const [loanID, setLoanID] = useState("");
  const [userNumber, setuserNumber] = useState("");
  const [timeList, SettimeList] = useState([]);
  const company_id = localStorage.getItem("company_id");
  const [debtorDataForBucket, setDebtorDataForBucket] = useState({
    debtor_emi_amt: 0,
    recovery_amount: 0,
    outstanding_amount: 0,
    dispute_amt: 0,
    admin_commission: 0,
    total_calls_made: 0,
    answered_calls: 0,
    dropped_calls: 0,
    promise_to_pay: 0,
    promise_to_pay_amount: 0,
    unable_to_pay: 0,
    rescheduled: 0,
    escalation: 0,
    part_payment: 0,
    technical_issue: 0,
    dispute: 0,
  });
  const [debtorDataCityWise, setDebtorDataCityWise] = useState();

  const [debtorProfileData, setDebtorProfileData] = useState({
    customer_id: 12334,
    name: "",
    mobile: "",
    email: "",
    gender: "",
    alt_mobile: "NA",
    dob: "NA",
    age: "NA",
    marital_status: "NA",
    highest_qualification: "NA",
    father_name: "NA",
    mother_name: "NA",
    permanent_add: "",
    current_add: "",
  });

  ChartJS.register(...registerables);

  const data = {
    labels: ["January", "February", "March", "April", "May", "June"],
    datasets: [
      {
        label: "TOTAL",
        data: [100000, 87700, 88100, 100200, 99600, 92900],
        borderColor: "rgb(75, 192, 192)",
        tension: 0.37,
        pointRadius: 2,
        fill: false,
      },
      {
        label: "COLLECTION",
        data: [30000, 23000, 21000, 27000, 22000, 19000],
        borderColor: "rgb(255, 99, 132)",
        tension: 0.37,
        pointRadius: 2,
        fill: false,
      },
      {
        label: "PENDING",
        data: [70000, 72000, 64000, 73000, 67000, 69000],
        borderColor: "rgb(255, 205, 86)",
        tension: 0.37,
        pointRadius: 2,
        fill: false,
      },
    ],
  };

  const options = {
    scales: {
      x: {
        title: {
          display: false,
        },
      },
      y: {
        title: {
          display: false,
        },
        ticks: {
          min: 30000,
          max: 110000,
          stepSize: 20000,
          precision: 0,
        },
      },
    },
    plugins: {
      tooltip: {
        mode: "nearest",
      },
    },
  };

  useEffect(() => {
    const start_date = "";
    const end_date = "";
    const flag = "5";
    let call_id = "0";
    master_data_get(start_date, end_date, flag, call_id);
  }, [``]);

  const master_data_get = async (start_date, end_date, flag, call_id) => {
    setLoading(true);
    const fd = new FormData();
    fd.append("admin_id", company_id);
    fd.append("start_date", start_date);
    fd.append("end_date", end_date);
    fd.append("flag", flag);
    fd.append("call_id", call_id);
    await server_post_data_shubham(`${get_all_Debtorprofile}${company_id}/`, fd)
      .then((Response) => {
        if (Response.data.error) {
          alert(Response.data.message);
        } else {
          const city_wise_data = Response.data.message.data_silder_image2;
          SettimeList(city_wise_data);
          let data = Response.data.message.data_debtors;

          const obj = {
            debtor_emi_amt: 0,
            recovery_amount: 0,
            outstanding_amount: 0,
            dispute_amt: 0,
            admin_commission: 0,
            total_calls_made: 0,
            answered_calls: 0,
            dropped_calls: 0,
            promise_to_pay: 0,
            promise_to_pay_amount: 0,
            unable_to_pay: 0,
            rescheduled: 0,
            escalation: 0,
            part_payment: 0,
            technical_issue: 0,
            dispute: 0,
          };
          for (let i = 0; i < data.length; i++) {
            const debtor = data[i];

            // cities
            let city = city_wise_data.filter((item) => {
              return debtor.city_id === item.id;
            });

            // calculate bucket using due data
            let now = Date.now();
            let dueDate = new Date(debtor.due_date);
            if (debtor.last_payment_date) {
              dueDate = new Date(debtor.last_payment_date);
            }
            let days = Math.ceil((now - dueDate) / (1000 * 60 * 60 * 24));

            // only moving forward for 60 days bucket
            if (days > 60) {
              debtor.debtor_history.forEach((instance) => {
                if (debtor.debtor_history.length > 0) {
                  obj.debtor_emi_amt += Number(instance.debtor_emi_amt);
                  obj.recovery_amount += Number(instance.recovery_amount);
                  obj.dispute_amt += Number(instance.dispute_amt);
                  obj.admin_commission += Number(instance.admin_commission);
                  // call_flag = 0, 1, 2,..,8 :
                  // 0 => call not made,
                  // >0 => call made,
                  // 1 => call dropped,
                  // 2 => answered call,
                  // 3 => promise to pay,
                  // 4 => unable to pay,
                  // 5 => rescheduled,
                  // 6 => L2 escalation,
                  // 7 => part payment,
                  // 8 => technical issue,
                  // 9 => dispute,
                  if (Number(instance.call_flag) > 0) {
                    obj.total_calls_made += 1;
                  }
                  if (instance.call_flag >= 2) {
                    obj.answered_calls += 1;
                  }
                  if (instance.call_flag === 3) {
                    obj.promise_to_pay += 1;
                    obj.promise_to_pay_amount +=
                      Number(instance.debtor_emi_amt) -
                      Number(instance.recovery_amount);
                  }
                  if (instance.call_flag === 4) {
                    obj.unable_to_pay += 1;
                  }
                  if (instance.call_flag === 5) {
                    obj.rescheduled += 1;
                  }
                  if (instance.call_flag === 6) {
                    obj.escalation += 1;
                  }
                  if (instance.call_flag === 7) {
                    obj.part_payment += 1;
                  }
                  if (instance.call_flag === 8) {
                    obj.technical_issue += 1;
                  }
                  if (instance.call_flag === 9) {
                    obj.dispute += 1;
                  }
                  city[0].debtor_emi_amt += Number(instance.debtor_emi_amt);
                  city[0].recovery_amount +=
                    Number(instance.recovery_amount) || 0;
                }
              });
              city[0].total_debtors += 1;
            }
            obj.outstanding_amount = obj.debtor_emi_amt - obj.recovery_amount;
            obj.dropped_calls = obj.total_calls_made - obj.answered_calls;
            city_wise_data.forEach((city) => {
              city.average_recovery_rate =
                parseFloat(
                  ((city.recovery_amount * 100) / city.debtor_emi_amt).toFixed(
                    2
                  )
                ) || 0;
            });
            setDebtorDataForBucket(obj);
            setDebtorDataCityWise(city_wise_data);
          }
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const searchDebtorProfile = async () => {
    // Fetch data from the API endpoint
    if (loanID !== "" || userNumber !== "") {
      setLoading(true);
      let url_for = get_debtor;
      if (userNumber.length === 0) {
        url_for += loanID;
      } else {
        url_for += userNumber;
      }

      await fetch(url_for)
        .then((response) => response.json())
        .then((data) => {
          let debtor = data.data;
          // debtor profile
          let gender = "Other";
          if (debtor.debtor_gender === "M") {
            gender = "Male";
          } else if (debtor.debtor_gender === "F") {
            gender = "Female";
          }
          const filteredData = timeList.filter(
            (item) => debtor.city_id === item.id
          );

          let debtor_profile = {
            customer_id: debtor.debtor_id,
            name: debtor.debtor_name,
            mobile: debtor.debtor_no,
            email: debtor.debtor_email,
            gender,
            alt_mobile: "NA",
            dob: "NA",
            age: "NA",
            marital_status: "NA",
            highest_qualification: "NA",
            father_name: "NA",
            mother_name: "NA",
            permanent_add: filteredData[0].name,
            current_add: filteredData[0].name,
          };
          setDebtorProfileData(debtor_profile);
          const button = document.getElementById("modelclick");
          if (button) {
            button.click();
          }
        })
        .catch((err) => {});
      setLoading(false);
    }
  };

  const [overviewShow, setOverviewShow] = useState(true); // Default to 'true'
  const [debtorProfile, setdebtorProfile] = useState(false);
  const [showListView, setShowListView] = useState(false);

  const ListView = ({ items }) => {
    return (
      <ul>
        {items.map((item, index) => (
          <li key={index}>
            <a href={`${item.links}`}>{item.pages}</a>
          </li>
        ))}
      </ul>
    );
  };

  const items = [
    {
      pages: ["30-Day Bucket"],
      links: ["/Debtorprofile/Bucket30days"],
    },
    {
      pages: ["60-Day Bucket"],
      links: ["/Debtorprofile/Bucket60days"],
    },
    // {
    //   pages: ["Pre Due Bucket"],
    // },
    // {
    //   pages: ["L 2 Escalation"],
    // },
    // {
    //   pages: ["Dispute"],
    // },
  ];

  const toggleListView = () => {
    setShowListView(!showListView);
  };
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const [showModal1, setShowModal1] = useState(false);
  const [showModal2, setShowModal2] = useState(false);

  const handleCloseModal1 = () => setShowModal1(false);
  const handleCloseModal2 = () => setShowModal2(false);

  const [showModal3, setShowModal3] = useState(false);
  const [showModal4, setShowModal4] = useState(false);

  const handleCloseModal3 = () => setShowModal3(false);
  const handleCloseModal4 = () => setShowModal4(false);

  const [showModal5, setShowModal5] = useState(false);
  const handleCloseModal5 = () => setShowModal5(false);

  const modalData = [
  ];

  const [valueAge, setValueAge] = useState([20, 37]);

  const handleChange = (event, newValueAge) => {
    setValueAge(newValueAge);
  };

  const [isZoomedIn, setIsZoomedIn] = useState(false);

  const toggleZoom = () => {
    setIsZoomedIn(!isZoomedIn);
  };

  return (
    <div className="dashboard">
      <Helmet>
        <title>Amra User Dashboard</title>
        <meta
          name="description"
          content="AI and Global Reach: The Key to Scalable Solutions in Customer
          Experience"
        />
      </Helmet>
      <div className="dashboard_container">
        <div className={isLoading ? "loader-overlay show" : "loader-overlay"}>
          <div className="loader23"></div>
        </div>
        <div className="dashboard_wrapper">
          <SideBar
            overviewShow={overviewShow}
            setOverviewShow={setOverviewShow}
            debtorProfile={debtorProfile}
            setdebtorProfile={setdebtorProfile}
          />
          <div id="content">
            <CenterNavbar />

            <div className="AmraConversations">
              <div className="AmraConversations_container">
                <div className="AmraConversations_container_wrapper">
                  <div className="overviewContaINER">
                    <div className="overviewHead overviewHeaddp ">
                      <h4>
                        Debtor Profile /{" "}
                        <span className="silash">90 Days Bucket</span>
                      </h4>
                      <div className="overviewHeadLeft">
                        <div className="overviewHeadLeftContn">
                          <img
                            src={calendericon}
                            alt="Technotask"
                            onClick={toggleListView}
                            style={{ cursor: "pointer" }}
                          />
                          <div className="daysListView">
                            {showListView && <ListView items={items} />}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="debtorProfile">
                      <div className="debtorProfileContainer">
                        <div className="debtordetailCards">
                          <div className="row m-0">
                            <div
                              className="col-md-9"
                              style={{ paddingLeft: "0" }}
                            >
                              <div className="debtordetailCardsContainer">
                                <div className="row m-0">
                                  <div
                                    className="col-md-4"
                                    style={{ paddingLeft: "0" }}
                                  >
                                    <div className="debtordetailCard">
                                      <div className="debtordetailCardHead">
                                        <h6>
                                          Promise To Pay{" "}
                                          <span className="postMvp">
                                            Post MVP
                                          </span>
                                        </h6>
                                        <img
                                          alt="Technotask"
                                          src={RightArrow}
                                          onClick={handleShowModal}
                                        />
                                      </div>
                                      <div className="debtordetailCardBody">
                                        <img src={OrngImg} alt="icon" />
                                        <h4>
                                          {debtorDataForBucket?.promise_to_pay}
                                        </h4>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="debtordetailCard">
                                      <div className="debtordetailCardHead">
                                        <h6>
                                          Unable to Pay{" "}
                                          <span className="postMvp">
                                            Post MVP
                                          </span>
                                        </h6>
                                        <img
                                          alt="Open"
                                          src={RightArrow}
                                          onClick={() => setShowModal1(true)}
                                        />
                                      </div>
                                      <div className="debtordetailCardBody">
                                        <img src={OrngImg} alt="icon" />
                                        <h4>
                                          {debtorDataForBucket?.unable_to_pay}
                                        </h4>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="debtordetailCard">
                                      <div className="debtordetailCardHead">
                                        <h6>Rescheduled</h6>
                                        {/* <img
                                          src={RightArrow}
                                          onClick={() => setShowModal1(true)}
                                        /> */}
                                      </div>
                                      <div className="debtordetailCardBody">
                                        <img src={OrngImg} alt="icon" />
                                        <h4>
                                          {debtorDataForBucket?.rescheduled}
                                        </h4>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className="col-md-4"
                                    style={{ paddingLeft: "0" }}
                                  >
                                    <div className="debtordetailCard">
                                      <div className="debtordetailCardHead">
                                        <h6>Escalation</h6>
                                        {/* <img
                                          src={RightArrow}
                                          onClick={handleShowModal}
                                        /> */}
                                      </div>
                                      <div className="debtordetailCardBody">
                                        <img src={OrngImg} alt="icon" />
                                        <h4>
                                          {debtorDataForBucket?.escalation}
                                        </h4>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="debtordetailCard">
                                      <div className="debtordetailCardHead">
                                        <h6>Give Part Payment</h6>
                                        {/* <img
                                          src={RightArrow}
                                          onClick={handleShowModal}
                                        /> */}
                                      </div>
                                      <div className="debtordetailCardBody">
                                        <img src={OrngImg} alt="icon" />
                                        <h4>
                                          {debtorDataForBucket?.part_payment}
                                        </h4>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="debtordetailCard">
                                      <div className="debtordetailCardHead">
                                        <h6>Technical Issue</h6>
                                        {/* <img
                                          src={RightArrow}
                                          onClick={() => setShowModal1(true)}
                                        /> */}
                                      </div>
                                      <div className="debtordetailCardBody">
                                        <img src={OrngImg} alt="icon" />
                                        <h4>
                                          {debtorDataForBucket?.technical_issue}
                                        </h4>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-3 debtorSearch">
                              <div className="debtorFilter">
                                <div className="debtorFilterContainer">
                                  <h5>Search Debtor </h5>
                                  <input
                                    className="inp"
                                    type="number"
                                    placeholder="Loan Id"
                                    value={loanID}
                                    onChange={(e) => {
                                      setLoanID(e.target.value);
                                      setuserNumber("");
                                    }}
                                    onInput={(e) => {
                                      e.target.value = e.target.value.replace(
                                        /[^0-9]/g,
                                        ""
                                      );
                                    }}
                                  />
                                  <h5>or</h5>
                                  <input
                                    className="inp"
                                    type="mobile no"
                                    placeholder="Mobile No."
                                    maxLength="10"
                                    value={userNumber}
                                    onChange={(e) => {
                                      setLoanID("");
                                      setuserNumber(e.target.value);
                                    }}
                                    onInput={(e) => {
                                      e.target.value = e.target.value.replace(
                                        /[^0-9]/g,
                                        ""
                                      );
                                    }}
                                  />
                                  <button
                                    onClick={searchDebtorProfile}
                                    className="Search_btn  d-flex"
                                  >
                                    <img src={SearchIcon} alt="icon" />
                                    <p>Search</p>
                                  </button>
                                  <button
                                    id="modelclick"
                                    className="hidden"
                                    data-bs-toggle="modal"
                                    data-bs-target="#staticBackdrop1"
                                  >
                                    {" "}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-sm-12 ">
                    <div className="catagoriesfilter">
                      <div className="cate">
                        <h6 className="">Categories</h6>
                        <div className="checkbox-container">
                          <div className="checkbox-item">
                            <input
                              type="checkbox"
                              id="checkbox1"
                              name="checkbox1"
                            />
                            <label htmlFor="checkbox1" className="checkbox-label">
                              <p>Business</p>
                            </label>
                          </div>

                          <div className="checkbox-item">
                            <input
                              type="checkbox"
                              id="checkbox2"
                              name="checkbox2"
                            />
                            <label htmlFor="checkbox2" className="checkbox-label">
                              <p>Self-Employed</p>
                            </label>
                          </div>

                          <div className="checkbox-item">
                            <input
                              type="checkbox"
                              id="checkbox3"
                              name="checkbox3"
                            />
                            <label htmlFor="checkbox3" className="checkbox-label">
                              <p>Professionals</p>
                            </label>
                          </div>

                          <div className="checkbox-item">
                            <input
                              type="checkbox"
                              id="checkbox4"
                              name="checkbox4"
                            />
                            <label htmlFor="checkbox4" className="checkbox-label">
                              <p>Unable to Pay</p>
                            </label>
                          </div>

                          <div className="checkbox-item">
                            <input
                              type="checkbox"
                              id="checkbox5"
                              name="checkbox5"
                            />
                            <label htmlFor="checkbox5" className="checkbox-label">
                              <p>Reschedule</p>
                            </label>
                          </div>

                          <div className="checkbox-item">
                            <input
                              type="checkbox"
                              id="checkbox6"
                              name="checkbox6"
                            />
                            <label htmlFor="checkbox6" className="checkbox-label">
                              <p>Escalation</p>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div>
                          <h6 className="fil">Filter</h6>
                        </div>
                        <div className="ageandGenderFIlter">
                          <div className="rangeSlider mb-0">
                            <h6>Age</h6>
                            <Box sx={{ width: 300 }}>
                              <Slider
                                getAriaLabel={() => "Temperature range"}
                                value={valueAge}
                                onChange={handleChange}
                                valueLabelDisplay="auto"
                                // getAriaValueText={valuetext}
                                sx={{
                                  color: "#00BD90", // Replace with your desired color
                                  "& .MuiSlider-thumb": {
                                    backgroundColor: "grey", // Replace with your desired thumb color
                                  },
                                  "& .MuiSlider-valueLabel": {
                                    backgroundColor: "#F29CF6", // Replace with your desired value label color
                                  },
                                }}
                              />
                            </Box>
                          </div>
                          <div className="radio-container">
                            <h6>Gender</h6>
                            <div className="genderRadioInputs">
                              <div className="radio-item">
                                <input type="radio" id="male" name="gender" />
                                <label htmlFor="male" className="radio-label">
                                  Male
                                </label>
                              </div>

                              <div className="radio-item">
                                <input type="radio" id="female" name="gender" />
                                <label htmlFor="female" className="radio-label">
                                  Female
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <div
                    className={`col-12 ${isZoomedIn ? "zoomedIn" : ""}`}
                    style={{ padding: "0 10px" }}
                  >
                    <div
                      className={`usersContainer mb-2 ${
                        isZoomedIn ? "zoomedIn" : ""
                      }`}
                    >
                      <div className="revenueCallHead revenueCallHead2 ">
                        <div className="revenueDIv noneBorderDiv">
                          <img src={Expand1} alt="icon" onClick={toggleZoom} />
                          <div className="revenueText">
                            <p>Call (Audience Map Location)</p>
                            <p className="rtext2">Report Center</p>
                          </div>
                        </div>
                        <div
                          className="moreOption"
                          style={{ paddingRight: "1rem" }}
                        >
                          <img src={MoreIcon} alt="icon" />
                        </div>
                      </div>
                      <div className="mapLocation">
                        <div className="mapLocation_contaINER">
                          <div className="row" style={{ display: "flex" }}>
                            <div className="col-md-5">
                              <div className="countryRecovry">
                                <ul>
                                  {debtorDataCityWise?.map(
                                    (cityData, index) => (
                                      <li key={index}>
                                        <div className="countryRecovryItem">
                                          <div className="cntryRcvryItemLeft">
                                            <div className="cntryRcvryItemLeftHead">
                                              <h6>{cityData?.name}</h6>
                                              <p>
                                                {cityData?.total_debtors} Users
                                              </p>
                                            </div>
                                            <div className="cntryRcvryProBar">
                                              <ProgressBar
                                                done={
                                                  cityData?.average_recovery_rate
                                                }
                                              />
                                            </div>
                                          </div>
                                          <div className="cntryRcvryItemRight">
                                            <div className="cntryRcvryItemRigthHead">
                                              <p>Recovery Rate</p>
                                              <p className="rcvrypercnt">
                                                {
                                                  cityData?.average_recovery_rate
                                                }
                                                %
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                    )
                                  )}
                                </ul>
                              </div>
                            </div>
                            <div className="col-md-7">
                              <MyChart calldata={timeList} />
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className="revenueCallFoot">
                        <Link to="/CallDetailView">
                          <button>
                            <span>SEE DETAILS</span>
                            <span>
                              <img src={Right} alt="icon" />
                            </span>
                          </button>
                        </Link>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="brandingContainer">
                <div>
                  <h5>Powered by</h5>
                  <img src={Technotask} alt="Logo" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <DebtorProfileModal debtor={debtorProfileData} />
      </div>
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        dialogClassName="custom-modal1"
        centered
      >
        <Modal.Header closeButton>
          <span className="postMvp">Post MVP</span>
        </Modal.Header>
        <Modal.Body>
          <div className="fullycontent">
            <div className="col-md-7 promToPay valueCardpad valueCardpad5 ">
              <div className="valueCard d-flex justify-content-around">
                <div className="valueCardInside">
                  <p>
                    Today’s Recovery <br />
                  </p>
                  <h4>$895K</h4>
                </div>

                <div className="valueCardInside">
                  <p>Next 7 Days</p>
                  <h4>$895K</h4>
                </div>
              </div>
            </div>
            <div className="row m-0">
              <div className="col-md-7">
                <div className="countryRecovry">
                  <ul>
                    {debtorDataCityWise?.map((cityData, index) => (
                      <li key={index}>
                        <div className="countryRecovryItem">
                          <div className="cntryRcvryItemLeft">
                            <div className="cntryRcvryItemLeftHead">
                              <h6>{cityData?.name}</h6>
                              <p>{cityData?.total_debtors} Users</p>
                            </div>
                            <div className="cntryRcvryProBar">
                              <ProgressBar
                                done={cityData?.average_recovery_rate}
                              />
                            </div>
                          </div>
                          <div className="cntryRcvryItemRight">
                            <div className="cntryRcvryItemRigthHead">
                              <p>Recovery Rate</p>
                              <p className="rcvrypercnt">
                                {cityData?.average_recovery_rate}%
                              </p>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              <div className="circleProgBar circleProgBarPopUp col-md-4 m-auto">
                <div className="collecBAr">
                  <div style={{ width: "60px" }}>
                    <CircularProgressbar
                      value={
                        (debtorDataForBucket?.recovery_amount * 100) /
                        debtorDataForBucket?.debtor_emi_amt
                      }
                      text={`${(
                        (debtorDataForBucket?.recovery_amount * 100) /
                        debtorDataForBucket?.debtor_emi_amt
                      ).toFixed(2)}%`}
                      styles={buildStyles({
                        textColor: "#fff", // Color of the text
                        trailColor: `#9D7EF9`, // Color of the progress circle
                        pathColor: "rgba(255, 255, 255, 1)", // Color of the background trail
                      })}
                    />
                  </div>
                  <div className="persntTetx">
                    <p>Collection </p>
                    <h5>$ {debtorDataForBucket?.recovery_amount}</h5>
                  </div>
                </div>

                <div className="collecBAr1">
                  <div style={{ width: "60px" }}>
                    <CircularProgressbar
                      value={
                        (debtorDataForBucket?.promise_to_pay * 100) /
                        debtorDataForBucket?.outstanding_amount
                      }
                      text={`${(
                        (debtorDataForBucket?.promise_to_pay * 100) /
                        debtorDataForBucket?.outstanding_amount
                      ).toFixed(2)}%`}
                      styles={buildStyles({
                        textWeight: "500",
                        textColor: "rgba(52, 58, 64, 1)", // Color of the text
                        trailColor: `rgba(243, 243, 244, 1)`,
                        pathColor: "rgba(31, 206, 80, 1)", // Color of the background trail
                      })}
                    />
                  </div>
                  <div className="persntTetx">
                    <p>Promise to Pay</p>
                    <h5>$ {debtorDataForBucket?.promise_to_pay_amount}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showModal1}
        onHide={handleCloseModal1}
        dialogClassName="custom-modal1"
        centered
      >
        <Modal.Header closeButton>
          <span className="postMvp">Post MVP</span>
        </Modal.Header>
        <Modal.Body>
          <div className="fullycontent">
            <div className="valueCardpad">
              <div className="valueCard d-flex justify-content-around">
                <div className="valueCardInside popupdtailsCard">
                  <p>
                    Personal Reasons
                    <br /> Sick/Demise/Accident
                  </p>
                  <h4>
                    82K<span>user</span>
                  </h4>
                </div>

                <div className="valueCardInside popupdtailsCard">
                  <p>Scheduled With Manager</p>
                  <h4>
                    900K<span>user</span>
                  </h4>
                </div>

                <div className="valueCardInside popupdtailsCard">
                  <p>Inefficient Bankruptcy Amount</p>
                  <h4>
                    982<span>user</span>
                  </h4>
                </div>
              </div>
            </div>
            <div className="row m-0">
              <div className="col-md-7">
                <div className="countryRecovry">
                  <ul>
                    {debtorDataCityWise?.map((cityData, index) => (
                      <li key={index}>
                        <div className="countryRecovryItem">
                          <div className="cntryRcvryItemLeft">
                            <div className="cntryRcvryItemLeftHead">
                              <h6>{cityData?.name}</h6>
                              <p>{cityData?.total_debtors} Users</p>
                            </div>
                            <div className="cntryRcvryProBar">
                              <ProgressBar
                                done={cityData?.average_recovery_rate}
                              />
                            </div>
                          </div>
                          <div className="cntryRcvryItemRight">
                            <div className="cntryRcvryItemRigthHead">
                              <p>Recovery Rate</p>
                              <p className="rcvrypercnt">
                                {cityData?.average_recovery_rate}%
                              </p>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              <div className="circleProgBar circleProgBarPopUp col-md-4 m-auto">
                <div className="collecBAr">
                  <div style={{ width: "60px" }}>
                    <CircularProgressbar
                      value={
                        (debtorDataForBucket?.recovery_amount * 100) /
                        debtorDataForBucket?.debtor_emi_amt
                      }
                      text={`${(
                        (debtorDataForBucket?.recovery_amount * 100) /
                        debtorDataForBucket?.debtor_emi_amt
                      ).toFixed(2)}%`}
                      styles={buildStyles({
                        textColor: "#fff", // Color of the text
                        trailColor: `#9D7EF9`, // Color of the progress circle
                        pathColor: "rgba(255, 255, 255, 1)", // Color of the background trail
                      })}
                    />
                  </div>
                  <div className="persntTetx">
                    <p>Collection </p>
                    <h5>$ {debtorDataForBucket?.recovery_amount}</h5>
                  </div>
                </div>

                <div className="collecBAr1">
                  <div style={{ width: "60px" }}>
                    <CircularProgressbar
                      value={
                        (debtorDataForBucket?.promise_to_pay * 100) /
                        debtorDataForBucket?.outstanding_amount
                      }
                      text={`${(
                        (debtorDataForBucket?.promise_to_pay * 100) /
                        debtorDataForBucket?.outstanding_amount
                      ).toFixed(2)}%`}
                      styles={buildStyles({
                        textWeight: "500",
                        textColor: "rgba(52, 58, 64, 1)", // Color of the text
                        trailColor: `rgba(243, 243, 244, 1)`,
                        pathColor: "rgba(31, 206, 80, 1)", // Color of the background trail
                      })}
                    />
                  </div>
                  <div className="persntTetx">
                    <p>Promise to Pay</p>
                    <h5>$ {debtorDataForBucket?.promise_to_pay_amount}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default DebtorProfile60;
