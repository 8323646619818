import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import CenterNavAmraAdmin from "./CenterNavBurjeelAdmin";
import NAfiaAdminSideBar from "./BurjeelAdminSideBar.js";
import $ from "jquery";
import Success from "../../assets/images/success_gif.gif";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";

import "moment/locale/en-au";
import {
  server_post_data_shubham,
  GET_HOSPITAL_FARIDA,
  CREATE_HOSPITAL_FARIDA,
  city_list,
} from "../../KeyConnection/KeysService.js";
import {
  check_vaild_save,
  combiled_form_data,
  empty_form,
  handleAphabetsChange,
  handleEmailChange,
  handleIaphabetnumberChange,
  handleNumbersChange,
} from "../../CommonJquery/CommonJquery.js";

function AmraAdminCustomers() {
  const [userLandmarks, setuserLandmarks] = useState([""]);
  const [control, setControl] = useState(null);

  const handleAddLandmark = (e) => {
    e.preventDefault();
    setuserLandmarks([...userLandmarks, ""]);
  };
  const handleRemoveLandmark = (e) => {
    e.preventDefault();
    const updated = [...userLandmarks];
    updated.pop();
    setuserLandmarks(updated);
  };
  const [editoHostipallist, setEditHostipallist] = useState([]);
  const [Hostipallist, setHostipallist] = useState([]);
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const retrievedAdminId = "0";
  const [dynaicimage, setDynaicimage] = useState(null);
  const [showModal1, setShowModal1] = useState(false);
  const [editFlag, setEditFlag] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [citiesList, setCitiesList] = useState([]);
  const [selectedCity, setSelectedCity] = useState();
  const [country, setCountry] = useState("");
  const closeModal = () => {
    setShowModal(false);
  };

  const controlOptions = [
    { name: "Basic", code: "1" },
    { name: "Standard", code: "2" },
    { name: "Premium", code: "3" },
  ];

  const handleShowModal1 = (call_type) => {
    if (call_type === 0) {
      setEditHostipallist([]);
      setEditFlag(0);
      setCountry("");
      setSelectedCity();
    } else {
      setEditHostipallist(Hostipallist[call_type - 1]);
      setEditFlag(Hostipallist[call_type - 1].id);
      setControl(
        controlOptions[Hostipallist[call_type - 1].active_plan_id - 1]
      );
      setuserLandmarks(Hostipallist[call_type - 1].landmarks.split("@@@"));
      setCountry(Hostipallist[call_type - 1].country);
      citiesList.forEach((city) => {
        if (city.name == Hostipallist[call_type - 1].city) {
          console.log(city);
          setSelectedCity(city);
        }
      });
    }
    setShowModal1(true);
  };

  const handleCloseModal1 = () => {
    setShowModal1(false);
  };

  const handleCityChange = (val) => {
    setSelectedCity(val);
    console.log(val);
    setCountry(val.country_name);
  };

  useEffect(() => {
    const start_date = "";
    const end_date = "";
    const flag = "1";
    let call_id = "0";

    master_data_get(start_date, end_date, flag, call_id);
  }, []);

  useEffect(() => {
    if (Hostipallist.length > 0) {
      // Destroy the DataTable if it already exists
      if ($.fn.DataTable.isDataTable("#myTable")) {
        $("#myTable").DataTable().destroy();
      }
      // Initialize DataTable
      $("#myTable").DataTable();
    }
  }, [Hostipallist]);

  const master_data_get = async (start_date, end_date, flag, call_id) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("admin_id", retrievedAdminId);
    fd.append("start_date", start_date);
    fd.append("end_date", end_date);
    fd.append("flag", flag);
    fd.append("call_id", call_id);

    let hostiptal_list = [];
    await server_post_data_shubham(GET_HOSPITAL_FARIDA, fd)
      .then((Response) => {
        if (Response.data.error) {
          alert(Response.data.message);
        } else {
          hostiptal_list = Response.data.message.data_slider;
          console.log(hostiptal_list);
          setHostipallist(hostiptal_list);
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });

    const response3 = await fetch(city_list, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data3 = await response3.json();
    console.log(data3.data);
    hostiptal_list.forEach((hospital) => {
      const digitRegex = /\d/;
      if (digitRegex.test(hospital.city)) {
        data3.data.forEach((city) => {
          if (city.id == hospital.city) {
            hospital.city = city.name;
            hospital.country = city.country_name;
          }
        });
      }
    });
    setCitiesList(data3.data);
  };

  const handleSaveChangesdynamic = async (form_data, url_for_save) => {
    let vaild_data = check_vaild_save(form_data);
    if (vaild_data) {
      setshowLoaderAdmin(true);
      let fd_from = combiled_form_data(form_data, dynaicimage);
      fd_from.append("active_plan_id", control.code);
      fd_from.append("edit_flag", editFlag);
      fd_from.append("totallandmarkcount", userLandmarks.length);
      fd_from.append("city", selectedCity.id);
      await server_post_data_shubham(url_for_save, fd_from)
        .then((Response) => {
          console.log(Response.data.message);
          setshowLoaderAdmin(false);
          if (Response.data.error) {
            alert(Response.data.message);
          }
          if (!Response.data.error) {
            empty_form(form_data);
            handleCloseModal1();
            setShowModal(true);
            const start_date = "";
            const end_date = "";
            const flag = "1";
            let call_id = "0";

            master_data_get(start_date, end_date, flag, call_id);
          }
        })
        .catch((error) => {
          setshowLoaderAdmin(false);
        });
    }
  };

  const handleFileChangedynamic = (keyname) => (event) => {
    const file = event.target.files[0];

    let new_file_name = keyname + "_show";

    if (file) {
      const reader = new FileReader();

      reader.onload = () => {
        setDynaicimage((prevImages) => ({
          ...prevImages,
          [keyname]: file,
          [new_file_name]: reader.result,
        }));
      };
      reader.readAsDataURL(file);
    } else {
      setDynaicimage((prevImages) => ({
        ...prevImages,
        [keyname]: null,
        [new_file_name]: null,
      }));
    }
  };

  return (
    <div className="dashboard">
      <Helmet>
        <title>Farida Admin - Hospitals</title>
        <meta
          name="description"
          content="AI and Global Reach: The Key to Scalable Solutions in Customer
          Experience"
        />
      </Helmet>
      <div className="dashboard_container">
        <div
          className={showLoaderAdmin ? "loader-overlay show" : "loader-overlay"}
        >
          <div className="loader23"></div>
        </div>
        <div className="dashboard_wrapper">
          <NAfiaAdminSideBar />
          <div id="content">
            <CenterNavAmraAdmin />

            <div className="Dashpages_contaioner">
              <div className="overview mt-3">
                <div className="overviewContaINER">
                  <div className="row m-0">
                    <div className="col-md-8"></div>
                    <div className="col-md-4">
                      <div className="AddAgentBtn w-100 m-0">
                        <button
                          className="w-100"
                          style={{ height: "45px" }}
                          onClick={() => handleShowModal1(0)}
                        >
                          Add Hospital
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="revenueCall">
                    <div className="amradataTable">
                      <div className="amradataTable_container">
                        <h4>Hospital List</h4>
                        <table id="myTable" className="display">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Hospital Name</th>
                              <th>Hospital Location</th>
                              <th>Date of Onboarding</th>
                              <th>Specializes in</th>
                              <th>Subscription</th>
                              {/* <th>Commission Rate</th> */}
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Hostipallist &&
                              Hostipallist.map((item, index) => (
                                <tr key={index}>
                                  <td>
                                    <p style={{ color: "#000" }}>{index + 1}</p>
                                  </td>
                                  <td>
                                    <p style={{ color: "#000" }}>
                                      {item.hospital_name}
                                    </p>
                                  </td>
                                  <td>
                                    <p style={{ color: "#000" }}>
                                      {item.city}, {item.country}
                                    </p>
                                  </td>
                                  <td>
                                    <p style={{ color: "#000" }}>
                                      {item.created_at}
                                    </p>
                                  </td>
                                  <td style={{ color: "#000" }}>
                                    {item.specialization}
                                  </td>
                                  <td>
                                    <p style={{ color: "#000" }}>
                                      {item.active_plan_name}
                                    </p>
                                  </td>
                                  {/* <td>
                                    <p style={{ color: "#000" }}>
                                      {item.admin_commission}
                                    </p>
                                  </td> */}
                                  <td>
                                    <button
                                      className="editUsrContrlBtn"
                                      onClick={() =>
                                        handleShowModal1(index + 1)
                                      }
                                    >
                                      Edit
                                    </button>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showModal1}
        onHide={handleCloseModal1}
        dialogClassName="custom-modal2"
        centered
        size="xl"
      >
        <Modal.Header>
          <h5 className="mt-3">Add Hospital</h5>
        </Modal.Header>
        <Modal.Body>
          <div className="mngUserContainer px-5">
            <form className="needs-validation" id="CarrerformData">
              <div className="row m-0">
                <div className="col-md-6">
                  <div className="mngUserInput">
                    <label>Hospital Name</label>
                    <br />
                    <input
                      className="form-control  trio_mandatory"
                      name="hospital_name"
                      id="hospital_name"
                      maxLength={100}
                      onInput={handleAphabetsChange}
                      placeholder="Enter Hospital Name"
                      defaultValue={editoHostipallist?.hospital_name || ""}
                      type="text"
                    />
                    <span className="condition_error"></span>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mngUserInput">
                    <label>Popularly known as</label>
                    <br />
                    <input
                      className="form-control  trio_mandatory"
                      name="popular_name"
                      id="popular_name"
                      maxLength={100}
                      onInput={handleAphabetsChange}
                      placeholder="Enter Popularly known "
                      defaultValue={editoHostipallist?.popular_name || ""}
                      type="text"
                    />
                    <span className="condition_error"></span>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mngUserInput">
                    <label>Upload Image</label>
                    <br />
                    <input
                      className="inputEditFile"
                      type="file"
                      name="hospital_image"
                      onChange={handleFileChangedynamic("hospital_image")}
                      accept=".jpg,.jpeg,.png"
                    />
                  </div>
                </div>
                <div className="col-md-6 hidden" hidden>
                  <div className="mngUserInput">
                    <label>old Image</label>
                    <br />
                    <input
                      className="form-control  "
                      name="old_image_link"
                      id="old_image_link"
                      maxLength={100}
                      onInput={handleAphabetsChange}
                      placeholder="Enter Old Image"
                      defaultValue={editoHostipallist?.hospital_image || ""}
                      type="text"
                    />
                    <span className="condition_error"></span>
                  </div>
                </div>
                <div className="col-md-6 hidden" hidden>
                  <div className="mngUserInput">
                    <label>old Image</label>
                    <br />
                    <input
                      className="form-control  "
                      name="old_image_link"
                      id="old_image_link"
                      maxLength={100}
                      onInput={handleAphabetsChange}
                      placeholder="Enter Old Image"
                      defaultValue={editoHostipallist?.hospital_image || ""}
                      type="text"
                    />
                    <span className="condition_error"></span>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mngUserInput">
                    <label>Specializes in</label>
                    <br />
                    <input
                      className="form-control  trio_mandatory"
                      name="specialization"
                      id="specialization"
                      maxLength={100}
                      onInput={handleAphabetsChange}
                      placeholder="Enter Specializes in"
                      defaultValue={editoHostipallist?.specialization || ""}
                      type="text"
                    />
                    <span className="condition_error"></span>
                  </div>
                </div>
                {editoHostipallist.length === 0 && (
                <div className="col-md-6">
                  <div className="mngUserInput">
                    <label>Full Name</label>
                    <br />
                    <input
                      className="form-control  trio_mandatory"
                      name="full_name"
                      id="full_name"
                      maxLength={100}
                      onInput={handleAphabetsChange}
                      placeholder="Enter User Name"
                      defaultValue={editoHostipallist?.name || ""}
                      type="text"
                    />
                    <span className="condition_error"></span>
                  </div>
                </div>
                )}
                {editoHostipallist.length === 0 && (
                  <div className="col-md-6">
                    <div className="mngUserInput">
                      <label>Email</label>
                      <br />
                      <input
                        className="form-control  trio_mandatory trio_email"
                        name="email"
                        id="email"
                        maxLength={100}
                        onInput={handleEmailChange}
                        placeholder="Enter email"
                        defaultValue={editoHostipallist?.email || ""}
                        type="text"
                      />
                      <span className="condition_error"></span>
                    </div>
                  </div>
                )}
                <div className="col-md-6">
                  <div className="mngUserInput">
                    <label>Mobile No.</label>
                    <br />
                    <input
                      className="form-control  trio_mandatory trio_no"
                      name="mobile_no"
                      id="mobile_no"
                      maxLength={15}
                      onInput={handleNumbersChange}
                      placeholder="Enter Mobile No."
                      defaultValue={editoHostipallist?.mobile_no || ""}
                      type="text"
                    />
                    <span className="condition_error"></span>
                  </div>
                </div>
                {editoHostipallist.length === 0 && (
                  <div className="col-md-6">
                    <div className="mngUserInput">
                      <label>Password</label>
                      <br />
                      <input
                        className="form-control  trio_mandatory trio_password"
                        name="password"
                        id="password"
                        maxLength={15}
                        onInput={handleIaphabetnumberChange}
                        placeholder="Enter Password"
                        defaultValue={editoHostipallist?.password || ""}
                        type="password"
                      />
                      <span className="condition_error"></span>
                    </div>
                  </div>
                )}
                <div className="col-md-6">
                  <div className="mngUserInput">
                    <label>License Type</label>
                    <br />
                    <Dropdown
                      className="form-control d-flex"
                      style={{
                        border: "none",
                        backgroundColor: "#f2f0f0",
                        color: "#5c6066",
                      }}
                      name="active_plan_id"
                      id="active_plan_id"
                      value={control}
                      onChange={(e) => setControl(e.value)}
                      options={controlOptions}
                      optionLabel="name"
                      placeholder="Choose license"
                    />
                    <span className="condition_error"></span>
                  </div>
                </div>
                <div className="col-md-6" hidden>
                  <div className="mngUserInput">
                    <label>Total Commission</label>
                    <br />
                    <input
                      className="form-control  trio_mandatory"
                      name="admin_commission"
                      id="admin_commission"
                      maxLength={100}
                      onInput={handleNumbersChange}
                      placeholder="Enter Total Commission"
                      defaultValue={editoHostipallist?.admin_commission || ""}
                      type="text"
                    />
                    <span className="condition_error"></span>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mngUserInput mb-0">
                    <label>City</label>
                    <br />
                    <Dropdown
                      value={selectedCity}
                      onChange={(e) => handleCityChange(e.value)}
                      options={citiesList}
                      optionLabel="name"
                      placeholder="Select City"
                      className="form-control d-flex"
                      style={{
                        border: "none",
                        backgroundColor: "#f2f0f0",
                        color: "#5c6066",
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mngUserInput">
                    <label>Country</label>
                    <br />
                    <input
                      className="form-control  trio_mandatory"
                      name="country"
                      id="country"
                      maxLength={100}
                      onInput={handleAphabetsChange}
                      placeholder="Enter country"
                      disabled={country === "" ? false : true}
                      defaultValue={editoHostipallist?.country || country}
                      type="text"
                    />
                    <span className="condition_error"></span>
                  </div>
                </div>
                {/* <div className="col-md-6">
                  <div className="mngUserInput">
                    <label>City</label>
                    <br />
                    <input
                      className="form-control  trio_mandatory"
                      name="city"
                      id="city"
                      maxLength={100}
                      onInput={handleAphabetsChange}
                      placeholder="Enter city"
                      defaultValue={editoHostipallist?.city || ""}
                      type="text"
                    />
                    <span className="condition_error"></span>
                  </div>
                </div> */}
                <div className="col-md-6">
                  <div className="mngUserInput">
                    <label>Pincode</label>
                    <br />
                    <input
                      className="form-control  trio_mandatory"
                      name="pincode"
                      id="pincode"
                      maxLength={8}
                      onInput={handleNumbersChange}
                      placeholder="Enter Pincode"
                      defaultValue={editoHostipallist?.pincode || ""}
                      type="text"
                    />
                    <span className="condition_error"></span>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mngUserInput">
                    <label>Landmark</label>
                    <br />
                    {userLandmarks.map((landmark, index) => (
                      <div>
                        <input
                          className="form-control  trio_mandatory"
                          name={`landmarks${index}`}
                          id={`landmarks${index}`}
                          maxLength={100}
                          onInput={handleAphabetsChange}
                          placeholder="Enter Landmark"
                          defaultValue={
                            editoHostipallist?.landmarks ? landmark : ""
                          }
                          type="text"
                        />
                        <span className="condition_error"></span>
                      </div>
                    ))}
                    <button onClick={handleAddLandmark}>+</button>
                    {userLandmarks.length > 1 && (
                      <button onClick={handleRemoveLandmark}>-</button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="updateCnclebtnDiv">
            <button className="updateCnclebtnCan" onClick={handleCloseModal1}>
              Cancel
            </button>
            <button
              className="updateCnclebtnUp"
              onClick={() =>
                handleSaveChangesdynamic(
                  "CarrerformData",
                  CREATE_HOSPITAL_FARIDA
                )
              }
            >
              {editoHostipallist?.name ? "Update" : "Add"}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      {/* second modal */}
      <Modal
        id="myModal"
        show={showModal}
        onHide={closeModal}
        centered
        backdrop="static"
      >
        <Modal.Body className="modal_body">
          <div className="success_img d-flex justify-content-center">
            <img src={Success} alt="icon" />
          </div>
          <p>Hospital has been updated successfully</p>
        </Modal.Body>
        <Modal.Footer>
          <Link
            to="/burjeel_hospitals"
            onClick={() => window.location.reload(false)}
          >
            <div className="btn go_to_login">View</div>
          </Link>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default AmraAdminCustomers;
