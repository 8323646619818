import React, { useState } from "react";
import CompanyImg from "../assets/images/compnyImg.png";
import Footer from "./Footer";
import Header from "./Header";
import CompanyAboutImg from "../assets/images/companyAboutImg.png";
import coreTeamImg1 from "../assets/images/Adil.png";
import coreTeamImg2 from "../assets/images/Vaibhav.png";
import coreTeamImg3 from "../assets/images/Manoj.png";
import CertiImg1 from "../assets/images/certi1.svg";
import CertiImg2 from "../assets/images/certi2.svg";
import CertiImg3 from "../assets/images/certi3.svg";
import CertiImg4 from "../assets/images/certi4.svg";
import CertiImg5 from "../assets/images/certi5.svg";
import CertiImg6 from "../assets/images/certi6.svg";
import certificate1 from "../assets/images/bqccerti.png";
import certificate2 from "../assets/images/Certificate02.png";
import certificate3 from "../assets/images/Certificate03.png";
import ImagePresence from "../assets/images/ImagePresence.svg";
import EventsImg from "../assets/images/events.png";
import EventsImg2 from "../assets/images/event2.png";
import { Link } from "react-router-dom";
import MPnagar from "../assets/images/mpnagar.png";
import ITPark from "../assets/images/itpark.png";
import Jabalpur from "../assets/images/jabalpur.png";
import Raipur from "../assets/images/raipur.png";
import Rajnandgaon from "../assets/images/rajnandgoan.png";
import Cairo from "../assets/images/cairo.png";
import Mysuru from "../assets/images/mysuru.png";
import Lucknow from "../assets/images/lucknow.png";
import Vijaywada from "../assets/images/vijaywada.png";
import Chennai from "../assets/images/chennai.png";
import UAEImg from "../assets/images/UAE.png";
import USAImg from "../assets/images/USA.png";
import KSAImg from "../assets/images/KSA.png";
import UKImg from "../assets/images/UK.png";
import RogerImg from "../assets/images/Roger.png";
import RobertImg from "../assets/images/Robert.png";
import { Helmet } from "react-helmet";

function Company() {
  const locations = [
    {
      imgSrc: ImagePresence,
      city: "Bhopal (Ayodhya Bypass)",
      state: "Madhya Pradesh",
      country: "India",
    },
    {
      imgSrc: MPnagar,
      city: "Bhopal (MP Nagar)",
      state: "Madhya Pradesh",
      country: "India",
    },
    {
      imgSrc: ITPark,
      city: "Bhopal (IT Park)",
      state: "Madhya Pradesh",
      country: "India",
    },
    {
      imgSrc: Jabalpur,
      city: "Jabalpur",
      state: "Madhya Pradesh",
      country: "India",
    },
    {
      imgSrc: Raipur,
      city: "Raipur",
      state: "Chhattisgarh",
      country: "India",
    },
    {
      imgSrc: Rajnandgaon,
      city: "Rajnandgaon",
      state: "Chhattisgarh",
      country: "India",
    },
    {
      imgSrc: Cairo,
      city: "Cairo",
      state: "Egypt",
      country: "Egypt",
    },
    {
      imgSrc: Mysuru,
      city: "Mysuru",
      state: "Karnataka",
      country: "India",
    },
    {
      imgSrc: Lucknow,
      city: "Lucknow",
      state: "Uttar Pradesh",
      country: "India",
    },
    {
      imgSrc: Vijaywada,
      city: "Vijaywada",
      state: "Andhra Pradesh",
      country: "India",
    },
    {
      imgSrc: Chennai,
      city: "Chennai",
      state: "Tamil Nadu",
      country: "India",
    },
    {
      imgSrc: UAEImg,
      city: "UAE",
      state: "International",
      country: "International",
    },
    {
      imgSrc: USAImg,
      city: "USA",
      state: "International",
      country: "International",
    },
    {
      imgSrc: KSAImg,
      city: "KSA",
      state: "International",
      country: "International",
    },
    {
      imgSrc: UKImg,
      city: "UK",
      state: "International",
      country: "International",
    },
  ];

  const [selectedCountry, setSelectedCountry] = useState("");

  const handleCountryChange = (event) => {
    const newCountry = event.target.value;
    setSelectedCountry((prevCountry) =>
      prevCountry === newCountry ? "" : newCountry
    );
  };

  const countryOptions = ["India", "Egypt", "International"];

  return (
    <div className="company">
      <Helmet>
        <title>Our Company</title>
        <meta
          name="description"
          content="We Partner with MNCs for the optimization of their CX Strategies."
        />
      </Helmet>
      <header className="header_container">
        <Header />
      </header>
      <div className="company_container">
        <section className="welcome_screen main_welcome_screen container-lg m-auto my-5">
          <div className="welcome_screen_container">
            <div className="row wlcmContentRow">
              <div className="wlcm_left col-md-5 col-sm-5">
                <div className="wlcmText companyText">
                  <h2>
                    We Partner with MNCs for the optimization of their CX
                    Strategies.
                  </h2>
                  <p>
                    We assist multinational corporations in the improvement of
                    their CX strategies.
                  </p>
                  <div className="getInBtn">
                    <Link to="/book_demo">
                      <button>Book Demo Today</button>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="wlcm_right col-xl-6 col-lg-7 col-sm-7 col-11 m-auto">
                <div className="mainLandImg">
                  <img src={CompanyImg} alt="icon" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="product container-lg">
          <div className="product_container">
            <div className="productRow row">
              <div className="col-lg-5 col-md-6 d-flex align-items-center mx-auto">
                <div className="companyAboutImg">
                  <img src={CompanyAboutImg} alt="ABout" />
                </div>
              </div>
              <div className="col-md-6 mx-auto">
                <div className="companyAboutImg">
                  <h3>Who we are</h3>
                  <p>
                    At Technotask, we are the pioneers in transforming customer
                    experience globally. Since our inception in March 2020, we
                    have been on an exponential journey of innovation and
                    growth. We strive to deliver unparalleled client value
                    through outstanding omnichannel solutions which elevate CX
                    and drive excellence in business.
                  </p>
                  <p>
                    From our beginning in Bhopal (India), we have rapidly expanded our
                    presence by establishing 13 centres across UAE, Egypt and
                    India. Our diverse team, spanning across Colombia, the UAE, Saudi, KSA and
                    the US has proudly served 48 LOBs and 20 global clients. In
                    just 3 years, we have achieved an impressive growth of 400%.
                  </p>
                </div>
              </div>
              <div className="col-md-5"></div>
            </div>
          </div>
        </section>

        <section className="product container-lg">
          <div className="product_container">
            <div className="productRow productRow1 row">
              <div className="col-md-6 mx-auto">
                <div className="vissionGoal h-100">
                  <div className="vissionContainerDiv">
                    <div className="vissionContainer">
                      <h6>Vision</h6>
                      <p>
                        We envision a future where our organisation is the
                        undisputed leader in customer experience solutions which
                        is transformative on a global scale.
                      </p>
                    </div>
                    <div className="vissionContainer">
                      <h6>Goal</h6>
                      <p>
                        We aim to expand our global presence with the
                        establishment of 20 more centres by 2025 in key markets while
                        further diversifying our industry expertise.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mx-auto">
                <div className="missionDiv">
                  <h5>Mission</h5>
                  <p>
                    Our mission is to continuously revolutionise and elevate the
                    global CX landscape by delivering data-driven and innovative
                    omnichannel solutions.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="coreTeam">
          <div className="coreTeam_container container-lg">
            <div className="coreTeamHead">
              <h4>Meet Our Core Team</h4>
            </div>
            <div className="coreTeamCards">
              <div className="coreTeamCardsrow row">
                <div className="col-lg-3 col-sm-4 col-10 m-auto">
                  <div className="coreTeamCard">
                    <div className="coreTeamCardImg">
                      <img src={coreTeamImg1} alt="icon" />
                    </div>
                    <div className="coreTeamCardText">
                      <h6>Syed Adil Hussain</h6>
                      <div className="coreTeamCardDetails">
                        <p>(Founder & CEO)</p>
                        <p>18+ Years of experience across Industries</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-4 col-10 m-auto">
                  <div className="coreTeamCard">
                    <div className="coreTeamCardImg">
                      <img src={coreTeamImg2} alt="icon" />
                    </div>
                    <div className="coreTeamCardText">
                      <h6>P. Vaibhav Naidu</h6>
                      <div className="coreTeamCardDetails">
                        <p>(Founder & CFO)</p>
                        <p>17+ years of experience across IT/ ITeS</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-4 col-10 m-auto">
                  <div className="coreTeamCard">
                    <div className="coreTeamCardImg">
                      <img src={coreTeamImg3} alt="icon" />
                    </div>
                    <div className="coreTeamCardText">
                      <h6>Manoj Biswas</h6>
                      <div className="coreTeamCardDetails">
                        <p>(Founder & CBO)</p>
                        <p>25+ years of experience across industries</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="coreTeam" style={{ marginBottom: "2rem" }}>
          <div className="coreTeam_container container-lg">
            <div className="coreTeamHead">
              <h4 className="text-center">
                International Business development Liaisons
              </h4>
            </div>
            <div className="coreTeamCards">
              <div className="coreTeamCardsrow row">
                <div className="col-lg-6 col-md-8 col-sm-10 m-auto">
                  <div className="coreTeamCard RogerCard">
                    <div className="coreTeamCardImg col-sm-5 col-4">
                      <img src={RogerImg} alt="icon" />
                    </div>
                    <div className="coreTeamCardText RogerCardText">
                      <h6>Roger Lundgren</h6>
                      <div className="coreTeamCardDetails">
                        <p>( Coordinator Europe & Australia )</p>
                        <p>
                          He is a Swedish businessman with more than 20 years of
                          experience in business transition and development in
                          the BPO and startup ecosystems
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-8 col-sm-10 m-auto">
                  <div className="coreTeamCard RogerCard">
                    <div className="coreTeamCardImg col-sm-5 col-4">
                      <img src={RobertImg} alt="icon" />
                    </div>
                    <div className="coreTeamCardText RogerCardText">
                      <h6>Robert Merill Fletcher</h6>
                      <div className="coreTeamCardDetails">
                        <p>( Director AI - USA )</p>
                        <p>
                          Robert is a C-Level executive with extensive
                          experience in AI solutions for multinational
                          corporations.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="BDLsection"></section>

        <section className="caseStudies">
          <div className="caseStudies_container container-lg">
            <div className="caseStudiesHead">
              <p>CASE STUDIES</p>
              <h5>Our work speaks for itself</h5>
            </div>
            <div className="certificationsDiv">
              <div className="certificationsDivRow row">
                <div className="certiImg col-md-2 col-4">
                  <img src={CertiImg1} alt="Certificate" />
                </div>
                <div className="certiImg col-md-2 col-4">
                  <img src={CertiImg2} alt="Certificate" />
                </div>
                <div className="certiImg col-md-2 col-4">
                  <img src={CertiImg3} alt="Certificate" />
                </div>
                <div className="certiImg col-md-2 col-4">
                  <img src={CertiImg4} alt="Certificate" />
                </div>
                <div className="certiImg col-md-2 col-4">
                  <img src={CertiImg5} alt="Certificate" />
                </div>
                <div className="certiImg col-md-2 col-4">
                  <img src={CertiImg6} alt="Certificate" />
                </div>
              </div>
            </div>

            <div className="emptyHeight"></div>

            <div className="cartificateSection">
              <div className="cartificateContainer">
                <div className="row">
                  <div className="col-md-3 col-sm-4 col-4 m-auto">
                    <div className="certificate">
                      <img src={certificate1} alt="Certificaste" />
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-4 col-4 m-auto">
                    <div className="certificate">
                      <img src={certificate2} alt="Certificaste" />
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-4 col-4 m-auto">
                    <div className="certificate">
                      <img src={certificate3} alt="Certificaste" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="emptyHeight"></div>

        <section className="presence">
          <div className="presence_container container-lg">
            <div className="presenceHead">
              <h4>Our Presence</h4>
            </div>
            <div className="presenceFilterBar">
              <div className="presenceFilter">
                <div className="Presence_bar">
                  <div className="Presence_bar_div col-lg-10 m-auto">
                    <div className="barHeadr d-flex" style={{ gap: "1rem" }}>
                      <h6>Location</h6>
                      <h6>Filter by:</h6>
                    </div>
                    <div>
                      <form className="filterForm">
                        {countryOptions.map((country, index) => (
                          <label key={index}>
                            {country}
                            <input
                              type="radio"
                              name="location"
                              value={country}
                              onChange={handleCountryChange}
                              checked={selectedCountry === country}
                            />
                          </label>
                        ))}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="presenceCountries">
              <div className="presenceCountriesContainer">
                <div className="presenceCountriesrow row">
                  {locations.map((location, index) => {
                    if (
                      selectedCountry === "" ||
                      location.country === selectedCountry
                    ) {
                      return (
                        <div
                          key={index}
                          className="presenceCountriesimage col-lg-3 col-md-3 col-sm-3 col-6 m-auto"
                        >
                          <img src={location.imgSrc} alt={index + 1} />
                          <div className="presenceCountriesText">
                            <p>{location.city}</p>
                            <p className="stateText">{location.state}</p>
                          </div>
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="events">
          <div className="events_container container-lg">
            <div className="eventsHead col-sm-5">
              <h1>Our Events</h1>
              <p>
                The more you praise and celebrate your life, the more there is
                in life to celebrate.
              </p>
            </div>
            <div className="enevtsImg col-12">
              <img src={EventsImg} className="d-none d-sm-block" alt="Events" />
              <img
                src={EventsImg2}
                className="d-block d-sm-none"
                alt="Events"
              />
            </div>
          </div>
        </section>

        <section className="JoinOurTeam">
          <div className="JoinOurTeam_container container-lg">
            <div className="JoinOurTeamHead">
              <h1>Join our team to be a part of our story</h1>
              <div className="join_text">
                "Embark on your journey with us, where you'll not just be a part
                of our story, but a cherished member of a team that fosters your
                skills, nurtures your growth, and aligns with your career
                ambitions."
              </div>
              <Link to="/careers">
                <button className="joinNow_btn">Join Now</button>
              </Link>
            </div>
          </div>
        </section>
      </div>
      <div className="px-lg-5 px-md-4 px-sm-3 px-1">
        <Footer />
      </div>
    </div>
  );
}

export default Company;
