import React, { useState, useEffect, useRef } from "react";
import "../Css/Dashboard.css";
import SideBar from "./SideBar";
import Group290 from "../../assets/images/Group 290.png";
import Expand1 from "../../assets/images/expand 1.svg";
import MoreIcon from "../../assets/images/more_vertical.svg";
import { Line, Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, registerables } from "chart.js";
import "react-circular-progressbar/dist/styles.css";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import chillGreen from "../../assets/images/Rounded.svg";
import PhoneImg from "../../assets/images/phone23.svg";
import VRline from "../../assets/images/vrLine.svg";
import OrngImg from "../../assets/images/orangeProfile.svg";
import ProgressBar from "./ProgressBar";
import MyChart from "./Mychart";
import Calender from "../../assets/images/calendar12.svg";
import Technotask from "../../assets/images/TechnotaskLogo12.png";
import calenderImg from "../../assets/images/calendar12white.svg";
import { Helmet } from "react-helmet";
import $ from "jquery";
import moment from "moment";
import "moment/locale/en-au";
import "daterangepicker";
import {
  csv_amra_user_upload,
  city_list,
  get_debtor,
  server_post_data_shubham,
  get_all_Debtorprofile,
  API_LINK_KEY,
} from "../../KeyConnection/KeysService.js";
import Logout from "../../assets/images/giflogout.gif";
import CenterNavbar from "./CenterNavbar";
import { Link } from "react-router-dom";
import de from "faker/lib/locales/de/index.js";

function Dashboard() {
  const [isLoading, setLoading] = useState(false);
  const [timeList, SettimeList] = useState([]);
  const company_id = localStorage.getItem("company_id");
  const [allButtonActive, setAllButtonActive] = useState(true);
  const [datePickerActive, setDatePickerActive] = useState(false);

  const [debtorDataForDateRange, setDebtorDataForDateRange] = useState({
    debtor_emi_amt: 0,
    recovery_amount: 0,
    outstanding_amount: 0,
    dispute_amt: 0,
    admin_commission: 0,
    total_calls_made: 0,
    answered_calls: 0,
    dropped_calls: 0,
    pre_bucket_user_count: 0,
    days_30_bucket_user_count: 0,
    days_60_bucket_user_count: 0,
    days_90_bucket_user_count: 0,
  });

  const [debtorDataCityWise, setDebtorDataCityWise] = useState();
  const [RUPESS_ICON, setRUPESS_ICON] = useState();

  const [debtorDataBucketWise, setDebtorDataBucketWise] = useState([
    {
      name: "Pre Due Bucket",
      live_calls: 0,
      recovery_amt: 0,
    },
    {
      name: "30 Days Bucket",
      live_calls: 0,
      recovery_amt: 0,
    },
    {
      name: "60 Days Bucket",
      live_calls: 0,
      recovery_amt: 0,
    },
    {
      name: "90 Days Bucket",
      live_calls: 0,
      recovery_amt: 0,
    },
  ]);

  const [datarecovry, setDataRecovery] = useState({
    labels: [
      "Pre Due Bucket",
      "30 Days Bucket",
      "60 Days Bucket",
      "90 Days Bucket",
    ],
    datasets: [
      {
        data: [10, 45, 13, 32],
        backgroundColor: ["#FF007A", "#4623E9", "#0070C0", "#DCD9E8"],
      },
    ],
  });

  const optionsrecovry = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: "60%",
    plugins: {
      legend: {
        display: false, // Hide legend
      },
    },
    scales: {
      x: {
        display: false, // Hide x-axis
      },
      y: {
        display: false, // Hide y-axis
      },
    },
    elements: {
      arc: {
        backgroundColor: "transparent", // Set the background color to transparent
      },
    },
  };

  const [debtorDataMonthWise, setDebtorDataForMonthWise] = useState({
    TOTAL: [0, 0, 0, 0, 0, 0],
    COLLECTION: [0, 0, 0, 0, 0, 0],
    PENDING: [0, 0, 0, 0, 0, 0],
    total_pending: 0,
    total_collection: 0,
    total_amount: 0,
    success_rate: 0,
  });

  const [last6MonthsData, setLast6MonthsData] = useState({
    labels: ["January", "February", "March", "April", "May", "June"],
    datasets: [
      {
        label: "TOTAL",
        data: [30, 38, 10, 15, 43, 10, 27],
        borderColor: "rgb(75, 192, 192)",
        tension: 0.37,
        pointRadius: 2,
        fill: false,
      },
      {
        label: "COLLECTION",
        data: [40, 48, 8, 43, 10, 47, 30],
        borderColor: "rgb(255, 99, 132)",
        tension: 0.37,
        pointRadius: 2,
        fill: false,
      },
      {
        label: "PENDING",
        data: [20, 24, 19, 42, 14, 35, 32],
        borderColor: "rgb(255, 205, 86)",
        tension: 0.37,
        pointRadius: 2,
        fill: false,
      },
    ],
  });

  const options = {
    scales: {
      x: {
        title: {
          display: false,
        },
      },
      y: {
        title: {
          display: false,
        },
        ticks: {
          min: 30000,
          max: 110000,
          stepSize: 20000,
          precision: 0,
        },
      },
    },
    plugins: {
      tooltip: {
        mode: "nearest",
      },
    },
  };

  const master_data_get = async (
    start_date,
    end_date,
    flag,
    call_id,
    dateRange
  ) => {
    setLoading(true);
    const fd = new FormData();
    fd.append("admin_id", company_id);
    fd.append("start_date", start_date);
    fd.append("end_date", end_date);
    fd.append("flag", flag);
    fd.append("call_id", call_id);
    await server_post_data_shubham(`${get_all_Debtorprofile}${company_id}/`, fd)
      .then((Response) => {
        if (Response.data.error) {
          alert(Response.data.message);
        } else {
          const city_wise_data = Response.data.message.data_silder_image2;
          SettimeList(city_wise_data);
          setRUPESS_ICON(Response.data.message.icon_money);
          let amount_plus = 0;
          let data = Response.data.message.data_debtors;
          const month_wise_data = {
            TOTAL: [0, 0, 0, 0, 0, 0],
            COLLECTION: [0, 0, 0, 0, 0, 0],
            PENDING: [0, 0, 0, 0, 0, 0],
            total_pending: 0,
            total_collection: 0,
            total_amount: 0,
            success_rate: 0,
          };

          const obj = {
            debtor_emi_amt: 0,
            recovery_amount: 0,
            outstanding_amount: 0,
            dispute_amt: 0,
            admin_commission: 0,
            total_calls_made: 0,
            answered_calls: 0,
            dropped_calls: 0,
            pre_bucket_user_count: 0,
            days_30_bucket_user_count: 0,
            days_60_bucket_user_count: 0,
            days_90_bucket_user_count: 0,
          };

          const bucket_wise_data = [
            {
              name: "Pre Due Bucket",
              live_calls: 0,
              recovery_amt: 0,
            },
            {
              name: "30 Days Bucket",
              live_calls: 0,
              recovery_amt: 0,
            },
            {
              name: "60 Days Bucket",
              live_calls: 0,
              recovery_amt: 0,
            },
            {
              name: "90 Days Bucket",
              live_calls: 0,
              recovery_amt: 0,
            },
          ];

          const [months_idx, months_name] = getLastSixMonths(dateRange);
          for (let i = 0; i < data.length; i++) {
            const debtor = data[i];
            if (
              dateRange?.startDate <= new Date(debtor.created_at) &&
              dateRange?.endDate >= new Date(debtor.created_at)
            ) {
              // if (1 === 1) {
              // cities
              let city = city_wise_data.filter((item) => {
                return debtor.city_id === item.id;
              });

              // calculate bucket using due data for filtered dates
              let now = Date.now();
              let dueDate = new Date(debtor.due_date);
              if (debtor.last_payment_date) {
                dueDate = new Date(debtor.last_payment_date);
              }

              amount_plus += Number(debtor.debtor_emi_amt);
              debtor.debtor_history.forEach((instance) => {
                if (debtor.debtor_history.length > 0) {
                  if (instance.debtor_bucket === "pre due") {
                    obj.pre_bucket_user_count += 1;
                  } else if (instance.debtor_bucket === "30 days") {
                    obj.days_30_bucket_user_count += 1;
                  } else if (instance.debtor_bucket === "60 days") {
                    obj.days_60_bucket_user_count += 1;
                  } else if (instance.debtor_bucket === "90 days") {
                    obj.days_90_bucket_user_count += 1;
                  }

                  obj.debtor_emi_amt += Number(instance.debtor_emi_amt);
                  obj.recovery_amount += Number(instance.recovery_amount);
                  obj.dispute_amt += Number(instance.dispute_amt);
                  obj.admin_commission += Number(instance.admin_commission);

                  // call_flag = 0, 1, 2 : 0 => call not made, 1 => call made, 2 => answered call
                  if (Number(instance.call_flag) > 0) {
                    obj.total_calls_made += 1;
                  }
                  if (instance.call_flag >= 2) {
                    obj.answered_calls += 1;
                  }

                  city[0].debtor_emi_amt += Number(instance.debtor_emi_amt);
                  city[0].recovery_amount +=
                    Number(instance.recovery_amount) || 0;
                }
              });
            }

            // calculate bucket using due data (for bucket wise data)
            let now = Date.now();
            let dueDate = new Date(debtor.due_date);
            if (debtor.last_payment_date) {
              dueDate = new Date(debtor.last_payment_date);
            }
            let days = Math.ceil((now - dueDate) / (1000 * 60 * 60 * 24));
            let bucket_idx = 0;
            if (days <= 0) {
              bucket_idx = 0;
            } else if (days > 0 && days <= 30) {
              bucket_idx = 1;
            } else if (days > 30 && days <= 60) {
              bucket_idx = 2;
            } else if (days > 60) {
              bucket_idx = 3;
            }

            let target_bucket = bucket_wise_data[bucket_idx];
            if (debtor.live_call === 1) {
              target_bucket.live_calls += 1;
            }

            // month-wise (outside date range condition)
            let emi_month = new Date(debtor.due_date).getMonth();
            let target_idx = months_idx.findIndex(
              (month) => month === emi_month
            );

            debtor.debtor_history.forEach((instance) => {
              if (debtor.debtor_history.length > 0) {
                if (instance.debtor_bucket === "30 days") {
                  bucket_idx = 1;
                } else if (instance.debtor_bucket === "60 days") {
                  bucket_idx = 2;
                } else if (instance.debtor_bucket === "90 days") {
                  bucket_idx = 2;
                }
                let target_bucket = bucket_wise_data[bucket_idx];
                target_bucket.recovery_amt += Number(instance.recovery_amount);
                if (target_idx !== -1) {
                  console.log(instance.debtor_emi_amt);
                  month_wise_data["TOTAL"][target_idx] += Number(
                    instance.debtor_emi_amt
                  );
                  month_wise_data["COLLECTION"][target_idx] += Number(
                    instance.recovery_amount
                  );
                  month_wise_data["PENDING"][target_idx] =
                    month_wise_data["TOTAL"][target_idx] -
                    month_wise_data["COLLECTION"][target_idx];
                }
              }
            });
          }

          obj.outstanding_amount = obj.debtor_emi_amt - obj.recovery_amount;
          obj.dropped_calls = obj.total_calls_made - obj.answered_calls;

          city_wise_data.forEach((city) => {
            city.average_recovery_rate =
              parseFloat(
                ((city.recovery_amount / city.debtor_emi_amt) * 100).toFixed(2)
              ) || 0;
          });

          setDataRecovery({
            labels: [
              "Pre Due Bucket",
              "30 Days Bucket",
              "60 Days Bucket",
              "90 Days Bucket",
            ],
            datasets: [
              {
                data: [
                  bucket_wise_data[0].recovery_amt,
                  bucket_wise_data[1].recovery_amt,
                  bucket_wise_data[2].recovery_amt,
                  bucket_wise_data[3].recovery_amt,
                ],
                backgroundColor: ["#FF007A", "#4623E9", "#0070C0", "#DCD9E8"],
              },
            ],
          });

          setDebtorDataBucketWise(bucket_wise_data);
          setDebtorDataForDateRange(obj);
          setDebtorDataCityWise(city_wise_data);

          console.log(month_wise_data);

          month_wise_data["PENDING"].forEach((amt) => {
            month_wise_data.total_pending += amt;
          });
          month_wise_data["TOTAL"].forEach((amt) => {
            month_wise_data.total_amount += amt;
          });
          month_wise_data["COLLECTION"].forEach((amt) => {
            month_wise_data.total_collection += amt;
          });
          if (month_wise_data.total_collection !== 0) {
            month_wise_data.success_rate = parseFloat(
              (
                (month_wise_data.total_collection * 100) /
                month_wise_data.total_amount
              ).toFixed(2)
            );
          }

          setDebtorDataForMonthWise(month_wise_data);

          const last6Months = {
            labels: months_name,
            datasets: [
              {
                label: "TOTAL",
                data: month_wise_data["TOTAL"],
                borderColor: "rgb(75, 192, 192)",
                tension: 0.37,
                pointRadius: 2,
                fill: false,
              },
              {
                label: "COLLECTION",
                data: month_wise_data["COLLECTION"],
                borderColor: "rgb(255, 99, 132)",
                tension: 0.37,
                pointRadius: 2,
                fill: false,
              },
              {
                label: "PENDING",
                data: month_wise_data["PENDING"],
                borderColor: "rgb(255, 205, 86)",
                tension: 0.37,
                pointRadius: 2,
                fill: false,
              },
            ],
          };

          setLast6MonthsData(last6Months);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  ChartJS.register(...registerables);

  const getLastSixMonths = (dateRange) => {
    const currentDate = new Date(dateRange?.endDate);
    const months_idx = [];
    const months_name = [];
    for (let i = 5; i >= 0; i--) {
      const month = new Date(currentDate);
      if (currentDate.getMonth() - i < 0) {
        months_idx.push(currentDate.getMonth() - i + 12);
      } else {
        months_idx.push(currentDate.getMonth() - i);
      }
      month.setMonth(currentDate.getMonth() - i);
      months_name.push(month.toLocaleString("default", { month: "long" }));
    }
    return [months_idx, months_name];
  };

  const inputRef = useRef(null);

  const handleDateRangeChange = (start, end, label) => {
    // Callback function for handling date range changes
    let startDate = start.format("DD-MM-YYYY").toString();
    let endDate = end.format("DD-MM-YYYY").toString();

    let dates = {
      startDate: new Date(start._d),
      endDate: new Date(end._d),
    };

    const start_date = "";
    const end_date = "";
    const flag = "2";
    let call_id = "0";
    master_data_get(start_date, end_date, flag, call_id, dates);
  };

  useEffect(() => {
    // Call the function to fetch data when the component mount

    const datepicker = $(inputRef.current);
    if (datePickerActive) {
      datepicker.daterangepicker(
        {
          timePicker: true,
          startDate: moment().subtract(7, "days").startOf("day"), // Set start date to 7 days ago
          endDate: moment().endOf("day"), // Set end date to today
          locale: {
            format: "DD/MM/YYYY",
          },
        },
        handleDateRangeChange
      );
    }

    // Call handleDateRangeChange on component load
    const startDate =
      datepicker?.data("daterangepicker")?.startDate ||
      moment().subtract(365, "days").startOf("day");
    const endDate =
      datepicker?.data("daterangepicker")?.endDate ||
      moment().add(2, "days").endOf("day");
    handleDateRangeChange(startDate, endDate);

    // Call the function to fetch data when the component mounts
    // showAmradataonDashboard(csv_amra_user_upload);
    // showAmradataonUserCountrywise(URL_FOR_USERDATCOUNTRYWISE);

    const cards = document.querySelectorAll(".samecardHeight");
    let maxHeight = 0;

    cards.forEach((card) => {
      const cardHeight = card.offsetHeight;
      if (cardHeight > maxHeight) {
        maxHeight = cardHeight;
      }
    });

    // Apply the maximum height to all cards
    cards.forEach((card) => {
      card.style.height = `${maxHeight}px`;
    });
  }, [datePickerActive]);

  const handleLoader = () => {
    setLoading(true);
  };

  const [isZoomedIn, setIsZoomedIn] = useState(false);

  const toggleZoom = () => {
    setIsZoomedIn(!isZoomedIn);
  };

  const [isZoomedInRev, setIsZoomedInRev] = useState(false);

  const toggleZoomRev = () => {
    setIsZoomedInRev(!isZoomedInRev);
  };

  const [isZoomedInCall, setIsZoomedInCall] = useState(false);

  const toggleZoomCall = () => {
    setIsZoomedInCall(!isZoomedInCall);
  };

  return (
    <div className="dashboard">
      <Helmet>
        <title>Amra User Dashboard</title>
        <meta
          name="description"
          content="AI and Global Reach: The Key to Scalable Solutions in Customer
          Experience"
        />
      </Helmet>
      <div className="dashboard_container">
        <div className={isLoading ? "loader-overlay show" : "loader-overlay"}>
          <div className="loader23"></div>
        </div>
        <div className="dashboard_wrapper">
          <SideBar />
          <div id="content">
            <CenterNavbar />

            <div className="Dashpages_contaioner">
              <div className="overview">
                <div className="overviewContaINER">
                  <div className="overviewHead overviewHeaddp">
                    <h4>Overview</h4>
                    {/* Loader button */}
                    {/* <button onClick={handleLoader} className="btn btn-primary">
                      Loader
                    </button> */}
                    <div className="col-md-4">
                      <div className="row m-0">
                        <div className="col-md-3">
                          <div className="date_filter_div h-100">
                            <div
                              className="date_filter w-100 h-100"
                              onClick={() => {
                                setAllButtonActive(true);
                                setDatePickerActive(false);
                              }}
                              style={{
                                cursor: "pointer",
                                backgroundColor: allButtonActive
                                  ? "#7e32cb"
                                  : "#ffffff",
                                color: allButtonActive ? "#ffffff" : "#212529",
                                justifyContent: "center",
                                paddingLeft: "0",
                              }}
                            >
                              All
                            </div>
                          </div>
                        </div>
                        <div className="col-md-9">
                          <div
                            className="date_filter_div"
                            onClick={() => {
                              setAllButtonActive(false);
                              setDatePickerActive(true);
                            }}
                            style={{
                              cursor: "pointer",
                            }}
                          >
                            {" "}
                            {datePickerActive ? (
                              <div
                                className="date_filter w-100"
                                style={{
                                  backgroundColor: "#7e32cb",
                                  color: "#ffffff",
                                }}
                              >
                                <div className="calender_icon">
                                  <label
                                    style={{ cursor: "pointer" }}
                                    htmlFor="datepicker"
                                  >
                                    <img
                                      style={{ paddingLeft: "12px" }}
                                      src={calenderImg}
                                      alt="Image"
                                    />
                                  </label>
                                </div>
                                <input
                                  id="datepicker"
                                  className="form-control form-control-solid"
                                  placeholder="Pick date range"
                                  ref={inputRef}
                                  style={{
                                    backgroundColor: "#7e32cb",
                                    color: "#ffffff",
                                  }}
                                />
                              </div>
                            ) : (
                              <div
                                className="date_filter w-100"
                                style={{
                                  padding: "0.5rem",
                                  justifyContent: "center",
                                }}
                              >
                                Choose Date
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="valueCards">
                    <div className="valueCardsrow row m-0">
                      <div className="valueCardpad  col-md-3 m-auto">
                        <div className="valueCard samecardHeight">
                          <p>Total Outstanding Amount</p>
                          <h4>
                            <span style={{ color: "rgba(252, 145, 43, 1)" }}>
                              {RUPESS_ICON}
                            </span>{" "}
                            {debtorDataForDateRange?.outstanding_amount}
                          </h4>
                        </div>
                      </div>
                      <div className="valueCardpad  col-md-3 m-auto">
                        <div className="valueCard samecardHeight">
                          <p>Total Recoverd Amount</p>
                          <h4>
                            <span style={{ color: "rgba(252, 145, 43, 1)" }}>
                              {RUPESS_ICON}
                            </span>{" "}
                            {debtorDataForDateRange?.recovery_amount}
                          </h4>
                        </div>
                      </div>
                      <div className="valueCardpad  col-md-6 m-auto">
                        <div className="valueCard samecardHeight d-flex justify-content-around">
                          <div className="valueCardInside">
                            <p>Total Call Made</p>
                            <h4> {debtorDataForDateRange?.total_calls_made}</h4>
                          </div>
                          <img src={VRline} className="px-2" alt="icon" />
                          <div className="valueCardInside">
                            <p>Answered Calls</p>
                            <h4> {debtorDataForDateRange?.answered_calls}</h4>
                          </div>
                          <img src={VRline} className="px-2" alt="icon" />
                          <div className="valueCardInside">
                            <p>Dropped Calls</p>
                            <h4> {debtorDataForDateRange?.dropped_calls}</h4>
                          </div>
                        </div>
                      </div>
                      <div className="valueCardpad col-md-3 m-auto">
                        <div className="valueCard">
                          <p>Pre Due Bucket</p>
                          <div className="valuTxt">
                            <img src={OrngImg} alt="icon" />
                            <h4>
                              {debtorDataForDateRange?.pre_bucket_user_count}
                              {debtorDataForDateRange?.pre_bucket_user_count ===
                              1 ? (
                                <span style={{ fontSize: "12px" }}> user</span>
                              ) : (
                                <span style={{ fontSize: "12px" }}> users</span>
                              )}
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div className="valueCardpad col-md-3 m-auto">
                        <div className="valueCard">
                          <p>30 Days Bucket</p>
                          <div className="valuTxt">
                            <img src={OrngImg} alt="icon" />
                            <h4>
                              {
                                debtorDataForDateRange?.days_30_bucket_user_count
                              }
                              {debtorDataForDateRange?.days_30_bucket_user_count ===
                              1 ? (
                                <span style={{ fontSize: "12px" }}> user</span>
                              ) : (
                                <span style={{ fontSize: "12px" }}> users</span>
                              )}
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div className="valueCardpad col-md-3 m-auto">
                        <div className="valueCard">
                          <p>60 Days Bucket </p>
                          <div className="valuTxt">
                            <img src={OrngImg} alt="icon" />
                            <h4>
                              {
                                debtorDataForDateRange?.days_60_bucket_user_count
                              }
                              {debtorDataForDateRange?.days_60_bucket_user_count ===
                              1 ? (
                                <span style={{ fontSize: "12px" }}> user</span>
                              ) : (
                                <span style={{ fontSize: "12px" }}> users</span>
                              )}
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div className="valueCardpad col-md-3 m-auto">
                        <div className="valueCard">
                          <p>90 Days Bucket </p>
                          <div className="valuTxt">
                            <img src={OrngImg} alt="icon" />
                            <h4>
                              {
                                debtorDataForDateRange?.days_90_bucket_user_count
                              }
                              {debtorDataForDateRange?.days_90_bucket_user_count ===
                              1 ? (
                                <span style={{ fontSize: "12px" }}> user</span>
                              ) : (
                                <span style={{ fontSize: "12px" }}> users</span>
                              )}
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="revenueCall">
                    <div className="row m-0">
                      <div className="col-md-8 p-0">
                        <div className="valueCardpad valueCardpad1">
                          <div className="valueCard d-flex justify-content-around">
                            <div className="valueCardInside">
                              <p>
                                Amount payable to Technotask
                                <div style={{ fontSize: "10px" }}>
                                  (based on successful collections)
                                </div>
                              </p>
                              <h4>
                                {RUPESS_ICON}{" "}
                                {debtorDataForDateRange?.admin_commission}
                              </h4>
                            </div>
                            <img src={VRline} alt="icon" />
                            <div className="valueCardInside">
                              <p>
                                Dispute Amount
                                <div style={{ opacity: "0", fontSize: "10px" }}>
                                  (based)
                                </div>
                              </p>
                              <h4>
                                {" "}
                                {RUPESS_ICON}{" "}
                                {debtorDataForDateRange?.dispute_amt}
                              </h4>
                            </div>
                          </div>
                        </div>

                        <div
                          className={`col-12 ${isZoomedIn ? "zoomedIn" : ""}`}
                        >
                          <div
                            className={`usersContainer mb-2 ${
                              isZoomedIn ? "zoomedIn" : ""
                            }`}
                          >
                            <div className="revenueCallHead revenueCallHead2 ">
                              <div className="revenueDIv noneBorderDiv">
                                <img
                                  src={Expand1}
                                  alt="icon"
                                  onClick={toggleZoom}
                                />
                                <div className="revenueText">
                                  <p>Call (Audience Map Location)</p>
                                  <p className="rtext2">Report Center</p>
                                </div>
                              </div>
                              <div
                                className="moreOption"
                                style={{ paddingRight: "1rem" }}
                                hidden
                              >
                                <img src={MoreIcon} alt="icon" />
                              </div>
                            </div>
                            <div className="mapLocation">
                              <div className="mapLocation_contaINER">
                                <div
                                  className="row"
                                  style={{ display: "flex" }}
                                >
                                  <div className="col-md-5">
                                    <div className="countryRecovry">
                                      <ul>
                                        {debtorDataCityWise?.map(
                                          (cityData, index) => (
                                            <li key={index}>
                                              <div className="countryRecovryItem">
                                                <div className="cntryRcvryItemLeft">
                                                  <div className="cntryRcvryItemLeftHead">
                                                    <h6>{cityData?.name}</h6>
                                                    <p>
                                                      {cityData?.total_debtors}{" "}
                                                      Users
                                                    </p>
                                                  </div>
                                                  <div className="cntryRcvryProBar">
                                                    <ProgressBar
                                                      done={
                                                        cityData?.average_recovery_rate
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                                <div className="cntryRcvryItemRight">
                                                  <div className="cntryRcvryItemRigthHead">
                                                    <p>Recovery Rate</p>
                                                    <p className="rcvrypercnt">
                                                      {
                                                        cityData?.average_recovery_rate
                                                      }
                                                      %
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                            </li>
                                          )
                                        )}
                                      </ul>
                                    </div>
                                  </div>
                                  <div className="col-md-7">
                                    <MyChart calldata={timeList} />
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* <div className="revenueCallFoot">
                              <Link to="/CallDetailView">
                                <button>
                                  <span>SEE DETAILS</span>
                                  <span>
                                    <img src={Right} alt="icon" />
                                  </span>
                                </button>
                              </Link>
                            </div> */}
                          </div>
                        </div>

                        <div
                          className={`col-12 ${
                            isZoomedInRev ? "zoomedIn" : ""
                          }`}
                        >
                          <div
                            className={`revenueCall_container graphsRevenueDiv ${
                              isZoomedInRev ? "zoomedIn" : ""
                            }`}
                            style={{ alignSelf: "stretch" }}
                          >
                            <div className="revenueCallHead">
                              <div className="revenueDIv">
                                <img
                                  src={Expand1}
                                  alt="icon"
                                  onClick={toggleZoomRev}
                                />
                                <div className="revenueText">
                                  <p>Revenue</p>
                                  <p className="rtext2">Report Center</p>
                                </div>
                              </div>

                              <div className="outAmount w-100">
                                <div className="outAmountDiv">
                                  <div
                                    className="d-flex align-items-center"
                                    style={{ gap: "0.5rem" }}
                                  >
                                    <p>
                                      Total Outstanding <br />
                                      Amount
                                    </p>
                                    <h4>
                                      {RUPESS_ICON}
                                      {debtorDataMonthWise?.total_pending}{" "}
                                    </h4>
                                  </div>
                                  <div className="moreOption" hidden>
                                    <img src={MoreIcon} alt="icon" />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row m-0">
                              <div className="lineChartCOl col-xl-8 m-auto">
                                <Line
                                  className="canvas"
                                  data={last6MonthsData}
                                  options={options}
                                  responsive={true}
                                  maintainAspectRatio={false}
                                />
                              </div>
                              <div className="circleProgBar col-xl-4 m-auto">
                                <div className="collecBAr">
                                  <div style={{ width: "60px" }}>
                                    <CircularProgressbar
                                      value={debtorDataMonthWise?.success_rate}
                                      text={`${debtorDataMonthWise?.success_rate.toFixed(
                                        0
                                      )}%`}
                                      styles={buildStyles({
                                        textColor: "#fff", // Color of the text
                                        trailColor: `#9D7EF9`, // Color of the progress circle
                                        pathColor: "rgba(255, 255, 255, 1)", // Color of the background trail
                                      })}
                                    />
                                  </div>
                                  <div className="persntTetx">
                                    <p>Collection by Amra </p>
                                    <h5>
                                      {RUPESS_ICON}
                                      {debtorDataMonthWise?.total_collection}
                                    </h5>
                                  </div>
                                </div>

                                <div className="collecBAr1">
                                  <div style={{ width: "60px" }}>
                                    <CircularProgressbar
                                      value={debtorDataMonthWise?.success_rate}
                                      text={`${debtorDataMonthWise?.success_rate.toFixed(
                                        0
                                      )}%`}
                                      styles={buildStyles({
                                        textWeight: "500",
                                        textColor: "rgba(52, 58, 64, 1)", // Color of the text
                                        trailColor: `rgba(243, 243, 244, 1)`,
                                        pathColor: "rgba(31, 206, 80, 1)", // Color of the background trail
                                      })}
                                    />
                                  </div>
                                  <div className="persntTetx">
                                    <p style={{ color: "#ADB5BD" }}>
                                      Amra Success Rate
                                    </p>
                                    {/* <h5>$ 24K</h5> */}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="revenueCallFoot">
                              {/* <Link to="/PaymentHistory">
                                <button>
                                  <span>SEE DETAILS</span>
                                  <span>
                                    <img src={Right} alt="icon" />
                                  </span>
                                </button>
                              </Link> */}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="callRecord col-md-4 mb-0">
                        <div className="piechatrDiv">
                          <p style={{ fontWeight: "500" }}>Recovery Status </p>
                          <div
                            style={{
                              width: "100%",
                              height: "250px",
                              position: "relative",
                            }}
                          >
                            <Doughnut
                              data={datarecovry}
                              options={optionsrecovry}
                              style={{ width: "100%" }}
                            />
                            <div
                              style={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                textAlign: "center",
                                fontSize: "16px",
                              }}
                            >
                              Recovery <br /> Status
                            </div>
                            <div className="recoveryPercent">
                              <p>{datarecovry.datasets[0].data[1]}</p>
                            </div>
                            <div className="disputePercent">
                              <p>{datarecovry.datasets[0].data[2]}</p>
                            </div>
                            <div className="L2Percent">
                              <p>{datarecovry.datasets[0].data[0]}</p>
                            </div>
                          </div>
                          <div className="legendsContainer">
                            <div className="row legendsRow m-0">
                              <div className="col-xl-6 p-0">
                                <div className="legendDetail">
                                  <div className="recoveryColor"></div>
                                  <p>{datarecovry.labels[1]}</p>
                                </div>
                              </div>
                              <div className="col-xl-6 p-0">
                                <div className="legendDetail">
                                  <div className="L2Color"></div>
                                  <p>{datarecovry.labels[0]}</p>
                                </div>
                              </div>
                              <div className="col-xl-6 p-0">
                                <div className="legendDetail">
                                  <div className="disputeColor"></div>
                                  <p>{datarecovry.labels[2]}</p>
                                </div>
                              </div>
                              <div className="col-xl-6 p-0">
                                <div className="legendDetail">
                                  <div className="pendingColor"></div>
                                  <p>{datarecovry.labels[3]}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className={`col-12 ${
                            isZoomedInCall ? "zoomedIn" : ""
                          }`}
                        >
                          <div
                            className={`callRecordContainer ${
                              isZoomedInCall ? "zoomedIn" : ""
                            }`}
                            style={{ alignSelf: "stretch" }}
                          >
                            <div className="callRecordHead">
                              <div className="revenueCallHead revenueCallHead2 ">
                                <div className="revenueDIv noneBorderDiv">
                                  <img
                                    src={Expand1}
                                    alt="icon"
                                    onClick={toggleZoomCall}
                                  />
                                  <div className="revenueText">
                                    <p>Calls in progress</p>
                                    <p className="rtext2">Report Center</p>
                                  </div>
                                  <div className="ticmark">
                                    <img src={Group290} alt="Technotask" />
                                  </div>
                                </div>

                                <div
                                  className="moreOption"
                                  style={{ paddingRight: "1rem" }}
                                  hidden
                                >
                                  <img src={MoreIcon} alt="icon" />
                                </div>
                              </div>
                              <div className="chillin">
                                <div className="chillin_container">
                                  <img src={chillGreen} alt="Technotask" />
                                  <div className="chillText">
                                    <h6>Keep chilling</h6>
                                    <p>All 4 buckets are running smoothly. </p>
                                  </div>
                                </div>
                              </div>

                              <div className="recentAlrts">
                                <div className="recentAlrts_container">
                                  <p className="csllListHeading">
                                    Recent alerts
                                  </p>
                                  <div className="csllList">
                                    <ul>
                                      {debtorDataBucketWise.map((bucket, i) => {
                                        return (
                                          <li key={i}>
                                            <div className="csllListItm">
                                              <div className="csllListItm_left">
                                                <div className="Fullgreen"></div>
                                                <p>{bucket.name}</p>
                                              </div>
                                              <div className="csllListItm_right">
                                                <span>
                                                  <img
                                                    src={PhoneImg}
                                                    alt="icon"
                                                  />
                                                </span>
                                                <p>{bucket.live_calls}</p>
                                              </div>
                                            </div>
                                          </li>
                                        );
                                      })}
                                      {/* <li>
                                        <div className="csllListItm">
                                          <div className="csllListItm_left">
                                            <div className="Fullgreen"></div>
                                            <p>Pre Due Date Calls </p>
                                          </div>
                                          <div className="csllListItm_right">
                                            <span>
                                              <img src={PhoneImg} alt="icon" />
                                            </span>
                                            <p>
                                              {dataFromAmra.pre_due_bucket_call}
                                            </p>
                                          </div>
                                        </div>
                                      </li>
                                      <li>
                                        <div className="csllListItm">
                                          <div className="csllListItm_left">
                                            <div className="lightgreen"></div>
                                            <p>30 Days Bucket</p>
                                          </div>
                                          <div className="csllListItm_right">
                                            <span>
                                              <img src={PhoneImg} alt="icon" />
                                            </span>
                                            <p>
                                              {dataFromAmra.days_30_bucket_call}
                                            </p>
                                          </div>
                                        </div>
                                      </li>
                                      <li>
                                        <div className="csllListItm">
                                          <div className="csllListItm_left">
                                            <div className="orangegreen"></div>
                                            <p>60 Days Call</p>
                                          </div>
                                          <div className="csllListItm_right">
                                            <span>
                                              <img src={PhoneImg} alt="icon" />
                                            </span>
                                            <p>
                                              {dataFromAmra.days_60_bucket_call}
                                            </p>
                                          </div>
                                        </div>
                                      </li>
                                      <li>
                                        <div className="csllListItm">
                                          <div className="csllListItm_left">
                                            <div className="offgreen"></div>
                                            <p>90 Days Call</p>
                                          </div>
                                          <div className="csllListItm_right">
                                            <span>
                                              <img src={PhoneImg} alt="icon" />
                                            </span>
                                            <p>
                                              {dataFromAmra.days_90_bucket_call}
                                            </p>
                                          </div>
                                        </div>
                                      </li> */}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="reportPieChart">
                    <div className="reportPieChartcontainer"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="brandingContainer">
              <div>
                <h5>Powered by</h5>
                <img src={Technotask} alt="Logo" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
