import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import CareerForm from "./CareerForm";
import { Helmet } from "react-helmet";

function CareerDetails() {
  return (
    <div className="careerDetails">
      <Helmet>
        <title>IT Sales Execution</title>
        <meta
          name="description"
          content="IT Sales Execution."
        />
      </Helmet>
      <header className="header_container">
        <Header />
      </header>
      <div className="careerDetails_container container-lg">
        <div className="vacncyheadDiv">
          <h1 className="vacncyhead_">IT Sales Execution</h1>
        </div>
        <div className="POXCards">
          <div className="row col-lg-10">
            <div className="col-md-3 col-sm-4">
              <div className="POXcard">
                <div className="POXcard-body">
                  <h5 className="POXcard-title">Experience</h5>
                  <p className="POXcard-text">1-3 Years </p>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-4">
              <div className="POXcard">
                <div className="POXcard-body">
                  <h5 className="POXcard-title">Opening</h5>
                  <p className="POXcard-text">10</p>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-4">
              <div className="POXcard">
                <div className="POXcard-body">
                  <h5 className="POXcard-title">Package</h5>
                  <p className="POXcard-text">8000 to 20000</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="Dscphead col-lg-8 col-11">
          <h5>Qualification :</h5>
          <p>
            This area of work is open to all graduates, but a degree or HND in
            one of the following subjects may be particularly useful: business
            information systems or technology business management
          </p>
        </div>

        <div className="Dscphead col-lg-8 col-11">
          <h5>Job Description</h5>
          <ul>
            <li>
              <p>
                Need to have a good knowledge of SaaS Products and technology.
              </p>
            </li>
            <li>
              <p>speak to customers, either face to face or over the phone.</p>
            </li>
            <li>
              <p>
                gain an understanding of customers' diverse and specific
                business needs and apply product knowledge to meet them.
              </p>
            </li>
            <li>
              <p>
                ensure quality of service by developing a thorough and detailed
                knowledge of technical specifications and other features of
                employers' systems and processes, and then documenting them.
              </p>
            </li>
            <li>
              <p>
                carry out cold-calling in order to create interest in products
                and services, generate new business leads and arrange meetings.
              </p>
            </li>
            <li>
              <p>
                identify and develop new business through networking and
                follow-up courtesy calls prepare and deliver presentations and
                demonstrations of software to customers market and promote a
                portfolio of products by writing and designing sales literature
                and attending industry events.
              </p>
            </li>
            <li>
              <p>
                maintain awareness and keep abreast of constantly changing
                software and hardware systems and peripherals (e.g. keyboard and
                mouse).
              </p>
            </li>
            <li>
              <p>develop effective sales plans using sales methodology.</p>
            </li>
            <li>
              <p>
                provide technical advice to customers on all aspects of the
                installation and use of computer systems and networks, both
                before and after the sale.
              </p>
            </li>
            <li>
              <p>
                advise on software features and how they can be applied to
                assist in a variety of contexts such as accounting,
                manufacturing or other specialist areas.
              </p>
            </li>
            <li>
              <p>
                meet sales targets set by managers and contribute to team
                targets network with existing customers in order to maintain
                links and promote additional products and upgrades.
              </p>
            </li>
            <li>
              <p>
                handle hardware or software problems and faults, referring on to
                specialist technical colleagues where appropriate.
              </p>
            </li>
            <li>
              <p>
                respond to tender documents, proposals, reports and supporting
                literature manage workload in order to organize and prioritize
                daily and weekly goals.
              </p>
            </li>
            <li>
              <p>
                Contribute to team or progress meetings to update and inform
                colleagues. convert this ui and li in html above points.
              </p>
            </li>
          </ul>
          <button type="submit" className="btn btn-primary">
            Apply Now
          </button>
        </div>

        <div className="applyvacancyDiv">
          <h4>Enter Below Details To Apply.</h4>
          <div className="formDetailsContainer">
            <CareerForm />
          </div>
        </div>
      </div>
      <footer className="footer_area section_padding_130_0 px-lg-5 px-md-4 px-sm-3 px-1">
        <Footer />
      </footer>
    </div>
  );
}

export default CareerDetails;
