import React from "react";
function PatientProfileModal({ debtor }) {
  return (
    <div>
      <div
        className="modal fade debtorProfileModalW"
        id="staticBackdrop1"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
          {debtor && debtor.name !== "" && debtor.name !== undefined ? (
            <div
              className="modal-content"
              style={{ backgroundColor: "#EBEBEB" }}
            >
              <div className="modal-header modalHeadPopUp modalHeadPopUp2">
                <h4 className="modal-title popHead" id="staticBackdropLabel">
                  Debtor Profile
                </h4>

                <div className="custMob">
                  <div className="customerid">
                    <div className="custumerId">
                      <p>Customer Id: {debtor?.customer_id}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-body">
                <div className="AmraConversations_data">
                  <div className="searchdataTable">
                    <div className="searchdataTable_container">
                      <div className="debtorBasicInfo">
                        <div className="debtorBasicInfoContainer">
                          <div className="prsnlinfoContainer">
                            {/* <div className="phoneDetails"> */}
                            <div className="row m-0">
                              <div className="col-md-6">
                                <div className="infoDetails">
                                  <p>Name:</p>
                                  <h6>{debtor?.name}</h6>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="infoDetails">
                                  <p>Email:</p>
                                  <h6>{debtor?.email}</h6>
                                </div>
                              </div>
                            </div>
                            <div className="row m-0">
                              <div className="col-md-6">
                                <div className="infoDetails">
                                  <p>Phone:</p>
                                  <h6>{debtor?.mobile}</h6>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="infoDetails">
                                  <p>Atl. Phone:</p>
                                  <h6>{debtor?.alt_mobile}</h6>
                                </div>
                              </div>
                            </div>
                            {/* </div> */}
                            {/* <div className="col-xl-2 col-md-4">
                            <div className="debtorImag">
                              <img src={DebtorImg} alt="Debtor" />
                            </div>
                          </div> */}
                          </div>
                        </div>
                        <div className="debtorprsnlInfoContainer">
                          <div className="prsnlinfoContainer">
                            <div className="row m-0">
                              <div className="col-4">
                                <div className="prsnlinfoDetail infoDetails">
                                  <p>DOB:</p>
                                  <h6>{debtor?.dob}</h6>
                                </div>
                              </div>
                              <div className="col-4">
                                <div className="prsnlinfoDetail infoDetails">
                                  <p>Age:</p>
                                  <h6>{debtor?.age}</h6>
                                </div>
                              </div>
                              <div className="col-4">
                                <div className="prsnlinfoDetail infoDetails">
                                  <p>Gender:</p>
                                  <h6>{debtor?.gender}</h6>
                                </div>
                              </div>
                            </div>
                            <div className="row m-0">
                              <div className="col-6">
                                <div className="prsnlinfoDetail infoDetails">
                                  <p>Marital Status:</p>
                                  <h6>{debtor?.marital_status}</h6>
                                </div>
                              </div>
                              <div className="col-xl-6 col-8 qualificationData">
                                <div className="prsnlinfoDetail infoDetails">
                                  <p>Highest Qualification:</p>
                                  <h6>{debtor?.highest_qualification}</h6>
                                </div>
                              </div>
                            </div>
                            <div className="row m-0">
                              <div className="col-6">
                                <div className="prsnlinfoDetail infoDetails">
                                  <p>Father’s name:</p>
                                  <h6>{debtor?.father_name}</h6>
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="prsnlinfoDetail infoDetails">
                                  <p>Mother’s name:</p>
                                  <h6>{debtor?.mother_name}</h6>
                                </div>
                              </div>
                            </div>
                            <div className="row m-0">
                              <div className="col-6">
                                <div className="adressDetailDiv infoDetails">
                                  <p>Permanent Address:</p>
                                  <h6 style={{ whiteSpace: "wrap" }}>
                                    {debtor?.permanent_add}
                                  </h6>
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="adressDetailDiv infoDetails">
                                  <p>Current Address:</p>
                                  <h6 style={{ whiteSpace: "wrap" }}>
                                    {debtor?.current_add}
                                  </h6>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          ) : (
            <div
              className="modal-content"
              style={{
                backgroundColor: "#EBEBEB",
                color: "red",
                padding: "40px",
              }}
            >
              Cannot find call details corresponding to given Loan ID or Mobile
              number
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default PatientProfileModal;
