import axios from "axios";

const firebaseConfig = {
  apiKey: "AIzaSyBBksgHnCPm8qkP1odT8IEeK_OC67-PstM",
  authDomain: "trio3d-8b129.firebaseapp.com",
  projectId: "trio3d-8b129",
  storageBucket: "trio3d-8b129.appspot.com",
  messagingSenderId: "173611114498",
  appId: "1:173611114498:web:6ec624813cb4f651c3c71b",
  measurementId: "G-XTLRK4KTVF",
};

//triosoft 2018
const DID_API_KEY = {
  key: "dHJpb3NvZnQyMDE4QGdtYWlsLmNvbQ:luImt3vf1TnBSZZ15wHwZ",
  url: "https://api.d-id.com",
};

let API_LINK_KEY = "https://technotaskapi.triosoft.ai/";
//let API_LINK_KEY = "http://127.0.0.1:7998/";
//let API_LINK_KEY = "http://192.168.79.165:7998/";

const URL_FOR_TICKETS = API_LINK_KEY + "api/dashboard/ticket_list/";
const URL_FOR_SINGLE_TICKET = API_LINK_KEY + "api/dashboard/ticket/";
const URL_FOR_TICKET_CONVERSATION =
  API_LINK_KEY + "api/dashboard/ticket_conversation/";

let URL_FOR_LINK = API_LINK_KEY + "api/voicechatboat/nafia/";
//URL_FOR_LINK = API_LINK_KEY + "api/dashboard/nafia/";
const URL_FOR_LINK_ARMA = API_LINK_KEY + "api/voicechatboat/amra/";
const URL_FOR_LINK_ARMA_CONVR_UNIQUE =
  API_LINK_KEY + "api/voicechatboat/amrashow2/";
//const URL_FOR_LINK_ARMA = API_LINK_KEY + "api/dashboard/amra/";
const URL_FOR_LINK_SEND_FORM =
  API_LINK_KEY + "api/voicechatboat/debtor_qrcode/";
const domain_urls = API_LINK_KEY + "api/dashboard/url";
const url_scraped = API_LINK_KEY + "api/dashboard/content";
const chatboat_api_update = API_LINK_KEY + "api/dashboard/chatboatupdate/";
const chatboat_api = (cmy_id) =>
  API_LINK_KEY + "api/dashboard/chatboatupdate/" + cmy_id;
const URL_FOR_TOKEN_SEND = API_LINK_KEY + "api/voicechatboat/debtor_qrcode/";
//const URL_FOR_TOKEN_SEND = API_LINK_KEY + "api/dashboard/debtor_qrcode/";
//const URL_FOR_FAQ_SEND = API_LINK_KEY + "api/voicechatboat/faq/";
const URL_FOR_FAQ_SEND = API_LINK_KEY + "api/dashboard/faq/";
const CompanyList = API_LINK_KEY + "api/dashboard/Company/";
const AgentList = (cmy_id) =>
  API_LINK_KEY + "api/dashboard/AgentByCompany/" + cmy_id;

const CompanyListNafia = API_LINK_KEY + "api/dashboard/CompanyNafia/";
const AgentListNafia = (cmy_id) =>
  API_LINK_KEY + "api/dashboard/AgentByCompanyNafia/" + cmy_id;

const URL_FOR_FAQ_SAVE = API_LINK_KEY + "api/voicechatboat/faq/";

const URL_FOR_BOOK_NOW_SAVE = API_LINK_KEY + "api/voicechatboat/book/";
const URL_FOR_CAREER_SAVE = API_LINK_KEY + "api/voicechatboat/career/";
const URL_FOR_NEWS_SAVE = API_LINK_KEY + "api/voicechatboat/newsletter/";
const csv_amra_user_upload = API_LINK_KEY + "api/dashboard/csv_upload/";
const get_debtor = API_LINK_KEY + "api/dashboard/debtor/";
const city_list = API_LINK_KEY + "api/dashboard/city_list/";
const URL_FOR_LOGIN = API_LINK_KEY + "api/dashboard/amrauserlogin/";
const URL_FOR_LOGIN_Nafia = API_LINK_KEY + "api/dashboard/nafiauserlogin/";
const URL_FOR_LOGIN_Hospital = API_LINK_KEY + "api/binary/hospitaluserlogin/";
// const URL_GET_BOOK_NOW = "http://192.168.1.18:7999/Book/";
// const URL_GET_NEWS = "http://192.168.1.18:7999/newsletter/";
// const URL_GET_CAREER = "http://192.168.1.18:7999/Career/";

// shubham jain amra setting page
const get_all_amra_admin = API_LINK_KEY + "api/dashboard/get_all_amra_admin/";
const otp_send_admin = API_LINK_KEY + "api/dashboard/otp_send_admin/";
const save_update_amra_admin =
  API_LINK_KEY + "api/dashboard/save_update_amra_admin/";
const delete_update_data = API_LINK_KEY + "api/dashboard/delete_update_data/";
const save_update_AgentData =
  API_LINK_KEY + "api/dashboard/save_update_AgentData/";
const get_all_agent_data = API_LINK_KEY + "api/dashboard/get_all_agent_data/";
const get_all_amra_user_detail =
  API_LINK_KEY + "api/dashboard/get_all_amra_user_detail/";
const update_name_amara_admin =
  API_LINK_KEY + "api/dashboard/update_name_amara_admin/";
const get_all_amra_user = API_LINK_KEY + "api/dashboard/get_all_amra_user/";
const save_update_amra_user =
  API_LINK_KEY + "api/dashboard/save_update_amra_user/";

const search_debtor_id = API_LINK_KEY + "api/dashboard/search_debtor_id/";
const get_all_Debtorprofile =
  API_LINK_KEY + "api/dashboard/get_all_Debtorprofile/";
const chatboat_text_save = API_LINK_KEY + "api/dashboard/text";

const URL_FOR_AMRA_ADMIN_LOGIN = API_LINK_KEY + "api/dashboard/amraadminlogin/";
const URL_FOR_Nafia_ADMIN_LOGIN =
  API_LINK_KEY + "api/dashboard/nafiaadminlogin/";

const get_all_nafia_admin = API_LINK_KEY + "api/dashboard/get_all_nafia_admin/";
const save_update_nafia_admin =
  API_LINK_KEY + "api/dashboard/save_update_nafia_admin/";
const save_update_AgentData_nafia =
  API_LINK_KEY + "api/dashboard/save_update_AgentData_nafia/";
const get_all_agent_data_nafia =
  API_LINK_KEY + "api/dashboard/get_all_agent_data_nafia/";
// shubham jain amra setting page

// const URL_GET_NAFIA_CONVR = API_LINK_KEY + "api/voicechatboat/nafia/";
const URL_GET_NAFIA_CONVR =
  "https://api.technotask.ai/api/voicechatboat/nafia/";
const URL_GET_NAFIA_CONVR_COUNT =
  API_LINK_KEY + "api/voicechatboat/nafia/count/";
const URL_GET_AMRA_CONVR_COUNT = API_LINK_KEY + "api/voicechatboat/amra/count/";
const URL_GET_AMRA_CONVR = API_LINK_KEY + "api/voicechatboat/amra/";
const URL_FOR_CUTOMER_FAQ = API_LINK_KEY + "api/voicechatboat/faq/";

const URL_FOR_AMRADATA =
  API_LINK_KEY + "api/voicechatboat/amra_dashboard_data/"; //new to show amradata on frontend this is for Dash data
const URL_FOR_USERDATCOUNTRYWISE = API_LINK_KEY + "api/voicechatboat/users/"; //new to show amradata on frontend this is for user data
const URL_FOR_NAFIA_ADMIN_DASH = API_LINK_KEY + "api/voicechatboat/Nafiaadmin/";
const URL_FOR_AMRA_ADMIN_DASH = API_LINK_KEY + "api/voicechatboat/Amraadmin/";
const URL_FOR_DASHBOARD_DATA =
  API_LINK_KEY + "api/voicechatboat/Dashboardstats/";
const URl_FOR_NAFIA_HOMEPAGE =
  " https://api.technotask.ai/api/voicechatboat/homepagenafiadata/"; //homepage nafia

const THANKYOU_LINK = API_LINK_KEY + "mediafiles/media/thankyou_english.mp3";
const THANKYOU_LINK_HINDI =
  API_LINK_KEY + "mediafiles/media/thankyou_hindi.mp3";
const THANKYOU_LINK_ARBIC =
  API_LINK_KEY + "mediafiles/media/thankyou_arabic.mp3";
const THANKYOU_MSG = "Thankyou for your time! Have a nice day";
const THANKYOU_MSG_HINDI = "आपके समय के लिए धन्यवाद! आपका दिन शुभ हो";
const THANKYOU_MSG_ARABIC = "شكرا لك على وقتك! طاب يومك";
const IMAGE_AVATAR_LINK = API_LINK_KEY + "mediafiles/media/pooja.png";

// Nafia URLS
const NAFIA_DATA_AGGREGATE =
  API_LINK_KEY + "api/dashboard/nafia_data_aggregate/";
const NAFIA_POSITIVE_NEGATIVE =
  API_LINK_KEY + "api/dashboard/nafia_positive_negative/";
const NAFIA_USER_LIST = API_LINK_KEY + "api/dashboard/nafia_user_list/";

//  Amra Admin
const AMRA_CUSTOMERS_DATA = API_LINK_KEY + "api/dashboard/amrausercompanydata/";
const AMRA_DEBTOR_DATA_AGGREGATE =
  API_LINK_KEY + "api/dashboard/amra_debtor_data_aggregate/";
const AMRA_DEBTOR_DATA_DETAILS =
  API_LINK_KEY + "api/dashboard/amra_debtor_details/";

// Farida User Dashboard
const URL_FOR_LINK_FARIDA = API_LINK_KEY + "api/binary/farida/";
const USER_DATA_FARIDA = API_LINK_KEY + "api/binary/farida_user_data/";
const APPOINTMENT_DATA_FARIDA =
  API_LINK_KEY + "api/binary/farida_appointment_data/";
const DEPARTMENT_DATA_FARIDA =
  API_LINK_KEY + "api/binary/farida_department_data/";
const CREATE_DEPARTMENT_FARIDA = API_LINK_KEY + "api/binary/create_department/";
const GET_DEPARTMENT_FARIDA = API_LINK_KEY + "api/binary/get_department_data/";
const GET_MASTER_DEPARTMENT_FARIDA =
  API_LINK_KEY + "api/binary/master_department_list/";
const CREATE_AGENT_FARIDA = API_LINK_KEY + "api/binary/create_agent/";
const GET_AGENT_FARIDA = API_LINK_KEY + "api/binary/get_agent_data/";
const CREATE_USER_FARIDA = API_LINK_KEY + "api/binary/create_user/";
const GET_USER_FARIDA = API_LINK_KEY + "api/binary/get_user_data/";
const CREATE_DOCTOR_FARIDA = API_LINK_KEY + "api/binary/create_doctor/";
const GET_DOCTOR_FARIDA = API_LINK_KEY + "api/binary/get_doctor_data/";
const GET_PATIENT_FARIDA = API_LINK_KEY + "api/binary/patient_list_mobile/";
const CREATE_APPOINTMENT_FARIDA =
  API_LINK_KEY + "api/binary/create_appointment/";
const GET_APPOINTMENT_FARIDA =
  API_LINK_KEY + "api/binary/get_appointment_data/";

//  Farida Admin
const GET_HOSPITAL_FARIDA = API_LINK_KEY + "api/binary/get_hospitals_data/";
const CREATE_HOSPITAL_FARIDA =
  API_LINK_KEY + "api/binary/create_hospital_user/";

const READYPLAYER_URL_copy =
  "https://models.readyplayer.me/65200e89e596f70e7e2ab93d.glb?morphTargets=ARKit,Oculus+Visemes,mouthOpen,mouthSmile,eyesClosed,eyesLookUp,eyesLookDown&textureSizeLimit=1024&textureFormat=png&pose=A";
const READYPLAYER_URL =
  "?morphTargets=ARKit,Oculus+Visemes,mouthOpen,mouthSmile,eyesClosed,eyesLookUp,eyesLookDown&textureSizeLimit=1024&textureFormat=png&pose=A";

const ttsEndpoint = "https://texttospeech.googleapis.com/v1/text:synthesize";
const ttsApikey = "AIzaSyAqUfO9QmUksI3cTQx2ejleGLK5aWN8OLg";
const ttsEndpoint_final = ttsEndpoint + (ttsApikey ? "?key=" + ttsApikey : "");

const ttsVoiceEnglish = "hi-IN-Standard-A";
const ttsVoiceHindi = "hi-IN-Standard-A";
const ttsVoiceArabic = "ar-XA-Standard-A";
const ttsRate = 0.95;
const ttsPitch = 0;
const ttsVolume = 0;

const keywords_confirm = [
  // English
  "yes",
  "sure",
  "agreed",
  "confirm",
  "absolutely",
  "certainly",
  "definitely",
  "indeed",
  "affirmative",
  "positive",
  "okay",
  "alright",
  "fine",
  "good",
  "great",
  "exactly",
  "absolutely right",
  "you're right",
  "I agree",
  "i agree",
  "agree",
  "agri",
  "I confirm",
  "that's correct",
  "that's right",
  "very well",
  "no problem",
  "of course",
  "without a doubt",

  // Hindi (हिन्दी)
  "हां", // Haan (Yes)
  "बिल्कुल", // Bilkul (Absolutely)
  "सहमत", // Sahmat (Agreed)
  "पुष्टि करें", // Pushti Karen (Confirm)
  "ठीक है", // Theek Hai (Alright)
  "अच्छा", // Achha (Good)
  "मैं सहमत हूँ",
  "हाँ बिल्कुल", // Haan Bilkul (Yes, Absolutely)

  // Arabic (العربية)
  "نعم", // Na'am (Yes)
  "بالتأكيد", // Bitaqad (Certainly)
  "مؤكد", // Muwakkad (Confirmed)
  "موافق", // Muwafiq (Agreed)
  "تأكيد", // Ta'kid (Confirmation)
  "حسنًا", // Hasanan (Alright)
  "جيد", // Jayyidan (Good)
  "بالطبع", // Bialtabi' (Of course)
  "بدون شك", // Bedun Shak (Without a doubt)
];

const keyword_agree = ["I agree", "agree", "agri", "मैं सहमत हूँ", "أنا موافق"];

const keywords_hello = [
  "hello",
  "नमस्ते", // Namaste
  "مرحبا", // Marhaba
];

const keywords_links_complete = [
  THANKYOU_LINK,
  THANKYOU_LINK_HINDI, // Namaste
  THANKYOU_LINK_ARBIC, // Marhaba
];

// Retrieving data
const server_post_data = async (url_for, form_data) => {
  const headers_data = {
    "Content-Type": "application/json",
  };

  return fetch(url_for, {
    method: "POST",
    body: form_data,
    headers: headers_data, // Remove the nesting here
  });
};

// Retrieving data
const server_post_data_shubham = async (url_for, form_data) => {
  // const headers = {
  //   "Content-Type": "application/json",
  // };
  return axios.post(url_for, form_data);
};

// Retrieving data
const server_get_data_master = async (url_for, form_data) => {
  const headers_data = {
    "Content-Type": "application/json",
  };

  return fetch(url_for, {
    method: "GET",
    headers: headers_data, // Remove the nesting here
  });
};

const server_patch_data_master = async (url_for, form_data) => {
  const headers_data = {
    "Content-Type": "application/json",
  };

  return fetch(url_for, {
    method: "PATCH",
    headers: headers_data,
    body: JSON.stringify(form_data), // Make sure to stringify the data if it's an object
  });
};

const amrachatconversationretival = (session_id) =>
  API_LINK_KEY + "api/voicechatboat/nafia/show_session/" + session_id;
const nafiachatconversationretival = (session_id) =>
  API_LINK_KEY + "api/voicechatboat/amra/show_session/" + session_id;

export {
  firebaseConfig,
  DID_API_KEY,
  URL_FOR_LINK,
  URL_FOR_LINK_ARMA,
  READYPLAYER_URL,
  THANKYOU_LINK,
  THANKYOU_MSG,
  THANKYOU_MSG_HINDI,
  THANKYOU_MSG_ARABIC,
  IMAGE_AVATAR_LINK,
  ttsEndpoint,
  ttsEndpoint_final,
  ttsApikey,
  ttsVoiceEnglish,
  ttsVoiceHindi,
  ttsVoiceArabic,
  URL_FOR_LOGIN,
  ttsRate,
  ttsPitch,
  ttsVolume,
  keywords_confirm,
  keyword_agree,
  keywords_hello,
  READYPLAYER_URL_copy,
  URL_FOR_LINK_SEND_FORM,
  URL_FOR_TOKEN_SEND,
  URL_FOR_FAQ_SEND,
  URL_FOR_FAQ_SAVE,
  URL_FOR_BOOK_NOW_SAVE,
  URL_FOR_CAREER_SAVE,
  keywords_links_complete,
  URL_FOR_NEWS_SAVE,
  // URL_GET_BOOK_NOW,
  // URL_GET_NEWS,
  // URL_GET_CAREER,
  URL_GET_NAFIA_CONVR,
  URL_GET_AMRA_CONVR,
  URL_FOR_LINK_ARMA_CONVR_UNIQUE,
  URL_FOR_CUTOMER_FAQ,
  URL_GET_NAFIA_CONVR_COUNT,
  URL_GET_AMRA_CONVR_COUNT,
  URL_FOR_AMRADATA, //new to show amradata on frontend
  URL_FOR_USERDATCOUNTRYWISE, //new to show amra user data on frontend
  URL_FOR_NAFIA_ADMIN_DASH, // new for nafia admin dash
  URL_FOR_AMRA_ADMIN_DASH, //new for amra
  URL_FOR_DASHBOARD_DATA, // new for dash board data
  URL_FOR_LINK_FARIDA, // Farida link
  USER_DATA_FARIDA,
  APPOINTMENT_DATA_FARIDA,
  DEPARTMENT_DATA_FARIDA,
  GET_HOSPITAL_FARIDA,
  CREATE_HOSPITAL_FARIDA,
  GET_MASTER_DEPARTMENT_FARIDA,
  GET_DEPARTMENT_FARIDA,
  CREATE_DEPARTMENT_FARIDA,
  CREATE_AGENT_FARIDA,
  GET_AGENT_FARIDA,
  CREATE_USER_FARIDA,
  GET_USER_FARIDA,
  CREATE_DOCTOR_FARIDA,
  GET_DOCTOR_FARIDA,
  GET_APPOINTMENT_FARIDA,
  GET_PATIENT_FARIDA,
  CREATE_APPOINTMENT_FARIDA,
  URl_FOR_NAFIA_HOMEPAGE, //Nafia home page data
  NAFIA_DATA_AGGREGATE,
  NAFIA_POSITIVE_NEGATIVE,
  NAFIA_USER_LIST,
  AMRA_CUSTOMERS_DATA,
  AMRA_DEBTOR_DATA_AGGREGATE,
  AMRA_DEBTOR_DATA_DETAILS,
  server_post_data,
  server_post_data_shubham,
  server_get_data_master,
  server_patch_data_master,
  amrachatconversationretival,
  nafiachatconversationretival,
  URL_FOR_TICKETS,
  URL_FOR_SINGLE_TICKET,
  URL_FOR_TICKET_CONVERSATION,
  csv_amra_user_upload,
  get_debtor,
  city_list,
  CompanyList,
  get_all_amra_admin,
  save_update_amra_admin,
  otp_send_admin,
  delete_update_data,
  save_update_AgentData,
  get_all_agent_data,
  get_all_amra_user_detail,
  update_name_amara_admin,
  get_all_amra_user,
  save_update_amra_user,
  search_debtor_id,
  AgentList,
  get_all_Debtorprofile,
  API_LINK_KEY,
  domain_urls,
  url_scraped,
  chatboat_api_update,
  chatboat_text_save,
  chatboat_api,
  URL_FOR_LOGIN_Nafia,
  CompanyListNafia,
  AgentListNafia,
  URL_FOR_AMRA_ADMIN_LOGIN,
  URL_FOR_Nafia_ADMIN_LOGIN,
  get_all_nafia_admin,
  save_update_nafia_admin,
  save_update_AgentData_nafia,
  get_all_agent_data_nafia,
  URL_FOR_LOGIN_Hospital,
};
