import React, { useState, useEffect, useRef } from "react";
import "../../Dasboard/Css/Dashboard.css";
import "../../Dasboard/Css/Bucket30days.css";
import "../../Dasboard/Css/SettingPage.css";
import "../../Dasboard/Css/HelpSupport.css";
import "../../Dasboard/Css/SideBar.css";
import "../../Dasboard/Css/HomePageNafia.css";
import "../../SuperAdmin/NAfiaAdmin.css";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, registerables } from "chart.js";
import NafiaSideBar from "./NafiaSideBar.js";
import FilterSvg from "../../assets/images/Funnel.svg";
import Calender from "../../assets/images/calendar12.svg";
import DownloadBtn from "../../assets/images/downloadsvg.svg";
import { Modal, Button } from "react-bootstrap";
import {
  server_get_data_master,
  URl_FOR_NAFIA_HOMEPAGE,
  NAFIA_DATA_AGGREGATE,
  NAFIA_USER_LIST,
  URL_GET_NAFIA_CONVR,
} from "../../KeyConnection/KeysService";
import $ from "jquery";
import moment from "moment";
import { Helmet } from "react-helmet";
import "moment/locale/en-au";
import "daterangepicker";
import Technotask from "../../assets/images/TechnotaskLogo12.png";
import NafiaUserCenterNav from "./NafiaUserCenterNav.js";

function CreateNewTicket() {
  const [Nafiahomepagedata, setNafiahomepagedata] = useState({
    series_1: null, // Initialize with null or appropriate default values
    series_2: null, // Initialize with null or appropriate default values
  });

  const [chartState, setChartState] = useState({
    series: [],
    options: {
      labels: ["Escalations", "Success Rate"],
      chart: {
        type: "donut",
      },
      colors: ["rgba(223, 54, 112, 1) ", "rgba(79, 63, 240, 1)"],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 100,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  });
  useEffect(() => {
    const series1data = Nafiahomepagedata.series_1;
    const series2data = Nafiahomepagedata.series_2;

    // Check if series data is available before updating the state
    if (series1data !== null && series2data !== null) {
      setChartState({
        series: [series1data, series2data],
        options: chartState.options, // Retain the existing options
      });
    }
  }, [Nafiahomepagedata, chartState.options]);

  const totalseriesvalue =
    Nafiahomepagedata.series_1 + Nafiahomepagedata.series_2;

  // Step 2: Calculate percentages
  const [percentage1, setpercentage1] = useState(0);
  const [percentage2, setpercentage2] = useState(0);

  ChartJS.register(...registerables);

  const [overviewShow, setOverviewShow] = useState(true); // Default to 'true'
  const [debtorProfile, setdebtorProfile] = useState(false);

  const inputRef = useRef(null);

  const [isLoading, setLoading] = useState(false);
  const [companyID, setCompanyID] = useState(
    localStorage.getItem("company_id")
  );
  const [botID, setBotID] = useState(1);
  const [nafiaAggregateData, setNafiaAggregateData] = useState({
    avg_response_time: 0,
    escalations_count: 0,
    total_interaction_count: 0,
    total_message_count: 0,
  });
  const [nafiaMonthWiseData, setNafiaMonthWiseData] = useState();
  const [nafiaUserInteraction, setNafiaUserInteraction] = useState([
    {
      serial: "",
      user_id: "",
      user_name: "",
      interaction_date: "",
      startTime: "",
      endTime: "",
      // endTime: "NA",
      duration_min: "",
      duration_sec: "",
      // duration: "NA",
    },
  ]);
  const [interactiveGraphData, setInteractiveGraphData] = useState({
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
    datasets: [
      {
        label: "Total Interactions",
        data: [30, 38, 10, 15, 43, 10],
        borderColor: "rgba(167, 0, 255, 1)",
        tension: 0.37,
        pointRadius: 1,
        fill: false,
      },
      {
        label: "Total Messages",
        data: [40, 48, 8, 43, 10, 47],
        borderColor: "rgba(60, 216, 86, 1)",
        tension: 0.37,
        pointRadius: 1,
        fill: false,
      },
    ],
  });

  const options = {
    scales: {
      x: {
        title: {
          display: false,
        },
        grid: {
          display: false,
        },
      },
      y: {
        title: {
          display: true,
          text: "Count",
        },
        ticks: {
          min: 0,
          max: 50,
          stepSize: 10,
          precision: 0,
        },
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      tooltip: {
        mode: "nearest",
      },
    },
  };

  const showNafiadataonDashboard = async (
    URL_FOR,
    dateRange,
    company_id,
    bot_id
  ) => {
    setLoading(true);
    let target_url = `${URL_FOR}${dateRange.startDate}/${dateRange.endDate}/${company_id}/${bot_id}/`;
    console.log(target_url);
    try {
      const res = await server_get_data_master(target_url);
      const data = await res.json();
      setNafiaAggregateData(data.data);
      setNafiaMonthWiseData(data.datewise_data);
      const months = [];
      const sessionCount = [];
      const messageCount = [];

      data.datewise_data.forEach((item) => {
        const abbreviatedMonth = item.month.substring(0, 3);
        months.push(abbreviatedMonth);
        sessionCount.push(item.unique_session_count);
        messageCount.push(item.total_message_count);
      });

      const updatedInteractiveData = {
        labels: months,
        datasets: [
          {
            label: "Total Interactions",
            data: sessionCount,
            borderColor: "rgba(167, 0, 255, 1)",
            tension: 0.37,
            pointRadius: 1,
            fill: false,
          },
          {
            label: "Total Messages",
            data: messageCount,
            borderColor: "rgba(60, 216, 86, 1)",
            tension: 0.37,
            pointRadius: 1,
            fill: false,
          },
        ],
      };
      setInteractiveGraphData(updatedInteractiveData);
    } catch (err) {
      console.log(err);
    }
  };

  const showNafiaRecentInteractions = async (
    URL_FOR,
    dateRange,
    company_id,
    bot_id
  ) => {
    setLoading(true);
    let target_url = `${URL_FOR}${dateRange.startDate}/${dateRange.endDate}/${company_id}/${bot_id}/`;
    //console.log(target_url);
    try {
      const res = await server_get_data_master(target_url);
      const data = await res.json();
      //console.log(data);
      const interaction_arr = [];

      data.data.forEach(async (interaction, idx) => {
        const url = URL_GET_NAFIA_CONVR + interaction.session_id;
        const response = await fetch(url);
        const data = await response.json();
        const chat = data.data;
        let start_date = new Date(chat[0].created_at);
        let end_date = new Date(chat[chat.length - 1].created_at);
        // Date formatting options
        const dateFormatOptions = {
          year: "numeric",
          month: "long",
          day: "numeric",
        };
        // Time formatting options
        const timeFormatOptions = {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
          timeZone: "IST", // Adjust this based on your actual timezone
        };
        // Format date and time separately
        const formattedDate_start = start_date.toLocaleDateString(
          "en-US",
          dateFormatOptions
        );
        const formattedTime_start = new Intl.DateTimeFormat(
          "en-US",
          timeFormatOptions
        ).format(start_date);

        const formattedTime_end = new Intl.DateTimeFormat(
          "en-US",
          timeFormatOptions
        ).format(end_date);

        let timeDiff = Math.abs(end_date - start_date);
        let diffSeconds = Math.ceil(timeDiff / 1000);

        let minutes = Math.floor(diffSeconds / 60);
        let seconds = diffSeconds % 60;

        const obj = {
          serial: idx + 1,
          user_id: interaction.session_id,
          user_name: "NA",
          interaction_date: formattedDate_start,
          startTime: formattedTime_start,
          endTime: formattedTime_end,
          // endTime: "NA",
          duration_min: minutes,
          duration_sec: seconds,
          // duration: "NA",
        };

        interaction_arr.push(obj);
        setNafiaUserInteraction([...interaction_arr]);
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleDateRangeChange = (start, end, label) => {
    // Callback function for handling date range changes
    let startDate = start.format("DD-MM-YYYY").toString();
    let endDate = end.format("DD-MM-YYYY").toString();

    let dates = {
      startDate: start.format("YYYY-MM-DD").toString(),
      endDate: end.format("YYYY-MM-DD").toString(),
    };
    showNafiadataonDashboard(NAFIA_DATA_AGGREGATE, dates, companyID, botID);
    showNafiaRecentInteractions(NAFIA_USER_LIST, dates, companyID, botID);
  };

  useEffect(() => {
    // Call the function to fetch data when the component mounts

    const datepicker = $(inputRef.current);
    datepicker.daterangepicker(
      {
        timePicker: true,
        startDate: moment().subtract(7, "days").startOf("day"), // Set start date to 7 days ago
        endDate: moment().add(2, "days").endOf("day"), // Set end date to today
        locale: {
          format: "DD/MM/YYYY",
        },
      },
      handleDateRangeChange
    );

    // Call handleDateRangeChange on component load
    const startDate = datepicker.data("daterangepicker").startDate;
    const endDate = datepicker.data("daterangepicker").endDate;
    handleDateRangeChange(startDate, endDate);

    if (
      Nafiahomepagedata &&
      totalseriesvalue !== null &&
      totalseriesvalue !== undefined
    ) {
      const calculatedPercentage1 = (
        (Nafiahomepagedata.series_1 / totalseriesvalue) *
        100
      ).toFixed(2);
      const calculatedPercentage2 = (
        (Nafiahomepagedata.series_2 / totalseriesvalue) *
        100
      ).toFixed(2);

      setpercentage1(parseFloat(calculatedPercentage1)); // Convert the string to a number
      setpercentage2(parseFloat(calculatedPercentage2)); // Convert the string to a number
    } else {
      console.error(
        "Nafiahomepagedata or totalseriesvalue is null or undefined"
      );
    }
  }, []);

  const [callRecords, setCallRecords] = useState([
    {
      type: "content",
      text: "hello",
    },
    {
      type: "response",
      text: "hello, am I speaking with Fathima",
    },
  ]);

  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => {
    setShowModal(false); // Close the modal
  };

  const showNafiaConversation = async (customer_id) => {
    console.log("interaction getting fetched");
    const url = URL_GET_NAFIA_CONVR + customer_id;
    try {
      const response = await fetch(url);
      const data = await response.json();
      const chat = data.data;
      const conversation = chat.map((message, idx) => {
        console.log(message.content_source);
        return {
          user: message.content_source,
          bot: message.response_source,
        };
      });
      console.log(conversation);
      setCallRecords(conversation);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setShowModal(true);
  };

  const dataTableRef = useRef(null);

  useEffect(() => {
    // Initialize DataTable
    $("#myTable").DataTable();
    // if (nafiaUserInteraction && nafiaUserInteraction.length > 0) {
    //   // Destroy the DataTable if it already exists
    //   if ($.fn.DataTable.isDataTable("#myTable")) {
    //     $("#myTable").DataTable().destroy();
    //   }

    //   // Initialize DataTable
    //   $("#myTable").DataTable();
    // }
    // console.log(nafiaUserInteraction);
  }, []);

  return (
    <div className="dashboard">
      <Helmet>
        <title>Nafia User Dashboard</title>
        <meta
          name="description"
          content="AI and Global Reach: The Key to Scalable Solutions in Customer
          Experience"
        />
      </Helmet>
      <div className="dashboard_container">
        <div className="dashboard_wrapper">
          <NafiaSideBar
            overviewShow={overviewShow}
            setOverviewShow={setOverviewShow}
            debtorProfile={debtorProfile}
            setdebtorProfile={setdebtorProfile}
          />
          <div id="content">
            <NafiaUserCenterNav />
            <div className="overviewContaINER nafiaSuperOverview">
              <div className="overviewHead overviewHeaddp ">
                <h4 style={{ color: "#343A40" }}>Overview</h4>
                <div className="overviewHeadLeft">
                  <div className="overviewHeadLeftContn">
                    <div className="date_filter_div">
                      <div className="date_filter">
                        <div className="calender_icon">
                          <label
                            style={{ cursor: "pointer" }}
                            htmlFor="datepicker"
                          >
                            <img src={Calender} alt="Image" />
                          </label>
                        </div>
                        <input
                          id="datepicker"
                          className="form-control form-control-solid"
                          placeholder="Pick date range"
                          ref={inputRef}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="debtorProfile">
                <div className="debtorProfileContainer">
                  <div className="debtordetailCards">
                    <div className="row m-0">
                      <div className="col-md-4 ">
                        <div
                          style={{ color: "#343A40", gap: "1rem" }}
                          className="row height_100_per"
                        >
                          <div className="col-sm-12">
                            <div
                              className="debtordetailCardNafia"
                              style={{ backgroundColor: "#F8F8F8" }}
                            >
                              <div className="debtordetailCardHead">
                                <h6>Total Interactions</h6>
                                <img />
                              </div>
                              <div className="debtordetailCardBody">
                                <h4>
                                  {nafiaAggregateData.total_interaction_count}{" "}
                                </h4>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-12">
                            <div
                              className="debtordetailCardNafia"
                              style={{ backgroundColor: "#F8F8F8" }}
                            >
                              <div className="debtordetailCardHead">
                                <h6>Total Messages</h6>
                                <img />
                              </div>
                              <div className="debtordetailCardBody">
                                <h4>
                                  {nafiaAggregateData.total_message_count}{" "}
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 ">
                        <div className="lineChartCOl13">
                          <h6 style={{ color: "#343A40", margin: "10px" }}>
                            Interactive Summary
                          </h6>
                          <div className="rainchart">
                            <Line
                              className="canvas"
                              data={interactiveGraphData}
                              options={options}
                              responsive={true}
                              maintainAspectRatio={false}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 ">
                        <div
                          style={{ color: "#343A40", gap: "1rem" }}
                          className="row height_100_per"
                        >
                          <div className="col-sm-12">
                            <div
                              className="debtordetailCardNafia"
                              style={{ backgroundColor: "#F8F8F8" }}
                            >
                              <div className="debtordetailCardHead">
                                <h6>Average Response Time </h6>
                                <img />
                              </div>
                              <div className="debtordetailCardBody">
                                <h4>{nafiaAggregateData.avg_response_time} </h4>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-12">
                            <div
                              className="debtordetailCardNafia"
                              style={{ backgroundColor: "#F8F8F8" }}
                            >
                              <div className="debtordetailCardHead">
                                <h6>Escalations</h6>
                                <img />
                              </div>
                              <div className="debtordetailCardBody">
                                <h4>{nafiaAggregateData.escalations_count} </h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="datatableCOntainerDiv">
                <div className="datatableCOntainerHead">
                  <h4>Recent Interaction</h4>
                  {/* <div className="filterBtnDivs">
                    <button>
                      <p>Filter</p>
                      <img src={FilterSvg} alt="icon" />
                    </button>
                  </div> */}
                </div>
                <div className="amradataTable p-0">
                  <div className="amradataTable_container amradataTable_container2">
                    <table id="myTable" className="display" ref={dataTableRef}>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>User ID</th>
                          <th>Name</th>
                          <th>Interaction Date</th>
                          <th>Start Time</th>
                          <th>End Time</th>
                          <th>Total Duration</th>
                          <th>CRM Note</th>
                        </tr>
                      </thead>
                      <tbody>
                        {nafiaUserInteraction.map((item, index) => (
                          <tr key={index}>
                            <td>
                              <div className="tableData1 tableData12">
                                <p>{item.serial}</p>
                              </div>
                            </td>
                            <td>
                              <div className="tableData1 tableData12">
                                <p>{item.user_id}</p>
                              </div>
                            </td>
                            <td>
                              <div className="tableData1 tableData12">
                                <p style={{ color: "#000" }}>
                                  {item.user_name}
                                </p>
                              </div>
                            </td>
                            <td>
                              <div className="tableData1 tableData12">
                                <p>{item.interaction_date}</p>
                              </div>
                            </td>
                            <td>
                              <div className="tableData1 tableData12">
                                <p>{item.startTime}</p>
                              </div>
                            </td>
                            <td>
                              <div className="tableData1 tableData12">
                                <p>{item.endTime}</p>
                              </div>
                            </td>
                            <td>
                              <div className="tableData1 tableData12">
                                {item.duration_min === 0 && (
                                  <p>{`${item.duration_sec} sec`}</p>
                                )}
                                {item.duration_min !== 0 && (
                                  <p>{`${item.duration_min} min ${item.duration_sec} sec`}</p>
                                )}
                              </div>
                            </td>
                            <td>
                              <div className="downloadCRmDIv">
                                <img
                                  src={DownloadBtn}
                                  alt="icon"
                                  onClick={() =>
                                    showNafiaConversation(item.user_id)
                                  }
                                />
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="brandingContainer">
              <div>
                <h5>Powered by</h5>
                <img src={Technotask} alt="Logo" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showModal} onHide={handleCloseModal} size="lg" centered>
        {/* Modal content */}
        <Modal.Header closeButton>
          <Modal.Title>Interaction Transcript</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {callRecords.map((record, index) => (
              <div key={index}>
                <p
                  style={{
                    textAlign: "flex-end",
                    margin: "7px",
                    float: "left",
                  }}
                  className="leftTextbg"
                >
                  {record.user}
                </p>
                <br></br>
                <p
                  style={{
                    textAlign: "flex-endstart",
                    margin: "7px",
                    float: "right",
                  }}
                  className="rightTextbg"
                >
                  {record.bot}
                </p>
              </div>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default CreateNewTicket;
