import React, { useState, useEffect, useRef } from "react";
import NAfiaAdminSideBar from "./NAfiaAdminSideBar.js";
import "../../Dasboard/Css/Dashboard.css";
import "../AdminSIdebar.css";
import "../NAfiaAdmin.css";
import Calender from "../../assets/images/calendar12.svg";
import { Line, Doughnut } from "react-chartjs-2";
import { Dropdown } from "primereact/dropdown";
import { Chart as ChartJS, registerables } from "chart.js";
import "react-circular-progressbar/dist/styles.css";
import SearchImg from "../../assets/images/search-normal.svg";
import { Helmet } from "react-helmet";
import {
  NAFIA_CUSTOMERS_DATA,
  CompanyListNafia,
  server_get_data_master,
  URL_FOR_TICKETS,
  NAFIA_DATA_AGGREGATE,
} from "../../KeyConnection/KeysService.js";
import ProfileImg from "../../assets/images/Male_User21.svg";
import axios from "axios";
import moment from "moment";
import "moment/locale/en-au";
import "daterangepicker";
import CenterNavNafiaAuperAdmin from "./CenterNavNafiaAuperAdmin.js";
import { Modal } from "react-bootstrap";
function NafiaAdminClientMngt() {
  ChartJS.register(...registerables);
  const [dynaicimage, setDynaicimage] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [companyID, setCompanyID] = useState(1);
  const [botID, setBotID] = useState(1);
  const [newcustomerName, setnewcustomerName] = useState("");
  const [userName, setuserName] = useState("");
  const [password, setpassword] = useState("");
  const [mobileNumber, setmobileNumber] = useState("");
  const [email, setemail] = useState("");
  const [userCountry, setuserCountry] = useState("");
  const [commission, setcommission] = useState("");
  const [countries, setCountries] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [Nafiaadmindata, setdataFromNafia] = useState({
    total_customers: 0,
    for_renewals: 0,
    new_onboardings: 0,
    urgent_tickets: 0,
  });

  const controlOptions = [
    { name: "Agent 1", code: "1" },
    { name: "Agent 2", code: "2" },
    { name: "Agent 3", code: "3" },
  ];

  const [nafiaAggregateData, setNafiaAggregateData] = useState({
    avg_response_time: 0,
    escalations_count: 0,
    total_interaction_count: 0,
    total_message_count: 0,
  });

  const [companyListData, setCompanyListData] = useState([]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = new Intl.DateTimeFormat("en-US", { month: "long" }).format(
      date
    );
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  };

  const [interactiveGraphData, setInteractiveGraphData] = useState({
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
    datasets: [
      {
        label: "Total Interactions",
        data: [30, 38, 10, 15, 43, 10],
        borderColor: "rgba(167, 0, 255, 1)",
        tension: 0.37,
        pointRadius: 1,
        fill: false,
      },
      {
        label: "Total Messages",
        data: [40, 48, 8, 43, 10, 47],
        borderColor: "rgba(60, 216, 86, 1)",
        tension: 0.37,
        pointRadius: 1,
        fill: false,
      },
    ],
  });

  const showNafiadataonDashboard = async (URL_FOR, dateRange) => {
    setLoading(true);
    try {
      let adminData = {
        total_customers: 0,
        accounts_for_renewals: 0,
        new_onboardings: 0,
        urgent_tickets: 0,
      };

      const response = await server_get_data_master(URL_FOR_TICKETS);
      const data2 = await response.json();
      const ticketsArr = [];
      // setshowLoaderWebsite(false);
      if (data2.status === "error") {
        alert("Error fetching data");
      } else {
        data2.data.forEach((ticket) => {
          ticketsArr.push(ticket);
          if (ticket.ticket_for_flag === "2") {
            if (ticket.priority_status === "High") {
              adminData.urgent_tickets += 1;
            }
          }
        });
      }
      console.log(URL_FOR)

      const res = await server_get_data_master(URL_FOR);
      const data = await res.json();
      const companyList = data.data;
      adminData.total_customers = companyList.length;
      setCountries(data.city_list);
      const arr = [];

      companyList.forEach(async (company) => {
        let now = Date.now();
        let onboarding_date = new Date(company.created_at);
        const sevenDaysAgo = now - 7 * 24 * 60 * 60 * 1000;
        if (onboarding_date >= sevenDaysAgo) {
          adminData.new_onboardings += 1;
        }
        let plan_expiry_date = new Date(company.plan_expiry_date);
        if (plan_expiry_date.getMonth() === new Date().getMonth()) {
          adminData.accounts_for_renewals += 1;
        }
        setdataFromNafia(adminData);

        let target_url = `${NAFIA_DATA_AGGREGATE}${dateRange.startDate}/${dateRange.endDate}/${company.id}/${botID}/`;
        const res = await server_get_data_master(target_url);
        const data = await res.json();
        setNafiaAggregateData(data.data);

        const months = [];
        const sessionCount = [];
        const messageCount = [];
        data.datewise_data.forEach((item) => {
          const abbreviatedMonth = item.month.substring(0, 3);
          months.push(abbreviatedMonth);
          sessionCount.push(item.unique_session_count);
          messageCount.push(item.total_message_count);
        });

        const updatedInteractiveData = {
          labels: months,
          datasets: [
            {
              label: "Total Interactions",
              data: sessionCount,
              borderColor: "rgba(167, 0, 255, 1)",
              tension: 0.37,
              pointRadius: 1,
              fill: false,
            },
            {
              label: "Total Messages",
              data: messageCount,
              borderColor: "rgba(60, 216, 86, 1)",
              tension: 0.37,
              pointRadius: 1,
              fill: false,
            },
          ],
        };
        setInteractiveGraphData(updatedInteractiveData);

        let last_ticket_date = "";
        let last_ticket_no = "";
        ticketsArr.forEach((ticket) => {
          if (Number(ticket.company_id) === company.id) {
            console.log(ticket);
            if (last_ticket_date === "") {
              last_ticket_date = new Date(ticket.created_at);
              last_ticket_no = ticket.uuid_id;
            } else {
              if (new Date(ticket.created_at) > last_ticket_date) {
                last_ticket_date = new Date(ticket.created_at);
                last_ticket_no = ticket.uuid_id;
              }
            }
          }
        });

        console.log(last_ticket_date);

        const obj = {
          id: company.id,
          name: company.name,
          email: company.email,
          plan_expiry_date: formatDate(plan_expiry_date),
          onboarding_date: formatDate(onboarding_date),
          avg_response_time: data.data.avg_response_time,
          escalations_count: data.data.escalations_count,
          total_interaction_count: data.data.total_interaction_count,
          total_message_count: data.data.total_message_count,
          last_ticket_date:
            last_ticket_date === ""
              ? "No ticket"
              : formatDate(last_ticket_date),
          last_ticket_no,
        };
        console.log(obj);
        arr.push(obj);
        setCompanyListData(arr);
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleDateRangeChange = (start, end, label) => {
    // Callback function for handling date range changes
    // let startDate = start.format("DD-MM-YYYY").toString();
    // let endDate = end.format("DD-MM-YYYY").toString();

    // let dates = {
    //   startDate: start.format("YYYY-MM-DD").toString(),
    //   endDate: end.format("YYYY-MM-DD").toString(),
    // };
    let dates = {
      startDate: start,
      endDate: end,
    };
    showNafiadataonDashboard(CompanyListNafia, dates);
    // showNafiaRecentInteractions(NAFIA_USER_LIST, dates, companyID, botID);
  };

  useEffect(() => {
    // Fetch the list of all countries from the REST Countries API
    
    // Call the function to fetch data when the component mounts
    // showNafiaData(URL_FOR_NAFIA_ADMIN_DASH);
    // const datepicker = $(inputRef.current);
    // datepicker.daterangepicker(
    //   {
    //     timePicker: true,
    //     startDate: moment().subtract(7, "days").startOf("day"), // Set start date to 7 days ago
    //     endDate: moment().add(2, "days").endOf("day"), // Set end date to today
    //     locale: {
    //       format: "DD/MM/YYYY",
    //     },
    //   },
    //   handleDateRangeChange
    // );

    // Call handleDateRangeChange on component load
    // const startDate = datepicker.data("daterangepicker").startDate;
    // const endDate = datepicker.data("daterangepicker").endDate;

    let startDate = "2023-01-01";
    let endDate = "2023-12-28";
    handleDateRangeChange(startDate, endDate);
  }, []);

  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);

  const handleSaveChangesdynamic = async (_, url_for_save) => {
    setshowLoaderAdmin(true);
    console.log(url_for_save);
    const formData = {
      company_data: {
        company_name: newcustomerName,
        email: email,
        mobile_no: mobileNumber,
        company_image: "selectedImage",
        city: userCountry.id,
        admin_commission: commission,
      },
      user_data: {
        name: userName,
        email: email,
        mobile_no: mobileNumber,
        password: password,
      },
    };
    console.log(formData);
    setshowLoaderAdmin(false);
    const jsonData = JSON.stringify(formData);

    const headers_data = {
      "Content-Type": "application/json",
    };

    const res = await fetch(url_for_save, {
      method: "POST",
      body: jsonData,
      headers: headers_data, // Remove the nesting here
    });
    console.log(res);

    if (res.status !== 201) {
      alert("Something went wrong");
    } else {
      setnewcustomerName("");
      setuserName("");
      setpassword("");
      setmobileNumber("");
      setemail("");
      setuserCountry("");
      setSelectedImage(null);
      handleCloseModal();
      const startDate = moment().subtract(365, "days").startOf("day");
      const endDate = moment().add(2, "days").endOf("day");

      handleDateRangeChange(startDate, endDate);
    }

    //   .catch((error) => {
    //     setshowLoaderAdmin(false);
    //   });
  };

  const [graphData, setGraphData] = useState({
    labels: ["January", "February", "March", "April", "May", "June"],
    datasets: [
      {
        label: "Total Collection",
        data: [30, 38, 26, 34, 43, 28, 27],
        borderColor: "#006AFF", // Set line color to #006AFF
        backgroundColor: "#006AFF40",
        tension: 0.37,
        pointRadius: 2,
        fill: true,
      },
    ],
  });
  // Configuration options
  const options = {
    scales: {
      x: {
        title: {
          display: false,
        },
        grid: {
          display: false, // Hide the grid lines for the y-axis
        },
      },
      y: {
        title: {
          display: false,
        },
        grid: {
          display: false, // Hide the grid lines for the y-axis
        },
      },
    },
    plugins: {
      tooltip: {
        mode: "nearest",
      },
    },
  };

  const [datarecovry, setDataRecovery] = useState({
    labels: [
      "Imp. Ticket Raised",
      "Happy",
      "Unsatisfied",
      "Feedback not submit",
    ],
    datasets: [
      {
        data: [10, 45, 13, 32],
        backgroundColor: ["#FF007A", "#4623E9", "#0070C0", "#DCD9E8"],
      },
    ],
  });

  const optionsrecovry = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: "60%",
    plugins: {
      legend: {
        display: false, // Hide legend
      },
    },
    scales: {
      x: {
        display: false, // Hide x-axis
      },
      y: {
        display: false, // Hide y-axis
      },
    },
    elements: {
      arc: {
        backgroundColor: "transparent", // Set the background color to transparent
      },
    },
  };

 

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setSelectedImage(null);
    }
  };

  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleFileChangedynamic = (keyname) => (event) => {
    const file = event.target.files[0];

    let new_file_name = keyname + "_show";

    if (file) {
      const reader = new FileReader();

      reader.onload = () => {
        setDynaicimage((prevImages) => ({
          ...prevImages,
          [keyname]: file,
          [new_file_name]: reader.result,
        }));
      };
      reader.readAsDataURL(file);
    } else {
      setDynaicimage((prevImages) => ({
        ...prevImages,
        [keyname]: null,
        [new_file_name]: null,
      }));
    }
  };

  return (
    <div className="dashboard">
      <Helmet>
        <title>Nafia Dashboard</title>
        <meta
          name="description"
          content="AI and Global Reach: The Key to Scalable Solutions in Customer
          Experience"
        />
      </Helmet>
      <div className="dashboard_container">
        <div className="dashboard_wrapper">
          <NAfiaAdminSideBar />
          <div id="content">
            <CenterNavNafiaAuperAdmin />
            <div className="Dashpages_contaioner">
              <div className="overview">
                <div className="overviewContaINER">
                  <div className="adminoverviewHead">
                    <div className="adminoverviewHead_conrTAINER">
                      {/* <div className="adminoverviewSearch">
                        <img src={SearchImg} alt="icon" />
                        <input
                          type="text"
                          placeholder="Search for file, folder, etc..."
                        />
                      </div> */}
                    </div>
                    <div className="btnClientNo">
                      <div className="ttlClientNo">
                        <div className="ttlClientNoTxt">
                          <img src={ProfileImg} alt="img" />
                          <p>Total Customers </p>
                        </div>
                        <hr className="ttlClientNoHr" />
                        <h5>{Nafiaadmindata.total_customers}</h5>
                      </div>
                      <div className="AddNafiaUsrBtn">
                        <button onClick={() => handleShowModal(0)}>
                          Add Customer
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="nafiaADminDataTable">
                    <div className="nafiaTAbleCOntainer">
                      <table className="table nafiaTAble">
                        <thead>
                          <tr>
                            <th scope="col">ID</th>
                            <th scope="col">Company Name</th>
                            <th scope="col">Onboarding Date</th>
                            <th scope="col">Average Duration</th>
                            <th scope="col">Interaction</th>
                            <th scope="col">
                              Satisfaction Index{" "}
                              <span className="postMvp">Post MVP</span>
                            </th>
                            <th scope="col">Subscription Expiry</th>
                            <th scope="col">Last Ticket</th>
                          </tr>
                        </thead>
                        <tbody>
                          {companyListData.map((company, idx) => {
                            return (
                              <tr key={idx}>
                                <td>
                                  <div className="tableData1 tabledata1radius">
                                    <p>#{company.id}</p>
                                  </div>
                                </td>
                                <td>
                                  <div className="tableData1">
                                    <p>{company.name}</p>
                                  </div>
                                </td>
                                <td>
                                  <div className="tableData1">
                                    <p>{company.onboarding_date}</p>
                                  </div>
                                </td>
                                <td>
                                  <div className="tableData2">
                                    <p>{company.avg_response_time} sec</p>
                                  </div>
                                </td>
                                <td>
                                  <div className="tableData1">
                                    <p>{company.total_interaction_count}</p>
                                  </div>
                                </td>
                                <td>
                                  <div className="tableData1">
                                    <p>Happy</p>
                                  </div>
                                </td>
                                <td>
                                  <div className="tableData3">
                                    <p>{company.plan_expiry_date}</p>
                                  </div>
                                </td>
                                <td>
                                  <div className="tableData1 tableData4">
                                    <p
                                      style={{ margin: "0", color: "#343A40" }}
                                    >
                                      #{company.last_ticket_no.substring(0, 7)}
                                      ...
                                      <br />
                                      {company.last_ticket_date}
                                    </p>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div className="nafiaAdminGraphsRow">
                    <div className="row m-0">
                      <div className="col-md-8">
                        <div className="areaCHArtDiv mt-0">
                          <div className="chartHeading">
                            <div className="chartHeadingtext-left">
                              <h4>
                                Growth & Revenue{" "}
                                <span className="postMvp">Post MVP</span>
                              </h4>
                            </div>
                            <div className="chartHeadingtext-right">
                              
                            </div>
                          </div>
                          <div className="p-3">
                            <Line data={graphData} options={options} />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="piechatrDiv">
                          <p style={{ fontWeight: "500" }}>
                            Satisfaction Index{" "}
                            <span className="postMvp">Post MVP</span>
                          </p>
                          <div
                            style={{
                              width: "100%",
                              height: "250px",
                              position: "relative",
                            }}
                          >
                            <Doughnut
                              data={datarecovry}
                              options={optionsrecovry}
                              style={{ width: "100%" }}
                            />
                            <div
                              style={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                textAlign: "center",
                                fontSize: "16px",
                              }}
                            >
                              Recovery <br /> Status
                            </div>
                            <div className="recoveryPercent">
                              <p
                                style={{
                                  color: "#000",
                                  fontSize: "13px",
                                  fontWeight: "400",
                                }}
                              >
                                Happy
                              </p>
                            </div>
                            <div className="disputePercent">
                              <p
                                style={{
                                  color: "#000",
                                  fontSize: "13px",
                                  fontWeight: "400",
                                }}
                              >
                                Imp. Ticket <br /> Raised
                              </p>
                            </div>
                            <div className="L2Percent">
                              <p
                                style={{
                                  color: "#000",
                                  fontSize: "13px",
                                  fontWeight: "400",
                                }}
                              >
                                Unsatisfied
                              </p>
                            </div>
                            <div className="L3Percent">
                              <p
                                style={{
                                  color: "#000",
                                  fontSize: "13px",
                                  fontWeight: "400",
                                }}
                              >
                                Feedback <br /> Not Submitted
                              </p>
                            </div>
                          </div>
                          <div className="legendsContainer">
                            <div className="row legendsRow m-0">
                              <div className="col-xl-6 p-0">
                                <div className="legendDetail">
                                  <div
                                    className="recoveryColor"
                                    style={{ backgroundColor: "#32AE1E" }}
                                  ></div>
                                  <p>{datarecovry.labels[1]}</p>
                                </div>
                              </div>
                              <div className="col-xl-6 p-0">
                                <div className="legendDetail">
                                  <div className="disputeColor"></div>
                                  <p>{datarecovry.labels[0]}</p>
                                </div>
                              </div>
                              <div className="col-xl-6 p-0">
                                <div className="legendDetail">
                                  <div className="L2Color"></div>
                                  <p>{datarecovry.labels[2]}</p>
                                </div>
                              </div>
                              <div className="col-xl-6 p-0">
                                <div className="legendDetail">
                                  <div className="pendingColor"></div>
                                  <p>{datarecovry.labels[3]}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="reportPieChart">
                    <div className="reportPieChartcontainer"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        dialogClassName="custom-modal2"
        centered
        size="xl"
      >
        <Modal.Header>
          <h5 className="mt-3">Add Customer</h5>
        </Modal.Header>
        <Modal.Body>
          <div className="mngUserContainer px-5">
            <form className="needs-validation" id="CarrerformData">
              <div className="row m-0">
                <div className="col-md-6">
                  <div className="mngUserInput">
                    <label>Company Name</label>
                    <br />
                    <input
                      type="text"
                      onChange={(e) => setnewcustomerName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mngUserInput">
                    <label>Upload Image</label>
                    <br />
                    <input
                      type="file"
                      className="form-control  trio_mandatory"
                      name="admin_image"
                      onChange={handleFileChangedynamic("admin_image")}
                      accept=".jpg,.jpeg,.png"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mngUserInput">
                    <label>Full Name</label>
                    <br />
                    <input
                      type="text"
                      className="form-control  trio_mandatory"
                      onChange={(e) => setuserName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mngUserInput">
                    <label>Email</label>
                    <br />
                    <input
                      type="Email"
                      placeholder="Email"
                      className="form-control  trio_mandatory"
                      onChange={(e) => setemail(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mngUserInput">
                    <label>Mobile No.</label>
                    <br />
                    <input
                      type="number"
                      className="form-control  trio_mandatory"
                      onChange={(e) => setmobileNumber(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mngUserInput">
                    <label>Password</label>
                    <br />
                    <input
                      type="password"
                      className="form-control  trio_mandatory"
                      onChange={(e) => setpassword(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mngUserInput">
                    <label>Location</label>
                    <br />
                    <Dropdown
                      value={userCountry}
                      onChange={(e) => setuserCountry(e.value)}
                      options={countries}
                      optionLabel="name"
                      placeholder="Select Location"
                      className="controlSlct"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="updateCnclebtnDiv">
            <button className="updateCnclebtnCan" onClick={handleCloseModal}>
              Cancel
            </button>
            <button
              className="updateCnclebtnUp"
              onClick={() =>
                handleSaveChangesdynamic("CarrerformData", CompanyListNafia)
              }
            >
              Add
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default NafiaAdminClientMngt;
