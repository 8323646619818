import React from "react";
import "../Css/Error404.css";
import { Helmet } from "react-helmet";

const Error404 = () => {
  return (
    <div className="container">
      <Helmet>
        <title>Error 404</title>
        <meta
          name="description"
          content="The page you're looking for doesn't exist."
        />
      </Helmet>
      <div className="error-content">
        <h1>404</h1>
        <p>Oops! The page you're looking for doesn't exist.</p>
        <a href="/">Go back to the homepage</a>
      </div>
      <div className="animated-bg"></div>
    </div>
  );
};

export default Error404;
