import React, { useRef } from "react";
import { Link } from "react-router-dom";
import "../Css/Home2.css";
import "../CommonJquery/FunctionJquery.js";
import Header from "./Header";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import TestiImg from "../assets/images/testiImg.png";
import LeftQoute from "../assets/images/leftQoute.svg";
import RightQoute from "../assets/images/right.svg";
import blog1 from "../assets/images/blog1.png";
import blog2 from "../assets/images/blog2.png";
import blog3 from "../assets/images/blog3.png";
import blog4 from "../assets/images/blog4.png";
import blog5 from "../assets/images/debtBlogImg.png";
import blog6 from "../assets/images/blog6.png";
import Footer from "./Footer";
import MainLandImg from "../assets/images/nafiaNewBLue.png";
import SoluImg from "../assets/images/Headphonecolor.png";
import Testiimg0 from "../assets/images/Testiimg0.png";
import TestiImg2 from "../assets/images/Afsanaimg.jpg";
import "../CommonJquery/CommonJquery";
import NewsLetter from "./NewsLetter";
import { Helmet } from "react-helmet";

function ProductNafia() {
  const options = {
    loop: true,
    margin: 10,
    nav: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };

  const testimonialsRef = useRef(null);

  const scrollToTestimonials = () => {
    if (testimonialsRef.current) {
      const testimonialsElement = testimonialsRef.current;
      const testimonialsOffset = testimonialsElement.offsetTop;
      const scrollToPosition =
        testimonialsOffset -
        6 * parseFloat(getComputedStyle(document.documentElement).fontSize);

      window.scrollTo({
        top: scrollToPosition,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="prdctNafia">
      <Helmet>
        <title>Isabella</title>
        <meta
          name="description"
          content="Elevating Your Experience with Personalization at its Finest."
        />
      </Helmet>
      <div className="home_container">
        <header className="header_container">
          <Header scrollToTestimonials={scrollToTestimonials} />
        </header>

        <div className="home_content">
          <section className="welcome_screen main_welcome_screen container-lg m-auto my-5">
            <div className="welcome_screen_container">
              <div className="row wlcmContentRow">
                <div className="wlcm_left col-md-5 col-sm-5">
                  <div className="wlcmText">
                    <p>We Are Here</p>
                    <h3>
                      Elevating Your Experience with Personalization at its
                      Finest
                    </h3>
                    <p className="wlcmCnt">
                      Technotask empowers you to swiftly craft a cutting-edge
                      digital persona, allowing you to create a personalized and
                      efficient customer service agent in just minutes.
                    </p>
                    <div className="getInBtn">
                      <Link to="/book_demo">
                        <button>Book A Demo Today!</button>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="wlcm_right col-xl-6 col-lg-6 col-sm-6 col-11 m-auto">
                  <div className="mainLandImg">
                    <img src={MainLandImg} alt="icon" />
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="solution">
            <div className="solution_container container-lg">
              <div className="solution1">
                <div className="solutionRow row">
                  <div className="soluLeft col-md-4 col-11 m-auto">
                    <img src={SoluImg} alt="icon" />
                  </div>
                  <div className="soluRight col-lg-6 col-md-8 m-auto">
                    <div className="soluText">
                      <p>What We Do</p>
                      <h3>
                        Revolutionising Customer Engagement with Human-Like
                        Avatars with Generative AI
                      </h3>
                      <h6>
                      Isabella emerges as one of the dazzling solutions, which
                        replaces inefficient and time-draining customer support
                        with graceful generative AI. Isabella conjures instant
                        assistance while shortening response times leaving
                        consumers in awe. With each interaction, it enhances
                        customer satisfaction which echoes through the digital
                        realm.
                      </h6>
                      <div className="getInBtnWhite">
                        <button>Get Started </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section>
            <div className="aboutNafia_container container-lg">
              <div className="aboutNafia_text nafiaprdctText">
                <h4>
                  How Isabella powered by Generative AI can improvise the Customer
                  experience?
                </h4>
                <ul>
                  <li>
                    <b>Instant Responses: </b>Isabella unleashes instant
                    assistance, obliterates wait times and skyrockets customer
                    satisfaction.
                  </li>
                  <li>
                    <b>Personalisation: </b>It crafts bespoke interactions that
                    mirror the desire of each customer while elevating
                    engagements to a captivating and enchanting experience
                  </li>
                  <li>
                    <b>Consistency: </b>Isabella is responsible for delivering
                    unwavering accuracy in each interaction while forging trust
                    and consistency
                  </li>
                  <li>
                    <b>Insights that are data-driven: </b>It furnishes
                    actionable analytics for discerning intricate patterns in
                    customer preferences and behaviour, further facilitating
                    proactive and data-driven enhancements for optimal
                    performance.
                  </li>
                  <li>
                    <b>Scalability: </b>Isabella efficiently manages concurrent
                    interactions demonstrating its scalable capacity and
                    maintaining service quality integrity while accommodating
                    substantial growth.
                  </li>
                  <li>
                    <b>24/7 Availability: </b>It is responsible for establishing
                    continuous 24/7 accessibility while guaranteeing perpetual
                    assistance availability of customers and thereby fostering
                    seamless and uninterrupted experience.
                  </li>
                </ul>
              </div>
            </div>
          </section>

          <section className="talkGptBlack">
            <div className="container-lg">
              <div className="talkGpt talkGptBlackPadding">
                <h4>Talk to Isabella</h4>
                <p>
                  Gain exclusive access to innovative self-animating and
                  generative AI technology which offers a glimpse into the
                  future.
                </p>
                <div className="lets_talk_btn">
                  <Link to="/Nafia">
                    <button>Lets’ Talk with Isabella</button>
                  </Link>
                </div>
              </div>
            </div>
          </section>

          <section ref={testimonialsRef} className="testimonials container-lg">
            <div className="testimonials_container">
              <div className="testi_slider_container">
                <OwlCarousel className="owl-theme" {...options}>
                  <div className="item">
                    <div className="testiItem col-11 mx-auto">
                      <h5>TESTIMONIALS</h5>
                      <div className="testiItemCont">
                        <img
                          className="textiImg"
                          src={Testiimg0}
                          alt="Testimonial"
                        />
                        <h5>JOE</h5>
                        <h6>E-Commerce Giant</h6>
                        <div className="testiContent col-lg-7 col-md-8 col-sm-10 col-11">
                          <div className="left_qoute">
                            <img
                              className="qouteImg"
                              src={LeftQoute}
                              alt="icon"
                            />
                          </div>
                          <p>
                            Your support and efforts have certainly contributed
                            to our success so far. We still have a long way to
                            go but with your constant endeavors 85% of TNPS &
                            BPO scores do not look like a distant dream.
                          </p>
                          <div className="right_qoute">
                            <img
                              className="qouteImg"
                              src={RightQoute}
                              alt="icon"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <div className="testiItem col-11 mx-auto">
                      <h5>TESTIMONIALS</h5>
                      <div className="testiItemCont">
                        <img
                          className="textiImg"
                          src={TestiImg}
                          alt="Testimonial"
                        />
                        <h5>VARSHA</h5>
                        <h6>E-Commerce Giant</h6>
                        <div className="testiContent col-lg-7 col-md-8 col-sm-10 col-11">
                          <div className="left_qoute">
                            <img
                              className="qouteImg"
                              src={LeftQoute}
                              alt="icon"
                            />
                          </div>
                          <p>
                            The significant rise in productivity from the
                            operations team has been massive which has helped us
                            achieve our targeted answering numbers during the
                            BIG BOLD sale period and ensure the quality of calls
                            are not compromised, we have been able to move from
                            30% to 78% now and marching towards 85%.
                          </p>
                          <div className="right_qoute">
                            <img
                              className="qouteImg"
                              src={RightQoute}
                              alt="icon"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <div className="testiItem col-11 mx-auto">
                      <h5>TESTIMONIALS</h5>
                      <div className="testiItemCont">
                        <img
                          className="textiImg"
                          src={TestiImg2}
                          alt="Testimonial"
                        />
                        <h5>AFSANA</h5>
                        <h6>Founder of KSG Enterpirse</h6>
                        <div className="testiContent col-lg-7 col-md-8 col-sm-10 col-11">
                          <div className="left_qoute">
                            <img
                              className="qouteImg"
                              src={LeftQoute}
                              alt="icon"
                            />
                          </div>
                          <p>
                            Your continued dedication and hard work have played
                            a crucial role in our ongoing success. While we
                            still have a miles to go, your consistent efforts
                            have brought us closer to our goal of achieving 85%
                            satisfaction in TNPS and BPO scores.
                          </p>
                          <div className="right_qoute">
                            <img
                              className="qouteImg"
                              src={RightQoute}
                              alt="icon"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </OwlCarousel>
              </div>
            </div>
          </section>

          <section className="blog">
            <div className="blog_section container-lg">
              <h4 className="col-md-8 col-11 mx-auto">
                AI Innovations are Catalyzing a Communicational Revolution
              </h4>
              <div className="card_container">
                <div className="row">
                  <div className=" col-md-4 col-sm-5 mx-auto p-1">
                    <Link to="/Driving Efficiency and Elevated Experiences">
                      <div className="card">
                        <div className="image">
                          <img src={blog1} alt="Card 1" />
                        </div>
                        <div className="blogtext_container">
                          <h6 style={{ textAlign: "left" }} className="my-2">
                            Driving Efficiency and Elevated Experiences:
                            Insights into Cost-Effective Service Improvement
                          </h6>
                          <div className="blogtext">
                            <p>
                              Efficiency in the process of customer service
                              means the fulfilment of customer service goals
                              with the minimum amount of effort from both sides.
                              Efficiency can be considered intelligent laziness
                              and doing the right things with as little effort
                              as possible. It is not about saving resources and
                              money or simply doing things fast. Fewer efforts
                              mean, for example, the agents of customer service
                              operate with minimal resource wastage but the
                              consumer achieves their goals in as few steps as
                              possible as well.
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="col-md-4 col-sm-5 mx-auto p-1">
                    <Link to="/The Perfect Blend">
                      <div className="card">
                        <div className="image">
                          <img src={blog2} alt="Card 1" />
                        </div>
                        <div className="blogtext_container">
                          <h6 style={{ textAlign: "left" }} className="my-2">
                            The Perfect Blend: Why a Mix of Virtual and Real Is
                            Essential for Exceptional CX
                          </h6>
                          <div className="blogtext">
                            <p>
                              Digital customer experience or DCX refers to how
                              consumers engage and interact with business brands
                              online. It includes online platforms across
                              desktop and mobile and digitally mediated
                              experiences through social media content and own
                              applications. It also extends to environments
                              connected digitally like IoT and devices that are
                              voice-activated. Ultimately, wherever your
                              consumers are engaging with your brand in the
                              digital landscape becomes a digital experience.
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="col-md-4 col-sm-5 mx-auto p-1">
                    <Link to="/AI and Global Reach">
                      <div className="card">
                        <div className="image">
                          <img src={blog3} alt="Card 1" />
                        </div>
                        <div className="blogtext_container">
                          <h6 style={{ textAlign: "left" }} className="my-2">
                            AI and Global Reach: The Key to Scalable Solutions
                            by Technotask
                          </h6>
                          <div className="blogtext">
                            <p>
                              AI or Artificial Intelligence has become one of
                              the transformative forces in today’s era of
                              technological advancements. The influence of AI
                              extends across various industries by
                              revolutionising the way we live, interact and
                              work. From healthcare and education to finance and
                              entertainment, AI is reshaping traditional
                              practices and opening up exciting and emerging
                              possibilities.
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>

                <div className="row mt-3 d-none d-sm-flex">
                  <div className="col-md-4 col-sm-5 mx-auto p-1">
                    <Link to="/Unleashing the Potential of Gen AI">
                      <div className="card">
                        <div className="image">
                          <img src={blog4} alt="Card 1" />
                        </div>
                        <div className="blogtext_container">
                          <h6 style={{ textAlign: "left" }} className="my-2">
                            Unleashing the Potential of Gen AI: Elevating
                            Customer Experiences Through Omni-Channel Engagement
                            Introduction
                          </h6>
                          <div className="blogtext">
                            <p>
                              Omnichannel means that customers can discover
                              one’s brand through multiple channels like social
                              media, search engines, email newsletters and more.
                              The primary objective of omnichannel isn’t just
                              about ensuring customer access to your products.
                              It is also about making sure they know the value
                              of those products along with their cost and their
                              availability in the market. This is exactly where
                              AI comes into play.
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="col-md-4 col-sm-5 mx-auto p-1">
                    <Link to="/Innovations in Debt Collection">
                      <div className="card">
                        <div className="image">
                          <img src={blog5} alt="Card 1" />
                        </div>
                        <div className="blogtext_container">
                          <h6 style={{ textAlign: "left" }} className="my-2">
                            Innovations in Debt Collection: Enhancing Customer
                            Experiences and Recovery Rates
                          </h6>
                          <div className="blogtext">
                            <p>
                              Debt collection plays a pivotal part in the
                              financial sector. It helps in the measurement of
                              cash flow for any organisation. Track and
                              assessment will help in the identification and
                              avoiding slow-paying customers. In addition to
                              that, debt collection helps in avoiding paying
                              late fees to vendors and preventing opening a line
                              of credit. Furthermore, it helps to ensure that
                              debts are paid in full and on time which helps in
                              the maintenance of financial stability for the
                              lender as well as the borrower along with the
                              promotion of responsible borrowing through
                              reminding individuals and businesses of their
                              financial obligations.
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="col-md-4 col-sm-5 mx-auto p-1">
                    <Link to="/AI Revolution in Customer Experience 2023">
                      <div className="card">
                        <div className="image">
                          <img src={blog6} alt="Card 1" />
                        </div>
                        <div className="blogtext_container">
                          <h6 style={{ textAlign: "left" }} className="my-2">
                            AI Revolution in Customer Experience 2023
                          </h6>
                          <div className="blogtext">
                            <p>
                              In the contemporary era’s business landscape,
                              which is highly competitive in nature,
                              organisations need to provide a great customer
                              experience to succeed and stand out from their
                              competitors. Customer experience can be considered
                              the sum of all customer experiences with a
                              particular brand from its first point of contact
                              to post-purchase support. CX or customer
                              experience encompasses every touchpoint an
                              organisation has with an organisation including
                              sales, customer service, marketing and the usage
                              of product.
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="seeAll">
                <Link to="/resources">
                  <button className="seeAllBtn">See All...</button>
                </Link>
              </div>
            </div>
          </section>

          <section className="newsletter container-lg">
            <div className="outer-div">
              <div className="inner-div col-lg-7 col-md-9">
                <h4 className="newsltrHeading">Subscribe To Our Newsletter</h4>
                <p className="newsltrtext">
                  Discover exclusive insights into our irresistible deals and
                  newest offerings. Join our newsletter and be in the know!
                </p>

                <NewsLetter />
              </div>
            </div>
          </section>

          <footer className="footer_area section_padding_130_0 px-lg-5 px-md-4 px-sm-3 px-1">
            <Footer />
          </footer>
        </div>
      </div>
    </div>
  );
}

export default ProductNafia;
