import React, { useState } from "react";
import "../../Dasboard/Css/Dashboard.css";
import "../../Dasboard/Css/Bucket30days.css";
import "../../Dasboard/Css/SettingPage.css";
import NafiaSideBar from "./NafiaSideBar.js";
import CircleCheck from "../../assets/images/circle_check.svg";
import NotificationsImg from "../../assets/images/notification.svg";
import SideBarControl from "../../assets/images/rightLeftSIdebar.png";
import UserImgPrf from "../../assets/images/userimg45.svg";
import adm_img from "../../assets/images/adm_img.png";
import "react-circular-progressbar/dist/styles.css";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import userBtn from "../../assets/images/usermngmt.svg";
import invBtn from "../../assets/images/invoicee.svg";
import subsButton from "../../assets/images/subscriptions.svg";
import subsButtonWhite from "../../assets/images/subscriptionsblue.svg";
import pymtIcon from "../../assets/images/pymtIcon23.svg";
import redirectvector from "../../assets/images/redirectvector.png";
import RightaRROW from "../../assets/images/rightmorethan.svg";
import RightaRROWWhite from "../../assets/images/rightmorethanblue.svg";
import { Link, useLocation } from "react-router-dom";
import removeIcon from "../../assets/images/removeIcon23.svg";
import AddIcon from "../../assets/images/addIcon23.svg";
import Logout from "../../assets/images/giflogout.gif";
import Technotask from "../../assets/images/TechnotaskLogo12.png";
import NafiaUserCenterNav from "./NafiaUserCenterNav.js";
import { Helmet } from "react-helmet";

function DebtorProfile60() {
  const [overviewShow, setOverviewShow] = useState(true); // Default to 'true'
  const [debtorProfile, setdebtorProfile] = useState(false);
  const location = useLocation();
  return (
    <div className="dashboard">
      <Helmet>
        <title>Nafia User Dashboard</title>
        <meta
          name="description"
          content="AI and Global Reach: The Key to Scalable Solutions in Customer
          Experience"
        />
      </Helmet>
      <div className="dashboard_container">
        <div className="dashboard_wrapper">
          <NafiaSideBar
            overviewShow={overviewShow}
            setOverviewShow={setOverviewShow}
            debtorProfile={debtorProfile}
            setdebtorProfile={setdebtorProfile}
          />
          <div id="content">
            <NafiaUserCenterNav />

            <div className="AmraConversations">
              <div className="AmraConversations_container">
                <div className="AmraConversations_container_wrapper">
                  <div className="overviewContaINER pb-0">
                    <div className="col-md-8 overviewHead overviewHeaddp mb-1">
                      <h4>Settings</h4>
                    </div>
                  </div>
                  <div className="subscript">
                    <div className="row m-0 justify-content-center">
                      <div className="col-lg-4 col-md-5 column1 pt-0">
                        <div className="circleProgBar m-auto">
                          <div className="collecBar">
                            <div style={{ width: "60px" }}>
                              <CircularProgressbar
                                value={75}
                                text={`${75}%`}
                                styles={buildStyles({
                                  textColor: "#fff", // Color of the text
                                  trailColor: `blank`, // Color of the progress circle
                                  pathColor: "rgba(255, 255, 255, 1)", // Color of the background trail
                                })}
                              />
                            </div>
                            <div className="profileTxt">
                              <p>Profile Information </p>
                              <button className="completeProfileBtn">
                                Complete your profile
                              </button>
                            </div>
                          </div>
                          <div className="button-container23">
                            <button className="custom-button23">
                              <div
                                className={`insdbtndiv ${
                                  location.pathname === "/NafiaSettingPage01"
                                    ? "activemainSettingItem"
                                    : ""
                                }`}
                              >
                                <img
                                  src={
                                    location.pathname === "/NafiaSettingPage01"
                                      ? subsButtonWhite
                                      : subsButton
                                  }
                                  alt="ICON"
                                />
                                <p>Subscriptions</p>
                              </div>
                              <div>
                                <img
                                  src={
                                    location.pathname === "/NafiaSettingPage01"
                                      ? RightaRROWWhite
                                      : RightaRROW
                                  }
                                  alt="ICON"
                                />
                              </div>
                            </button>
                            <Link to="NafiaInvoice">
                              <button className="custom-button23">
                                <div className="insdbtndiv">
                                  <img src={invBtn} alt="ICON" />
                                  <p>Invoice</p>
                                </div>
                                <div>
                                  <img src={RightaRROW} alt="ICON" />
                                </div>
                              </button>
                            </Link>
                            <Link to="NafiaUserManagement">
                              <button className="custom-button23">
                                <div className="insdbtndiv">
                                  <img src={userBtn} alt="ICON" />
                                  <p>User Management</p>
                                </div>
                                <div>
                                  <img src={RightaRROW} alt="ICON" />
                                </div>
                              </button>
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-5 col-md-5 column2">
                        <h5 className="mb-3">
                          <b>Subscriptions</b>
                        </h5>
                        <div className="currentPlan">
                          <p className="crntPlanHead">CURRENT PLAN</p>
                          <hr
                            className="mt-1"
                            style={{ borderTop: "2px solid" }}
                          />
                          <p className="teamPack">Team Package</p>
                          <h6 className="perMonth">$10 per month</h6>
                          <div className="d-flex" style={{ gap: "1rem" }}>
                            <Link to="/BillingForm">
                              <button className="upgbtn">Upgrade plan</button>
                            </Link>
                            <button className="canbtn">Cancel plan</button>
                          </div>
                          <p className="planRenew">
                            Your plan renews on October 1, 2021
                          </p>
                        </div>

                        <div className="pymtMethod">
                          <p className="crntPlanHead">PAYMENT METHOD</p>
                          <hr
                            className="mt-1"
                            style={{ borderTop: "2px solid" }}
                          />
                          <div className="paymentmthdContainer">
                            <div className="pymtmthdOld">
                              <img src={pymtIcon} alt="icon" />
                              <div className="expiryDiv">
                                <p>Expires 04/2022</p>
                                <img src={removeIcon} alt="icon" />
                              </div>
                            </div>
                            <div className="pymtmthdNew">
                              <button>
                                <img src={AddIcon} alt="icon" />
                                <p>Add payment method</p>
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className="billHistory">
                          <hp className="crntPlanHead">BILLING HISTORY</hp>
                          <hr
                            className="mt-1"
                            style={{ borderTop: "2px solid" }}
                          />

                          <table className="custom-table">
                            <tbody>
                              <tr>
                                <td>
                                  <div className="dateDivRow">
                                    <p>2023-11-14</p>
                                    <img src={redirectvector} />
                                  </div>
                                </td>
                                <td>
                                  <div>
                                    <p>$10</p>
                                  </div>
                                </td>
                                <td>
                                  <div>
                                    <p>Team Package</p>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="dateDivRow">
                                    <p>2023-11-14</p>
                                    <img src={redirectvector} />
                                  </div>
                                </td>
                                <td>
                                  <div>
                                    <p>$10</p>
                                  </div>
                                </td>
                                <td>
                                  <div>
                                    <p>Team Package</p>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="dateDivRow">
                                    <p>2023-11-14</p>
                                    <img src={redirectvector} />
                                  </div>
                                </td>
                                <td>
                                  <div>
                                    <p>$10</p>
                                  </div>
                                </td>
                                <td>
                                  <div>
                                    <p>Team Package</p>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="brandingContainer">
              <div>
                <h5>Powered by</h5>
                <img src={Technotask} alt="Logo" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DebtorProfile60;
