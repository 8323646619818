import React from "react";
import "../../Dasboard/Css/SideBar.css";
import Dashboardwhite from "../../assets/images/dashboard.svg";
import DashboardBlack from "../../assets/images/SquaresFour.svg";
import ChatIcon1 from "../../assets/images/Ticket.png";
import ChatIcon2 from "../../assets/images/Ticket.png";
import eyeBlack from "../../assets/images/AmrUser.png";
import eyewhite from "../../assets/images/Userwhitenew.svg";
import report from "../../assets/images/CustUsers.png";
import reportWhite from "../../assets/images/CustUsers.png";
import Cube from "../../assets/images/Cube.png";
import CubeWhite from "../../assets/images/Cube.png";
import file from "../../assets/images/AFile.png";
import fileWhite from "../../assets/images/FileWhire.svg";
import Logo from "../../assets/images/logoNew.png";
import customerWhite from "../../assets/images/Users1234.svg";
import Customer from "../../assets/images/UserChartBar.png";
import { Link, useLocation } from "react-router-dom";
import gear from "../../assets/images/Gear.png";
import gearWhite from "../../assets/images/settingswhite.svg";
import AddUser from "../../assets/images/adduser23.svg";
import SignOutWhite from "../../assets/images/SignOut.png";
import CursorClick from "../../assets/images/CursorClick.png";
import CursorClickWhite from "../../assets/images/CursorClickWhite.svg";
import HelpImg from "../../assets/images/Vector.svg";
import HelpImgWhite from "../../assets/images/helpWhite.svg";
function NAfiaAdminSideBar() {
  const location = useLocation();
  return (
    <div className="sidebar">
      <div className="sidebar_container">
        <nav id="sidebar">
          <div className="sidebar-header sideBarHead sideBarHead2">
            <div className="new_chat_btn new_chat_btn2">
              <img src={Logo} alt="Logo" />
            </div>
            <hr
              style={{
                borderTop: "1px solid #F1F1F4",
                width: "100%",
                height: "2px",
                margin: "0",
              }}
            />
          </div>
          <div className="sidebar-body">
            <div className="sidebar-body-container">
              <div className="mainMenu mt-3">
                <div className="mainMenuContainer mainMenuContainer1">
                  <ul>
                    <li className="mainMenuItem2">
                      <Link to="/AmraAdmin">
                        <div
                          className={`mainMenuItem  ${
                            location.pathname === "/AmraAdmin"
                              ? "activemainMenuItem"
                              : ""
                          }`}
                        >
                          <img
                            src={
                              location.pathname === "/AmraAdmin"
                                ? Dashboardwhite
                                : DashboardBlack
                            }
                            alt="icon"
                          />
                          <p>Dashboard</p>
                        </div>
                      </Link>
                    </li>
                  </ul>

                  <ul className="mb-0">
                    <h6>ANALYTICS</h6>
                    <li className="mainMenuItem2">
                      <Link to="/AdminUserControl">
                        <div
                          className={`mainMenuItem ${
                            location.pathname === "/AdminUserControl"
                              ? "activemainMenuItem"
                              : ""
                          }`}
                        >
                          <img
                            src={
                              location.pathname === "/AdminUserControl"
                                ? customerWhite
                                : Customer
                            }
                            alt="icon"
                          />
                          <p>User Management</p>
                        </div>
                      </Link>
                    </li>
                    <li className="mainMenuItem2">
                      <Link to="/Amra_Access_Control">
                        <div
                          className={`mainMenuItem  ${
                            location.pathname === "/Amra_Access_Control"
                              ? "activemainMenuItem"
                              : ""
                          }`}
                        >
                          <img
                            src={
                              location.pathname === "/Amra_Access_Control"
                                ? CursorClickWhite
                                : CursorClick
                            }
                            alt="icon"
                          />
                          <p>
                            Access Controls{" "}
                            <span
                              className={`postMvp ${
                                location.pathname === "/Amra_Access_Control"
                                  ? "postMvpWhite"
                                  : " "
                              }`}
                            >
                              Post MVP
                            </span>
                          </p>
                        </div>
                      </Link>
                    </li>
                  </ul>

                  <div className="px-2">
                    <hr
                      style={{
                        borderTop: "1px solid #F1F1F4",
                        width: "100%",
                        height: "2px",
                      }}
                    />
                  </div>

                  <ul>
                    <h6>SUPPORT</h6>
                    {/* <li className="mainMenuItem2">
                      <Link>
                        <div
                          className={`mainMenuItem  ${
                            location.pathname === "/Nafia_conversations"
                              ? "activemainMenuItem"
                              : ""
                          }`}
                        >
                          <img
                            src={
                              location.pathname === "/Nafia_conversations"
                                ? ChatIcon2
                                : ChatIcon1
                            }
                            alt="icon"
                          />
                          <p>System Configuration</p>
                        </div>
                      </Link>
                    </li> */}
                    <li className="mainMenuItem2">
                      <Link to="/Amra_Agents">
                        <div
                          className={`mainMenuItem  ${
                            location.pathname === "/Amra_Agents"
                              ? "activemainMenuItem"
                              : ""
                          }`}
                        >
                          <img
                            src={
                              location.pathname === "/Amra_Agents"
                                ? eyewhite
                                : eyeBlack
                            }
                            alt="icon"
                          />
                          <p>Agents</p>
                        </div>
                      </Link>
                    </li>
                    <li className="mainMenuItem2">
                      <Link to="/Amra_Customers">
                        <div
                          className={`mainMenuItem  ${
                            location.pathname === "/Amra_Customers"
                              ? "activemainMenuItem"
                              : ""
                          }`}
                        >
                          <img
                            src={
                              location.pathname === "/Amra_Customers"
                                ? customerWhite
                                : report
                            }
                            alt="icon"
                          />
                          <p>Customers</p>
                        </div>
                      </Link>
                    </li>

                    <div className="px-2">
                      <hr
                        style={{
                          borderTop: "1px solid #F1F1F4",
                          width: "100%",
                          height: "2px",
                        }}
                      />
                    </div>

                    <li className="mainMenuItem2">
                      <Link to="/Amra_Analytics_Reporting">
                        <div
                          className={`mainMenuItem  ${
                            location.pathname === "/Amra_Analytics_Reporting"
                              ? "activemainMenuItem"
                              : ""
                          }`}
                        >
                          <img
                            src={
                              location.pathname === "/Amra_Analytics_Reporting"
                                ? fileWhite
                                : file
                            }
                            alt="icon"
                          />
                          <p>
                            Analytics and Reporting{" "}
                            {/* <span
                              className={`postMvp ${
                                location.pathname ===
                                "/Amra_Analytics_Reporting"
                                  ? "postMvpWhite"
                                  : " "
                              }`}
                            >
                              Post MVP
                            </span> */}
                          </p>
                        </div>
                      </Link>
                    </li>
                    <li className="mainMenuItem2">
                      <Link to="/Amra_Admin_Settings">
                        <div
                          className={`mainMenuItem  ${
                            location.pathname === "/Amra_Admin_Settings" ||
                            location.pathname ===
                              "/Amra_Admin_Settings/Amra_Admin_Invoice" ||
                            location.pathname ===
                              "/Amra_Admin_Settings/Amra_Admin_UserManagement"
                              ? "activemainMenuItem"
                              : ""
                          }`}
                        >
                          <img
                            src={
                              location.pathname === "/Amra_Admin_Settings" ||
                              location.pathname ===
                                "/Amra_Admin_Settings/Amra_Admin_Invoice" ||
                              location.pathname ===
                                "/Amra_Admin_Settings/Amra_Admin_UserManagement"
                                ? gearWhite
                                : gear
                            }
                            alt="icon"
                          />
                          <p>Settings</p>
                          <span
                            className={`postMvp ${
                              location.pathname === "/Amra_Admin_Settings" ||
                              location.pathname ===
                                "/Amra_Admin_Settings/Amra_Admin_Invoice" ||
                              location.pathname ===
                                "/Amra_Admin_Settings/Amra_Admin_UserManagement"
                                ? "postMvpWhite"
                                : " "
                            }`}
                          >
                            Post MVP
                          </span>
                        </div>
                      </Link>
                    </li>
                    <li className="mainMenuItem2">
                      <Link to="/Admin_Ticlets">
                        <div
                          className={`mainMenuItem  ${
                            location.pathname === "/Admin_Ticlets" ||
                            location.pathname ===
                              "/Admin_Ticlets/Reply_Admin_Ticlets"
                              ? "activemainMenuItem"
                              : ""
                          }`}
                        >
                          <img
                            src={
                              location.pathname === "/Admin_Ticlets" ||
                              location.pathname ===
                                "/Admin_Ticlets/Reply_Admin_Ticlets"
                                ? HelpImgWhite
                                : HelpImg
                            }
                            alt="icon"
                          />
                          <p>Help & Support</p>
                        </div>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
}

export default NAfiaAdminSideBar;
