import React, { useState, useEffect, useRef } from "react";
import "../../Dasboard/Css/Dashboard.css";
import "../../Dasboard/Css/Bucket30days.css";
import "../../Dasboard/Css/SettingPage.css";
import "../../Dasboard/Css/HelpSupport.css";
import "../../Dasboard/Css/SideBar.css";
import "../../Dasboard/Css/HomePageNafia.css";
import "../../Dasboard/Css/KUKnowledgeBase.css";
import "./../Dasboard/Css/ApexChart.css";
import { Helmet } from "react-helmet";
import { Chart as ChartJS, registerables } from "chart.js";
import NafiaSideBar from "./NafiaSideBar.js";
import Logout from "../../assets/images/giflogout.gif";
import CircleCheck from "../../assets/images/circle_check.svg";
import NotificationsImg from "../../assets/images/notification.svg";
import SideBarControl from "../../assets/images/rightLeftSIdebar.png";
import UserImgPrf from "../../assets/images/userimg45.svg";
import adm_img from "../../assets/images/adm_img.png";
import Calender from "../../assets/images/calendar12.svg";
import { Link, useLocation } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import ReactApexChart from "react-apexcharts";
import Technotask from "../../assets/images/TechnotaskLogo12.png";
import $ from "jquery";
import moment from "moment";
import "moment/locale/en-au";
import "daterangepicker";
import NafiaUserCenterNav from "./NafiaUserCenterNav.js";

function CreateNewTicket() {
  const location = useLocation();
  const [selectedHeading, setSelectedHeading] = useState(null);

  const handleHeadingClick = (heading) => {
    // Revert color of the previously clicked heading
    if (selectedHeading) {
      selectedHeading.style.color = "black !important";
    }

    // Change color of the clicked heading
    heading.style.color = "rgba(126, 50, 203, 1)";

    // Set the clicked heading as the currently selected heading
    setSelectedHeading(heading);
  };

  const [Nafiaadmindata, setdataFromNafia] = useState([]);
  ChartJS.register(...registerables);

  const [overviewShow, setOverviewShow] = useState(true); // Default to 'true'
  const [debtorProfile, setdebtorProfile] = useState(false);

  const [selectedtime, setSelectedselectedtime] = useState(null);
  const timeList = [
    { name: "This Week", code: "This Week" },
    { name: "Last Month", code: "Last Month" },
    { name: "last 3 Months ", code: "last 3 Months " },
    { name: "last 6 Months ", code: "last 6 Months " },
    { name: "last Year", code: "last Year" },
    { name: "Lifetime", code: "Lifetime" },
  ];

  const data = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
    datasets: [
      {
        label: "Process Oriented",
        data: [30, 38, 10, 15, 43, 10, 27],
        borderColor: "rgba(167, 0, 255, 1)",
        tension: 0.37,
        pointRadius: 1,
        fill: false,
      },
      {
        label: "General",
        data: [40, 48, 8, 43, 10, 47, 30],
        borderColor: "rgba(60, 216, 86, 1)",
        tension: 0.37,
        pointRadius: 1,
        fill: false,
      },
      {
        label: "Appointment",
        data: [20, 24, 19, 42, 14, 35, 32],
        borderColor: "rgba(239, 68, 68, 1)",
        tension: 0.37,
        pointRadius: 1,
        fill: false,
      },
    ],
  };

  const optionsRadial = {
    chart: {
      type: "radialBar",
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: "40%",
        },
        bar: {
          borderRadius: 10, // Set the border radius for the bars
        },
        dataLabels: {
          name: {
            fontSize: "14px",
          },
          value: {
            fontSize: "13px",
          },
          total: {
            show: false,
            formatter: function (w) {
              // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
              return 100;
            },
          },
        },
      },
    },
    labels: data.labels,
  };

  const chartDataRadial = {
    labels: ["Good", "Average", "Bad"],
    series: [70, 20, 10],
  };
  const customColors = ["#7E32CB", "#F29CF6", "#CF6269"];

  const series = chartDataRadial.series;

  const updatedData = {
    ...data, // Retain the existing structure of the data object
    datasets: data.datasets.map((dataset) => {
      let newData = [...dataset.data]; // Copy the existing data array
      // Update data based on the label name
      if (dataset.label === "Customer") {
        newData.push(Nafiaadmindata.total_customers);
      } else if (dataset.label === "Interaction") {
        newData.push(Nafiaadmindata.total_interaction);
      }
      return {
        ...dataset,
        data: newData, // Set the updated data array
      };
    }),
  };

  const options = {
    scales: {
      x: {
        title: {
          display: false,
        },
        grid: {
          display: false,
        },
      },
      y: {
        title: {
          display: false,
        },
        ticks: {
          min: 0,
          max: 50,
          stepSize: 10,
          precision: 0,
        },
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      tooltip: {
        mode: "nearest",
      },
    },
  };

  const [showListView, setShowListView] = useState(false);
  const toggleListView = () => {
    setShowListView(!showListView);
  };

  const ListView = ({ items }) => {
    return (
      <ul>
        {items.map((item, index) => (
          <ul key={index}>
            <a href={`${item.links}`}>{item.pages}</a>
          </ul>
        ))}
      </ul>
    );
  };
  const items = [
    {
      pages: ["Last Month"],
    },
    {
      pages: ["Month to Date"],
    },
    {
      pages: ["Last 3 Months"],
    },
    {
      pages: ["Last 6 Months"],
    },
    {
      pages: ["Year to Date"],
    },
    {
      pages: ["Lifetime"],
    },
  ];

  const [chartState, setChartState] = useState({
    series: [82, 18],
    options: {
      labels: ["KB", "Technical"],
      plotOptions: {
        pie: {
          donut: {
            size: "75%",
            labels: {
              show: true,
              name: {
                show: true,
              },
              value: {
                show: false,
              },
              total: {
                show: true,
                label: "Optimum Performance",
                color: "#373d3f",
                fontSize: "16px", // Increase the font size for the total label
              },
            },
          },
        },
      },
      colors: ["#14B8A6", "#F59E0B"],
    },
  });

  const inputRef = useRef(null);

  const handleDateRangeChange = (start, end, label) => {
    // Callback function for handling date range changes
    let startDate = start.format("DD-MM-YYYY").toString();
    let endDate = end.format("DD-MM-YYYY").toString();

    // handlecallevnt(startDate, endDate);
    console.log(startDate, endDate);
  };

  useEffect(() => {
    // Call the function to fetch data when the component mounts
    // $(document).ready(function () {
    $("#myTable").DataTable();
    // });

    const datepicker = $(inputRef.current);
    datepicker.daterangepicker(
      {
        timePicker: true,
        startDate: moment().startOf("hour"),
        endDate: moment().startOf("hour").add(32, "hour"),
        locale: {
          format: "DD/MM/YYYY",
        },
      },
      handleDateRangeChange
    );
    // Call handleDateRangeChange on component load
    const startDate = datepicker.data("daterangepicker").startDate;
    const endDate = datepicker.data("daterangepicker").endDate;
    handleDateRangeChange(startDate, endDate);
  }, []);

  return (
    <div className="dashboard">
      <Helmet>
        <title>Nafia User Dashboard</title>
        <meta
          name="description"
          content="AI and Global Reach: The Key to Scalable Solutions in Customer
          Experience"
        />
      </Helmet>
      <div className="dashboard_container">
        <div className="dashboard_wrapper">
          <NafiaSideBar
            overviewShow={overviewShow}
            setOverviewShow={setOverviewShow}
            debtorProfile={debtorProfile}
            setdebtorProfile={setdebtorProfile}
          />
          <div id="content">
            <NafiaUserCenterNav />
            <div className="overviewContaINER">
              <div className="overviewHead overviewHeaddp ">
                <h4>Knowledge Update</h4>
                <div className="overviewHeadLeft">
                  <div className="overviewHeadLeftContn">
                    <div className="date_filter_div">
                      <div className="date_filter">
                        <div className="calender_icon">
                          <label
                            style={{ cursor: "pointer" }}
                            htmlFor="datepicker"
                          >
                            <img src={Calender} alt="Image" />
                          </label>
                        </div>
                        <input
                          id="datepicker"
                          className="form-control form-control-solid"
                          placeholder="Pick date range"
                          ref={inputRef}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="knowUpdHead">
                <div className="col-xl-8 col-lg-10">
                  <div className="knowUpdHeadContainer">
                    <div
                      // className="headsOfKU"
                      className={`headsOfKU ${
                        location.pathname === "/Knowledge"
                          ? "activeheadsOfKU"
                          : ""
                      }`}
                    >
                      <Link to="/Knowledge">
                        <h6>KB Update</h6>
                      </Link>
                    </div>
                    <div
                      className={`headsOfKU ${
                        location.pathname === "/KnowledgeBase"
                          ? "activeheadsOfKU"
                          : ""
                      }`}
                    >
                      <Link to="/KnowledgeBase">
                        <h6>Knowledge Base</h6>
                      </Link>
                    </div>
                    <div
                      className={`headsOfKU ${
                        location.pathname === "/KBhealth"
                          ? "activeheadsOfKU"
                          : ""
                      }`}
                    >
                      <Link to="/KBhealth">
                        <h6>
                          KB Health &nbsp;{" "}
                          <span className="postMvp">Post MVP</span>
                        </h6>
                      </Link>
                    </div>
                    <div className="headsOfKU">
                      <h6>
                        Manage KBS &nbsp;{" "}
                        <span className="postMvp">Post MVP</span>
                      </h6>
                    </div>
                    <div className="headsOfKU">
                      <h6>
                        KB History &nbsp;{" "}
                        <span className="postMvp">Post MVP</span>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
              <div className="optmprfrmcDiv">
                <div className="col-xl-10 col-lg-11">
                  <div className="col-lg-5 col-md-6 col-sm-7 col-10 m-auto">
                    <div className="centered-divKB">
                      <div className="TechDisputes">
                        <div>
                          <p>Technical Disputes</p>
                          <h5>18%</h5>
                        </div>
                      </div>
                      <div id="chart-container" className="custom-apex-chart">
                        <ReactApexChart
                          options={chartState.options}
                          series={chartState.series}
                          type="donut"
                        />
                      </div>
                      <div className="KBDisputes">
                        <div>
                          <p>KB Disputes</p>
                          <h5>82%</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="brandingContainer">
              <div>
                <h5>Powered by</h5>
                <img src={Technotask} alt="Logo" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateNewTicket;
