import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import CenterNavbar from "./CenterNavbar.js";
import SideBar from "./SideBar";
import $ from "jquery";
import Success from "../../assets/images/success_gif.gif";
import SearchIcon from "../../assets/images/searchIcon.svg";
import Modal from "react-bootstrap/Modal";
import { uid } from "uid";
import { Link } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import "moment/locale/en-au";
import {
  server_post_data_shubham,
  server_get_data_master,
  GET_DEPARTMENT_FARIDA,
  CREATE_DOCTOR_FARIDA,
  GET_DOCTOR_FARIDA,
  GET_PATIENT_FARIDA,
  GET_APPOINTMENT_FARIDA,
  CREATE_APPOINTMENT_FARIDA,
} from "../../KeyConnection/KeysService.js";
import {
  check_vaild_save,
  combiled_form_data,
  empty_form,
  handleAphabetsChange,
  handleEmailChange,
  handleIaphabetnumberChange,
  handleNumbersChange,
} from "../../CommonJquery/CommonJquery.js";

function AmraAdminCustomers() {
  const [userLandmarks, setuserLandmarks] = useState([""]);
  const [control, setControl] = useState(null);
  const [controlDoctor, setControlDoctor] = useState(null);
  const [userType, setUserType] = useState("0"); // "registered" or "new"
  const [patientIllness, setPatientIllness] = useState("");
  const [mobileNo, setMobileNo] = useState();

  const handleIllnessChange = (event) => {
    setPatientIllness(event.target.value);
  };

  const handleUserTypeChange = (type) => {
    setUserType(type);
    setMobileNo("");
  };

  const [editoHostipallist, setEditHostipallist] = useState([]);
  const [patient, setPatient] = useState();
  const [Hostipallist, setHostipallist] = useState([]);
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const retrievedAdminId = "0";
  const hospitalID = localStorage.getItem("hospital_id") || "14";
  const [dynaicimage, setDynaicimage] = useState(null);
  const [showModal1, setShowModal1] = useState(false);
  const [editFlag, setEditFlag] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());

  const isDateValid = (date) => {
    const now = new Date();
    const currentHour = now.getHours();
    const selectedHour = date.getHours();

    // Check if the selected date is in the future
    if (date > now) {
      // Check if the selected time is within the allowed range (8 am to 8 pm)
      if (selectedHour >= 8 && selectedHour <= 20) {
        return true;
      }
    }

    return false;
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const [controlOptions, setControlOptions] = useState([]);
  const [controlOptionsDoctor, setControlOptionsDoctor] = useState([]);

  const handleShowModal1 = (call_type) => {
    if (call_type === 0) {
      setEditHostipallist([]);
      setEditFlag(0);
    } else {
      setEditHostipallist(Hostipallist[call_type - 1]);
      setEditFlag(Hostipallist[call_type - 1].doctor_id);
      setControl(controlOptions[Hostipallist[call_type - 1].gender]);
      console.log(Hostipallist[call_type - 1]);
      let arr = Hostipallist[call_type - 1].departments.split(",");
      setSelectedSpecialties([...arr]);
      console.log(arr);
      let arr2 = [];
      arr.forEach((num) => {
        departmentData.forEach((department) => {
          if (department.department_id == num) {
            arr2.push(department.department_name);
          }
        });
      });
      setSelectedSpecialtiesName([...arr2]);

      console.log("selectedSpecialties:", selectedSpecialties);
      console.log("selectedSpecialtiesName:", selectedSpecialtiesName);
    }
    setShowModal1(true);
  };

  const handleCloseModal1 = () => {
    setShowModal1(false);
  };

  useEffect(() => {
    const start_date = "";
    const end_date = "";
    const flag = "1";
    let call_id = "0";
    // $("#myTable").DataTable();
    master_data_get(start_date, end_date, flag, call_id);
  }, []);

  useEffect(() => {
    if (Hostipallist.length > 0) {
      // Destroy the DataTable if it already exists
      if ($.fn.DataTable.isDataTable("#myTable")) {
        $("#myTable").DataTable().destroy();
      }
      // Initialize DataTable
      $("#myTable").DataTable();
    }
  }, [Hostipallist]);

  const master_data_get = async (start_date, end_date, flag, call_id) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("admin_id", retrievedAdminId);
    fd.append("start_date", start_date);
    fd.append("end_date", end_date);
    fd.append("flag", flag);
    fd.append("call_id", call_id);
    fd.append("hospital_id", hospitalID);

    await server_post_data_shubham(GET_DEPARTMENT_FARIDA, fd)
      .then((Response) => {
        if (Response.data.error) {
          setshowLoaderAdmin(false);
          alert(Response.data.message);
        } else {
          console.log(Response.data.message.department_dropdown);
          setDepartmentData(Response.data.message.data_slider);
          setControlOptions(Response.data.message.department_dropdown);
          let hostiptal_list = Response.data.message.department_list;
          setDepartmentList(hostiptal_list);
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });

    await server_post_data_shubham(GET_APPOINTMENT_FARIDA, fd)
      .then((Response) => {
        if (Response.data.error) {
          alert(Response.data.message);
        } else {
          let hostiptal_list = Response.data.message.data_slider;
          console.log(hostiptal_list);
          setHostipallist(hostiptal_list);
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };

  const findRegisteredPatientDetails = async () => {
    console.log(mobileNo);
    await server_get_data_master(`${GET_PATIENT_FARIDA}${mobileNo}`)
      .then((Response) => {
        return Response.json();
      })
      .then((data) => {
        if (data.status === "error") {
          alert("No patient is registered with this number");
        } else {
          console.log(data.data);
          setPatient(data.data);
        }
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };

  const setDepartmentValue = async (value) => {
    setControl(value);
    const fd = new FormData();
    fd.append("flag", "2");
    fd.append("call_id", "0");
    fd.append("hospital_id", hospitalID);
    fd.append("department_id", value.code);
    await server_post_data_shubham(GET_DOCTOR_FARIDA, fd)
      .then((Response) => {
        if (Response.data.error) {
          alert(Response.data.message);
        } else {
          console.log(Response.data.message.data_slider);
          let hostiptal_list = Response.data.message.data_slider;
          setControlOptionsDoctor(hostiptal_list);
        }
        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };

  const handleSaveChangesdynamic = async (form_data, url_for_save) => {
    let vaild_data = check_vaild_save(form_data);
    if (vaild_data) {
      setshowLoaderAdmin(true);
      let fd_from = combiled_form_data(form_data, dynaicimage);
      let uid_for_person = uid(25);

      fd_from.append("edit_flag", editFlag);
      fd_from.append("hospital_id", hospitalID);
      fd_from.append("appointment_no", uid_for_person);
      fd_from.append("appointment_date", selectedDate);
      fd_from.set(
        "doctor_id",
        controlOptionsDoctor[fd_from.get("doctor_id")].code
      );
      fd_from.set(
        "department_id",
        controlOptions[fd_from.get("department_id")].code
      );

      await server_post_data_shubham(url_for_save, fd_from)
        .then((Response) => {
          console.log(Response.data.message);
          setshowLoaderAdmin(false);
          if (Response.data.error) {
            alert(Response.data.message);
          }
          if (!Response.data.error) {
            empty_form(form_data);
            handleCloseModal1();
            setShowModal(true);
            const start_date = "";
            const end_date = "";
            const flag = "1";
            let call_id = "0";

            master_data_get(start_date, end_date, flag, call_id);
          }
        })
        .catch((error) => {
          setshowLoaderAdmin(false);
        });
    }
  };

  const handleFileChangedynamic = (keyname) => (event) => {
    const file = event.target.files[0];

    let new_file_name = keyname + "_show";

    if (file) {
      const reader = new FileReader();

      reader.onload = () => {
        setDynaicimage((prevImages) => ({
          ...prevImages,
          [keyname]: file,
          [new_file_name]: reader.result,
        }));
      };
      reader.readAsDataURL(file);
    } else {
      setDynaicimage((prevImages) => ({
        ...prevImages,
        [keyname]: null,
        [new_file_name]: null,
      }));
    }
  };

  const [departmentList, setDepartmentList] = useState([]);
  const [departmentData, setDepartmentData] = useState([]);

  const [selectedSpecialties, setSelectedSpecialties] = useState([]);
  const [selectedSpecialtiesName, setSelectedSpecialtiesName] = useState([]);

  const handleCheckboxChange = (event) => {
    const value = event.target.value;
    const updatedSpecialties = [...selectedSpecialties];
    const updatedSpecialtiesName = [...selectedSpecialtiesName];

    let matchingDepartmentId = null;

    departmentData.forEach((department) => {
      if (department.department_name === value) {
        matchingDepartmentId = department.department_id;
      }
    });

    if (updatedSpecialties.includes("" + matchingDepartmentId)) {
      // If the specialty is already selected, remove it
      updatedSpecialties.splice(
        updatedSpecialties.indexOf("" + matchingDepartmentId),
        1
      );
    } else {
      // If the specialty is not selected, add it
      updatedSpecialties.push("" + matchingDepartmentId);
    }

    if (updatedSpecialtiesName.includes(value)) {
      // If the specialty is already selected, remove it
      updatedSpecialtiesName.splice(updatedSpecialtiesName.indexOf(value), 1);
    } else {
      // If the specialty is not selected, add it
      updatedSpecialtiesName.push(value);
    }

    setSelectedSpecialties(updatedSpecialties);
    setSelectedSpecialtiesName(updatedSpecialtiesName);
  };

  return (
    <div className="dashboard">
      <div
        className={showLoaderAdmin ? "loader-overlay show" : "loader-overlay"}
      >
        <div className="loader23"></div>
      </div>
      <Helmet>
        <title>Farida User - Doctors</title>
        <meta
          name="description"
          content="AI and Global Reach: The Key to Scalable Solutions in Customer
          Experience"
        />
      </Helmet>
      <div className="dashboard_container">
        <div className="dashboard_wrapper">
          <SideBar />
          <div id="content">
            <CenterNavbar />

            <div className="Dashpages_contaioner">
              <div className="overview mt-3">
                <div className="overviewContaINER">
                  <div className="revenueCall">
                    <div className="row m-0">
                      <div className="col-md-8 p-0  "></div>
                      <div className="col-md-4">
                        <div className="AddAgentBtn w-100 m-0">
                          <button
                            className="w-100"
                            style={{ height: "45px" }}
                            onClick={() => handleShowModal1(0)}
                          >
                            Create Appointment
                          </button>
                        </div>
                        {/* <div className="searchClientContaienr">
                          <div
                            className="searchContaienr"
                            style={{ backgroundColor: "#8639CE" }}
                          >
                            <div className="text-center col-xl-10 m-auto">
                              <h5>Search Hospital </h5>
                              <div className="searchInputsConta">
                                <input
                                  placeholder="Hospital Name "
                                  type="text"
                                  value={customerName}
                                  onChange={(e) => {
                                    setMobileNo("");
                                    setCustomerName(e.target.value);
                                  }}
                                />
                                <p
                                  style={{
                                    fontWeight: "500",
                                    margin: "0.5rem 0",
                                  }}
                                >
                                  or
                                </p>
                                <input
                                  placeholder="Mobile No."
                                  type="text"
                                  value={mobileNo}
                                  onChange={(e) => {
                                    setCustomerName("");
                                    setMobileNo(e.target.value);
                                  }}
                                />
                              </div>
                              <div className="searchBtnADmin">
                                <button onClick={fetchCustomerData}>
                                  <img src={searchIcon} alt="Icon" />
                                  <p>Search </p>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </div>

                    <div className="amradataTable">
                      <div
                        className="amradataTable_container"
                        style={{ overflowX: "auto" }}
                      >
                        <h4>Appointment List</h4>
                        <table
                          id="myTable"
                          className="display"
                          style={{ textAlign: "center" }}
                        >
                          <thead>
                            <tr style={{ textAlign: "center" }}>
                              <th
                                style={{
                                  maxWidth: "20px",
                                }}
                              >
                                #
                              </th>
                              <th
                              >
                                App. No.
                              </th>
                              <th
                              >
                                App. Date & Time
                              </th>
                              <th
                              >
                                Patient Details
                              </th>
                              <th
                              >
                                Doctor Details
                              </th>
                              <th
                              >
                                Remark
                              </th>
                              <th
                                style={{
                                  maxWidth: "80px",
                                }}
                              >
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody className="horizontalScroll">
                            {Hostipallist.map((item, index) => (
                              <tr key={index}>
                                <td>
                                  <p style={{ color: "#000" }}>{index + 1}</p>
                                </td>
                                <td
                                >
                                  <p style={{ color: "#000" }}>
                                    {item.appointment_no}
                                  </p>
                                </td>
                                <td
                                >
                                  <p style={{ color: "#000" }}>
                                    {item.appointment_date} <br />{" "}
                                    {item.appointment_time}
                                  </p>
                                </td>
                                <td
                                >
                                  <p style={{ color: "#000" }}>
                                    <span style={{ whiteSpace: "nowrap" }}>
                                      Name:{" "}
                                      {!item.patient_name
                                        ? "NA"
                                        : item.patient_name}
                                    </span>
                                    <br />
                                    <span style={{ whiteSpace: "nowrap" }}>
                                      Mobile:{" "}
                                      {item.mobile_no === 0
                                        ? "NA"
                                        : item.mobile_no}
                                    </span>
                                    <br />
                                    <span style={{ whiteSpace: "nowrap" }}>
                                      Emirates ID:{" "}
                                      {item.emirates_id === 0
                                        ? "NA"
                                        : item.emirates_id}
                                    </span>
                                  </p>
                                </td>
                                <td
                                >
                                  <p style={{ color: "#000" }}>
                                    <span style={{ whiteSpace: "nowrap" }}>
                                      Name: {item.doctor_name}
                                    </span>
                                    <br />
                                    <span style={{ whiteSpace: "nowrap" }}>
                                      Department:{" "}
                                      {item?.department_name || "NA"}
                                    </span>
                                  </p>
                                </td>
                                <td
                                >
                                  <p style={{ color: "#000" }}>
                                    {item.remarks === ""
                                      ? "Up to date"
                                      : item.remarks}
                                  </p>
                                </td>
                                <td>
                                  <button
                                    className="editUsrContrlBtn"
                                    onClick={() => handleShowModal1(index + 1)}
                                  >
                                    Edit
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showModal1}
        onHide={handleCloseModal1}
        dialogClassName="custom-modal2 custom-modal3"
        centered
        size="xl"
      >
        <Modal.Header>
          <h5 className="mt-3">Create Appointment</h5>
        </Modal.Header>
        <Modal.Body>
          <div className="mngUserContainer px-xl-5">
            <form className="needs-validation" id="CarrerformData">
              <div className="row m-0">
                <div className="d-flex gap-5 mb-3">
                  <div>
                    <input
                      className="  trio_mandatory"
                      type="radio"
                      id="registeredUser"
                      name="caller_is_registered"
                      value="1"
                      checked={userType === "1"}
                      onChange={() => {
                        setPatient(null);
                        setMobileNo(null);
                        setPatientIllness("");
                        setControl(null);
                        setControlDoctor(null);
                        setSelectedDate(null);
                        handleUserTypeChange("1");
                      }}
                    />
                    &nbsp;
                    <label htmlFor="registeredUser">Registered Patient</label>
                  </div>
                  <div>
                    <input
                      className="  trio_mandatory"
                      name="caller_is_registered"
                      type="radio"
                      id="newUser"
                      value="0"
                      checked={userType === "0"}
                      onChange={() => {
                        setPatient(null);
                        setMobileNo(null);
                        setPatientIllness("");
                        setControl(null);
                        setControlDoctor(null);
                        setSelectedDate(null);
                        handleUserTypeChange("0");
                      }}
                    />
                    &nbsp;
                    <label htmlFor="newUser">New Patient</label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mngUserInput position-relative">
                    <label>Mobile Number</label>
                    <br />
                    <input
                      className="form-control  trio_mandatory trio_no"
                      name="mobile_no"
                      id="mobile_no"
                      maxLength={15}
                      onInput={(e) => {
                        setMobileNo(e.target.value);
                        handleNumbersChange(e);
                      }}
                      readonly={patient}
                      placeholder="Enter mobile number with country code"
                      defaultValue={
                        editoHostipallist
                          ? editoHostipallist?.mobile_no
                          : mobileNo || ""
                      }
                      type="text"
                      key={userType}
                    />
                    {userType === "1" && (
                      <img
                        style={{
                          position: "absolute",
                          bottom: "5px",
                          right: "5px",
                        }}
                        src={SearchIcon}
                        alt="Icon"
                        onClick={(e) => {
                          e.preventDefault();
                          findRegisteredPatientDetails();
                        }}
                      />
                    )}
                    <span className="condition_error"></span>
                  </div>
                </div>
                {(userType === "0" || patient?.user_name) && (
                  <div className="col-md-6">
                    <div className="mngUserInput">
                      <label>Patient Name</label>
                      <br />
                      <input
                        className="form-control  trio_mandatory"
                        name="patient_name"
                        id="patient_name"
                        maxLength={100}
                        onInput={handleAphabetsChange}
                        placeholder="Enter patient Name"
                        defaultValue={
                          editoHostipallist?.patient_name ||
                          patient?.user_name ||
                          ""
                        }
                        readonly={patient}
                        type="text"
                        key={userType}
                      />
                      <span className="condition_error"></span>
                    </div>
                  </div>
                )}
                {(userType === "0" || patient?.emirates_id) && (
                  <div className="col-md-6">
                    <div className="mngUserInput">
                      <label>Emirates ID (only for UAE residents)</label>
                      <br />
                      <input
                        className="form-control"
                        name="emirates_id"
                        id="emirates_id"
                        maxLength={15}
                        onInput={handleNumbersChange}
                        placeholder="Enter emirates ID"
                        readonly={patient}
                        defaultValue={
                          editoHostipallist?.emirates_id ||
                          patient?.emirates_id ||
                          ""
                        }
                        type="text"
                        key={userType}
                      />
                      <span className="condition_error"></span>
                    </div>
                  </div>
                )}
                <div className="col-md-12">
                  <div className="mngUserInput">
                    <label>Patient Illness Description</label>
                    <br />
                    <textarea
                      style={{
                        width: "100%",
                        border: "none",
                        backgroundColor: "#f2f0f0",
                        padding: "1rem",
                        borderRadius: "10px",
                      }}
                      id="patientIllness"
                      name="patientIllness"
                      value={patientIllness}
                      onChange={handleIllnessChange}
                      rows={4}
                      placeholder="Enter details about patient's illness..."
                    ></textarea>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mngUserInput">
                    <label>Choose Department</label>
                    <br />
                    <Dropdown
                      className="form-control d-flex"
                      style={{ border: "none", backgroundColor: "#f2f0f0" }}
                      name="department_id"
                      id="department_id"
                      value={control}
                      placeholder="Choose Department"
                      onChange={(e) => setDepartmentValue(e.value)}
                      options={controlOptions}
                      optionLabel="name"
                    />
                    <span className="condition_error"></span>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mngUserInput">
                    <label>Choose Doctor</label>
                    <br />
                    <Dropdown
                      className="form-control d-flex"
                      style={{ border: "none", backgroundColor: "#f2f0f0" }}
                      name="doctor_id"
                      id="doctor_id"
                      value={controlDoctor}
                      onChange={(e) => setControlDoctor(e.value)}
                      options={controlOptionsDoctor}
                      placeholder="Choose Doctor"
                      optionLabel="name"
                    />
                    <span className="condition_error"></span>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="mngUserInput">
                    <label>Appointment Date & Time</label>
                    <br />
                    <div>
                      <DatePicker
                        selected={selectedDate}
                        onChange={handleDateChange}
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={30}
                        minDate={new Date()} // Set minimum date to today
                        filterTime={isDateValid} // Filter out invalid times
                        dateFormat="MMMM d, yyyy h:mm aa"
                        placeholderText="Select Date & Time"
                      />
                    </div>
                    {/* <div>
                      <TimePicker
                        onChange={handleTimeChange}
                        value={selectedTime}
                        disableClock
                        format="HH:mm"
                      />
                    </div> */}
                    <span className="condition_error"></span>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="updateCnclebtnDiv">
            <button className="updateCnclebtnCan" onClick={handleCloseModal1}>
              Cancel
            </button>
            <button
              className="updateCnclebtnUp"
              onClick={() =>
                handleSaveChangesdynamic(
                  "CarrerformData",
                  CREATE_APPOINTMENT_FARIDA
                )
              }
            >
              {editoHostipallist?.name ? "Update" : "Create"}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      {/* second modal */}
      <Modal
        id="myModal"
        show={showModal}
        onHide={closeModal}
        centered
        backdrop="static"
      >
        <Modal.Body className="modal_body">
          <div className="success_img d-flex justify-content-center">
            <img src={Success} alt="icon" />
          </div>
          <p>Doctor has been updated successfully</p>
        </Modal.Body>
        <Modal.Footer>
          <Link
            to="/burjeel_doctors"
            onClick={() => window.location.reload(false)}
          >
            <div className="btn go_to_login">View</div>
          </Link>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default AmraAdminCustomers;
