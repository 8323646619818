import $ from "jquery";

// This function adds and removes the "slctdItem" class to/from list items
$(document).ready(function () {
  // Add a click event handler to the list items
  $(".chalenges_item").click(function () {
    // Remove the 'selected' class from all list items
    $(".chalenges_item").removeClass("slctdItem");

    // Add the 'selected' class to the clicked item
    $(this).addClass("slctdItem");
  });
});

// // This function adjusts the height of the ".vertical_gradient" div based on the
$(document).ready(function () {
  function adjustVerticalGradientHeight() {
    const leftHeight = $(".col-lg-6").height();
    const rightHeight = $(".col-lg-5").height();

    // Set the height of the vertical_gradient div to the maximum of left and right heights
    $(".vertical_gradient").height(Math.max(leftHeight, rightHeight));
  }

  // Call the function initially and whenever the window is resized
  adjustVerticalGradientHeight();
  $(window).resize(adjustVerticalGradientHeight);
});

$(document).ready(function () {
  // Add a click event handler to the list items
  $(".nafia_btn_div").click(function () {
    // Remove the 'selected' class from all list items
    $(".nafia_hide").toggleClass("nafia_show");
  });
});

export function scrollToTestimonials() {
  const testimonialsSection = document.getElementById("testimonials");

  if (testimonialsSection) {
    testimonialsSection.scrollIntoView({ behavior: "smooth" });
  }
}
