import React, { useEffect, useRef, useState } from "react";
import downloadingUpdates from "../../assets/images/Downloading Updates.png";
import DownloadingUp from "../../assets/images/Downloading Updates.png";
import Calender from "../../assets/images/calendar12.svg";
import $ from "jquery";
import moment from "moment";
import "moment/locale/en-au";
import "daterangepicker";
import VRline from "../../assets/images/vrLine.svg";
import { Modal, Button } from "react-bootstrap";
import { URL_FOR_LINK_ARMA_CONVR_UNIQUE } from "../../KeyConnection/KeysService.js";

function DataModal({ modalData }) {
  const inputRef = useRef(null);
  const [filterdata, setFilterData] = useState([]);
  const handleDateRangeChange = (start, end, label) => {
    // Callback function for handling date range changes
    let startDate = start.format("DD/MM/YYYY");
    let endDate = end.format("DD/MM/YYYY");
    if (modalData && modalData.call_record.length > 0) {
      data_filter(startDate, endDate);
    }
  };

  const data_filter = (startDate, endDate) => {
    console.log(modalData.call_record);
    if (startDate === null) {
      setFilterData(modalData.call_record);
    } else {
      const startDateddd = moment(startDate, "DD/MM/YYYY");
      const endDateddd = moment(endDate, "DD/MM/YYYY");
      setFilterData(
        modalData.call_record.filter((item) => {
          const callDateddd = moment(item.call_date, "DD/MM/YYYY");
          const isBetween = callDateddd.isBetween(
            startDateddd,
            endDateddd,
            null,
            "[]"
          );
          console.log("Is Between:", isBetween);
          return true;
        })
      );
    }
  };

  useEffect(() => {
    // Call the function to fetch data when the component mounts

    const datepicker = $(inputRef.current);
    datepicker.daterangepicker(
      {
        timePicker: true,
        startDate: moment().subtract(7, "days").startOf("day"),
        endDate: moment().startOf("hour").add(32, "hour"),
        locale: {
          format: "DD/MM/YYYY",
        },
      },
      handleDateRangeChange
    );

    // Call handleDateRangeChange on component
    const startDate = datepicker.data("daterangepicker").startDate;
    const endDate = datepicker.data("daterangepicker").endDate;

    handleDateRangeChange(startDate, endDate);
  }, [modalData]);

  const [showModal, setShowModal] = useState(false);
  const [callRecords, setCallRecords] = useState([
    {
      user: "Hello bot",
      bot: "Hello user",
    },
  ]);

  const getCallRecords = async (chat_id) => {
    try {
      // Fetch data from the API endpoint
      const url = URL_FOR_LINK_ARMA_CONVR_UNIQUE + chat_id;
      console.log(url);
      const response = await fetch(url);
      const data = await response.json();
      const chat = data.data;

      const conversation = chat.map((message, idx) => {
        console.log(message);
        return {
          user: message.content_source,
          bot: message.response_source,
        };
      });
      console.log(conversation);
      setCallRecords(conversation);
      setShowModal(true); // Open the modal when the function is called
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false); // Close the modal
  };

  return (
    <div>
      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content" style={{ backgroundColor: "#EBEBEB" }}>
            <div className="modal-header modalHeadPopUp">
              <h4 className="modal-title popHead" id="staticBackdropLabel">
                Call Details
              </h4>
              {/* <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button> */}
              <div className="date_filter_div hidden">
                <div className="date_filter">
                  <div className="calender_icon">
                    <label style={{ cursor: "pointer" }} htmlFor="datepicker">
                      <img src={Calender} alt="Image" />
                    </label>
                  </div>
                  <input
                    id="datepicker"
                    className="form-control form-control-solid"
                    placeholder="Pick date range"
                    ref={inputRef}
                  />
                </div>
              </div>
            </div>
            {modalData != null && (
              <div className="custMob">
                <div className="customerid">
                  <div className="custumerId">
                    <p>Customer Id: {modalData.customer_id}</p>
                  </div>
                  <div className="VLine">
                    <img src={VRline} alt="icon" />
                  </div>
                  <div className="mobNum">
                    <p>Name: {modalData.name}</p>
                  </div>
                  <div className="VLine">
                    <img src={VRline} alt="icon" />
                  </div>
                  <div className="mobNum">
                    <p>Mobile No: {modalData.mobile}</p>
                  </div>
                </div>
              </div>
            )}

            <div className="modal-body">
              <div className="AmraConversations_data">
                <div className="searchdataTable">
                  <div className="searchdataTable_container">
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">
                            <div className="colFirst">
                              <p>S.No.</p>
                            </div>
                          </th>
                          <th scope="col">
                            <div>
                              <p>Call Date</p>
                            </div>
                          </th>
                          <th scope="col">
                            <div>
                              <p>Call Time</p>
                            </div>
                          </th>
                          <th scope="col">
                            <div>
                              <p>Bucket</p>
                            </div>
                          </th>
                          <th scope="col">
                            <div>
                              <p>Details</p>
                            </div>
                          </th>
                          <th scope="col">
                            <div>
                              <p>Recording</p>
                            </div>
                          </th>
                          <th scope="col">
                            <div>
                              <p>CRM Notes</p>
                            </div>
                          </th>
                          <th scope="col">
                            <div className="colLast">
                              <p>Case Assigned</p>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {filterdata.length > 0 &&
                          filterdata.map((item, index) => (
                            <tr key={index}>
                              <td scope="row">
                                <div className="colFirst">

                                  <p>{item.serial_no} {filterdata.length}</p>
                                </div>
                              </td>
                              <td>
                                <div>
                                  <p>{item.call_date}</p>
                                </div>
                              </td>
                              <td>
                                <div>
                                  <p>{item.call_time}</p>
                                </div>
                              </td>
                              <td>
                                <div>
                                  <p>{item.bucket}</p>
                                </div>
                              </td>
                              <td>
                                <div>
                                  <p>{item.details}</p>
                                </div>
                              </td>
                              <td>
                                <div>
                                  <img
                                    style={{ cursor: "pointer" }}
                                    src={DownloadingUp}
                                    alt="icon"
                                    onClick={() => getCallRecords(item.chat_id)}
                                  />
                                </div>
                              </td>
                              <td>
                                <div>
                                  <p>{item.notes}</p>
                                </div>
                              </td>
                              <td>
                                <div className="colLast">
                                  <p>{item.case}</p>
                                </div>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showModal} onHide={handleCloseModal}>
        {/* Modal content */}
        <Modal.Header closeButton>
          <Modal.Title>Call Records</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {callRecords.map((record, index) => (
            <div key={index}>
              <p
                style={{
                  textAlign: "flex-end",
                  margin: "7px",
                  float: "left",
                }}
                className="leftTextbg"
              >
                {record.user}
              </p>
              <br></br>
              <p
                style={{
                  textAlign: "flex-endstart",
                  margin: "7px",
                  float: "right",
                }}
                className="rightTextbg"
              >
                {record.bot}
              </p>
            </div>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default DataModal;
