import React, { useState } from "react";
import {
  show_popup_thanks,
  check_vaild_save,
} from "../CommonJquery/CommonJquery";
import ModalImg from "../assets/images/modalImg.gif";
import {
  URL_FOR_NEWS_SAVE,
  server_post_data,
} from "../KeyConnection/KeysService"; // Adjust the path as neededome() {;

function Newsletter() {
  const [userEmail, setuserEmail] = useState("");
  const [showLoaderWebsite, setshowLoaderWebsite] = useState(false);

  const handleSaveUserData = async (form_data, url_for_save) => {
    let vaild_data = check_vaild_save(form_data);

    if (vaild_data) {
      setshowLoaderWebsite(true);
      const body_data = JSON.stringify({
        user_email: userEmail,
      });
      await server_post_data(url_for_save, body_data)
        .then((response) => response.json())
        .then((data) => {
          setshowLoaderWebsite(false);

          if (data.status !== "error") {
            setuserEmail("");
            show_popup_thanks("click_button");
          }
        })
        .catch((error) => {
          console.log("asdsa");
          setshowLoaderWebsite(false);
        });
    }
  };

  return (
    <div>
      <div className={showLoaderWebsite ? "loading_website" : ""}></div>
      <form id="newsletterFormData">
        <div className="inputBtnDiv col-md-9 ">
          <input
            placeholder="Email Address"
            className="emailInpt trio_email trio_mendate"
            type="email"
            id="email"
            name="email"
            value={userEmail}
            onChange={(e) => {
              setuserEmail(e.target.value);
            }}
            onInput={(e) => {
              if (!/^[a-zA-Z0-9@.]*$/.test(e.target.value)) {
                e.target.value = ""; // Clear the input if it contains any other character
              }
            }} // value={email} // onChange={handleEmailChange}
          />
          <button
            className="Subscribebtn"
            type="button"
            onClick={() =>
              handleSaveUserData("newsletterFormData", URL_FOR_NEWS_SAVE)
            }
          >
            <span>Subscribe</span>
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-arrow-right"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                />
              </svg>
            </span>
          </button>

          <button
            hidden
            id="click_button"
            type="button"
            data-toggle="modal"
            data-target="#exampleModalCenter2"
          ></button>
        </div>
      </form>
      <div
        className="modal fade"
        id="exampleModalCenter2"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close closeModay"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="text-center mb-2">
                <img src={ModalImg} alt="icon" />
              </div>
              <p style={{fontSize:"15px"}}>
                You've successfully joined our newsletter community! Prepare to
                receive cutting-edge insights and tech-savvy inspiration. We're
                your go-to source for staying informed and empowered in this
                digital age.
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary closeFooterModal"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Newsletter;
