import React, { useState } from "react";
import "../../Dasboard/Css/Dashboard.css";
import "../../Dasboard/Css/Bucket30days.css";
import "../../Dasboard/Css/SettingPage.css";
import "../../Dasboard/Css/UserManagement.css";
import NafiaSideBar from "./NafiaSideBar.js";
import Logout from "../../assets/images/giflogout.gif";
import CircleCheck from "../../assets/images/circle_check.svg";
import NotificationsImg from "../../assets/images/notification.svg";
import SideBarControl from "../../assets/images/rightLeftSIdebar.png";
import UserImgPrf from "../../assets/images/userimg45.svg";
import adm_img from "../../assets/images/adm_img.png";
import "react-circular-progressbar/dist/styles.css";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import addImg from "../../assets/images/addImg.png";
import userBtn from "../../assets/images/usermngmt.svg";
import userBtnwhite from "../../assets/images/usermngmntblue.svg";
import invBtn from "../../assets/images/invoicee.svg";
import subsButton from "../../assets/images/subscriptions.svg";
import RightaRROW from "../../assets/images/rightmorethan.svg";
import RightaRROWWhite from "../../assets/images/rightmorethanblue.svg";
import { Link, useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Technotask from "../../assets/images/TechnotaskLogo12.png";
import NafiaUserCenterNav from "./NafiaUserCenterNav.js";
import { Helmet } from "react-helmet";

function DebtorProfile60() {
  const [overviewShow, setOverviewShow] = useState(true); // Default to 'true'
  const [debtorProfile, setdebtorProfile] = useState(false);
  const location = useLocation();

  const handleSuccess = () => {
    toast.success("Operation successful!", {
      position: "top-right",
      autoClose: 3000, // Duration in milliseconds
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      className: "custom-toast-success",
    });
  };

  const handleError = () => {
    toast.error("An error occurred!", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      className: "custom-toast-error",
    });
  };
  return (
    <div className="dashboard">
      <Helmet>
        <title>Nafia User Dashboard</title>
        <meta
          name="description"
          content="AI and Global Reach: The Key to Scalable Solutions in Customer
          Experience"
        />
      </Helmet>
      <div className="dashboard_container">
        <div className="dashboard_wrapper">
          <NafiaSideBar
            overviewShow={overviewShow}
            setOverviewShow={setOverviewShow}
            debtorProfile={debtorProfile}
            setdebtorProfile={setdebtorProfile}
          />
          <div id="content">
            <NafiaUserCenterNav />

            <div className="AmraConversations">
              <div className="AmraConversations_container">
                <div className="AmraConversations_container_wrapper">
                  <div className="overviewContaINER">
                    <div className="col-md-8 overviewHead overviewHeaddp mb-1">
                      <h4>Settings</h4>
                    </div>
                  </div>
                  <div className="subscript">
                    <div className="row m-0 justify-content-center">
                      <div className="col-lg-4 col-md-5 column1 pt-0">
                        <div className="circleProgBar m-auto">
                          <div className="collecBar">
                            <div style={{ width: "60px" }}>
                              <CircularProgressbar
                                value={75}
                                text={`${75}%`}
                                styles={buildStyles({
                                  textColor: "#fff", // Color of the text
                                  trailColor: `blank`, // Color of the progress circle
                                  pathColor: "rgba(255, 255, 255, 1)", // Color of the background trail
                                })}
                              />
                            </div>
                            <div className="profileTxt">
                              <p>Profile Information </p>
                              <button className="completeProfileBtn">
                                Complete your profile
                              </button>
                            </div>
                          </div>
                          <div className="button-container23">
                            <Link to="/NafiaSettingPage01">
                              <button className="custom-button23">
                                <div className="insdbtndiv">
                                  <img src={subsButton} alt="ICON" />
                                  <p>Subscriptions</p>
                                </div>
                                <div>
                                  <img src={RightaRROW} alt="ICON" />
                                </div>
                              </button>
                            </Link>
                            <Link to="/NafiaSettingPage01/NafiaInvoice">
                              <button className="custom-button23">
                                <div className="insdbtndiv">
                                  <img src={invBtn} alt="ICON" />
                                  <p>Invoice</p>
                                </div>
                                <div>
                                  <img src={RightaRROW} alt="ICON" />
                                </div>
                              </button>
                            </Link>
                            <button className="custom-button23">
                              <div
                                className={`insdbtndiv ${
                                  location.pathname ===
                                  "/NafiaSettingPage01/NafiaUserManagement"
                                    ? "activemainSettingItem"
                                    : ""
                                }`}
                              >
                                <img
                                  src={
                                    location.pathname ===
                                    "/NafiaSettingPage01/NafiaUserManagement"
                                      ? userBtnwhite
                                      : userBtn
                                  }
                                  alt="ICON"
                                />
                                <p>User Management</p>
                              </div>
                              <div>
                                <img
                                  src={
                                    location.pathname ===
                                    "/NafiaSettingPage01/NafiaUserManagement"
                                      ? RightaRROWWhite
                                      : RightaRROW
                                  }
                                  alt="ICON"
                                />
                              </div>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-5 col-md-5 column2">
                        <h5 className="mb-3">
                          <b>User Management</b>
                        </h5>
                        <div
                          className="accordion accordion-flush"
                          id="accordionFlushExample"
                        >
                          <div className="accordion-item">
                            <h2
                              className="accordion-header profiledrop"
                              id="flush-headingOne"
                            >
                              <button
                                className="accordion-button collapsed pb-2"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseOne"
                                aria-expanded="false"
                                aria-controls="flush-collapseOne"
                              >
                                <b>Profile</b>
                              </button>
                            </h2>
                            <p className="subText">
                              First Name, Last Name, Email address
                            </p>
                            <div
                              id="flush-collapseOne"
                              className="accordion-collapse collapse"
                              aria-labelledby="flush-headingOne"
                              data-bs-parent="#accordionFlushExample"
                            >
                              <div className="accordion-body profileInput">
                                <input
                                  type="text"
                                  value="Dilara"
                                  onInput={(e) => {
                                    e.target.value = e.target.value.replace(
                                      /[^A-Za-z\s]/g,
                                      ""
                                    );
                                  }}
                                />
                                <input
                                  type="text"
                                  value="Mataraci"
                                  onInput={(e) => {
                                    e.target.value = e.target.value.replace(
                                      /[^A-Za-z\s]/g,
                                      ""
                                    );
                                  }}
                                />
                                <input
                                  type="text"
                                  value="dmataraci@gmail.com"
                                  onInput={(e) => {
                                    if (
                                      !/^[a-zA-Z0-9@.]*$/.test(e.target.value)
                                    ) {
                                      e.target.value = ""; // Clear the input if it contains any other character
                                    }
                                  }}
                                />
                                <div className="connCalen">
                                  <button
                                    className="btn btn-primary"
                                    onClick={handleSuccess}
                                  >
                                    Connect Calender
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-headingTwo">
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseTwo"
                                aria-expanded="false"
                                aria-controls="flush-collapseTwo"
                              >
                                <b>Password</b>
                              </button>
                            </h2>
                            <p className="subText">
                              Your email address is xyz@gmail.com
                            </p>
                            <div
                              id="flush-collapseTwo"
                              className="accordion-collapse collapse"
                              aria-labelledby="flush-headingTwo"
                              data-bs-parent="#accordionFlushExample"
                            >
                              <div className="accordion-body profileInput">
                                <form>
                                  <input
                                    type="text"
                                    placeholder="Current Password"
                                  />
                                  <input
                                    type="text"
                                    placeholder="New Password"
                                  />
                                </form>
                                <div className="connCalen">
                                  <button
                                    className="btn btn-primary"
                                    onClick={handleSuccess}
                                  >
                                    Update password
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="accordion-item">
                            <h2
                              className="accordion-header"
                              id="flush-headingThree"
                            >
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseThree"
                                aria-expanded="false"
                                aria-controls="flush-collapseThree"
                              >
                                <b>Notifications</b>
                              </button>
                            </h2>
                            <p className="subText">
                              We will send you notifications
                            </p>
                            <div
                              id="flush-collapseThree"
                              className="accordion-collapse collapse"
                              aria-labelledby="flush-headingThree"
                              data-bs-parent="#accordionFlushExample"
                            >
                              <div className="accordion-body">
                                <form>
                                  <div className="custom-checkbox">
                                    <input
                                      type="checkbox"
                                      id="checkbox1"
                                      name="checkbox1"
                                    />
                                    <label
                                      style={{ fontWeight: "500" }}
                                      className="chkbox"
                                      htmlFor="checkbox1"
                                    >
                                      Receive weekly e-mails
                                    </label>
                                  </div>

                                  <div className="custom-checkbox">
                                    <input
                                      type="checkbox"
                                      id="checkbox2"
                                      name="checkbox2"
                                    />
                                    <label
                                      style={{ fontWeight: "500" }}
                                      className="chkbox"
                                      htmlFor="checkbox2"
                                    >
                                      Receive new rating announcements
                                    </label>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-headingFour">
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseFour"
                                aria-expanded="false"
                                aria-controls="flush-collapseFour"
                              >
                                <b>Time zone</b>
                              </button>
                            </h2>
                            <p className="subText">
                              Your timezone is currently set to: Pacific Time
                              (US)
                            </p>
                            <div
                              id="flush-collapseFour"
                              className="accordion-collapse collapse"
                              aria-labelledby="flush-headingFour"
                              data-bs-parent="#accordionFlushExample"
                            >
                              <div className="accordion-body profileInput">
                                <form>
                                  <select id="utcSelector" name="utcSelector">
                                    <option value="-12">
                                      (UTC-12:00) International Date Line West
                                    </option>
                                    <option value="-11">
                                      (UTC-11:00) Midway Island, Samoa
                                    </option>
                                    <option value="-10">
                                      (UTC-10:00) Hawaii
                                    </option>
                                    <option value="-9">
                                      (UTC-09:00) Alaska
                                    </option>
                                    <option value="-8">
                                      (UTC-08:00) Pacific Time (US & Canada)
                                    </option>
                                    <option value="-7">
                                      (UTC-07:00) Mountain Time (US & Canada)
                                    </option>
                                    <option value="-6">
                                      (UTC-06:00) Central Time (US & Canada)
                                    </option>
                                    <option value="-5">
                                      (UTC-05:00) Eastern Time (US & Canada)
                                    </option>
                                    <option value="-4">
                                      (UTC-04:00) Atlantic Time (Canada)
                                    </option>
                                    <option value="-3">
                                      (UTC-03:00) Buenos Aires, Georgetown
                                    </option>
                                    <option value="-2">
                                      (UTC-02:00) Mid-Atlantic
                                    </option>
                                    <option value="-1">
                                      (UTC-01:00) Azores, Cape Verde Islands
                                    </option>
                                    <option value="0">
                                      (UTC) Greenwich Mean Time
                                    </option>
                                    <option value="1">
                                      (UTC+01:00) Amsterdam, Berlin, Paris, Rome
                                    </option>
                                    <option value="2">
                                      (UTC+02:00) Athens, Istanbul, Jerusalem
                                    </option>
                                    <option value="3">
                                      (UTC+03:00) Moscow, St. Petersburg,
                                      Volgograd
                                    </option>
                                  </select>
                                  <button className="updButton btn btn-primary">
                                    Update
                                  </button>
                                </form>
                              </div>
                            </div>
                          </div>

                          <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-headingFive">
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseFive"
                                aria-expanded="false"
                                aria-controls="flush-collapseFive"
                              >
                                <b>Deactivate account</b>
                              </button>
                            </h2>
                            <p className="subText">
                              If you no longer want to receive emails
                            </p>
                            <div
                              id="flush-collapseFive"
                              className="accordion-collapse collapse"
                              aria-labelledby="flush-headingFive"
                              data-bs-parent="#accordionFlushExample"
                            >
                              <div className="accordion-body">
                                <button className="btn btn-primary">
                                  Deactivate
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="imgAdd d-flex">
                            <button className="addImg">
                              <img src={addImg} alt="icon" />
                            </button>
                            <p>Add account</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="brandingContainer">
              <div>
                <h5>Powered by</h5>
                <img src={Technotask} alt="Logo" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default DebtorProfile60;
