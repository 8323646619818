import React, { useState, useEffect } from "react";
import "../../Dasboard/Css/BillingForm.css";
import { countries } from "countries-list";
import axios from "axios";
import { Helmet } from "react-helmet";

const ShippingBillingForm = () => {
  const [userName, setuserName] = useState("");
  const [userEmail, setuserEmail] = useState("");

  const [shippingName, setShippingName] = useState("");
  const [shippingAddress, setShippingAddress] = useState("");
  const [billingName, setBillingName] = useState("");
  const [billingAddress, setBillingAddress] = useState("");
  const [creditCard, setCreditCard] = useState("");
  const [expirationDate, setExpirationDate] = useState("");
  const [cvv, setCvv] = useState("");

  const [selectedState, setSelectedState] = useState("");

  const statesList = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
  ];

  const [userCountry, setuserCountry] = useState("");
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    // Fetch the list of all countries from the REST Countries API
    axios
      .get("https://restcountries.com/v3.1/all")
      .then((response) => {
        // Extract the country names from the response data
        const countryNames = response.data.map(
          (country) => country.name.common
        );
        setCountries(countryNames);
      })
      .catch((error) => {
        console.error("Error fetching countries:", error);
      });
  }, []);

  // const handleCountryChange = (e) => {
  //   setSelectedCountry(e.target.value);
  // };

  const handleChange = (event) => {
    setSelectedState(event.target.value);
  };

  const [cardInfo, setCardInfo] = useState("");

  const handleCardInfoChange = (e) => {
    let value = e.target.value;

    // Remove non-digit characters
    value = value.replace(/\D/g, "");

    // Add a space after every 4 digits
    if (value.length > 4) {
      value = `${value.slice(0, 4)} / ${value.slice(4, 12)}`;
    }

    setCardInfo(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Extract the card number and expiry date from the combined input
    const [cardNumber, expiryDate] = cardInfo.split(" / ");
    // Implement your logic for handling the form submission
    console.log("Form submitted:", { cardNumber, expiryDate });
  };

  // const maxCharacters = 50;
  //   if (inputValue.length > maxCharacters) {
  //     inputValue = inputValue.slice(0, maxCharacters);
  //   }

  return (
    
    <div className="container w-50 p-3 col-md">
      <Helmet>
        <title>Nafia User Dashboard</title>
        <meta
          name="description"
          content="AI and Global Reach: The Key to Scalable Solutions in Customer
          Experience"
        />
      </Helmet>
      <div className="heaDDiv">
        <h5 className=" ">SHIPPING & BILLLING INFORMATION</h5>
        <div className="billInput">
          <form className="form-inline">
            <div className="form-group row">
              <label htmlFor="name" className=" almrg col-md-2 col-form-label">
                Name
              </label>
              <div className="col-md-10">
                <input
                  type="name"
                  className="form-control mRGin"
                  id="name"
                  placeholder="Name"
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/[^A-Za-z\s]/g, "");
                  }}
                  onChange={(e) => {
                    setuserName(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="email" className=" almrg col-md-2 col-form-label">
                Email
              </label>
              <div className="col-md-10">
                <input
                  type="email"
                  className="form-control mRGin"
                  id="email"
                  placeholder="Email"
                  onChange={(e) => {
                    setuserEmail(e.target.value);
                  }}
                  onInput={(e) => {
                    if (!/^[a-zA-Z0-9@.]*$/.test(e.target.value)) {
                      e.target.value = ""; // Clear the input if it contains any other character
                    }
                  }}
                />
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="Address" className="almrg col-md-2 col-form-label">
                Address
              </label>
              <div className="col-md-10  ">
                <input
                  type="address"
                  className="form-control"
                  id="inputPassword"
                  placeholder="Address"
                />
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="city" className=" almrg col-md-2 col-form-label">
                City
              </label>
              <div className="col-md-10">
                <input
                  type="city"
                  className="form-control"
                  id="inputPassword"
                  placeholder="City"
                />
              </div>
            </div>
            <div className="form-group row">
              <div className=" almrg col-md-2 col-form-label">
                <label>State </label>
              </div>
              <div className="col-md-3">
                <input
                  type="state"
                  className="form-control"
                  id="inputPassword"
                  placeholder="State"
                />
              </div>

              <div className=" almrg txntr col-md-3 col-form-label">
                <label htmlFor="zip">Zip Code</label>
              </div>
              <div className="col-md-4">
                <input
                  type="text"
                  className="form-control"
                  id="zip"
                  placeholder="Zip code"
                  required
                />
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="country" className=" almrg col-md-2 col-form-label">
                Country
              </label>
              <div className="sltcnty col-md-10 col-form-label">
                <select
                  value={userCountry}
                  className="form-select trio_mendate"
                  onChange={(e) => {
                    setuserCountry(e.target.value);
                  }}
                  id="country"
                  name="country"
                >
                  <option value="">Select Your Country</option>
                  {countries.map((country, index) => (
                    <option key={index} value={country}>
                      {country}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </form>
        </div>

        <div className="payInf">
          <h5>PAYMENT INFORMATION</h5>
        </div>

        <form className="payInfo form-inline">
          <div className="form-group row">
            <label htmlFor="Cadrrd" className="cArD col-md-2 ">
              Card
            </label>
            <div className="col-md-6">
              <div className="PIcrd form-group">
                <input
                  type="text"
                  name="number"
                  className="   form-control"
                  placeholder="Card"
                />
              </div>
            </div>
            <div className="col-md-2">
              <div className=" form-group">
                <input
                  type="text"
                  placeholder="MM/YY"
                  name="expiry"
                  className="form-control"
                />
              </div>
            </div>
            <div className="PI col-md-2">
              <div className=" form-group">
                <input
                  type="text"
                  placeholder="CVV"
                  name="expiry"
                  className="form-control"
                />
              </div>
            </div>
          </div>
        </form>
        <div className="paybtn">
          <button className="paybtn1 btn btn-primary col-md-12 ">
            Pay $0000
          </button>
        </div>
      </div>
    </div>
  );
};

export default ShippingBillingForm;
