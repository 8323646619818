import React, { useState } from "react";
import "../../Dasboard/Css/Dashboard.css";
import "../../Dasboard/Css/Bucket30days.css";
import "../../Dasboard/Css/SettingPage.css";
import NafiaSideBar from "./NafiaSideBar.js";
import CircleCheck from "../../assets/images/circle_check.svg";
import NotificationsImg from "../../assets/images/notification.svg";
import SideBarControl from "../../assets/images/rightLeftSIdebar.png";
import UserImgPrf from "../../assets/images/userimg45.svg";
import adm_img from "../../assets/images/adm_img.png";
import "react-circular-progressbar/dist/styles.css";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import userBtn from "../../assets/images/usermngmt.svg";
import invBtn from "../../assets/images/invoicee.svg";
import invBtnWhite from "../../assets/images/invoiceeBlue.svg";
import subsButton from "../../assets/images/subscriptions.svg";
import RightaRROW from "../../assets/images/rightmorethan.svg";
import RightaRROWWhite from "../../assets/images/rightmorethanblue.svg";
import { Link, useLocation } from "react-router-dom";
import subsButtonWhite from "../../assets/images/subscriptionsblue.svg";
import Logout from "../../assets/images/giflogout.gif";
import Technotask from "../../assets/images/TechnotaskLogo12.png";
import NafiaUserCenterNav from "./NafiaUserCenterNav.js";
import { Helmet } from "react-helmet";

function DebtorProfile60() {
  const [overviewShow, setOverviewShow] = useState(true); // Default to 'true'
  const [debtorProfile, setdebtorProfile] = useState(false);
  const location = useLocation();
  return (
    <div className="dashboard">
      <Helmet>
        <title>Nafia User Dashboard</title>
        <meta
          name="description"
          content="AI and Global Reach: The Key to Scalable Solutions in Customer
          Experience"
        />
      </Helmet>
      <div className="dashboard_container">
        <div className="dashboard_wrapper">
          <NafiaSideBar
            overviewShow={overviewShow}
            setOverviewShow={setOverviewShow}
            debtorProfile={debtorProfile}
            setdebtorProfile={setdebtorProfile}
          />
          <div id="content">
            <NafiaUserCenterNav />

            <div className="AmraConversations">
              <div className="AmraConversations_container">
                <div className="AmraConversations_container_wrapper">
                  <div className="overviewContaINER pb-0">
                    <div className="col-md-8 overviewHead overviewHeaddp mb-1">
                      <h4>Settings</h4>
                    </div>
                  </div>
                  <div className="subscript">
                    <div className="row m-0 justify-content-center">
                      <div className="col-lg-4 col-md-5 column1 pt-0">
                        <div className="circleProgBar m-auto">
                          <div className="collecBar">
                            <div style={{ width: "60px" }}>
                              <CircularProgressbar
                                value={75}
                                text={`${75}%`}
                                styles={buildStyles({
                                  textColor: "#fff", // Color of the text
                                  trailColor: `blank`, // Color of the progress circle
                                  pathColor: "rgba(255, 255, 255, 1)", // Color of the background trail
                                })}
                              />
                            </div>
                            <div className="profileTxt">
                              <p>Profile Information </p>
                              <button className="completeProfileBtn">
                                Complete your profile
                              </button>
                            </div>
                          </div>
                          <div className="button-container23">
                            <Link to="/NafiaSettingPage01">
                              <button className="custom-button23">
                                <div className="insdbtndiv">
                                  <img src={subsButton} alt="ICON" />
                                  <p>Subscriptions</p>
                                </div>
                                <div>
                                  <img src={RightaRROW} alt="ICON" />
                                </div>
                              </button>
                            </Link>
                            <button className="custom-button23">
                              <div
                                className={`insdbtndiv ${
                                  location.pathname ===
                                  "/NafiaSettingPage01/NafiaInvoice"
                                    ? "activemainSettingItem"
                                    : ""
                                }`}
                              >
                                <img
                                  src={
                                    location.pathname ===
                                    "/NafiaSettingPage01/NafiaInvoice"
                                      ? invBtnWhite
                                      : userBtn
                                  }
                                  alt="ICON"
                                />
                                <p>Invoice</p>
                              </div>
                              <div>
                                <img
                                  src={
                                    location.pathname ===
                                    "/NafiaSettingPage01/NafiaInvoice"
                                      ? RightaRROWWhite
                                      : RightaRROW
                                  }
                                  alt="ICON"
                                />
                              </div>
                            </button>
                            <Link to="/NafiaSettingPage01/NafiaUserManagement">
                              <button className="custom-button23">
                                <div className="insdbtndiv">
                                  <img src={userBtn} alt="ICON" />
                                  <p>User Management</p>
                                </div>
                                <div>
                                  <img src={RightaRROW} alt="ICON" />
                                </div>
                              </button>
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-5 col-md-5 column2">
                        <h5 className="mb-3">
                          <b>Invoice</b>
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="brandingContainer">
              <div>
                <h5>Powered by</h5>
                <img src={Technotask} alt="Logo" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DebtorProfile60;
