import React, { useState } from "react";
import "../Css/Dashboard.css";
import "../Css/Bucket30days.css";
import "../Css/AddDebtorForm.css";
import SideBar from "./SideBar";
import CircleCheck from "../../assets/images/circle_check.svg";
import NotificationsImg from "../../assets/images/notification.svg";
import SideBarControl from "../../assets/images/rightLeftSIdebar.png";
import UserImgPrf from "../../assets/images/userimg45.svg";
import adm_img from "../../assets/images/adm_img.png";
import Expand1 from "../../assets/images/expand 1.svg";
import MoreIcon from "../../assets/images/more_vertical.svg";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, registerables } from "chart.js";
import "react-circular-progressbar/dist/styles.css";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import TickImg from "../../assets/images/check.svg";
import Technotask from "../../assets/images/TechnotaskLogo12.png";
import Logout from "../../assets/images/giflogout.gif";
import Success from "../../assets/images/success_gif.gif";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import CenterNavbar from "./CenterNavbar";
import { Helmet } from "react-helmet";

function DebtorProfile60() {
  ChartJS.register(...registerables);

  const [overviewShow, setOverviewShow] = useState(true); // Default to 'true'
  const [debtorProfile, setdebtorProfile] = useState(false);

  const [showListView, setShowListView] = useState(false);

  const ListView = ({ items }) => {
    return (
      <ul>
        {items.map((item, index) => (
          <ul key={index}>
            <a href={`${item.links}`}>{item.pages}</a>
          </ul>
        ))}
      </ul>
    );
  };

  const [showModal, setShowModal] = useState(false);
  const closeModal = () => {
    setShowModal(false);
  };

  const openModal = () => {
    setShowModal(true);
  };

  return (
    <div className="dashboard">
      <Helmet>
        <title>Amra User Dashboard</title>
        <meta
          name="description"
          content="AI and Global Reach: The Key to Scalable Solutions in Customer
          Experience"
        />
      </Helmet>
      <div className="dashboard_container">
        <div className="dashboard_wrapper">
          <SideBar
            overviewShow={overviewShow}
            setOverviewShow={setOverviewShow}
            debtorProfile={debtorProfile}
            setdebtorProfile={setdebtorProfile}
          />
          <div id="content">
            <CenterNavbar />

            <div className="AmraConversations">
              <div className="AmraConversations_container">
                <div className="AmraConversations_container_wrapper">
                  <div className="overviewContaINER">
                    <div className="overviewHead overviewHeaddp ">
                      <h4>Debtor Profile / Add Debtor</h4>
                      <div className="overviewHeadLeft">
                        <div className="overviewHeadCntnt">
                          <p>Upload Debtor Data sss</p>
                          <label htmlFor="uploadCSV" className="uploadCSVBtn">
                            Upload CSV
                          </label>
                          <input id="uploadCSV" type="file" hidden />
                          <button className="addDebtorBtn" hidden>
                            Add Debtor
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="valueCardpad valueCardpad1" hidden>
                      <div className="valueCard bg-white d-flex align-items-center justify-content-center">
                        <div className="round12 round12cmpltd">
                          <p>01</p>
                        </div>
                        <div className="horizonline"></div>
                        <div className="round12">
                          <p>02</p>
                        </div>
                        <div className="horizonline"></div>
                        <div className="round12">
                          <p>03</p>
                        </div>
                        <div className="horizonline"></div>
                        <div className="round12">
                          <p>04</p>
                        </div>
                      </div>
                    </div>
                    <div className="userBasicInfo col-lg-12">
                      <div className="text-md-left">
                        <h4>User Basic Info</h4>
                      </div>

                      <form>
                        <div
                          className="row mx-0"
                          style={{
                            borderBottom: "2px solid #A253D8",
                            paddingBottom: "1rem",
                            marginBottom: "1rem",
                          }}
                        >
                          <div className="col-lg-10 col-md-9">
                            <div className="row m-0">
                              <div className="col-xl-5 col-lg-6">
                                <div className="formInput">
                                  <div className="col-xl-4 col-md-5">
                                    <label>Reg. Mobile No.</label>
                                  </div>
                                  <div className="col-md-7">
                                    <input
                                      type="text"
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-xl-1 d-none d-xl-block p-0"></div>
                              <div className="col-xl-5 col-lg-6">
                                <div className="formInput">
                                  <div className="col-xl-4 col-md-5">
                                    <label>Mobile No.</label>
                                  </div>
                                  <div className="col-md-7">
                                    <input
                                      type="text"
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-xl-5 col-lg-6">
                                <div className="formInput">
                                  <div className="col-xl-4 col-md-5">
                                    <label>Email ID</label>
                                  </div>
                                  <div className="col-md-7">
                                    <input
                                      type="text"
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-xl-1 d-none d-xl-block p-0"></div>
                              <div className="col-xl-5 col-lg-6">
                                <div className="formInput">
                                  <div className="col-xl-4 col-md-5">
                                    <label>Alt. Mobile No.</label>
                                  </div>
                                  <div className="col-md-7">
                                    <input
                                      type="text"
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-xl-5 col-lg-6">
                                <div className="formInput">
                                  <div className="col-xl-4 col-md-5">
                                    <label>Alt. Email Id</label>
                                  </div>
                                  <div className="col-md-7">
                                    <input
                                      type="text"
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-2 col-md-3 p-0">
                            <div className="uploadPhoto">
                              <label htmlFor="uploadCSV" className="uploadBtn">
                                Upload photo
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="text-md-left">
                          <h4>Basic Info</h4>
                        </div>
                        <div className="form-row col-md-12">
                          <div className="form-row col-md">
                            <div className="row mx-0 formInput">
                              <div className="col-md-2">
                                <label>Name</label>
                              </div>
                              <div className="col-md-3">
                                <input
                                  type="text"
                                  className="form-control inputs"
                                  id="firstName"
                                  placeholder="First Name"
                                />
                              </div>
                              <div className="col-md-3">
                                <input
                                  type="text"
                                  className="form-control inputs"
                                  id="middleName"
                                  placeholder="Middle Name"
                                />
                              </div>
                              <div className="col-md-3">
                                <input
                                  type="text"
                                  className="form-control inputs"
                                  id="lastName"
                                  placeholder="Last Name"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="form-row col-md-12">
                            <div>
                              <div className="row m-0">
                                <div className="col-md-4">
                                  <div className="formInput">
                                    <div className="">
                                      <label>Date of Birth</label>
                                    </div>
                                    <div className="">
                                      <input
                                        type="date"
                                        className="form-control inputs"
                                        id="dob"
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-4">
                                  <div className="formInput">
                                    <div className="">
                                      <label>Age</label>
                                    </div>
                                    <div className="">
                                      <input
                                        type="number"
                                        className="form-control inputs"
                                        id="age"
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-4">
                                  <div className="formInput">
                                    <div className="">
                                      <label>Gender</label>
                                    </div>
                                    <div className="w-100">
                                      <select
                                        className="form-control inputs"
                                        id="gender"
                                      >
                                        <option value="male">Male</option>
                                        <option value="female">Female</option>
                                        <option value="other">Other</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="form-row col-md-12">
                            <div className="row m-0">
                              <div className="col-md-6">
                                <div className="formInput">
                                  <div className="">
                                    <label htmlFor="maritalStatus">
                                      Marital Status
                                    </label>
                                  </div>
                                  <div className="">
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="maritalStatus"
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="formInput">
                                  <div className="">
                                    <label htmlFor="qualification">
                                      Highest Qualification
                                    </label>
                                  </div>
                                  <div className="">
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="qualification"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="form-row col-md-12">
                            <div className="row m-0">
                              <div className="col-md-6">
                                <div className="formInput">
                                  <div className="">
                                    <label htmlFor="fatherName">
                                      Father's Name
                                    </label>
                                  </div>
                                  <div className="">
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="fatherName"
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="formInput">
                                  <div className="">
                                    <label htmlFor="motherName">
                                      Mother's Name
                                    </label>
                                  </div>
                                  <div className="">
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="motherName"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="form-row col-md-12">
                            <div className="text-md-left">
                              <div className="formInput col-md-12">
                                <h5>Address</h5>
                                <button
                                  type="btn success"
                                  className="btn btn-primary addbtn"
                                >
                                  <img src={TickImg} alt="icon" />
                                  <p>Address</p>
                                </button>
                              </div>
                            </div>
                          </div>

                          <div className="form-row col-md-12 m-0">
                            <div
                              className="d-flex"
                              style={{ gap: "1.5rem", width: "100%" }}
                            >
                              <div className="formInput formInput2">
                                <label htmlFor="permanentAddress">
                                  Permanent Address
                                </label>
                                <textarea
                                  className="form-control"
                                  id="permanentAddress"
                                  rows="4"
                                ></textarea>
                              </div>

                              <div className="formInput formInput2">
                                <label htmlFor="currentAddress">
                                  Current Address
                                </label>
                                <textarea
                                  className="form-control"
                                  id="currentAddress"
                                  rows="4"
                                ></textarea>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="submitFormButtonDiv"
                          style={{ textAlign: "center" }}
                        >
                          <button
                            type="button"
                            className="addDebtorBtn"
                            style={{ padding: "0.7rem 2rem" }}
                            onClick={openModal}
                          >
                            Add Debtor
                          </button>
                        </div>
                      </form>
                    </div>

                    <div className="debtorProfile">
                      <div className="debtorProfileContainer1">
                        <div className="debtordetailCards"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="brandingContainer">
                <div>
                  <h5>Powered by</h5>
                  <img src={Technotask} alt="Logo" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        id="myModal"
        show={showModal}
        onHide={closeModal}
        centered
        backdrop="static"
      >
        <Modal.Body className="modal_body">
          <div className="success_img d-flex justify-content-center">
            <img src={Success} alt="icon" />
          </div>
          <p>
            Debtor profile has been added successfully. Click on the button
            below to see debtor profile.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Link to="/Debtorprofile">
            <div className="btn go_to_login">Debtor Profile</div>
          </Link>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default DebtorProfile60;
