import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Error Message
export function showErrorMsg(msg) {
    toast.error(msg, {
      position: 'top-right',
      autoClose: 3000, // Duration in milliseconds
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: "colored",
    });
  }

  // Success Message
  export function showSuccessMsg() {

    toast.success('Updated Succesfully!', {
      position: 'top-right',
      autoClose: 3000, // Duration in milliseconds
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: "colored",
    });
  }