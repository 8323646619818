import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../Css/BookDemo.css";
import "../CommonJquery/CommonJquery";
import {
  check_vaild_save,
  getRandomSixLetterString,
} from "../CommonJquery/CommonJquery";
import {
  URL_FOR_BOOK_NOW_SAVE,
  server_post_data,
} from "../KeyConnection/KeysService"; // Adjust the path as needed
import axios from "axios";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import ModalImg from "../assets/images/modalImg.gif";
import $ from "jquery";
import { Helmet } from "react-helmet";

let captch_check = "";

function BookDemo() {
  const [userName, setuserName] = useState("");
  const [userEmail, setuserEmail] = useState("");
  const [userNumber, setuserNumber] = useState("");
  const [userCountry, setuserCountry] = useState("");
  const [userMsg, setuserMsg] = useState("");
  const [showLoaderWebsite, setshowLoaderWebsite] = useState(false);
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    if (captch_check === "") {
      captch_check = getRandomSixLetterString();
    }
  }, []);
  const [fileName, setFileName] = useState("");

  const handleFileChange = (event) => {
    const file = event.target.files[0]; // Get the selected file
    if (file) {
      setFileName(file.name); // Set the file name in the state
    } else {
      setFileName(""); // Reset the file name if no file is selected
    }
  };

  const [userInputCpt, setUserInputCpt] = useState("");
  const [isCaptchaValid, setIsCaptchaValid] = useState(true);

  function openModal() {
    $("#exampleModalCenter").modal("show");
  }

  useEffect(() => {
    // Fetch the list of all countries from the REST Countries API
    axios
      .get("https://restcountries.com/v3.1/all")
      .then((response) => {
        // Extract the country names from the response data
        const countryNames = response.data.map(
          (country) => country.name.common
        );
        setCountries(countryNames);
      })
      .catch((error) => {
        console.error("Error fetching countries:", error);
      });
  }, []);

  const handleSaveUserData = async (form_data, url_for_save) => {
    let vaild_data = check_vaild_save(form_data);

    if (vaild_data) {
      if (userInputCpt === captch_check) {
        setIsCaptchaValid(true);
        setshowLoaderWebsite(true);
        const body_data = JSON.stringify({
          user_name: userName,
          user_email: userEmail,
          user_no: userNumber,
          user_country: userCountry,
          user_msg: userMsg,
        });
        console.log(body_data);
        await server_post_data(url_for_save, body_data)
          .then((response) => response.json())
          .then((data) => {
            setshowLoaderWebsite(false);
            console.log(data);
            if (data.status !== "error") {
              openModal();
              setuserName("");
              setuserEmail("");
              setuserNumber("");
              setuserCountry("");
              setuserMsg("");
            }
          })
          .catch((error) => {
            console.log("asdsa");
            setshowLoaderWebsite(false);
          });
      } else {
        setIsCaptchaValid(false);
        // alert("Please enter Correct Captcha");
      }
    }
  };

  const reloadWindow = () => {
    window.location.reload();
  };
  return (
    <div className="bookdemo">
      <Helmet>
        <title>Book A Demo</title>
        <meta
          name="description"
          content="Compliant and Effortless AI Voice Solutions for Streamlined Debt Collection."
        />
      </Helmet>
      <header className="header_container">
        <Header />
      </header>
      <div className="bookdemo_container container-lg">
        <div className={showLoaderWebsite ? "loading_website" : ""}></div>
        <div className="bookdemoForm_container col-xl-8 col-lg-10 col-md-11">
          <div className="bookdemoHead">
            <h3 className="center">BOOK A DEMO</h3>
            <p className="center">
              Fill up your details, our representative will reach you within 24
              Hours.
            </p>
          </div>

          <form id="BookDemoForm">
            <div className="row">
              <div className="bookdemoInput bookdemoInputName col-md-6">
                <div className="mb-3">
                  <input
                    type="text"
                    className="form-control trio_name trio_mendate"
                    id="name"
                    name="name"
                    value={userName}
                    placeholder="Your Name*"
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(
                        /[^A-Za-z\s]/g,
                        ""
                      );
                    }}
                    onChange={(e) => {
                      setuserName(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="bookdemoInput col-md-6">
                <div className="mb-3">
                  <input
                    type="email"
                    className="form-control trio_email trio_mendate"
                    id="email"
                    name="email"
                    placeholder="Email Address*"
                    value={userEmail}
                    onChange={(e) => {
                      setuserEmail(e.target.value);
                    }}
                    onInput={(e) => {
                      if (!/^[a-zA-Z0-9@.]*$/.test(e.target.value)) {
                        e.target.value = ""; // Clear the input if it contains any other character
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="bookdemoInput col-md-6">
                <div className="mb-3">
                  <label htmlFor="country" className="form-label"></label>
                  <select
                    value={userCountry}
                    className="form-select trio_mendate"
                    onChange={(e) => {
                      setuserCountry(e.target.value);
                    }}
                    id="country"
                    name="country"
                  >
                    <option value="">Select Your Country</option>
                    {countries.map((country, index) => (
                      <option key={index} value={country}>
                        {country}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="bookdemoInput col-md-6">
                <div className="mb-3">
                  <label htmlFor="phone" className="form-label"></label>
                  <PhoneInput
                    id="phone"
                    name="phone"
                    defaultCountry="sa"
                    value={userNumber}
                    onChange={(phone) => setuserNumber(phone)}
                  />
                </div>
              </div>
            </div>
            <div className="bookdemoInput mb-3">
              <label htmlFor="message" className="form-label"></label>
              <textarea
                className="form-control"
                id="message"
                name="message"
                rows="5"
                value={userMsg}
                placeholder="Your Message Here"
                onChange={(e) => {
                  setuserMsg(e.target.value);
                }}
              ></textarea>
            </div>
          </form>

          <div className="inputFormCareer captchadIV col-md-12">
            <div>
              <div className="captchaText">
                <label htmlFor="captcha">Enter Captcha:</label>

                <h6>{captch_check}</h6>
              </div>
              <input
                type="text"
                className="form-control"
                id="captcha"
                name="captcha"
                onChange={(e) => {
                  setUserInputCpt(e.target.value);
                }}
                required
              />
              {isCaptchaValid ? (
                <p></p>
              ) : (
                <p className="text-danger mt-1 text-center">
                  CAPTCHA is invalid.
                </p>
              )}
            </div>
          </div>

          <div className="bookdemobTN text-center">
            <button
              data-toggle="modal"
              data-target="#exampleModalCenter"
              type="button"
              onClick={() =>
                handleSaveUserData("QRformData", URL_FOR_BOOK_NOW_SAVE)
              }
              className="btn btn-primary"
            >
              Submit
            </button>
          </div>
        </div>

        <div className="whatsnext_container">
          <div className="whatsnext_container_wrapper">
            <div className="What_happen_nxt_heading_div">
              <h4 className="What_happen_nxt_heading">
                <span className="what_heading">What </span>
                <span className="happen_nxt_heading">Happens Next?</span>
              </h4>
            </div>
            <div className="whatsnextTextwrapper row">
              <div className=" whatsnextText col-sm-5 mx-auto">
                <div className="numbox">
                  <p>01</p>
                </div>
                <p>Our Team Will Reach Out</p>
              </div>
              <div className="whatsnextText col-sm-5 mx-auto">
                <div className="numbox">
                  <p>02</p>
                </div>
                <p>Requirement Analysis & Demo</p>
              </div>
              <div className="whatsnextText col-sm-5 mx-auto">
                <div className="numbox">
                  <p>03</p>
                </div>
                <p>Detailed Interaction About the Process</p>
              </div>
              <div className="whatsnextText col-sm-5 mx-auto">
                <div className="numbox">
                  <p>04</p>
                </div>
                <p>Project Commitment & Resource Allocation</p>
              </div>
            </div>
          </div>
        </div>

        <div className="emptyBook"></div>
      </div>
      <footer className="footer_area section_padding_130_0 px-lg-5 px-md-4 px-sm-3 px-1">
        <Footer />
      </footer>

      <div
        className="modal fade"
        id="exampleModalCenter"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close closeModay"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="text-center mb-2">
                <img src={ModalImg} alt="icon" />
              </div>
              We're thrilled that you've booked a demo with us! Brace yourself
              for an awe-inspiring showcase of our AI model's remarkable
              features and benefits. Your world is about to be revolutionized!
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary closeFooterModal"
                data-dismiss="modal"
                onClick={reloadWindow}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BookDemo;
