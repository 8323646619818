import React, { useRef } from "react";
import "../Css/Career.css";
import Header from "./Header";
import Footer from "./Footer";
import facePink from "../assets/images/facePink.svg";
import LinkedPink from "../assets/images/linkPink.svg";
import whatsPink from "../assets/images/whatsPink.svg";
import emailPink from "../assets/images/emailPink.svg";
import CareerImg1 from "../assets/images/careerImg1.svg";
import CareerImg2 from "../assets/images/careerImg2.svg";
import CareerImg3 from "../assets/images/careerImg3.svg";
import CareerImg4 from "../assets/images/careerImg4.svg";
import rightArrow from "../assets/images/rightImage.svg";
import CareerForm from "./CareerForm";
import Perks1 from "../assets/images/Perks1.png";
import Perks2 from "../assets/images/Perks2.png";
import Perks3 from "../assets/images/Perks3.png";
import Perks4 from "../assets/images/Perks4.png";
import Perks5 from "../assets/images/Perks5.png";
import Perks6 from "../assets/images/Perks6.png";
import perks7 from "../assets/images/Group 9248.png";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

function Career() {
  function sendMessageToWhatsApp() {
    const phoneNumber = "+919433032220"; // Replace with the recipient's phone number
    const message = "Hello, How may i help You!"; // Replace with your desired message

    // Create a WhatsApp URL with the phone number and message
    const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      message
    )}`;

    // Open the WhatsApp chat in a new tab or window
    window.open(whatsappURL, "_blank");
  }

  const careerFormRef = useRef(null);
  const scrollToForm = () => {
    if (careerFormRef.current) {
      const testimonialsElement = careerFormRef.current;
      const testimonialsOffset = testimonialsElement.offsetTop;
      const scrollToPosition =
        testimonialsOffset -
        6 * parseFloat(getComputedStyle(document.documentElement).fontSize);

      window.scrollTo({
        top: scrollToPosition,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="career">
      <Helmet>
        <title>Join Our Team</title>
        <meta
          name="description"
          content="WE HAVE A WIDE ARRAY OF FANTASTIC OPPORTUNITIES AWAITING YOU."
        />
      </Helmet>
      <header className="header_container">
        <Header />
      </header>

      <section className="careerCnct container-lg">
        <div className="careerCnct_container">
          <div className="careerCnctHead">
            <div className="careerCnctHeadLeft">
              <p>
                WE HAVE A WIDE ARRAY OF FANTASTIC OPPORTUNITIES AWAITING YOU
              </p>
              <h1>Join Our Team</h1>
            </div>
            <div className="careerCnctHeadLeft">
              <ul>
                <li className="careerIcons1">
                  <a href="https://www.facebook.com/technotask.business.solutions/">
                    <img src={facePink} alt="icon" />
                  </a>
                </li>
                <li className="careerIcons2">
                  <a href="https://instagram.com/technotaskglobal?igshid=MzRlODBiNWFlZA==">
                    <img src={LinkedPink} alt="icon" />
                  </a>
                </li>
                <li className="careerIcons3" onClick={sendMessageToWhatsApp}>
                  <img src={whatsPink} alt="icon" />
                </li>
              </ul>
            </div>
          </div>
          <div className="emailPhoneD">
            <div className="emailD">
              <img src={emailPink} alt="icon" />
              <p>hr@technotask.co.in</p>
            </div>
            <div className="emailD">
              <img src={perks7} alt="icon" />
              <p>+91 97130 63623</p>
            </div>
          </div>
        </div>

        <div className="careerCards_container">
          <div className="row">
            <div className="careerCard col-md-3 col-sm-6">
              <Link to="/careers/vacancy_details">
                <div className="careerCardDiv">
                  <div className="careerCardHead">
                    <div className="d-flex">
                      <div className="careerCardHeadBorder"></div>
                      <div className="careerCardHeadText">
                        <p>IT SALES EXECUTIVEVE</p>
                      </div>
                    </div>
                    <img src={CareerImg1} alt="icon" />
                  </div>
                  <div className="careerCardFooter">
                    <p>1-3 Years </p>
                    <div className="goBtn">
                      <img src={rightArrow} alt="icon" />
                    </div>
                  </div>
                </div>
              </Link>
            </div>

            <div className="careerCard col-md-3 col-sm-6">
              <Link to="/careers/vacancy_details">
                <div className="careerCardDiv">
                  <div className="careerCardHead">
                    <div className="d-flex">
                      <div className="careerCardHeadBorder"></div>
                      <div className="careerCardHeadText">
                        <p>Upwork Online Bidder</p>
                      </div>
                    </div>
                    <img src={CareerImg2} alt="icon" />
                  </div>
                  <div className="careerCardFooter">
                    <p>1-3 Years </p>
                    <div className="goBtn">
                      <img src={rightArrow} alt="icon" />
                    </div>
                  </div>
                </div>
              </Link>
            </div>

            <div className="careerCard col-md-3 col-sm-6">
              <Link to="/careers/vacancy_details">
                <div className="careerCardDiv">
                  <div className="careerCardHead">
                    <div className="d-flex">
                      <div className="careerCardHeadBorder"></div>
                      <div className="careerCardHeadText">
                        <p>Sr. Python Developer </p>
                      </div>
                    </div>
                    <img src={CareerImg3} alt="icon" />
                  </div>
                  <div className="careerCardFooter">
                    <p>1-3 Years </p>
                    <div className="goBtn">
                      <img src={rightArrow} alt="icon" />
                    </div>
                  </div>
                </div>
              </Link>
            </div>

            <div className="careerCard col-md-3 col-sm-6">
              <Link to="/careers/vacancy_details">
                <div className="careerCardDiv">
                  <div className="careerCardHead">
                    <div className="d-flex">
                      <div className="careerCardHeadBorder"></div>
                      <div className="careerCardHeadText">
                        <p>Sr. Front End Developer</p>
                      </div>
                    </div>
                    <img src={CareerImg4} alt="icon" />
                  </div>
                  <div className="careerCardFooter">
                    <p>1-3 Years </p>
                    <div className="goBtn">
                      <img src={rightArrow} alt="icon" />
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className="careerForm container-lg">
        <div className="careerForm_container">
          <div className="careerFormBtn">
            <button onClick={scrollToForm}>
              <h6>"Can't find your area of expertise?"</h6>
              <p>Apply now</p>
            </button>
          </div>

          <div className="careerFormHead col-md-10 mx-auto">
            <p>"Discover your niche?"</p>
          </div>
        </div>

        <div ref={careerFormRef} className="formDetailsContainer">
          <CareerForm />
        </div>
      </section>

      <section className="perksBenefits container-lg">
        <div className="perksBenefits-container">
          <div className="perksBenefitsHead">
            <h3>Perks & Benefits</h3>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 p-3 pt-5">
              <div className="perksBenefitscard">
                <div className="perksBenefitsimage col-4">
                  <img src={Perks1} alt="Card 1" />
                </div>
                <div className="text">
                  <p>
                    <b>Professional Development</b>
                  </p>
                  <p>
                    Professional growth is a must for an individual from any
                    field. You will get dedicated time to invest in increasing
                    your professional skills that add value to your profile and
                    career opportunities, as well.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 p-3 pt-5">
              <div className="perksBenefitscard">
                <div className="perksBenefitsimage col-4">
                  <img src={Perks2} alt="Card 1" />
                </div>
                <div className="text">
                  <p>
                    <b>Healthcare Activities</b>
                  </p>
                  <p>
                    At Technotask , we organize private Healthcare activities to
                    ensure you are in good health. We also finance the medical
                    package for our employees from one of the finest health care
                    groups.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 p-3 pt-5">
              <div className="perksBenefitscard">
                <div className="perksBenefitsimage col-4">
                  <img src={Perks3} alt="Card 1" />
                </div>
                <div className="text">
                  <p>
                    <b>Relax Time</b>
                  </p>
                  <p>
                    It’s imperative to switch off and relax sometime around the
                    office. We emphasize creating a work and life balance to
                    rejuvenate and boost productivity. We practice it by taking
                    paid time off for a vacation or recharge through some fun
                    activities.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 p-3 pt-5">
              <div className="perksBenefitscard">
                <div className="perksBenefitsimage col-4">
                  <img src={Perks4} alt="Card 1" />
                </div>
                <div className="text">
                  <p>
                    <b>Above Average Salaries</b>
                  </p>
                  <p>
                    We evaluate and recognize the skills within you to offer
                    salaries or annual packages that match your position of work
                    and are best in the industry. Employees that are part of our
                    company perform the best out of themselves and make it
                    count.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 p-3 pt-5">
              <div className="perksBenefitscard">
                <div className="perksBenefitsimage col-4">
                  <img src={Perks5} alt="Card 1" />
                </div>
                <div className="text">
                  <p>
                    <b>Soft Skills Development</b>
                  </p>
                  <p>
                    We conduct weekly English classes for our employees to
                    ensure they are comfortable in communication with our
                    international clients and inside the premises of our
                    company.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 p-3 pt-5">
              <div className="perksBenefitscard">
                <div className="perksBenefitsimage col-4">
                  <img src={Perks6} alt="Card 1" />
                </div>
                <div className="text">
                  <p>
                    <b>Co-Finance Conference</b>
                  </p>
                  <p>
                    Our vision is to learn and grow together. We will manage and
                    partially fund the budget for your conference attendance so
                    that you can learn from the best and acquire optimal skills.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <footer className="footer_area section_padding_130_0 px-lg-5 px-md-4 px-sm-3 px-1">
        <Footer />
      </footer>
    </div>
  );
}

export default Career;
