import "../Css/NafiyaScreen.css";
import maintance from "../assets/images/maintance.gif";

function Maintance() {
  return (
    <div className="screen_container">
      <div className="screen_wrapper col-lg-11 mx-auto">
        <div className="policies_screen col-lg-11 mx-auto">
          <div className="policies_screen_row row m-0">
            <div className="full_loader_image col-5 m-auto">
              <img
                src={maintance}
                className="speaker_load_image2"
                alt="speaker"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Maintance;
