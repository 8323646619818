import React, { useState } from "react";
import "../Css/Dashboard.css";
import "../Css/Bucket30days.css";
import "../Css/SettingPage.css";
import "../Css/UserManagement.css";
import SideBar from "./SideBar";
import CircleCheck from "../../assets/images/circle_check.svg";
import NotificationsImg from "../../assets/images/notification.svg";
import SideBarControl from "../../assets/images/rightLeftSIdebar.png";
import UserImgPrf from "../../assets/images/userimg45.svg";
import adm_img from "../../assets/images/adm_img.png";
import "react-circular-progressbar/dist/styles.css";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import addImg from "../../assets/images/addImg.png";
import userBtn from "../../assets/images/usermngmt.svg";
import userBtnwhite from "../../assets/images/usermngmntblue.svg";
import invBtn from "../../assets/images/invoicee.svg";
import subsButton from "../../assets/images/subscriptions.svg";
import RightaRROW from "../../assets/images/rightmorethan.svg";
import RightaRROWWhite from "../../assets/images/rightmorethanblue.svg";
import { Link, useLocation } from "react-router-dom";
import Logout from "../../assets/images/giflogout.gif";
import Technotask from "../../assets/images/TechnotaskLogo12.png";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CenterNavbar from "./CenterNavbar";
import invBtnWhite from "../../assets/images/invoiceeBlue.svg";
import subsButtonWhite from "../../assets/images/subscriptionsblue.svg";

function UserProfileCompleteSideBar() {
  const location = useLocation();
  return (
    <div className="circleProgBar m-auto">
      <div className="collecBar">
        <div style={{ width: "60px" }}>
          <CircularProgressbar
            value={75}
            text={`${75}%`}
            styles={buildStyles({
              textColor: "#fff", // Color of the text
              trailColor: `blank`, // Color of the progress circle
              pathColor: "rgba(255, 255, 255, 1)", // Color of the background trail
            })}
          />
        </div>
        <div className="profileTxt">
          <p>Profile Information </p>
          <button className="completeProfileBtn">Complete your profile</button>
        </div>
      </div>
      <div className="button-container23">
        <Link to="/SettingPage">
          <button className="custom-button23">
            <div
              className={`insdbtndiv ${
                location.pathname === "/SettingPage"
                  ? "activemainSettingItem"
                  : ""
              }`}
            >
              <img
                src={
                  location.pathname === "/SettingPage"
                    ? subsButtonWhite
                    : subsButton
                }
                alt="ICON"
              />
              <p>Subscriptions</p>
            </div>
            <div>
              <img
                src={
                  location.pathname === "/SettingPage"
                    ? RightaRROWWhite
                    : RightaRROW
                }
                alt="ICON"
              />
            </div>
          </button>
        </Link>
        <Link to="/SettingPage/Invoicepage">
          <button className="custom-button23">
            <div
              className={`insdbtndiv ${
                location.pathname === "/SettingPage/Invoicepage"
                  ? "activemainSettingItem"
                  : ""
              }`}
            >
              <img
                src={
                  location.pathname === "/SettingPage/Invoicepage"
                    ? invBtnWhite
                    : invBtn
                }
                alt="ICON"
              />
              <p>Invoice</p>
            </div>
            <div>
              <img
                src={
                  location.pathname === "/SettingPage/Invoicepage"
                    ? RightaRROWWhite
                    : RightaRROW
                }
                alt="ICON"
              />
            </div>
          </button>
        </Link>
        <Link to="/SettingPage/Usermanagement">
          <button className="custom-button23">
            <div
              className={`insdbtndiv ${
                location.pathname === "/SettingPage/Usermanagement"
                  ? "activemainSettingItem"
                  : ""
              }`}
            >
              <img
                src={
                  location.pathname === "/SettingPage/Usermanagement"
                    ? userBtnwhite
                    : userBtn
                }
                alt="ICON"
              />
              <p>User Management</p>
            </div>
            <div>
              <img
                src={
                  location.pathname === "/SettingPage/Usermanagement"
                    ? RightaRROWWhite
                    : RightaRROW
                }
                alt="ICON"
              />
            </div>
          </button>
        </Link>
      </div>
    </div>
  );
}

export default UserProfileCompleteSideBar;
