import React, { useState, useEffect } from "react";
import "../Css/Dashboard.css";
import "../Css/Bucket30days.css";
import "../Css/SettingPage.css";
import "../Css/HelpSupport.css";
import SideBar from "./SideBar";
import CircleCheck from "../../assets/images/circle_check.svg";
import NotificationsImg from "../../assets/images/notification.svg";
import SideBarControl from "../../assets/images/rightLeftSIdebar.png";
import UserImgPrf from "../../assets/images/userimg45.svg";
import Tooltip from "../../assets/images/tooltipIcon.png";
import Success from "../../assets/images/success_gif.gif";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import Technotask from "../../assets/images/TechnotaskLogo12.png";
import Logout from "../../assets/images/giflogout.gif";
import CenterNavbar from "./CenterNavbar";
import { Dropdown } from "primereact/dropdown";
import { Helmet } from "react-helmet";

import {
  server_post_data,
  URL_FOR_TICKETS,
  URL_FOR_TICKET_CONVERSATION,
} from "../../KeyConnection/KeysService"; // Adjust the path as needed

function CreateNewTicket() {
  const user_name = localStorage.getItem("user_name") || ""; // logged in user
  const user_email = localStorage.getItem("user_email") || ""; // logged in user
  const [isLoading, setLoading] = useState(false);
  const [overviewShow, setOverviewShow] = useState(true); // Default to 'true'
  const [debtorProfile, setdebtorProfile] = useState(false);

  const [email, setEmail] = useState("");
  const [ticketType, setTicketType] = useState("");
  const [priority, setPriority] = useState("High");
  const [ticketBody, setTicketBody] = useState("");
  const [companyID, setCompanyID] = useState(
    localStorage.getItem("company_id")
  );

  // Function to handle form submission
  const handleFormSubmit = () => {
    // Perform actions with form data, e.g., send ticket
    createNewTicket(URL_FOR_TICKETS);
    console.log("Form submitted", { email, ticketType, priority, ticketBody });
    setShowModal(true);

    setEmail("");
    setTicketType("");
    setPriority("High");
    setTicketBody("");
  };
  const [showModal, setShowModal] = useState(false);
  const closeModal = () => {
    setShowModal(false);
  };

  const createNewTicket = async (url_for) => {
    setLoading(true);
    const body_data = JSON.stringify({
      email: user_email,
      ticket_for_flag: 3,
      request_ticket_type: ticketType,
      priority_status: priority,
      company_id: companyID,
      ticket_body: ticketBody,
      ticket_category: "0", // as this one is new
    });

    console.log(body_data);
    try {
      const response = await server_post_data(url_for, body_data);
      const data = await response.json();
      await addTicketToConversation(
        URL_FOR_TICKET_CONVERSATION,
        data.data.uuid_id
      );
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const addTicketToConversation = async (url_for, uuid_id) => {
    setLoading(true);
    const body_data = JSON.stringify({
      uuid_id: uuid_id,
      priority_status: priority,
      ticket_body: ticketBody,
    });

    console.log(body_data);
    server_post_data(url_for, body_data)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.error(error);
      });
    setLoading(true);
  };

  const ticketNAme = [
    { name: "Bug", code: "Bug" },
    { name: "Feature Request", code: "Feature Request" },
    { name: "Amendment", code: "Ammendment" },
    { name: "Upgradation", code: "Upgradation" },
    { name: "KB Update", code: "KB Update" },
  ];

  const priorityArray = [
    { name: "High", code: "High" },
    { name: "Medium", code: "Medium" },
    { name: "Low", code: "Low" },
  ];

  useEffect(() => {
    // Enable tooltips on component mount
    const tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new window.bootstrap.Tooltip(tooltipTriggerEl);
    });
  }, []);

  return (
    <div className="dashboard">
      <Helmet>
        <title>Amra User Dashboard</title>
        <meta
          name="description"
          content="AI and Global Reach: The Key to Scalable Solutions in Customer
          Experience"
        />
      </Helmet>
      <div className="dashboard_container">
        <div className={isLoading ? "loader-overlay show" : "loader-overlay"}>
          <div className="loader23"></div>
        </div>
        <div className="dashboard_wrapper">
          <SideBar
            overviewShow={overviewShow}
            setOverviewShow={setOverviewShow}
            debtorProfile={debtorProfile}
            setdebtorProfile={setdebtorProfile}
          />
          <div id="content">
            <CenterNavbar />
            <div className="Suppotr_container">
              <div className="Suppotr_wrapper">
                <div className="newticket-container">
                  <div className="newticket_head">
                    <h4>Create Quick Ticket</h4>
                    <p>Write and address new queries and issues</p>
                  </div>
                  <div className="newTicketform">
                    <div className="row m-0">
                      {/* <div className="col-md-4">
                        <div className="form-row">
                          <label>Email</label>
                          <br />
                          <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Type Email"
                          />
                        </div>
                      </div> */}

                      <div className="col-md-4">
                        <div className="form-row">
                          <label>Request Ticket Type</label>
                          <br />

                          <select
                            value={ticketType}
                            onChange={(e) => setTicketType(e.target.value)}
                            placeholder="Choose Type"
                            className="priorityBtn"
                            style={{ paddingTop: "8px", paddingBottom: "8px" }}
                          >
                            <option value=" ">Choose Type</option>
                            {ticketNAme.map((item, index) => (
                              <option key={index} value={item.code}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-row">
                          <label>Priority Status</label>
                          <button
                            type="button"
                            className="TOOLTIPbTN"
                            data-bs-toggle="tooltip"
                            data-bs-placement="right"
                            data-bs-title="Tooltip on right"
                          >
                            <img src={Tooltip} alt="icon" />
                          </button>
                          <br />

                          <select
                            value={priority}
                            onChange={(e) => setPriority(e.target.value)}
                            placeholder="Choose Type"
                            optionLabel="name"
                            className="priorityBtn"
                            style={{ paddingTop: "8px", paddingBottom: "8px" }}
                          >
                            <option value=" ">Choose Type</option>
                            {priorityArray.map((item, index) => (
                              <option key={index} value={item.code}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="form-row" style={{ padding: "0 12px" }}>
                      <label>Ticket Body</label>
                      <br />
                      <textarea
                        rows="7"
                        value={ticketBody}
                        onChange={(e) => setTicketBody(e.target.value)}
                      />
                    </div>
                  </div>
                  <div
                    className="newticketSubmitBtn"
                    style={{ padding: "0 12px" }}
                  >
                    <button onClick={handleFormSubmit}>Send Ticket</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="brandingContainer">
              <div>
                <h5>Powered by</h5>
                <img src={Technotask} alt="Logo" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        id="myModal"
        show={showModal}
        onHide={closeModal}
        centered
        backdrop="static"
      >
        <Modal.Body className="modal_body">
          <div className="success_img d-flex justify-content-center">
            <img src={Success} alt="icon" />
          </div>
          <p>
            Your ticket has been send successfully. Click on the button below to
            see your tickets.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Link to="/HelpSupport1">
            <div className="btn go_to_login">View Tickets</div>
          </Link>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default CreateNewTicket;
