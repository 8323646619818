import React, { useState, useEffect } from "react";
import "../Css/QRPage.css";
import "../../Css/loading.css";
import LogoCom from "../../assets/images/logoNew.png";
import HeadPhone from "../../assets/images/Headphone34.png";
import Username from "../../assets/images/Username.png";
import EmailAdrs from "../../assets/images/EmailAddress.png";
import Gender from "../../assets/images/gendernew1.png";
import Watch from "../../assets/images/Watch.png";
import debt from "../../assets/images/duedate.png";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { empty_form, check_vaild_save } from "../../CommonJquery/CommonJquery";
import {
  URL_FOR_LINK_SEND_FORM,
  server_post_data,
} from "../../KeyConnection/KeysService"; // Adjust the path as needed

function QRForm() {
  const [userName, setuserName] = useState("");
  const [userEmail, setuserEmail] = useState("");
  const [userNumber, setuserNumber] = useState("");
  const [userBucket, setuserBucket] = useState("");
  const [tokenno, settokenno] = useState("0");
  const [userGender, setuserGender] = useState("");
  const [userForm, setuserForm] = useState(false);
  const [time, setTime] = useState(120);
  const [showLoaderWebsite, setshowLoaderWebsite] = useState(false);
  useEffect(() => {
    if (userForm) {
      const intervalId = setInterval(() => {
        if (time > 0) {
          setTime(time - 1);
        } else {
          window.location.reload();
        }
      }, 1000);

      // Clean up the interval when the component unmounts
      return () => clearInterval(intervalId);
    }
  }, [time, userForm]);

  const handleSaveUserData = async (form_data, url_for_save) => {
    let vaild_data = check_vaild_save(form_data);

    if (vaild_data) {
      setshowLoaderWebsite(true);
      const body_data = JSON.stringify({
        debtor_name: userName,
        debtor_email: userEmail,
        debtor_no: userNumber,
        debtor_bucket: userBucket,
        debtor_gender: userGender,
      });
      await server_post_data(url_for_save, body_data)
        .then((response) => response.json())
        .then((data) => {
          setshowLoaderWebsite(false);
          console.log(data);
          if (data.status === "error") {
            alert(data.data);
          } else {
            settokenno(data.data);
            setuserForm(true);
            empty_form(form_data);
          }
        })
        .catch((error) => {
          console.log("asdsa");
          setshowLoaderWebsite(false);
        });
    }
  };
  //URL_FOR_LINK_SEND_FORM

  return (
    <div className="qrpage2">
      <div className="qr_contaianer">
        <div className={showLoaderWebsite ? "loading_website" : ""}></div>
        <div className="qr_wrapper col-xl-4 col-lg-11 m-auto">
          {!userForm && (
            <div className="qrsrceen qrsrceen2 ">
              <div className="comapnyLogo1 col-xl-5 col-md-6 col-8 mx-auto">
                <img src={LogoCom} alt="Triosoft" />
              </div>
              <div className="fillInfoHead">
                <h4>Please Fill Information</h4>
                <div className="fillInfoHeadImg">
                  <img src={HeadPhone} alt="Triosoft" />
                </div>
              </div>
              <div className="QRFormDiv col-sm-11 p-0">
                <form id="QRformData">
                  <div className="QRInputS">
                    <div className="QRInput">
                      <img src={Username} alt="Triosoft" />
                      <input
                        type="text"
                        id="name"
                        name="name"
                        placeholder="Your Name"
                        className="trio_name trio_mendate"
                        onChange={(e) => {
                          setuserName(e.target.value);
                        }}
                        onInput={(e) => {
                          e.target.value = e.target.value.replace(
                            /[^A-Za-z\s]/g,
                            ""
                          );
                        }}
                      />
                    </div>
                    <div className="QRInput">
                      <img src={EmailAdrs} alt="Triosoft" />
                      <input
                        type="text"
                        id="email"
                        name="email"
                        className="trio_email trio_mendate"
                        placeholder="Your Email"
                        onChange={(e) => {
                          setuserEmail(e.target.value);
                        }}
                        onInput={(e) => {
                          if (!/^[a-zA-Z0-9@.]*$/.test(e.target.value)) {
                            e.target.value = ""; // Clear the input if it contains any other character
                          }
                        }}
                      />
                    </div>
                    <div className="QRInput">
                      <PhoneInput
                        id="phone"
                        name="phone"
                        defaultCountry="sa"
                        value={userNumber}
                        onChange={(phone) => setuserNumber(phone)}
                      />
                    </div>
                    <div className="QRInput">
                      <img src={Gender} className="dueDate" alt="Triosoft" />
                      <select
                        id="bucket"
                        name="bucket"
                        onChange={(e) => {
                          setuserGender(e.target.value);
                        }}
                      >
                        <option value="">
                          <span>Select Your Gender</span>
                        </option>
                        <option value="M">Male</option>
                        <option value="F">Female</option>
                        <option value="O">Transgender</option>
                        <option value="O">Others</option>
                      </select>
                    </div>
                    <div className="QRInput">
                      <img src={debt} className="dueDate" alt="Triosoft" />
                      <select
                        id="bucket"
                        name="bucket"
                        onChange={(e) => {
                          setuserBucket(e.target.value);
                        }}
                      >
                        <option value="">Debt Due Since</option>
                        <option value="30">30 Days</option>
                        <option value="60">60 Days</option>
                        <option value="90">90 Days</option>
                      </select>
                    </div>
                  </div>
                </form>

                <div className="SaveBtn">
                  <button
                    onClick={() =>
                      handleSaveUserData("QRformData", URL_FOR_LINK_SEND_FORM)
                    }
                    type="button"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          )}
          {userForm && (
            <div className="qrsrceen">
              <div className="comapnyLogo">
                <img src={LogoCom} alt="Triosoft" />
              </div>

              <div className="tokenNumber">
                <h6>Your Token Number is :</h6>
                <h4>{tokenno}</h4>
              </div>
              <div className="scanQR scanQR2 col-sm-11 mx-auto">
                <h4>Thank you for Showing Interest in Me</h4>
                <div className="QRCOde col-5 m-auto">
                  <img src={Watch} alt="Triosoft" />
                </div>
                <div className="timer30s">
                  <p>{time} seconds</p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default QRForm;
