import React, { useState, useEffect, useRef } from "react";
import NAfiaAdminSideBar from "./NAfiaAdminSideBar";
import "../../Dasboard/Css/Dashboard.css";
import "../AdminSIdebar.css";
import "../NAfiaAdmin.css";
import { Chart as ChartJS, registerables } from "chart.js";
import "react-circular-progressbar/dist/styles.css";
import SearchImg from "../../assets/images/search-normal.svg";
import { Helmet } from "react-helmet";
import {
  URL_FOR_NAFIA_ADMIN_DASH,
  chatboat_api,
  CompanyListNafia,
  URL_FOR_TICKETS,
  NAFIA_USER_LIST,
  server_get_data_master,
} from "../../KeyConnection/KeysService";
import $ from "jquery";
import moment from "moment";
import "moment/locale/en-au";
import "daterangepicker";
import CenterNavNafiaAuperAdmin from "./CenterNavNafiaAuperAdmin";
import axios from "axios";
import { Modal } from "react-bootstrap";
import {
  handleAphabetsChange,
  handleEmailChange,
  handleIaphabetnumberChange,
  handleNumbersChange,
} from "../../CommonJquery/CommonJquery.js";
import DownloadingUp from "../../assets/images/Downloading Updates.png";

import searchCrawler from "../../assets/images/searchCrawl.png";
import Dltimg from "../../assets/images/Dltimg.png";
import InterNet from "../../assets/images/Internet.svg";
import FileIcon from "../../assets/images/file_icon.svg";
import { PhoneInput } from "react-international-phone";
import { Link, useLocation, useNavigate } from "react-router-dom";

function KBManagementNafia() {
  const [Nafiaadmindata, setdataFromNafia] = useState([]);
  const [userNumber, setuserNumber] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [filecharacter, setFileCharacter] = useState(0);
  const [websitecharacter, setWebsiteCharacter] = useState(0);
  const [page_found, setPage_found] = useState(0);
  const [page_crawled, setPage_crawled] = useState(0);
  const [url_characters_found, setUrl_characters_found] = useState(0);
  const [textcharacter, setTextCharacter] = useState(0);
  const [totalCharacterCount, setTotalCharacterCount] = useState(0);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [urls, setUrls] = useState([]);
  const [checkedBoxes, setCheckedBoxes] = useState([]);
  const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];
  const navigate = useNavigate();
  const [companyListData, setCompanyListData] = useState([]);
  const [botID, setBotID] = useState(1);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = new Intl.DateTimeFormat("en-US", { month: "long" }).format(
      date
    );
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  };

  const allknowledgeupdate = async () => {
    let adminData = {
      kb_under_updation: 0,
      total_script: 0,
      total_libraries: 0,
      kb_tickets: 0,
      flow_tickets: 0,
    };
    const arr = [];
    const response = await server_get_data_master(URL_FOR_TICKETS);
    const data2 = await response.json();
    const ticketsArr = [];
    // setshowLoaderWebsite(false);
    if (data2.status === "error") {
      alert("Error fetching data");
    } else {
      data2.data.forEach((ticket) => {
        ticketsArr.push(ticket);
        if (ticket.ticket_for_flag === "2") {
          console.log(ticket);
          if (ticket.request_ticket_type === "KB Update") {
            adminData.kb_tickets += 1;
          } else {
            adminData.flow_tickets += 1;
          }
        }
      });
    }
    const res = await server_get_data_master(CompanyListNafia);
    const data = await res.json();
    const companyList = data.data;
    companyList.forEach(async (company) => {
      let plan_expiry_date = new Date(company.plan_expiry_date);
      if (plan_expiry_date.getMonth() === new Date().getMonth()) {
        adminData.accounts_for_renewals += 1;
      }
      const headers = {
        "Content-Type": "application/json", // Change the content type to handle file upload
      };
      const data = await axios.get(chatboat_api(company.id), headers);
      const knowledgeList = data.data.data;
      adminData.total_libraries += knowledgeList.length;

      setdataFromNafia(adminData);

      let last_ticket_date = "";
      let last_ticket_no = "";
      ticketsArr.forEach((ticket) => {
        if (Number(ticket.company_id) === company.id) {
          console.log(ticket);
          if (last_ticket_date === "") {
            last_ticket_date = new Date(ticket.created_at);
            last_ticket_no = ticket.uuid_id;
          } else {
            if (new Date(ticket.created_at) > last_ticket_date) {
              last_ticket_date = new Date(ticket.created_at);
              last_ticket_no = ticket.uuid_id;
            }
          }
        }
      });
      let startDate = "2023-01-01";
      let endDate = "2024-01-28";
      let target_url = `${NAFIA_USER_LIST}${startDate}/${endDate}/${company.id}/${botID}/`;
      //console.log(target_url);
      const res = await server_get_data_master(target_url);
      const data3 = await res.json();
      const interactions = data3.data;

      let total_duration_sec = 0;
      let total_duration_min = 0;
      let total_duration_hrs = 0;
      interactions.forEach((interaction, idx) => {
        let date = new Date(interaction.first_created_at);
        let date_to = new Date(interaction.first_responce_at);

        console.log(date);
        console.log(date_to);

        // Calculate the difference in milliseconds
        let timeDiff = Math.abs(date_to - date);

        // Convert milliseconds to minutes
        let diffHours = Math.ceil(timeDiff / (1000 * 60 * 60));
        let diffMinutes = Math.ceil(timeDiff / (1000 * 60));
        let diffSeconds = Math.ceil(timeDiff / 1000);

        total_duration_sec += diffSeconds;
        total_duration_min += diffMinutes;
        total_duration_hrs += diffHours;
      });

      const obj = {
        id: company.id,
        name: company.name,
        plan_expiry_date: formatDate(plan_expiry_date),
        total_duration_hrs,
        total_duration_min,
        total_duration_sec,
        total_interactions: interactions.length,
        total_kbs: knowledgeList.length,
        last_ticket_date:
          last_ticket_date === "" ? "No ticket" : formatDate(last_ticket_date),
        last_ticket_no,
      };
      // console.log(obj);
      arr.push(obj);
      setCompanyListData(arr);
    });
  };

  // const formattedData = AmraadminData.map((item) => {
  //   const dateTime = new Date(item.created_at);
  //   const date = dateTime.toLocaleDateString(); // Format as 'MM/DD/YYYY'
  //   const time = dateTime.toLocaleTimeString(); // Format as 'HH:MM:SS'

  //   return { id: item.id, date, time };
  // });

  useEffect(() => {
    // This will be called when the component mounts
    allknowledgeupdate();
  }, []);

  const handleInputChange = (index, e) => {
    const value = e.target.value;
    if (value.length === 1 && index < 3) {
      inputRefs[index + 1].current.focus();
    } else if (value.length === 0 && index > 0) {
      inputRefs[index - 1].current.focus();
    } else if (value.length === 0 && index === 0) {
    }
  };

  ChartJS.register(...registerables);

  const handleFileSelect = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles((prevSelectedFiles) => [...prevSelectedFiles, ...files]);
  };

  const CheckUncheckBox = (index) => {
    // Check if the checkbox is already checked
    const updatedwebchr = [...urls];
    const slchk = updatedwebchr.splice(index, 1)[0]; // Remove the file at the specified index
    // console.log(slchk.charctr);
    const chtrlnts = slchk.charctr; // Get the character count of the deleted file
    if (checkedBoxes.includes(index)) {
      // Checkbox is already checked, so uncheck it
      setCheckedBoxes(checkedBoxes.filter((item) => item !== index));
      setWebsiteCharacter((prevCount) => prevCount - chtrlnts);
    } else {
      // Checkbox is unchecked, so check it
      setCheckedBoxes([...checkedBoxes, index]);
      setWebsiteCharacter((prevCount) => prevCount + chtrlnts);
    }
  };

  const handleDltFiles = () => {
    setSelectedFiles([]);
  };

  const handleDltUrls = () => {
    setUrls([]);
  };

  const [activeKBTab, setActiveKBTab] = useState("Website");
  const shouldRenderTabs = urls.length > 0 || selectedFiles.length > 0;

  const handleTabClick = (tab) => {
    setActiveKBTab(tab);
  };

  const handleKeyDown = (index, e) => {
    const input = e.target;

    if (e.key === "Backspace") {
      // If the cursor is at the beginning and not in the first input, move to the previous input
      if (input.selectionStart === 0 && index > 0) {
        inputRefs[index - 1].current.focus();
      }
    }
  };

  function convertKBtoMB(sizeInKB) {
    const sizeInMB = sizeInKB / 1024;
    return sizeInMB.toFixed(2);
  }

  const handleModalOpen = () => {
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const showNafiaData = async (url_for) => {
    // Fetch data from the API endpoint

    await fetch(url_for)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.status === "error") {
          alert(data.data);
        } else {
          setdataFromNafia(data); // Update state with fetched data
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    // Call the function to fetch data when the component mounts
    showNafiaData(URL_FOR_NAFIA_ADMIN_DASH);
  }, []);

  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  return (
    <div className="dashboard">
      <Helmet>
        <title>Nafia Dashboard</title>
        <meta
          name="description"
          content="AI and Global Reach: The Key to Scalable Solutions in Customer
          Experience"
        />
      </Helmet>
      <div className="dashboard_container">
        <div className="dashboard_wrapper">
          <NAfiaAdminSideBar />
          <div id="content">
            <CenterNavNafiaAuperAdmin />
            <div className="Dashpages_contaioner">
              <div className="overview">
                <div className="overviewContaINER">
                  <div className="px-0 my-4">
                    <div className="row m-0">
                      <div
                        className="col-xl-9 col-md-10"
                        style={{ paddingRight: "0" }}
                      >
                        <div className="knldgUpdateContainer mt-0">
                          <div className="col-xl-12 col-lg-12">
                            <div className="crwalingDiv">
                              <div className="URLSercher">
                                <div className="urlText">
                                  <p>website url:</p>
                                  <div className="webInputDiv">
                                    <img src={InterNet} alt="icon" />
                                    <input
                                      type="url"
                                      value={inputValue}
                                      onChange={(e) =>
                                        setInputValue(e.target.value)
                                      }
                                      placeholder="www.XYZCompany.in"
                                    ></input>
                                  </div>
                                </div>
                                <button
                                  className={`btnCrawler button_crown ${
                                    inputValue.trim() === ""
                                      ? "disabledbtnCrawler"
                                      : ""
                                  }`}
                                  // onClick={addUrl}
                                >
                                  <span>
                                    <img src={searchCrawler} alt="icon" />
                                  </span>
                                  <p>Crawl</p>
                                </button>
                              </div>
                              <div className="addFILEBtnDiv">
                                {" "}
                                <span className="postMvp">Post MVP</span>
                                <label
                                  htmlFor="add_file"
                                  className={`addFILEBtn`}
                                >
                                  Add File
                                </label>
                                <input
                                  id="add_file"
                                  multiple
                                  onChange={handleFileSelect}
                                  type="file"
                                  hidden
                                />
                              </div>
                            </div>
                            {shouldRenderTabs && (
                              <div className=" urlAndFileTabs">
                                <div className="urlAndFileTabsContainer">
                                  {urls.length > 0 && (
                                    <div
                                      className={`urlTab ${
                                        activeKBTab === "Website"
                                          ? "activeKBTab"
                                          : ""
                                      }`}
                                      onClick={() => handleTabClick("Website")}
                                    >
                                      <p>Website KB</p>
                                    </div>
                                  )}
                                  {selectedFiles.length > 0 && (
                                    <div
                                      className={`urlTab ${
                                        activeKBTab === "File"
                                          ? "activeKBTab"
                                          : ""
                                      }`}
                                      onClick={() => handleTabClick("File")}
                                    >
                                      <p>File KB</p>
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}

                            {urls.length == 0 && selectedFiles.length == 0 && (
                              <div className="nodataInput">
                                <h6 style={{ color: "#DA0E0E" }}>
                                  Input company URL or Add Files to update
                                  knowledge.
                                </h6>
                              </div>
                            )}
                            {/* URL Crwal Design */}
                            {urls.length > 0 && activeKBTab === "Website" && (
                              <>
                                <div className="crwalTableWrapper">
                                  <div className="crwalTableContainer">
                                    <div className="crwalTable addFileTableContainer2">
                                      <div className="moving-color"></div>
                                      <table>
                                        <thead>
                                          <tr>
                                            <th>
                                              <div className="firstCol"></div>
                                            </th>
                                            <th>
                                              <div>
                                                <p>PAGE URL</p>
                                              </div>
                                            </th>
                                            <th>
                                              <div>
                                                <p>STATUS</p>
                                              </div>
                                            </th>
                                            <th>
                                              <div>
                                                <p>CHARACTER</p>
                                              </div>
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {urls.map((url, index) => (
                                            <tr key={index}>
                                              <td>
                                                {url.status == "200" ? (
                                                  <div className="tdFIrstCol">
                                                    <input
                                                      type="checkbox"
                                                      checked={checkedBoxes.includes(
                                                        index
                                                      )}
                                                      onChange={() =>
                                                        CheckUncheckBox(index)
                                                      }
                                                    />
                                                  </div>
                                                ) : (
                                                  <input
                                                    type="checkbox"
                                                    disabled
                                                  />
                                                )}
                                              </td>
                                              <td>
                                                <div>
                                                  <p>{url.url}</p>
                                                </div>
                                              </td>

                                              <td>
                                                {url.status == "200" ? (
                                                  <div className="processed">
                                                    <p className="statusSuccess">
                                                      200
                                                    </p>
                                                    {/* <p className="statusError">500 Error</p> */}
                                                  </div>
                                                ) : (
                                                  <div className="notprocessed">
                                                    <p>500</p>
                                                  </div>
                                                )}
                                              </td>
                                              <td>
                                                <div>
                                                  <p
                                                    style={{ color: "#868e96" }}
                                                  >
                                                    {url.charctr}
                                                  </p>
                                                </div>
                                              </td>
                                            </tr>
                                          ))}
                                        </tbody>
                                      </table>
                                    </div>
                                    <div
                                      id="bottom-section"
                                      className="urlFOundtxt"
                                    >
                                      <p>
                                        <span id="totalUrls">{page_found}</span>{" "}
                                        PAGES FOUND
                                      </p>
                                      <p>
                                        <span id="text1">{page_crawled}</span>{" "}
                                        PAGES CRAWLED
                                      </p>
                                      <p>
                                        <span id="text2">
                                          {url_characters_found}
                                        </span>{" "}
                                        CHARACTER
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="dataSource">
                                  <button
                                    className="RmvDataSource"
                                    onClick={handleDltUrls}
                                  >
                                    <div className="dltimg">
                                      <img src={Dltimg} />
                                    </div>
                                    <div
                                      className="DStxt remove_btn"
                                      onClick={handleModalOpen}
                                    >
                                      <p>Remove this Data Source</p>
                                    </div>
                                  </button>
                                </div>

                                <div className="TTLcharacter">
                                  <div className="TTLtxt">
                                    <p>
                                      Total Characters: {totalCharacterCount}
                                    </p>
                                  </div>
                                  <div
                                    className="UPDationbtn update_knowledge_btn save_chatboat"
                                    // onClick={save_chatboat}
                                  >
                                    <button>
                                      <h5>Update Knowledge</h5>
                                    </button>
                                  </div>
                                </div>
                              </>
                            )}

                            {/* Added File Design */}
                            {selectedFiles.length > 0 &&
                              activeKBTab === "File" && (
                                <div className="addFileTableWrapper">
                                  <div className="addFileTableContainer">
                                    <div className="col-11 mx-auto">
                                      <div className="addFileTableHead">
                                        <div className="addFileTableHeadLeft">
                                          <img src={FileIcon} alt="icon" />
                                          <h4>File</h4>
                                        </div>
                                        <div className="addFileTableHeadRight">
                                          <h5>KB Name</h5>
                                          <input type="text" />
                                          <select
                                            style={{ cursor: "pointer" }}
                                            name="category"
                                            id="category"
                                          >
                                            <option value="">Category</option>
                                            <option value="category1">
                                              Category 1
                                            </option>
                                            <option value="category2">
                                              Category 2
                                            </option>
                                            <option value="category3">
                                              Category 3
                                            </option>
                                          </select>
                                        </div>
                                      </div>
                                      <div className="addFileTable">
                                        <div className="addFileTableContainer2">
                                          <table className="scrollableBody">
                                            <thead>
                                              <tr>
                                                <th>
                                                  <div className="filefirstCol">
                                                    <p>NAME</p>
                                                  </div>
                                                </th>
                                                <th>
                                                  <div>
                                                    <p>SIZE</p>
                                                  </div>
                                                </th>
                                                <th>
                                                  <div>
                                                    <p>CHARACTERS</p>
                                                  </div>
                                                </th>
                                                <th>
                                                  <div className="filelastCol">
                                                    <p>STATUS</p>
                                                  </div>
                                                </th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {selectedFiles.map(
                                                (file, index) => (
                                                  <tr key={index}>
                                                    <td>
                                                      <div>
                                                        <p>{file.name}</p>
                                                      </div>
                                                    </td>
                                                    <td>
                                                      <div>
                                                        <p>
                                                          {convertKBtoMB(
                                                            file.size
                                                          )}{" "}
                                                          MB
                                                        </p>
                                                      </div>
                                                    </td>
                                                    <td>
                                                      <div>
                                                        <p>1837</p>
                                                      </div>
                                                    </td>
                                                    <td>
                                                      <div>
                                                        <p className="processeDone">
                                                          Processed
                                                        </p>
                                                      </div>
                                                    </td>
                                                  </tr>
                                                )
                                              )}
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>

                                      <div className="updateByFile">
                                        <div className="updateByFileContainer">
                                          <form>
                                            <div className="updatebyContainer">
                                              <div className="updateByInput">
                                                <h6>Updated By</h6>
                                                <input type="text" />
                                              </div>
                                              <div className="updateByphone">
                                                <div className="updateByphoneinput">
                                                  <h6>Phone No.</h6>
                                                  <div className="updateByphoneinputContainr">
                                                    <PhoneInput
                                                      id="phone"
                                                      name="phone"
                                                      defaultCountry="ae"
                                                      value={userNumber}
                                                      onChange={(phone) =>
                                                        setuserNumber(phone)
                                                      }
                                                    />
                                                    <p>Get OTP</p>
                                                  </div>
                                                </div>
                                                <div className="getOTP">
                                                  {[0, 1, 2, 3].map((index) => (
                                                    <input
                                                      key={index}
                                                      ref={inputRefs[index]}
                                                      type="text"
                                                      maxLength="1"
                                                      pattern="\d"
                                                      inputMode="numeric"
                                                      required
                                                      onChange={(e) =>
                                                        handleInputChange(
                                                          index,
                                                          e
                                                        )
                                                      }
                                                      onKeyDown={(e) =>
                                                        handleKeyDown(index, e)
                                                      }
                                                    />
                                                  ))}
                                                </div>
                                              </div>
                                            </div>
                                          </form>
                                        </div>
                                        <div className="updateBtns">
                                          <div className="updateBtnsContaienr">
                                            <div className="updateBtnsLeft">
                                              <h5 style={{ color: "#DA0E0E" }}>
                                                File: 02
                                              </h5>
                                              <h5>Total Characters: 495</h5>
                                              <button
                                                className="RmvDataSource p-0"
                                                onClick={handleDltFiles}
                                              >
                                                <div className="dltimg">
                                                  <img src={Dltimg} />
                                                </div>
                                                <div className="DStxt">
                                                  <p>Remove this Data Source</p>
                                                </div>
                                              </button>
                                            </div>
                                            <div className="updateBtnsRight">
                                              <div className="UPDationbtn">
                                                <button>
                                                  <h5>Update Knowledge</h5>
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-3 col-md-2">
                        <div className="kbmngmtCardsDiv m-0">
                          <div className="kbmngmtCard mb-2">
                            <div className="card p-2 px-3 mx-4 mx-lg-0 mx-md-auto">
                              <p>
                                Knowledge Base <br /> Under Updation{" "}
                                <span className="postMvp">Post MVP</span>
                              </p>
                              <h4>{Nafiaadmindata.kb_under_updation}</h4>
                            </div>
                          </div>
                          <div className="kbmngmtCard mb-2">
                            <div className="card p-2 px-3 mx-4 mx-lg-0 mx-md-auto">
                              <p>
                                Knowledge Base <br /> Tickets
                              </p>
                              <h4>{Nafiaadmindata.kb_tickets}</h4>
                            </div>
                          </div>
                          <div className="kbmngmtCard mb-2">
                            <div className="card p-2 px-3 mx-4 mx-lg-0 mx-md-auto">
                              <p>Total Libraries</p>
                              <h4>{Nafiaadmindata.total_libraries}</h4>
                            </div>
                          </div>
                          <div className="kbmngmtCard mb-2">
                            <div className="card p-2 px-3 mx-4 mx-lg-0 mx-md-auto">
                              <p>
                                Total Scripts{" "}
                                <span className="postMvp">Post MVP</span>
                              </p>
                              <h4>{Nafiaadmindata.total_script}</h4>
                            </div>
                          </div>
                          <div className="kbmngmtCard mb-2">
                            <div className="card p-2 px-3 mx-4 mx-lg-0 mx-md-auto">
                              <p>Flow Tickets</p>
                              <h4>{Nafiaadmindata.flow_tickets}</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="nafiaADminDataTable">
                    <div className="nafiaADminDataTableHead">
                      <h5>Customer Session Records</h5>
                      <div className="adminoverviewSearch">
                        <img src={SearchImg} alt="icon" />
                        <input type="text" placeholder="Customer Name/ID" />
                      </div>
                    </div>
                    <div className="nafiaTAbleCOntainer">
                      <table className="table nafiaTAble">
                        <thead>
                          <tr>
                            <th scope="col">ID</th>
                            <th scope="col">Customer Name</th>
                            <th scope="col">Total KBS</th>
                            <th scope="col">Total Interactions</th>
                            <th scope="col">Total Duration</th>
                            <th scope="col">Last Ticket</th>
                            <th scope="col">Subscription Expiry</th>
                            <th scope="col">
                              History <span className="postMvp">Post MVP</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {companyListData.map((company, idx) => {
                            return (
                              <tr key={idx}>
                                <td>
                                  <div className="tableData1 tabledata1radius">
                                    <p># {company.id}</p>
                                  </div>
                                </td>
                                <td>
                                  <div className="tableData1">
                                    <p>{company.name}</p>
                                  </div>
                                </td>
                                <td>
                                  <div className="tableData1">
                                    <p>{company.total_kbs}</p>
                                  </div>
                                </td>
                                <td>
                                  <div className="tableData2">
                                    <p>{company.total_interactions}</p>
                                  </div>
                                </td>
                                <td>
                                  <div className="tableData1">
                                    {company.total_duration_sec < 1800 && (
                                      <p>{company.total_duration_sec} sec.</p>
                                    )}
                                    {company.total_duration_sec >= 1800 &&
                                      company.total_duration_sec < 10800 && (
                                        <p>
                                          {company.total_duration_min} mins.
                                        </p>
                                      )}
                                    {company.total_duration_sec >= 10800 && (
                                      <p>{company.total_duration_hrs} hrs.</p>
                                    )}
                                  </div>
                                </td>
                                <td>
                                  <div className="tableData1">
                                    <p>
                                      #{company.last_ticket_no.substring(0, 7)}
                                      ... <br />
                                      {company.last_ticket_date}
                                    </p>
                                  </div>
                                </td>
                                <td>
                                  <div className="tableData3">
                                    <p>{company.plan_expiry_date}</p>
                                  </div>
                                </td>
                                <td>
                                  <div className="tableData1 tableData4">
                                    <img
                                      style={{ cursor: "pointer" }}
                                      src={DownloadingUp}
                                      alt="icon"
                                    />
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div className="reportPieChart">
                    <div className="reportPieChartcontainer"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        dialogClassName="custom-modal3"
        centered
        size="xl"
      >
        <Modal.Header>
          <h5 className="mt-3">Add Customer</h5>
        </Modal.Header>
        <Modal.Body>
          <div className="mngUserContainer px-5">
            <form className="needs-validation" id="CarrerformData">
              <div className="row m-0">
                <div className="col-md-6 hidden">
                  <div className="mngUserInput">
                    <label>Admin Id</label>
                    <br />
                    <input
                      type="text"
                      className="form-control  trio_mendate"
                      name="main_id"
                      id="main_id"
                      maxLength={150}
                      placeholder="Enter Admin Id"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mngUserInput">
                    <label>Full Name</label>
                    <br />
                    <input
                      type="text"
                      className="form-control  trio_mendate"
                      name="full_name"
                      id="full_name"
                      maxLength={150}
                      onInput={handleAphabetsChange}
                      placeholder="Enter Full Name"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mngUserInput">
                    <label>User Name</label>
                    <br />
                    <input
                      type="text"
                      className="form-control  trio_mendate trio_email"
                      name="user_name"
                      id="user_name"
                      maxLength={150}
                      onInput={handleEmailChange}
                      placeholder="Enter User Name"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mngUserInput">
                    <label>Mobile No.</label>
                    <br />
                    <input
                      type="text"
                      className="form-control  trio_mendate trio_no"
                      name="user_mobile_no"
                      id="user_mobile_no"
                      maxLength={12}
                      onInput={handleNumbersChange}
                      placeholder="Enter Mobile No"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mngUserInput">
                    <label>Password</label>
                    <br />
                    <input
                      type="password"
                      className="form-control  trio_mendate trio_password"
                      name="user_password"
                      id="user_password"
                      onInput={handleIaphabetnumberChange}
                      maxLength={15}
                      placeholder="Enter Password"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mngUserInput">
                    <label>Confirm Password</label>
                    <br />
                    <input
                      type="password"
                      className="form-control  trio_mendate trio_password"
                      name="confirm_password"
                      id="confirm_password"
                      onInput={handleIaphabetnumberChange}
                      maxLength={15}
                      placeholder="Enter Confirm Password"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mngUserInput">
                    <label>Control</label>
                    <br />

                    <select
                      className="form-control trio_mendate controlSlct "
                      name="control_access"
                      id="control_access"
                    >
                      <option>Agent 1</option>
                      <option>Agent 2</option>
                      <option>Agent 3</option>
                    </select>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="updateCnclebtnDiv">
            <button className="updateCnclebtnCan" onClick={handleCloseModal}>
              Cancel
            </button>
            <button
              className="updateCnclebtnUp"
              type="button"
              onClick={handleCloseModal}
            >
              Add
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default KBManagementNafia;
