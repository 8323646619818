import React from "react";
import "../Css/test1.css";
import "../CommonJquery/aniamtion";

function LoadrerTechnoTask() {
  const loadingStyles = {
    span: {
      animation: "loading01 1.4s infinite alternate",
    },
  };

  for (let i = 0; i < 8; i++) {
    loadingStyles[`span:nth-child(${i + 1})`] = {
      animationDelay: `${i * 0.1}s`,
    };
  }

  return (
    <div className="loader_container01">
      <section>
        <h2>&nbsp;</h2>
        <div className="loading loading01" style={loadingStyles}>
          <span style={{ color: "#F29CF6" }}>T</span>&nbsp;
          <span style={{ color: "#7E32CB" }}>R</span>&nbsp;
          <span style={{ color: "#54C0EB" }}>I</span>&nbsp;
          <span style={{ color: "#AFD371" }}>O</span>&nbsp;
          <span style={{ color: "#F8E75F" }}>S</span>&nbsp;
          <span style={{ color: "#EA7B5B" }}>O</span>&nbsp;
          <span style={{ color: "#CF6269" }}>F</span>&nbsp;
          <span style={{ color: "#AFD371" }}>T</span>
        </div>
        <div className="text34">
          <h4>
            <span className="words-wrapper">
              <p className="is-visible">Setting up Stuff...</p>
              <p>Loading things...</p>
              <p>Refreshing objects...</p>
            </span>
          </h4>
        </div>
      </section>
    </div>
  );
}

export default LoadrerTechnoTask;
