import React, { useState } from "react";
import "../Css/SideBar.css";
import Dashboardwhite from "../../assets/images/dashboard.svg";
import DashboardBlack from "../../assets/images/dashboard12.svg";
import ChatIcon1 from "../../assets/images/chatIconBlack.png";
import ChatIcon2 from "../../assets/images/chatIconWhite.png";
import eyeBlack from "../../assets/images/eye.svg";
import eyewhite from "../../assets/images/Viewwhite.svg";
import report from "../../assets/images/report.svg";
import reportWhite from "../../assets/images/reportwhite.svg";
import file from "../../assets/images/file.svg";
import fileWhite from "../../assets/images/filewhite.svg";
import Logo from "../../assets/images/logoNew.png";
import customerWhite from "../../assets/images/customerWHite.png";
import Customer from "../../assets/images/customer.png";
import { Link, useLocation } from "react-router-dom";

function SideBar2() {
  const location = useLocation();

  return (
    <div className="sidebar">
      <div className="sidebar_container">
        <nav id="sidebar">
          <div className="sidebar-header sideBarHead">
            <div className="new_chat_btn new_chat_btn1">
              <img src={Logo} alt="Logo" />
            </div>
            
          </div>
          <div className="sidebar-body">
            <div className="sidebar-body-container">
              <div className="mainMenu mt-3">
                <div className="mainMenuContainer mainMenuContainer1">
                  <ul>
                    <li>
                      <Link to="/Dashboard">
                        <div
                          className={`mainMenuItem  ${
                            location.pathname === "/Dashboard"
                              ? "activemainMenuItem"
                              : ""
                          }`}
                        >
                          <img
                            src={
                              location.pathname === "/Dashboard"
                                ? Dashboardwhite
                                : DashboardBlack
                            }
                            alt="icon"
                          />
                          <p>Overview</p>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to="/Customers">
                        <div
                          className={`mainMenuItem ${
                            location.pathname === "/Customers"
                              ? "activemainMenuItem"
                              : ""
                          }`}
                        >
                          <img
                            src={
                              location.pathname === "/Customers"
                                ? customerWhite
                                : Customer
                            }
                            alt="icon"
                          />
                          <p>Customers</p>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to="/Amra_conversations">
                        <div
                          className={`mainMenuItem ${
                            location.pathname === "/Amra_conversations"
                              ? "activemainMenuItem"
                              : ""
                          }`}
                        >
                          <img
                            src={
                              location.pathname === "/Amra_conversations"
                                ? ChatIcon2
                                : ChatIcon1
                            }
                            alt="icon"
                          />
                          <p>Amra Conversations</p>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to="/Nafia_conversations">
                        <div
                          className={`mainMenuItem ${
                            location.pathname === "/Nafia_conversations"
                              ? "activemainMenuItem"
                              : ""
                          }`}
                        >
                          <img
                            src={
                              location.pathname === "/Nafia_conversations"
                                ? ChatIcon2
                                : ChatIcon1
                            }
                            alt="icon"
                          />
                          <p>Nafia Conversations</p>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to="/Career_applications">
                        <div
                          className={`mainMenuItem ${
                            location.pathname === "/Career_applications"
                              ? "activemainMenuItem"
                              : ""
                          }`}
                        >
                          <img
                            src={
                              location.pathname === "/Career_applications"
                                ? eyewhite
                                : eyeBlack
                            }
                            alt="icon"
                          />
                          <p>Career Applications</p>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to="/Booked_demo">
                        <div
                          className={`mainMenuItem ${
                            location.pathname === "/Booked_demo"
                              ? "activemainMenuItem"
                              : ""
                          }`}
                        >
                          <img
                            src={
                              location.pathname === "/Booked_demo"
                                ? reportWhite
                                : report
                            }
                            alt="icon"
                          />
                          <p>Booked Demo</p>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to="/Subscribers">
                        <div
                          className={`mainMenuItem ${
                            location.pathname === "/Subscribers"
                              ? "activemainMenuItem"
                              : ""
                          }`}
                        >
                          <img
                            src={
                              location.pathname === "/Subscribers"
                                ? fileWhite
                                : file
                            }
                            alt="icon"
                          />
                          <p>News Letter</p>
                        </div>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
}

export default SideBar2;
