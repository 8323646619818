import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import CenterNavAmraAdmin from "./CenterNavAmraAdmin";
import NAfiaAdminSideBar from "./AmraAdminSideBar";
import $ from "jquery";
import { Modal } from "react-bootstrap";
import { Dropdown } from "primereact/dropdown";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, registerables } from "chart.js";
import axios from "axios";
import searchIcon from "../../assets/images/searchIcon.svg";
import UsersImg from "../../assets/images/Money.png";
import Chart1 from "../../assets/images/Chart12.svg";
import neeewImg from "../../assets/images/neBoarding.svg";
import ugentIcon from "../../assets/images/urgentT.svg";
import moneyBagImg from "../../assets/images/MoneyBag.svg";
import CallImg from "../../assets/images/totalCalsssss.svg";
import ListGroup from "react-bootstrap/ListGroup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Calender from "../../assets/images/calendar12.svg";
import moment from "moment";
import "moment/locale/en-au";
import {
  server_post_data_shubham,
  AMRA_CUSTOMERS_DATA,
  AMRA_DEBTOR_DATA_AGGREGATE,
  AMRA_DEBTOR_DATA_DETAILS,
  server_post_data,
  AgentList,
  city_list,
} from "../../KeyConnection/KeysService";
import {
  check_vaild_save,
  combiled_form_data,
  empty_form,
  handleAphabetsChange,
  handleEmailChange,
  handleIaphabetnumberChange,
  handleNumbersChange,
  handleConfimDeleteClick,
} from "../../CommonJquery/CommonJquery.js";

function AmraAdminCustomers() {
  const [newcustomerName, setnewcustomerName] = useState("");
  const [userName, setuserName] = useState("");
  const [password, setpassword] = useState("");
  const [mobileNumber, setmobileNumber] = useState("");
  const [email, setemail] = useState("");
  const [userCountry, setuserCountry] = useState("");
  const [commission, setcommission] = useState("");
  const [countries, setCountries] = useState([]);
  const [control, setControl] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);

  const controlOptions = [
    { name: "Basic", code: "1" },
    { name: "Standard", code: "2" },
    { name: "Premium", code: "3" },
  ];
  const [selectedCheckboxes, setSelectedCheckboxes] = useState({
    "30 days": true,
    "60 days": true,
    "90 days": true,
    "pre-due": true,
  });

  const [onBoardDate, setonBoardDate] = useState(new Date());
  const [renewalDate, setrenewalDate] = useState(new Date());
  const [dynaicimage, setDynaicimage] = useState(null);
  useEffect(() => {
    // Fetch the list of all countries from the REST Countries API
  }, []);

  const handleControlChange = (value) => {
    setControl(value);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setSelectedImage(null);
    }
  };

  const [showModal1, setShowModal1] = useState(false);
  const handleShowModal1 = () => {
    setShowModal1(true);
  };

  const handleCloseModal1 = () => {
    setShowModal1(false);
  };

  const [showModal2, setShowModal2] = useState(false);
  const handleShowModal2 = () => {
    setShowModal2(true);
  };

  const handleCloseModal2 = () => {
    setShowModal2(false);
  };

  ChartJS.register(...registerables);
  const [graphData, setGraphData] = useState({
    labels: ["January", "February", "March", "April", "May", "June"],
    datasets: [
      {
        label: "Total Collection",
        data: [30, 38, 10, 15, 43, 10, 27],
        borderColor: "rgb(75, 192, 192)",
        tension: 0.37,
        pointRadius: 2,
        fill: false,
      },
      {
        label: "Total Commission",
        data: [40, 48, 8, 43, 10, 47, 30],
        borderColor: "rgb(255, 99, 132)",
        tension: 0.37,
        pointRadius: 2,
        fill: false,
      },
    ],
  });

  const options = {
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
      },
    },
  };

  const handleCheckboxChange = (checkboxId) => {
    setSelectedCheckboxes((prevSelected) => ({
      ...prevSelected,
      [checkboxId]: !prevSelected[checkboxId],
    }));
  };

  const [customerDetails, setCustomerDetails] = useState({
    customer_id: 1234,
    customer_name: "XYZ",
    user_count: 3,
    city: "Dubai",
    license_type: 2,
    onboarding_date: "",
    total_commission: 123,
    total_collection: 1234,
    total_calls: 567,
    server_cost: 234,
    user_name: localStorage.getItem("user_name") || "",
    debtor_details: {
      pre_due_amount: 0,
      day_30_amount: 0,
      day_60_amount: 0,
      day_90_amount: 0,
      escalation_amount: 0,
      dispute_amount: 0,
    },
    filteredTickets: [
      {
        status: "On-going", //high, medium, low
        ticket_no: "acvb",
        date: "04/12/2023",
        time: "12:30 PM",
      },
    ],
  });

  const [AmraadminData, setdataFromAmra] = useState([
    {
      company_name: "",
      customer: "", // to be decided
      customer_id: ``,
      plan: "", // to be decided
      license: "",
      avg_call_Dur: "", // to be decided
      boarding_date: "",
      comission: 0,
      total_collection: 0,
      bucket_30: 0,
      bucket_60: 0,
      bucket_90: 0,
      l2_esc: 0,
    },
  ]);

  const fetchAmraAdminDashboardData = async (dateRange) => {
    try {
      setshowLoaderAdmin(true);
      const response1 = await fetch(AMRA_CUSTOMERS_DATA, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const list = await response1.json();
      setshowLoaderAdmin(false);
      const companiesList = list.customer_list;
      const citysList = list.city_list;
      setCountries(citysList);
      let arr = [];
      let subscriptionPlanArr = ["Basic", "Standard", "Premium"];
      companiesList.forEach(async (company) => {
        let onboarding_date = new Date(company.created_at);

        let obj = {
          company_name: company.company_name,
          customer: "", // to be decided
          customer_id: `#${company.id}`,
          plan: subscriptionPlanArr[company.subscription_plan - 1], // to be decided
          license: 0,
          avg_call_Dur: 0, // to be decided
          boarding_date: onboarding_date.toLocaleDateString("en-GB"),
          comission: 0,
          total_collection: 0,
          bucket_30: 0,
          bucket_60: 0,
          bucket_90: 0,
          l2_esc: 0,
        };

        console.log(company);
        let url_debtor_detail = `${AMRA_DEBTOR_DATA_DETAILS}${company.mobile_no}/`;
        const response1 = await fetch(url_debtor_detail, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });

        const data = await response1.json();
        const debtors = data.debtors;

        debtors.forEach((debtor) => {
          let now = Date.now();
          let dueDate = new Date(debtor.due_date);
          if (debtor.last_payment_date) {
            dueDate = new Date(debtor.last_payment_date);
          }
          let days = Math.ceil((now - dueDate) / (1000 * 60 * 60 * 24));
          let bucket = "";
          if (days > 0 && days <= 30) {
            bucket = "bucket_30";
          } else if (days > 30 && days <= 60) {
            bucket = "bucket_60";
          } else if (days > 60) {
            bucket = "bucket_90";
          }
          debtor.debtor_history.forEach((instance) => {
            obj.total_collection += Number(instance.recovery_amount);
            obj.comission += Number(instance.admin_commission);
            if (instance.call_flag === 6) {
              obj.l2_esc += 1;
            }
            obj[bucket] += 1;
          });
        });
        let url_agent_detail = AgentList(company.id);
        const response2 = await fetch(url_agent_detail, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        const agent = await response2.json();

        obj.license = agent.data.length;
        arr.push(obj);
        setdataFromAmra([...arr]);
      });
      let startDate = dateRange.startDate || "2023-01-01";
      let endDate = dateRange.endDate || "2023-12-28";
      let url_debtor_aggregate = `${AMRA_DEBTOR_DATA_AGGREGATE}${startDate}/${endDate}/0/`;
      const response2 = await fetch(url_debtor_aggregate, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response2.json();

      const months = [];
      const totalCollection = [];
      const totalCommission = [];

      data.datewise_data.forEach((item) => {
        const abbreviatedMonth = item.month.substring(0, 3);
        months.push(abbreviatedMonth);
        totalCollection.push(item.total_collection);
        totalCommission.push(item.total_commission);
      });

      setGraphData({
        labels: months,
        datasets: [
          {
            label: "Total Collection",
            data: totalCollection,
            borderColor: "rgb(75, 192, 192)",
            tension: 0.37,
            pointRadius: 2,
            fill: false,
          },
          {
            label: "Total Commission",
            data: totalCommission,
            borderColor: "rgb(255, 99, 132)",
            tension: 0.37,
            pointRadius: 2,
            fill: false,
          },
        ],
      });
    } catch (err) {
      console.log("Company not Found");
      setshowLoaderAdmin(false);
    }
  };
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);

  const handleSaveChangesdynamic = async (_, url_for_save) => {
    setshowLoaderAdmin(true);
    console.log(url_for_save);
    const formData = {
      company_data: {
        company_name: newcustomerName,
        email: email,
        mobile_no: mobileNumber,
        company_image: "selectedImage",
        active_plan_id: control.code,
        subscription_plan: control.code,
        city: userCountry.id,
        admin_commission: commission,
      },
      user_data: {
        name: userName,
        email: email,
        mobile_no: mobileNumber,
        password: password,
      },
    };
    console.log(formData);
    setshowLoaderAdmin(false);
    const jsonData = JSON.stringify(formData);

    const headers_data = {
      "Content-Type": "application/json",
    };

    const res = await fetch(url_for_save, {
      method: "POST",
      body: jsonData,
      headers: headers_data, // Remove the nesting here
    });
    console.log(res);

    if (res.status !== 201) {
      alert("Something went wrong");
    } else {
      setnewcustomerName("");
      setuserName("");
      setpassword("");
      setmobileNumber("");
      setemail("");
      setuserCountry("");
      setcommission("");
      setControl(null);
      setSelectedImage(null);
      handleCloseModal1();
      const startDate = moment().subtract(365, "days").startOf("day");
      const endDate = moment().add(2, "days").endOf("day");
      let dates = {
        startDate: startDate.format("YYYY-MM-DD").toString(),
        endDate: endDate.format("YYYY-MM-DD").toString(),
      };
      fetchAmraAdminDashboardData(dates);
    }

    //   .catch((error) => {
    //     setshowLoaderAdmin(false);
    //   });
  };

  const handleSaveChangesdynamic_old = async (form_data, url_for_save) => {
    let vaild_data = check_vaild_save(form_data);
    if (vaild_data) {
      setshowLoaderAdmin(true);
      let fd = combiled_form_data(form_data, dynaicimage);
      fd.append("company_data[company_name]", newcustomerName);
      fd.append("company_data[email]", email);
      fd.append("company_data[mobile_no]", mobileNumber);
      fd.append("company_data[company_image]", "selectedImage");
      fd.append("company_data[active_plan_id]", control.code);
      fd.append("company_data[subscription_plan]", control.code);
      fd.append("company_data[city]", userCountry.id);
      fd.append("company_data[admin_commission]", commission);

      // Append user_data
      fd.append("user_data[name]", userName);
      fd.append("user_data[email]", email);
      fd.append("user_data[mobile_no]", mobileNumber);
      fd.append("user_data[password]", password);
      await server_post_data_shubham(url_for_save, fd)
        .then((Response) => {
          console.log(Response.data.message);
          setshowLoaderAdmin(false);
          if (Response.data.error) {
            alert(Response.data.message);
          } else {
            setnewcustomerName("");
            setuserName("");
            setpassword("");
            setmobileNumber("");
            setemail("");
            setuserCountry("");
            setcommission("");
            setControl(null);
            setSelectedImage(null);
            handleCloseModal1();
            calldata();
          }
        })
        .catch((error) => {
          setshowLoaderAdmin(false);
        });
    }
  };

  const [customerName, setCustomerName] = useState("");
  const [mobileNo, setMobileNo] = useState("");

  const calldata = async () => {
    const startDate = moment().subtract(365, "days").startOf("day");
    const endDate = moment().add(2, "days").endOf("day");
    let dates = {
      startDate: startDate.format("YYYY-MM-DD").toString(),
      endDate: endDate.format("YYYY-MM-DD").toString(),
    };
    fetchAmraAdminDashboardData(dates);
  };

  const fetchCustomerData = async () => {
    try {
      setshowLoaderAdmin(true);
      let searchInput = customerName;
      if (customerName === "") {
        searchInput = mobileNo;
      }
      let url_debtor_detail = `${AMRA_DEBTOR_DATA_DETAILS}${searchInput}/`;
      const response1 = await fetch(url_debtor_detail, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response1.json();
      setshowLoaderAdmin(false);
      const companyDetail = data.company_details;
      const debtorInfo = data.debtors_info;
      const debtors = data.debtors;
      const tickets = data.tickets;

      const onBoardingDate = new Date(
        companyDetail.company_onboarding_date
      ).toLocaleDateString("en-GB");

      const response2 = await fetch(city_list, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data2 = await response2.json();
      let city_name = "";
      data2.data.forEach((city) => {
        if (city.id === Number(companyDetail.company_city)) {
          city_name = city.name;
        }
      });

      let subscriptionPlanArr = ["Basic", "Standard", "Premium"];

      const obj = {
        customer_id: companyDetail.company_id,
        customer_name: companyDetail.company_name,
        user_count: companyDetail.users_count,
        city: city_name === "" ? companyDetail.company_city : city_name,
        license_type: subscriptionPlanArr[companyDetail.company_plan_id - 1],
        onboarding_date: onBoardingDate,
        total_commission: debtorInfo.total_commission,
        total_collection: debtorInfo.total_collection,
        total_calls: debtorInfo.total_calls,
        server_cost: companyDetail.server_cost,
        user_name: companyDetail.company_email,
        debtor_details: {
          pre_due_amount: 0,
          day_30_amount: 0,
          day_60_amount: 0,
          day_90_amount: 0,
          escalation_amount: 0,
          dispute_amount: 0,
        },
        filteredTickets: [],
      };

      debtors.forEach((debtor) => {
        let now = Date.now();
        let dueDate = new Date(debtor.due_date);
        if (debtor.last_payment_date) {
          dueDate = new Date(debtor.last_payment_date);
        }
        let days = Math.ceil((now - dueDate) / (1000 * 60 * 60 * 24));
        let bucket = "";
        if (days <= 0) {
          bucket = "pre_due_amount";
        } else if (days > 0 && days <= 30) {
          bucket = "day_30_amount";
        } else if (days > 30 && days <= 60) {
          bucket = "day_60_amount";
        } else if (days > 60) {
          bucket = "day_90_amount";
        }

        debtor.debtor_history.forEach((instance) => {
          let outstanding_amount =
            Number(instance.debtor_emi_amt) - Number(instance.recovery_amount);

          obj.debtor_details[bucket] += outstanding_amount;

          if (instance.call_flag === 6) {
            obj.debtor_details.escalation_amount += Number(
              instance.debtor_emi_amt
            );
          }

          if (instance.call_flag === 9) {
            obj.debtor_details.dispute_amount += Number(
              instance.debtor_emi_amt
            );
          }
        });
      });

      const ticket_category = ["New", "On-going", "Resolved"];
      const timeFormatOptions = {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
        timeZone: "IST", // Adjust this based on your actual timezone
      };

      tickets.forEach((ticket) => {
        let tick_obj = {
          status: ticket_category[Number(ticket.ticket_category)], //high, medium, low
          ticket_no: ticket.uuid_id,
          date: new Date(ticket.created_at).toLocaleDateString("en-GB"),
          time: new Intl.DateTimeFormat("en-US", timeFormatOptions).format(
            new Date(ticket.created_at)
          ),
        };
        obj.filteredTickets.push(tick_obj);
      });
      // calculate bucket using due data
      setCustomerDetails(obj);
      handleShowModal2();
    } catch (err) {
      alert("Company not Found");
      setshowLoaderAdmin(false);
    }
  };

  useEffect(() => {
    calldata();
  }, []);

  useEffect(() => {
    // Initialize DataTable
    

    if (AmraadminData && AmraadminData.length > 0) {
      // Destroy the DataTable if it already exists
      if ($.fn.DataTable.isDataTable("#myTable")) {
        $("#myTable").DataTable().destroy();
      }
      // Initialize DataTable
      $("#myTable").DataTable();
    }
    // console.log(AmraadminData);
  }, []);

  const handleFileChangedynamic = (keyname) => (event) => {
    const file = event.target.files[0];

    let new_file_name = keyname + "_show";

    if (file) {
      const reader = new FileReader();

      reader.onload = () => {
        setDynaicimage((prevImages) => ({
          ...prevImages,
          [keyname]: file,
          [new_file_name]: reader.result,
        }));
      };
      reader.readAsDataURL(file);
    } else {
      setDynaicimage((prevImages) => ({
        ...prevImages,
        [keyname]: null,
        [new_file_name]: null,
      }));
    }
  };
  return (
    <div className="dashboard">
      <div
        className={showLoaderAdmin ? "loader-overlay show" : "loader-overlay"}
      >
        <div className="loader23"></div>
      </div>
      <Helmet>
        <title>Amra Dashboard</title>
        <meta
          name="description"
          content="AI and Global Reach: The Key to Scalable Solutions in Customer
          Experience"
        />
      </Helmet>
      <div className="dashboard_container">
        <div className="dashboard_wrapper">
          <NAfiaAdminSideBar />
          <div id="content">
            <CenterNavAmraAdmin />

            <div className="Dashpages_contaioner">
              <div className="overview mt-3">
                <div className="overviewContaINER">
                  <div className="revenueCall">
                    <div className="row m-0">
                      <div className="col-md-8 p-0  ">
                        <div className="searchAndDteFilteers">
                          <div className="row m-0">
                            <div
                              className="col-md-6"
                              style={{ paddingLeft: "0" }}
                            >
                              <div className="searchBarCustomer">
                                <input
                                  type="text"
                                  placeholder="Company Name /Client Name/ID"
                                />
                                <img src={searchIcon} alt="icon" />
                              </div>
                              <div className="mngUserInput m-0">
                                <Dropdown
                                  value={control}
                                  onChange={(e) => {
                                    handleControlChange(e.value);
                                  }}
                                  options={controlOptions}
                                  optionLabel="name"
                                  placeholder="License Type"
                                  className="controlSlct"
                                  style={{
                                    backgroundColor: "#ffffff",
                                    color: "#5c6066",
                                    fontWeight: "500",
                                  }}
                                />
                              </div>
                            </div>
                            <div
                              className="col-md-6"
                              style={{ paddingRight: "0" }}
                            >
                              <div className="datePickerOnboardRenewal">
                                <div className="PickerOnboardRenewal">
                                  <div className="revewalDatePstn">
                                    <p
                                      style={{
                                        fontSize: "13px",
                                        margin: "0",
                                        color: "#5C6066",
                                        fontWeight: "500",
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      Onboard Date
                                    </p>
                                  </div>
                                  <label htmlFor="renewalDateInput">
                                    <img src={Calender} alt="icon" />
                                  </label>
                                  <DatePicker
                                    id="renewalDateInput"
                                    selected={onBoardDate}
                                    onChange={(date) => setonBoardDate(date)}
                                  />
                                </div>
                                <div className="PickerOnboardRenewal">
                                  <div className="revewalDatePstn">
                                    <p
                                      style={{
                                        fontSize: "13px",
                                        margin: "0",
                                        color: "#5C6066",
                                        fontWeight: "500",
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      Renewal Date
                                    </p>
                                  </div>
                                  <label htmlFor="onBoardDateInput">
                                    <img src={Calender} alt="icon" />
                                  </label>
                                  <DatePicker
                                    id="onBoardDateInput"
                                    selected={renewalDate}
                                    onChange={(date) => setrenewalDate(date)}
                                  />
                                </div>
                              </div>
                              <div className="mngUserInput mb-0">
                                <Dropdown
                                  value={userCountry}
                                  onChange={(e) => setuserCountry(e.value)}
                                  options={countries}
                                  optionLabel="name"
                                  placeholder="Select Control"
                                  style={{
                                    height: "40px",
                                    color: "#5c6066",
                                    fontWeight: "500",
                                    outline: "none",
                                    boxShadow: "none",
                                    border: "none",
                                    backgroundColor: "#ffffff",
                                    borderRadius: "6px",
                                    padding: "0.5rem 1rem",
                                    width: "100%",
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="areaCHArtDiv">
                          <div className="chartHeading">
                            <div className="chartHeadingtext-left">
                              <h4>Growth & Revenue</h4>
                            </div>
                            <div className="chartHeadingtext-right">
                              {/* <div className="dateDropdownform">
                                <select>
                                  <option value=" ">Current Year</option>
                                  <option value="Last">Last Month</option>
                                  <option value="Month to Date">
                                    Month to Date
                                  </option>
                                  <option value="Last 3">Last 3 Months</option>
                                  <option value="Last 6">Last 6 Months</option>
                                  <option value="Year to Date">
                                    Year to Date
                                  </option>
                                  <option value="Lifetime">Lifetime</option>
                                </select>
                              </div> */}
                            </div>
                          </div>
                          <div className="p-3">
                            <Line data={graphData} options={options} />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="AddAgentBtn w-100 m-0">
                          <button
                            className="w-100"
                            style={{ height: "45px" }}
                            onClick={handleShowModal1}
                          >
                            Add Customer
                          </button>
                        </div>
                        <div className="searchClientContaienr">
                          <div
                            className="searchContaienr"
                            style={{ backgroundColor: "#8639CE" }}
                          >
                            <div className="text-center col-xl-10 m-auto">
                              <h5>Search Customer </h5>
                              <div className="searchInputsConta">
                                <input
                                  placeholder="Customer Name "
                                  type="text"
                                  value={customerName}
                                  onChange={(e) => {
                                    setMobileNo("");
                                    setCustomerName(e.target.value);
                                  }}
                                />
                                <p
                                  style={{
                                    fontWeight: "500",
                                    margin: "0.5rem 0",
                                  }}
                                >
                                  or
                                </p>
                                <input
                                  placeholder="Mobile No."
                                  type="text"
                                  value={mobileNo}
                                  onChange={(e) => {
                                    setCustomerName("");
                                    setMobileNo(e.target.value);
                                  }}
                                />
                              </div>
                              <div className="searchBtnADmin">
                                <button onClick={fetchCustomerData}>
                                  <img src={searchIcon} alt="Icon" />
                                  <p>Search </p>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="amradataTable">
                      <div className="amradataTable_container">
                        <h4></h4>
                        <table id="myTable" className="display">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Company/Customer Name</th>
                              <th>Avg. Call Duration</th>
                              <th>Date of Onboarding</th>
                              <th>Comission</th>
                              <th>Total Collection</th>
                              <th>Bucket Calls</th>
                              <th>L2 Escalation Calls</th>
                            </tr>
                          </thead>
                          <tbody>
                            {AmraadminData.map((item, index) => (
                              <tr key={index}>
                                <td>
                                  <p style={{ color: "#000" }}>{index + 1}</p>
                                </td>
                                <td>
                                  <p style={{ color: "#000" }}>
                                    {item.company_name}
                                    <br />
                                    {item.customer_id}&nbsp;
                                    <span className="planTetx">
                                      {item.plan}
                                    </span>
                                    &nbsp;
                                    <span style={{ fontSize: "10px" }}>
                                      {item.license} Licence
                                    </span>
                                  </p>
                                </td>
                                <td>
                                  <p style={{ color: "#000" }}>
                                    {item.avg_call_Dur}
                                  </p>
                                </td>
                                <td>
                                  <p style={{ color: "#000" }}>
                                    {item.boarding_date}
                                  </p>
                                </td>
                                <td style={{ color: "#000" }}>
                                  ${item.comission}
                                </td>
                                <td>
                                  <p style={{ color: "#000" }}>
                                    ${item.total_collection}
                                  </p>
                                </td>
                                <td>
                                  <p style={{ color: "#000" }}>
                                    <span style={{ whiteSpace: "nowrap" }}>
                                      30 Days: {item.bucket_30}
                                    </span>
                                    <br />
                                    <span style={{ whiteSpace: "nowrap" }}>
                                      60 Days: {item.bucket_60}
                                    </span>
                                    <br />
                                    <span style={{ whiteSpace: "nowrap" }}>
                                      90 Days: {item.bucket_90}
                                    </span>
                                  </p>
                                </td>
                                <td>
                                  <p style={{ color: "#000" }}>{item.l2_esc}</p>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showModal1}
        onHide={handleCloseModal1}
        dialogClassName="custom-modal2"
        centered
        size="xl"
      >
        <Modal.Header>
          <h5 className="mt-3">Add Customer</h5>
        </Modal.Header>
        <Modal.Body>
          <div className="mngUserContainer px-5">
            <form className="needs-validation" id="CarrerformData">
              <div className="row m-0">
                <div className="col-md-6">
                  <div className="mngUserInput">
                    <label>Company Name</label>
                    <br />
                    <input
                      type="text"
                      onChange={(e) => setnewcustomerName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mngUserInput">
                    <label>Upload Image</label>
                    <br />
                    <input
                      type="file"
                      className="form-control  trio_mandatory"
                      name="admin_image"
                      onChange={handleFileChangedynamic("admin_image")}
                      accept=".jpg,.jpeg,.png"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mngUserInput">
                    <label>Full Name</label>
                    <br />
                    <input
                      type="text"
                      className="form-control  trio_mandatory"
                      onChange={(e) => setuserName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mngUserInput">
                    <label>Email</label>
                    <br />
                    <input
                      type="Email"
                      placeholder="Email"
                      className="form-control  trio_mandatory"
                      onChange={(e) => setemail(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mngUserInput">
                    <label>Mobile No.</label>
                    <br />
                    <input
                      type="number"
                      className="form-control  trio_mandatory"
                      onChange={(e) => setmobileNumber(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mngUserInput">
                    <label>Password</label>
                    <br />
                    <input
                      type="password"
                      className="form-control  trio_mandatory"
                      onChange={(e) => setpassword(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mngUserInput">
                    <label>Location</label>
                    <br />
                    <Dropdown
                      value={userCountry}
                      onChange={(e) => setuserCountry(e.value)}
                      options={countries}
                      optionLabel="name"
                      placeholder="Select Location"
                      className="controlSlct"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mngUserInput">
                    <label>License Type</label>
                    <br />
                    <Dropdown
                      value={control}
                      onChange={(e) => handleControlChange(e.value)}
                      options={controlOptions}
                      optionLabel="name"
                      placeholder="Select License"
                      className="controlSlct"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mngUserInput">
                    <label>Total Commission</label>
                    <br />
                    <input
                      type="text"
                      className="form-control  trio_mandatory"
                      onChange={(e) => setcommission(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-xl-6 col-lg-10">
                  <div className="mngUserInput">
                    <label>Bucket</label>
                    <br />
                    <div
                      className="bucketButtonText"
                      style={{ flexWrap: "wrap" }}
                    >
                      {Object.keys(selectedCheckboxes).map((checkboxId) => (
                        <div key={checkboxId} className="bucketBtnsDiv">
                          <div style={{ position: "relative" }}>
                            <input
                              id={checkboxId}
                              type="checkbox"
                              hidden
                              checked={selectedCheckboxes[checkboxId]}
                              onChange={() => handleCheckboxChange(checkboxId)}
                            />
                            <label
                              htmlFor={checkboxId}
                              className="checkmark"
                            ></label>
                          </div>
                          <p>{`${checkboxId
                            .charAt(0)
                            .toUpperCase()}${checkboxId.slice(1)}`}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="updateCnclebtnDiv">
            <button className="updateCnclebtnCan" onClick={handleCloseModal1}>
              Cancel
            </button>
            <button
              className="updateCnclebtnUp"
              onClick={() =>
                handleSaveChangesdynamic("CarrerformData", AMRA_CUSTOMERS_DATA)
              }
            >
              Add
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      {/* second modal */}
      <Modal
        show={showModal2}
        onHide={handleCloseModal2}
        dialogClassName="custom-modal1"
        centered
        size="xl"
      >
        <Modal.Header>
          <h5 className="mt-3">Customer Details</h5>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              maxHeight: "550px",
              overflowY: "auto",
              padding: "0 1.5rem",
              backgroundColor: "#F0F0F0",
            }}
          >
            <section className="CIENTdETAILShEAD">
              <div className="clientHeadleft">
                <p>Customer Name : {customerDetails.customer_name}</p>
                <p>Email : {customerDetails.user_name}</p>
                <p>Customer Id : # {customerDetails.customer_id}</p>
              </div>
              <div className="clientHeadright">
                <p>User : {customerDetails.user_count}</p>
                <p>City : {customerDetails.city}</p>
                <p>Licence Type : {customerDetails.license_type}</p>
              </div>
            </section>

            <section className="clientDtataContainer">
              <div className="row m-0">
                <div className="col-md-8 my-3 p-0">
                  <div className="row m-0">
                    {/* -----------------Total Revenue----------------------- */}
                    <div className="col-md-4" style={{ paddingLeft: "0" }}>
                      <div className="customersCard">
                        <div className="customersCardLeftText">
                          <img src={UsersImg} alt="icon" />
                          <p>Total Collection</p>
                        </div>
                        <div className="customersCardLeft">
                          <div className="customersCardrighttext">
                            <h5 style={{ color: "#000", margin: "0" }}>
                              $ {customerDetails.total_collection}
                            </h5>
                          </div>
                          <div className="customersCardright">
                            <img
                              style={{ width: "2rem" }}
                              src={Chart1}
                              alt="chat"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4" style={{ paddingLeft: "0" }}>
                      <div className="customersCard">
                        <div className="customersCardLeftText">
                          <img src={ugentIcon} alt="icon" />
                          <p>Total Commission</p>
                        </div>
                        <div className="customersCardLeft">
                          <div className="customersCardrighttext">
                            <h5 style={{ color: "#000", margin: "0" }}>
                              $ {customerDetails.total_commission}
                            </h5>
                          </div>
                          <div className="customersCardright">
                            <img
                              style={{ width: "2rem" }}
                              src={neeewImg}
                              alt="chat"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-md-4"
                      style={{ paddingLeft: "0", paddingRight: "0" }}
                    >
                      <div className="serveandCallCost">
                        <div className="serverCostContainer mb-2">
                          <img src={moneyBagImg} className=" " alt="" />
                          <div className="serveandCallCostText">
                            <h1 className="fs-6 text-secondary">Server Cost</h1>
                            <p className=" fw-bold m-0 ">
                              $ {customerDetails.server_cost}
                            </p>
                          </div>
                        </div>
                        <div className="serverCostContainer">
                          <img src={CallImg} className="" alt="" />
                          <div className="serveandCallCostText">
                            <h1 className="fs-6 text-secondary ">
                              Total Calls
                            </h1>
                            <p className=" fw-bold m-0 ">
                              {customerDetails.total_calls}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-100 my-3">
                    <div className="callVSMonChart">
                      <div className="callVSMonChartHead">
                        <h5 className="mt-2" style={{ color: "#000" }}>
                          Growth & Revenue{" "}
                        </h5>
                      </div>
                      <Line data={graphData} options={options} />
                    </div>
                  </div>
                </div>
                {/* debtor_details: {
        pre_due_amount: 0,
        day_30_amount: 0,
        day_60_amount: 0,
        day_90_amount: 0,
        escalation_amount: 0,
        dispute_amount: 0,
      },
      filteredTickets: [
        {
          priority: "high", //high, medium, low
          ticket_no: "acvb",
          date: "04/12/2023",
          time: "12:30 PM",
        },
      ], */}

                {/* ----------------------------------------Details---------------------------------- */}
                <div className="col-md-4">
                  <div className="">
                    <div className="mt-3">
                      <ListGroup className="dfgsdfg">
                        <ListGroup.Item className="bg-black text-white">
                          Details
                        </ListGroup.Item>
                        <ListGroup.Item className="d-flex justify-content-between border-end-0 border-start-0">
                          <p>Pre-Schedule B</p>
                          <p>
                            $ {customerDetails?.debtor_details?.pre_due_amount}
                          </p>
                        </ListGroup.Item>
                        <ListGroup.Item className="d-flex justify-content-between border-end-0 border-start-0">
                          <p>30 Days B.</p>
                          <p>
                            $ {customerDetails?.debtor_details?.day_30_amount}
                          </p>
                        </ListGroup.Item>
                        <ListGroup.Item className="d-flex justify-content-between border-end-0 border-start-0">
                          <p>60 Days B.</p>
                          <p>
                            $ {customerDetails?.debtor_details?.day_60_amount}
                          </p>
                        </ListGroup.Item>
                        <ListGroup.Item className="d-flex justify-content-between border-end-0 border-start-0">
                          <p>90 Days B.</p>
                          <p>
                            $ {customerDetails?.debtor_details?.day_90_amount}
                          </p>
                        </ListGroup.Item>
                        <ListGroup.Item className="d-flex justify-content-between border-end-0 border-start-0">
                          <p>L2 Escallation</p>
                          <p>
                            ${" "}
                            {customerDetails?.debtor_details?.escalation_amount}
                          </p>
                        </ListGroup.Item>
                        <ListGroup.Item className="d-flex justify-content-between border-end-0 border-start-0 border-bottom-0">
                          <p>Dispute B.</p>
                          <p>
                            $ {customerDetails?.debtor_details?.dispute_amount}
                          </p>
                        </ListGroup.Item>
                      </ListGroup>
                    </div>

                    {/* ----------------------------------------All Tickets---------------------------------- */}
                    <div className="clientticketsAll">
                      <div className="clientticketsAllHead">
                        <h5>All Tickets</h5>
                        <button className="clientticketsAllBtn bg-transparent border-0">
                          See All
                        </button>
                      </div>

                      <div className="clientticketsAllList">
                        {customerDetails?.filteredTickets?.map((ticket) => {
                          return (
                            <div className="clientticketsListItem">
                              <div className="clientticketsListItemHead">
                                <h6>{ticket?.date}</h6>
                                <p title={ticket.ticket_no}>
                                  {ticket.ticket_no.slice(0, 10)}...
                                </p>
                              </div>
                              <div className="clientticketsListItemHead">
                                <p>Time: {ticket?.time}</p>
                                <p style={{ color: "#DC0D00" }}>
                                  Status: {ticket?.status}
                                </p>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    <div className="OtherDetailsBtns">
                      <button>
                        Commission History/Transaction{" "}
                        <span className="postMvp">Post MVP</span>{" "}
                      </button>
                      <div className="d-flex" style={{ gap: "1rem" }}>
                        <button>
                          Subscription <br /> Details <br />
                          <span className="postMvp">Post MVP</span>
                        </button>
                        <button>
                          Knowledge Base <br /> Details <br />
                          <span className="postMvp">Post MVP</span>
                        </button>
                      </div>
                      <div className="d-flex" style={{ gap: "1rem" }}>
                        <button>
                          Reports <br />
                          <span className="postMvp">Post MVP</span>
                        </button>
                        <button>
                          Mails <br />
                          <span className="postMvp">Post MVP</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="updateCnclebtnDiv">
            <button
              className="updateCnclebtnCan clientDataCanelBtns"
              onClick={handleCloseModal2}
            >
              Cancel
            </button>
            <button
              className="updateCnclebtnUp clientEditBytns"
              onClick={handleCloseModal2}
            >
              Edit Details <span className="postMvp">Post MVP</span>
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default AmraAdminCustomers;
