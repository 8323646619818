import React, { useState, useEffect } from "react";
import "../Css/Dashboard.css";
import "../Css/Bucket30days.css";
import "../Css/SettingPage.css";
import SideBar from "./SideBar";
import CircleCheck from "../../assets/images/circle_check.svg";
import NotificationsImg from "../../assets/images/notification.svg";
import SideBarControl from "../../assets/images/rightLeftSIdebar.png";
import UserImgPrf from "../../assets/images/userimg45.svg";
import adm_img from "../../assets/images/adm_img.png";
import "react-circular-progressbar/dist/styles.css";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import userBtn from "../../assets/images/usermngmt.svg";
import invBtn from "../../assets/images/invoicee.svg";
import invBtnWhite from "../../assets/images/invoiceeBlue.svg";
import subsButton from "../../assets/images/subscriptions.svg";
import RightaRROW from "../../assets/images/rightmorethan.svg";
import RightaRROWWhite from "../../assets/images/rightmorethanblue.svg";
import { Link, useLocation } from "react-router-dom";
import Logout from "../../assets/images/giflogout.gif";
import Technotask from "../../assets/images/TechnotaskLogo12.png";
import CenterNavbar from "./CenterNavbar";
import { Helmet } from "react-helmet";
import UserProfileCompleteSideBar from "./UserProfileCompleteSideBar";
import redirectvector from "../../assets/images/redirectvector.png";
import {
  server_post_data_shubham,
  get_all_amra_user_detail,
  save_update_amra_admin,
} from "../../KeyConnection/KeysService.js";
import {
  check_vaild_save,
  combiled_form_data,
  empty_form,
  handleAphabetsChange,
  handleEmailChange,
  handleIaphabetnumberChange,
  handleNumbersChange,
  handleConfimDeleteClick,
  dateformartmonth,
} from "../../CommonJquery/CommonJquery.js";
function DebtorProfile60() {
  const [overviewShow, setOverviewShow] = useState(true); // Default to 'true'
  const [debtorProfile, setdebtorProfile] = useState(false);
  const location = useLocation();
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [dataFromAmra, setdataFromAmra] = useState([]);
  const [dataFromAmraInvoice, setdataFromAmraInvoice] = useState([]);
  const [IconMoney, setIconMoney] = useState([]);
  const company_id = localStorage.getItem("company_id");

  useEffect(() => {
    const start_date = "";
    const end_date = "";
    const flag = "3";
    let call_id = "0";
    master_data_get(start_date, end_date, flag, call_id);
  }, [``]);

  const master_data_get = async (start_date, end_date, flag, call_id) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("admin_id", company_id);
    fd.append("start_date", start_date);
    fd.append("end_date", end_date);
    fd.append("flag", flag);
    fd.append("call_id", call_id);
    await server_post_data_shubham(get_all_amra_user_detail, fd)
      .then((Response) => {
        if (Response.data.error) {
          alert(Response.data.message);
        } else {
          console.log(Response.data.message.data_silder_image3);
          setdataFromAmra(Response.data.message.data_silder_image2[0]);
          setdataFromAmraInvoice(Response.data.message.data_silder_image3);
          setIconMoney(Response.data.message.icon_money);
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };

  return (
    <div className="dashboard">
      <Helmet>
        <title>Amra User Dashboard</title>
        <meta
          name="description"
          content="AI and Global Reach: The Key to Scalable Solutions in Customer
          Experience"
        />
      </Helmet>
      <div className="dashboard_container">
        <div className="dashboard_wrapper">
          <SideBar
            overviewShow={overviewShow}
            setOverviewShow={setOverviewShow}
            debtorProfile={debtorProfile}
            setdebtorProfile={setdebtorProfile}
          />
          <div id="content">
            <CenterNavbar />

            <div className="AmraConversations">
              <div className="AmraConversations_container">
                <div className="AmraConversations_container_wrapper">
                  <div className="overviewContaINER pb-0">
                    <div className="col-md-8 overviewHead overviewHeaddp mb-1">
                      <h4>Settings</h4>
                    </div>
                  </div>
                  <div className="subscript">
                    <div className="row m-0 justify-content-center">
                      <div className="col-lg-4 col-md-5 column1 pt-0">
                        <UserProfileCompleteSideBar />
                      </div>
                      <div className="col-xl-4 col-lg-5 col-md-5 column2">
                        <h5 className="mb-3">
                          <b>Invoice</b>
                        </h5>
                        <div className="billHistory">
                          <hp className="crntPlanHead">BILLING HISTORY</hp>
                          <hr
                            className="mt-1"
                            style={{ borderTop: "2px solid" }}
                          />

                          <table className="custom-table">
                            <tbody>
                              {dataFromAmraInvoice.map((blogddd, index) => (
                                <tr key={index}>
                                  <td>
                                    <div className="dateDivRow">
                                      <p>
                                        {dateformartmonth(blogddd.created_at)}
                                      </p>
                                      <img src={redirectvector} alt="Prop"/>
                                    </div>
                                  </td>
                                  <td>
                                    <div>
                                      <p>
                                        {IconMoney + blogddd.package_amount}
                                      </p>
                                    </div>
                                  </td>
                                  <td>
                                    <div>
                                      <p>{blogddd.package_name}</p>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="brandingContainer">
              <div>
                <h5>Powered by</h5>
                <img src={Technotask} alt="Logo" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DebtorProfile60;
