import React from "react";
import "../Css/Resources.css";
import ResrcImg from "../assets/images/resrcImg.png";
import Header from "./Header";
import Footer from "./Footer";
import blog1 from "../assets/images/blog1.png";
import blog2 from "../assets/images/blog2.png";
import blog3 from "../assets/images/blog3.png";
import blog4 from "../assets/images/blog4.png";
import blog5 from "../assets/images/debtBlogImg.png";
import blog6 from "../assets/images/blog6.png";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

function Resources() {
  return (
    <div className="resources">
      <Helmet>
        <title>Our Blogs</title>
        <meta
          name="description"
          content="Stay informed with the latest analytical insights and updates about Technotask, the dynamic world of generative AI and our digital people."
        />
      </Helmet>
      <header className="header_container">
        <Header />
      </header>
      <div className="resources_container container-lg px-0 my-5">
        <div className="rsrc_text">
          <h1>Resources</h1>
          <h5>
            Stay informed with the latest analytical insights and updates about
            Technotask, the dynamic world of generative AI and our digital
            people.
          </h5>
        </div>
        <div className="rsrcimage col-md-9 col-sm-9 col-10 mr-0">
          <img src={ResrcImg} alt="icon" />
        </div>
      </div>
      <div className="Input_section container-lg px-4">
        <div className="row">
          <div className="inputBlogHead col-md-2">
            <h2 className="Blog_Heading">Blog</h2>
          </div>
          <div className="col-md-10">
            <div className="inputHead">
              <p>Topics</p>
            </div>

            <div className="inputCheck1">
              <div>
                <div className="form-check">
                  <label className="form-check-label" htmlFor="flexCheckDefault">
                    Augmented Voice Intelligence
                  </label>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                  />
                </div>
                <div className="form-check">
                  <label className="form-check-label" htmlFor="flexCheckDefault">
                    Contact Center Automation
                  </label>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                  />
                </div>
              </div>
              <div>
                <div className="form-check">
                  <label className="form-check-label" htmlFor="flexCheckDefault">
                    Customer Experience
                  </label>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                  />
                </div>
                <div className="form-check">
                  <label className="form-check-label" htmlFor="flexCheckDefault">
                    Voice AI for Business Growth
                  </label>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                  />
                </div>
              </div>
              <div>
                <div className="form-check">
                  <label className="form-check-label" htmlFor="flexCheckDefault">
                    Technology
                  </label>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                  />
                </div>
                <div className="form-check">
                  <label className="form-check-label" htmlFor="flexCheckDefault">
                    Agent Experience
                  </label>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="blog">
        <div className="blog_section container-lg">
          <h4 className="col-md-8 col-11 mx-auto">
            AI Innovations are Catalyzing a Communicational Revolution
          </h4>
          <div className="card_container">
            <div className="row">
              <div className=" col-md-4 col-sm-5 mx-auto p-1">
                <Link to="/Driving Efficiency and Elevated Experiences">
                  <div className="card">
                    <div className="image">
                      <img src={blog1} alt="Card 1" />
                    </div>
                    <div className="blogtext_container">
                      <h6 style={{ textAlign: "left" }} className="my-2">
                        Driving Efficiency and Elevated Experiences: Insights
                        into Cost-Effective Service Improvement
                      </h6>
                      <div className="blogtext">
                        <p>
                          Efficiency in the process of customer service means
                          the fulfilment of customer service goals with the
                          minimum amount of effort from both sides. Efficiency
                          can be considered intelligent laziness and doing the
                          right things with as little effort as possible. It is
                          not about saving resources and money or simply doing
                          things fast. Fewer efforts mean, for example, the
                          agents of customer service operate with minimal
                          resource wastage but the consumer achieves their goals
                          in as few steps as possible as well.
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-md-4 col-sm-5 mx-auto p-1">
                <Link to="/The Perfect Blend">
                  <div className="card">
                    <div className="image">
                      <img src={blog2} alt="Card 1" />
                    </div>
                    <div className="blogtext_container">
                      <h6 style={{ textAlign: "left" }} className="my-2">
                        The Perfect Blend: Why a Mix of Virtual and Real Is
                        Essential for Exceptional CX
                      </h6>
                      <div className="blogtext">
                        <p>
                          Digital customer experience or DCX refers to how
                          consumers engage and interact with business brands
                          online. It includes online platforms across desktop
                          and mobile and digitally mediated experiences through
                          social media content and own applications. It also
                          extends to environments connected digitally like IoT
                          and devices that are voice-activated. Ultimately,
                          wherever your consumers are engaging with your brand
                          in the digital landscape becomes a digital experience.
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-md-4 col-sm-5 mx-auto p-1">
                <Link to="/AI and Global Reach">
                  <div className="card">
                    <div className="image">
                      <img src={blog3} alt="Card 1" />
                    </div>
                    <div className="blogtext_container">
                      <h6 style={{ textAlign: "left" }} className="my-2">
                        AI and Global Reach: The Key to Scalable Solutions by
                        Technotask
                      </h6>
                      <div className="blogtext">
                        <p>
                          AI or Artificial Intelligence has become one of the
                          transformative forces in today’s era of technological
                          advancements. The influence of AI extends across
                          various industries by revolutionising the way we live,
                          interact and work. From healthcare and education to
                          finance and entertainment, AI is reshaping traditional
                          practices and opening up exciting and emerging
                          possibilities.
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-md-4 col-sm-5 mx-auto p-1">
                <Link to="/Unleashing the Potential of Gen AI">
                  <div className="card">
                    <div className="image">
                      <img src={blog4} alt="Card 1" />
                    </div>
                    <div className="blogtext_container">
                      <h6 style={{ textAlign: "left" }} className="my-2">
                        Unleashing the Potential of Gen AI: Elevating Customer
                        Experiences Through Omni-Channel Engagement Introduction
                      </h6>
                      <div className="blogtext">
                        <p>
                          Omnichannel means that customers can discover one’s
                          brand through multiple channels like social media,
                          search engines, email newsletters and more. The
                          primary objective of omnichannel isn’t just about
                          ensuring customer access to your products. It is also
                          about making sure they know the value of those
                          products along with their cost and their availability
                          in the market. This is exactly where AI comes into
                          play.
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-md-4 col-sm-5 mx-auto p-1">
                <Link to="/Innovations in Debt Collection">
                  <div className="card">
                    <div className="image">
                      <img src={blog5} alt="Card 1" />
                    </div>
                    <div className="blogtext_container">
                      <h6 style={{ textAlign: "left" }} className="my-2">
                        Innovations in Debt Collection: Enhancing Customer
                        Experiences and Recovery Rates
                      </h6>
                      <div className="blogtext">
                        <p>
                          Debt collection plays a pivotal part in the financial
                          sector. It helps in the measurement of cash flow for
                          any organisation. Track and assessment will help in
                          the identification and avoiding slow-paying customers.
                          In addition to that, debt collection helps in avoiding
                          paying late fees to vendors and preventing opening a
                          line of credit. Furthermore, it helps to ensure that
                          debts are paid in full and on time which helps in the
                          maintenance of financial stability for the lender as
                          well as the borrower along with the promotion of
                          responsible borrowing through reminding individuals
                          and businesses of their financial obligations.
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-md-4 col-sm-5 mx-auto p-1">
                <Link to="/AI Revolution in Customer Experience 2023">
                  <div className="card">
                    <div className="image">
                      <img src={blog6} alt="Card 1" />
                    </div>
                    <div className="blogtext_container">
                      <h6 style={{ textAlign: "left" }} className="my-2">
                        AI Revolution in Customer Experience 2023
                      </h6>
                      <div className="blogtext">
                        <p>
                          In the contemporary era’s business landscape, which is
                          highly competitive in nature, organisations need to
                          provide a great customer experience to succeed and
                          stand out from their competitors. Customer experience
                          can be considered the sum of all customer experiences
                          with a particular brand from its first point of
                          contact to post-purchase support. CX or customer
                          experience encompasses every touchpoint an
                          organisation has with an organisation including sales,
                          customer service, marketing and the usage of product.
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="px-lg-5 px-md-4 px-sm-3 px-1">
        <Footer />
      </div>
    </div>
  );
}

export default Resources;
