import React, { useState, useEffect, useRef } from "react";
import "../../Dasboard/Css/Dashboard.css";
import "../../Dasboard/Css/Bucket30days.css";
import "../../Dasboard/Css/SettingPage.css";
import "../../Dasboard/Css/HelpSupport.css";
import "../../Dasboard/Css/SideBar.css";
import "../../Dasboard/Css/HomePageNafia.css";
import "../../Dasboard/Css/KUKnowledgeBase.css";
import { Chart as ChartJS, registerables } from "chart.js";
import NafiaSideBar from "./NafiaSideBar.js";
import Calender from "../../assets/images/calendar12.svg";
import { Link, useLocation } from "react-router-dom";
import FilterSvg from "../../assets/images/Funnel.svg";
import $ from "jquery";
import moment from "moment";
import "moment/locale/en-au";
import "daterangepicker";
import Technotask from "../../assets/images/TechnotaskLogo12.png";
import NafiaUserCenterNav from "./NafiaUserCenterNav.js";
import axios from "axios";
import { chatboat_api } from "../../KeyConnection/KeysService.js"; // Adjust the path as needed
function CreateNewTicket() {
  const location = useLocation();
  const [selectedHeading, setSelectedHeading] = useState(null);

  const handleHeadingClick = (heading) => {
    // Revert color of the previously clicked heading
    if (selectedHeading) {
      selectedHeading.style.color = "black";
    }

    // Change color of the clicked heading
    heading.style.color = "rgba(126, 50, 203, 1)";

    // Set the clicked heading as the currently selected heading
    setSelectedHeading(heading);
  };

  const [Nafiaadmindata, setdataFromNafia] = useState([]);
  ChartJS.register(...registerables);

  const [overviewShow, setOverviewShow] = useState(true); // Default to 'true'
  const [debtorProfile, setdebtorProfile] = useState(false);

  const [selectedtime, setSelectedselectedtime] = useState(null);
  const timeList = [
    { name: "This Week", code: "This Week" },
    { name: "Last Month", code: "Last Month" },
    { name: "last 3 Months ", code: "last 3 Months " },
    { name: "last 6 Months ", code: "last 6 Months " },
    { name: "last Year", code: "last Year" },
    { name: "Lifetime", code: "Lifetime" },
  ];

  const [AmraadminData, setdataFromAmra] = useState([]);
  const inputRef = useRef(null);

  const allknowledgeupdate = (cmy_id) => {
    const headers = {
      "Content-Type": "application/json", // Change the content type to handle file upload
    };
    axios
      .get(chatboat_api(cmy_id), headers)
      .then(function (response) {
        const data = response.data;
        console.log(data.data);
        setdataFromAmra(data.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const formattedData = AmraadminData.map((item) => {
    const dateTime = new Date(item.created_at);
    const date = dateTime.toLocaleDateString(); // Format as 'MM/DD/YYYY'
    const time = dateTime.toLocaleTimeString(); // Format as 'HH:MM:SS'

    return { id: item.id, date, time };
  });

  useEffect(() => {
    // This will be called when the component mounts
    allknowledgeupdate(localStorage.getItem("company_id"));
  }, []);

  const handleDateRangeChange = (start, end, label) => {
    // Callback function for handling date range changes
    let startDate = start.format("DD-MM-YYYY").toString();
    let endDate = end.format("DD-MM-YYYY").toString();

    // handlecallevnt(startDate, endDate);
    console.log(startDate, endDate);
  };

  useEffect(() => {
    const datepicker = $(inputRef.current);
    datepicker.daterangepicker(
      {
        timePicker: true,
        startDate: moment().subtract(7, "days").startOf("day"), // Set start date to 7 days ago
        endDate: moment().add(2, "days").endOf("day"), // Set end date to today
        locale: {
          format: "DD/MM/YYYY",
        },
      },
      handleDateRangeChange
    );

    // Call handleDateRangeChange on component load
    const startDate = datepicker.data("daterangepicker").startDate;
    const endDate = datepicker.data("daterangepicker").endDate;
    handleDateRangeChange(startDate, endDate);
  }, []);

  useEffect(() => {
    if (AmraadminData && AmraadminData.length > 0) {
      // Destroy the DataTable if it already exists
      if ($.fn.DataTable.isDataTable("#myTable")) {
        $("#myTable").DataTable().destroy();
      }

      // Initialize DataTable
      $("#myTable").DataTable();
    }
    console.log(AmraadminData);
  }, [AmraadminData]);

  return (
    <div className="dashboard">
      <div className="dashboard_container">
        <div className="dashboard_wrapper">
          <NafiaSideBar
            overviewShow={overviewShow}
            setOverviewShow={setOverviewShow}
            debtorProfile={debtorProfile}
            setdebtorProfile={setdebtorProfile}
          />
          <div id="content">
            <NafiaUserCenterNav />
            <div className="overviewContaINER">
              <div className="overviewHead overviewHeaddp ">
                <h4>Knowledge Update</h4>
                <div className="overviewHeadLeft">
                  <div className="date_filter_div">
                    <div className="date_filter">
                      <div className="calender_icon">
                        <label
                          style={{ cursor: "pointer" }}
                          htmlFor="datepicker"
                        >
                          <img src={Calender} alt="Image" />
                        </label>
                      </div>
                      <input
                        id="datepicker"
                        className="form-control form-control-solid"
                        placeholder="Pick date range"
                        ref={inputRef}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="knowUpdHead">
                <div className="col-xl-8 col-lg-10">
                  <div className="knowUpdHeadContainer">
                    <div
                      // className="headsOfKU"
                      className={`headsOfKU ${
                        location.pathname === "/Knowledge"
                          ? "activeheadsOfKU"
                          : ""
                      }`}
                    >
                      <Link to="/Knowledge">
                        <h6>KB Update</h6>
                      </Link>
                    </div>
                    <div
                      className={`headsOfKU ${
                        location.pathname === "/KnowledgeBase"
                          ? "activeheadsOfKU"
                          : ""
                      }`}
                    >
                      <Link to="/KnowledgeBase">
                        <h6>Knowledge Base</h6>
                      </Link>
                    </div>
                    <div className="headsOfKU">
                      <Link to="/KBhealth">
                        <h6>
                          KB Health &nbsp;{" "}
                          {/* <span className="postMvp">Post MVP</span> */}
                        </h6>
                      </Link>
                    </div>
                    <div className="headsOfKU">
                      <h6>
                        Manage KBS &nbsp;{" "}
                        {/* <span className="postMvp">Post MVP</span> */}
                      </h6>
                    </div>
                    <div className="headsOfKU">
                      <h6>
                        KB History &nbsp;{" "}
                        {/* <span className="postMvp">Post MVP</span> */}
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
              <div className="datatableCOntainerDiv">
                <div className="datatableCOntainerHead">
                  <h4>Recent Update</h4>
                  <div className="filterBtnDivs">
                    <button>
                      <p>Filter</p>
                      <img src={FilterSvg} alt="icon" />
                    </button>
                  </div>
                </div>
                <div className="amradataTable">
                  <div className="amradataTable_container ">
                    <table id="myTable" className="display">
                      <thead>
                        <tr>
                          <th style={{ textAlign: "center" }}>#</th>
                          <th style={{ textAlign: "center" }}>KB ID</th>
                          <th style={{ textAlign: "center" }}>Update Date</th>
                          <th style={{ textAlign: "center" }}>Time</th>
                        </tr>
                      </thead>
                      <tbody>
                        {formattedData.map((item, index) => (
                          <tr key={index}>
                            <td>
                              <div className="tableData1 tableData12">
                                <p>{index + 1}</p>
                              </div>
                            </td>
                            <td>
                              <div className="tableData1 tableData12">
                                <p>{item.id}</p>
                              </div>
                            </td>
                            <td>
                              <div className="tableData1 tableData12">
                                <p style={{ color: "#000" }}>{item.date}</p>
                              </div>
                            </td>
                            <td>
                              <div className="tableData1 tableData12">
                                <p>{item.time}</p>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="brandingContainer">
                <div>
                  <h5>Powered by</h5>
                  <img src={Technotask} alt="Logo" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateNewTicket;
