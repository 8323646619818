import React, { useState } from "react";
import "../Css/Dashboard.css";
import "../Css/Bucket30days.css";
import "../Css/AddDebtorForm.css";
import "../Css/PaymentHistory.css";

import SideBar from "./SideBar";
import { Chart as ChartJS, registerables } from "chart.js";
import "react-circular-progressbar/dist/styles.css";
import generateButton from "../../assets/images/generateButton.png";
import DataModal from "./DataModal";
import Technotask from "../../assets/images/TechnotaskLogo12.png";
import { get_debtor } from "../../KeyConnection/KeysService.js";
import { Helmet } from "react-helmet";

import CenterNavbar from "./CenterNavbar";
function DebtorProfile60() {
  const [loanID, setLoanID] = useState("");
  const [userNumber, setuserNumber] = useState("");

  ChartJS.register(...registerables);

  const [overviewShow, setOverviewShow] = useState(true); // Default to 'true'
  const [debtorProfile, setdebtorProfile] = useState(false);

  const [modalData, setModalData] = useState({
    customer_id: 1234,
    name: "Bhasim",
    mobile: 92345,
    call_record: [],
  });

  const searchCallDetails = async () => {
    // Fetch data from the API endpoint
    let url_for = get_debtor;
    if (loanID !== "" || userNumber !== "") {
      if (userNumber.length === 0) {
        url_for += loanID;
      } else {
        url_for += userNumber;
      }
      await fetch(url_for)
        .then((response) => response.json())
        .then((data) => {
          let debtor = data.data;
          const status = data.status_flag;

          const call_record = debtor.debtor_history.map((data, i) => {
            // calculate bucket using due data
            let bucket = "";
            let now = Date.now();
            let dueDate = new Date(debtor.due_date);
            if (debtor.last_payment_date) {
              dueDate = new Date(debtor.last_payment_date);
            }
            let days = Math.ceil((now - dueDate) / (1000 * 60 * 60 * 24));
            if (days <= 0) {
              bucket = "Pre-due";
            } else if (days > 0 && days <= 30) {
              bucket = "30 days";
            } else if (days > 30 && days <= 60) {
              bucket = "60 days";
            } else if (days > 60) {
              bucket = "90 days";
            }

            const date = new Date(data.created_at);
            const formattedDate = date.toLocaleDateString("en-US", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            });
            const hours = date.getHours().toString().padStart(2, "0");
            const minutes = date.getMinutes().toString().padStart(2, "0");

            const formattedTime = `${hours}:${minutes}`;
            let details = "";
            if (data.remarks != "") {
              try {
                details = data.remarks.split("@@@")[0];
              } catch (err) {
                //err
              }
            }

            return {
              chat_id: data.chat_id,
              serial_no: i + 1,
              call_date: formattedDate,
              call_time: formattedTime,
              chat_id: data.chat_id,
              bucket,
              details,
              notes: status[data.call_flag],
              case: "N/A",
            };
          });

          const obj = {
            customer_id: debtor.debtor_id,
            name: debtor.debtor_name,
            mobile: debtor.debtor_no,
            email: debtor.debtor_email,
            call_record,
          };

          setModalData(obj);

          // Attach the function to the button click event
          const button = document.getElementById("modelclick");
          if (button) {
            button.click();
          }
        })
        .catch((err) =>
          alert(
            "Cannot find call data corresponding to given Loan ID or Mobile number"
          )
        );
    }
  };

  return (
    <div className="dashboard">
      <Helmet>
        <title>Amra User Dashboard</title>
        <meta
          name="description"
          content="AI and Global Reach: The Key to Scalable Solutions in Customer
          Experience"
        />
      </Helmet>
      <div className="dashboard_container">
        <div className="dashboard_wrapper">
          <SideBar
            overviewShow={overviewShow}
            setOverviewShow={setOverviewShow}
            debtorProfile={debtorProfile}
            setdebtorProfile={setdebtorProfile}
          />
          <div id="content">
            <CenterNavbar />
            <div className="row justify-content-center  align-items-center m-0 h-100">
              <div className="col-xl-3 col-md-4 col-sm-5 debtorSearch">
                <div className="debtorFilter">
                  <div className="debtorFilterContainer">
                    <h5 className="my-3">Call Data </h5>
                    <input
                      className="inp"
                      type="mobile no"
                      placeholder="Loan ID"
                      value={loanID}
                      onChange={(e) => {
                        setLoanID(e.target.value);
                        setuserNumber("");
                      }}
                      onInput={(e) => {
                        if (!/^[a-zA-Z0-9@.]*$/.test(e.target.value)) {
                          e.target.value = ""; // Clear the input if it contains any other character
                        }
                      }}
                    />
                    <h5>or</h5>
                    <input
                      className="inp"
                      type="mobile no"
                      placeholder="Mobile No."
                      value={userNumber}
                      onChange={(e) => {
                        setLoanID("");
                        setuserNumber(e.target.value);
                      }}
                      maxLength="10"
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, "");
                      }}
                    />
                    <button
                      onClick={searchCallDetails}
                      className="Search_btn  d-flex"
                    >
                      <img src={generateButton} alt="icon" />
                    </button>
                    <button
                      id="modelclick"
                      className="hidden"
                      data-bs-toggle="modal"
                      data-bs-target="#staticBackdrop"
                    >
                      <img src={generateButton} alt="icon" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="brandingContainer">
              <div>
                <h5>Powered by</h5>
                <img src={Technotask} alt="Logo" />
              </div>
            </div>
            <div>
              <DataModal modalData={modalData} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DebtorProfile60;
