import React, { useEffect, useCallback, useRef, useState } from "react";
import CircleCheck from "../../assets/images/circle_check.svg";
import NotificationsImg from "../../assets/images/notification.svg";
import { useNavigate, Link, useLocation } from "react-router-dom";
import SideBarControl from "../../assets/images/rightLeftSIdebar.png";
import adm_img from "../../assets/images/adm_img.png";
import Logout from "../../assets/images/giflogout.gif";
import $ from "jquery";

function CenterNavbar() {
  const navigate = useNavigate();
  const location = useLocation();
  const user_name = localStorage.getItem("user_name");
  const user_email = localStorage.getItem("user_email");
  const company_name = localStorage.getItem("company_name");
  const login_for = localStorage.getItem("login_for");
  const company_created_at = localStorage.getItem("company_created_at");
  const logout = () => {
    localStorage.clear();
    navigate("/Hospital_Login");
  };

  const redirectiflogin = useCallback(() => {
    if (user_email) {
      navigate("/burjeel_homepage");
    }
  }, [user_email, navigate]);

  const redirectifnotlogin = useCallback(() => {
    if (!user_email) {
      navigate("/Hospital_Login");
    }
  }, [user_email, navigate]);

  useEffect(() => {
    const currentUrl = location.pathname;

    const pathnameParts = currentUrl.split("/");
    if (
      (currentUrl === "/AmraLogin" || currentUrl === "/sign_up") &&
      login_for === "amra_user"
    ) {
      redirectiflogin();
    } else if (
      currentUrl === "/Amra_Dashboard" ||
      currentUrl === "/Debtorprofile"
    ) {
      redirectifnotlogin();
    }
  }, [location.pathname, redirectiflogin, redirectifnotlogin]);

  useEffect(() => {
    // Define the function to handle sidebar collapse
    const handleSidebarCollapse = () => {
      $("#sidebar").toggleClass("active");
      $(".btn_toggle1").toggleClass("btn_toggle1ani");
    };

    // Attach the event listener to your elements
    $("#sidebarCollapse").on("click", handleSidebarCollapse);
    $("#sidebarCollapse1").on("click", handleSidebarCollapse);

    // Cleanup logic
    return () => {
      // Remove the event listeners when the component is unmounted
      $("#sidebarCollapse").off("click", handleSidebarCollapse);
      $("#sidebarCollapse1").off("click", handleSidebarCollapse);
    };
  }, []);

  return (
    <nav className="navbar centerNav navbar-expand-lg navbar-light bg-light p-0">
      <div className="navbar_left">
        <div
          type="button"
          id="sidebarCollapse1"
          className="btn_toggle1 btn_toggle1ani"
        >
          <img src={SideBarControl} alt="img" />
        </div>

        <div className="logo_msgCount">
          <div className="logo_div">
            <h4> {company_name} </h4>
          </div>
          <div className="msgCount_div">
            <p>Created: {company_created_at}</p>
          </div>
        </div>
        <div className="liveDiv">
          <img src={CircleCheck} alt="icon" />
          <p>LIVE</p>
        </div>
      </div>

      <div className="navbar_right">
        {/* <div className="navbarRightNoti">
                  <img src={NotificationsImg} alt="icon" />
                </div> */}
        <div className="navbarRightAcc">
          <img src={adm_img} alt="icon" />
          <div className="nav-item dropdown">
            <div
              className="nav-link dropdown-toggle"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {user_name}
            </div>
            <div
              className="dropdown-menu prdctList LEFTalINGdROPDOWN"
              aria-labelledby="navbarDropdown"
            >
              {/* <a className="dropdown-item" href="/">
                        Account
                      </a> */}
              <a className="dropdown-item" onClick={logout}>
                <div className="logoutIconandDiv">
                  <p>Logout</p>
                  <img src={Logout} alt="icon" />
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default CenterNavbar;
