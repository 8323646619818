import React, { useState, useEffect, useRef } from "react";
import "../Css/Dashboard.css";
import "../Css/Bucket30days.css";
import "../Css/AddDebtorForm.css";
import "../Css/PaymentHistory.css";
import SideBar from "./SideBar";
import CircleCheck from "../../assets/images/circle_check.svg";
import NotificationsImg from "../../assets/images/notification.svg";
import SideBarControl from "../../assets/images/rightLeftSIdebar.png";
import UserImgPrf from "../../assets/images/userimg45.svg";
import { Chart as ChartJS, registerables } from "chart.js";
import "react-circular-progressbar/dist/styles.css";
import VRline from "../../assets/images/vrLine.svg";
import SearchIcon from "../../assets/images/searchIcon.svg";
import { Bar } from "react-chartjs-2";
import faker from "faker";
import Calender from "../../assets/images/calendar12.svg";
import Vector1 from "../../assets/images/Vector1.png";
import GreenTick from "../../assets/images/circle_check.svg";
import GreyTick from "../../assets/images/circle_checkgrey.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import adm_img from "../../assets/images/adm_img.png";
import DataModal from "./DataModal";
import Technotask from "../../assets/images/TechnotaskLogo12.png";
import Logout from "../../assets/images/giflogout.gif";
import $ from "jquery";
import moment from "moment";
import "moment/locale/en-au";
import "daterangepicker";
import { Helmet } from "react-helmet";
import CenterNavbar from "./CenterNavbar";
import { get_debtor } from "../../KeyConnection/KeysService.js";

function DebtorProfile60() {
  const [city_input, setCityInput] = useState("");
  const [loanID, setLoanID] = useState("");
  const [userNumber, setuserNumber] = useState("");
  const [userEmail, setuserEmail] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [showDatePicker, setShowDatePicker] = useState(false);

  const [selectedCheckboxes, setSelectedCheckboxes] = useState({
    "30 days": true,
    "60 days": true,
    "90 days": true,
    "pre-due": true,
  });

  const handleCheckboxChange = (checkboxId) => {
    setSelectedCheckboxes((prevSelected) => ({
      ...prevSelected,
      [checkboxId]: !prevSelected[checkboxId],
    }));
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        display: false,
      },
      title: {
        display: false,
        text: "Chart.js Bar Chart",
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: true,
        },
        beginAtZero: true,
      },
    },
  };
  const labels = ["30 Days", "60 Days", "90 Days"];

  const data = {
    labels,
    datasets: [
      {
        label: "Dataset 1",
        data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
        backgroundColor: "rgba(236, 204, 255, 1)", // Color for Dataset 1
        barThickness: 25,
      },

      {
        label: "Dataset 2",
        data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
        backgroundColor: "rgba(126, 50, 203, 1)", // Color for Dataset 2
        barThickness: 25,
      },
    ],
  };
  ChartJS.register(...registerables);

  const [overviewShow, setOverviewShow] = useState(true); // Default to 'true'
  const [debtorProfile, setdebtorProfile] = useState(false);

  const [modalData, setModalData] = useState({
    customer_id: 1234,
    chat_id: "",
    name: "Bhasim",
    mobile: 92345,
    call_record: [],
  });

  const searchCallDetails = async () => {
    // Fetch data from the API endpoint
    let url_for = get_debtor;
    if (userNumber.length === 0) {
      url_for += loanID;
    } else {
      url_for += userNumber;
    }
    await fetch(url_for)
      .then((response) => response.json())
      .then((data) => {
        let debtor = data.data;
        const status = data.status_flag;

        const call_record = debtor.debtor_history.map((data, i) => {
          // calculate bucket using due data
          console.log(data);
          let bucket = "";
          let now = Date.now();
          let dueDate = new Date(debtor.due_date);
          if (debtor.last_payment_date) {
            dueDate = new Date(debtor.last_payment_date);
          }
          let days = Math.ceil((now - dueDate) / (1000 * 60 * 60 * 24));
          if (days <= 0) {
            bucket = "Pre-due";
          } else if (days > 0 && days <= 30) {
            bucket = "30 days";
          } else if (days > 30 && days <= 60) {
            bucket = "60 days";
          } else if (days > 60) {
            bucket = "90 days";
          }

          const date = new Date(data.created_at);
          const formattedDate = date.toLocaleDateString("en-US", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          });
          const hours = date.getHours().toString().padStart(2, "0");
          const minutes = date.getMinutes().toString().padStart(2, "0");

          const formattedTime = `${hours}:${minutes}`;
          let details = "";
          if (data.remarks != "") {
            try {
              details = data.remarks.split("@@@")[0];
            } catch (err) {
              //err
            }
          }

          return {
            chat_id: data.chat_id,
            serial_no: i + 1,
            call_date: formattedDate,
            call_time: formattedTime,
            chat_id: data.chat_id,
            bucket,
            details,
            notes: status[data.call_flag],
            case: "N/A",
          };
        });

        const obj = {
          customer_id: debtor.debtor_id,
          name: debtor.debtor_name,
          mobile: debtor.debtor_no,
          email: debtor.debtor_email,
          call_record,
        };

        setModalData(obj);
      })
      .catch((err) => alert(err));
  };

  // const inputRef = useRef(null);

  // const handleDateRangeChange = (start, end, label) => {
  //   // Callback function for handling date range changes
  //   let startDate = start.format("DD-MM-YYYY").toString();
  //   let endDate = end.format("DD-MM-YYYY").toString();

  //   // handlecallevnt(startDate, endDate);
  //   console.log(startDate, endDate);
  // };

  useEffect(() => {
    // Call the function to fetch data when the component mounts
    // const datepicker = $(inputRef.current);
    // datepicker.daterangepicker(
    //   {
    //     timePicker: true,
    //     startDate: moment().startOf("hour"),
    //     endDate: moment().startOf("hour").add(32, "hour"),
    //     locale: {
    //       format: "DD/MM/YYYY",
    //     },
    //   },
    //   handleDateRangeChange
    // );
    // // Call handleDateRangeChange on component load
    // const startDate = datepicker.data("daterangepicker").startDate;
    // const endDate = datepicker.data("daterangepicker").endDate;
    // handleDateRangeChange(startDate, endDate);
  }, []);

  return (
    <div className="dashboard">
      <Helmet>
        <title>Amra User Dashboard</title>
        <meta
          name="description"
          content="AI and Global Reach: The Key to Scalable Solutions in Customer
          Experience"
        />
      </Helmet>
      <div className="dashboard_container">
        <div className="dashboard_wrapper">
          <SideBar
            overviewShow={overviewShow}
            setOverviewShow={setOverviewShow}
            debtorProfile={debtorProfile}
            setdebtorProfile={setdebtorProfile}
          />
          <div id="content">
            <CenterNavbar />

            <div className="AmraConversations">
              <div className="AmraConversations_container">
                <div className="AmraConversations_container_wrapper">
                  <div className="overviewContaINER">
                    <div className="overviewHead overviewHeaddp ">
                      <h4>Payment History</h4>
                    </div>
                    <div className="valueCardpad valueCardpad2">
                      <div className="valueCard filterDic">
                        <div className="filterDivHeadContiaenr">
                          <div className="filterDivHead">
                            <img
                              src={Vector1}
                              alt="Icon"
                              className="img-fluid"
                            />
                            <h3>Filters</h3>
                          </div>
                          {/* <div className="date_filter_div">
                            <div
                              className="date_filter"
                            >
                              <div className="calender_icon">
                                <label
                                  style={{ cursor: "pointer" }}
                                  htmlFor="datepicker"
                                >
                                  <img src={Calender} alt="Image" />
                                </label>
                              </div>
                              <input
                                id="datepicker"
                                className="form-control form-control-solid"
                                placeholder="Pick date range"
                                ref={inputRef}
                              />
                            </div>
                          </div> */}
                        </div>
                        <div className="manualcallflex-container findcity">
                          <div className="row m-0">
                            <div className="col-md-5" style={{ padding: "0" }}>
                              <div className="input-div">
                                <input
                                  type="text"
                                  className="form-control searchUser"
                                  placeholder="Search for User/City"
                                  onChange={(e) => {
                                    setCityInput(e.target.value);
                                  }}
                                  onInput={(e) => {
                                    if (
                                      !/^[a-zA-Z0-9@.]*$/.test(e.target.value)
                                    ) {
                                      e.target.value = ""; // Clear the input if it contains any other character
                                    }
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-md-7">
                              <div
                                type="text"
                                className="form-control Buckettext-div"
                              >
                                <div className="bucketButtonText">
                                  <div
                                    className=""
                                    style={{ marginRight: "0.2rem" }}
                                  >
                                    <p>Bucket</p>
                                  </div>
                                  {/* Render checkboxes and labels */}
                                  {Object.keys(selectedCheckboxes).map(
                                    (checkboxId) => (
                                      <div
                                        key={checkboxId}
                                        className="bucketBtnsDiv"
                                      >
                                        <div style={{ position: "relative" }}>
                                          <input
                                            id={checkboxId}
                                            type="checkbox"
                                            hidden
                                            checked={
                                              selectedCheckboxes[checkboxId]
                                            }
                                            onChange={() =>
                                              handleCheckboxChange(checkboxId)
                                            }
                                          />
                                          <label
                                            htmlFor={checkboxId}
                                            className="checkmark"
                                          ></label>
                                        </div>
                                        <p>{`${checkboxId
                                          .charAt(0)
                                          .toUpperCase()}${checkboxId.slice(
                                          1
                                        )}`}</p>
                                      </div>
                                    )
                                  )}
                                  {/* <div className="bucketBtnsDiv">
                                    <div
                                      style={{
                                        position: "relative",
                                      }}
                                    >
                                      <input
                                        id="30days"
                                        type="checkbox"
                                        hidden
                                      />
                                      <label
                                        htmlFor="30days"
                                        className="checkmark"
                                      ></label>
                                    </div>
                                    <p>30 Days</p>
                                  </div>
                                  <div className="bucketBtnsDiv">
                                    <div
                                      style={{
                                        position: "relative",
                                      }}
                                    >
                                      <input
                                        id="60days"
                                        type="checkbox"
                                        hidden
                                      />
                                      <label
                                        htmlFor="60days"
                                        className="checkmark"
                                      ></label>
                                    </div>
                                    <p>60 Days</p>
                                  </div>
                                  <div className="bucketBtnsDiv">
                                    <div
                                      style={{
                                        position: "relative",
                                      }}
                                    >
                                      <input
                                        id="90days"
                                        type="checkbox"
                                        hidden
                                      />
                                      <label
                                        htmlFor="90days"
                                        className="checkmark"
                                      ></label>
                                    </div>
                                    <p>90 Days</p>
                                  </div>
                                  <div className="bucketBtnsDiv">
                                    <div
                                      style={{
                                        position: "relative",
                                      }}
                                    >
                                      <input
                                        id="pre-due"
                                        type="checkbox"
                                        hidden
                                      />
                                      <label
                                        htmlFor="pre-due"
                                        className="checkmark"
                                      ></label>
                                    </div>
                                    <p>Pre-due</p>
                                  </div> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <div className="rowCibil cibil">
                          <div className="cibiloutdiv ">
                            <div className="indiv d-flex">
                              <h5 style={{ margin: "0" }}>CIBIL Score</h5>
                              <div className="chechBoxDiv">
                                <input
                                  type="checkbox"
                                  className="checkbox"
                                  id="checkbox1"
                                ></input>
                                <label htmlFor="checkbox1">Poor</label>
                              </div>
                              <div className="chechBoxDiv">
                                <input
                                  type="checkbox"
                                  className="checkbox"
                                  id="checkbox2"
                                ></input>
                                <label htmlFor="checkbox2">Fair</label>
                              </div>
                              <div className="chechBoxDiv">
                                <input
                                  type="checkbox"
                                  className="checkbox"
                                  id="checkbox3"
                                ></input>
                                <label htmlFor="checkbox3">Good</label>
                              </div>
                              <div className="chechBoxDiv">
                                <input
                                  type="checkbox"
                                  className="checkbox"
                                  id="checkbox4"
                                ></input>
                                <label htmlFor="checkbox4">Excellent</label>
                              </div>
                            </div>
                          </div>

                          <div className="radiotherDiv">
                            <div className="chechBoxDiv">
                              <input
                                type="checkbox"
                                className="checkbox"
                                id="checkbox5"
                              ></input>
                              <label htmlFor="checkbox5">L2 Escalation</label>
                            </div>
                            <div className="chechBoxDiv">
                              <input
                                type="checkbox"
                                className="checkbox"
                                id="checkbox6"
                              ></input>
                              <label htmlFor="checkbox6">Rescheduled</label>
                            </div>
                            <div className="chechBoxDiv">
                              <input
                                type="checkbox"
                                className="checkbox"
                                id="checkbox7"
                              ></input>
                              <label htmlFor="checkbox7">
                                Payment Link Shared by Amra
                              </label>
                            </div>
                          </div>
                        </div> */}
                      </div>

                      <div></div>

                      <div className="debtorProfile">
                        <div className="debtorProfileContainer1">
                          <div className="debtordetailCards"></div>
                        </div>
                      </div>
                    </div>

                    <div className="revenueCall">
                      <div className="row m-0">
                        <div className="row col-md-8 p-0">
                          {selectedCheckboxes["30 days"] && (
                            <div className="col-md-6">
                              <div className="valueCardpadPymntHstry valueCardpad1PymntHstry">
                                <div className="valueCardPymntHstry  justify-content-around">
                                  <div className="headblock">
                                    <h6>30 days Payment Received</h6>
                                  </div>
                                  <div className="paymntRcvdCaRDS">
                                    <div className="valueCardInsidePymntHstry">
                                      <p>Today</p>
                                      <p>Estimated This week</p>
                                      <p>Estimated This Month</p>
                                    </div>
                                    <div className="valueCardInsidePymntHstry">
                                      <img src={VRline} alt="icon" />
                                    </div>
                                    <div className="valueCardInsidePymntHstry">
                                      <p style={{ fontWeight: "600" }}>$256K</p>
                                      <p style={{ fontWeight: "600" }}>$256K</p>
                                      <p style={{ fontWeight: "600" }}>$256K</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          {selectedCheckboxes["60 days"] && (
                            <div className="col-md-6">
                              <div className="valueCardpadPymntHstry valueCardpad1PymntHstry">
                                <div className="valueCardPymntHstry  justify-content-around">
                                  <div className="headblock">
                                    <h6>60 days Payment Received</h6>
                                  </div>
                                  <div className="paymntRcvdCaRDS">
                                    <div className="valueCardInsidePymntHstry">
                                      <p>Today</p>
                                      <p>Estimated This week</p>
                                      <p>Estimated This Month</p>
                                    </div>
                                    <div className="valueCardInsidePymntHstry">
                                      <img src={VRline} alt="icon" />
                                    </div>
                                    <div className="valueCardInsidePymntHstry">
                                      <p style={{ fontWeight: "600" }}>$256K</p>
                                      <p style={{ fontWeight: "600" }}>$256K</p>
                                      <p style={{ fontWeight: "600" }}>$256K</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          {selectedCheckboxes["90 days"] && (
                            <div className="col-md-6">
                              <div className="valueCardpadPymntHstry valueCardpad1PymntHstry">
                                <div className="valueCardPymntHstry  justify-content-around">
                                  <div className="headblock">
                                    <h6>90 days Payment Received</h6>
                                  </div>
                                  <div className="paymntRcvdCaRDS">
                                    <div className="valueCardInsidePymntHstry">
                                      <p>Today</p>
                                      <p>Estimated This week</p>
                                      <p>Estimated This Month</p>
                                    </div>
                                    <div className="valueCardInsidePymntHstry">
                                      <img src={VRline} alt="icon" />
                                    </div>
                                    <div className="valueCardInsidePymntHstry">
                                      <p style={{ fontWeight: "600" }}>$256K</p>
                                      <p style={{ fontWeight: "600" }}>$256K</p>
                                      <p style={{ fontWeight: "600" }}>$256K</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          {selectedCheckboxes["pre-due"] && (
                            <div className="col-md-6">
                              <div className="valueCardpadPymntHstry valueCardpad1PymntHstry">
                                <div className="valueCardPymntHstry  justify-content-around">
                                  <div className="headblock">
                                    <h6>Pre Scheduled Request Received</h6>
                                  </div>
                                  <div className="paymntRcvdCaRDS">
                                    <div className="valueCardInsidePymntHstry">
                                      <p>Today</p>
                                      <p>Estimated This week</p>
                                      <p>Estimated This Month</p>
                                    </div>
                                    <div className="valueCardInsidePymntHstry">
                                      <img src={VRline} alt="icon" />
                                    </div>
                                    <div className="valueCardInsidePymntHstry">
                                      <p style={{ fontWeight: "600" }}>$256K</p>
                                      <p style={{ fontWeight: "600" }}>$256K</p>
                                      <p style={{ fontWeight: "600" }}>$256K</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>

                        <div className="callRecord col-md-4">
                          <div className="col-md debtorSearchPayHis">
                            <div className="debtorFilter">
                              <div className="debtorFilterContainer">
                                <h5>Search (Debtor) </h5>
                                {/* <input
                                  className="inp"
                                  type="mobile no"
                                  placeholder="Customer Id"
                                  onChange={(e) => {
                                    setLoanID(e.target.value);
                                  }}
                                  onInput={(e) => {
                                    if (
                                      !/^[a-zA-Z0-9@.]*$/.test(e.target.value)
                                    ) {
                                      e.target.value = ""; // Clear the input if it contains any other character
                                    }
                                  }}
                                />
                                <h5>or</h5> */}
                                <input
                                  className="inp"
                                  type="mobile no"
                                  placeholder="Mobile No."
                                  value={userNumber}
                                  onChange={(e) => {
                                    setLoanID("");
                                    setuserNumber(e.target.value);
                                  }}
                                  maxLength="10"
                                  onInput={(e) => {
                                    e.target.value = e.target.value.replace(
                                      /[^0-9]/g,
                                      ""
                                    );
                                  }}
                                />
                                <h5>or</h5>
                                <input
                                  className="inp"
                                  type="mobile no"
                                  placeholder="Loan Id."
                                  value={loanID}
                                  onChange={(e) => {
                                    setLoanID(e.target.value);
                                    setuserNumber("");
                                  }}
                                  onInput={(e) => {
                                    if (
                                      !/^[a-zA-Z0-9@.]*$/.test(e.target.value)
                                    ) {
                                      e.target.value = ""; // Clear the input if it contains any other character
                                    }
                                  }}
                                />
                                {/* <h5>or</h5>
                                <input
                                  className="inp"
                                  type="Email address"
                                  placeholder="Email Address"
                                  onChange={(e) => {
                                    setuserEmail(e.target.value);
                                  }}
                                  onInput={(e) => {
                                    if (
                                      !/^[a-zA-Z0-9@.]*$/.test(e.target.value)
                                    ) {
                                      e.target.value = ""; // Clear the input if it contains any other character
                                    }
                                  }}
                                /> */}
                                <button
                                  onClick={searchCallDetails}
                                  className="Search_btn PayHis d-flex"
                                  data-bs-toggle="modal"
                                  data-bs-target="#staticBackdrop"
                                >
                                  <img src={SearchIcon} alt="icon" />
                                  <p>Search</p>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="col-md-8 p30 revenueCall_container graphsRevenueDiv "
                        style={{ alignSelf: "stretch", padding: "1.5rem 1rem" }}
                      >
                        <div className="barChartLegends">
                          <div className="barChartLegend">
                            <div className="barChartLegendpink"></div>
                            <p>Pending</p>
                          </div>
                          <div className="barChartLegend">
                            <div className="barChartLegendblue"></div>
                            <p>Recovered</p>
                          </div>
                        </div>
                        <Bar
                          className="w-100 h-100"
                          options={options}
                          data={data}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="brandingContainer">
                <div>
                  <h5>Powered by</h5>
                  <img src={Technotask} alt="Logo" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <DataModal modalData={modalData} />
      </div>
    </div>
  );
}

export default DebtorProfile60;
