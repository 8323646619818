import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Home from "./Components/Home";
import Resources from "./Components/Resources";
import Career from "./Components/Career";
import NafiyaScreen from "./Components/NafiyaScreen";
import NafiyaScreenVideo from "./Components/NafiyaScreen_video";
import NafiyaScreenAvatar from "./Components/NafiyaScreenAvatar";
import NafiyaScreenUnity from "./Components/NafiyaScreenUnity";
import NafiyaScreenFaq from "./Components/NafiyaScreenFaq";
import BookDemo from "./Components/BookDemo";
import CareerDetails from "./Components/CareerDetails";
import MainLanding from "./Components/MainLanding";
import Maintance from "./Components/Maintance";
import ScrollToTop from "./Components/ScrollToTop";
import ProductNafia from "./Components/ProductNafia";
import ShowBlog from "./Components/ShowBlog";
import Company from "./Components/Company";
import Dashboard from "./Dasboard/Componets/Dashboard";
import PrivacyPolicy from "./Components/PrivacyPolicy";
import TermsOfUse from "./Components/TermsOfUse";
import AmraScreen from "./Components/AmraScreen";
import ShowBlog2 from "./Components/ShowBlog2";
import ShowBlog3 from "./Components/ShowBlog3";
import ShowBlog4 from "./Components/ShowBlog4";
import ShowBlog5 from "./Components/ShowBlog5";
import LoadingScreen from "./Components/LoadingScren";
import Error404 from "./Components/Error404";
import ShowBlog6 from "./Components/ShowBlog6";
import QRPage from "./QR/Components/QRPage";
import QRPageNafia from "./QR/Components/QRPageNafia";
import QRForm from "./QR/Components/QRForm";
import QRWaitPage from "./QR/Components/QRWaitPage";
import FAQNAfia from "./QR/Components/FAQNAfia";
import LoadrerTechnoTask from "./Components/LoaderAnimation";
import TechnotaskDashboard from "./Dasboard/Componets/AmraDashboard";
import Customers from "./Dasboard/Componets/Customers";
import AmraConversations from "./Dasboard/Componets/AmraConversations";
import NafiaConversations from "./Dasboard/Componets/NafiaConversation";
import CareerView from "./Dasboard/Componets/CareerView";
import BookDemoView from "./Dasboard/Componets/BookDemoView";
import NewsView from "./Dasboard/Componets/NewsView";
import Bucket30days from "./Dasboard/Componets/Bucket30days";
import DebtorProfile60 from "./Dasboard/Componets/60day";
import DebtorProfile90 from "./Dasboard/Componets/90day";
import AddDebtorForm from "./Dasboard/Componets/AddDebtorForm";
import PaymentHistory from "./Dasboard/Componets/PaymentHistory";
import CallData from "./Dasboard/Componets/CallData";
import CallDetailView from "./Dasboard/Componets/CallDetailView";
import GeneratingReport from "./Dasboard/Componets/GeneratingReport";
import SettingPage from "./Dasboard/Componets/SettingPage";
import DebtorProfile from "./Dasboard/Componets/DebtorProfile";
import Chart from "./Dasboard/Componets/Chart";
import Invoicepage from "./Dasboard/Componets/Invoicepage";
import Usermanagement from "./Dasboard/Componets/Usermanagement";
import HelpSupport1 from "./Dasboard/Componets/HelpSupport1";
import CreateNewTicket from "./Dasboard/Componets/CreateTicket";
import ReplyToTicket from "./Dasboard/Componets/ReplyToTicket";
/*Nafia Dashboard Page import*/

import NafiaSettingPage01 from "./NafiaDashboard/Components/NafiaSettingPage01";
import NafiaInvoice from "./NafiaDashboard/Components/NafiaInvoice";
import NafiaUserManagement from "./NafiaDashboard/Components/NafiaUserManagement";
import NafiaHelpSupport1 from "./NafiaDashboard/Components/NafiaHelpSupport1";
import NafiaCreateNewTicket from "./NafiaDashboard/Components/NafiaCreateTicket";
import NafiaReplyToTicket from "./NafiaDashboard/Components/NafiaReplyToTicket";
import HomePageNafia from "./NafiaDashboard/Components/HomePageNafia";
import NafiaAdmin from "./SuperAdmin/NafiaAdmin/NafiaAdmin";
import NafiaAgents from "./SuperAdmin/NafiaAdmin/NafiaAgents";
import AmraAdmin from "./SuperAdmin/AmraAdmin/AmraAdmin";
import KUKnowledgeBase from "./NafiaDashboard/Components/KUKnowledgeBase";
import KBhealth from "./NafiaDashboard/Components/KBhealth";
import NafiaGeneratingReport from "./NafiaDashboard/Components/NafiaGeneratingReport";
import BillingForm from "./NafiaDashboard/Components/BillingForm";
import Knowledge from "./NafiaDashboard/Components/Knowledge";
import KnowledgeAddFile from "./NafiaDashboard/Components/KnowledgeAddFile";
import Login from "./NafiaDashboard/Components/Login";
import Login1 from "./Dasboard/Componets/Login1.js";
import Login2 from "./SuperAdmin/AmraAdmin/Login2.js";
import Login3 from "./SuperAdmin/NafiaAdmin/Login3.js";
import FaridaScreen from "./Components/FaridaScreen";
import "datatables.net";
import "datatables.net-dt/css/jquery.dataTables.css";
import HelpSupportAmraAdmin from "./SuperAdmin/AmraAdmin/HelpSupportAmraAdmin.js";
import ReplyAdminTicket from "./SuperAdmin/AmraAdmin/ReplyAdminTicket.js";
import AdminUserControl from "./SuperAdmin/AmraAdmin/AdminUserControl.js";
import HelpSupportNafiaAdmin from "./SuperAdmin/NafiaAdmin/NafiaAdminHelpSupport.js";
import ReplyNafiaAdminTicket from "./SuperAdmin/NafiaAdmin/NafiaAdminReplyToTicket.js";
import AmraAccessControl from "./SuperAdmin/AmraAdmin/AmraAccessControl.js";
import AmraAgents from "./SuperAdmin/AmraAdmin/AmraAgents.js";
import AmraAdminCustomers from "./SuperAdmin/AmraAdmin/AmraAdminCustomers.js";
import AmraUserControlUser from "./Dasboard/Componets/AmraUserControlUser.js";
import AmraAnaluticsReporting from "./SuperAdmin/AmraAdmin/AmraAnaluticsReporting.js";
import AmraAdminSettings from "./SuperAdmin/AmraAdmin/AmraAdminSettings.js";
import AmraAdminInvoice from "./SuperAdmin/AmraAdmin/AmraAdminInvoice.js";
import UserManagementAmraAdmin from "./SuperAdmin/AmraAdmin/AmraADminUsrMngmnt.js";
import NafiaAdminAccessControl from "./SuperAdmin/NafiaAdmin/NafiaAccessControl.js";
import KBManagementNafia from "./SuperAdmin/NafiaAdmin/KBManagementNafia.js";
import NafiaAdminPerformance from "./SuperAdmin/NafiaAdmin/NafiaPerformancePage.js";
import NafiaAdminClientMngt from "./SuperAdmin/NafiaAdmin/NafiaClientMngt.js";
import NafiaAdminUserMngt from "./SuperAdmin/NafiaAdmin/NafiaUserMngmt.js";

// Burjeel Pages
import HomepageBurjeel from "./BurjeelDashboard/HospitalDashboard/HomepageBurjeel.js";
import PatientProfile from "./BurjeelDashboard/HospitalDashboard/PatientProfile.js";
import CallDetailBurjeel from "./BurjeelDashboard/HospitalDashboard/CallDetailBurjeel.js";
import AppointmentDetails from "./BurjeelDashboard/HospitalDashboard/AppointmentDetails.js";
import DoctorsDetails from "./BurjeelDashboard/HospitalDashboard/DoctorsDetails.js";
import DepartmentDetails from "./BurjeelDashboard/HospitalDashboard/DepartmentDetails.js";
import AgentDetails from "./BurjeelDashboard/HospitalDashboard/AgentDetails.js";
import FaridaUserManagement from "./BurjeelDashboard/HospitalDashboard/FaridaUserManagement.js";
import FaridaHelpSupport from "./BurjeelDashboard/HospitalDashboard/FaridaHelpSupport.js";
import FaridaCreateNewTicket from "./BurjeelDashboard/HospitalDashboard/FaridaCreateTicket.js";
import FaridaReplyToTicket from "./BurjeelDashboard/HospitalDashboard/FaridaTicketReply.js";
import Hospital_Login from "./BurjeelDashboard/HospitalDashboard/Login4.js";

// Burjeel Admin
import BurjeelAdminHome from "./SuperAdmin/BurjeelAdmin/BurjeelAdmin.js";
import BurjeelAdminHelpSupport from "./SuperAdmin/BurjeelAdmin/HelpSupportBurjeelAdmin.js";
import BurjeelAdminReplyTicket from "./SuperAdmin/BurjeelAdmin/ReplyAdminTicket.js";
import BurjeelAdminCustomers from "./SuperAdmin/BurjeelAdmin/BurjeelAdminCustomers.js";
import BurjeelAdminLogin from "./SuperAdmin/BurjeelAdmin/Login6.js";
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<MainLanding />} />
          <Route path="/AmraHome" element={<Home />} />
          <Route path="/Nafia_product" element={<ProductNafia />} />
          <Route path="/company" element={<Company />} />
          <Route path="/avatar" element={<Home />} />
          <Route path="/resources" element={<Resources />} />
          <Route path="/careers" element={<Career />} />
          <Route path="/book_demo" element={<BookDemo />} />

          <Route path="/careers/vacancy_details" element={<CareerDetails />} />
          <Route path="/NafiaManitance" element={<Maintance />} />

          <Route path="/privacy_policy" element={<PrivacyPolicy />} />
          <Route path="/terms_&_conditions" element={<TermsOfUse />} />
          {/* Blog Pages */}
          <Route
            path="/Driving Efficiency and Elevated Experiences"
            element={<ShowBlog />}
          />
          <Route path="/The Perfect Blend" element={<ShowBlog2 />} />
          <Route path="/AI and Global Reach" element={<ShowBlog3 />} />
          <Route
            path="/Unleashing the Potential of Gen AI"
            element={<ShowBlog4 />}
          />
          <Route
            path="/Innovations in Debt Collection"
            element={<ShowBlog5 />}
          />
          <Route
            path="/AI Revolution in Customer Experience 2023"
            element={<ShowBlog6 />}
          />
          {/* Blog Pages */}
          <Route path="/LoadingScreen" element={<LoadingScreen />} />
          <Route path="/*" element={<Error404 />} />

          {/* Nafia */}

          <Route path="/cx-software" element={<NafiyaScreen />} />
          <Route path="/Nafia2222" element={<NafiyaScreenVideo />} />
          <Route path="/NafiaAvatarReal" element={<NafiyaScreenAvatar />} />
          <Route path="/Nafia3DAvatar" element={<NafiyaScreenUnity />} />
          <Route path="/FaqNafia" element={<NafiyaScreenFaq />} />
          <Route path="/NafiaAvatar" element={<FAQNAfia />} />
          {/* Nafia */}

          {/* Amra */}
          <Route path="/debt-collection" element={<AmraScreen />} />
          {/* Amra */}
          {/* Farida */}
          <Route path="/appointment-management" element={<FaridaScreen />} />
          {/* Farida */}

          {/*Technotask Dashboard Page*/}
          <Route path="/Dashboard" element={<TechnotaskDashboard />} />
          <Route path="/Customers" element={<Customers />} />
          <Route path="/Amra_conversations" element={<AmraConversations />} />
          <Route path="/Nafia_conversations" element={<NafiaConversations />} />
          <Route path="/Career_applications" element={<CareerView />} />
          <Route path="/Booked_demo" element={<BookDemoView />} />
          <Route path="/Subscribers" element={<NewsView />} />

          {/*Amra User Dashboard Page*/}
          <Route path="/Amra_Dashboard" element={<Dashboard />} />
          <Route
            path="/Debtorprofile/Bucket30days"
            element={<Bucket30days />}
          />
          <Route
            path="/Debtorprofile/Bucket60days"
            element={<DebtorProfile60 />}
          />
          <Route
            path="/Debtorprofile/Bucket90days"
            element={<DebtorProfile90 />}
          />
          <Route
            path="/Debtorprofile/AddDebtorForm"
            element={<AddDebtorForm />}
          />
          <Route path="/PaymentHistory" element={<PaymentHistory />} />
          <Route path="/Amra_manage_user" element={<AmraUserControlUser />} />
          <Route path="/CallDetailView" element={<CallDetailView />} />
          <Route path="/GeneratingReport" element={<GeneratingReport />} />
          <Route path="/CallData" element={<CallData />} />
          <Route path="/Career_applications" element={<CareerView />} />
          <Route path="/SettingPage" element={<SettingPage />} />
          <Route path="/Debtorprofile" element={<DebtorProfile />} />
          <Route path="/Chart" element={<Chart />} />
          <Route path="/SettingPage/Invoicepage" element={<Invoicepage />} />
          <Route
            path="/SettingPage/Usermanagement"
            element={<Usermanagement />}
          />
          <Route path="/HelpSupport1" element={<HelpSupport1 />} />
          <Route
            path="/HelpSupport1/Create_new_ticket"
            element={<CreateNewTicket />}
          />
          <Route
            path="/HelpSupport1/Reply_to_ticket/:ticketId/:ticketStatus"
            element={<ReplyToTicket />}
          />

          {/* Dashboard Login Pages */}
          <Route path="/NafiaLogin" element={<Login />} />
          <Route path="/AmraLogin" element={<Login1 />} />
          <Route path="/AmraAdminLogin" element={<Login2 />} />
          <Route path="/NafiaAdminLogin" element={<Login3 />} />

          {/*Nafia Dashboard Page*/}
          <Route path="/HomePageNafia" element={<HomePageNafia />} />
          <Route path="/KnowledgeBase" element={<KUKnowledgeBase />} />
          <Route path="/KBhealth" element={<KBhealth />} />
          <Route
            path="/NafiaGeneratingReport"
            element={<NafiaGeneratingReport />}
          />
          <Route path="/Knowledge" element={<Knowledge />} />
          <Route path="/KnowledgeAddFile" element={<KnowledgeAddFile />} />
          <Route path="/NafiaSettingPage01" element={<NafiaSettingPage01 />} />
          <Route
            path="/NafiaSettingPage01/NafiaInvoice"
            element={<NafiaInvoice />}
          />
          <Route
            path="/NafiaSettingPage01/NafiaUserManagement"
            element={<NafiaUserManagement />}
          />

          <Route path="/BillingForm" element={<BillingForm />} />
          <Route path="/NafiaHelpSupport1" element={<NafiaHelpSupport1 />} />
          <Route
            path="/NafiaHelpSupport1/NafiaCreate_new_ticket"
            element={<NafiaCreateNewTicket />}
          />
          <Route
            path="/NafiaHelpSupport1/NafiaReply_to_ticket/:ticketId/:ticketStatus"
            element={<NafiaReplyToTicket />}
          />

          {/*Nafia Super Admin Dashboard Page*/}
          <Route path="/NafiaAdmin" element={<NafiaAdmin />} />
          <Route
            path="/NafiaAdminClientManagement"
            element={<NafiaAdminClientMngt />}
          />
          <Route
            path="/NafiaAdminUserManagement"
            element={<NafiaAdminUserMngt />}
          />
          <Route
            path="/NafiaAdminAccessControl"
            element={<NafiaAdminAccessControl />}
          />
          <Route
            path="/NafiaAdminKBManagement"
            element={<KBManagementNafia />}
          />
          <Route
            path="/NafiaAdminPerformance"
            element={<NafiaAdminPerformance />}
          />
          <Route
            path="/Nafia_Admin_Ticlets"
            element={<HelpSupportNafiaAdmin />}
          />
          <Route
            path="/Nafia_Admin_Ticlets/Nafia_Admin_Ticlets_reply/:ticketId/:ticketStatus"
            element={<ReplyNafiaAdminTicket />}
          />
          <Route path="/Nafia_Agents" element={<NafiaAgents />} />
          {/*Amra Super Admin Dashboard Page*/}
          <Route path="/AmraAdmin" element={<AmraAdmin />} />
          <Route path="/Amra_Access_Control" element={<AmraAccessControl />} />
          <Route path="/Amra_Agents" element={<AmraAgents />} />
          <Route path="/Amra_Customers" element={<AmraAdminCustomers />} />
          <Route path="/AdminUserControl" element={<AdminUserControl />} />
          <Route path="/Amra_Admin_Settings" element={<AmraAdminSettings />} />
          <Route
            path="/Amra_Admin_Settings/Amra_Admin_Invoice"
            element={<AmraAdminInvoice />}
          />
          <Route
            path="/Amra_Admin_Settings/Amra_Admin_UserManagement"
            element={<UserManagementAmraAdmin />}
          />
          <Route
            path="/Amra_Analytics_Reporting"
            element={<AmraAnaluticsReporting />}
          />
          <Route path="/Admin_Ticlets" element={<HelpSupportAmraAdmin />} />
          <Route
            path="/Admin_Ticlets/Reply_Admin_Ticlets/:ticketId/:ticketStatus"
            element={<ReplyAdminTicket />}
          />

          {/* Burjeel Hospital Dashboard */}
          <Route path="/hospital_login" element={<Hospital_Login />} />
          <Route path="/burjeel_homepage" element={<HomepageBurjeel />} />
          <Route path="/PatientProfile" element={<PatientProfile />} />
          <Route path="/burjeel_callDetails" element={<CallDetailBurjeel />} />
          <Route
            path="/burjeel_appointments"
            element={<AppointmentDetails />}
          />
          <Route path="/burjeel_doctors" element={<DoctorsDetails />} />
          <Route path="/burjeel_department" element={<DepartmentDetails />} />
          <Route path="/burjeel_agents" element={<AgentDetails />} />
          <Route
            path="/burjeel_user_management"
            element={<FaridaUserManagement />}
          />
          <Route path="/burjeel_help_support" element={<FaridaHelpSupport />} />
          <Route
            path="/burjeel_help_support/burjeel_new_ticket"
            element={<FaridaCreateNewTicket />}
          />
          <Route
            path="/burjeel_help_support/burjeel_reply_ticket/:ticketId/:ticketStatus"
            element={<FaridaReplyToTicket />}
          />

          {/* Burjeel Admin Dashboard */}
          <Route path="/BurjeelAdminLogin" element={<BurjeelAdminLogin />} />
          <Route path="/burjeel_admin" element={<BurjeelAdminHome />} />
          <Route
            path="/burjeel_hospitals"
            element={<BurjeelAdminCustomers />}
          />
          <Route
            path="/burjeel_tickets"
            element={<BurjeelAdminHelpSupport />}
          />
          <Route
            path="/burjeel_tickets/admin_reply_ticket/:ticketId/:ticketStatus"
            element={<BurjeelAdminReplyTicket />}
          />

          {/* QR Screens Page */}
          <Route path="/QR" element={<QRPage />} />
          <Route path="/QRNafia" element={<QRPageNafia />} />
          <Route path="/QrDetails" element={<QRForm />} />
          <Route path="/waitForCall" element={<QRWaitPage />} />
          <Route path="/test" element={<LoadrerTechnoTask />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

//  //  "homepage": "https://www.chatnext.co/avatar/"
//  //  "homepage": "https://technotask.ai/"

export default App;
