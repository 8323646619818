import React, { useState, useEffect, useRef, useCallback } from "react";
import "../Css/NafiyaScreen.css";
import MicIcon from "../assets/images/mic.gif";
import Micload from "../assets/images/dots_mic.gif";
import LogoCom from "../assets/images/Logo.png";
import speakerGIf from "../assets/images/audioWave.gif";
import { uid } from "uid";
import {
  URL_FOR_LINK,
  THANKYOU_LINK,
  THANKYOU_MSG,
  server_post_data,
  server_get_data_master,
  URL_FOR_FAQ_SEND,
} from "../KeyConnection/KeysService"; // Adjust the path as needed
import { changelanguage } from "../CommonJquery/CommonJquery.js";
import { Unity, useUnityContext } from "react-unity-webgl";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";

import { empty_form, check_vaild_save } from "../CommonJquery/CommonJquery";
import LoadrerTechnoTask from "./LoaderAnimation";

let language_for = "en-XA"; //english
let target_language = "en-XA"; //english
let old_text = "";
// Variables for server communication and flags
let start_server = true;
let pop_up_timing = 0;
let uid_for_person = "";
let uid_for_person_final = "";
let back_flag_backend = "";
let front_website_word = "";

let flag_for_start = "15";

//timer flag
let isTimerRunning = false;
let start_audio = true;
let audio_off_final = false;

//Question for first and second
let Question_First = "";
let Question_Second = "";
let Question_Temp = "";

//Answer for first and second
let Answer_First = "";
let Answer_Second = "";
let Answer_Temp = "";
let Answer_final = "";

function NafiyaScreen() {
  const audioRef = useRef(null);

  // State variable to manage the visibility of the Loading
  const [showLoader, setshowLoader] = useState(false);
  const [showSpeaker, setshowSpeaker] = useState(false);
  const [showMic, setshowMic] = useState(false);

  const [timer, setTimer] = useState(30);

  const { finalTranscript, resetTranscript } = useSpeechRecognition();

  const { unityProvider, sendMessage, addEventListener } = useUnityContext({
    loaderUrl: "/unity_files/build.loader.js",
    dataUrl: "/unity_files/build.data",
    frameworkUrl: "/unity_files/build.framework.js",
    codeUrl: "/unity_files/build.wasm",
    webglContextAttributes: {
      preserveDrawingBuffer: true,
    },
  });

  //form data

  const [userForm, setuserForm] = useState(true);
  const [tokenno, settokenno] = useState("0");
  const [showLoaderWebsite, setshowLoaderWebsite] = useState(false);
  //form data

  useEffect(() => {
    let interval;
    if (isTimerRunning) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
        console.log(timer);
      }, 1000);
    } else {
      clearInterval(interval);
    }
    //unity function
    addEventListener("GameOver", handleEndAudiounity);

    // Check if the timer has reached 0
    if (timer < 2 && isTimerRunning) {
      handleTimerComplete();
    }
    return () => {
      clearInterval(interval);
    };
  }, [timer, isTimerRunning, showLoader, showSpeaker, showMic, userForm]);

  const first_time_function = (call_data) => {
    if (call_data === "") {
      if (uid_for_person_final === "") {
        uid_for_person = uid(25);
      } else {
        uid_for_person = uid_for_person_final;
      }
      end_audio_video_link(false);
    }
  };
  // Function to start the timer
  const startTimer = () => {
    isTimerRunning = true;
    setTimer(30); // Reset the timer to 30 seconds when starting
  };

  // Function to perform an action when the timer completes
  const handleTimerComplete = () => {
    isTimerRunning = false;
    flag_for_start = "50";
    // Do something when the timer completes
    console.log("stop complete");
    start_audio = true;
    endAudio(THANKYOU_LINK, THANKYOU_MSG, true);
  };

  const webkitSpeechRecognition = () => {
    isTimerRunning = true;
    SpeechRecognition.startListening({
      continuous: true,
      language: target_language,
    });
  };

  const stopwebkitSpeechRecognition = () => {
    isTimerRunning = false;
    SpeechRecognition.stopListening();
    resetTranscript();
    clearTimeout(null); // Clear the timeout if recognition is manually stopped
  };

  // Function to start speech recognition and handle user input
  const startSpeechRecognition = (word) => {
    if (word !== "") {
      let word_correct = false;
      let website_vaild = true;
      if (flag_for_start === "14" || flag_for_start === "15") {
        // Handle other cases where word_correct is set to true based on conditions
        if (word !== "" && old_text !== word) {
          word_correct = true;
        }
      }

      if (word_correct && website_vaild) {
        old_text = word;
        conversationalTalk(word, flag_for_start);
      }
    }
  };

  //when audio video middleplay play middle script data
  const middle_audio_video_link = (
    currentAudioTime,
    audioDuration,
    finish_loop
  ) => {
    // Calculate the delay to execute the code block 3 seconds before the audio ends
    let delay = Number(pop_up_timing);
    if (delay === 0) {
      delay = Math.max(0, audioDuration - 7);
    }
    if ((currentAudioTime > delay || delay < 1) && finish_loop) {
      finish_loop = false;
    }
  };

  //when audio video end play end script data
  const end_audio_video_link = (end_audio) => {
    // When audio ends, set someone_speaking to true and enable speech recognition
    start_server = true;
    if (end_audio) {
      window.location.reload();
    } else {
      show_loader_mic_speaker(false, true, false);
    }
  };

  // Function to handle user input and start server communication
  const question_histry = (userMessage_send) => {
    let send_data = "0~@~0";
    if (Question_First === "") {
      Question_First = userMessage_send;
    } else if (Question_Second === "") {
      Question_Second = userMessage_send;
    } else {
      Question_Temp = Question_Second;
      Question_First = Question_Temp;
      Question_Second = userMessage_send;
    }
    send_data = Question_First + "~@~" + Question_Second;
    return send_data;
  };

  // Function to handle user input and start server communication
  const answer_histry = (userMessage_send) => {
    let send_data = "0~@~0";
    if (Answer_First === "") {
      Answer_First = userMessage_send;
    } else if (Answer_Second === "") {
      Answer_Second = userMessage_send;
    } else {
      Answer_Temp = Answer_Second;
      Answer_First = Answer_Temp;
      Answer_Second = userMessage_send;
    }
    send_data = Answer_First + "~@~" + Answer_Second;
    return send_data;
  };

  // Function to handle user input and start server communication
  const conversationalTalk = (userMessage, flag) => {
    stopwebkitSpeechRecognition();

    if (start_server) {
      //loader,mic,specker
      show_loader_mic_speaker(true, false, false);
      start_server = false;

      let question_bank = question_histry(userMessage);
      let splitQuestions = question_bank.split("~@~");

      // Send a request to the server with user input
      let lauage_word = changelanguage(language_for);
      let lauage_target = changelanguage(target_language);
      const body_data = JSON.stringify({
        content_txt: userMessage,
        language_used: lauage_word,
        content_language: lauage_target,
        uid_id: uid_for_person,
        front_website: front_website_word,
        question_1: splitQuestions[0],
        question_2: splitQuestions[1],
        answer_1: Answer_final[0],
        answer_2: Answer_final[1],
        front_flag: flag,
      });

      console.log(body_data);
      server_post_data(URL_FOR_LINK, body_data)
        .then((response) => response.json())
        .then((data) => {
          //loader,mic,specker
          show_loader_mic_speaker(false, false, true);
          server_get_data("normal", data);
        })
        .catch((error) => {
          console.error("Error:", error);
          start_server = true;
        });
    }
  };

  const show_loader_mic_speaker = (for_loader, for_mic, for_speaker) => {
    setshowLoader(for_loader);
    setshowMic(for_mic);
    setshowSpeaker(for_speaker);

    if (for_mic === true) {
      startTimer();
      webkitSpeechRecognition();
    }
  };

  // Function to handle server response
  const server_get_data = (where_from, data) => {
    //technotask
    console.log(where_from, data);
    pop_up_timing = 0;
    flag_for_start = "150";
    if (data.for_front_flag) {
      flag_for_start = data.for_front_flag;
    }
    let answer_bank = answer_histry(data.speak_txt);
    Answer_final = answer_bank.split("~@~");

    back_flag_backend = data.back_flag;
    front_website_word = data.front_website;
    let audio_file = data.file_path;
    let audio_text = data.speak_txt;
    audio_off_final = false;

    if (back_flag_backend === "50") {
      //stop the converstion
      audio_off_final = true;
      audio_file = THANKYOU_LINK;
    }
    start_audio = true;
    endAudio(audio_file, audio_text, audio_off_final);
  };

  //shubham jain  code

  let currentAudioTime = 0;
  let audioDuration = 0;
  let timerInterval;
  // Function to play audio and trigger speech recognition when it ends
  const endAudio = async (musicLink, music_text, end_audio) => {
    if (start_audio) {
      start_audio = false;
      audioDuration = 0;
      if (end_audio === false) {
        try {
          audioRef.current.src = musicLink;
          await audioRef.current.addEventListener("canplaythrough", () => {
            audioDuration = audioRef.current.duration;
            currentAudioTime = audioRef.current.duration;
            sendMessage("PlayAudio", "PlayMyAudio", musicLink);
            handleTimer();
          });
        } catch {
          //error
          sendMessage("PlayAudio", "PlayMyAudio", musicLink);
          handleTimer();
        }
      }
    }
  };

  const handleTimer = () => {
    clearInterval(timerInterval); // Clear any existing interval to prevent overlapping intervals

    timerInterval = setInterval(() => {
      if (currentAudioTime < 1) {
        clearInterval(timerInterval); // Stop the interval when the timer reaches 0
        console.log("Final function and exit.");
        return;
        // Call your final function or perform other exit actions here
      } else {
        console.log(currentAudioTime, audioDuration, true);
        middle_audio_video_link(currentAudioTime, audioDuration, true);
        currentAudioTime--;
      }
    }, 1000); // Update the timer every 1 second
  };

  //unity function
  // Define an event listener function
  const handleEndAudiounity = useCallback((userName, score) => {
    console.log(userName);
    console.log(score);
    end_audio_video_link(audio_off_final);
  }, []);
  //unity function

  //form for company

  const [showLoaderTechnotask, setshowLoaderTechnotask] = useState(false);

  // Use useEffect to hide the loader after 5 seconds
  function setShowLoaderTechnotaskFuc() {
    setshowLoaderTechnotask(true);
    const timer = setTimeout(() => {
      setshowLoaderTechnotask(false);
    }, 7000);
    return () => clearTimeout(timer);
  }

  const handleSaveUserData = async (form_data, url_for_save) => {
    let vaild_data = check_vaild_save(form_data);
    setShowLoaderTechnotaskFuc();
    if (vaild_data) {
      let url_send = url_for_save + tokenno;
      await server_get_data_master(url_send, null)
        .then((response) => response.json())
        .then((data) => {
          setshowLoaderWebsite(false);
          console.log(data);
          if (data.status === "error") {
            alert(data.data);
          } else {
            uid_for_person_final = tokenno;
            empty_form(form_data);
            setuserForm(false);
            first_time_function("");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  //form for company

  return (
    <div className="screen_container">
      <div className="screen_wrapper col-lg-10 mx-auto">
        <div className="policies_screen col-lg-10 mx-auto position-">
          {/** data */}
          {userForm && (
            <div className="qrsrceen ">
              <div className="comapnyLogo1 col-xl-3 col-md-3 col-12 mx-auto">
                <img src={LogoCom} alt="technotask" />
              </div>
              <div className="fillInfoHead fillInfoHead2 col-md-5 col-sm-5">
                <h6>
                  Please fill the Token No and details about you want to ask to
                  Nafia.
                </h6>
              </div>
              <div className="QRFormDiv QRFaqDiv my-4 p-0">
                <form id="QRFaqFormData">
                  <div className="QRInputS">
                    <div className="QRInput QRInputFAQ">
                      <label htmlFor="company_name">Token No</label>
                      <input
                        type="number"
                        id="token_no"
                        name="token_no"
                        placeholder="Token No"
                        value={tokenno}
                        onChange={(e) => settokenno(e.target.value)}
                        className="trio_mendate"
                      />
                    </div>
                  </div>
                </form>

                <div className="SaveBtn">
                  <button
                    type="button"
                    onClick={() =>
                      handleSaveUserData("QRFaqFormData", URL_FOR_FAQ_SEND)
                    }
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          )}
          {/** data */}
          {showLoaderTechnotask && (
            <div className="loadeTechno">
              <LoadrerTechnoTask />
            </div>
          )}
          {!userForm && (
            <div className="policies_screen_row row m-0">
              <div className="nafiaImg col-lg-4 col-md-5 col-sm-5 col-8 m-auto-left-right-bottom">
                <Unity
                  unityProvider={unityProvider}
                  style={{ display: "block" }}
                />
              </div>
              <div hidden>
                <audio
                  ref={audioRef}
                  // autoPlay
                  controls
                >
                  <source type="audio/mpeg" />
                  Your browser does not support the audio element.
                </audio>
                <div hidden>{startSpeechRecognition(finalTranscript)}</div>
              </div>
              <div className={`loader_speaker ${showSpeaker ? "loading" : ""}`}>
                <img
                  src={speakerGIf}
                  className="speaker_load_image left_icon_show"
                  alt="nafia"
                />
              </div>
              <div
                className={`loader_mic_load left_icon_show ${
                  showLoader ? "loading" : ""
                }`}
              >
                <img src={Micload} className="mic_load_image" alt="nafia" />
              </div>
              <div
                className={`loader_mic left_icon_show ${
                  showMic ? "loading" : ""
                }`}
              >
                <img src={MicIcon} className="mic_image " alt="nafia" />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default NafiyaScreen;
