import React from "react";
import "../../Dasboard/Css/SideBar.css";
import Dashboardwhite from "../../assets/images/dashboard.svg";
import DashboardBlack from "../../assets/images/dashboard12.svg";
import ChatIcon1 from "../../assets/images/CursorClick.png";
import ChatIcon2 from "../../assets/images/CursorClick.png";
import file from "../../assets/images/AFile.png";
import fileWhite from "../../assets/images/AFile.png";
import Logo from "../../assets/images/logoNew.png";
import UserWhite from "../../assets/images/ChartBar.png";
import User from "../../assets/images/ChartBar.png";
import Knowledge from "../../assets/images/Knowledge.png";
import KnowledgeWhite from "../../assets/images/Knowledge.png";
import callcenter from "../../assets/images/Group 217.png";
import callcenterwhite from "../../assets/images/Group 217.png";
import Layes from "../../assets/images/Layes.png";
import Layeswhite from "../../assets/images/Layes.png";
import Cube from "../../assets/images/Cube.png";
import Cubewhite from "../../assets/images/Cube.png";
import gear from "../../assets/images/Gear.png";
import gearWhite from "../../assets/images/Gear.png";
import SignOut from "../../assets/images/SignOut.png";
import SignOutWhite from "../../assets/images/SignOut.png";
import HelpImg from "../../assets/images/Vector.svg";
import HelpImgWhite from "../../assets/images/helpWhite.svg";
import eyeBlack from "../../assets/images/AmrUser.png";
import eyewhite from "../../assets/images/Userwhitenew.svg";
import { Link, useLocation } from "react-router-dom";

function NAfiaAdminSideBar() {
  const location = useLocation();
  return (
    <div className="sidebar">
      <div className="sidebar_container">
        <nav id="sidebar">
          <div className="sidebar-header sideBarHead">
            <div className="new_chat_btn new_chat_btn1">
              <img src={Logo} alt="Logo" />
            </div>
          </div>
          <div className="px-2">
            <hr
              style={{
                borderTop: "1px solid #F1F1F4",
                width: "100%",
                height: "2px",
                marginTop: "0px",
              }}
            />
          </div>
          <div className="sidebar-body">
            <div className="sidebar-body-container">
              <div className="mainMenu mt-3">
                <div className="mainMenuContainer mainMenuContainer1">
                  <ul>
                    <li className="mainMenuItem2">
                      <Link to="/NafiaAdmin">
                        <div
                          className={`mainMenuItem  ${
                            location.pathname === "/NafiaAdmin"
                              ? "activemainMenuItem"
                              : ""
                          }`}
                        >
                          <img
                            src={
                              location.pathname === "/NafiaAdmin"
                                ? Dashboardwhite
                                : DashboardBlack
                            }
                            alt="icon"
                          />
                          <p>Overview</p>
                        </div>
                      </Link>
                    </li>
                  </ul>

                  <ul>
                    <h6>ANALYTICS</h6>
                    <li className="mainMenuItem2">
                      <Link to="/NafiaAdminClientManagement">
                        <div
                          className={`mainMenuItem ${
                            location.pathname === "/NafiaAdminClientManagement"
                              ? "activemainMenuItem"
                              : ""
                          }`}
                        >
                          <img
                            src={
                              location.pathname ===
                              "/NafiaAdminClientManagement"
                                ? UserWhite
                                : User
                            }
                            alt="icon"
                          />
                          <p>Client Management</p>
                        </div>
                      </Link>
                    </li>
                    <li className="mainMenuItem2">
                      <Link to="/NafiaAdminAccessControl">
                        <div
                          className={`mainMenuItem  ${
                            location.pathname === "/NafiaAdminAccessControl"
                              ? "activemainMenuItem"
                              : ""
                          }`}
                        >
                          <img
                            src={
                              location.pathname === "/NafiaAdminAccessControl"
                                ? ChatIcon2
                                : ChatIcon1
                            }
                            alt="icon"
                          />
                          <p>
                            Access Controls{" "}
                            <span
                              className={`postMvp ${
                                location.pathname === "/NafiaAdminAccessControl"
                                  ? "postMvpWhite"
                                  : " "
                              }`}
                            >
                              Post MVP
                            </span>
                          </p>
                        </div>
                      </Link>
                    </li>
                  </ul>

                  <div className="px-2">
                    <hr
                      style={{
                        borderTop: "1px solid #F1F1F4",
                        width: "100%",
                        height: "2px",
                      }}
                    />
                  </div>

                  <ul>
                    <h6>SUPPORT</h6>
                    <li className="mainMenuItem2">
                      <Link to="/NafiaAdminKBManagement">
                        <div
                          className={`mainMenuItem  ${
                            location.pathname === "/NafiaAdminKBManagement"
                              ? "activemainMenuItem"
                              : ""
                          }`}
                        >
                          <img
                            src={
                              location.pathname === "/NafiaAdminKBManagement"
                                ? Knowledge
                                : KnowledgeWhite
                            }
                            alt="icon"
                          />
                          <p>Knowledge Base Man.</p>
                        </div>
                      </Link>
                    </li>
                    <li className="mainMenuItem2">
                      <Link to="/NafiaAdminPerformance">
                        <div
                          className={`mainMenuItem  ${
                            location.pathname === "/NafiaAdminPerformance"
                              ? "activemainMenuItem"
                              : ""
                          }`}
                        >
                          <img
                            src={
                              location.pathname === "/NafiaAdminPerformance"
                                ? callcenter
                                : callcenterwhite
                            }
                            alt="icon"
                          />
                          <p>
                            Performance{" "}
                            <span
                              className={`postMvp ${
                                location.pathname === "/NafiaAdminPerformance"
                                  ? "postMvpWhite"
                                  : " "
                              }`}
                            >
                              Post MVP
                            </span>
                          </p>
                        </div>
                      </Link>
                    </li>
                    <li className="mainMenuItem2">
                      <Link to="/NafiaAdminUserManagement">
                        <div
                          className={`mainMenuItem ${
                            location.pathname === "/NafiaAdminUserManagement"
                              ? "activemainMenuItem"
                              : ""
                          }`}
                        >
                          <img
                            src={
                              location.pathname === "/NafiaAdminUserManagement"
                                ? UserWhite
                                : User
                            }
                            alt="icon"
                          />
                          <p>User Management</p>
                        </div>
                      </Link>
                    </li>
                    <li className="mainMenuItem2">
                      <Link to="/Nafia_Agents">
                        <div
                          className={`mainMenuItem  ${
                            location.pathname === "/Nafia_Agents"
                              ? "activemainMenuItem"
                              : ""
                          }`}
                        >
                          <img
                            src={
                              location.pathname === "/Nafia_Agents"
                                ? eyewhite
                                : eyeBlack
                            }
                            alt="icon"
                          />
                          <p>Agents</p>
                        </div>
                      </Link>
                    </li>
                    <div className="px-2">
                      <hr
                        style={{
                          borderTop: "1px solid #F1F1F4",
                          width: "100%",
                          height: "2px",
                        }}
                      />
                    </div>
                    <li className="mainMenuItem2">
                      <Link>
                        <div
                          className={`mainMenuItem  ${
                            location.pathname === "/Subscribers"
                              ? "activemainMenuItem"
                              : ""
                          }`}
                        >
                          <img
                            src={
                              location.pathname === "/Subscribers"
                                ? fileWhite
                                : file
                            }
                            alt="icon"
                          />
                          <p>
                            Reports <span className="postMvp">Post MVP</span>
                          </p>
                        </div>
                      </Link>
                    </li>
                    <li className="mainMenuItem2">
                      <Link>
                        <div
                          className={`mainMenuItem  ${
                            location.pathname === "/Subscribers"
                              ? "activemainMenuItem"
                              : ""
                          }`}
                        >
                          <img
                            src={
                              location.pathname === "/Subscribers"
                                ? gearWhite
                                : gear
                            }
                            alt="icon"
                          />
                          <p>Settings</p>
                          <span className="postMvp">Post MVP</span>
                        </div>
                      </Link>
                    </li>
                    <li className="mainMenuItem2">
                      <Link to="/Nafia_Admin_Ticlets">
                        <div
                          className={`mainMenuItem  ${
                            location.pathname === "/Nafia_Admin_Ticlets" ||
                            location.pathname ===
                              "/Nafia_Admin_Ticlets/Nafia_Admin_Ticlets_reply"
                              ? "activemainMenuItem"
                              : ""
                          }`}
                        >
                          <img
                            src={
                              location.pathname === "/Nafia_Admin_Ticlets" ||
                              location.pathname ===
                                "/Nafia_Admin_Ticlets/Nafia_Admin_Ticlets_reply"
                                ? HelpImgWhite
                                : HelpImg
                            }
                            alt="icon"
                          />
                          <p>Help & Support</p>
                        </div>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
}

export default NAfiaAdminSideBar;
