import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import CenterNavAmraAdmin from "./CenterNavAmraAdmin";
import NAfiaAdminSideBar from "./AmraAdminSideBar";
import $ from "jquery";
import { Modal } from "react-bootstrap";
import {
  server_post_data_shubham,
  AMRA_CUSTOMERS_DATA,
  AMRA_DEBTOR_DATA_AGGREGATE,
  AMRA_DEBTOR_DATA_DETAILS,
  server_post_data,
  AgentList,
} from "../../KeyConnection/KeysService";
import moment from "moment";
import "moment/locale/en-au";

function AmraAnaluticsReporting() {
  const [showModal, setShowModal] = useState(false);

  const company_id = localStorage.getItem("company_id");
  const controlOptions = [
    { name: "Admin", code: "Admin" },
    { name: "Super Admin", code: "Super Admin" },
    { name: "Sub Admin", code: "Sub Admin" },
    { name: "Manager", code: "Manager" },
  ];

  const [AmraadminData, setdataFromAmra] = useState([
    {
      company_name: "",
      customer: "",
      customer_id: "",
      plan: "",
      license: "",
      avg_call_Dur: "",
      boarding_date: "",
      comission: "",
      total_collection: "",
      bucket_30: "",
      bucket_60: "",
      bucket_90: "",
      l2_esc: "",
    },
  ]);

  const fetchAmraAdminDashboardData = async (dateRange) => {
    try {
      const response1 = await fetch(AMRA_CUSTOMERS_DATA, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const list = await response1.json();
      const companiesList = list.customer_list;
      let arr = [];
      let subscriptionPlanArr = ["Basic", "Standard", "Premium"];
      companiesList.forEach(async (company) => {
        let onboarding_date = new Date(company.created_at);

        let obj = {
          company_name: company.company_name,
          customer: "Mr.Shekh Sameer", // to be decided
          customer_id: `#${company.id}`,
          plan: subscriptionPlanArr[company.subscription_plan - 1], // to be decided
          license: 0,
          avg_call_Dur: 0, // to be decided
          boarding_date: onboarding_date.toLocaleDateString("en-GB"),
          comission: 0,
          total_collection: 0,
          bucket_30: 0,
          bucket_60: 0,
          bucket_90: 0,
          l2_esc: 0,
        };

        console.log(company);
        let url_debtor_detail = `${AMRA_DEBTOR_DATA_DETAILS}${company.mobile_no}/`;
        const response1 = await fetch(url_debtor_detail, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });

        const data = await response1.json();
        const debtors = data.debtors;

        debtors.forEach((debtor) => {
          let now = Date.now();
          let dueDate = new Date(debtor.due_date);
          if (debtor.last_payment_date) {
            dueDate = new Date(debtor.last_payment_date);
          }
          let days = Math.ceil((now - dueDate) / (1000 * 60 * 60 * 24));
          let bucket = "";
          if (days > 0 && days <= 30) {
            bucket = "bucket_30";
          } else if (days > 30 && days <= 60) {
            bucket = "bucket_60";
          } else if (days > 60) {
            bucket = "bucket_90";
          }
          debtor.debtor_history.forEach((instance) => {
            obj.total_collection += Number(instance.recovery_amount);
            obj.comission += Number(instance.admin_commission);
            if (instance.call_flag === 6) {
              obj.l2_esc += 1;
            }
            obj[bucket] += 1;
          });
        });
        let url_agent_detail = AgentList(company.id);
        const response2 = await fetch(url_agent_detail, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        const agent = await response2.json();

        obj.license = agent.data.length;
        arr.push(obj);
        setdataFromAmra([...arr]);
      });
    } catch (err) {
      console.log("Company not Found");
    }
  };

  useEffect(() => {
    $("#myTable").DataTable();

    const startDate = moment().subtract(365, "days").startOf("day");
    const endDate = moment().add(2, "days").endOf("day");
    let dates = {
      startDate: startDate.format("YYYY-MM-DD").toString(),
      endDate: endDate.format("YYYY-MM-DD").toString(),
    };
    fetchAmraAdminDashboardData(dates);
  }, []);

  return (
    <div className="dashboard">
      <Helmet>
        <title>Amra Dashboard</title>
        <meta
          name="description"
          content="AI and Global Reach: The Key to Scalable Solutions in Customer
          Experience"
        />
      </Helmet>
      <div className="dashboard_container">
        <div className="dashboard_wrapper">
          <NAfiaAdminSideBar />
          <div id="content">
            <CenterNavAmraAdmin />

            <div className="Dashpages_contaioner">
              <div className="overview">
                <div className="overviewContaINER">
                  <div className="amradataTable">
                    <div className="amradataTable_container">
                      <h4></h4>
                      <table id="myTable" className="display">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Company/Customer Name</th>
                            <th>
                              Avg. Call Duration
                              <br />
                              <span className="postMvp">Post MVP</span>
                            </th>
                            <th>Date of Onboarding</th>
                            <th>Comission</th>
                            <th>Total Collection</th>
                            <th>Bucket Calls</th>
                            <th>L2 Escalation Calls</th>
                          </tr>
                        </thead>
                        <tbody>
                          {AmraadminData.map((item, index) => (
                            <tr key={index}>
                              <td>
                                <div className="tableData1 tableData21">
                                  <p style={{ color: "#000" }}>{index + 1}</p>
                                </div>
                              </td>
                              <td>
                                <div className="tableData1 tableData21">
                                  <p style={{ color: "#000" }}>
                                    {item.company_name}
                                    <br />
                                    {item.customer}
                                    <br />
                                    {item.customer_id}&nbsp;
                                    <span className="planTetx">
                                      {item.plan}
                                    </span>
                                    &nbsp;
                                    <span style={{ fontSize: "10px" }}>
                                      {item.license} Licence
                                    </span>
                                  </p>
                                </div>
                              </td>
                              <td>
                                <div className="tableData1 tableData21">
                                  <p style={{ color: "#000" }}>
                                    {item.avg_call_Dur}
                                  </p>
                                </div>
                              </td>
                              <td>
                                <div className="tableData1 tableData21 h-100">
                                  <p style={{ color: "#000" }}>
                                    {item.boarding_date}
                                  </p>
                                </div>
                              </td>
                              <td>
                                <div className="tableData1 tableData21">
                                  <td style={{ color: "#000" }}>
                                    ${item.comission}
                                  </td>
                                </div>
                              </td>
                              <td>
                                <div className="tableData1 tableData21">
                                  <p style={{ color: "#000" }}>
                                    ${item.total_collection}
                                  </p>
                                </div>
                              </td>
                              <td>
                                <div className="tableData1 tableData21">
                                  <p style={{ color: "#000" }}>
                                    <span style={{ whiteSpace: "nowrap" }}>
                                      30 Days: {item.bucket_30}
                                    </span>
                                    <br />
                                    <span style={{ whiteSpace: "nowrap" }}>
                                      60 Days: {item.bucket_60}
                                    </span>
                                    <br />
                                    <span style={{ whiteSpace: "nowrap" }}>
                                      90 Days: {item.bucket_90}
                                    </span>
                                  </p>
                                </div>
                              </td>
                              <td>
                                <div className="tableData1 tableData21">
                                  <p style={{ color: "#000" }}>{item.l2_esc}</p>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AmraAnaluticsReporting;
