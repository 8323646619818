import React from "react";
import "../Css/LoadingScren.css"

// Define your random function (outside of the component)
const texts = ['T', 'r', 'i', 'o', 's', 'o', 'f', 't'];
const number_of_particle = 12;

const Particle = ({ text, particleClass }) => (
  <div className={`particle ${particleClass}`}>{text}</div>
);

// const random = (min, max) => Math.floor(Math.random() * (max - min + 1) + min);

const LoadingScreen = () => {
  const _number_of_text = 9;
  const _number_of_particle = 12;
  const _mult_num_text = 360 / _number_of_text;
  const _mult_num_particle = 360 / _number_of_particle;
  const _width = 40;
  const _height = 40;

  // Define inline styles for your HTML and body elements
  const containerStyles = {
    height: "100vh",
    width: "100vw",
  };

  const bodyStyles = {
    fontFamily: "Montserrat, sans-serif",
    background: "#FFF",
    position: "relative",
    overflow: "hidden",
    fontSize: "100%",
    textAlign: "center",
  };

  // Define inline styles for .criterion
  const criterionStyles = {
    fontSize: "1.6rem",
    position: "absolute",
    top: "50%",
    left: "50%",
    height: "0",
    width: "0",
    transform: `translate(-${_width / 2}px, -${_height / 2}px)`,
  };

  // Define inline styles for .background and keyframes
  const backgroundStyles = {
    position: "absolute",
    top: "0",
    height: "100vh",
    width: "0",
    animation: "background-animation 2s ease-in-out 4s 1 normal forwards",
  };

  const generateBackgroundStyles = () => {
    const backgroundStylesArray = [];
    for (let i = 0; i <= _number_of_text; i++) {
      const left = `calc(12.5% * ${i})`;
      const backgroundColor = `hsl(${i * _mult_num_text}, 80%, 60%)`;
      const style = {
        left,
        height: "100vh",
        backgroundColor,
      };
      backgroundStylesArray.push(style);
    }
    return backgroundStylesArray;
  };

  // Define keyframes for background animation
  const keyframesBackgroundAnimation = {
    "0%": { width: "0" },
    "50%": { width: "12.5%", opacity: "1" },
    "100%": { opacity: "0", width: "25%" },
  };

  // Define inline styles for .text and keyframes
  const textStyles = {
    position: "absolute",
    width: `${_width}px`,
    lineHeight: `${_height}px`,
    opacity: "0",
    overflow: "hidden",
  };

  const generateTextStyles = () => {
    const textStylesArray = [];
    for (let i = 0; i <= _number_of_text; i++) {
      const left = `calc(-${_number_of_text / 2 - i} * ${_width}px + ${
        _width / 2
      }px)`;
      const style = {
        left,
        top: "0",
        animation: `text-animation-${i} 1s ease-in-out 1s ${
          i * 200
        }ms 1 normal forwards, text2-animation-${i} 2s ease-in-out 5s 1 normal forwards`,
      };
      textStylesArray.push(style);
    }
    return textStylesArray;
  };

  const generateTextAfterStyles = () => {
    const textAfterStylesArray = [];
    for (let i = 0; i <= _number_of_text; i++) {
      const backgroundColor = `hsl(${i * _mult_num_text}, 80%, 60%)`;
      const style = {
        width: "0px",
        backgroundColor,
        opacity: "1",
      };
      textAfterStylesArray.push(style);
    }
    return textAfterStylesArray;
  };

  const generateText2AnimationKeyframes = () => {
    const keyframesText2Animation = {};
    for (let i = 0; i <= _number_of_text; i++) {
      if (i !== _number_of_text - 1) {
        keyframesText2Animation[`0%-${i}`] = {
          left: `calc(-${_number_of_text / 2 - i} * ${_width}px + ${
            _width / 2
          }px)`,
          opacity: "1",
        };
        keyframesText2Animation[`50%-${i}`] = {
          left: `calc(-${_number_of_text / 2 - i - 1} * ${_width}px + ${
            _width / 2
          }px)`,
          opacity: "0",
        };
        keyframesText2Animation[`100%-${i}`] = {
          left: `calc(-${_number_of_text / 2 - i - 1} * ${_width}px + ${
            _width / 2
          }px)`,
          opacity: "0",
        };
      } else {
        keyframesText2Animation[`0%-${i}`] = {
          left: `calc(-${_number_of_text / 2 - i} * ${_width}px + ${
            _width / 2
          }px)`,
          opacity: "1",
          top: "0",
          transform: "scale(1, 1)",
        };
        keyframesText2Animation[`50%-${i}`] = {
          left: `calc(-${_number_of_text / 2 - i - 1} * ${_width}px + ${
            _width / 2
          }px)`,
          opacity: "1",
          transform: "scale(1, 1)",
        };
        keyframesText2Animation[`65%-${i}`] = {
          top: "0",
          transform: "scale(1, 1)",
        };
        keyframesText2Animation[`70%-${i}`] = {
          transform: "scale(3, 3) rotate(90deg)",
          top: "-30px",
        };
        keyframesText2Animation[`75%-${i}`] = {
          left: `calc(-${_number_of_text / 2 - i - 1} * ${_width}px + ${
            _width / 2
          }px)`,
          top: "0",
          opacity: "1",
          transform: "scale(2, 2) rotate(90deg)",
        };
        keyframesText2Animation[`85%-${i}`] = {
          left: `calc(-${_number_of_text / 2 - i - 1} * ${_width}px + ${
            _width / 2
          }px)`,
        };
        keyframesText2Animation[`100%-${i}`] = {
          left: "1000px",
          opacity: "0",
          transform: "scale(2, 2) rotate(90deg)",
        };
      }
    }
    return keyframesText2Animation;
  };

  // Define inline styles for .frame and keyframes
  const frameStyles = {
    position: "absolute",
    width: `${_width}px`,
    height: `${_height}px`,
    borderRadius: "50%",
    opacity: "0",
  };

  const generateFrameStyles = () => {
    const frameStylesArray = [];
    for (let i = 0; i <= _number_of_text; i++) {
      const left = `calc(-${_number_of_text / 2 - i} * ${_width}px + ${
        _width / 2
      }px)`;
      const backgroundColor = `hsl(${i * _mult_num_text}, 80%, 60%)`;
      const style = {
        left,
        top: "0",
        animation: `frame-animation-${i} 1s ease-in-out ${
          i * 200
        }ms 1 normal forwards`,
        backgroundColor,
      };
      frameStylesArray.push(style);
    }
    return frameStylesArray;
  };

  const generateFrameAnimationKeyframes = () => {
    const keyframesFrameAnimation = {};
    for (let i = 0; i <= _number_of_text; i++) {
      keyframesFrameAnimation[`0%-${i}`] = {
        transform: "translateY(-1000px)",
        opacity: "1",
      };
      keyframesFrameAnimation[`50%-${i}`] = {
        opacity: "0.8",
      };
      keyframesFrameAnimation[`100%-${i}`] = {
        transform: "translateY(0)",
        opacity: "0",
      };
    }
    return keyframesFrameAnimation;
  };

  // Define inline styles for .particle and keyframes
  const particleStyles = {
    position: "absolute",
    width: `${_width}px`,
    height: `${_height}px`,
    borderRadius: "50%",
  };

  const generateParticleStyles = () => {
    const particleStylesArray = [];
    for (let i = 0; i <= _number_of_text; i++) {
      for (let j = 0; j <= _number_of_particle; j++) {
        const left = `calc(-${_number_of_text / 2 - i} * ${_width}px + ${
          _width / 2
        }px)`;
        const top = `${
          Math.sin(j * _mult_num_particle * (Math.PI / 180)) * 100
        }px`;
        const backgroundColor = `hsl(${i * _mult_num_text}, 80%, 60%)`;
        const style = {
          left,
          top,
          opacity: "0",
          animation: `particle-animation-${i}-${j} 1s ease-in-out 1s ${
            i * 200
          }ms 1 normal forwards`,
          backgroundColor,
        };
        particleStylesArray.push(style);
      }
    }
    return particleStylesArray;
  };

  const generateParticleAnimationKeyframes = () => {
    const keyframesParticleAnimation = {};
    for (let i = 0; i <= _number_of_text; i++) {
      for (let j = 0; j <= _number_of_particle; j++) {
        keyframesParticleAnimation[`0%-${i}-${j}`] = {
          left: `calc(-${_number_of_text / 2 - i} * ${_width}px + ${
            _width / 2
          }px)`,
          top: "0",
          opacity: "0",
          transform: "scale(1, 1)",
        };
        keyframesParticleAnimation[`100%-${i}-${j}`] = {
          left: `calc(-${_number_of_text / 2 - i} * ${_width}px + ${
            _width / 2
          }px)`,
          top: `${Math.sin(j * _mult_num_particle * (Math.PI / 180)) * 100}px`,
          opacity: "1",
          transform: "scale(0, 0)",
        };
      }
    }
    return keyframesParticleAnimation;
  };

  const backgroundElements = texts.map((text, index) => (
    <div key={`background${index}`} className={`background${index}`}>
      <div className="criterion">
        <div className={`text text${index}`}>{text}</div>
        <div className={`frame frame${index}`} />
        <div className="particles">
          {Array.from({ length: number_of_particle }, (_, j) => (
            <Particle
              key={`particle${index}${j}`}
              text={text}
              particleClass={`particle${index}${j}`}
            />
          ))}
        </div>
      </div>
    </div>
  ));

  return (
    <>
      <div style={containerStyles}>
        <body style={bodyStyles}>
          <div className="criterion" style={criterionStyles}>
            {/* Criterion Content */}
          </div>
          <div className="background" style={backgroundStyles}>
            {Array.from({ length: _number_of_text + 1 }, (_, i) => (
              <div
                key={i}
                className={`background${i}`}
                style={generateBackgroundStyles()[i]}
              />
            ))}
          </div>
          <style>
            {Object.keys(keyframesBackgroundAnimation).map(
              (key) =>
                `@keyframes background-animation {
              ${key} {
                ${Object.entries(keyframesBackgroundAnimation[key])
                  .map(([prop, value]) => `${prop}: ${value};`)
                  .join("\n")}
              }
            }`
            )}
          </style>
          <div className="text" style={textStyles}>
            {Array.from({ length: _number_of_text + 1 }, (_, i) => (
              <div
                key={i}
                className={`text${i}`}
                style={generateTextStyles()[i]}
              >
                {/* Text Content */}
              </div>
            ))}
          </div>
          <style>
            {Object.keys(generateText2AnimationKeyframes()).map(
              (key) =>
                `@keyframes text2-animation-${key} {
              ${key} {
                ${Object.entries(generateText2AnimationKeyframes()[key])
                  .map(([prop, value]) => `${prop}: ${value};`)
                  .join("\n")}
              }
            }`
            )}
          </style>
          <style>
            {Object.keys(generateTextAfterStyles()).map(
              (key) =>
                `@keyframes text-after-animation-${key} {
              ${key} {
                ${Object.entries(generateTextAfterStyles()[key])
                  .map(([prop, value]) => `${prop}: ${value};`)
                  .join("\n")}
              }
            }`
            )}
          </style>
          <div className="frame" style={frameStyles}>
            {Array.from({ length: _number_of_text + 1 }, (_, i) => (
              <div
                key={i}
                className={`frame${i}`}
                style={generateFrameStyles()[i]}
              >
                {/* Frame Content */}
              </div>
            ))}
          </div>
          <style>
            {Object.keys(generateFrameAnimationKeyframes()).map(
              (key) =>
                `@keyframes frame-animation-${key} {
              ${key} {
                ${Object.entries(generateFrameAnimationKeyframes()[key])
                  .map(([prop, value]) => `${prop}: ${value};`)
                  .join("\n")}
              }
            }`
            )}
          </style>
          <div className="particle" style={particleStyles}>
            {Array.from({ length: _number_of_text + 1 }, (_, i) =>
              Array.from({ length: _number_of_particle + 1 }, (_, j) => (
                <div
                  key={j}
                  className={`particle${i}${j}`}
                  style={
                    generateParticleStyles()[i * (_number_of_particle + 1) + j]
                  }
                >
                  {/* Particle Content */}
                </div>
              ))
            )}
          </div>
          <style>
            {Object.keys(generateParticleAnimationKeyframes()).map(
              (key) =>
                `@keyframes particle-animation-${key} {
              ${key} {
                ${Object.entries(generateParticleAnimationKeyframes()[key])
                  .map(([prop, value]) => `${prop}: ${value};`)
                  .join("\n")}
              }
            }`
            )}
          </style>
        </body>
      </div>

      <div>{backgroundElements}</div>
    </>
  );
};
export default LoadingScreen;
