import React, { useState, useEffect, useRef } from "react";
import ReactApexChart from "react-apexcharts";
// import { Dropdown, DropdownButton } from "react-bootstrap";
import NAfiaAdminSideBar from "./AmraAdminSideBar";
import "../../Dasboard/Css/Dashboard.css";
import "../AdminSIdebar.css";
import "../NAfiaAdmin.css";
import { Modal } from "react-bootstrap";
import NotificationsImg from "../../assets/images/notification.svg";
import SideBarControl from "../../assets/images/rightLeftSIdebar.png";
import UserImgPrf from "../../assets/images/userimg45.svg";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, registerables } from "chart.js";
import "react-circular-progressbar/dist/styles.css";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import SearchImg from "../../assets/images/search-normal.svg";
import MyChart from "../../Components/test";
import { Helmet } from "react-helmet";
import calenderImg from "../../assets/images/calendar12white.svg";
import adm_img from "../../assets/images/adm_img.png";
import UsersImg from "../../assets/images/Money.png";
import arrowUp from "../../assets/images/ArrowRight12.svg";
import Chart1 from "../../assets/images/Chart12.svg";
import Chart2 from "../../assets/images/Chart123.svg";
import helpSign from "../../assets/images/Info123.svg";
import CompanmyImg2 from "../../assets/images/Bitmap2.svg";
import gear1 from "../../assets/images/Gear1.png";
import Question from "../../assets/images/Question.png";
import Bell from "../../assets/images/Bell.png";
import Users12 from "../../assets/images/Users12.svg";
import {
  AMRA_CUSTOMERS_DATA,
  URL_FOR_TICKETS,
  AMRA_DEBTOR_DATA_AGGREGATE,
  AMRA_DEBTOR_DATA_DETAILS,
  city_list,
} from "../../KeyConnection/KeysService";
import Logout from "../../assets/images/giflogout.gif";
import Calender from "../../assets/images/calendar12.svg";
import $ from "jquery";
import moment from "moment";
import "moment/locale/en-au";
import "daterangepicker";
import CenterNavAmraAdmin from "./CenterNavAmraAdmin";
import searchIcon from "../../assets/images/searchIcon.svg";
import RenuewalIcon from "../../assets/images/renewalgrey.svg";
import RenuewalImg from "../../assets/images/reewalPink.svg";
import neeewIcon from "../../assets/images/newB.svg";
import neeewImg from "../../assets/images/neBoarding.svg";
import ugentIcon from "../../assets/images/urgentT.svg";
import ugentImg from "../../assets/images/urTicket.svg";
import systemIcon from "../../assets/images/upgradeSys.svg";
import systemImg from "../../assets/images/upsysytem.svg";
import FilterImg from "../../assets/images/filterImag23.svg";
import { Dropdown } from "primereact/dropdown";

import noteImg from "../../assets/images/upgradeSys.svg";
import graphImg from "../../assets/images/upgradeSys.svg";
import handShakeImg from "../../assets/images/upgradeSys.svg";
import chatImg from "../../assets/images/upgradeSys.svg";
import moneyBagImg from "../../assets/images/MoneyBag.svg";
import CallImg from "../../assets/images/totalCalsssss.svg";
import ListGroup from "react-bootstrap/ListGroup";

function NafiaAdmin() {
  ChartJS.register(...registerables);
  const [customerDetails, setCustomerDetails] = useState({
    customer_id: 1234,
    customer_name: "XYZ",
    user_count: 3,
    city: "Dubai",
    license_type: 2,
    onboarding_date: "",
    total_commission: 123,
    total_collection: 1234,
    total_calls: 567,
    server_cost: 234,
    user_name: localStorage.getItem("user_name") || "",
    debtor_details: {
      pre_due_amount: 0,
      day_30_amount: 0,
      day_60_amount: 0,
      day_90_amount: 0,
      escalation_amount: 0,
      dispute_amount: 0,
    },
    filteredTickets: [
      {
        status: "On-going", //high, medium, low
        ticket_no: "acvb",
        date: "04/12/2023",
        time: "12:30 PM",
      },
    ],
  });
  const [customerList, setCustomerList] = useState([
    {
      customer_id: 1234,
      customer_name: "XYZ",
      // user_count: 3,
      // city: "Dubai",
      // license_type: 2,
      onboarding_date: "",
      total_commission: 123,
      total_collection: 1234,
      // total_calls: 567,
      // server_cost: 234,
    },
  ]);

  const [filteredTickets, setFilteredTickets] = useState([
    // {
    //   priority: "high", //high, medium, low
    //   type: "urgent", //urgent, feature_addition, bug, ammendment, upgradation, kb_update
    //   customer_name: "Sam",
    //   ticket_no: "acvb",
    //   date: "",
    // },
  ]);

  const [amraAdminDashboardData, setAmraAdminDashboardData] = useState({
    total_customers: 0,
    new_onboardings: 0, //last 7 days
    total_collection: 0,
    total_commission: 0,
    accounts_for_renewals: 0,
    urgent_tickets: 0, // high priority
    tickets: [
      {
        priority: "high", //high, medium, low
        type: "urgent", //urgent, feature_addition, bug, ammendment, upgradation, kb_update
        customer_name: "Sam",
        ticket_no: "acvb",
        date: "",
      },
    ],
  });

  const [graphData, setGraphData] = useState({
    labels: ["January", "February", "March", "April", "May", "June"],
    datasets: [
      {
        label: "Total Collection",
        data: [30, 38, 10, 15, 43, 10, 27],
        borderColor: "rgb(75, 192, 192)",
        tension: 0.37,
        pointRadius: 2,
        fill: false,
      },
      {
        label: "Total Commission",
        data: [40, 48, 8, 43, 10, 47, 30],
        borderColor: "rgb(255, 99, 132)",
        tension: 0.37,
        pointRadius: 2,
        fill: false,
      },
    ],
  });
  const [graphDatapopup, setGraphDatapopup] = useState({
    labels: ["January", "February", "March", "April", "May", "June"],
    datasets: [
      {
        label: "Total Collection",
        data: [30, 38, 10, 15, 43, 10, 27],
        borderColor: "rgb(75, 192, 192)",
        tension: 0.37,
        pointRadius: 2,
        fill: false,
      },
      {
        label: "Total Commission",
        data: [40, 48, 8, 43, 10, 47, 30],
        borderColor: "rgb(255, 99, 132)",
        tension: 0.37,
        pointRadius: 2,
        fill: false,
      },
    ],
  });
  // Configuration options
  const options = {
    scales: {
      x: {
        title: {
          display: false,
        },
      },
      y: {
        title: {
          display: false,
        },
        ticks: {
          min: 30000,
          max: 110000,
          stepSize: 20000,
          precision: 0,
        },
      },
    },
    plugins: {
      tooltip: {
        mode: "nearest",
      },
    },
  };

  const fetchAmraAdminDashboardData = async (dateRange) => {
    const cust_list = [];

    const adminData = {
      total_customers: 0,
      new_onboardings: 0, //last 7 days
      accounts_for_renewals: 0,
      total_collection: 0,
      total_commission: 0,
      urgent_tickets: 0, // high priority
      tickets: [],
    };

    try {
      const response1 = await fetch(AMRA_CUSTOMERS_DATA, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const list = await response1.json();
      const companiesList = list.customer_list;
      //   {
      //     "id": 1,
      //     "company_name": "Technotask",
      //     "email": "xyzfinancial@techonotask.ai",
      //     "active_plan_id": "1",
      //     "subscription_plan": "1",
      //     "plan_expiry_date": "2024-01-27",
      //     "created_at": "2023-12-20T05:56:36Z"
      // }
      adminData.total_customers = companiesList.length;
      companiesList.forEach(async (company) => {
        let now = Date.now();

        let onboarding_date = new Date(company.created_at);
        const sevenDaysAgo = now - 7 * 24 * 60 * 60 * 1000;
        if (onboarding_date >= sevenDaysAgo) {
          adminData.new_onboardings += 1;
        }

        let plan_expiry_date = new Date(company.plan_expiry_date);
        if (plan_expiry_date.getMonth() === new Date().getMonth()) {
          adminData.accounts_for_renewals += 1;
        }

        let obj = {
          customer_id: company.id,
          customer_name: company.company_name,
          onboarding_date: onboarding_date.toLocaleDateString("en-GB"),
          total_commission: 0,
          total_collection: 0,
        };

        let url_debtor_detail = `${AMRA_DEBTOR_DATA_DETAILS}${company.mobile_no}/`;
        const response1 = await fetch(url_debtor_detail, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });

        const data = await response1.json();
        const debtors = data.debtors;

        debtors.forEach((debtor) => {
          debtor.debtor_history.forEach((instance) => {
            obj.total_collection += Number(instance.recovery_amount);
            obj.total_commission += Number(instance.admin_commission);
          });
        });
        cust_list.push(obj);
        setCustomerList(cust_list);
        setAmraAdminDashboardData(adminData);
      });

      let startDate = dateRange.startDate || "2023-01-01";
      let endDate = dateRange.endDate || "2023-12-28";
      let url_debtor_aggregate = `${AMRA_DEBTOR_DATA_AGGREGATE}${startDate}/${endDate}/0/`;
      const response2 = await fetch(url_debtor_aggregate, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response2.json();
      adminData.total_collection = data.data.total_collection;
      adminData.total_commission = data.data.total_commission;
      setAmraAdminDashboardData(adminData);

      const months = [];
      const totalCollection = [];
      const totalCommission = [];

      data.datewise_data.forEach((item) => {
        const abbreviatedMonth = item.month.substring(0, 3);
        months.push(abbreviatedMonth);
        totalCollection.push(item.total_collection);
        totalCommission.push(item.total_commission);
      });

      setGraphData({
        labels: months,
        datasets: [
          {
            label: "Total Collection",
            data: totalCollection,
            borderColor: "rgb(75, 192, 192)",
            tension: 0.37,
            pointRadius: 2,
            fill: false,
          },
          {
            label: "Total Commission",
            data: totalCommission,
            borderColor: "rgb(255, 99, 132)",
            tension: 0.37,
            pointRadius: 2,
            fill: false,
          },
        ],
      });

      const response3 = await fetch(URL_FOR_TICKETS, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const ticket_data = await response3.json();
      const tickets = ticket_data.data;
      tickets.forEach((ticket) => {
        if (ticket.ticket_for_flag === "1") {
          if (ticket.priority_status === "High") {
            adminData.urgent_tickets += 1;
          }
          let obj = {
            priority: ticket.priority_status, //high, medium, low
            type: ticket.request_ticket_type, //urgent, feature_addition, bug, ammendment, upgradation, kb_update
            customer_name: ticket.email,
            ticket_no: ticket.uuid_id,
            date: new Date(ticket.created_at).toLocaleDateString("en-GB"),
          };

          adminData.tickets.push(obj);
        }
        setAmraAdminDashboardData(adminData);
        setFilteredTickets(adminData.tickets);
      });
    } catch (err) {
      console.log("Company not Found");
    }
  };

  const [customerName, setCustomerName] = useState("");
  const [mobileNo, setMobileNo] = useState("");

  const fetchCustomerData = async () => {
    try {
      let searchInput = customerName;
      if (customerName === "") {
        searchInput = mobileNo;
      }
      let url_debtor_detail = `${AMRA_DEBTOR_DATA_DETAILS}${searchInput}/`;
      const response1 = await fetch(url_debtor_detail, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response1.json();
      const companyDetail = data.company_details;
      const debtorInfo = data.debtors_info;
      const debtors = data.debtors;
      const tickets = data.tickets;

      const onBoardingDate = new Date(
        companyDetail.company_onboarding_date
      ).toLocaleDateString("en-GB");

      const response3 = await fetch(city_list, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data3 = await response3.json();
      let city_name = "";
      data3.data.forEach((city) => {
        if (city.id === Number(companyDetail.company_city)) {
          city_name = city.name;
        }
      });

      let subscriptionPlanArr = ["Basic", "Standard", "Premium"];

      const obj = {
        customer_id: companyDetail.company_id,
        customer_name: companyDetail.company_name,
        user_count: companyDetail.users_count,
        city: city_name === "" ? companyDetail.company_city : city_name,
        license_type: subscriptionPlanArr[companyDetail.company_plan_id - 1],
        onboarding_date: onBoardingDate,
        total_commission: debtorInfo.total_commission,
        total_collection: debtorInfo.total_collection,
        total_calls: debtorInfo.total_calls,
        server_cost: companyDetail.server_cost,
        user_name: companyDetail.company_email,
        debtor_details: {
          pre_due_amount: 0,
          day_30_amount: 0,
          day_60_amount: 0,
          day_90_amount: 0,
          escalation_amount: 0,
          dispute_amount: 0,
        },
        filteredTickets: [],
      };

      debtors.forEach((debtor) => {
        let now = Date.now();
        let dueDate = new Date(debtor.due_date);
        if (debtor.last_payment_date) {
          dueDate = new Date(debtor.last_payment_date);
        }
        let days = Math.ceil((now - dueDate) / (1000 * 60 * 60 * 24));
        let bucket = "";
        if (days <= 0) {
          bucket = "pre_due_amount";
        } else if (days > 0 && days <= 30) {
          bucket = "day_30_amount";
        } else if (days > 30 && days <= 60) {
          bucket = "day_60_amount";
        } else if (days > 60) {
          bucket = "day_90_amount";
        }

        debtor.debtor_history.forEach((instance) => {
          let outstanding_amount =
            Number(instance.debtor_emi_amt) - Number(instance.recovery_amount);

          obj.debtor_details[bucket] += outstanding_amount;

          if (instance.call_flag === 6) {
            obj.debtor_details.escalation_amount += Number(
              instance.debtor_emi_amt
            );
          }
          if (instance.call_flag === 9) {
            obj.debtor_details.dispute_amount += Number(
              instance.debtor_emi_amt
            );
          }
        });
      });
      let startDate = "2022-01-01";
      let endDate = "2024-12-28";
      let url_debtor_aggregate = `${AMRA_DEBTOR_DATA_AGGREGATE}${startDate}/${endDate}/${companyDetail.company_id}/`;
      const response2 = await fetch(url_debtor_aggregate, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data2 = await response2.json();

      const months = [];
      const totalCollection = [];
      const totalCommission = [];
      console.log(data2.datewise_data);
      data2.datewise_data.forEach((item, index) => {
        const abbreviatedMonth = item.month.substring(0, 3);
        months.push(abbreviatedMonth);
        totalCollection.push(item.total_collection);
        totalCommission.push(item.total_commission);
      });

      setGraphDatapopup({
        labels: months,
        datasets: [
          {
            label: "Total Collection",
            data: totalCollection,
            borderColor: "rgb(75, 192, 192)",
            tension: 0.37,
            pointRadius: 2,
            fill: false,
          },
          {
            label: "Total Commission",
            data: totalCommission,
            borderColor: "rgb(255, 99, 132)",
            tension: 0.37,
            pointRadius: 2,
            fill: false,
          },
        ],
      });

      const ticket_category = ["New", "On-going", "Resolved"];
      const timeFormatOptions = {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
        timeZone: "IST", // Adjust this based on your actual timezone
      };

      tickets.forEach((ticket) => {
        let tick_obj = {
          status: ticket_category[Number(ticket.ticket_category)], //high, medium, low
          ticket_no: ticket.uuid_id,
          date: new Date(ticket.created_at).toLocaleDateString("en-GB"),
          time: new Intl.DateTimeFormat("en-US", timeFormatOptions).format(
            new Date(ticket.created_at)
          ),
        };
        obj.filteredTickets.push(tick_obj);
      });
      // calculate bucket using due data
      setCustomerDetails(obj);
      handleShowModal1();
    } catch (err) {
      alert("Company not Found");
    }
  };

  const [AmraadminData, setdataFromAmra] = useState([]);
  const [selectedPriority, setSelectedPriority] = useState("All");
  const showAmraData = async (url_for) => {
    // Fetch data from the API endpoint

    await fetch(url_for)
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "error") {
          alert(data.data);
        } else {
          setdataFromAmra(data); // Update state with fetched data
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  // const monthlysale = Math.round(AmraadminData.total_revenue_earned / 12);

  // const dynamic_datas = AmraadminData.company_data;
  // let dynamic_data = "";
  // // Check if company_data array exists and has at least one element
  // if (dynamic_datas && dynamic_datas.length > 0) {
  //   const firstCompany = dynamic_datas[0];
  //   dynamic_data = firstCompany.graph_data;
  // }
  // // Update the existing data object directly
  // const dataArray = dynamic_data.split(",").map(Number);
  // data.datasets[0].data = dataArray;

  // Use the 'data' object as needed

  const populateTable = () => {
    return customerList.map((item, index) => (
      <tr key={index}>
        <td>
          <div className="tableData1">
            <p>{index + 1}</p>
          </div>
        </td>
        <td>
          <div className="tableData1">
            <p>{item.onboarding_date}</p>
          </div>
        </td>
        <td>
          <div className="tableData1">
            <p># {item.customer_id}</p>
          </div>
        </td>
        <td>
          <div className="tableData1">
            <p style={{ color: "#000" }}>{item.customer_name}</p>
          </div>
        </td>
        <td>
          <div className="tableData1">
            <td>$ {item.total_collection}</td>
          </div>
        </td>
        <td>
          <div className="tableData1">
            <p>$ {item.total_commission}</p>
          </div>
        </td>
      </tr>
    ));
  };

  const inputRef = useRef(null);

  const handleDateRangeChange = (start, end, label) => {
    // Callback function for handling date range changes
    let startDate = start.format("DD-MM-YYYY").toString();
    let endDate = end.format("DD-MM-YYYY").toString();

    let dates = {
      startDate: start.format("YYYY-MM-DD").toString(),
      endDate: end.format("YYYY-MM-DD").toString(),
    };
    fetchAmraAdminDashboardData(dates);
  };

  const [allButtonActive, setAllButtonActive] = useState(true);
  const [datePickerActive, setDatePickerActive] = useState(false);

  useEffect(() => {
    $("#myTable").DataTable();
    // Call the function to fetch data when the component mounts
    // showAmraData(URL_FOR_AMRA_ADMIN_DASH);

    const datepicker = $(inputRef.current);
    if (datePickerActive) {
      datepicker.daterangepicker(
        {
          timePicker: true,
          startDate: moment().subtract(7, "days").startOf("day"), // Set start date to 7 days ago
          endDate: moment().endOf("day"), // Set end date to today
          locale: {
            format: "DD/MM/YYYY",
          },
        },
        handleDateRangeChange
      );
    }

    // Call handleDateRangeChange on component load
    const startDate =
      datepicker?.data("daterangepicker")?.startDate ||
      moment().subtract(365, "days").startOf("day");
    const endDate =
      datepicker?.data("daterangepicker")?.endDate ||
      moment().add(2, "days").endOf("day");

    console.log(startDate, endDate);
    handleDateRangeChange(startDate, endDate);
  }, [datePickerActive]);

  const priority = [
    { name: "All", code: "All" },
    { name: "Feature Request", code: "Feature Request" },
    { name: "Bug", code: "Bug" },
    { name: "Amendment", code: "Ammendment" },
    { name: "Upgradation", code: "Upgradation" },
    { name: "KB Update", code: "KB Update" },
  ];

  const filterTickets = (val) => {
    setSelectedPriority(val);
    if (val === "All") {
      setFilteredTickets(amraAdminDashboardData.tickets);
      return;
    }
    const filteredTickets = amraAdminDashboardData.tickets.filter((ticket) => {
      return ticket.type === val;
    });
    setFilteredTickets(filteredTickets);
  };

  const [showModal1, setShowModal1] = useState(false);
  const handleShowModal1 = () => {
    setShowModal1(true);
  };

  const handleCloseModal1 = () => {
    setShowModal1(false);
  };

  return (
    <div className="dashboard">
      <Helmet>
        <title>Amra Dashboard</title>
        <meta
          name="description"
          content="AI and Global Reach: The Key to Scalable Solutions in Customer
          Experience"
        />
      </Helmet>
      <div className="dashboard_container">
        <div className="dashboard_wrapper">
          <NAfiaAdminSideBar />
          <div id="content">
            <CenterNavAmraAdmin />

            <div className="Dashpages_contaioner">
              <div className="overview">
                <div className="overviewContaINER">
                  {/* <div className="adminoverviewHead">
                    <div className="adminoverviewHead_conrTAINER">
                      <div className="adminoverviewSearch">
                        <img src={SearchImg} alt="icon" />
                        <input
                          type="text"
                          placeholder="Search for file, folder, etc..."
                        />
                      </div>
                    </div>
                    <div className="date_filter_div">
                      <div className="date_filter">
                        <div className="calender_icon">
                          <label style={{ cursor: "pointer" }} htmlFor="datepicker">
                            <img src={Calender} alt="Image" />
                          </label>
                        </div>
                        <input
                          id="datepicker"
                          className="form-control form-control-solid"
                          placeholder="Pick date range"
                          ref={inputRef}
                        />
                      </div>
                    </div>
                  </div> */}

                  <div className="revenueCall">
                    <div className="row m-0">
                      <div className="col-md-8 p-0  ">
                        <div className="row m-0">
                          <div
                            className="col-md-4 mb-3"
                            style={{ padding: "0" }}
                          >
                            <div className="customersCard">
                              <div className="customersCardLeftText">
                                <img src={Users12} alt="icon" />
                                <p>Customers</p>
                              </div>
                              <div className="customersCardLeft">
                                <div className="customersCardrighttext">
                                  <h3>
                                    {amraAdminDashboardData.total_customers}
                                  </h3>
                                  {/* <p>
                                    34.3%{" "}
                                    <span>
                                      <img src={arrowUp} alt="icon" />
                                    </span>
                                  </p> */}
                                </div>
                                <div className="customersCardright">
                                  <img src={Chart1} alt="chat" />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            className="col-md-4 mb-3"
                            style={{ paddingRight: "0" }}
                          >
                            <div className="customersCard">
                              <div className="customersCardLeftText">
                                <img src={UsersImg} alt="icon" />
                                <p>Total Collection</p>
                              </div>
                              <div className="customersCardLeft">
                                <div className="customersCardrighttext">
                                  <h3>
                                    {amraAdminDashboardData.total_collection}
                                  </h3>
                                  {/* <p>
                                    56.5%
                                    <span>
                                      <img src={arrowUp} alt="icon" />
                                    </span>
                                  </p> */}
                                </div>
                                <div className="customersCardright">
                                  <img src={Chart2} alt="chat" />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            className="col-md-4 mb-3"
                            style={{ paddingRight: "0" }}
                          >
                            <div className="customersCard">
                              <div className="customersCardLeftText">
                                <img src={UsersImg} alt="icon" />
                                <p>Total Commission</p>
                              </div>
                              <div className="customersCardLeft">
                                <div className="customersCardrighttext">
                                  <h3>
                                    {amraAdminDashboardData.total_commission}
                                  </h3>
                                  {/* <p>
                                    56.5%
                                    <span>
                                      <img src={arrowUp} alt="icon" />
                                    </span>
                                  </p> */}
                                </div>
                                <div className="customersCardright">
                                  <img src={Chart2} alt="chat" />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-4" style={{ padding: "0" }}>
                            <div className="customersCard">
                              <div className="customersCardLeftText">
                                <img src={neeewIcon} alt="icon" />
                                <p>New Onboardings</p>
                              </div>
                              <div className="customersCardLeft">
                                <div className="customersCardrighttext">
                                  <h3>
                                    {amraAdminDashboardData.new_onboardings}
                                  </h3>
                                  {/* <p>
                                    56.5%
                                    <span>
                                      <img src={arrowUp} alt="icon" />
                                    </span>
                                  </p> */}
                                </div>
                                <div className="customersCardright">
                                  <img src={neeewImg} alt="chat" />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            className="col-md-4"
                            style={{ paddingRight: "0" }}
                          >
                            <div className="customersCard">
                              <div className="customersCardLeftText">
                                <img src={ugentIcon} alt="icon" />
                                <p>Urgent Tickets</p>
                              </div>
                              <div className="customersCardLeft">
                                <div className="customersCardrighttext">
                                  <h3>
                                    {amraAdminDashboardData.urgent_tickets}
                                  </h3>
                                  {/* <p>
                                    56.5%
                                    <span>
                                      <img src={arrowUp} alt="icon" />
                                    </span>
                                  </p> */}
                                </div>
                                <div className="customersCardright">
                                  <img src={ugentImg} alt="chat" />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            className="col-md-4"
                            style={{ paddingRight: "0" }}
                          >
                            <div className="customersCard">
                              <div className="customersCardLeftText">
                                <img src={RenuewalIcon} alt="icon" />
                                <p>Accounts for Renewal </p>
                              </div>
                              <div className="customersCardLeft">
                                <div className="customersCardrighttext">
                                  <h3>
                                    {
                                      amraAdminDashboardData.accounts_for_renewals
                                    }
                                  </h3>
                                  {/* <p>
                                    56.5%
                                    <span>
                                      <img src={arrowUp} alt="icon" />
                                    </span>
                                  </p> */}
                                </div>
                                <div className="customersCardright">
                                  <img src={RenuewalImg} alt="chat" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="areaCHArtDiv">
                          <div className="chartHeading">
                            <div className="chartHeadingtext-left">
                              <h4>Growth & Revenue</h4>
                            </div>
                            <div className="chartHeadingtext-right">
                              {/* <div className="dateDropdownform">
                                <select>
                                  <option value=" ">Current Year</option>
                                  <option value="Last">Last Month</option>
                                  <option value="Month to Date">
                                    Month to Date
                                  </option>
                                  <option value="Last 3">Last 3 Months</option>
                                  <option value="Last 6">Last 6 Months</option>
                                  <option value="Year to Date">
                                    Year to Date
                                  </option>
                                  <option value="Lifetime">Lifetime</option>
                                </select>
                              </div> */}
                            </div>
                          </div>
                          <div className="p-3">
                            <Line data={graphData} options={options} />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="row m-0">
                          <div className="col-md-3">
                            <div className="date_filter_div h-100">
                              <div
                                className="date_filter w-100 h-100"
                                onClick={() => {
                                  setAllButtonActive(true);
                                  setDatePickerActive(false);
                                }}
                                style={{
                                  cursor: "pointer",
                                  backgroundColor: allButtonActive
                                    ? "#7e32cb"
                                    : "#ffffff",
                                  color: allButtonActive
                                    ? "#ffffff"
                                    : "#212529",
                                  justifyContent: "center",
                                  paddingLeft: "0",
                                }}
                              >
                                All
                              </div>
                            </div>
                          </div>
                          <div className="col-md-9">
                            <div
                              className="date_filter_div"
                              onClick={() => {
                                setAllButtonActive(false);
                                setDatePickerActive(true);
                              }}
                              style={{
                                cursor: "pointer",
                              }}
                            >
                              {" "}
                              {datePickerActive ? (
                                <div
                                  className="date_filter w-100"
                                  style={{
                                    backgroundColor: "#7e32cb",
                                    color: "#ffffff",
                                  }}
                                >
                                  <div className="calender_icon">
                                    <label
                                      style={{ cursor: "pointer" }}
                                      htmlFor="datepicker"
                                    >
                                      <img
                                        style={{ paddingLeft: "12px" }}
                                        src={calenderImg}
                                        alt="Image"
                                      />
                                    </label>
                                  </div>
                                  <input
                                    id="datepicker"
                                    className="form-control form-control-solid"
                                    placeholder="Pick date range"
                                    ref={inputRef}
                                    style={{
                                      backgroundColor: "#7e32cb",
                                      color: "#ffffff",
                                    }}
                                  />
                                </div>
                              ) : (
                                <div
                                  className="date_filter w-100"
                                  style={{
                                    padding: "0.5rem",
                                    justifyContent: "center",
                                  }}
                                >
                                  Choose Date
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="searchClientContaienr">
                          <div className="searchContaienr">
                            <div className="text-center col-xl-10 m-auto">
                              <h5>Search Customer </h5>
                              <div className="searchInputsConta">
                                <input
                                  placeholder="Customer Name "
                                  type="text"
                                  value={customerName}
                                  onChange={(e) => {
                                    setMobileNo("");
                                    setCustomerName(e.target.value);
                                  }}
                                />
                                <p
                                  style={{
                                    fontWeight: "500",
                                    margin: "0.5rem 0",
                                  }}
                                >
                                  or
                                </p>
                                <input
                                  placeholder="Mobile No."
                                  type="text"
                                  value={mobileNo}
                                  onChange={(e) => {
                                    setCustomerName("");
                                    setMobileNo(e.target.value);
                                  }}
                                />
                              </div>
                              <div className="searchBtnADmin">
                                <button onClick={fetchCustomerData}>
                                  <img src={searchIcon} alt="Icon" />
                                  <p>Search </p>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="ticktfiltertable">
                          <div className="ticktfiltertableContainer">
                            <div className="ticktfiltertableHead">
                              <h5>Ticket</h5>
                              <div className="filterDrpdwn">
                                <img src={FilterImg} alt="icon" />
                                <Dropdown
                                  value={selectedPriority}
                                  onChange={(e) => filterTickets(e.value.code)}
                                  options={priority}
                                  optionLabel="name"
                                  placeholder={selectedPriority}
                                  className="priorityBtn filterDrpdwnSelects"
                                />
                              </div>
                            </div>
                            <div
                              className="ticktstable"
                              style={{ maxWidth: "100%", overflowX: "auto" }}
                            >
                              <table
                                className="table"
                                style={{ width: "100%" }}
                              >
                                <thead>
                                  <tr>
                                    <th scope="col">Date</th>
                                    <th scope="col">Client Name</th>
                                    <th scope="col">Ticket No</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {filteredTickets.map((ticket, idx) => {
                                    return (
                                      <tr key={idx}>
                                        <td>{ticket.date}</td>
                                        <td title={ticket.customer_name}>
                                          {ticket.customer_name.slice(0, 10)}...
                                        </td>
                                        <td title={ticket.ticket_no}>
                                          {ticket.ticket_no.slice(0, 6)}...
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                            {/* <div className="ticktfiltertableBtn">
                              <button>Download</button>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="amradataTable">
                    <div className="amradataTable_container">
                      <h4>Recent Customers</h4>
                      <table id="myTable" className="display">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Onboarding Date</th>
                            <th>Customer ID</th>
                            <th>Customer Name</th>
                            <th>Total Collection</th>
                            <th>Total Comission</th>
                          </tr>
                        </thead>
                        <tbody>
                          {customerList.map((item, index) => (
                            <tr key={index}>
                              <td>
                                <div className="tableData1">
                                  <p>{index + 1}</p>
                                </div>
                              </td>
                              <td>
                                <div className="tableData1">
                                  <p>{item.onboarding_date}</p>
                                </div>
                              </td>
                              <td>
                                <div className="tableData1">
                                  <p>#{item.customer_id}</p>
                                </div>
                              </td>
                              <td>
                                <div className="tableData1">
                                  <p style={{ color: "#000" }}>
                                    {item.customer_name}
                                  </p>
                                </div>
                              </td>
                              <td>
                                <div className="tableData1">
                                  <td>${item.total_collection}</td>
                                </div>
                              </td>
                              <td>
                                <div className="tableData1">
                                  <p>${item.total_commission}</p>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={showModal1}
        onHide={handleCloseModal1}
        dialogClassName="custom-modal1"
        centered
        size="xl"
      >
        <Modal.Header>
          <h5 className="mt-3">Customer Details</h5>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              maxHeight: "550px",
              overflowY: "auto",
              padding: "0 1.5rem",
              backgroundColor: "#F0F0F0",
            }}
          >
            <section className="CIENTdETAILShEAD">
              <div className="clientHeadleft">
                <p>Customer Name : {customerDetails.customer_name}</p>
                <p>Email : {customerDetails.user_name}</p>
                <p>Customer Id : # {customerDetails.customer_id}</p>
              </div>
              <div className="clientHeadright">
                <p>Users : {customerDetails.user_count}</p>
                <p>City : {customerDetails.city}</p>
                <p>Licence Type : {customerDetails.license_type}</p>
              </div>
            </section>

            <section className="clientDtataContainer">
              <div className="row m-0">
                <div className="col-md-8 my-3 p-0">
                  <div className="row m-0">
                    {/* -----------------Total Revenue----------------------- */}
                    <div className="col-md-4" style={{ paddingLeft: "0" }}>
                      <div className="customersCard">
                        <div className="customersCardLeftText">
                          <img src={UsersImg} alt="icon" />
                          <p>Total Collection</p>
                        </div>
                        <div className="customersCardLeft">
                          <div className="customersCardrighttext">
                            <h5 style={{ color: "#000", margin: "0" }}>
                              $ {customerDetails.total_collection}
                            </h5>
                          </div>
                          <div className="customersCardright">
                            <img
                              style={{ width: "2rem" }}
                              src={Chart1}
                              alt="chat"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4" style={{ paddingLeft: "0" }}>
                      <div className="customersCard">
                        <div className="customersCardLeftText">
                          <img src={ugentIcon} alt="icon" />
                          <p>Total Commission</p>
                        </div>
                        <div className="customersCardLeft">
                          <div className="customersCardrighttext">
                            <h5 style={{ color: "#000", margin: "0" }}>
                              $ {customerDetails.total_commission}
                            </h5>
                          </div>
                          <div className="customersCardright">
                            <img
                              style={{ width: "2rem" }}
                              src={neeewImg}
                              alt="chat"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-md-4"
                      style={{ paddingLeft: "0", paddingRight: "0" }}
                    >
                      <div className="serveandCallCost">
                        <div className="serverCostContainer mb-2">
                          <img src={moneyBagImg} className=" " alt="" />
                          <div className="serveandCallCostText">
                            <h1 className="fs-6 text-secondary">Server Cost</h1>
                            <p className=" fw-bold m-0 ">
                              $ {customerDetails.server_cost}
                            </p>
                          </div>
                        </div>
                        <div className="serverCostContainer">
                          <img src={CallImg} className="" alt="" />
                          <div className="serveandCallCostText">
                            <h1 className="fs-6 text-secondary ">
                              Total Calls
                            </h1>
                            <p className=" fw-bold m-0 ">
                              {customerDetails.total_calls}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-100 my-3">
                    <div className="callVSMonChart">
                      <div className="callVSMonChartHead">
                        <h5 className="mt-2" style={{ color: "#000" }}>
                          Growth & Revenue{" "}
                        </h5>
                      </div>
                      <Line data={graphDatapopup} options={options} />
                    </div>
                  </div>
                </div>
                {/* debtor_details: {
        pre_due_amount: 0,
        day_30_amount: 0,
        day_60_amount: 0,
        day_90_amount: 0,
        escalation_amount: 0,
        dispute_amount: 0,
      },
      filteredTickets: [
        {
          priority: "high", //high, medium, low
          ticket_no: "acvb",
          date: "04/12/2023",
          time: "12:30 PM",
        },
      ], */}

                {/* ----------------------------------------Details---------------------------------- */}
                <div className="col-md-4">
                  <div className="">
                    <div className="mt-3">
                      <ListGroup className="dfgsdfg">
                        <ListGroup.Item className="bg-black text-white">
                          Details
                        </ListGroup.Item>
                        <ListGroup.Item className="d-flex justify-content-between border-end-0 border-start-0">
                          <p>Pre-Schedule B</p>
                          <p>
                            $ {customerDetails?.debtor_details?.pre_due_amount}
                          </p>
                        </ListGroup.Item>
                        <ListGroup.Item className="d-flex justify-content-between border-end-0 border-start-0">
                          <p>30 Days B.</p>
                          <p>
                            $ {customerDetails?.debtor_details?.day_30_amount}
                          </p>
                        </ListGroup.Item>
                        <ListGroup.Item className="d-flex justify-content-between border-end-0 border-start-0">
                          <p>60 Days B.</p>
                          <p>
                            $ {customerDetails?.debtor_details?.day_60_amount}
                          </p>
                        </ListGroup.Item>
                        <ListGroup.Item className="d-flex justify-content-between border-end-0 border-start-0">
                          <p>90 Days B.</p>
                          <p>
                            $ {customerDetails?.debtor_details?.day_90_amount}
                          </p>
                        </ListGroup.Item>
                        <ListGroup.Item className="d-flex justify-content-between border-end-0 border-start-0">
                          <p>L2 Escallation</p>
                          <p>
                            ${" "}
                            {customerDetails?.debtor_details?.escalation_amount}
                          </p>
                        </ListGroup.Item>
                        <ListGroup.Item className="d-flex justify-content-between border-end-0 border-start-0 border-bottom-0">
                          <p>Dispute B.</p>
                          <p>
                            $ {customerDetails?.debtor_details?.dispute_amount}
                          </p>
                        </ListGroup.Item>
                      </ListGroup>
                    </div>

                    {/* ----------------------------------------All Tickets---------------------------------- */}
                    <div className="clientticketsAll">
                      <div className="clientticketsAllHead">
                        <h5>All Tickets</h5>
                        <button className="clientticketsAllBtn bg-transparent border-0">
                          See All
                        </button>
                      </div>

                      <div className="clientticketsAllList">
                        {customerDetails?.filteredTickets?.map((ticket) => {
                          return (
                            <div className="clientticketsListItem">
                              <div className="clientticketsListItemHead">
                                <h6>{ticket?.date}</h6>
                                <p title={ticket.ticket_no}>
                                  {ticket.ticket_no.slice(0, 10)}...
                                </p>
                              </div>
                              <div className="clientticketsListItemHead">
                                <p>Time: {ticket?.time}</p>
                                <p style={{ color: "#DC0D00" }}>
                                  Status: {ticket?.status}
                                </p>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    <div className="OtherDetailsBtns">
                      <button>
                        Commission History/Transaction{" "}
                        <span className="postMvp">Post MVP</span>{" "}
                      </button>
                      <div className="d-flex" style={{ gap: "1rem" }}>
                        <button>
                          Subscription <br /> Details <br />
                          <span className="postMvp">Post MVP</span>
                        </button>
                        <button>
                          Knowledge Base <br /> Details <br />
                          <span className="postMvp">Post MVP</span>
                        </button>
                      </div>
                      <div className="d-flex" style={{ gap: "1rem" }}>
                        <button>
                          Reports <br />
                          <span className="postMvp">Post MVP</span>
                        </button>
                        <button>
                          Mails <br />
                          <span className="postMvp">Post MVP</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="updateCnclebtnDiv">
            <button
              className="updateCnclebtnCan clientDataCanelBtns"
              onClick={handleCloseModal1}
            >
              Cancel
            </button>
            <button
              className="updateCnclebtnUp clientEditBytns"
              onClick={handleCloseModal1}
            >
              Edit Details <span className="postMvp">Post MVP</span>
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default NafiaAdmin;
