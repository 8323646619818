import React, { useEffect, useState } from "react";
import $ from "jquery";
import AE_UAE_TEPO from "../../assets/images/ae-all.topo.json";
const MyChart = ({ calldata }) => {
  const [chartLoaded, setChartLoaded] = useState(false);

  useEffect(() => {
    get_map_data();
  }, [calldata]);

  const get_map_data = () => {
    if (!chartLoaded && calldata.length > 0) {
      import("highcharts/highmaps").then((Highcharts) => {
        import("highcharts/modules/map").then(() => {
          setChartLoaded(true);

          // Your chart initialization code here
          (async () => {
            const topology = AE_UAE_TEPO;
            // Prepare the data to link to the map regions
            const mapData = topology.objects.default.geometries.map(
              (geometry) => {
                const countryData = calldata.find(
                  (item) =>
                    item.name.toLowerCase() ===
                    geometry.properties.name.toLowerCase()
                );
                return [
                  geometry.properties["hc-key"],
                  countryData ? countryData.total_debtors : 0,
                ];
              }
            );

            // Create the chart with updated data
            Highcharts.mapChart("container", {
              chart: {
                map: topology,
              },
              title: {
                text: "UAE regions",
              },
              mapNavigation: {
                enabled: false,
              },
              series: [
                {
                  data: mapData,
                  name: "Total Users",
                  color: "#fff",
                  states: {
                    hover: {
                      color: "lightgrey",
                    },
                  },
                  dataLabels: {
                    enabled: true,
                    format: "{point.name}",
                  },
                },
              ],
            });
            //console.log(point.name);
            //textchng();
            $(".highcharts-key-ae-az").css({ fill: "#7E32CB" });
            $(".highcharts-key-ae-du").css({ fill: "#0070C0" });
            $(".highcharts-key-ae-aj").css({ fill: "#F29CF6" });
            $(".highcharts-key-ae-sh").css({ fill: "#80CAFF" });
            $(".highcharts-key-ae-rk").css({ fill: "#003860" });
            $(".highcharts-key-ae-742").css({ fill: "#FC912B" });
            $(".highcharts-credits").html("");
            $(".highcharts-exporting-group").remove();
            $(".highcharts-title").remove();
            $(".highcharts-root").css({ height: "60%", width: "100%" });
            // $(".highcharts-background").css({ height: "150px" });
            // $(".highcharts-sihl41x-0").css({ height: "270px" });
          })();
        });
      });
    }
  };

  return (
    <div>
      <div
        id="container"
        style={{
          // height: "100%",
          // width: "100%",
          margin: "0 auto",
          backgroundColor: "transparent",
        }}
      ></div>
    </div>
  );
};

export default MyChart;
