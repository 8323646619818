import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import CenterNavAmraAdmin from "./CenterNavAmraAdmin";
import NAfiaAdminSideBar from "./AmraAdminSideBar";
import $ from "jquery";
import { Modal } from "react-bootstrap";
import { Dropdown } from "primereact/dropdown";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, registerables } from "chart.js";
import moneyBagImg from "../../assets/images/MoneyBag.svg";
import CallImg from "../../assets/images/totalCalsssss.svg";
import neeewImg from "../../assets/images/neBoarding.svg";
import ugentIcon from "../../assets/images/urgentT.svg";
import UsersImg from "../../assets/images/Money.png";
import Chart1 from "../../assets/images/Chart12.svg";
import moment from "moment";
import "moment/locale/en-au";
import {
  AMRA_CUSTOMERS_DATA,
  AMRA_DEBTOR_DATA_AGGREGATE,
  AMRA_DEBTOR_DATA_DETAILS,
  AgentList,
} from "../../KeyConnection/KeysService";
import {
  server_post_data_shubham,
  save_update_AgentData,
  get_all_agent_data,
} from "../../KeyConnection/KeysService.js";
import {
  check_vaild_save,
  combiled_form_data,
  empty_form,
  handleAphabetsChange,
} from "../../CommonJquery/CommonJquery.js";
function AmraAgents() {
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [control, setControl] = useState(null);
  const [controlOptions, setControlOption] = useState([]);
  const [showModal1, setShowModal1] = useState(false);

  const [AmraadminData, setdataFromAmra] = useState([]);
  const company_id = localStorage.getItem("company_id");

  ChartJS.register(...registerables);

  const [AmraAdminAgentsOverview, setAmraAdminAgentsOverview] = useState({
    total_l2_amount: 0,
    average_call_duration: 0,
    total_collection: 0,
    total_commission: 0,
    total_dispute_amount: 0,
    total_calls_made: 1,
  });

  const [graphData, setGraphData] = useState({
    labels: ["January", "February", "March", "April", "May", "June"],
    datasets: [
      {
        label: "Total Collection",
        data: [30, 38, 10, 15, 43, 10, 27],
        borderColor: "rgb(75, 192, 192)",
        tension: 0.37,
        pointRadius: 2,
        fill: false,
      },
      {
        label: "Total Commission",
        data: [40, 48, 8, 43, 10, 47, 30],
        borderColor: "rgb(255, 99, 132)",
        tension: 0.37,
        pointRadius: 2,
        fill: false,
      },
    ],
  });
  // Configuration options
  const options = {
    scales: {
      x: {
        title: {
          display: false,
        },
      },
      y: {
        title: {
          display: false,
        },
        ticks: {
          min: 30000,
          max: 110000,
          stepSize: 20000,
          precision: 0,
        },
      },
    },
    plugins: {
      tooltip: {
        mode: "nearest",
      },
    },
  };

  const handleSaveChangesdynamic = async (form_data, url_for_save) => {
    let vaild_data = check_vaild_save(form_data);
    if (vaild_data) {
      setshowLoaderAdmin(true);
      let fd_from = combiled_form_data(form_data, null);
      fd_from.append("admin_id", company_id);
      fd_from.append("company_id", control.id);
      fd_from.append("main_id", "0");
      await server_post_data_shubham(url_for_save, fd_from)
        .then((Response) => {
          console.log(Response.data.message);
          setshowLoaderAdmin(false);
          if (Response.data.error) {
            alert(Response.data.message);
          } else {
            alert(Response.data.message);
            empty_form(form_data);
            handleCloseModal1();
            datacallfront();
          }
        })
        .catch((error) => {
          setshowLoaderAdmin(false);
        });
    }
  };

  useEffect(() => {
    datacallfront();
  }, []);

  useEffect(() => {
    if (AmraadminData && AmraadminData.length > 0) {
      // Destroy the DataTable if it already exists
      if ($.fn.DataTable.isDataTable("#myTable")) {
        $("#myTable").DataTable().destroy();
      }

      // Initialize DataTable
      $("#myTable").DataTable();
    }
  }, [AmraadminData]);

  const datacallfront = () => {
    const startDate = moment().subtract(365, "days").startOf("day");
    const endDate = moment().add(2, "days").endOf("day");
    let dates = {
      startDate: startDate.format("YYYY-MM-DD").toString(),
      endDate: endDate.format("YYYY-MM-DD").toString(),
    };
    fetchAmraAdminDashboardData(dates);
  };
  const handleControlChange = (value) => {
    console.log(value);
    setControl(value);
  };

  const handleShowModal1 = () => {
    setShowModal1(true);
  };

  const handleCloseModal1 = () => {
    setShowModal1(false);
  };

  const fetchAmraAdminDashboardData = async (dateRange) => {
    const adminData = {
      total_l2_amount: 0,
      average_call_duration: 0, // to be decided
      total_collection: 0,
      total_commission: 0,
      total_dispute_amount: 0,
      total_calls_made: 0,
    };

    try {
      const response1 = await fetch(AMRA_CUSTOMERS_DATA, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const list = await response1.json();
      const companiesList = list.customer_list;
      const agent_list = list.agent_list;
      setControlOption(companiesList);
      setdataFromAmra(agent_list);
      let arr = [];
      let optionArr = [];
      let subscriptionPlanArr = ["Basic", "Standard", "Premium"];
      companiesList.forEach(async (company) => {
        let onboarding_date = new Date(company.created_at);
        optionArr.push({
          name: company.company_name,
          code: company.company_name,
        });

        let obj = {
          company_name: company.company_name,
          customer: "Mr.Shekh Sameer", // to be decided
          customer_id: `${company.id}`,
          plan: subscriptionPlanArr[company.subscription_plan - 1], // to be decided
          license: 0,
          avg_call_Dur: 0, // to be decided
          boarding_date: onboarding_date.toLocaleDateString("en-GB"),
          comission: 0,
          total_collection: 0,
          bucket_30: 0,
          bucket_60: 0,
          bucket_90: 0,
          l2_esc: 0,
        };

        let url_debtor_detail = `${AMRA_DEBTOR_DATA_DETAILS}${company.mobile_no}/`;
        const response1 = await fetch(url_debtor_detail, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });

        const data = await response1.json();
        const debtors = data.debtors;

        debtors.forEach((debtor) => {
          adminData.total_calls_made += debtor.debtor_history.length;
          let now = Date.now();
          let dueDate = new Date(debtor.due_date);
          if (debtor.last_payment_date) {
            dueDate = new Date(debtor.last_payment_date);
          }
          let days = Math.ceil((now - dueDate) / (1000 * 60 * 60 * 24));
          let bucket = "";
          if (days > 0 && days <= 30) {
            bucket = "bucket_30";
          } else if (days > 30 && days <= 60) {
            bucket = "bucket_60";
          } else if (days > 60) {
            bucket = "bucket_90";
          }
          debtor.debtor_history.forEach((instance) => {
            obj.total_collection += Number(instance.recovery_amount);
            obj.comission += Number(instance.admin_commission);
            if (instance.call_flag === 6) {
              obj.l2_esc += 1;
            }
            obj[bucket] += 1;
            //
            // adminData.total_collection += Number(instance.recovery_amount);
            // adminData.total_commission += Number(instance.admin_commission);
            adminData.total_dispute_amount += Number(instance.dispute_amt);
            if (instance.call_flag === 6) {
              adminData.total_l2_amount +=
                Number(instance.debtor_emi_amt) -
                Number(instance.recovery_amount);
            }
          });
        });
        let url_agent_detail = AgentList(company.id);
        const response2 = await fetch(url_agent_detail, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        const agent = await response2.json();

        obj.license = agent.data.length;
        arr.push(obj);
        console.log(arr);
        // setdataFromAmra([...arr]);
      });

      setAmraAdminAgentsOverview(adminData);

      let startDate = dateRange.startDate || "2023-01-01";
      let endDate = dateRange.endDate || "2023-12-28";
      let url_debtor_aggregate = `${AMRA_DEBTOR_DATA_AGGREGATE}${startDate}/${endDate}/0/`;
      const response2 = await fetch(url_debtor_aggregate, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response2.json();
      console.log(data);
      adminData.total_collection = data.data.total_collection;
      adminData.total_commission = data.data.total_commission;

      const months = [];
      const totalCollection = [];
      const totalCommission = [];

      data.datewise_data.forEach((item) => {
        const abbreviatedMonth = item.month.substring(0, 3);
        months.push(abbreviatedMonth);
        totalCollection.push(item.total_collection);
        totalCommission.push(item.total_commission);
      });

      setGraphData({
        labels: months,
        datasets: [
          {
            label: "Total Collection",
            data: totalCollection,
            borderColor: "rgb(75, 192, 192)",
            tension: 0.37,
            pointRadius: 2,
            fill: false,
          },
          {
            label: "Total Commission",
            data: totalCommission,
            borderColor: "rgb(255, 99, 132)",
            tension: 0.37,
            pointRadius: 2,
            fill: false,
          },
        ],
      });
    } catch (err) {}
  };

  return (
    <div className="dashboard">
      <div
        className={showLoaderAdmin ? "loader-overlay show" : "loader-overlay"}
      >
        <div className="loader23"></div>
      </div>
      <Helmet>
        <title>Amra Dashboard</title>
        <meta
          name="description"
          content="AI and Global Reach: The Key to Scalable Solutions in Customer
          Experience"
        />
      </Helmet>
      <div className="dashboard_container">
        <div className="dashboard_wrapper">
          <NAfiaAdminSideBar />
          <div id="content">
            <CenterNavAmraAdmin />

            <div className="Dashpages_contaioner">
              <div className="overview">
                <div className="overviewContaINER">
                  <div className="row m-0">
                    {/* -----------------Total Revenue----------------------- */}
                    <div className="col-md-3" style={{ paddingLeft: "0" }}>
                      <div className="customersCard">
                        <div className="customersCardLeftText">
                          <img src={UsersImg} alt="icon" />
                          <p>Total Collection</p>
                        </div>
                        <div className="customersCardLeft">
                          <div className="customersCardrighttext">
                            <h5 style={{ color: "#000", margin: "0" }}>
                              $ {AmraAdminAgentsOverview.total_collection}
                            </h5>
                          </div>
                          <div className="customersCardright">
                            <img
                              style={{ width: "2rem" }}
                              src={Chart1}
                              alt="chat"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3" style={{ paddingLeft: "0" }}>
                      <div className="customersCard">
                        <div className="customersCardLeftText">
                          <img src={ugentIcon} alt="icon" />
                          <p>Total Commission</p>
                        </div>
                        <div className="customersCardLeft">
                          <div className="customersCardrighttext">
                            <h5 style={{ color: "#000", margin: "0" }}>
                              $ {AmraAdminAgentsOverview.total_commission}
                            </h5>
                          </div>
                          <div className="customersCardright">
                            <img
                              style={{ width: "2rem" }}
                              src={neeewImg}
                              alt="chat"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3" style={{ paddingLeft: "0" }}>
                      <div className="serveandCallCost">
                        <div className="serverCostContainer mb-2">
                          <img src={moneyBagImg} className=" " alt="" />
                          <div className="serveandCallCostText">
                            <h1 className="fs-6 text-secondary">
                              L2 Escallation
                            </h1>
                            <p className=" fw-bold m-0 ">
                              $ {AmraAdminAgentsOverview.total_l2_amount}
                            </p>
                          </div>
                        </div>
                        <div className="serverCostContainer">
                          <img src={CallImg} className="" alt="" />
                          <div className="serveandCallCostText">
                            <h1 className="fs-6 text-secondary ">
                              Average Call Duration{" "}
                              <span className="postMvp">Post MVP</span>
                            </h1>
                            <p className=" fw-bold m-0 ">
                              {AmraAdminAgentsOverview.average_call_duration}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-md-3"
                      style={{ paddingLeft: "0", paddingRight: "0" }}
                    >
                      <div className="serveandCallCost">
                        <div className="serverCostContainer mb-2">
                          <img src={moneyBagImg} className=" " alt="" />
                          <div className="serveandCallCostText">
                            <h1 className="fs-6 text-secondary">Dispute</h1>
                            <p className=" fw-bold m-0 ">
                              $ {AmraAdminAgentsOverview.total_dispute_amount}
                            </p>
                          </div>
                        </div>
                        <div className="serverCostContainer">
                          <img src={CallImg} className="" alt="" />
                          <div className="serveandCallCostText">
                            <h1 className="fs-6 text-secondary ">
                              30/60/90 Days Bucket
                            </h1>
                            <p className=" fw-bold m-0 ">
                              {AmraAdminAgentsOverview.total_calls_made}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row m-0">
                    <div className="col-md-8 p-0  ">
                      <div className="w-100 my-3">
                        <div className="callVSMonChart">
                          <div className="callVSMonChartHead">
                            <h5 className="mt-2" style={{ color: "#000" }}>
                              Growth & Revenue{" "}
                            </h5>
                          </div>
                          <Line data={graphData} options={options} />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="amradataTable">
                    <div className="amradataTable_container">
                      <h4></h4>
                      <table id="myTable" className="display">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Agent Name</th>
                            <th> Company Name </th>
                          </tr>
                        </thead>
                        <tbody>
                          {AmraadminData?.map((item, index) => (
                            <tr key={index}>
                              <td>
                                <div className="tableData1 tableData21">
                                  <p style={{ color: "#000" }}>{index + 1}</p>
                                </div>
                              </td>
                              <td>
                                <div className="tableData1 tableData21">
                                  <p style={{ color: "#000" }}>{item.name}</p>
                                </div>
                              </td>
                              <td>
                                <div className="tableData1 tableData21">
                                  <p style={{ color: "#000" }}>
                                    {
                                      controlOptions.find(
                                        (itemqqqq) =>
                                          itemqqqq.id === item.company_id
                                      )?.company_name
                                    }
                                  </p>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div className="AddAgentBtn">
                    <button onClick={handleShowModal1}>Add Agent</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showModal1}
        onHide={handleCloseModal1}
        dialogClassName="custom-modal2"
        centered
        size="xl"
      >
        <Modal.Header>
          <h5 className="mt-3">Add Agent</h5>
        </Modal.Header>
        <Modal.Body>
          <div className="mngUserContainer px-5">
            <form className="needs-validation" id="CarrerformData">
              <div className="row m-0">
                <div className="col-md-6">
                  <div className="mngUserInput">
                    <label>Agent Name</label>
                    <br />
                    <input
                      type="text"
                      className="form-control  trio_mendate"
                      name="name"
                      id="name"
                      maxLength={150}
                      onInput={handleAphabetsChange}
                      placeholder="Enter Full Name"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mngUserInput">
                    <label>Company</label>
                    <br />
                    <Dropdown
                      value={control}
                      onChange={(e) => handleControlChange(e.value)}
                      options={controlOptions}
                      optionLabel="company_name"
                      placeholder="Select Company"
                      className="controlSlct"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="updateCnclebtnDiv">
            <button className="updateCnclebtnCan" onClick={handleCloseModal1}>
              Cancel
            </button>
            <button
              className="updateCnclebtnUp"
              onClick={() =>
                handleSaveChangesdynamic(
                  "CarrerformData",
                  save_update_AgentData
                )
              }
            >
              Add
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default AmraAgents;
