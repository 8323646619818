import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import CenterNavAmraAdmin from "./CenterNavAmraAdmin";
import NAfiaAdminSideBar from "./AmraAdminSideBar";
import $ from "jquery";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, registerables } from "chart.js";
import searchIcon from "../../assets/images/searchIcon.svg";
import "react-circular-progressbar/dist/styles.css";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { Modal } from "react-bootstrap";
import { Dropdown } from "primereact/dropdown";
import Verified from "../../assets/images/verifieGIF.gif";

function AmraAccessControl() {
  ChartJS.register(...registerables);

  const percentage = "80";
  const CircularProgressBar = ({ percentage }) => {
    return (
      <div style={{ width: "50px", margin: "auto" }}>
        <CircularProgressbar
          value={percentage}
          text={`${percentage}%`}
          styles={buildStyles({
            textColor: "#fff",
            pathColor: "#4caf50",
            trailColor: "#d6d6d6",
            textSize: "16px",
          })}
        />
      </div>
    );
  };

  const [control, setControl] = useState(null);
  const controlOptions = [
    { name: "Admin", code: "Admin" },
    { name: "Super Admin", code: "Super Admin" },
    { name: "Sub Admin", code: "Sub Admin" },
    { name: "Manager", code: "Manager" },
  ];

  const handleControlChange = (value) => {
    setControl(value);
  };

  const [AmraadminData, setdataFromAmra] = useState([
    {
      company_name: "XYZ Financial Services",
      customer: "Mr.Shekh Sameer",
      customer_id: "#021530",
      plan: "Basic",
      license: "1",
      avg_call_Dur: "125.33 Hrs",
      boarding_date: "25.06.2023",
      comission: "$265K",
      total_collection: "$5865M",
      bucket_30: "26358K",
      bucket_60: "26358K",
      bucket_90: "2935K",
      l2_esc: "2691K",
    },
    {
      company_name: "XYZ Financial Services",
      customer: "Mr.Shekh Sameer",
      customer_id: "#021530",
      plan: "Basic",
      license: "1",
      avg_call_Dur: "125.33 Hrs",
      boarding_date: "25.06.2023",
      comission: "$265K",
      total_collection: "$5865M",
      bucket_30: "26358K",
      bucket_60: "26358K",
      bucket_90: "2935K",
      l2_esc: "2691K",
    },
    {
      company_name: "XYZ Financial Services",
      customer: "Mr.Shekh Sameer",
      customer_id: "#021530",
      plan: "Basic",
      license: "1",
      avg_call_Dur: "125.33 Hrs",
      boarding_date: "25.06.2023",
      comission: "$265K",
      total_collection: "$5865M",
      bucket_30: "26358K",
      bucket_60: "26358K",
      bucket_90: "2935K",
      l2_esc: "2691K",
    },
    {
      company_name: "XYZ Financial Services",
      customer: "Mr.Shekh Sameer",
      customer_id: "#021530",
      plan: "Basic",
      license: "1",
      avg_call_Dur: "125.33 Hrs",
      boarding_date: "25.06.2023",
      comission: "$265K",
      total_collection: "$5865M",
      bucket_30: "26358K",
      bucket_60: "26358K",
      bucket_90: "2935K",
      l2_esc: "2691K",
    },
  ]);

  const data = {
    labels: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "june",
      "july",
      "august",
      "september",
      "october",
      "november",
      "december",
    ],
    datasets: [
      {
        // label: "Monthly Sales",
        data: [65, 59, 80, 81, 56, 55, 40, 50, 81, 56, 55, 40],
        fill: false, // Fill the area beneath the line
        backgroundColor: "#006AFF40",
        borderColor: "#006AFF", // Line color
        borderWidth: 3, // Line width
        tension: 0.4, // How rounded the corners are on the line
        pointRadius: 3,
      },
    ],
  };

  const options = {
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
      },
    },
  };

  const [progress, setProgress] = useState(0);

  const [query, setQuery] = useState("");
  const [suggestions, setSuggestions] = useState([
    "HDFC Bank",
    "YBL Bank",
    "Yes Bank",
    "Bank of India",
  ]);

  const handleInputChange = (event) => {
    event.preventDefault();
    const newQuery = event.target.value;
    setQuery(newQuery);

    // Filter suggestions based on the current input
    const filteredSuggestions = suggestions.filter((suggestion) =>
      suggestion.toLowerCase().includes(newQuery.toLowerCase())
    );

    setSuggestions(filteredSuggestions);
  };

  const handleSuggestionClick = (suggestion) => {
    setQuery(suggestion);
    // You can perform additional actions when a suggestion is clicked, like submitting a search
  };

  useEffect(() => {
    $("#myTable").DataTable();

    const interval = setInterval(() => {
      // Simulating progress increase over time
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 0 : prevProgress + 10
      );
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="dashboard">
      <Helmet>
        <title>Amra Dashboard</title>
        <meta
          name="description"
          content="AI and Global Reach: The Key to Scalable Solutions in Customer
          Experience"
        />
      </Helmet>
      <div className="dashboard_container">
        <div className="dashboard_wrapper">
          <NAfiaAdminSideBar />
          <div id="content">
            <CenterNavAmraAdmin />

            <div className="Dashpages_contaioner">
              <div className="overview">
                <div className="overviewContaINER">
                  <div className="px-0 my-4">
                    <div className="row m-0">
                      <div className="col-md-2 mb-2">
                        <div className="card p-2 px-3 mx-4 mx-lg-0 mx-md-auto">
                          <p>Knowledge Base</p>
                          <h4>2548k</h4>
                        </div>
                      </div>
                      <div className="col-md-2 mb-2">
                        <div className="card p-2 px-3 mx-4 mx-lg-0 mx-md-auto">
                          <p>Tech Tickets</p>
                          <h4>65k</h4>
                        </div>
                      </div>
                      <div className="col-md-2 mb-2">
                        <div className="card p-2 px-3 mx-4 mx-lg-0 mx-md-auto">
                          <p>Fatal Calls</p>
                          <h4>02k</h4>
                        </div>
                      </div>
                      <div className="col-md-2 mb-2">
                        <div className="card p-2 px-3 mx-4 mx-lg-0 mx-md-auto">
                          <p>Total License</p>
                          <h4>25</h4>
                        </div>
                      </div>
                      <div className="col-md-2 mb-2">
                        <div className="card p-2 px-3 mx-4 mx-lg-0 mx-md-auto">
                          <p>Total Call Recording</p>
                          <h4>25361M</h4>
                        </div>
                      </div>
                      <div className="col-md-2 mb-2">
                        <div className="card p-2 px-3 mx-4 mx-lg-0 mx-md-auto circularBarandtext">
                          <div>
                            <p>Server Status</p>
                            <p
                              style={{ fontSize: "13px" }}
                              className=" m-0 text-success fw-semibold"
                            >
                              80% <span>Good</span>
                            </p>
                          </div>
                          <div>
                            <CircularProgressBar percentage={80} />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2 mb-2">
                        <div className="card p-2 px-3 align-items-center mx-4 mx-lg-0 mx-md-auto">
                          <p>System Update</p>
                        </div>
                      </div>
                      <div className="col-md-2 mb-2">
                        <div className="card p-2 px-3 mx-4 mx-lg-0 mx-md-auto">
                          <p>
                            History Logs <br />
                            (Activity)
                          </p>
                        </div>
                      </div>
                      <div className="col-md-2 mb-2">
                        <div className="card p-2 px-3 mx-4 mx-lg-0 mx-md-auto">
                          <p>
                            System <br />
                            Configuration Details
                          </p>
                        </div>
                      </div>
                      <div className="col-md-2 mb-2">
                        <div className="card p-2 px-3 align-items-center mx-4 mx-md-auto mx-lg-0">
                          <p>New User Update</p>
                        </div>
                      </div>
                      <div className="col-md-2 mb-2">
                        <div className="card p-2 px-3 mx-4 mx-lg-0 mx-md-auto">
                          <p>Total Ticket</p>
                          <h4>989</h4>
                        </div>
                      </div>
                      <div className="col-md-2 mb-2">
                        <div className="card p-2 px-3 mx-4 mx-lg-0 mx-md-auto">
                          <p>Last Backup</p>
                          <p className="m-0">09.06.2023</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="my-2">
                    <div className="row m-0">
                      <div className="col-md-8">
                        <div className="rounded-3 px-2 bg-white p-2">
                          <div className="d-flex justify-content-between align-items-center mx-4 my-2 ">
                            <h1 className="fs-3 fw-semibold ">
                              Growth & Revenue
                            </h1>
                          </div>
                          <div>
                            <Line data={data} options={options} />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="totalRecordCard my-2 my-md-0 h-100">
                          <div className=" align-self-lg-start  text-primary fw-semibold my-2 ">
                            Total Records
                          </div>

                          <div className="searchInputBox">
                            <div className=" position-relative">
                              <input
                                className="searchIcon"
                                type="text"
                                value={query}
                                onChange={handleInputChange}
                                placeholder="Company ID /Company Name "
                              />
                              <img
                                src={searchIcon}
                                className="searchImgPosition"
                                alt=""
                              />
                            </div>

                            <p>
                              {suggestions.map((suggestion, index) => (
                                <li
                                  key={index}
                                  onClick={() =>
                                    handleSuggestionClick(suggestion)
                                  }
                                  className=" bankBox"
                                >
                                  {suggestion}
                                </li>
                              ))}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="amradataTable">
                    <div className="amradataTable_container">
                      <h4></h4>
                      <table id="myTable" className="display">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Company/Customer Name</th>
                            <th>Ave. Call Duration</th>
                            <th>Date of Onboarding</th>
                            <th>Comission</th>
                            <th>Total Collection</th>
                            <th>Bucket Calls</th>
                            <th>L2 Escalation Calls</th>
                          </tr>
                        </thead>
                        <tbody>
                          {AmraadminData.map((item, index) => (
                            <tr key={index}>
                              <td>
                                <div
                                  style={{ height: "65px" }}
                                  className="tableData1 tableData21"
                                >
                                  <p style={{ color: "#000" }}>{index + 1}</p>
                                </div>
                              </td>
                              <td>
                                <div
                                  style={{ height: "65px" }}
                                  className="tableData1 tableData21"
                                >
                                  <p style={{ color: "#000" }}>
                                    {item.company_name}
                                    <br />
                                    {item.customer}
                                    <br />
                                    {item.customer_id}&nbsp;
                                    <span className="planTetx">
                                      {item.plan}
                                    </span>
                                    &nbsp;
                                    <span style={{ fontSize: "10px" }}>
                                      {item.license} Licence
                                    </span>
                                  </p>
                                </div>
                              </td>
                              <td>
                                <div
                                  style={{ height: "65px" }}
                                  className="tableData1 tableData21"
                                >
                                  <p style={{ color: "#000" }}>
                                    {item.avg_call_Dur}
                                  </p>
                                </div>
                              </td>
                              <td>
                                <div
                                  style={{ height: "65px" }}
                                  className="tableData1 tableData21 h-100"
                                >
                                  <p style={{ color: "#000" }}>
                                    {item.boarding_date}
                                  </p>
                                </div>
                              </td>
                              <td>
                                <div
                                  style={{ height: "65px" }}
                                  className="tableData1 tableData21"
                                >
                                  <td style={{ color: "#000" }}>
                                    ${item.comission}
                                  </td>
                                </div>
                              </td>
                              <td>
                                <div
                                  style={{ height: "65px" }}
                                  className="tableData1 tableData21"
                                >
                                  <p style={{ color: "#000" }}>
                                    ${item.total_collection}
                                  </p>
                                </div>
                              </td>
                              <td>
                                <div
                                  style={{ height: "65px" }}
                                  className="tableData1 tableData21"
                                >
                                  <p style={{ color: "#000" }}>
                                    <span style={{ whiteSpace: "nowrap" }}>
                                      30 Days: {item.bucket_30}
                                    </span>
                                    <br />
                                    <span style={{ whiteSpace: "nowrap" }}>
                                      60 Days: {item.bucket_60}
                                    </span>
                                    <br />
                                    <span style={{ whiteSpace: "nowrap" }}>
                                      90 Days: {item.bucket_90}
                                    </span>
                                  </p>
                                </div>
                              </td>
                              <td>
                                <div className="tableData1 tableData21">
                                  <p style={{ color: "#000" }}>{item.l2_esc}</p>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AmraAccessControl;
