import React, { useState } from "react";
import "../../Dasboard/Css/Dashboard.css";
import "../../Dasboard/Css/Bucket30days.css";
import "../../Dasboard/Css/SettingPage.css";
import "../../Dasboard/Css/HelpSupport.css";
import "../../Dasboard/Css/SideBar.css";
import "../../Dasboard/Css/HomePageNafia.css";
import "../../Dasboard/Css/KUKnowledgeBase.css";
import { Link } from "react-router-dom";
import { Chart as ChartJS, registerables } from "chart.js";
import NafiaSideBar from "./NafiaSideBar.js";import Logout from "../../assets/images/giflogout.gif";
import CircleCheck from "../../assets/images/circle_check.svg";
import NotificationsImg from "../../assets/images/notification.svg";
import SideBarControl from "../../assets/images/rightLeftSIdebar.png";
import UserImgPrf from "../../assets/images/userimg45.svg";
import adm_img from "../../assets/images/adm_img.png";
import Calender from "../../assets/images/calDatePick.png";
import searchCrawler from "../../assets/images/searchCrawl.png";
import Dltimg from "../../assets/images/Dltimg.png";
import { Dropdown } from "react-bootstrap";
import internet from "../../assets/images/Internet.png";

function CreateNewTicket() {
  const [selectedHeading, setSelectedHeading] = useState(null);

  const handleHeadingClick = (heading) => {
    // Revert color of the previously clicked heading
    if (selectedHeading) {
      selectedHeading.style.color = "black";
    }

    // Change color of the clicked heading
    heading.style.color = "rgba(126, 50, 203, 1)";

    // Set the clicked heading as the currently selected heading
    setSelectedHeading(heading);
  };

  const [Nafiaadmindata, setdataFromNafia] = useState([]);
  ChartJS.register(...registerables);

  const [overviewShow, setOverviewShow] = useState(true); // Default to 'true'
  const [debtorProfile, setdebtorProfile] = useState(false);

  const [email, setEmail] = useState("");
  const [ticketType, setTicketType] = useState("");
  const [priority, setPriority] = useState("High");
  const [ticketBody, setTicketBody] = useState("");

  // Function to handle form submission
  const handleFormSubmit = () => {
    // Perform actions with form data, e.g., send ticket
    console.log("Form submitted", { email, ticketType, priority, ticketBody });

    setEmail("");
    setTicketType("");
    setPriority("High");
    setTicketBody("");
  };

  const [showListView, setShowListView] = useState(false);
  const toggleListView = () => {
    setShowListView(!showListView);
  };

  const ListView = ({ items }) => {
    return (
      <ul>
        {items.map((item, index) => (
          <ul key={index}>
            <a href={`${item.links}`}>{item.pages}</a>
          </ul>
        ))}
      </ul>
    );
  };
  const items = [
    {
      pages: ["Last Month"],
    },
    {
      pages: ["Month to Date"],
    },
    {
      pages: ["Last 3 Months"],
    },
    {
      pages: ["Last 6 Months"],
    },
    {
      pages: ["Year to Date"],
    },
    {
      pages: ["Lifetime"],
    },
  ];

  var dynamicRows = [
    { url: "http://example.com", status: "Active", character: "3451" },
    { url: "http://example2.com", status: "Inactive", character: "45645" },
    // Add more rows as needed
  ];

  // Function to populate dynamic rows in the table
  function populateDynamicRows() {
    var tbody = document.querySelector("tbody");
    tbody.innerHTML = "";

    dynamicRows.forEach(function (row) {
      var tr = document.createElement("tr");
      tr.innerHTML = `
              <td><input type="checkbox" className="rowCheckbox"></td>
              <td>${row.url}</td>
              <td>${row.status}</td>
              <td>${row.character}</td>
              <td><button onclick="deleteRow(this)"><img src={Dltimg}/></button></td>
          `;
      tbody.appendChild(tr);
    });
  }

  ///   THIS CODE FOR DYNAMIC TABLE CONTENT    ///

  // // Function to update bottom section information
  // function updateBottomSection() {
  //     var totalUrls = dynamicRows.length;
  //     document.getElementById('totalUrls').textContent = totalUrls;
  // }

  // // Function to handle checkbox for selecting all rows
  // document.getElementById('selectAll').addEventListener('change', function () {
  //     var checkboxes = document.querySelectorAll('.rowCheckbox');
  //     checkboxes.forEach(function (checkbox) {
  //         checkbox.checked = document.getElementById('selectAll').checked;
  //     });
  // });

  // // Function to delete a row
  // function deleteRow(button) {
  //     var row = button.parentNode.parentNode;
  //     var index = Array.from(row.parentNode.children).indexOf(row);
  //     dynamicRows.splice(index, 1);
  //     populateDynamicRows();
  //     updateBottomSection();
  // }

  // // Initial setup
  // populateDynamicRows();
  // updateBottomSection();

  return (
    <div className="dashboard">
      <div className="dashboard_container">
        <div className="dashboard_wrapper">
          <NafiaSideBar
            overviewShow={overviewShow}
            setOverviewShow={setOverviewShow}
            debtorProfile={debtorProfile}
            setdebtorProfile={setdebtorProfile}
          />
          <div id="content">
            <nav className="navbar centerNav navbar-expand-lg navbar-light bg-light p-0">
              <div className="navbar_left">
                <div
                  type="button"
                  id="sidebarCollapse1"
                  className="btn_toggle1 btn_toggle1ani"
                >
                  <img src={SideBarControl} alt="img" />
                </div>

                <div className="logo_msgCount">
                  <div className="logo_div">
                    <h4>XYZ Financial Corp.</h4>
                  </div>
                  <div className="msgCount_div">
                    <p>Created: 24/02/2019</p>
                  </div>
                </div>
                <div className="liveDiv">
                  <img src={CircleCheck} alt="icon" />
                  <p>LIVE</p>
                </div>
              </div>

              <div className="navbar_right">
                {/* <div className="navbarRightNoti">
                  <img src={NotificationsImg} alt="icon" />
                </div> */}
                <div className="navbarRightAcc">
                  <img src={adm_img} alt="icon" />
                  <div className="nav-item dropdown">
                    <div
                      className="nav-link dropdown-toggle"
                      id="navbarDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Ashley H.
                    </div>
                    <div
                      className="dropdown-menu prdctList LEFTalINGdROPDOWN"
                      aria-labelledby="navbarDropdown"
                    >
                      {/* <a className="dropdown-item" href="/">
                        Account
                      </a> */}
                      <a className="dropdown-item" href="/">
                        Logout
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </nav>
            <div className="overviewContaINER">
              <div className="overviewHead overviewHeaddp ">
                <h4>Knowledge Update</h4>
                <div className="overviewHeadLeft">
                  <div className="overviewHeadLeftContn">
                    <img
                      src={Calender}
                      alt="Clickable Image"
                      onClick={toggleListView}
                      style={{ cursor: "pointer" }}
                    />
                    <div className="daysListView">
                      {showListView && <ListView items={items} />}
                    </div>
                  </div>
                </div>
              </div>
              <div className="knowUpdHead">
                <div className="d-flex d-flex-overflow">
                  <div
                    className="headsOfKU"
                    onClick={(e) => handleHeadingClick(e.target)}
                  >
                    <Link to="/KnowledgeAddFile">
                      <h5>Knowledge</h5>
                    </Link>
                  </div>
                  <div
                    className="headsOfKU"
                    onClick={(e) => handleHeadingClick(e.target)}
                  >
                    <Link to="/KnowledgeBase">
                      <h5>Knowledge Base</h5>
                    </Link>
                  </div>
                  <div
                    className="headsOfKU"
                    onClick={(e) => handleHeadingClick(e.target)}
                  >
                    <Link to="/KBhealth">
                      <h5>KB Health</h5>
                    </Link>
                  </div>
                  <div
                    className="headsOfKU"
                    onClick={(e) => handleHeadingClick(e.target)}
                  >
                    <h5>Manage KBS</h5>
                  </div>
                  <div
                    className="headsOfKU"
                    onClick={(e) => handleHeadingClick(e.target)}
                  >
                    <h5>KB History</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="crwalingDiv">
              <div className="urlText">
                <p>website url:</p>
              </div>
              <div className="row">
                <div className="URLSercher col-md-5 d-flex">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="www.XYZCompany.in"
                  ></input>
                  <button className="btnCrawler button_crown">
                    <span>
                      <img src={searchCrawler} alt="icon" />
                    </span>
                    <p>Crawler</p>
                  </button>
                </div>
                <div className="addFILE col-md-7 d-flex">
                  <label htmlFor="uploadCSV" className="uploadCSVBtn addFileBtn">
                    Add File
                  </label>
                  <input id="uploadCSV" type="file" hidden />
                </div>
              </div>
            </div>
            <div className="amradataTable">
              <div className="amradataTable_container">
                <div className="AddFileHeader row">
                  <div className="FimgT d-flex col-md-5">
                    <div>
                      <img src={internet} />
                    </div>

                    <div>
                      <h5 className="Filetxt">File</h5>
                    </div>
                  </div>
                  <div className="KB d-flex col-md-7 ">
                    <div className="KBtxt">
                      <h6>KB Name</h6>
                    </div>
                    <div className="KBarea">
                      <input></input>
                    </div>
                    <div className="KBDropdown">
                      <select>
                        <option value="volvo">Category</option>
                        <option value="saab">pdf</option>
                        <option value="opel">svg</option>
                        <option value="audi">jpg</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="amradataTable">
                  <div className="amradataTable_container crwalTable">
                    <table className="addFleTable">
                      <thead className="addFleTableH">
                        <tr>
                          <th>Name</th>
                          <th>Size</th>
                          <th>Character</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Proposal.pdf</td>
                          <td>2.09MB</td>
                          <td>1887</td>
                          <td><button className="PRObtn">Processed</button></td>
                        </tr>
                        <tr>
                          <td>Confrence.pdf</td>
                          <td>12.4MB</td>
                          <td>24732</td>
                          <td><button className="PRObtn">Processed</button></td>
                        </tr>
                      </tbody>
                    </table>
                    
                  </div>
                  <div className="fSection ">
                    <div className="lstSSection d-flex">
                      <div className="updPhone d-flex">
                        <div className="updBy">
                          <h5>Updated By</h5>
                        </div>
                        <div className="KBarea btnaddfile">
                          <input></input>
                        </div>
                      </div>
                      <div className="PN d-flex">
                        <div className="updBy">
                          <h5>Phone No.</h5>
                        </div>
                        <div className="num btnaddfile">
                          <input
                            type="text"
                            id="phoneNumber"
                            placeholder="+971"
                          ></input>
                        </div>
                      </div>
                    </div>
                    <div className="lstSection d-flex">
                      <div className="Tcharacter">
                        <h4 className="headfile">File: 02</h4>
                        <h5>Total Characters: 495</h5>
                        <div className="adFileRmv d-flex">
                          <div className="dltimgg">
                            <img src={Dltimg} />
                          </div>
                          <div className="DStxt">
                            <p>Remove this Data Source</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                    <div className="UPDationbtn1 col-md-12">
                  <button><div className="updkno"><h3>Update Knowledge</h3></div></button>
                  </div>
                </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateNewTicket;
