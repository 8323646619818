import React from "react";
import "../Css/PolicyAndTerms.css";
import Header from "./Header";
import Footer from "./Footer";
import { Helmet } from "react-helmet";

function PrivacyPolicy() {
  return (
    <div className="PrivacyPolicy">
      <Helmet>
        <title>Privacy Policy</title>
        <meta
          name="description"
          content="Use of the Platform and sharing of Information from Visitors and
          Users (as defined below) is conditioned upon your acceptance of
          the terms and conditions contained in this privacy policy as
          available on the Platform (“Privacy Policy”)."
        />
      </Helmet>
      <div className="termsOfUse_container">
        <header className="header_container">
          <Header />
        </header>
        <div className="termsOfUse_container_wrapper container-lg">
          <h2 className="termsOfUseHeading">Privacy Policy</h2>
          <div className="termsOfUse_content col-lg-10">
            <p>
              Triosoft Business Solutions PRIVATE LIMITED is the owner of the
              website domain at{" "}
              <a href="https://Triosoft.ai">Triosoft.ai</a>.
            </p>
            <p>
              Use of the Platform and sharing of Information from Visitors and
              Users (as defined below) is conditioned upon your acceptance of
              the terms and conditions contained in this privacy policy as
              available on the Platform (“Privacy Policy”).
            </p>

            <h4>Definitions</h4>

            <ol>
              <li>
                <p>
                  ‘Agreement’ shall refer to the Letter of Intent, Service
                  Provider Agreement, Non-disclosure Agreement, the Tri-Party
                  NDA and any other document executed between the User and
                  Triosoft.ai that sets out the terms and conditions upon
                  which the User shall use Triosoft.ai’s Services.
                </p>
              </li>
              <li>
                <p>
                  ‘Visitor’ shall refer to any person who browses the Platform
                  and submits any enquiry on the Platform.
                </p>
              </li>
              <li>
                <p>
                  ‘User’ shall refer to any entity such as an individual,
                  company, and partnership firm etc. who enters into the
                  Agreement with{" "}
                  <a href="https://Triosoft.ai">Triosoft.ai</a>. The Visitor
                  and User may hereinafter be referred to as ‘you’ or ‘your’ as
                  the case may be.
                </p>
              </li>
              <li>
                <p>
                  ‘Services’ shall refer to the services provided by
                  <a href="https://Triosoft.ai">Triosoft.ai</a> to the User
                  as detailed in the Agreement.
                </p>
              </li>
            </ol>

            <h4>Introduction and applicability of the Privacy Policy</h4>

            <ol>
              <li>
                <p>
                  We are strongly committed to respecting your online privacy
                  and recognize the need for appropriate protection and
                  management of any personal information collected and/or
                  collated by us.
                </p>
              </li>
              <li>
                <p>
                  The purpose of this Privacy Policy is to ensure that there is
                  an intact charter to collect, use and protect any personal
                  and/or sensitive data collected by us. This Privacy Policy
                  defines our procedure for collection, usage, processing,
                  disclosure and protection of any information obtained by us
                  through the Platform.
                </p>
              </li>
              <li>
                <p>
                  Any reference made to the Privacy Policy in this document
                  shall mean and refer to the latest version of the Privacy
                  Policy.
                </p>
              </li>
              <li>
                <p>
                  If you are a User who avails our Services during the course of
                  your association with us, you may be required to execute
                  certain other agreements and such agreements and this Privacy
                  Policy shall, unless explicitly specified to the contrary,
                  govern your relationship with us.
                </p>
              </li>
            </ol>

            <h4>Disclaimer</h4>

            <ol>
              <li>
                <p>
                  Please be advised that any Information (as defined herein
                  below) procured by us, shall be:
                </p>
                <ul>
                  <li>
                    Processed fairly and lawfully for rendering the Services;
                  </li>
                  <li>
                    Obtained only for specified and lawful purposes, and not be
                    used in any manner which is against the law or policy in
                    force in India (“Applicable Law”);
                  </li>
                  <li>
                    Adequate, relevant and not excessive with the purpose for
                    which it is required;
                  </li>
                  <li>
                    Ability to be reviewed by the User, from time to time and
                    updated if need arises; and not kept longer than for the
                    time which it is required or the purpose for which it is
                    required or as required by the Applicable Law
                  </li>
                </ul>
              </li>
              <li>
                <p>
                  We shall not be liable for any loss or damage sustained
                  because of any disclosure (inadvertent or otherwise) of any
                  data if the same is either (a) required for sharing your
                  information for legitimate purposes; or (b) was affected
                  through no fault, act, or omission of the company.
                </p>
              </li>
              <li>
                <p>
                  By accessing the platform and using the services, you
                  explicitly accept, without limitation or qualification, the
                  data collection, use and transfer in the manner described
                  herein.
                </p>
              </li>
            </ol>
            <p>
              Please read this privacy policy carefully, as it affects your
              rights and liabilities under law.
            </p>

            <h4>Your consent</h4>

            <ol>
              <li>
                <p>
                  Please note that by providing the Information (as enumerated
                  upon herein below) or by consenting to the provision of the
                  Information by your authorized representative, you provide
                  your consent and authorize us to collect, use or disclose
                  Information for the Legitimate Purposes (as defined below) and
                  as stated in this Privacy Policy, the Agreement and as
                  permitted or required by Applicable Law. Moreover, you
                  understand and hereby consent that this Information may be
                  transferred to any third-party user for the purpose of
                  providing Services through the Platform or to any third-party
                  providers for rendering Services (as defined in the
                  Agreement), any jointly developed or marketed services,
                  payment processing, order fulfillment, customer services, data
                  analysis, information technology services and such other
                  services which enable us to provide Services through the
                  Platform.
                </p>
              </li>
              <li>
                <p>
                  This Privacy Policy shall be enforceable against you in the
                  same manner as any other written agreement. By visiting or
                  accessing the Platform and voluntarily providing us with
                  Information (including Personal Data), you are consenting to
                  our use of the Information, in accordance with this Privacy
                  Policy. If you do not agree with this Privacy Policy, you may
                  refuse or withdraw your consent any time, or alternatively
                  choose to not provide us with any Personal Data or Sensitive
                  Personal Information. Under such circumstances, your access to
                  the Services we provide may be limited or we may be unable to
                  render Services.
                </p>
              </li>
            </ol>

            <h4>Types of information collected by us</h4>

            <ol>
              <li>
                <p>
                  “Personal Data” means and includes any information that
                  relates to a natural person through which an individual is
                  identified, such as the name, date of birth, contact details,
                  email address, or any other relevant material provided by a
                  Visitor or User, including but not limited to, information
                  gathered through availing Services.
                </p>
              </li>
              <li>
                <p>
                  “Sensitive Personal Information” shall mean personal
                  information, which consists of information relating to any of
                  the following of an individual:
                </p>
                <ul>
                  <li>insurance data;</li>
                  <li>important dates and events;</li>
                  <li>personal interest;</li>
                  <li>
                    banking and finance related documents (excluding passwords,
                    pins etc.);
                  </li>
                  <li>legal documents, agreements.</li>
                </ul>
              </li>
              <li>
                <p>
                  “Technical Information” means and includes any information
                  gathered through various technologies that may employ cookies,
                  web beacons, or similar technologies to automatically record
                  certain information from your device through which you use the
                  Platform. This technical information may include your Internet
                  Protocol (IP) address, device or browser type, Internet
                  Service Provider (ISP), referring or exit pages, clickstream
                  data, operating system, hardware model, operating system
                  version, unique device identifiers, and mobile network. This
                  data includes usage and log information and user statistics.
                </p>
              </li>
              <li>
                <p>
                  “Locational Information” shall mean and include the
                  geo-information obtained through GPS or other means, such as
                  the geographical location of the User and sensor data from the
                  device on which you access the Services.
                </p>
              </li>
              <li>
                <p>“Non-Personal Information”</p>
                <ul>
                  <li>
                    “Information through use of our Service” means and includes
                    information which is shared with us to avail our Services.
                  </li>
                  <li>
                    “Non-Personal Information” means and includes any
                    information that does not reveal your specific identity,
                    such as:
                    <ul>
                      <li>Browser information</li>
                      <li>
                        Information collected through Cookies (as defined below)
                      </li>
                      <li>Pixel tags and other technologies</li>
                      <li>Demographic information</li>
                      <li>Crash reports</li>
                      <li>System activity</li>
                      <li>Device state information, etc.</li>
                    </ul>
                    As is true with most websites and mobile applications,
                    <a href="https://Triosoft.ai">Triosoft.ai</a> gathers
                    some information automatically when you visit the Platform.
                    When you use the Platform, we may collect certain
                    information about your computer or mobile to facilitate,
                    evaluate, and verify your use of the Platform. For example,
                    we may store environmental variables, such as browser type,
                    operating system, speed of the central processing unit
                    (CPU), referring or exit web pages, click patterns, and the
                    internet protocol (IP) address of your computer.
                  </li>
                </ul>
              </li>
              <li>
                <p>
                  (The Personal Data, Sensitive Personal Information, Technical
                  Information, Locational Information, and Non-Personal
                  Information are collectively referred to as “Information).
                </p>
              </li>
            </ol>

            <h4>Purpose of Collection and Usage of this Information:</h4>
            <ol>
              <li>
                <p>
                  The Information collected by us shall be used for availing our
                  Services and utilised for other functions, including but not
                  limited to:
                </p>
                <ul>
                  <li>To render Services;</li>
                  <li>For maintaining the Platform;</li>
                  <li>
                    To evaluate the quality and competence of our personnel;
                  </li>
                  <li>
                    To resolve any complaints you may have and ensure that you
                    receive the highest quality of Services;
                  </li>
                  <li>Notifying you about changes to our Platform;</li>
                  <li>
                    Allowing you to participate in interactive features of our
                    Platform when you choose to do so;
                  </li>
                  <li>
                    Providing analysis or valuable information so that we can
                    improve the Platform;
                  </li>
                  <li>Monitoring the usage of the Platform;</li>
                  <li>
                    Detecting, preventing and addressing technical issues;
                  </li>
                  <li>
                    To conduct crash analytics in the event the Platform and/or
                    Service crashes;
                  </li>
                  <li>Analyze usage patterns and user preferences;</li>
                  <li>Improve user experience;</li>
                  <li>Notify you about new products and features.</li>
                </ul>
              </li>
              <li>
                <p>
                  Business or Research Purposes: The Information saved and
                  except Personal Data, is used for business or research
                  purposes, including improving and customizing the Platform for
                  ease of use and the products and services offered by us. We
                  may archive this information to use it for future
                  communications for providing updates and/or surveys.
                </p>
              </li>
              <li>
                <p>
                  Aggregating Information / Anonymized data: We may aggregate
                  Information and analyze it to further accentuate the level of
                  services we offer to our customers. This Information includes
                  average number of Users of the Platform, the average clicks of
                  the services, the features used, the response rate, etc. and
                  other such statistics regarding groups or individuals. In
                  doing so, we shall not be making disclosures of any Personal
                  Data as defined above.
                </p>
              </li>
            </ol>
            <p>(Collectively referred to as “Legitimate Purposes”)</p>

            <h4>Disclosure and Sharing of Information</h4>

            <ol>
              <li>
                <p>
                  We do not rent, sell, disclose or share any Information that
                  we collect from you, with third parties, save and except in
                  order to provide you with the Services or for the Legitimate
                  Purposes as specified above. Any such disclosure, if made,
                  shall be in accordance with this Privacy Policy and as per the
                  procedure prescribed by law and in compliance with our legal
                  obligations.
                </p>
              </li>
              <li>
                <p>
                  Additionally, we may share your Information in circumstances
                  and for the purposes as specified hereunder:
                </p>
                <ul>
                  <li>
                    <p>
                      We shall share the information with the third-party
                      service providers/vendors, to provide you with the
                      Services and to effectuate any activities that fall under
                      the Legitimate Purpose for which such Information has been
                      collected.
                    </p>
                  </li>
                  <li>
                    <p>
                      When compelled by law: We may disclose any Information
                      provided by you on the Platform as may be deemed to be
                      necessary or appropriate: Under Applicable law, including
                      laws outside your country of residence; To comply with the
                      legal process; To respond to requests from public and
                      government authorities including public and government
                      authorities outside your country of residence; To protect
                      our operations or those of any of our affiliates; To
                      protect our rights, privacy, safety or property, and/that
                      of our affiliates, you or others; To allow us to pursue
                      available remedies or limit the damages that we may
                      sustain; To protect against legal liability; To protect
                      the personal safety of Users of the Platform; To prevent
                      or investigate possible wrongdoing in connection with the
                      Platform.
                    </p>
                  </li>
                  <li>
                    <p>
                      Merger or Acquisition: We may share Information upon
                      merger or acquisition of{" "}
                      <a href="https://Triosoft.ai">Triosoft.ai</a> with
                      another company. We shall transmit and transfer the
                      Information upon acquisition or merger of{" "}
                      <a href="https://Triosoft.ai">Triosoft.ai</a> with
                      another company;
                    </p>
                  </li>
                  <li>
                    <p>
                      With our service providers: We may share Information with
                      other service providers on a need-to-know basis, subject
                      to obligations of confidentiality for provision of
                      Services. We hereby clarify that{" "}
                      <a href="https://Triosoft.ai">Triosoft.ai</a> works
                      with institutions, vendors, partners, advertisers, and
                      other service providers, including (but not limited) to
                      those who provide products or services such as contact
                      Information verification, website hosting, data analysis,
                      providing infrastructure, information technology services,
                      auditing services and other similar services, in different
                      industries and categories of business by virtue of lawful
                      contracts instituted between such third parties and
                      <a href="https://Triosoft.ai">Triosoft.ai</a> to
                      improve our product and services. Accordingly, we may
                      share your Information with such service provider in order
                      to provide you with Services;
                    </p>
                  </li>
                  <li>
                    <p>
                      Employees/Agents of{" "}
                      <a href="https://Triosoft.ai">Triosoft.ai</a> We
                      follow a strict confidentiality policy with regard to
                      disclosure of confidential information to our employees or
                      other personnel. There may be situations, where we may
                      disclose the confidential information only to those of our
                      employees and other personnel on a need-to-know basis. Any
                      breach of confidential information by the employees,
                      personnel within{" "}
                      <a href="https://Triosoft.ai">Triosoft.ai</a> is dealt
                      stringently by us.
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <p>
                  Except for the Information disclosed pursuant to the above,
                  <a href="https://Triosoft.ai">Triosoft.ai</a> may share
                  Information if you authorize us to do so.
                </p>
              </li>
            </ol>

            <h4>Transfer of Information</h4>

            <ol>
              <li>
                Your information may be transferred to, and maintained on,
                computers located outside of your state, province, country, or
                other governmental jurisdiction where the data protection laws
                may differ from those of your jurisdiction.
              </li>
              <li>
                If you are located outside India and choose to provide
                information to us, please note that we may transfer the data to
                India to process the information.
              </li>
              <li>
                Your consent to this Privacy Policy followed by your submission
                of such information represents your agreement to that transfer.
              </li>
              <li>
                We will take all steps reasonably necessary to ensure that your
                data is treated securely and in accordance with this Privacy
                Policy, and no transfer of your data will take place to an
                organization or a country unless there are adequate controls in
                place, including the security of your data.
              </li>
            </ol>

            <h4>Your Rights</h4>

            <p>
              You can always choose not to provide the requested information to
              us, it may however result in you not availing certain features or
              the entire, of our services. You retain several rights in relation
              to your Personal Data as provided under Applicable Law. These may
              include the rights to
            </p>
            <ol>
              <li>
                Access, confirm, and review Personal Data you may have provided;
              </li>
              <li>
                Correct Personal Data that may be inaccurate or irrelevant;
              </li>
              <li>
                Delete and erase your Personal Data from the publicly available
                pages of the Platform;
              </li>
              <li>
                Receive Personal Data we hold about you in a portable format;
              </li>
              <li>
                Object to or restrict any form of processing you may not be
                comfortable with.
              </li>
            </ol>
            <p>
              In order to exercise these rights, please contact us on the email
              address provided at Discrepancies and Grievances clause if you
              want to withdraw your consent or raise any objection to the use of
              your information for receiving any direct marketing information to
              which you previously opted-in, you can do so by contacting our
              customer support at above mentioned addresses. If you withdraw
              your consent or object to the use of your information, our use of
              the information provided by you before your withdrawal/objection
              shall still be lawful.
            </p>

            <h4>Children’s Privacy</h4>

            <p>
              Our Platform and Services are not meant for use by children and we
              knowingly do not collect Information of/from children. If it comes
              to our notice that we have collected Information from/of children,
              we shall take steps to remove such Information from our servers.
              If you believe that we might have any Information that may have
              been collected from a child or has been provided by a child,
              please write to us at the email id provided in Discrepancies and
              Grievances clause.
            </p>

            <h4>Contact You</h4>

            <p>
              You agree that we may contact you through telephone, email, SMS,
              or any other means of communication for the purpose of:
            </p>
            <ol>
              <li>Rendering Services;</li>
              <li>Imparting product/Service-related information;</li>
              <li>
                Obtaining feedback in relation to the Platform or our Services;
              </li>
              <li>
                Any events, promotional offers, or initiatives that you may be
                interested in as part of the Services offered by us or
                associated third-parties;
              </li>
              <li>
                Resolving any complaints, information requests, or queries by
                Users.
              </li>
            </ol>
            <p>
              You agree that if you have registered yourself under Do Not
              Disturb (DND) or Do Not Call (DNC) or National Customer Preference
              Register (NCPR) services, you still authorize us to contact you
              for the above-mentioned purposes till your Account subsists.
            </p>

            <h4>Control the collection or use of the Information</h4>

            <p>
              If you have any reservations, constraints or apprehensions
              regarding the access to, collection, storage, or any other use of
              the Information which you have provided to us, you may withdraw
              your consent in the manner as set out in Discrepancies and
              Grievances.
            </p>

            <h4>Retention of Information</h4>

            <p>
              All Information provided by you, save and except upon withdrawal
              or termination, shall be retained in locations outside the direct
              control of <a href="https://Triosoft.ai">Triosoft.ai</a> (for
              instance, on servers or databases co-locates with hosting
              providers). We will delete Information based on a request received
              from you within a reasonable period and latest within thirty (30)
              days of receiving a deletion request. However, we may retain such
              portion of the Information and for such periods as may be required
              under Applicable Law. Not withstanding anything contained herein,{" "}
              <a href="https://Triosoft.ai">Triosoft.ai</a> may retain data
              after account deletion for reasons including but limited to the
              following purposes: If there is an unresolved issue relating to
              your account, or an unresolved claim or dispute; If we are
              required to by Applicable Law, and/or in aggregated and/or
              anonymized form, or{" "}
              <a href="https://Triosoft.ai">Triosoft.ai</a> may also retain
              certain information if necessary, for its legitimate business
              interests.
            </p>

            <h4>Cookies and other Tracking Technologies</h4>

            <p>
              Our Platform may utilize “cookies” and other Technical
              Information. “Cookies” are a small text file consisting of
              alphanumeric numbers used to collect the Information about
              Platform activity. The Technical Information helps us analyse web
              traffic and helps you by customizing the Platform to your
              preferences. Cookies in no way gives us access to your computer or
              mobile device. In relation to Cookies, you can deny access to the
              installation of the Cookies by modifying the settings on your web
              browser, however, this may prevent you from taking full advantage
              of the Platform.
            </p>
            <p>
              Our use of Cookies and Technical Information allows us to improve
              Platform and your experience of Platform and Services. We may also
              analyse Technical Information that does not contain Personal Data
              or Sensitive Personal Information for trends and statistics.
            </p>

            <h4>Third Party Services</h4>

            <p>
              We may use your Information to send you a promotional information
              about third parties which, we think you may find interesting, if
              you tell us that you wish this to happen. We shall not be
              responsible for any disclosure of Information due to unauthorized
              third-party access or other acts of third parties or acts or
              omissions beyond our reasonable control and you agree that you
              will not hold us responsible for any breach of security unless
              such breach has been caused as a direct result of our negligence
              or wilful default.
            </p>
            <p>
              Once you leave the Platform, we are not liable for any use/
              storage/ processing/ collection of your information obtained by
              any third-party websites or payment facilitators or provided by
              you to these third-parties or payment facilitators. Such entities
              and their respective websites/platforms may be governed by their
              own “Privacy Policy” and “Terms of Service”, which are beyond our
              control.
            </p>

            <h4>Data Security</h4>

            <p>
              You agree and accept that your Information may be stored in
              third-party cloud service infrastructure providers. While all
              reasonable attempts have been taken from our end to ensure the
              safe and secure storage of your data, we shall not be liable for
              any data breach on the part of the third-party cloud service
              infrastructure provider that was beyond our control. In addition
              to the security measures put in place by the third-party cloud
              service infrastructure provider for safe and secure storage of
              your Information, we use certain physical, managerial, technical
              or operational safeguards as per industry standards and
              established best practices to protect the Information we collect.
              We use reasonable security practices and procedures and use secure
              servers as mandated under Applicable Laws for the protection of
              your Information. We review our Information collection, storage,
              and processing practices, including physical security measures to
              guard against unauthorized access to systems. However, as
              effective as these measures are, no security system is
              impenetrable. We cannot guarantee the security of our database,
              nor can we guarantee that the Information you supply will not be
              intercepted while being transmitted to us over the internet. You
              accept the inherent security implications of data transmission
              over the internet and the internet cannot always be guaranteed as
              completely secure. Therefore, your use of the Platform will be at
              your own risk. If you have any concerns, please feel free to
              contact us at the details given in Discrepancies and Grievances
              clause.
            </p>

            <h4>Changes and updates to Policy</h4>

            <p>
              We may modify or revise the Privacy Policy from time to time and
              shall accordingly notify you of any changes to the Privacy Policy
              by posting the revised Privacy Policy on the Platform with an
              updated date of revision. We shall endeavor to review, revise,
              update, modify, amend or correct the Privacy Policy on a regular
              and routine basis, especially whenever a significant update is
              made to the technology employed by us. You must periodically
              review the Privacy Policy for the latest information on our
              privacy practices. In the event you continue to use the Platform
              and our services after any update in the Privacy Policy, your use
              of the Platform shall be subject to such updated privacy policy.
              Your continued usage of the Services, post any amendment would
              deem to mean that you accept and understand the revised Privacy
              Policy. Further, we retain the right at any time to deny or
              suspend access to all, or any part of, the Service and/or access
              to the Platform to anyone who we reasonably believe has violated
              any provision of this Privacy Policy.
            </p>

            <h4>Miscellaneous</h4>

            <p>
              The invalidity or unenforceability of any part of this privacy
              policy shall not prejudice or affect the validity or
              enforceability of the remainder of this privacy policy. This
              privacy policy does not apply to any information other than the
              information collected by us through the platform. This privacy
              policy shall be inapplicable to any unsolicited information you
              provide us through the platform or through any other means. All
              unsolicited information shall be deemed to be non-confidential and
              we shall be free to use and/Or disclose such unsolicited
              information without any limitations. The rights and remedies
              available under this policy may be exercised as often as necessary
              and are cumulative and not exclusive of rights or remedies
              provided by law.The rights under this policy may be waived only in
              writing. Delay in exercising or non-exercise of any such right or
              remedy does not constitute a waiver of that right or remedy, or
              any other right or remedy.
            </p>

            <h4>Indemnification</h4>

            <p>
              You shall defend, indemnify, and hold harmless{" "}
              <a href="https://Triosoft.ai">Triosoft.ai</a>, its
              affiliates/subsidiaries/joint venture partners and each of its,
              and its affiliates’/subsidiaries/joint venture partners’
              employees, contractors, directors, suppliers and representatives
              from all liabilities, losses, claims, and expenses, including
              reasonable attorneys’ fees, that arise from or relate to (i) your
              use or misuse of, or access to, the Services or Platform; or (ii)
              your violation of the Privacy Policy; or any applicable law,
              contract, policy, regulation or other obligation. We reserve the
              right to assume the exclusive defense and control of any matter
              otherwise subject to indemnification by you, in which event you
              will assist and cooperate with us in connection therewith.
            </p>

            <h4>Limitation of Liability</h4>

            <p>
              To the fullest extent permitted by law, in no event shall the
              company (including its directors, employees, agents, sponsors,
              partners, suppliers, content providers, licensors or resellers,)
              be liable under contract, tort, strict liability, negligence or
              any other legal or equitable theory with respect to the services
              (i) For any lost profits, data loss, loss of goodwill or
              opportunity, or special, indirect, incidental, punitive, or
              consequential damages of any kind whatsoever, (ii) For your
              reliance on the services (iii) For any damages (iv) For any matter
              beyond its or their reasonable control, even if company has been
              advised of the possibility of any of the aforementioned damages.
            </p>

            <h4>Exemptions to Liability of Company</h4>

            <p>
              You further agree and confirm that{" "}
              <a href="https://Triosoft.ai">Triosoft.ai</a> shall not be
              responsible, in any manner whatsoever, for any unavailability of
              the Platform, or failure to meet its obligations under the Privacy
              Policy, which may be caused, directly or indirectly, due to:
            </p>
            <ol>
              <li>Your failure to cooperate.</li>
              <li>Your unavailability and/or unresponsiveness.</li>
              <li>
                Your failure to provide accurate and complete information.
              </li>
              <li>
                Your failure to provide or facilitate the submission of
                Information in a timely manner.
              </li>
              <li>
                Any event beyond{" "}
                <a href="https://Triosoft.ai">Triosoft.ai</a>’s reasonable
                control.
              </li>
            </ol>
            <h4>Your acceptance of the Privacy Policy</h4>

            <p>
              By using or visiting the platform and using our services, you
              accept and consent to this privacy policy agreement. If you do not
              agree to any of these terms, please do not use this platform or
              services.
            </p>
          </div>
        </div>
        <footer className="footer_area section_padding_130_0 px-lg-5 px-md-4 px-sm-3 px-1">
          <Footer />
        </footer>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
