import React from "react";
import "../Css/Home2.css";
import Email from "../assets/images/email.svg";
import Phone from "../assets/images/phone.svg";
import Place from "../assets/images/place.svg";
import Youtube from "../assets/images/youtube.svg";
import Instagram from "../assets/images/Instagram.svg";
import Twitter from "../assets/images/twitter1.svg";
import LinkedIn from "../assets/images/Linkedin.svg";
import Skype from "../assets/images/skype.svg";
import LogoMain from "../assets/images/logoNew.png";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <>
      <div className="horixon_footer"></div>
      <div className="footer_container">
        <div className="row">
          <div className="col-lg-5 col-sm-9 cntct_div">
            <div className="single-footer-widget section_padding_0_130">
              <div className="img3">
                <Link to="/#">
                  <img style={{width:"180px"}} src={LogoMain} alt="Card 1" />
                </Link>
              </div>

              <h6>Contact Us </h6>

              <div className="cntct_text cntct">
                <img src={Email} alt="icon" />
                <p>support@triosoft.ai</p>
              </div>

              <div className="cntct_text cntct">
                <img src={Phone} alt="icon" />{" "}
                <p>+917880016644</p>
              </div>

              <div className="cntct_text cntct">
                <img src={Place} alt="icon" />
                <p>
                  Triosoft Technologies Pvt. Ltd., Plot No. 96, 2nd Floor, Gargi
                  Rani Complex, Opposite Indian Overseas Bank, M.P. Nagar,
                  Zone-II, Bhopal, Madhya Pradesh India- 462011
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-2 col-sm-4 col-6">
            <div className="single-footer-widget section_padding_0_130">
              <h6 className="widget-title">Company</h6>

              <div className="footer_menu">
                <ul>
                  <li>
                    <Link to="/Nafia_product">Product</Link>
                  </li>

                  <li>
                    <Link to="/company">Company</Link>
                  </li>

                  <li>
                    <Link to="/resources">Resources</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="col-lg-2 col-sm-4 col-6">
            <div className="single-footer-widget section_padding_0_130">
              <h6 className="widget-title">Useful links</h6>

              <div className="footer_menu">
                <ul>
                  <li>
                    <Link to="/privacy_policy">Privacy Policy</Link>
                  </li>

                  <li>
                    <Link to="/terms_&_conditions">Terms &amp; Conditions</Link>
                  </li>

                  <li>
                    <Link to="/resources">Blog</Link>
                  </li>

                  <li>
                    <Link to="/careers">Career</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-sm-4">
            <div className="single-footer-widget mediaLinks section_padding_0_130">
              <h6 className="widget-title">Follow Us On</h6>

              <ul className="footer_menu">
                <li className="cntct_text">
                  <img src={Youtube} alt="icon" />
                  <a href="https://www.youtube.com/channel/UCjLMQ71MCxeJylzSrak5B1g">
                    Youtube
                  </a>
                </li>

                <li className="cntct_text">
                  <img src={Instagram} alt="icon" />
                  <a
                    href="https://instagram.com/technotaskglobal?igshid=MzRlODBiNWFlZA==
"
                  >
                    Instagram
                  </a>
                </li>

                <li className="cntct_text">
                  <img src={LinkedIn} alt="icon" />
                  <a href="https://www.linkedin.com/company/technotaskbusinesssolutions/">
                    LinkedIn
                  </a>
                </li>

                <li className="cntct_text">
                  <img src={Twitter} alt="icon" />
                  <a href="https://twitter.com/technotask">Twitter</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
