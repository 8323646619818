import React, { useState, useEffect } from "react";
import RightArrow from "../../assets/images/rgtarow.svg";
import OrngImg from "../../assets/images/orangeProfile.svg";
import SearchIcon from "../../assets/images/searchIcon.svg";
import { Link } from "react-router-dom";
import SideBar from "./SideBar";
import { Helmet } from "react-helmet";
import "../Css/DebtorProfile.css";
import ProgressBar from "./ProgressBar";
import MyChart from "./Mychart";
import Expand1 from "../../assets/images/expand 1.svg";
import MoreIcon from "../../assets/images/more_vertical.svg";
import Technotask from "../../assets/images/TechnotaskLogo12.png";
import FilterSvg from "../../assets/images/Funnel.svg";
import PatientProfileModal from "./PatientProfileModal.js";
import Modal from "react-bootstrap/Modal";
import { Dropdown } from "primereact/dropdown";
import DownloadBtn1 from "../../assets/images/downloadwhite12.png";
import {
  csv_amra_user_upload,
  get_debtor,
  server_post_data_shubham,
  server_get_data_master,
  USER_DATA_FARIDA,
  get_all_Debtorprofile,
  API_LINK_KEY,
} from "../../KeyConnection/KeysService.js";

import $ from "jquery";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import CenterNavbar from "./CenterNavbar.js";
function DebtorProfile() {
  const [isLoading, setLoading] = useState(false);
  const [RUPESS_ICON, setRUPESS_ICON] = useState();
  const [loanID, setLoanID] = useState("");
  const [userNumber, setuserNumber] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedCityId, setSelectedCityId] = useState(null);
  const [selectedBucketId, setSelectedBucketId] = useState(null);
  const [timeList, SettimeList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showModalProfile, setShowModalProfile] = useState(false);
  const company_id = localStorage.getItem("company_id");

  const [debtorDataForDateRange, setDebtorDataForDateRange] = useState({
    debtor_emi_amt: 0,
    recovery_amount: 0,
    outstanding_amount: 0,
    dispute_amt: 0,
    admin_commission: 0,
    total_calls_made: 0,
    answered_calls: 0,
    dropped_calls: 0,
    promise_to_pay: 0,
    unable_to_pay: 0,
    rescheduled: 0,
    escalation: 0,
    part_payment: 0,
    technical_issue: 0,
    dispute: 0,
    pre_bucket_user_count: 0,
    days_30_bucket_user_count: 0,
    days_60_bucket_user_count: 0,
    days_90_bucket_user_count: 0,
  });
  const [debtorDataCityWise, setDebtorDataCityWise] = useState([]);

  const [debtorProfileData, setDebtorProfileData] = useState();

  const [faridaPatientAggregate, setFaridaPatientAggregate] = useState({
    patient_count: 3,
    expiry_due_count: 2,
    expired_count: 2,
    new_count: 1,
    patient_list: [
      {
        user_id: 1,
        user_name: "Akshit Gupta",
        mobile_no: 919910564180,
        emirates_id: 123412341234123,
        insurance_expiry_date: "2023-12-08T12:54:59Z",
        created_at: "2023-11-28T12:55:01.080502Z",
      },
    ],
  });

  useEffect(() => {
    const start_date = "2023-12-28";
    const end_date = "2024-01-06";
    // const flag = "3";
    // let call_id = "0";
    const company_id = "3";
    const bot_id = "1";
    // master_data_get(start_date, end_date, flag, call_id);
    let dates = {
      startDate: start_date,
      endDate: end_date,
    };
    showFaridaPatientData(USER_DATA_FARIDA, dates, company_id, bot_id);
  }, []);

  const showFaridaPatientData = async (
    URL_FOR,
    dateRange,
    company_id,
    bot_id
  ) => {
    setLoading(true);
    let target_url = `${URL_FOR}${dateRange.startDate}/${dateRange.endDate}/${company_id}/${bot_id}/`;
    console.log(target_url);
    try {
      const res = await server_get_data_master(target_url);
      const data = await res.json();
      console.log(data.data);
      setFaridaPatientAggregate(data.data);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  const master_data_get = async (start_date, end_date, flag, call_id) => {
    setLoading(true);
    const fd = new FormData();
    fd.append("admin_id", company_id);
    fd.append("start_date", start_date);
    fd.append("end_date", end_date);
    fd.append("flag", flag);
    fd.append("call_id", call_id);
    await server_post_data_shubham(`${get_all_Debtorprofile}${company_id}/`, fd)
      .then((Response) => {
        if (Response.data.error) {
          alert(Response.data.message);
        } else {
          const city_wise_data = Response.data.message.data_silder_image2;
          setdataFromAmra(Response.data.message.data_debtors);
          let amount_plus = 0;
          setRUPESS_ICON(Response.data.message.icon_money);
          SettimeList(city_wise_data);
          const obj = {
            debtor_emi_amt: 0,
            recovery_amount: 0,
            outstanding_amount: 0,
            dispute_amt: 0,
            admin_commission: 0,
            total_calls_made: 0,
            answered_calls: 0,
            dropped_calls: 0,
            promise_to_pay: 0,
            unable_to_pay: 0,
            rescheduled: 0,
            escalation: 0,
            part_payment: 0,
            technical_issue: 0,
            dispute: 0,
            pre_bucket_user_count: 0,
            days_30_bucket_user_count: 0,
            days_60_bucket_user_count: 0,
            days_90_bucket_user_count: 0,
          };
          let data = Response.data.message.data_debtors;
          for (let i = 0; i < data.length; i++) {
            const debtor = data[i];
            // cities
            let city = city_wise_data.filter((item) => {
              return debtor.city_id === item.id;
            });
            amount_plus += Number(debtor.debtor_emi_amt);
            // debtor data
            debtor.debtor_history.forEach((instance) => {
              if (debtor.debtor_history.length > 0) {
                if (instance.debtor_bucket === "pre due") {
                  obj.pre_bucket_user_count += 1;
                } else if (instance.debtor_bucket === "30 days") {
                  obj.days_30_bucket_user_count += 1;
                } else if (instance.debtor_bucket === "60 days") {
                  obj.days_60_bucket_user_count += 1;
                } else if (instance.debtor_bucket === "90 days") {
                  obj.days_90_bucket_user_count += 1;
                }

                obj.debtor_emi_amt += Number(instance.debtor_emi_amt);
                obj.recovery_amount += Number(instance.recovery_amount);
                obj.dispute_amt += Number(instance.dispute_amt);
                obj.admin_commission += Number(instance.admin_commission);

                // call_flag = 0, 1, 2 : 0 => call not made, 1 => call made, 2 => answered call
                if (Number(instance.call_flag) > 0) {
                  obj.total_calls_made += 1;
                }
                if (instance.call_flag >= 2) {
                  obj.answered_calls += 1;
                }
                if (instance.call_flag === 3) {
                  obj.promise_to_pay += 1;
                }
                if (instance.call_flag === 4) {
                  obj.unable_to_pay += 1;
                }
                if (instance.call_flag === 5) {
                  obj.rescheduled += 1;
                }
                if (instance.call_flag === 6) {
                  obj.escalation += 1;
                }
                if (instance.call_flag === 7) {
                  obj.part_payment += 1;
                }
                if (instance.call_flag === 8) {
                  obj.technical_issue += 1;
                }
                if (instance.call_flag === 9) {
                  obj.dispute += 1;
                }

                city[0].debtor_emi_amt += Number(instance.debtor_emi_amt);
                city[0].recovery_amount +=
                  Number(instance.recovery_amount) || 0;
              }
            });

            // calculate bucket using due data
            let now = Date.now();
            let dueDate = new Date(debtor.due_date);
            if (debtor.last_payment_date) {
              dueDate = new Date(debtor.last_payment_date);
            }
            let days = Math.ceil((now - dueDate) / (1000 * 60 * 60 * 24));
            if (days <= 0) {
              obj.pre_bucket_user_count += 1;
            }

            if (debtor.debtor_history.length == 0) {
              if (days > 0 && days < 31) {
                obj.days_30_bucket_user_count += 1;
              } else if (days > 30 && days < 61) {
                obj.days_60_bucket_user_count += 1;
              } else if (days > 60) {
                obj.days_90_bucket_user_count += 1;
              }
            }
          }
          obj.outstanding_amount = obj.debtor_emi_amt - obj.recovery_amount;
          obj.dropped_calls = obj.total_calls_made - obj.answered_calls;
          city_wise_data.forEach((city) => {
            console.log(city);
            city.average_recovery_rate =
              parseFloat(
                ((city.recovery_amount / city.debtor_emi_amt) * 100).toFixed(2)
              ) || 0;
          });

          //    console.log(city_wise_data);
          setDebtorDataForDateRange(obj);
          setDebtorDataCityWise(city_wise_data);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const searchDebtorProfile = async (click_type) => {
    // Fetch data from the API endpoint
    if (
      ((loanID !== "" || userNumber !== "") && click_type === "no") ||
      click_type !== "no"
    ) {
      let url_for = get_debtor;
      if (click_type === "no") {
        if (userNumber.length === 0) {
          url_for += loanID;
        } else {
          url_for += userNumber;
        }
      } else {
        url_for += click_type;
      }
    }

    setDebtorProfileData({
      customer_id: 12334,
      name: "",
      mobile: "",
      email: "",
      gender: "",
      alt_mobile: "NA",
      dob: "NA",
      age: "NA",
      marital_status: "NA",
      highest_qualification: "NA",
      father_name: "NA",
      mother_name: "NA",
      permanent_add: "",
      current_add: "",
    });
    setShowModalProfile(true);

    //   setDebtorProfileData(debtor_profile);

    //   await fetch(url_for)
    //     .then((response) => response.json())
    //     .then((data) => {
    //       let debtor = data.data;
    //       // debtor profile
    //       let gender = "Other";
    //       if (debtor.debtor_gender === "M") {
    //         gender = "Male";
    //       } else if (debtor.debtor_gender === "F") {
    //         gender = "Female";
    //       }
    //       console.log(debtor);

    //       const filteredData = timeList.filter(
    //         (item) => debtor.city_id === item.id
    //       );

    //       let debtor_profile = {
    //         customer_id: debtor.debtor_id,
    //         name: debtor.debtor_name,
    //         mobile: debtor.debtor_no,
    //         email: debtor.debtor_email,
    //         gender,
    //         alt_mobile: "NA",
    //         dob: "NA",
    //         age: "NA",
    //         marital_status: "NA",
    //         highest_qualification: "NA",
    //         father_name: "NA",
    //         mother_name: "NA",
    //         permanent_add: filteredData[0].name,
    //         current_add: filteredData[0].name,
    //       };
    //   setShowModalProfile(true);
    //   setDebtorProfileData(debtor_profile);

    //   const button = document.getElementById("modelclick");
    //   if (button) {
    //     button.click();
    //   }
    // })
    // .catch((err) => {
    //   setShowModalProfile(false);
    // alert(
    //   "Cannot find call details corresponding to given Loan ID or Mobile number"
    // );
    // });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0]; // Get the selected file
    setSelectedFile(file);
  };

  const validateForm = () => {
    var isValid = true;
    if (isValid === true) {
      if (isValid === true) {
        //console.log(selectedCityId.id);
        const formData = new FormData(document.getElementById("save_form")); // Get form data
        formData.append("csvfile", selectedFile);
        formData.append("city_id", selectedCityId.id);
        formData.append("company_id", company_id);
        formData.append("bucket", "30");
        saving_data(formData); // Pass the form data to the saving_data function
      }
    }
  };

  const saving_data = async (jsonData) => {
    setLoading(true);
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    axios
      .post(csv_amra_user_upload, jsonData, headers)
      .then(function (response) {
        setLoading(false);
        if (response.data.status != "success") {
          showErrorMsg(response.data.message);
        } else {
          showSuccessMsg();
          closeModal();
        }
      })
      .catch(function (error) {
        showErrorMsg(error.message);
        setLoading(false);
      });
  };

  const handleDownload = async () => {
    const csvUrl = API_LINK_KEY + "mediafiles/media/sample.csv";

    try {
      const response = await axios.get(csvUrl, { responseType: "blob" });

      // Create a download link
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.download = "sample.csv";

      // Append the link to the document
      document.body.appendChild(link);

      // Trigger the click event on the link
      link.click();

      // Remove the link from the document
      document.body.removeChild(link);
    } catch (error) {
      // console.error("Error downloading CSV:", error);
    }
  };

  const [selectedFilter, setSelectedFilters] = useState("");
  const filtersList = [
    { name: "All", code: "all" },
    { name: "Upcoming Appointment", code: "upcoming" },
    { name: "Insurance Expiry Due", code: "expiry" },
    { name: "Healthy Patients", code: "healthy" },
    // { name: "International", code: "international" },
  ];

  const handlePriorityChange = (selectedFilter) => {
    setSelectedFilters(selectedFilter ? selectedFilter.code : null);

    // Update selectedTab based on selectedPriority
    // if (selectedPriority) {
    //   const tabForPriority = mapPriorityToTab(selectedPriority.code);
    //   setSelectedTab(tabForPriority);
    // }
  };

  const customItemTemplate = (option) => {
    return (
      <div className="optionDIvPrio">
        <div
          className="optionBgDiv"
          style={{ backgroundColor: `${option.color}` }}
        ></div>
        {option.name}
      </div>
    );
  };

  // Error Message
  function showErrorMsg() {
    toast.error("Error in updating", {
      position: "top-right",
      autoClose: 3000, // Duration in milliseconds
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: "colored",
    });
  }

  // Success Message
  function showSuccessMsg() {
    toast.success("Updated Succesfully!", {
      position: "top-right",
      autoClose: 3000, // Duration in milliseconds
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: "colored",
    });
  }

  const closeModal = () => {
    setShowModal(false);
  };
  const openModal = () => {
    setShowModal(true);
  };

  const [isZoomedIn, setIsZoomedIn] = useState(false);

  const toggleZoom = () => {
    setIsZoomedIn(!isZoomedIn);
  };

  const [AmraadminData, setdataFromAmra] = useState([]);

  useEffect(() => {
    // Initialize DataTable
    $("#myTable").DataTable();
    // $("#myTable").DataTable();
    // if (AmraadminData && AmraadminData.length > 0) {
    //   // Destroy the DataTable if it already exists
    //   if ($.fn.DataTable.isDataTable("#myTable")) {
    //     $("#myTable").DataTable().destroy();
    //   }

    // }
  }, []);

  return (
    <div className="dashboard debtorprofileheader">
      <Helmet>
        <title>Farida Dashboard</title>
        <meta
          name="description"
          content="AI and Global Reach: The Key to Scalable Solutions in Customer
          Experience"
        />
      </Helmet>
      <div className="dashboard_container">
        <div className={isLoading ? "loader-overlay show" : "loader-overlay"}>
          <div className="loader23"></div>
        </div>
        <div className="dashboard_wrapper">
          <SideBar />
          <div id="content">
            <CenterNavbar />
            <div className="AmraConversations">
              <div className="AmraConversations_container">
                <div className="AmraConversations_container_wrapper">
                  <div className="overviewContaINER">
                    <div className="overviewHead overviewHeaddp ">
                      <h4>Patient Profile</h4>
                      <div className="overviewHeadLeft">
                        {/* <div className="overviewHeadCntnt">
                          <p>Upload Debtor Data </p>
                          <label
                            htmlFor="uploadCSV"
                            className="uploadCSVBtn"
                            onClick={openModal}
                          >
                            Upload CSV
                          </label>
                          <Link to="AddDebtorForm">
                            <button className="addDebtorBtn">Add Debtor</button>
                          </Link>
                        </div> */}
                      </div>
                    </div>
                    <div className="debtorProfile">
                      <div className="debtorProfileContainer">
                        <div className="debtordetailCards">
                          <div className="row m-0">
                            <div
                              className="col-md-9"
                              style={{ paddingLeft: "0" }}
                            >
                              <div className="debtordetailCardsContainer">
                                <div className="row m-0">
                                  <div
                                    className="col-md-4 "
                                    style={{ paddingLeft: "0" }}
                                  >
                                    <div className="debtordetailCard">
                                      <div className="debtordetailCardHead">
                                        <h6>Total Patients</h6>
                                        {/* <img src={RightArrow}  alt="Prop"  /> */}
                                      </div>
                                      <div className="debtordetailCardBody">
                                        <img src={OrngImg} alt="icon" />
                                        <h4>
                                          {faridaPatientAggregate.patient_count}
                                          {faridaPatientAggregate.patient_count ===
                                          1 ? (
                                            <span style={{ fontSize: "12px" }}>
                                              {" "}
                                              patient
                                            </span>
                                          ) : (
                                            <span style={{ fontSize: "12px" }}>
                                              {" "}
                                              patients
                                            </span>
                                          )}
                                        </h4>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="debtordetailCard">
                                      <div className="debtordetailCardHead">
                                        <h6>New Patients</h6>
                                        {/* <Link to="Bucket30days">
                                          <img src={RightArrow} alt="icon" />
                                        </Link> */}
                                      </div>
                                      <div className="debtordetailCardBody">
                                        <img src={OrngImg} alt="icon" />
                                        <h4>
                                          {faridaPatientAggregate.new_count}
                                          {faridaPatientAggregate.new_count ===
                                          1 ? (
                                            <span style={{ fontSize: "12px" }}>
                                              {" "}
                                              patient
                                            </span>
                                          ) : (
                                            <span style={{ fontSize: "12px" }}>
                                              {" "}
                                              patients
                                            </span>
                                          )}
                                        </h4>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="debtordetailCard">
                                      <div className="debtordetailCardHead">
                                        <h6>--</h6>
                                        {/* <Link to="Bucket60days">
                                          <img src={RightArrow} alt="Prop" />
                                        </Link> */}
                                      </div>
                                      <div className="debtordetailCardBody">
                                        <img src={OrngImg} alt="icon" />
                                        <h4>
                                          {
                                            debtorDataForDateRange?.days_60_bucket_user_count
                                          }
                                          {debtorDataForDateRange?.days_60_bucket_user_count ===
                                          1 ? (
                                            <span style={{ fontSize: "12px" }}>
                                              {" "}
                                              patient
                                            </span>
                                          ) : (
                                            <span style={{ fontSize: "12px" }}>
                                              {" "}
                                              patients
                                            </span>
                                          )}
                                        </h4>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className="col-md-4"
                                    style={{ paddingLeft: "0" }}
                                  >
                                    <div className="debtordetailCard">
                                      <div className="debtordetailCardHead">
                                        <h6>--</h6>
                                        {/* <Link to="Bucket90days">
                                          <img src={RightArrow} alt="Prop" />
                                        </Link> */}
                                      </div>
                                      <div className="debtordetailCardBody">
                                        <img src={OrngImg} alt="icon" />
                                        <h4>
                                          {
                                            debtorDataForDateRange?.days_90_bucket_user_count
                                          }
                                          {debtorDataForDateRange?.days_90_bucket_user_count ===
                                          1 ? (
                                            <span style={{ fontSize: "12px" }}>
                                              {" "}
                                              patient
                                            </span>
                                          ) : (
                                            <span style={{ fontSize: "12px" }}>
                                              {" "}
                                              patients
                                            </span>
                                          )}
                                        </h4>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="debtordetailCard">
                                      <div className="debtordetailCardHead">
                                        <h6>Insurance Expiry Due</h6>
                                        {/* <img src={RightArrow} alt="icon" /> */}
                                      </div>
                                      <div className="debtordetailCardBody">
                                        <img src={OrngImg} alt="icon" />
                                        <h4>
                                          {
                                            faridaPatientAggregate.expiry_due_count
                                          }
                                          {faridaPatientAggregate.expiry_due_count ===
                                          1 ? (
                                            <span style={{ fontSize: "12px" }}>
                                              {" "}
                                              patient
                                            </span>
                                          ) : (
                                            <span style={{ fontSize: "12px" }}>
                                              {" "}
                                              patients
                                            </span>
                                          )}
                                        </h4>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="debtordetailCard">
                                      <div className="debtordetailCardHead">
                                        <h6>Insurance Expired</h6>
                                        {/* <img src={RightArrow} /> */}
                                      </div>
                                      <div className="debtordetailCardBody">
                                        <img src={OrngImg} alt="icon" />
                                        <h4>
                                          {faridaPatientAggregate.expired_count}
                                          {faridaPatientAggregate.expired_count ===
                                          1 ? (
                                            <span style={{ fontSize: "12px" }}>
                                              {" "}
                                              patient
                                            </span>
                                          ) : (
                                            <span style={{ fontSize: "12px" }}>
                                              {" "}
                                              patients
                                            </span>
                                          )}
                                        </h4>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-3 debtorSearch">
                              <div className="debtorFilter">
                                <div className="debtorFilterContainer">
                                  <h5>Search Patient </h5>
                                  <input
                                    className="inp"
                                    type="mobile no"
                                    placeholder="Emirates Id"
                                    value={loanID}
                                    onChange={(e) => {
                                      setLoanID(e.target.value);
                                      setuserNumber("");
                                    }}
                                    onInput={(e) => {
                                      e.target.value = e.target.value.replace(
                                        /[^0-9]/g,
                                        ""
                                      );
                                    }}
                                  />
                                  <h5>or</h5>
                                  <input
                                    className="inp"
                                    type="mobile no"
                                    placeholder="Mobile No."
                                    value={userNumber}
                                    onChange={(e) => {
                                      setLoanID("");
                                      setuserNumber(e.target.value);
                                    }}
                                    maxLength="10"
                                    onInput={(e) => {
                                      e.target.value = e.target.value.replace(
                                        /[^0-9]/g,
                                        ""
                                      );
                                    }}
                                  />
                                  <button
                                    onClick={() => {
                                      //   setShowModalProfile(true);
                                      // searchDebtorProfile("no");
                                    }}
                                    className="Search_btn  d-flex"
                                  >
                                    <img src={SearchIcon} alt="icon" />
                                    <p>Search </p>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className={`col-12 ${isZoomedIn ? "zoomedIn" : ""}`}
                          style={{ padding: "0 10px" }}
                        >
                          {/* <div
                            className={`usersContainer mb-2 ${
                              isZoomedIn ? "zoomedIn" : ""
                            }`}
                          >
                            <div className="revenueCallHead revenueCallHead2 ">
                              <div className="revenueDIv noneBorderDiv">
                                <img
                                  src={Expand1}
                                  alt="icon"
                                  onClick={toggleZoom}
                                />
                                <div className="revenueText">
                                  <p>Call (Audience Map Location)</p>
                                  <p className="rtext2">Report Center</p>
                                </div>
                              </div>
                              <div
                                className="moreOption"
                                style={{ paddingRight: "1rem" }}
                              >
                                <img src={MoreIcon} alt="icon" />
                              </div>
                            </div>
                            <div className="mapLocation">
                              <div className="mapLocation_contaINER">
                                <div
                                  className="row"
                                  style={{ display: "flex" }}
                                >
                                  <div className="col-md-5">
                                    <div className="countryRecovry">
                                      <ul>
                                        {debtorDataCityWise?.map(
                                          (cityData, index) => (
                                            <li key={index}>
                                              <div className="countryRecovryItem">
                                                <div className="cntryRcvryItemLeft">
                                                  <div className="cntryRcvryItemLeftHead">
                                                    <h6>{cityData?.name}</h6>
                                                    <p>
                                                      {cityData?.total_debtors}{" "}
                                                      Users
                                                    </p>
                                                  </div>
                                                  <div className="cntryRcvryProBar">
                                                    <ProgressBar
                                                      done={
                                                        cityData?.average_recovery_rate
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                                <div className="cntryRcvryItemRight">
                                                  <div className="cntryRcvryItemRigthHead">
                                                    <p>Recovery Rate</p>
                                                    <p className="rcvrypercnt">
                                                      {
                                                        cityData?.average_recovery_rate
                                                      }
                                                      %
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                            </li>
                                          )
                                        )}
                                      </ul>
                                    </div>
                                  </div>
                                  <div className="col-md-7">
                                    <MyChart calldata={timeList} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div> */}
                          <div className="datatableCOntainerDiv mx-0">
                            <div className="datatableCOntainerHead">
                              <h4>Patient Details</h4>
                              {/* <div className="filterBtnDivs">
                                <Dropdown
                                  value={filtersList.find(
                                    (p) => p.code === selectedFilter
                                  )}
                                  onChange={(e) =>
                                    handlePriorityChange(e.value)
                                  }
                                  options={filtersList}
                                  optionLabel="name"
                                  placeholder="Filter Patients"
                                  className="priorityBtn"
                                  itemTemplate={customItemTemplate}
                                />
                              </div> */}
                            </div>
                            <div className="amradataTable">
                              <div className="amradataTable_container ">
                                <table id="myTable" className="display">
                                  <thead>
                                    <tr>
                                      <th className="text-center">#</th>
                                      <th className="text-center">
                                        Emirates Id
                                      </th>
                                      <th className="text-center">Name</th>
                                      <th className="text-center">Mobile</th>
                                      <th className="text-center">
                                        Last Appointment
                                      </th>
                                      <th className="text-center">
                                        Insurance Expiry
                                      </th>
                                    </tr>
                                  </thead>

                                  <tbody>
                                    {faridaPatientAggregate.patient_list.map(
                                      (item, index) => (
                                        <tr key={index}>
                                          <td>
                                            <div className="tableData1 tableData12">
                                              <p>{index + 1}</p>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="tableData1 tableData12">
                                              <p>{item.emirates_id}</p>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="tableData1 tableData12">
                                              <p style={{ color: "#000" }}>
                                                {item.user_name}
                                              </p>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="tableData1 tableData12">
                                              <p>{item.mobile_no}</p>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="tableData1 tableData12">
                                              <p style={{ color: "#000" }}>
                                                {item.user_name}
                                              </p>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="tableData1 tableData12">
                                              <p>
                                                {item.insurance_expiry_date}
                                              </p>
                                            </div>
                                          </td>
                                        </tr>
                                      )
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="brandingContainer">
                <button
                  id="modelclick"
                  className="hidden"
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop1"
                >
                  <img src={SearchIcon} alt="icon" />
                </button>
                <div>
                  <h5>Powered by</h5>
                  <img src={Technotask} alt="Logo" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <PatientProfileModal debtor={debtorProfileData} />
      </div>
      <ToastContainer />
      <Modal
        id="myModal"
        show={showModal}
        onHide={closeModal}
        centered
        backdrop="static"
        size="lg"
      >
        <form enctype="multipart/form-data" id="save_form">
          <Modal.Header className="modalHeader23">
            <Modal.Title>
              <h5 style={{ color: "#8639CE" }}>Upload Debtor Data</h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="modal_body modal_body12">
            <p>
              Select a CSV file and city to uplaod debtor's data.If you want to
              see a sample file, you can download it by clicking the "Sample"
              button.
            </p>
            <div className="uploadButtonCity">
              <div>
                <input
                  type="file"
                  id="file-input"
                  onChange={handleFileChange}
                  hidden
                />
                <label htmlFor="file-input" className="customfileCsvinput">
                  Select File
                </label>
                <span id="file-namelabel">
                  {selectedFile ? selectedFile.name : "No file selected"}
                </span>
              </div>
              <div>
                <Dropdown
                  value={selectedCityId}
                  onChange={(e) => {
                    console.log(e);
                    setSelectedCityId(e.value);
                  }}
                  options={timeList}
                  optionLabel="name"
                  placeholder="Select City"
                  className="priorityBtn"
                />
              </div>
              <div
                className="customfileCsvinput smpleCsvinput"
                onClick={handleDownload}
              >
                <div>Sample</div>
                <img
                  style={{ width: "1.4rem", height: "1.5rem" }}
                  src={DownloadBtn1}
                  alt="icon"
                />
              </div>
            </div>
            <div className="csvSubmitbtn">
              <button onClick={validateForm} type="button">
                Upload
              </button>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div onClick={closeModal} className="btn go_to_login">
              Cancel
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
}

export default DebtorProfile;
