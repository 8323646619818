import React, { useEffect } from "react";
import Logout from "../../assets/images/giflogout.gif";
import SideBarControl from "../../assets/images/rightLeftSIdebar.png";
import adm_img from "../../assets/images/adm_img.png";
import gear1 from "../../assets/images/Gear1.png";
import Question from "../../assets/images/Question.png";
import Bell from "../../assets/images/Bell.png";
import $ from "jquery";
import { useLocation } from "react-router-dom";

function CenterNavAmraAdmin() {
  const location = useLocation();
  const admin_name = localStorage.getItem("amra_admin_name");
  const admin_email = localStorage.getItem("amra_admin_email");
  useEffect(() => {
    // Define the function to handle sidebar collapse
    const handleSidebarCollapse = () => {
      $("#sidebar").toggleClass("active");
      $(".btn_toggle1").toggleClass("btn_toggle1ani");
    };

    // Attach the event listener to your elements
    $("#sidebarCollapse").on("click", handleSidebarCollapse);
    $("#sidebarCollapse1").on("click", handleSidebarCollapse);

    // Cleanup logic
    return () => {
      // Remove the event listeners when the component is unmounted
      $("#sidebarCollapse").off("click", handleSidebarCollapse);
      $("#sidebarCollapse1").off("click", handleSidebarCollapse);
    };
  }, []);

  return (
    <nav className="navbar centerNavBAr3 navbar-expand-lg p-0">
      <div className="navbar_left">
        <div
          type="button"
          id="sidebarCollapse1"
          className="btn_toggle1 btn_toggle1ani"
        >
          <img src={SideBarControl} alt="img" />
        </div>
        {location.pathname === "/burjeel_admin" && (
          <h4 style={{ margin: "0" }}>Overview</h4>
        )}
        {location.pathname === "/AdminUserControl" && (
          <h4 style={{ margin: "0" }}>User Management</h4>
        )}
        {location.pathname === "/Amra_Access_Control" && (
          <h4 style={{ margin: "0" }}>Access Control </h4>
        )}
        {location.pathname === "/burjeel_hospitals" && (
          <h4 style={{ margin: "0" }}>Hospitals</h4>
        )}
        {location.pathname === "/burjeel_tickets" && (
          <h4 style={{ margin: "0" }}>Help & Support</h4>
        )}
        {location.pathname === "/Amra_Analytics_Reporting" && (
          <h4 style={{ margin: "0" }}>Analytics and Reporting</h4>
        )}
        {location.pathname === "/Amra_Admin_Settings" && (
          <h4 style={{ margin: "0" }}>Settings</h4>
        )}
        {location.pathname === "/Amra_Admin_Settings/Amra_Admin_Invoice" && (
          <h4 style={{ margin: "0" }}>Invoice</h4>
        )}
        {location.pathname ===
          "/Amra_Admin_Settings/Amra_Admin_UserManagement" && (
          <h4 style={{ margin: "0" }}>User Settings</h4>
        )}
      </div>

      <div className="navbar_right">
        <div className="navbarRightNoti">
          {/* <img src={Question} alt="icon" />
          <img src={gear1} alt="icon" />
          <img src={Bell} alt="icon" /> */}
        </div>
        <div className="navbarRightAcc">
          <img src={adm_img} alt="icon" />
          <div className="nav-item dropdown">
            <div
              className="nav-link dropdown-toggle d-flex align-items-center"
              style={{ gap: "0.5rem" }}
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <p className="m-0">
                {admin_name}
                <p
                  style={{
                    fontSize: "10px",
                    margin: "0",
                    color: "#6E6E91",
                  }}
                >
                  {admin_email}
                </p>
              </p>
            </div>

            <div
              className="dropdown-menu prdctList LEFTalINGdROPDOWN sadfdgfdf"
              aria-labelledby="navbarDropdown"
            >
              {/* <a className="dropdown-item" href="/">
                        Account
                      </a> */}
              <a className="dropdown-item" href="/AmraAdminLogin">
                <div className="logoutIconandDiv">
                  <p>Logout</p>
                  <img src={Logout} alt="icon" />
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default CenterNavAmraAdmin;
