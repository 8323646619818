import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import CenterNavAmraAdmin from "./CenterNavAmraAdmin";
import NAfiaAdminSideBar from "./AmraAdminSideBar";
import $ from "jquery";
import { Modal } from "react-bootstrap";
import {
  server_post_data_shubham,
  get_all_amra_admin,
  save_update_amra_admin,
  delete_update_data,
} from "../../KeyConnection/KeysService.js";
import {
  check_vaild_save,
  combiled_form_data,
  empty_form,
  handleAphabetsChange,
  handleEmailChange,
  handleIaphabetnumberChange,
  handleNumbersChange,
  handleConfimDeleteClick,
} from "../../CommonJquery/CommonJquery.js";
function AdminUserControl() {
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [selectedUserData, setSelectedUserData] = useState([]);
  const [AmraadminData, setdataFromAmra] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const company_id = localStorage.getItem("company_id");
  const controlOptions = [
    { name: "Admin", code: "Admin" },
    { name: "Super Admin", code: "Super Admin" },
    { name: "Sub Admin", code: "Sub Admin" },
    { name: "Manager", code: "Manager" },
  ];

  useEffect(() => {
    const start_date = "";
    const end_date = "";
    const flag = "1";
    let call_id = "0";
    master_data_get(start_date, end_date, flag, call_id);
  }, [selectedUserData]);

  useEffect(() => {
    if (AmraadminData && AmraadminData.length > 0) {
      // Destroy the DataTable if it already exists
      if ($.fn.DataTable.isDataTable("#myTable")) {
        $("#myTable").DataTable().destroy();
      }
      // Initialize DataTable
      $("#myTable").DataTable();
    }
    console.log(AmraadminData);
  }, [AmraadminData]);

  const master_data_get = async (start_date, end_date, flag, call_id) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("admin_id", company_id);
    fd.append("start_date", start_date);
    fd.append("end_date", end_date);
    fd.append("flag", flag);
    fd.append("call_id", call_id);
    await server_post_data_shubham(get_all_amra_admin, fd)
      .then((Response) => {
        if (Response.data.error) {
          alert(Response.data.message);
        } else {
          setdataFromAmra(Response.data.message.data_slider);
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };

  const handleSaveChangesdynamic = async (form_data, url_for_save) => {
    let vaild_data = check_vaild_save(form_data);
    if (vaild_data) {
      setshowLoaderAdmin(true);
      let fd_from = combiled_form_data(form_data, null);
      fd_from.append("admin_id", company_id);
      await server_post_data_shubham(url_for_save, fd_from)
        .then((Response) => {
          console.log(Response.data.message);
          setshowLoaderAdmin(false);
          if (Response.data.error) {
            alert(Response.data.message);
          } else {
            alert(Response.data.message);
            empty_form(form_data);
            handleCloseModal();
            master_data_get("", "", "1", "0");
          }
        })
        .catch((error) => {
          setshowLoaderAdmin(false);
        });
    }
  };

  const handleShowModal = (call_type) => {
    console.log(call_type);
    if (call_type === 0) {
      setSelectedUserData(null);
    } else {
      setSelectedUserData(AmraadminData[call_type - 1]);
    }
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const master_data_action = async (call_id) => {
    if (handleConfimDeleteClick()) {
      setshowLoaderAdmin(true);
      const fd = new FormData();
      fd.append("id_for_delete", call_id);
      fd.append("flag_for", "1");
      fd.append("for_status_final", "d");
      console.log(call_id);
      await server_post_data_shubham(delete_update_data, fd)
        .then((Response) => {
          setshowLoaderAdmin(false);
          if (Response.data.error) {
            alert(Response.data.message);
          } else {
            master_data_get("", "", "1", "0");
          }
        })
        .catch((error) => {
          setshowLoaderAdmin(false);
        });
    }
  };

  return (
    <div className="dashboard">
      <div
        className={showLoaderAdmin ? "loader-overlay show" : "loader-overlay"}
      >
        <div className="loader23"></div>
      </div>
      <Helmet>
        <title>Amra Dashboard</title>
        <meta
          name="description"
          content="AI and Global Reach: The Key to Scalable Solutions in Customer
          Experience"
        />
      </Helmet>
      <div className="dashboard_container">
        <div className="dashboard_wrapper">
          <NAfiaAdminSideBar />
          <div id="content">
            <CenterNavAmraAdmin />

            <div className="Dashpages_contaioner">
              <div className="overview">
                <div className="overviewContaINER">
                  <div className="amradataTable">
                    <div className="amradataTable_container">
                      <table id="myTable" className="display">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Name</th>
                            <th>User Name</th>
                            <th>Registered Mob. No.</th>
                            <th>Control</th>
                            <th>
                              History Logs <br />
                              <span className="postMvp">Post MVP</span>
                            </th>
                            <th>
                              Logged in Device <br />
                              <span className="postMvp">Post MVP</span>
                            </th>
                            <th>Log out</th>
                          </tr>
                        </thead>
                        <tbody>
                          {AmraadminData.map((item, index) => (
                            <tr key={index}>
                              <td>
                                <div className="tableData1">
                                  <p
                                    style={{ color: "#000", fontWeight: "500" }}
                                  >
                                    #{index + 1}
                                  </p>
                                </div>
                              </td>
                              <td>
                                <div className="tableData1">
                                  <p
                                    style={{ color: "#000", fontWeight: "500" }}
                                  >
                                    {item.name}
                                  </p>
                                </div>
                              </td>
                              <td>
                                <div className="tableData1">
                                  <p
                                    style={{ color: "#000", fontWeight: "500" }}
                                  >
                                    {item.email}
                                  </p>
                                </div>
                              </td>
                              <td>
                                <div className="tableData1">
                                  <p
                                    style={{ color: "#000", fontWeight: "500" }}
                                  >
                                    {item.mobile_no}
                                  </p>
                                </div>
                              </td>
                              <td>
                                <div className="tableData1">
                                  <p
                                    style={{ color: "#000", fontWeight: "500" }}
                                  >
                                    {item.control_type}
                                  </p>
                                </div>
                              </td>
                              <td>
                                <div className="tableData1">
                                  <td
                                    style={{
                                      color: "#207DFF",
                                      fontWeight: "500",
                                      cursor: "pointer",
                                    }}
                                  >
                                    See Now
                                  </td>
                                </div>
                              </td>
                              <td>
                                <div className="tableData1 loggedIngdevicesBtn">
                                  <p style={{ fontWeight: "500" }}>
                                    {item.login_device_count} Devices
                                  </p>
                                </div>
                              </td>
                              <td>
                                <div className="tableData1">
                                  <button
                                    className="editUsrContrlBtn"
                                    onClick={() => handleShowModal(index + 1)}
                                  >
                                    Edit
                                  </button>
                                  <button
                                    className="remvUsrContrlBtn"
                                    onClick={() => master_data_action(item.id)}
                                  >
                                    Remove
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div className="AddAmraAdminuser">
                    <button onClick={() => handleShowModal(0)}>Add User</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={showModal}
        onHide={handleCloseModal}
        dialogClassName="custom-modal3"
        centered
        size="xl"
      >
        <Modal.Header>
          <h5 className="mt-3"> {selectedUserData ? "Update" : "Add"} User</h5>
        </Modal.Header>
        <Modal.Body>
          <div className="mngUserContainer px-5">
            <form className="needs-validation" id="CarrerformData">
              <div className="row m-0">
                <div className="col-md-6 hidden">
                  <div className="mngUserInput">
                    <label>Admin Id</label>
                    <br />
                    <input
                      type="text"
                      className="form-control  trio_mendate"
                      name="main_id"
                      id="main_id"
                      maxLength={150}
                      placeholder="Enter Admin Id"
                      defaultValue={
                        selectedUserData ? selectedUserData.id : "0"
                      }
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mngUserInput">
                    <label>Full Name</label>
                    <br />
                    <input
                      type="text"
                      className="form-control  trio_mendate"
                      name="full_name"
                      id="full_name"
                      maxLength={150}
                      onInput={handleAphabetsChange}
                      placeholder="Enter Full Name"
                      defaultValue={
                        selectedUserData ? selectedUserData.name : ""
                      }
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mngUserInput">
                    <label>User Name</label>
                    <br />
                    <input
                      type="text"
                      className="form-control  trio_mendate trio_email"
                      name="user_name"
                      id="user_name"
                      maxLength={150}
                      onInput={handleEmailChange}
                      placeholder="Enter User Name"
                      defaultValue={
                        selectedUserData ? selectedUserData.email : ""
                      }
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mngUserInput">
                    <label>Mobile No.</label>
                    <br />
                    <input
                      type="text"
                      className="form-control  trio_mendate trio_no"
                      name="user_mobile_no"
                      id="user_mobile_no"
                      maxLength={12}
                      onInput={handleNumbersChange}
                      placeholder="Enter Mobile No"
                      defaultValue={
                        selectedUserData ? selectedUserData.mobile_no : ""
                      }
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mngUserInput">
                    <label>Password</label>
                    <br />
                    <input
                      type="password"
                      className="form-control  trio_mendate trio_password"
                      name="user_password"
                      id="user_password"
                      onInput={handleIaphabetnumberChange}
                      maxLength={15}
                      defaultValue={
                        selectedUserData ? selectedUserData.password : ""
                      }
                      placeholder="Enter Password"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mngUserInput">
                    <label>Confirm Password</label>
                    <br />
                    <input
                      type="password"
                      className="form-control  trio_mendate trio_password"
                      name="confirm_password"
                      id="confirm_password"
                      onInput={handleIaphabetnumberChange}
                      maxLength={15}
                      defaultValue={
                        selectedUserData ? selectedUserData.password : ""
                      }
                      placeholder="Enter Confirm Password"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mngUserInput">
                    <label>Control</label>
                    <br />

                    <select
                      className="form-control trio_mendate controlSlct "
                      name="control_access"
                      id="control_access"
                    >
                      {controlOptions.map((blogdddddd, index) => (
                        <option
                          key={index}
                          value={blogdddddd.code}
                          selected={
                            selectedUserData
                              ? selectedUserData.control_type ===
                                blogdddddd.code
                              : false
                          }
                        >
                          {blogdddddd.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="updateCnclebtnDiv">
            <button className="updateCnclebtnCan" onClick={handleCloseModal}>
              Cancel
            </button>
            <button
              className="updateCnclebtnUp"
              type="button"
              onClick={() =>
                handleSaveChangesdynamic(
                  "CarrerformData",
                  save_update_amra_admin
                )
              }
            >
              {selectedUserData ? "Update" : "Add"}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default AdminUserControl;
