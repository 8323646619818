import React, { useState } from "react";
import "../../Dasboard/Css/SideBar.css";
import UserLogo from "../../assets/images/UserLogo.png";
import Dashboardwhite from "../../assets/images/dashboard.svg";
import DashboardBlack from "../../assets/images/dashboard12.svg";
import DebtorBlack from "../../assets/images/debtorBlack.svg";
import debtorWhite from "../../assets/images/debtorWhite.svg";
import PaymentBlack from "../../assets/images/payment.svg";
import PaymentWhite from "../../assets/images/credit_card.svg";
import CallBlack from "../../assets/images/eye.svg";
import CallWhite from "../../assets/images/eyeWhite.svg";
import ReportBlack from "../../assets/images/report.svg";
import ReportWhite from "../../assets/images/reportwhite.svg";
import SettingBlack from "../../assets/images/settings.svg";
import SettingWhite from "../../assets/images/settingswhite.svg";
import HelpImg from "../../assets/images/Vector.svg";
import HelpImgWhite from "../../assets/images/helpWhite.svg";
import Know from "../../assets/images/know.png";
import CLose from "../../assets/images/close_big.svg";

import { Link, useLocation } from "react-router-dom";
import { color } from "highcharts";

function SideBar({
  overviewShow,
  setOverviewShow,
  debtorProfile,
  setdebtorProfile,
}) {
  const handleItemClick = (item) => {
    setOverviewShow(item === "overview");
    setdebtorProfile(item === "debtorProfile");
  };

  const location = useLocation();

  // const [selectedHeading, setSelectedHeading] = useState(null);
  // const handleHeadingClick = (heading) => {
  //   // Revert background color and invert text color of the previously clicked heading
  //   if (selectedHeading) {
  //     selectedHeading.style.backgroundColor = "transparent";
  //     selectedHeading.style.color = "black";
  //   }

  //   // Change background color to linear gradient and invert text color of the clicked heading
  //   heading.style.background = "linear-gradient(to right, #7E32CB, #9532A5)";
  //   heading.style.color = "white";

  //   // Set the clicked heading as the currently selected heading
  //   setSelectedHeading(heading);
  // };

  const [isUpgradePlanVisible, setIsUpgradePlanVisible] = useState(true);
  const image_link_src = localStorage.getItem("company_image");
  const handleCloseUpgradePlan = () => {
    setIsUpgradePlanVisible(false);
  };
  return (
    <div className="sidebar">
      <div className="sidebar_container">
        <nav id="sidebar">
          <div className="sidebar-header">
            <div className="new_chat_btn">
              <img src={image_link_src} alt="Logo" />
            </div>
          </div>
          <div className="sidebar-body">
            <div className="sidebar-body-container">
              <div className="mainMenu">
                <h6>MAIN MENU</h6>
                <div className="mainMenuContainer">
                  <ul>
                    <li>
                      <Link to="/HomePageNafia">
                        <div
                          className={`mainMenuItem ${
                            location.pathname === "/HomePageNafia"
                              ? "activemainMenuItem"
                              : ""
                          }`}
                        >
                          <img
                            src={
                              location.pathname === "/HomePageNafia"
                                ? Dashboardwhite
                                : DashboardBlack
                            }
                            alt="icon"
                          />

                          <p>Overview</p>
                        </div>
                      </Link>
                    </li>
                    {/* <li>
                      <Link to="">
                        <div
                          className={`mainMenuItem ${
                            location.pathname === "/InteractionSummary"
                              ? "activemainMenuItem"
                              : ""
                          }`}
                        >
                          <img
                            src={
                              location.pathname === "/InteractionSummary"
                                ? debtorWhite
                                : DebtorBlack
                            }
                            alt="icon"
                          />
                          <p>Interaction Summary</p>
                        </div>
                      </Link>
                    </li> */}
                    <li>
                      <Link to="/Knowledge">
                        <div
                          className={`mainMenuItem ${
                            location.pathname === "/Knowledge" ||
                            location.pathname === "/KnowledgeBase" ||
                            location.pathname === "/KBhealth"
                              ? "activemainMenuItem"
                              : ""
                          }`}
                        >
                          <img
                            src={
                              location.pathname === "/Knowledge" ? Know : Know
                            }
                          />

                          <p>Knowledge Base Update</p>
                        </div>
                      </Link>
                    </li>
                    {/* <li>
                      <Link to="">
                        <div
                          className={`mainMenuItem ${
                            location.pathname === "/callDetailView"
                              ? "activemainMenuItem"
                              : ""
                          }`}
                        >
                          <img
                            src={
                              location.pathname === "/paymentHistory"
                                ? PaymentWhite
                                : PaymentBlack
                            }
                            alt="icon"
                          />

                          <p>Data & Setting</p>
                        </div>
                      </Link>
                    </li> */}
                    <li>
                      <Link to="/NafiaGeneratingReport">
                        <div
                          className={`mainMenuItem ${
                            location.pathname === "/NafiaGeneratingReport"
                              ? "activemainMenuItem"
                              : ""
                          }`}
                        >
                          <img
                            src={
                              location.pathname === "/NafiaGeneratingReport"
                                ? ReportWhite
                                : ReportBlack
                            }
                            alt="icon"
                          />

                          <p>Generating reports</p>
                        </div>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="mainMenu">
                <h6>PREFERENCES</h6>
                <div className="mainMenuContainer">
                  <ul>
                    <li>
                      <Link to="/NafiaSettingPage01">
                        <div
                          className={`mainMenuItem ${
                            location.pathname === "/NafiaSettingPage01" ||
                            location.pathname ===
                              "/NafiaSettingPage01/NafiaUserManagement" ||
                            location.pathname ===
                              "/NafiaSettingPage01/NafiaInvoice"
                              ? "activemainMenuItem"
                              : ""
                          }`}
                        >
                          <img
                            src={
                              location.pathname === "/NafiaSettingPage01" ||
                              location.pathname ===
                                "/NafiaSettingPage01/NafiaUserManagement" ||
                              location.pathname ===
                                "/NafiaSettingPage01/NafiaInvoice"
                                ? SettingWhite
                                : SettingBlack
                            }
                            alt="icon"
                          />
                          <p>Settings</p>
                          <span
                            className={`postMvp ${
                              location.pathname === "/NafiaSettingPage01" ||
                              location.pathname ===
                                "/NafiaSettingPage01/NafiaUserManagement" ||
                              location.pathname ===
                                "/NafiaSettingPage01/NafiaInvoice"
                                ? "postMvpWhite"
                                : " "
                            }`}
                          >
                            Post MVP
                          </span>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to="/NafiaHelpSupport1">
                        <div
                          className={`mainMenuItem ${
                            location.pathname === "/NafiaHelpSupport1" ||
                            location.pathname ===
                              "/NafiaHelpSupport1/NafiaReply_to_ticket" ||
                            location.pathname ===
                              "/NafiaHelpSupport1/NafiaCreate_new_ticket"
                              ? "activemainMenuItem"
                              : ""
                          }`}
                        >
                          <img
                            src={
                              location.pathname === "/NafiaHelpSupport1" ||
                              location.pathname ===
                                "/NafiaHelpSupport1/NafiaReply_to_ticket" ||
                              location.pathname ===
                                "/NafiaHelpSupport1/NafiaCreate_new_ticket"
                                ? HelpImgWhite
                                : HelpImg
                            }
                            alt="icon"
                          />
                          <p>Help & Support</p>
                        </div>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>

              {isUpgradePlanVisible && (
                <div className="upgradePlan hidden">
                  <div className="upgradePlanContianer">
                    <p className="upgradePlanHead">
                      Upgrade to <br />
                      Enterprise Account!
                    </p>
                    <p>
                      Increase your Debt Collection by using special features of
                      Enterprise Membership.
                    </p>
                    <Link to="/BillingForm">
                      <button>Upgrade</button>
                    </Link>
                    <div
                      className="closeImgDiv"
                      onClick={handleCloseUpgradePlan}
                    >
                      <img src={CLose} alt="icon" />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
}

export default SideBar;
