import React, { useState, useEffect } from "react";
import "../Css/AmraDashboard.css";
import moment from "moment";
import axios from "axios";
import {
  URL_GET_NAFIA_CONVR,
  server_get_data_master,
  nafiachatconversationretival,
} from "../../KeyConnection/KeysService"; // Adjust the path as needed
import SideBar2 from "./Sidebar2";
import NotificationsImg from "../../assets/images/notification.svg";
import SideBarControl from "../../assets/images/rightLeftSIdebar.png";
import UserImgPrf from "../../assets/images/userimg45.svg";
import adm_img from "../../assets/images/adm_img.png";
import LoadrerTechnoTask from "../../Components/LoaderAnimation";

function NafiaConversations() {
  const [chats, setchats] = useState([]);
  const [selecteddata, setselecteddataa] = useState([]);
  const [chatconverarray, SetChatConverArray] = useState([]);
  const [showLoaderWebsite, setshowLoaderWebsite] = useState(false);
  const [chatSelected, setChatSelected] = useState(false);
  const [showloaderfull, setshowloaderfull] = useState(false);

  const chatconversation = (chat_session) => {
    const headers = {
      "Content-Type": "application/json", // Change the content type to handle file upload
    };
    // chatbotiddata
    axios
      .get(nafiachatconversationretival(chat_session), headers)
      .then(function (response) {
        const data = response.data;
        console.log(data);
        if (response.data.status !== "success") {
          //$('.server_error').text(response.data.msg);
          console.log("asdsa");
          setChatSelected(true);
          SetChatConverArray(data.data);
        } else {
        }
      })
      .catch(function (error) {
        console.error(error);
        if (error.response.data.message) {
          console.error(error.response.data.message);
        } else {
          console.error("something went wrong");
        }
      });
  };

  console.log("ChatArray :", chatconverarray);

  const getUserData = async (url_for) => {
    setshowloaderfull(true);
    const fd = new FormData();
    await server_get_data_master(url_for, fd)
      .then((response) => response.json())
      .then((data) => {
        setshowloaderfull(false);
        console.log(data);
        if (data.status === "error") {
          alert(data.data);
        } else {
          setchats(data.data);
        }
      })
      .catch((error) => {
        setshowloaderfull(false);
      });
  };

  useEffect(() => {
    getUserData(URL_GET_NAFIA_CONVR);
  }, []);

  const getFormattedTimestamp = (timestamp) => {
    const today = moment().startOf("day");
    const yesterday = moment().subtract(1, "day").startOf("day");
    const messageTimestamp = moment(timestamp);

    if (messageTimestamp.isSame(today, "d")) {
      return messageTimestamp.format("h:mm A"); // Today with time (e.g., 3:30 PM)
    } else if (messageTimestamp.isSame(yesterday, "d")) {
      return "Yesterday " + messageTimestamp.format("h:mm A"); // Yesterday with time (e.g., Yesterday 3:30 PM)
    } else {
      return messageTimestamp.format("DD-MM-YYYY h:mm A"); // Date with time (e.g., 10-07-2023 3:30 PM)
    }
  };
  return (
    <div className="dashboard">
      <div className="dashboard_container">
        <div className="dashboard_wrapper">
          <div className="sidebar_wrapper">
            <SideBar2 />
          </div>
          <div id="content">
            <nav className="navbar centerNav navbar-expand-lg navbar-light bg-light p-0">
              <div className="navbar_left">
                <div
                  type="button"
                  id="sidebarCollapse1"
                  className="btn_toggle1 btn_toggle1ani"
                >
                  <img src={SideBarControl} alt="img" />
                </div>

                <div className="logo_msgCount">
                  <div className="logo_div">
                    <h4 style={{ fontSize: "24px" }}>Dashboard</h4>
                  </div>
                </div>
              </div>

              <div className="navbar_right">
                {/* <div className="navbarRightNoti">
                  <img src={NotificationsImg} alt="icon" />
                </div> */}
                <div className="navbarRightAcc">
                  <img src={adm_img} alt="icon" />
                  <div className="nav-item dropdown">
                    <div
                      className="nav-link dropdown-toggle"
                      id="navbarDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Account
                    </div>
                    <div
                      className="dropdown-menu prdctList LEFTalINGdROPDOWN"
                      aria-labelledby="navbarDropdown"
                    >
                      <a className="dropdown-item" href="/">
                        Settings
                      </a>
                      <a className="dropdown-item" href="/">
                        Logout
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </nav>
            <div className="AmraConversations">
              {showloaderfull ? (
                <div className="loadeTechnoLoaderNew">
                  <LoadrerTechnoTask />
                </div>
              ) : (
                <div className="AmraConversations_container">
                  <div className="AmraConversations_container_wrapper">
                    <div className="overviewContaINER">
                      <div className="overviewHead">
                        <h4>Nafia Conversations</h4>
                        <div className="AmraConversations_data">
                          {chats.length === 0 ? (
                            <div className="noChats_selected">
                              <div className="noChats_selected_container">
                                <div className="noChats_selected_text">
                                  <h5>No conversations yet</h5>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="conversatn">
                              <div className="conversatn_container">
                                <div className="row m-0">
                                  <div className="chatHistry col-5 m-auto">
                                    <div className="chatHistry_container">
                                      <ul>
                                        {chats.map((chatdata) => {
                                          const timestamp =
                                            getFormattedTimestamp(
                                              chatdata.created_at
                                            ); // Get formatted timestamp

                                          return (
                                            <li>
                                              <div
                                                className="chatHistryBox"
                                                key={chatdata.session_id}
                                                onClick={() =>
                                                  chatconversation(
                                                    chatdata.session_id
                                                  )
                                                }
                                              >
                                                <div className="chatHistryBoxText">
                                                  <p className="m-0">
                                                    {chatdata.content_target}
                                                  </p>
                                                  <div className="chatHistryBoxHead">
                                                    <h6>(Unknown)</h6>
                                                    <p>{timestamp}</p>
                                                  </div>
                                                </div>
                                              </div>
                                            </li>
                                          );
                                        })}
                                      </ul>
                                    </div>
                                  </div>
                                  <div className="sleectdChatHistry col-7 m-auto">
                                    <div className="sleectdChatHistryChat">
                                      <div className="sleectdChatHistryChat_container">
                                        {!chatSelected ? (
                                          <div className="nosleectdChatHistryChat">
                                            <h5>No Chat Slected</h5>
                                          </div>
                                        ) : (
                                          <div className="sleectdChatHistryChat_msgs">
                                            {chatconverarray.map((chatdata) => (
                                              <React.Fragment
                                                key={chatdata.index}
                                              >
                                                <div className="amramsg">
                                                  <p>
                                                    {chatdata.response_source}
                                                  </p>
                                                </div>
                                                <div className="usermsg">
                                                  <p>
                                                    {chatdata.content_target}
                                                  </p>
                                                </div>
                                              </React.Fragment>
                                            ))}
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NafiaConversations;
