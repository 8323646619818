import React, { useState, useEffect } from "react";
import "../Css/Dashboard.css";
import "../Css/Bucket30days.css";
import "../Css/SettingPage.css";
import "../Css/UserManagement.css";
import SideBar from "./SideBar";
import CircleCheck from "../../assets/images/circle_check.svg";
import NotificationsImg from "../../assets/images/notification.svg";
import SideBarControl from "../../assets/images/rightLeftSIdebar.png";
import UserImgPrf from "../../assets/images/userimg45.svg";
import adm_img from "../../assets/images/adm_img.png";
import "react-circular-progressbar/dist/styles.css";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import addImg from "../../assets/images/addImg.png";
import userBtn from "../../assets/images/usermngmt.svg";
import userBtnwhite from "../../assets/images/usermngmntblue.svg";
import invBtn from "../../assets/images/invoicee.svg";
import subsButton from "../../assets/images/subscriptions.svg";
import RightaRROW from "../../assets/images/rightmorethan.svg";
import RightaRROWWhite from "../../assets/images/rightmorethanblue.svg";
import { Link, useLocation } from "react-router-dom";
import Logout from "../../assets/images/giflogout.gif";
import Technotask from "../../assets/images/TechnotaskLogo12.png";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CenterNavbar from "./CenterNavbar";
import { Helmet } from "react-helmet";
import UserProfileCompleteSideBar from "./UserProfileCompleteSideBar";
import {
  server_post_data_shubham,
  get_all_amra_user_detail,
  update_name_amara_admin,
} from "../../KeyConnection/KeysService.js";
import {
  check_vaild_save,
  combiled_form_data,
  empty_form,
  handleAphabetsChange,
  handleEmailChange,
  handleIaphabetnumberChange,
  handleNumbersChange,
  handleConfimDeleteClick,
  dateformartmonth,
} from "../../CommonJquery/CommonJquery.js";
function DebtorProfile60() {
  const [overviewShow, setOverviewShow] = useState(true); // Default to 'true'
  const [debtorProfile, setdebtorProfile] = useState(false);
  const location = useLocation();

  const handleSuccess = () => {
    toast.success("Operation successful!", {
      position: "top-right",
      autoClose: 3000, // Duration in milliseconds
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      className: "custom-toast-success",
    });
  };

  const handleError = () => {
    toast.error("An error occurred!", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      className: "custom-toast-error",
    });
  };

  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [dataFromAmra, setdataFromAmra] = useState([]);
  const [dataFromAmraInvoice, setdataFromAmraInvoice] = useState([]);
  const [dataFromAmraId, setdataFromAmraId] = useState(0);
  const [IconMoney, setIconMoney] = useState([]);
  const company_id = localStorage.getItem("company_id");

  useEffect(() => {
    const start_date = "";
    const end_date = "";
    const flag = "3";
    let call_id = "0";
    master_data_get(start_date, end_date, flag, call_id);
  }, [``]);

  const master_data_get = async (start_date, end_date, flag, call_id) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("admin_id", company_id);
    fd.append("start_date", start_date);
    fd.append("end_date", end_date);
    fd.append("flag", flag);
    fd.append("call_id", call_id);
    await server_post_data_shubham(get_all_amra_user_detail, fd)
      .then((Response) => {
        if (Response.data.error) {
          alert(Response.data.message);
        } else {
          console.log(Response.data.message.data_silder_image3);
          setdataFromAmra(Response.data.message.data_silder_image2[0]);
          setdataFromAmraId(Response.data.message.data_silder_image2[0].id);
          setdataFromAmraInvoice(Response.data.message.data_silder_image3);
          setIconMoney(Response.data.message.icon_money);
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };

  const handleSaveChangesdynamic = async (form_data, url_for_save) => {
    let vaild_data = check_vaild_save(form_data);
    if (vaild_data) {
      setshowLoaderAdmin(true);
      let fd_from = combiled_form_data(form_data, null);
      fd_from.append("admin_id", company_id);
      fd_from.append("main_id", dataFromAmraId);
      await server_post_data_shubham(url_for_save, fd_from)
        .then((Response) => {
          console.log(Response.data.message);
          setshowLoaderAdmin(false);
          if (Response.data.error) {
            alert(Response.data.message);
          } else {
            alert(Response.data.message);
            empty_form(form_data);
            master_data_get("", "", "1", "0");
          }
        })
        .catch((error) => {
          setshowLoaderAdmin(false);
        });
    }
  };

  return (
    <div className="dashboard">
      <Helmet>
        <title>Amra User Dashboard</title>
        <meta
          name="description"
          content="AI and Global Reach: The Key to Scalable Solutions in Customer
          Experience"
        />
      </Helmet>
      <div className="dashboard_container">
        <div className="dashboard_wrapper">
          <SideBar
            overviewShow={overviewShow}
            setOverviewShow={setOverviewShow}
            debtorProfile={debtorProfile}
            setdebtorProfile={setdebtorProfile}
          />
          <div id="content">
            <CenterNavbar />

            <div className="AmraConversations">
              <div className="AmraConversations_container">
                <div className="AmraConversations_container_wrapper">
                  <div className="overviewContaINER">
                    <div className="col-md-8 overviewHead overviewHeaddp mb-1">
                      <h4>Settings</h4>
                    </div>
                  </div>
                  <div className="subscript">
                    <div className="row m-0 justify-content-center">
                      <div className="col-lg-4 col-md-5 column1 pt-0">
                        <UserProfileCompleteSideBar />
                      </div>
                      <div className="col-xl-4 col-lg-5 col-md-5 column2">
                        <h5 className="mb-3">
                          <b>User Management</b>
                        </h5>
                        <div className="acordianScroll">
                          <div
                            className="accordion accordion-flush"
                            id="accordionFlushExample"
                          >
                            <div className="accordion-item">
                              <h2
                                className="accordion-header profiledrop"
                                id="flush-headingOne"
                              >
                                <button
                                  className="accordion-button collapsed pb-2"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#flush-collapseOne"
                                  aria-expanded="false"
                                  aria-controls="flush-collapseOne"
                                >
                                  <b>Profile</b>
                                </button>
                              </h2>
                              <p className="subText">
                                First Name, Last Name, Email address
                              </p>
                              <div
                                id="flush-collapseOne"
                                className="accordion-collapse collapse"
                                aria-labelledby="flush-headingOne"
                                data-bs-parent="#accordionFlushExample"
                              >
                                <div className="accordion-body profileInput width_100_per">
                                  <form
                                    className="needs-validation"
                                    id="CarrerformData"
                                  >
                                    <input
                                      type="text"
                                      className="form-control  trio_mendate"
                                      name="full_name"
                                      id="full_name"
                                      maxLength={150}
                                      onInput={handleAphabetsChange}
                                      placeholder="Enter Full Name"
                                      defaultValue={
                                        dataFromAmra ? dataFromAmra.name : "sdfsdf"
                                      }
                                    />
                                    <div className="connCalen">
                                      <button
                                        className="btn btn-primary"
                                        onClick={() =>
                                          handleSaveChangesdynamic(
                                            "CarrerformData",
                                            update_name_amara_admin
                                          )
                                        }
                                      >
                                        Update Data
                                      </button>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                            <div className="accordion-item">
                              <h2
                                className="accordion-header"
                                id="flush-headingTwo"
                              >
                                <button
                                  className="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#flush-collapseTwo"
                                  aria-expanded="false"
                                  aria-controls="flush-collapseTwo"
                                >
                                  <b>Password</b>
                                </button>
                              </h2>
                              <p className="subText">
                                Your email address is xyz@gmail.com
                              </p>
                              <div
                                id="flush-collapseTwo"
                                className="accordion-collapse collapse"
                                aria-labelledby="flush-headingTwo"
                                data-bs-parent="#accordionFlushExample"
                              >
                                <div className="accordion-body profileInput">
                                  <form>
                                    <input
                                      type="text"
                                      placeholder="Current Password"
                                    />
                                    <input
                                      type="text"
                                      placeholder="New Password"
                                    />
                                  </form>
                                  <div className="connCalen">
                                    <button
                                      className="btn btn-primary"
                                      onClick={handleError}
                                    >
                                      Update password
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="accordion-item">
                              <h2
                                className="accordion-header"
                                id="flush-headingThree"
                              >
                                <button
                                  className="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#flush-collapseThree"
                                  aria-expanded="false"
                                  aria-controls="flush-collapseThree"
                                >
                                  <b>Notifications</b>
                                </button>
                              </h2>
                              <p className="subText">
                                We will send you notifications
                              </p>
                              <div
                                id="flush-collapseThree"
                                className="accordion-collapse collapse"
                                aria-labelledby="flush-headingThree"
                                data-bs-parent="#accordionFlushExample"
                              >
                                <div className="accordion-body">
                                  <form>
                                    <div className="custom-checkbox">
                                      <input
                                        type="checkbox"
                                        id="checkbox1"
                                        name="checkbox1"
                                      />
                                      <label
                                        style={{ fontWeight: "500" }}
                                        className="chkbox"
                                        htmlFor="checkbox1"
                                      >
                                        Receive weekly e-mails
                                      </label>
                                    </div>

                                    <div className="custom-checkbox">
                                      <input
                                        type="checkbox"
                                        id="checkbox2"
                                        name="checkbox2"
                                      />
                                      <label
                                        style={{ fontWeight: "500" }}
                                        className="chkbox"
                                        htmlFor="checkbox2"
                                      >
                                        Receive new rating announcements
                                      </label>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                            <div className="accordion-item">
                              <h2
                                className="accordion-header"
                                id="flush-headingFour"
                              >
                                <button
                                  className="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#flush-collapseFour"
                                  aria-expanded="false"
                                  aria-controls="flush-collapseFour"
                                >
                                  <b>Time zone</b>
                                </button>
                              </h2>
                              <p className="subText">
                                Your timezone is currently set to: Pacific Time
                                (US)
                              </p>
                              <div
                                id="flush-collapseFour"
                                className="accordion-collapse collapse"
                                aria-labelledby="flush-headingFour"
                                data-bs-parent="#accordionFlushExample"
                              >
                                <div className="accordion-body profileInput">
                                  <form>
                                    <select id="utcSelector" name="utcSelector">
                                      <option value="-12">
                                        (UTC-12:00) International Date Line West
                                      </option>
                                      <option value="-11">
                                        (UTC-11:00) Midway Island, Samoa
                                      </option>
                                      <option value="-10">
                                        (UTC-10:00) Hawaii
                                      </option>
                                      <option value="-9">
                                        (UTC-09:00) Alaska
                                      </option>
                                      <option value="-8">
                                        (UTC-08:00) Pacific Time (US & Canada)
                                      </option>
                                      <option value="-7">
                                        (UTC-07:00) Mountain Time (US & Canada)
                                      </option>
                                      <option value="-6">
                                        (UTC-06:00) Central Time (US & Canada)
                                      </option>
                                      <option value="-5">
                                        (UTC-05:00) Eastern Time (US & Canada)
                                      </option>
                                      <option value="-4">
                                        (UTC-04:00) Atlantic Time (Canada)
                                      </option>
                                      <option value="-3">
                                        (UTC-03:00) Buenos Aires, Georgetown
                                      </option>
                                      <option value="-2">
                                        (UTC-02:00) Mid-Atlantic
                                      </option>
                                      <option value="-1">
                                        (UTC-01:00) Azores, Cape Verde Islands
                                      </option>
                                      <option value="0">
                                        (UTC) Greenwich Mean Time
                                      </option>
                                      <option value="1">
                                        (UTC+01:00) Amsterdam, Berlin, Paris,
                                        Rome
                                      </option>
                                      <option value="2">
                                        (UTC+02:00) Athens, Istanbul, Jerusalem
                                      </option>
                                      <option value="3">
                                        (UTC+03:00) Moscow, St. Petersburg,
                                        Volgograd
                                      </option>
                                    </select>
                                    <button className="updButton btn btn-primary">
                                      Update
                                    </button>
                                  </form>
                                </div>
                              </div>
                            </div>

                            <div className="accordion-item">
                              <h2
                                className="accordion-header"
                                id="flush-headingFive"
                              >
                                <button
                                  className="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#flush-collapseFive"
                                  aria-expanded="false"
                                  aria-controls="flush-collapseFive"
                                >
                                  <b>Deactivate account</b>
                                </button>
                              </h2>
                              <p className="subText">
                                If you no longer want to receive emails
                              </p>
                              <div
                                id="flush-collapseFive"
                                className="accordion-collapse collapse"
                                aria-labelledby="flush-headingFive"
                                data-bs-parent="#accordionFlushExample"
                              >
                                <div className="accordion-body">
                                  <button className="btn btn-primary">
                                    Deactivate
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="imgAdd d-flex">
                              <button className="addImg">
                                <img src={addImg} alt="icon" />
                              </button>
                              <p>Add account</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="brandingContainer">
              <div>
                <h5>Powered by</h5>
                <img src={Technotask} alt="Logo" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default DebtorProfile60;
