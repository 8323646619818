import React, { useState } from "react";
import "../Css/SideBar.css";
import UserLogo from "../../assets/images/UserLogo.png";
import Dashboardwhite from "../../assets/images/dashboard.svg";
import DashboardBlack from "../../assets/images/dashboard12.svg";
import DebtorBlack from "../../assets/images/debtorBlack.svg";
import debtorWhite from "../../assets/images/debtorWhite.svg";
import PaymentBlack from "../../assets/images/payment.svg";
import PaymentWhite from "../../assets/images/credit_card.svg";
import CallBlack from "../../assets/images/eye.svg";
import CallWhite from "../../assets/images/eyeWhite.svg";
import ReportBlack from "../../assets/images/report.svg";
import ReportWhite from "../../assets/images/reportwhite.svg";
import CAllDAtaBlack from "../../assets/images/file.svg";
import CAllDataWhite from "../../assets/images/filewhite.svg";
import User1 from "../../assets/images/user.svg";
import User1White from "../../assets/images/Userwhitenew.svg";
import SettingBlack from "../../assets/images/settings.svg";
import SettingWhite from "../../assets/images/settingswhite.svg";
import HelpImg from "../../assets/images/Vector.svg";
import HelpImgWhite from "../../assets/images/helpWhite.svg";
import { Link, useLocation } from "react-router-dom";
import CLose from "../../assets/images/close_big.svg";

function SideBar({
  overviewShow,
  setOverviewShow,
  debtorProfile,
  setdebtorProfile,
}) {
  const image_link_src = localStorage.getItem("company_image");
  const handleItemClick = (item) => {
    setOverviewShow(item === "overview");
    setdebtorProfile(item === "debtorProfile");
  };

  const [isUpgradePlanVisible, setIsUpgradePlanVisible] = useState(false);

  const handleCloseUpgradePlan = () => {
    setIsUpgradePlanVisible(false);
  };

  const location = useLocation();
  return (
    <div className="sidebar">
      <div className="sidebar_container">
        <nav id="sidebar">
          <div className="sidebar-header">
            <div className="new_chat_btn">
              <img src={image_link_src} alt="Logo" />
            </div>
          </div>
          <div className="sidebar-body">
            <div className="sidebar-body-container">
              <div className="mainMenu">
                <h6>MAIN MENU</h6>
                <div className="mainMenuContainer">
                  <ul>
                    <li>
                      <Link to="/Amra_Dashboard">
                        <div
                          className={`mainMenuItem ${
                            location.pathname === "/Amra_Dashboard"
                              ? "activemainMenuItem"
                              : ""
                          }`}
                        >
                          <img
                            src={
                              location.pathname === "/Amra_Dashboard"
                                ? Dashboardwhite
                                : DashboardBlack
                            }
                            alt="icon"
                          />
                          <p>Overview</p>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to="/Debtorprofile">
                        <div
                          className={`mainMenuItem ${
                            location.pathname === "/Debtorprofile" ||
                            location.pathname ===
                              "/Debtorprofile/Bucket30days" ||
                            location.pathname ===
                              "/Debtorprofile/Bucket60days" ||
                            location.pathname === "/Debtorprofile/Bucket90days"
                              ? "activemainMenuItem"
                              : ""
                          }`}
                        >
                          <img
                            src={
                              location.pathname === "/Debtorprofile" ||
                              location.pathname ===
                                "/Debtorprofile/Bucket30days" ||
                              location.pathname ===
                                "/Debtorprofile/Bucket60days" ||
                              location.pathname ===
                                "/Debtorprofile/Bucket90days"
                                ? debtorWhite
                                : DebtorBlack
                            }
                            alt="icon"
                          />
                          <p>Debtor Profile</p>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to="/paymentHistory">
                        <div
                          className={`mainMenuItem ${
                            location.pathname === "/paymentHistory"
                              ? "activemainMenuItem"
                              : ""
                          }`}
                        >
                          <img
                            src={
                              location.pathname === "/paymentHistory"
                                ? PaymentWhite
                                : PaymentBlack
                            }
                            alt="icon"
                          />
                          <p>Payment History</p>
                          <span
                            className={`postMvp ${
                              location.pathname === "/paymentHistory"
                                ? "postMvpWhite"
                                : " "
                            }`}
                          >
                            Post MVP
                          </span>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to="/callDetailView">
                        <div
                          className={`mainMenuItem ${
                            location.pathname === "/callDetailView"
                              ? "activemainMenuItem"
                              : ""
                          }`}
                        >
                          <img
                            src={
                              location.pathname === "/callDetailView"
                                ? CallWhite
                                : CallBlack
                            }
                          />
                          <p>Viewing Call Details</p>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to="/GeneratingReport">
                        <div
                          className={`mainMenuItem ${
                            location.pathname === "/GeneratingReport"
                              ? "activemainMenuItem"
                              : ""
                          }`}
                        >
                          <img
                            src={
                              location.pathname === "/GeneratingReport"
                                ? ReportWhite
                                : ReportBlack
                            }
                            alt="icon"
                          />

                          <p>Generating Reports</p>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to="/CallData">
                        <div
                          className={`mainMenuItem ${
                            location.pathname === "/CallData"
                              ? "activemainMenuItem"
                              : ""
                          }`}
                        >
                          <img
                            src={
                              location.pathname === "/CallData"
                                ? CAllDataWhite
                                : CAllDAtaBlack
                            }
                            alt="icon"
                          />
                          <p>Search Call Data</p>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to="/Amra_manage_user">
                        <div
                          className={`mainMenuItem ${
                            location.pathname === "/Amra_manage_user"
                              ? "activemainMenuItem"
                              : ""
                          }`}
                        >
                          <img
                            src={
                              location.pathname === "/Amra_manage_user"
                                ? User1White
                                : User1
                            }
                            alt="icon"
                          />
                          <p>User Management</p>
                        </div>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="mainMenu">
                <h6>PREFERENCES</h6>
                <div className="mainMenuContainer">
                  <ul>
                    <li>
                      <Link to="/SettingPage">
                        <div
                          className={`mainMenuItem ${
                            location.pathname === "/SettingPage" ||
                            location.pathname ===
                              "/SettingPage/Usermanagement" ||
                            location.pathname === "/SettingPage/Invoicepage"
                              ? "activemainMenuItem"
                              : ""
                          }`}
                        >
                          <img
                            src={
                              location.pathname === "/SettingPage" ||
                              location.pathname ===
                                "/SettingPage/Usermanagement" ||
                              location.pathname === "/SettingPage/Invoicepage"
                                ? SettingWhite
                                : SettingBlack
                            }
                            alt="icon"
                          />
                          <p>Settings</p>
                          <span
                            className={`postMvp ${
                              location.pathname === "/SettingPage" ||
                              location.pathname ===
                                "/SettingPage/Usermanagement" ||
                              location.pathname === "/SettingPage/Invoicepage"
                                ? "postMvpWhite"
                                : " "
                            }`}
                          >
                            Post MVP
                          </span>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to="/HelpSupport1">
                        <div
                          className={`mainMenuItem ${
                            location.pathname === "/HelpSupport1" ||
                            location.pathname ===
                              "/HelpSupport1/Reply_to_ticket" ||
                            location.pathname ===
                              "/HelpSupport1/Create_new_ticket"
                              ? "activemainMenuItem"
                              : ""
                          }`}
                        >
                          <img
                            src={
                              location.pathname === "/HelpSupport1" ||
                              location.pathname ===
                                "/HelpSupport1/Reply_to_ticket" ||
                              location.pathname ===
                                "/HelpSupport1/Create_new_ticket"
                                ? HelpImgWhite
                                : HelpImg
                            }
                            alt="icon"
                          />
                          <p>Help & Support</p>
                        </div>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>

              {isUpgradePlanVisible && (
                <div className="upgradePlan">
                  <div className="upgradePlanContianer">
                    <p className="upgradePlanHead">
                      Upgrade to <br />
                      Enterprise Account!
                    </p>
                    <p>
                      Increase your Debt Collection by using special features of
                      Enterprise Membership.
                    </p>
                    <Link to="/BillingForm">
                      <button>Upgrade</button>
                    </Link>
                    <div
                      className="closeImgDiv"
                      onClick={handleCloseUpgradePlan}
                    >
                      <img src={CLose} alt="icon" />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
}

export default SideBar;
