import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../../Css/Login.css";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { URL_FOR_AMRA_ADMIN_LOGIN } from "../../KeyConnection/KeysService";
import { Helmet } from "react-helmet";
import $ from "jquery";
import axios from "axios";
import moment from "moment";
function DoctorLogin() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [bothError, setBothError] = useState("");
  const [isLoading, setLoading] = useState(false);
  const navigatesignin = useNavigate();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleLogin = async () => {
    // Reset previous error messages
    setEmailError("");
    setPasswordError("");

    // Email validation
    if (!email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      setEmailError("Invalid email address");
      return;
    }

    if (!email && !password) {
      setBothError(
        "UserId or Password cannot be empty. Please enter with valid credentials"
      );
      return;
    }

    // Password validation
    if (!password || password.length < 6) {
      setPasswordError("Short Password");
      return;
    }

    // Make API request for authentication
    try {
      setShowLoader(true);

      const response = await fetch(URL_FOR_AMRA_ADMIN_LOGIN, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          useremail: email,
          userpassword: password,
        }),
      });

      const data = await response.json();

      if (data.status === "true") {
        console.log("Login successful!");

        // Redirect or perform other actions for successful login
        navigatesignin("/burjeel_admin");
        // Assuming you have 'useHistory' from react-router-dom imported at the top of your component file
      } else {
        console.log("Login failed!");
        // Handle login failure, show an error message, etc.
      }
    } catch (error) {
      console.error("Error during login:", error);
    } finally {
      setShowLoader(false);
    }
  };

  const validateForm = () => {
    var isValid = true;

    $(".trio_mendate").each(function () {
      const $inputfld = $(this);
      const value = $.trim($inputfld.val());
      if (value === "") {
        isValid = false;
        $inputfld.css({ border: "1px solid red", width: "50px !important" });
        if ($inputfld.next("span.err_msg").length === 0) {
          $("<span>")
            .addClass("err_msg")
            .text("This field cannot be empty. Please fill this field")
            .insertAfter($inputfld);
        }
      } else {
        $inputfld.css({ border: "", background: "" });
        $inputfld.next("span.err_msg").remove();
      }
    });

    if (isValid === true) {
      $("span.err_msg").text("");
      let email_id = email;
      let userpassword = password;

      if (!validateEmail(email_id)) {
        isValid = false;
        adderrorspan("email_id", "User Id is not valid");
      }

      if (userpassword.length < 8) {
        isValid = false;
        adderrorspan("password", "Password should be at least 8 characters");
      }

      if (isValid === true) {
        const formData = new FormData(
          document.getElementById("form_data_admin")
        ); // Get form data
        //formData.append('appauth_key', appauth_key);
        const jsonData = {};

        for (let [key, value] of formData.entries()) {
          jsonData[key] = value;
        }

        saving_data(jsonData); // Pass the form data to the saving_data function
      }
    }
  };

  const saving_data = async (jsonData) => {
    const headers = {
      "Content-Type": "application/json", // Change the content type to handle file upload
    };
    setLoading(true);

    axios
      .post(URL_FOR_AMRA_ADMIN_LOGIN, jsonData, headers)
      .then(function (response) {
        setLoading(false);
        if (response.data.status !== "success") {
          $(".server_error").text(response.data.msg);
        } else {
          // Session Storage
          session_func(response, 0);
        }
      })
      .catch(function (error) {
        console.error(error);
        $(".server_error").text(error.message);
        // Handle the error here
        setLoading(false);
        // You can display an error message or perform any necessary actions
      });
  };

  const session_func = (response, google_flg) => {
    console.log(response.data.data);
    localStorage.setItem("user_id", response.data.data.id);
    localStorage.setItem("amra_admin_name", response.data.data.name);
        localStorage.setItem("amra_admin_id", response.data.data.id);
        localStorage.setItem("amra_admin_email", response.data.data.email);

        localStorage.setItem(
          "company_image",
          "https://t3.ftcdn.net/jpg/05/17/81/50/360_F_517815080_tD3cmJRmhhztVmeHrnJd3DA4HT1Zkks7.jpg"
        );
        localStorage.setItem("login_for", "amra_admin_user"); //for individual dashboard

        // Redirect or perform other actions for successful login
        navigatesignin("/burjeel_admin");

    //navigatesignin("/Amra_Dashboard");
  };
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const adderrorspan = (clasnm, msg) => {
    $("<span>")
      .addClass("err_msg")
      .text(msg)
      .insertAfter("." + clasnm);
    $("." + clasnm).css({ border: "1px solid red", width: "50px !important" });
  };

  const [showLoader, setShowLoader] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="login">
      <Helmet>
        <title>Burjeel Admin Dashboard</title>
        <meta
          name="description"
          content="AI and Global Reach: The Key to Scalable Solutions in Customer
          Experience"
        />
      </Helmet>
      
      <div className="login_wrapper">
        <div className="login_container col-xl-3 col-lg-4 col-md-5 col-sm-7 col-11 text-center shadow">
          <form id="form_data_admin">
            <div className="form_div col-lg-10 col-md-10 col-10 m-auto py-5">
              <div className="dashboardLoginName">
                <h4>
                 Admin <span>Login</span>
                </h4>
                <h6>(Burjeel)</h6>
              </div>
              <div className="input_fields dashboardLoginNameInputFields">
                <label>Email</label>
                <br />
                <input
                  name="useremail"
                  className="trio_email trio_mendate email_id"
                  type="text"
                  value={email}
                  onChange={handleEmailChange}
                  placeholder="Email"
                ></input>
                <span className="errormsgspan" style={{ color: "red" }}>
                  {emailError}
                </span>
                <br />
                <label> Password</label>
                <br />
                <div className="login_password">
                  <input
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={handlePasswordChange}
                    name="userpassword"
                    className="trio_mendate trio_password password"
                    placeholder="Password"
                  />
                  <span className="errormsgspan" style={{ color: "red" }}>
                    {passwordError}
                  </span>
                  <button
                    className="eye_btn_"
                    type="button"
                    onClick={handleTogglePassword}
                  >
                    {showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
                  </button>
                </div>
              </div>
              <div
                className={isLoading ? "loader-overlay show" : "loader-overlay"}
              >
                <div className="loader23"></div>
              </div>
              <span className="server_error err_msg"></span>
              <div className="login_btn_div text-center">
                <button
                  className="btn login_btn shadow"
                  type="button"
                  onClick={validateForm}
                >
                  Log in
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default DoctorLogin;
