import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import CenterNavbar from "./CenterNavbar.js";
import SideBar from "./SideBar";
import $ from "jquery";
import Success from "../../assets/images/success_gif.gif";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";

import "moment/locale/en-au";
import {
  server_post_data_shubham,
  GET_DEPARTMENT_FARIDA,
  CREATE_DOCTOR_FARIDA,
  GET_DOCTOR_FARIDA,
} from "../../KeyConnection/KeysService.js";
import {
  check_vaild_save,
  combiled_form_data,
  empty_form,
  handleAphabetsChange,
  handleEmailChange,
  handleIaphabetnumberChange,
  handleNumbersChange,
} from "../../CommonJquery/CommonJquery.js";

function AmraAdminCustomers() {
  const [userLandmarks, setuserLandmarks] = useState([""]);
  const [control, setControl] = useState(null);

  const handleAddLandmark = (e) => {
    e.preventDefault();
    setuserLandmarks([...userLandmarks, ""]);
  };
  const handleRemoveLandmark = (e) => {
    e.preventDefault();
    const updated = [...userLandmarks];
    updated.pop();
    setuserLandmarks(updated);
  };
  const [editoHostipallist, setEditHostipallist] = useState([]);
  const [Hostipallist, setHostipallist] = useState([]);
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const retrievedAdminId = "0";
  const hospitalID = localStorage.getItem("hospital_id") || "14";
  const [dynaicimage, setDynaicimage] = useState(null);
  const [showModal1, setShowModal1] = useState(false);
  const [editFlag, setEditFlag] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const closeModal = () => {
    setShowModal(false);
  };

  const controlOptions = [
    { name: "Male", code: "1" },
    { name: "Female", code: "2" },
    { name: "Other", code: "3" },
  ];

  const handleShowModal1 = (call_type) => {
    if (call_type === 0) {
      setEditHostipallist([]);
      setEditFlag(0);
    } else {
      setEditHostipallist(Hostipallist[call_type - 1]);
      setEditFlag(Hostipallist[call_type - 1].doctor_id);
      setControl(controlOptions[Hostipallist[call_type - 1].gender]);
      console.log(Hostipallist[call_type - 1]);
      let arr = Hostipallist[call_type - 1].departments.split(",");
      setSelectedSpecialties([...arr]);
      console.log(arr);
      let arr2 = [];
      arr.forEach((num) => {
        departmentData.forEach((department) => {
          if (department.department_id == num) {
            arr2.push(department.department_name);
          }
        });
      });
      setSelectedSpecialtiesName([...arr2]);

      console.log("selectedSpecialties:", selectedSpecialties);
      console.log("selectedSpecialtiesName:", selectedSpecialtiesName);
    }
    setShowModal1(true);
  };

  const handleCloseModal1 = () => {
    setShowModal1(false);
  };

  const [AmraadminData, setdataFromAmra] = useState([
    {
      company_name: "",
      customer: "", // to be decided
      customer_id: ``,
      plan: "", // to be decided
      license: "",
      avg_call_Dur: "", // to be decided
      boarding_date: "",
      comission: 0,
      total_collection: 0,
      bucket_30: 0,
      bucket_60: 0,
      bucket_90: 0,
      l2_esc: 0,
    },
  ]);

  useEffect(() => {
    const start_date = "";
    const end_date = "";
    const flag = "1";
    let call_id = "0";

    master_data_get(start_date, end_date, flag, call_id);
  }, []);

  useEffect(() => {
    if (Hostipallist.length > 0) {
      // Destroy the DataTable if it already exists
      if ($.fn.DataTable.isDataTable("#myTable")) {
        $("#myTable").DataTable().destroy();
      }
      // Initialize DataTable
      $("#myTable").DataTable();
    }
  }, [Hostipallist]);

  const master_data_get = async (start_date, end_date, flag, call_id) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("admin_id", retrievedAdminId);
    fd.append("start_date", start_date);
    fd.append("end_date", end_date);
    fd.append("flag", flag);
    fd.append("call_id", call_id);
    fd.append("hospital_id", hospitalID);

    await server_post_data_shubham(GET_DEPARTMENT_FARIDA, fd)
      .then((Response) => {
        if (Response.data.error) {
          alert(Response.data.message);
        } else {
          console.log(Response.data.message.data_slider);
          setDepartmentData(Response.data.message.data_slider);
          let hostiptal_list = Response.data.message.department_list;
          setDepartmentList(hostiptal_list);
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });

    await server_post_data_shubham(GET_DOCTOR_FARIDA, fd)
      .then((Response) => {
        if (Response.data.error) {
          alert(Response.data.message);
        } else {
          let hostiptal_list = Response.data.message.data_slider;
          console.log(hostiptal_list);
          setHostipallist(hostiptal_list);
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };

  const handleSaveChangesdynamic = async (form_data, url_for_save) => {
    let vaild_data = check_vaild_save(form_data);
    if (vaild_data) {
      setshowLoaderAdmin(true);
      let fd_from = combiled_form_data(form_data, dynaicimage);
      fd_from.append("active_plan_id", control.code);
      fd_from.append("edit_flag", editFlag);
      fd_from.append("totallandmarkcount", userLandmarks.length);
      fd_from.append("hospital_id", hospitalID);
      fd_from.append("departments_str", selectedSpecialties.join(","));
      await server_post_data_shubham(url_for_save, fd_from)
        .then((Response) => {
          console.log(Response.data.message);
          setshowLoaderAdmin(false);
          if (Response.data.error) {
            alert(Response.data.message);
          }
          if (!Response.data.error) {
            empty_form(form_data);
            handleCloseModal1();
            setShowModal(true);
            const start_date = "";
            const end_date = "";
            const flag = "1";
            let call_id = "0";

            master_data_get(start_date, end_date, flag, call_id);
          }
        })
        .catch((error) => {
          setshowLoaderAdmin(false);
        });
    }
  };

  const handleFileChangedynamic = (keyname) => (event) => {
    const file = event.target.files[0];

    let new_file_name = keyname + "_show";

    if (file) {
      const reader = new FileReader();

      reader.onload = () => {
        setDynaicimage((prevImages) => ({
          ...prevImages,
          [keyname]: file,
          [new_file_name]: reader.result,
        }));
      };
      reader.readAsDataURL(file);
    } else {
      setDynaicimage((prevImages) => ({
        ...prevImages,
        [keyname]: null,
        [new_file_name]: null,
      }));
    }
  };

  const handleLandmarkChange = (index, value) => {
    const updatedLandmarks = [...userLandmarks];
    updatedLandmarks[index] = value;
    setuserLandmarks(updatedLandmarks);
  };

  // const specialties = [
  //   "Anesthesiology",
  //   "Bariatric & Metabolic Surgery",
  //   "Bone Marrow Transplantation",
  //   "Cardiology and Cardiovascular Surgery",
  //   "Critical Care & ICU",
  //   "Dentistry",
  //   "Dermatology & Cosmetology",
  //   "Dietetics",
  //   "Emergency Medicine",
  //   "Endocrinology",
  //   "ENT, Head & Neck Surgery",
  //   "Family Medicine",
  //   "Gastroenterology",
  //   "General Medicine",
  //   "General & Laparoscopic Surgery",
  //   "Gynecology & Obstetrics",
  //   "Internal Medicine",
  //   "Long Term Care, Physiotherapy & Rehabilitation",
  // ];

  const [departmentList, setDepartmentList] = useState([]);
  const [departmentData, setDepartmentData] = useState([]);

  const [selectedSpecialties, setSelectedSpecialties] = useState([]);
  const [selectedSpecialtiesName, setSelectedSpecialtiesName] = useState([]);

  const handleCheckboxChange = (event) => {
    const value = event.target.value;
    const updatedSpecialties = [...selectedSpecialties];
    const updatedSpecialtiesName = [...selectedSpecialtiesName];

    let matchingDepartmentId = null;

    departmentData.forEach((department) => {
      if (department.department_name === value) {
        matchingDepartmentId = department.department_id;
      }
    });

    if (updatedSpecialties.includes("" + matchingDepartmentId)) {
      // If the specialty is already selected, remove it
      updatedSpecialties.splice(
        updatedSpecialties.indexOf("" + matchingDepartmentId),
        1
      );
    } else {
      // If the specialty is not selected, add it
      updatedSpecialties.push("" + matchingDepartmentId);
    }

    if (updatedSpecialtiesName.includes(value)) {
      // If the specialty is already selected, remove it
      updatedSpecialtiesName.splice(updatedSpecialtiesName.indexOf(value), 1);
    } else {
      // If the specialty is not selected, add it
      updatedSpecialtiesName.push(value);
    }

    setSelectedSpecialties(updatedSpecialties);
    setSelectedSpecialtiesName(updatedSpecialtiesName);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle the selected specialties as needed (e.g., send to server)
    console.log("Selected Specialties:", selectedSpecialties);
  };

  return (
    <div className="dashboard">
      <div
        className={showLoaderAdmin ? "loader-overlay show" : "loader-overlay"}
      >
        <div className="loader23"></div>
      </div>
      <Helmet>
        <title>Farida User - Doctors</title>
        <meta
          name="description"
          content="AI and Global Reach: The Key to Scalable Solutions in Customer
          Experience"
        />
      </Helmet>
      <div className="dashboard_container">
        <div className="dashboard_wrapper">
          <SideBar />
          <div id="content">
            <CenterNavbar />

            <div className="Dashpages_contaioner">
              <div className="overview mt-3">
                <div className="overviewContaINER">
                  <div className="revenueCall">
                    <div className="row m-0">
                      <div className="col-md-8 p-0  "></div>
                      <div className="col-md-4">
                        <div className="AddAgentBtn w-100 m-0">
                          <button
                            className="w-100"
                            style={{ height: "45px" }}
                            onClick={() => handleShowModal1(0)}
                          >
                            Add Doctor
                          </button>
                        </div>
                        {/* <div className="searchClientContaienr">
                          <div
                            className="searchContaienr"
                            style={{ backgroundColor: "#8639CE" }}
                          >
                            <div className="text-center col-xl-10 m-auto">
                              <h5>Search Hospital </h5>
                              <div className="searchInputsConta">
                                <input
                                  placeholder="Hospital Name "
                                  type="text"
                                  value={customerName}
                                  onChange={(e) => {
                                    setMobileNo("");
                                    setCustomerName(e.target.value);
                                  }}
                                />
                                <p
                                  style={{
                                    fontWeight: "500",
                                    margin: "0.5rem 0",
                                  }}
                                >
                                  or
                                </p>
                                <input
                                  placeholder="Mobile No."
                                  type="text"
                                  value={mobileNo}
                                  onChange={(e) => {
                                    setCustomerName("");
                                    setMobileNo(e.target.value);
                                  }}
                                />
                              </div>
                              <div className="searchBtnADmin">
                                <button onClick={fetchCustomerData}>
                                  <img src={searchIcon} alt="Icon" />
                                  <p>Search </p>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </div>

                    <div className="amradataTable">
                      <div className="amradataTable_container">
                        <h4>Doctor List</h4>
                        <table id="myTable" className="display">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Name</th>
                              <th>Experience</th>
                              <th>Date of Onboarding</th>
                              <th>Specializes In</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Hostipallist.map((item, index) => (
                              <tr key={index}>
                                <td>
                                  <p style={{ color: "#000" }}>{index + 1}</p>
                                </td>
                                <td>
                                  <p style={{ color: "#000" }}>
                                    {item.doctor_name}
                                  </p>
                                </td>
                                <td>
                                  <p style={{ color: "#000" }}>
                                    {item.experience}
                                  </p>
                                </td>
                                <td style={{ color: "#000" }}>
                                  {item.created_at}
                                </td>
                                <td>
                                  <p style={{ color: "#000" }}>
                                    {item.specialization}
                                  </p>
                                </td>
                                <td>
                                  <button
                                    className="editUsrContrlBtn"
                                    onClick={() => handleShowModal1(index + 1)}
                                  >
                                    Edit
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showModal1}
        onHide={handleCloseModal1}
        dialogClassName="custom-modal2 custom-modal3"
        centered
        size="xl"
      >
        <Modal.Header>
          <h5 className="mt-3">Add Doctor</h5>
        </Modal.Header>
        <Modal.Body>
          <div className="mngUserContainer px-xl-5">
            <form className="needs-validation" id="CarrerformData">
              <div className="row m-0">
                <div className="col-md-6">
                  <div className="mngUserInput">
                    <label>Doctor Name</label>
                    <br />
                    <input
                      className="form-control  trio_mandatory"
                      name="doctor_name"
                      id="doctor_name"
                      maxLength={100}
                      onInput={handleAphabetsChange}
                      placeholder="Enter doctor Name"
                      defaultValue={editoHostipallist?.doctor_name || ""}
                      type="text"
                    />
                    <span className="condition_error"></span>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mngUserInput">
                    <label>Years of Experience</label>
                    <br />
                    <input
                      className="form-control  trio_mandatory trio_no"
                      name="experience"
                      id="experience"
                      maxLength={15}
                      onInput={handleNumbersChange}
                      placeholder="Enter years of experience"
                      defaultValue={editoHostipallist?.experience || ""}
                      type="text"
                    />
                    <span className="condition_error"></span>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mngUserInput">
                    <label>Specialization</label>
                    <br />
                    <input
                      className="form-control  trio_mandatory"
                      name="specialization"
                      id="specialization"
                      maxLength={100}
                      onInput={handleAphabetsChange}
                      placeholder="Enter specialization"
                      defaultValue={editoHostipallist?.specialization || ""}
                      type="text"
                    />
                    <span className="condition_error"></span>
                  </div>
                </div>
                {editoHostipallist.length === 0 && (
                  <div className="col-md-6">
                    <div className="mngUserInput">
                      <label>Email</label>
                      <br />
                      <input
                        className="form-control  trio_mandatory trio_email"
                        name="email"
                        id="email"
                        maxLength={100}
                        onInput={handleEmailChange}
                        placeholder="Enter email"
                        defaultValue={editoHostipallist?.email || ""}
                        type="text"
                      />
                      <span className="condition_error"></span>
                    </div>
                  </div>
                )}
                <div className="col-md-6">
                  <div className="mngUserInput">
                    <label>Nationality</label>
                    <br />
                    <input
                      className="form-control  trio_mandatory"
                      name="nationality"
                      id="nationality"
                      maxLength={100}
                      onInput={handleAphabetsChange}
                      placeholder="Enter nationality"
                      defaultValue={editoHostipallist?.nationality || ""}
                      type="text"
                    />
                    <span className="condition_error"></span>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mngUserInput">
                    <label>Gender</label>
                    <br />
                    <Dropdown
                      className="form-control d-flex"
                      style={{ border: "none", backgroundColor: "#f2f0f0" }}
                      name="gender"
                      id="gender"
                      value={control}
                      onChange={(e) => setControl(e.value)}
                      options={controlOptions}
                      optionLabel="name"
                    />
                    <span className="condition_error"></span>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="mngUserInput mngUserInput2">
                    <label className="mb-3">Departments:</label>
                    <div className="row m-0">
                      {departmentData.map((specialty) => (
                        <div
                          className="col-md-6"
                          key={specialty.department_name}
                        >
                          <div className="d-flex align-items-start gap-2 mb-2">
                            <input
                              className="form-control "
                              style={{ width: "fit-content", height: "24px" }}
                              type="checkbox"
                              id={specialty.department_name}
                              name="departments"
                              value={specialty.department_name}
                              checked={selectedSpecialties.includes(
                                "" + specialty.department_id
                              )}
                              onChange={handleCheckboxChange}
                            />
                            <label htmlFor={specialty.department_name}>
                              {specialty.department_name}
                            </label>
                            <span className="condition_error"></span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="updateCnclebtnDiv">
            <button className="updateCnclebtnCan" onClick={handleCloseModal1}>
              Cancel
            </button>
            <button
              className="updateCnclebtnUp"
              onClick={() =>
                handleSaveChangesdynamic("CarrerformData", CREATE_DOCTOR_FARIDA)
              }
            >
              {editoHostipallist?.name ? "Update" : "Add"}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      {/* second modal */}
      <Modal
        id="myModal"
        show={showModal}
        onHide={closeModal}
        centered
        backdrop="static"
      >
        <Modal.Body className="modal_body">
          <div className="success_img d-flex justify-content-center">
            <img src={Success} alt="icon" />
          </div>
          <p>Doctor has been updated successfully</p>
        </Modal.Body>
        <Modal.Footer>
          <Link
            to="/burjeel_doctors"
            onClick={() => window.location.reload(false)}
          >
            <div className="btn go_to_login">View</div>
          </Link>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default AmraAdminCustomers;
