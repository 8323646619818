import React from "react";
import "../Css/PolicyAndTerms.css";
import Header from "./Header";
import Footer from "./Footer";
import { Helmet } from "react-helmet";

function TermsOfUse() {
  return (
    <div className="termsOfUse">
      <Helmet>
        <title>Terms of Use</title>
        <meta
          name="description"
          content="Please read these Terms of Use carefully before using the website."
        />
      </Helmet>
      <div className="termsOfUse_container">
        <header className="header_container">
          <Header />
        </header>
        <div className="termsOfUse_container_wrapper container-lg">
          <h2 className="termsOfUseHeading">Terms of Use</h2>
          <div className="termsOfUse_content">
            <div className="termsOfUse_content col-lg-10">
              <p>
                Please read these Terms of Use ("Terms," "Terms of Use")
                carefully before using the website{" "}
                <a href="/"> https://www.Triosoft.ai</a>
                (the "Site") operated by Triosoft ("us," "we," or "our"). Your
                access to and use of the Site is conditioned on your acceptance
                of and compliance with these Terms. These Terms apply to all
                visitors, users, and others who access or use the Site. By
                accessing or using the Site, you agree to be bound by these
                Terms. If you disagree with any part of the Terms, then you may
                not access the Site.
              </p>

              <h4>Content</h4>

              <p>
                Our Site allows you to post, link, store, share, and otherwise
                make available certain information, text, graphics, videos, or
                other material ("Content"). You are responsible for the Content
                that you post on or through the Site, including its legality,
                reliability, and appropriateness.
              </p>
              <p>
                By posting Content on or through the Site, you grant us the
                right and license to use, modify, publicly perform, publicly
                display, reproduce, and distribute such Content on and through
                the Site. You retain any and all of your rights to any Content
                you submit, post, or display on or through the Site and you are
                responsible for protecting those rights.
              </p>

              <h4>Intellectual Property</h4>

              <p>
                The Site and its original content (excluding Content provided by
                users), features, and functionality are and will remain the
                exclusive property of Triosoft and its licensors. The Site is
                protected by copyright, trademark, and other laws of both the
                United States and foreign countries. Our trademarks and trade
                dress may not be used in connection with any product or service
                without the prior written consent of Triosoft.
              </p>

              <h4>Links To Other Web Sites</h4>

              <p>
                Our Site may contain links to third-party websites or services
                that are not owned or controlled by Triosoft.
              </p>
              <p>
                Triosoft has no control over, and assumes no responsibility
                for, the content, privacy policies, or practices of any
                third-party websites or services. You further acknowledge and
                agree that Triosoft shall not be responsible or liable,
                directly or indirectly, for any damage or loss caused or alleged
                to be caused by or in connection with the use of or reliance on
                any such content, goods, or services available on or through any
                such websites or services.
              </p>
              <p>
                We strongly advise you to read the terms and conditions and
                privacy policies of any third-party websites or services that
                you visit.
              </p>

              <h4>Termination</h4>

              <p>
                We may terminate or suspend your access to the Site immediately,
                without prior notice or liability, for any reason whatsoever,
                including without limitation if you breach these Terms.
              </p>
              <p>
                Upon termination, your right to use the Site will immediately
                cease. If you wish to terminate your account, you may simply
                discontinue using the Site.
              </p>

              <h4>Governing Law</h4>

              <p>
                These Terms shall be governed and construed in accordance with
                the laws of [Your Jurisdiction], without regard to its conflict
                of law provisions.
              </p>

              <h4>Changes To These Terms</h4>

              <p>
                We reserve the right, at our sole discretion, to modify or
                replace these Terms at any time. If a revision is material, we
                will try to provide at least [30 days' notice] prior to any new
                terms taking effect. What constitutes a material change will be
                determined at our sole discretion.
              </p>
              <p>
                By continuing to access or use our Site after those revisions
                become effective, you agree to be bound by the revised terms. If
                you do not agree to the new terms, please stop using the Site.
              </p>

              <h4>Contact Us</h4>

              <p>
                If you have any questions about this Privacy Policy, please
                contact us at <b>info@Triosoft.co.in</b>  
              </p>
            </div>
          </div>
        </div>
        <footer className="footer_area section_padding_130_0 px-lg-5 px-md-4 px-sm-3 px-1">
          <Footer />
        </footer>
      </div>
    </div>
  );
}

export default TermsOfUse;
