import React, { useState, useEffect } from "react";
import "../Css/Dashboard.css";
import SideBar2 from "./Sidebar2";
import NotificationsImg from "../../assets/images/notification.svg";
import SideBarControl from "../../assets/images/rightLeftSIdebar.png";
import UserImgPrf from "../../assets/images/userimg45.svg";
import adm_img from "../../assets/images/adm_img.png";
import { Helmet } from "react-helmet";
import { Bar } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import {
  URL_FOR_CUTOMER_FAQ,
  URL_FOR_NEWS_SAVE,
  URL_GET_NAFIA_CONVR,
  URL_FOR_CAREER_SAVE,
  URL_FOR_BOOK_NOW_SAVE,
  URL_GET_AMRA_CONVR,
  URL_GET_NAFIA_CONVR_COUNT,
  URL_GET_AMRA_CONVR_COUNT,
  server_get_data_master,
  URL_FOR_DASHBOARD_DATA,
} from "../../KeyConnection/KeysService"; // Adjust the path as needed

function TechnotaskDashboard() {
  const [dataFromNafia, setDataFromNafia] = useState([]);
  const [dataFromAmra, setdataFromAmra] = useState([]);
  const [dataFromNafiaMSgCnt, setdataFromNafiaMSgCnt] = useState("0");
  const [dataFromAmraMsgCnt, setdataFromAmraMsgCnt] = useState("0");
  const [dataFromCareer, setdataFromCareer] = useState([]);
  const [dataFromNews, setdataFromNews] = useState([]);
  const [dataFromBook, setdataFromBook] = useState([]);
  const [dataFromCustomer, setdataFromCustomer] = useState([]);
  const [showLoaderWebsite, setshowLoaderWebsite] = useState(false);
  const [showDashboardData,setdashboardData]=useState([]);
  const getUserAmraCnt = async (url_for) => {
    const fd = new FormData();
    await server_get_data_master(url_for, fd)
      .then((response) => response.json())
      .then((data) => {
        setshowLoaderWebsite(false);
        if (data.status === "error") {
          // alert(data);
        } else {
          setdataFromAmraMsgCnt(data);
        }
      })
      .catch((error) => {
        console.log("asdsa");
        setshowLoaderWebsite(false);
      });
  };

  const getUserNafaiCnt = async (url_for) => {
    const fd = new FormData();
    await server_get_data_master(url_for, fd)
      .then((response) => response.json())
      .then((data) => {
        setshowLoaderWebsite(false);
        console.log(data);
        if (data.status === "error") {
          // alert(data);
        } else {
          setdataFromNafiaMSgCnt(data);
        }
      })
      .catch((error) => {
        setshowLoaderWebsite(false);
      });
  };

  const getUserDataBook = async (url_for) => {
    const fd = new FormData();
    await server_get_data_master(url_for, fd)
      .then((response) => response.json())
      .then((data) => {
        setshowLoaderWebsite(false);
        console.log(data);
        if (data.status === "error") {
          // alert(data.data);
        } else {
          setdataFromBook(data.data);
        }
      })
      .catch((error) => {
        setshowLoaderWebsite(false);
      });
  };

  const getUserDataNafia = async (url_for) => {
    const fd = new FormData();
    await server_get_data_master(url_for, fd)
      .then((response) => response.json())
      .then((data) => {
        setshowLoaderWebsite(false);
        console.log(data);
        if (data.status === "error") {
          // alert(data.data);
        } else {
          setDataFromNafia(data.data);
        }
      })
      .catch((error) => {
        setshowLoaderWebsite(false);
      });
  };

  const getUserData = async (url_for) => {
    const fd = new FormData();
    await server_get_data_master(url_for, fd)
      .then((response) => response.json())
      .then((data) => {
        setshowLoaderWebsite(false);
        console.log(data);
        if (data.status === "error") {
          // alert(data.data);
        } else {
          setdataFromCustomer(data.data);
        }
      })
      .catch((error) => {
        setshowLoaderWebsite(false);
      });
  };

  const getUserDataNews = async (url_for) => {
    const fd = new FormData();
    await server_get_data_master(url_for, fd)
      .then((response) => response.json())
      .then((data) => {
        setshowLoaderWebsite(false);
        console.log(data);
        if (data.status === "error") {
          // alert(data.data);
        } else {
          setdataFromNews(data.data);
        }
      })
      .catch((error) => {
        setshowLoaderWebsite(false);
      });
  };

  const getUserDataCareer = async (url_for) => {
    const fd = new FormData();
    await server_get_data_master(url_for, fd)
      .then((response) => response.json())
      .then((data) => {
        setshowLoaderWebsite(false);
        console.log(data);
        if (data.status === "error") {
          // alert(data.data);
        } else {
          setdataFromCareer(data.data);
        }
      })
      .catch((error) => {
        console.log("asdsa");
        setshowLoaderWebsite(false);
      });
  };

  const getUserDataAmra = async (url_for) => {
    const fd = new FormData();
    await server_get_data_master(url_for, fd)
      .then((response) => response.json())
      .then((data) => {
        setshowLoaderWebsite(false);
        console.log(data);
        if (data.status === "error") {
          // alert(data.data);
        } else {
          setdataFromAmra(data.data);
        }
      })
      .catch((error) => {
        setshowLoaderWebsite(false);
      });
  };
  const getDashboardData = async (url_for) => {
    const fd = new FormData();
    await server_get_data_master(url_for, fd)
      .then((response) => response.json())
      .then((data) => {
        setshowLoaderWebsite(false);
        
        if (data.status === "error") {
          // alert(data.data);
        } else {
          setdashboardData(data);
          console.log(data);
        }
      })
      .catch((error) => {
        setshowLoaderWebsite(false);
      });
  };

  useEffect(() => {
    getUserDataAmra(URL_GET_AMRA_CONVR);
    getUserData(URL_FOR_CUTOMER_FAQ);
    getUserDataNews(URL_FOR_NEWS_SAVE);
    getUserDataNafia(URL_GET_NAFIA_CONVR);
    getUserDataCareer(URL_FOR_CAREER_SAVE);
    getUserDataBook(URL_FOR_BOOK_NOW_SAVE);
    getUserAmraCnt(URL_GET_AMRA_CONVR_COUNT);
    getUserNafaiCnt(URL_GET_NAFIA_CONVR_COUNT);
    getDashboardData(URL_FOR_DASHBOARD_DATA);
  }, []);

  Chart.register(...registerables);

  const data = {
    labels: [
      "Customers",
      "Nafia",
      "Amra",
      "Nafia Messages",
      "Amra Messages",
      "Career",
      "Demo",
      "Subscribers",
    ],
    datasets: [
      {
        label: "Progress Data",
        data: [
          showDashboardData.total_users,
          showDashboardData.nafia_users,
          showDashboardData.amra_users,
          showDashboardData.nafia_messages,
          showDashboardData.amra_messages,
          showDashboardData.career_applications,
          showDashboardData.booked_demo,
          showDashboardData.total_subscribers,
        ],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(255, 159, 64, 0.2)",
          "rgba(255, 205, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(255, 159, 64, 0.2)",
          "rgba(255, 205, 86, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(153, 102, 255, 0.2)",
        ], // Customize the bar colors
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(255, 159, 64, 1)",
          "rgba(255, 205, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(255, 159, 64, 1)",
          "rgba(255, 205, 86, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(153, 102, 255, 1)",
        ], // Customize the border colors
        borderWidth: 1, // Customize the border width
      },
    ],
  };

  const options = {
    scales: {
      x: {
        grid: {
          display: false, // Hide x-axis grid lines
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          display: false, // Hide x-axis grid lines
        },
        // Customize the y-axis options
      },
    },
    plugins: {
      legend: {
        display: true,
        position: "top", // Customize the legend position
      },
      title: {
        display: true,
        text: "This Month's Progress Chart", // Customize the chart title
      },
    },
  };

  return (
    <div className="dashboard">
      <Helmet>
        <title>Dashboard</title>
        <meta
          name="description"
          content="AI and Global Reach: The Key to Scalable Solutions in Customer
          Experience"
        />
      </Helmet>
      <div className={showLoaderWebsite ? "loading_website" : ""}></div>
      <div className="dashboard_container">
        <div className="dashboard_wrapper">
          <div className="sidebar_wrapper">
            <SideBar2 />
          </div>
          <div id="content">
            <nav className="navbar centerNav navbar-expand-lg navbar-light bg-light p-0">
              <div className="navbar_left">
                <div
                  type="button"
                  id="sidebarCollapse1"
                  className="btn_toggle1 btn_toggle1ani"
                >
                  <img src={SideBarControl} alt="img" />
                </div>

                <div className="logo_msgCount">
                  <div className="logo_div">
                    <h4 style={{ fontSize: "24px" }}>Dashboard</h4>
                  </div>
                </div>
              </div>

              <div className="navbar_right">
                {/* <div className="navbarRightNoti">
                  <img src={NotificationsImg} alt="icon" />
                </div> */}
                <div className="navbarRightAcc">
                  <img src={adm_img} alt="icon" />
                  <div className="nav-item dropdown">
                    <div
                      className="nav-link dropdown-toggle"
                      id="navbarDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Account
                    </div>
                    <div
                      className="dropdown-menu prdctList LEFTalINGdROPDOWN"
                      aria-labelledby="navbarDropdown"
                    >
                      <a className="dropdown-item" href="/">
                        Settings
                      </a>
                      <a className="dropdown-item" href="/">
                        Logout
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </nav>

            <div className="Dashpages_contaioner">
              <div className="overview">
                <div className="overviewContaINER">
                  <div className="overviewHead">
                    <h4>Overview</h4>
                    <div className="dateContainer"></div>
                  </div>
                </div>
                <div className="AmraConversations_data">
                  <div className="ovrvwCards">
                    <div className="ovrvwCardsContainer">
                      <div className="row m-0">
                        <div className="col-md-3 col-sm-6">
                          <div className="ovrvwCard">
                            <h6>Total Users</h6>
                            <h5>
                            {showDashboardData.total_users}

                            </h5>
                          </div>
                        </div>
                        <div className="col-md-3 col-sm-6">
                          <div className="ovrvwCard">
                            <h6>Nafia Users</h6>
                            <h5>{showDashboardData.nafia_users}</h5>
                          </div>
                        </div>
                        <div className="col-md-3 col-sm-6">
                          <div className="ovrvwCard">
                            <h6>Amra Users</h6>
                            <h5>{showDashboardData.amra_users}</h5>
                          </div>
                        </div>
                        <div className="col-md-3 col-sm-6">
                          <div className="ovrvwCard">
                            <h6>Nafia Messages</h6>
                            <h5>{showDashboardData.nafia_messages}</h5>
                          </div>
                        </div>
                        <div className="col-md-3 col-sm-6">
                          <div className="ovrvwCard">
                            <h6>Amra Messages</h6>
                            <h5>{showDashboardData.amra_messages}</h5>
                          </div>
                        </div>
                        <div className="col-md-3 col-sm-6">
                          <div className="ovrvwCard">
                            <h6>Career Applications</h6>
                            <h5>{showDashboardData.career_applications}</h5>
                          </div>
                        </div>
                        <div className="col-md-3 col-sm-6">
                          <div className="ovrvwCard">
                            <h6>Booked Demo</h6>
                            <h5>{showDashboardData.booked_demo}</h5>
                          </div>
                        </div>
                        <div className="col-md-3 col-sm-6">
                          <div className="ovrvwCard">
                            <h6>Total Subscribers</h6>
                            <h5>{showDashboardData.total_subscribers}</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="chatDataDiv">
                    <div className="row m-0">
                      <div className="col-11 m-auto">
                        <Bar data={data} options={options} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* {amraShow && (
                <AmraConversations sendDataToParent={receiveDataFromAmra} />
              )}
              {customer && (
                <Customers sendDataToParent={receiveDataFromCustomer} />
              )}
              {nafiaShow && (
                <NafiaConversations sendDataToParent={receiveDataFromNafia} />
              )}
              {careeraShow && (
                <CareerView sendDataToParent={receiveDataFromCareer} />
              )}
              {demoShow && (
                <BookDemoView sendDataToParent={receiveDataFromBook} />
              )}
              {newsShow && <NewsView sendDataToParent={receiveDataFromNews} />} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TechnotaskDashboard;
