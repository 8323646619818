import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import CenterNavbar from "./CenterNavbar.js";
import SideBar from "./SideBar";
import $ from "jquery";
import Success from "../../assets/images/success_gif.gif";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";

import "moment/locale/en-au";
import {
  server_post_data_shubham,
  server_get_data_master,
  CREATE_DEPARTMENT_FARIDA,
  GET_DEPARTMENT_FARIDA,
  GET_MASTER_DEPARTMENT_FARIDA,
} from "../../KeyConnection/KeysService.js";
import {
  check_vaild_save,
  combiled_form_data,
  empty_form,
  handleAphabetsChange,
  handleEmailChange,
  handleIaphabetnumberChange,
  handleNumbersChange,
} from "../../CommonJquery/CommonJquery.js";

function AmraAdminCustomers() {
  const [userLandmarks, setuserLandmarks] = useState([""]);
  const [control, setControl] = useState(null);
  const [controlOptions, setControlOptions] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [department_value, setDepartmentValue] = useState("");

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  const [editoHostipallist, setEditHostipallist] = useState([]);
  const [Hostipallist, setHostipallist] = useState([]);
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const retrievedAdminId = "0";
  const [dynaicimage, setDynaicimage] = useState(null);
  const [showModal1, setShowModal1] = useState(false);
  const [editFlag, setEditFlag] = useState(0);
  const hospitalID = localStorage.getItem("hospital_id") || "14";

  const [showModal, setShowModal] = useState(false);
  const closeModal = () => {
    setShowModal(false);
  };

  const handleShowModal1 = (call_type) => {
    if (call_type === 0) {
      setEditHostipallist(null);
      setEditFlag(0);
    } else {
      setEditHostipallist(Hostipallist[call_type - 1]);
      setEditFlag(Hostipallist[call_type - 1].department_id);
      console.log(Hostipallist[call_type - 1]);
      let master = Hostipallist[call_type - 1].master_department_id;
      let control_val = controlOptions.find(
        (department) => department.code == master
      );
      setControl(control_val);
      if (control_val.name === Hostipallist[call_type - 1].department_name) {
        setIsChecked(true);
      }
    }
    setShowModal1(true);
  };

  const handleCloseModal1 = () => {
    setShowModal1(false);
  };

  useEffect(() => {
    let val = "";
    if (isChecked) {
      val = control.name;
      if (editoHostipallist && editoHostipallist.department_name) {
        editoHostipallist.department_name = "";
      }
    } else {
      if (editoHostipallist && editoHostipallist.department_name) {
        val = editoHostipallist.department_name;
      }
    }
    console.log(val);
    setDepartmentValue(val);
    //console.log();
  }, [editoHostipallist, isChecked, control,department_value]);


  
  useEffect(() => {
    const start_date = "";
    const end_date = "";
    const flag = "1";
    let call_id = "0";

    master_data_get(start_date, end_date, flag, call_id);
  }, []);

  useEffect(() => {
    if (Hostipallist.length > 0) {
      // Destroy the DataTable if it already exists
      if ($.fn.DataTable.isDataTable("#myTable")) {
        $("#myTable").DataTable().destroy();
      }
      // Initialize DataTable
      $("#myTable").DataTable();
    }
  }, [Hostipallist]);

  const master_data_get = async (start_date, end_date, flag, call_id) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("admin_id", retrievedAdminId);
    fd.append("start_date", start_date);
    fd.append("end_date", end_date);
    fd.append("flag", flag);
    fd.append("call_id", call_id);
    fd.append("hospital_id", hospitalID);
    await server_post_data_shubham(GET_DEPARTMENT_FARIDA, fd)
      .then((Response) => {
        if (Response.data.error) {
          alert(Response.data.message);
        } else {
          let hostiptal_list = Response.data.message.data_slider;
          console.log(hostiptal_list);
          setHostipallist(hostiptal_list);
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });

    await server_get_data_master(GET_MASTER_DEPARTMENT_FARIDA, fd)
      .then((Response) => {
        return Response.json();
      })
      .then((data) => {
        console.log(data.data);
        if (data.status === "error") {
          alert("Something went wrong");
        } else {
          setControlOptions(data.data.master_list);
        }
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };

  const handleSaveChangesdynamic = async (form_data, url_for_save) => {
    let vaild_data = check_vaild_save(form_data);
    if (vaild_data) {
      setshowLoaderAdmin(true);
      let fd_from = combiled_form_data(form_data, dynaicimage);
      //const fd_from = new FormData();
      fd_from.append("edit_flag", editFlag);
      fd_from.append("hospital_id", hospitalID);
      fd_from.append("code", control.code);
      fd_from.append("name", control.name);
      console.log(control.code);
      await server_post_data_shubham(url_for_save, fd_from)
        .then((Response) => {
          console.log(Response.data.message);
          setshowLoaderAdmin(false);
          if (Response.data.error) {
            alert(Response.data.message);
          }
          if (!Response.data.error) {
            empty_form(form_data);
            handleCloseModal1();
            setShowModal(true);
            const start_date = "";
            const end_date = "";
            const flag = "1";
            let call_id = "0";

            master_data_get(start_date, end_date, flag, call_id);
          }
        })
        .catch((error) => {
          setshowLoaderAdmin(false);
        });
    }
  };

  const handleFileChangedynamic = (keyname) => (event) => {
    const file = event.target.files[0];

    let new_file_name = keyname + "_show";

    if (file) {
      const reader = new FileReader();

      reader.onload = () => {
        setDynaicimage((prevImages) => ({
          ...prevImages,
          [keyname]: file,
          [new_file_name]: reader.result,
        }));
      };
      reader.readAsDataURL(file);
    } else {
      setDynaicimage((prevImages) => ({
        ...prevImages,
        [keyname]: null,
        [new_file_name]: null,
      }));
    }
  };

  return (
    <div className="dashboard">
      <Helmet>
        <title>Farida User - Departments</title>
        <meta
          name="description"
          content="AI and Global Reach: The Key to Scalable Solutions in Customer
          Experience"
        />
      </Helmet>
      <div className="dashboard_container">
        <div
          className={showLoaderAdmin ? "loader-overlay show" : "loader-overlay"}
        >
          <div className="loader23"></div>
        </div>
        <div className="dashboard_wrapper">
          <SideBar />
          <div id="content">
            <CenterNavbar />

            <div className="Dashpages_contaioner">
              <div className="overview mt-3">
                <div className="overviewContaINER">
                  <div className="row m-0">
                    <div className="col-md-8"></div>
                    <div className="col-md-4">
                      <div className="AddAgentBtn w-100 m-0">
                        <button
                          className="w-100"
                          style={{ height: "45px" }}
                          onClick={() => handleShowModal1(0)}
                        >
                          Add Department
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="revenueCall">
                    <div className="amradataTable">
                      <div className="amradataTable_container">
                        <h4>Department List</h4>
                        <table id="myTable" className="display">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Name</th>
                              <th style={{ textAlign: "center" }}>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Hostipallist?.map((item, index) => (
                              <tr key={index}>
                                <td>
                                  <p style={{ color: "#000" }}>{index + 1}</p>
                                </td>
                                <td>
                                  <p style={{ color: "#000" }}>
                                    {item.department_name}
                                  </p>
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  <button
                                    className="editUsrContrlBtn"
                                    onClick={() => handleShowModal1(index + 1)}
                                  >
                                    Edit
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showModal1}
        onHide={handleCloseModal1}
        dialogClassName="custom-modal2"
        centered
        size="xl"
      >
        <Modal.Header>
          <h5 className="mt-3">Add Department</h5>
        </Modal.Header>
        <Modal.Body>
          <div className="mngUserContainer px-5">
            <form className="needs-validation" id="CarrerformData">
              <div className="row m-0">
                <div className="col-md-6">
                  <div className="mngUserInput">
                    <label>Choose Department</label>
                    <br />
                    <Dropdown
                      className="form-control d-flex"
                      style={{ border: "none", backgroundColor: "#f2f0f0" }}
                      name="master_department_id"
                      id="master_department_id"
                      value={control}
                      onChange={(e) => {
                        setControl(e.value);
                        console.log(e.value);
                      }}
                      options={controlOptions}
                      optionLabel="name"
                    />
                    <span className="condition_error"></span>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mngUserInput">
                    <label>Department Known As</label>
                    <br />
                    <input
                      className="form-control  trio_mandatory"
                      name="department_name"
                      id="department_name"
                      maxLength={100}
                      onInput={handleAphabetsChange}
                      placeholder="Enter Department Name"
                      defaultValue={department_value}
                      onChange={(e) => {
                        setDepartmentValue(e.target.value);
                        console.log(e);
                      }}
                      type="text"
                      disabled={isChecked}
                    />
                    <span className="condition_error"></span>
                    {/* Checkbox */}
                    <div className="d-flex align-items-start gap-2 mb-2">
                      <input
                        style={{ width: "fit-content", height: "24px" }}
                        type="checkbox"
                        onChange={handleCheckboxChange}
                        checked={isChecked}
                      />
                      <label>Same as chosen department</label>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="updateCnclebtnDiv">
            <button className="updateCnclebtnCan" onClick={handleCloseModal1}>
              Cancel
            </button>
            <button
              className="updateCnclebtnUp"
              onClick={() =>
                handleSaveChangesdynamic(
                  "CarrerformData",
                  CREATE_DEPARTMENT_FARIDA
                )
              }
            >
              {editoHostipallist?.department_name ? "Update" : "Add"}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      {/* second modal */}
      <Modal
        id="myModal"
        show={showModal}
        onHide={closeModal}
        centered
        backdrop="static"
      >
        <Modal.Body className="modal_body">
          <div className="success_img d-flex justify-content-center">
            <img src={Success} alt="icon" />
          </div>
          <p>Department has been updated successfully</p>
        </Modal.Body>
        <Modal.Footer>
          <Link
            to="/burjeel_department"
            onClick={() => window.location.reload(false)}
          >
            <div className="btn go_to_login">View</div>
          </Link>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default AmraAdminCustomers;
