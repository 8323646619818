import React, { useState, useRef } from "react";
import { Helmet } from 'react-helmet';
import { Link } from "react-router-dom";
import "../Css/Home2.css";
import "../CommonJquery/FunctionJquery.js";
import AudioSpectrum from "../assets/images/spectrum1.png";
import PlayBtn from "../assets/images/playBtn.svg";
import MicBtn from "../assets/images/micBtn.svg";
import Challenge1 from "../assets/images/Challeng1.png";
import Challenge2 from "../assets/images/Challeng2.png";
import Challenge3 from "../assets/images/Challeng3.png";
import Solution1 from "../assets/images/Solution1.png";
import Solution2 from "../assets/images/Solution2.png";
import Solution3 from "../assets/images/Solution3.png";
import Solution4 from "../assets/images/Solution4.png";
import Solution5 from "../assets/images/Solution5.png";
import Solution6 from "../assets/images/Solution6.png";
import Solution7 from "../assets/images/Solution7.png";
import shadowBottom from "../assets/images/shadowBottom.png";
import Nafia2 from "../assets/images/Robot.png";
import Header from "./Header";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import TestiImg from "../assets/images/testiImg.png";
import LeftQoute from "../assets/images/leftQoute.svg";
import RightQoute from "../assets/images/right.svg";

import blog1 from "../assets/images/blog1.png";
import blog2 from "../assets/images/blog2.png";
import blog3 from "../assets/images/blog3.png";
import blog4 from "../assets/images/blog4.png";
import blog5 from "../assets/images/debtBlogImg.png";
import blog6 from "../assets/images/blog6.png";
import Footer from "./Footer";
import WlcmVideo from "../assets/video/wlcmVideo.mp4";
import Testiimg0 from "../assets/images/Testiimg0.png";
import TestiImg2 from "../assets/images/Afsanaimg.jpg";
import $ from "jquery";
import "../CommonJquery/CommonJquery";
import NewsLetter from "./NewsLetter";

function Home() {
  const [challenge1, setChallenge1] = useState(true);
  const [challenge2, setChallenge2] = useState(false);
  const [challenge3, setChallenge3] = useState(false);

  const handleItemClick = (index) => {
    // Depending on the clicked item, update the corresponding challenge state variables.
    if (index === 0) {
      setChallenge1(true);
      setChallenge2(false);
      setChallenge3(false);
    } else if (index === 1) {
      setChallenge1(false);
      setChallenge2(true);
      setChallenge3(false);
    } else if (index === 2) {
      setChallenge1(false);
      setChallenge2(false);
      setChallenge3(true);
    }
  };

  const options = {
    loop: true,
    margin: 10,
    nav: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };

  const [videoActive, setvideoActive] = useState(false);

  const handleVideoActive = () => {
    setvideoActive(true);
  };

  const handleVideoClose = () => {
    setvideoActive(false);
  };

  const testimonialsRef = useRef(null);

  const scrollToTestimonials = () => {
    if (testimonialsRef.current) {
      const testimonialsElement = testimonialsRef.current;
      const testimonialsOffset = testimonialsElement.offsetTop;
      const scrollToPosition =
        testimonialsOffset -
        6 * parseFloat(getComputedStyle(document.documentElement).fontSize);

      window.scrollTo({
        top: scrollToPosition,
        behavior: "smooth",
      });
    }
  };

  $(document).ready(function () {
    $(".chalenges_item").on("click", function () {
      console.log("gdfgdfg");
      $(".chalenges_item").removeClass("slctdItem");
      $(this).addClass("slctdItem");
    });
  });

  return (
    <div className="home">
      <Helmet>
        <title>Debt Collection</title>
        <meta
          name="description"
          content="Compliant and Effortless AI Voice Solutions for Streamlined Debt Collection."
        />
      </Helmet>
      <div className="home_wrapper">
        <div className="home_container">
          <header className="header_container">
            <Header scrollToTestimonials={scrollToTestimonials} />
          </header>

          <div className="home_content">
            <section className="welcome_screen col-xl-11 col-lg-10 col-md-10 m-auto my-5">
              {!videoActive ? (
                <div className="welcome_screen_container">
                  <div className="wlcm_heading_div_container">
                    <div className="wlcm_heading_div col-xl-8 col-lg-10 col-11">
                      <h1 className="wlcm_heading">Voice AI Solution</h1>
                      <div className="audio_container">
                        <img src={AudioSpectrum} alt="spectrum" />
                        <img
                          onClick={handleVideoActive}
                          className="audio_playBtn"
                          src={MicBtn}
                          alt="icon"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="video_container">
                    <h6>
                      Compliant and Effortless AI Voice Solutions for
                      Streamlined Debt Collection.
                    </h6>
                    <button
                      onClick={handleVideoActive}
                      className="video_playBtn"
                    >
                      <img src={PlayBtn} alt="icon" />
                    </button>
                  </div>
                </div>
              ) : (
                <div className="welcm_video container-lg">
                  <video src={WlcmVideo} type="video/mp4" controls />
                  <div className="closevideobtn" onClick={handleVideoClose}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="36"
                      height="36"
                      fill="currentColor"
                      className="bi bi-x"
                      viewBox="0 0 16 16"
                    >
                      <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                    </svg>
                  </div>
                </div>
              )}
            </section>

            <section className="challenges container-lg">
              <div className="challenges_container">
                <div className="row">
                  <div className="col-md-6">
                    <div className="left_challenge">
                      <h4>Challenges We Solve</h4>
                      <h6>
                        Let us all welcome the future of Debt Collection! We
                        have crafted an AI solution based on conversational
                        voice with precision tailored specifically to the unique
                        requirements of the Debt collection industry.
                      </h6>
                      <h6>
                        The revolutionary Olivia combines innovative technology
                        with deep industry knowledge for cost-effective and
                        faster debt recovery while reducing costs and boosting
                        revenue.
                      </h6>
                      <ul className="chalenges_list">
                        <li
                          className="chalenges_item slctdItem"
                          onClick={() => handleItemClick(0)}
                        >
                          • Account penetration
                        </li>
                        <li
                          className="chalenges_item"
                          onClick={() => handleItemClick(1)}
                        >
                          • Cost of collections
                        </li>
                        <li
                          className="chalenges_item"
                          onClick={() => handleItemClick(2)}
                        >
                          • Agent scarcity and attrition
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-1 mx-auto">
                    <div className="vertical_gradient mx-auto"></div>
                  </div>
                  <div className="col-md-5 my-auto">
                    <div className="challenges_image col-lg-10">
                      {challenge1 && (
                        <>
                          <h6>Account penetration</h6>
                          <div className="challenges_image_container">
                            <img src={Challenge1} alt="Account penetration" />
                          </div>
                        </>
                      )}
                      {challenge2 && (
                        <>
                          <h6>Cost of collections</h6>
                          <div className="challenges_image_container">
                            <img src={Challenge2} alt="Account penetration" />
                          </div>
                        </>
                      )}
                      {challenge3 && (
                        <>
                          <h6>Agent scarcity and attrition</h6>
                          <div className="challenges_image_container">
                            <img src={Challenge3} alt="Account penetration" />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="solution">
              <div className="solution_container container-lg">
                <div className="solution_text">
                  <h5 className="Box_section_points">
                    Faster Recovery from Generative AI
                  </h5>
                  <p>
                    We use innovative technology of Generative AI to speed up
                    the recovery of debts. Olivia works for you tirelessly which
                    aids in your debt recovery in a faster way.
                  </p>
                  <h5 className="Box_section_points">Compliance and Trust</h5>
                  <p>
                    Olivia can be considered your compliance companion. With Olivia
                    you can relax by knowing that it not only helps in the
                    maximisation of your collections but also complies with all
                    the rules regarding transparency and data security.
                  </p>
                  <h5 className="Box_section_points">Efficient Collections</h5>
                  <p>
                    Olivia effortlessly accelerates the efforts related to Debt
                    Collection. With Olivia as your facilitator, recovery rates
                    can soar while the manual work-burden lightens which
                    transforms the ordinary into a masterpiece of efficiency.
                  </p>
                </div>
                <div className="solutions_cards solutions_cards_resp">
                  <div className="cards">
                    <img src={Solution1} alt="icon" />
                    <p>
                      Configurable <br /> Collection Rules
                    </p>
                  </div>
                  <div className="cards d-none d-sm-block">
                    <img src={Solution2} alt="icon" />
                    <p>
                      Revolutionary <br /> Solution
                    </p>
                  </div>
                  <div className="cards">
                    <img src={Solution3} alt="icon" />
                    <p>
                      Secure <br /> Data Exchange
                    </p>
                  </div>
                  <div className="cards d-none d-lg-block">
                    <img src={Solution4} alt="icon" />
                    <p>
                      Compliance <br /> Filters
                    </p>
                  </div>
                  <div className="cards d-sm-none d-lg-block">
                    <img src={Solution5} alt="icon" />
                    <p>
                      On-Call <br /> Payment
                    </p>
                  </div>
                  <div className="cards d-none d-lg-block">
                    <img src={Solution6} alt="icon" />
                    <p>
                      Agent <br /> Transfer
                    </p>
                  </div>
                  <div className="cards d-none d-lg-block">
                    <img src={Solution7} alt="icon" />
                    <p>
                      Dashboard & <br /> Reports
                    </p>
                  </div>
                </div>
                <div className="solutions_cards solutions_cards_lg d-lg-none">
                  <div className="cards">
                    <img src={Solution4} alt="icon" />
                    <p>
                      Compliance <br /> Filters
                    </p>
                  </div>
                  <div className="cards d-none d-sm-block">
                    <img src={Solution5} alt="icon" />
                    <p>
                      On-Call <br /> Payment
                    </p>
                  </div>
                  <div className="cards">
                    <img src={Solution6} alt="icon" />
                    <p>
                      Agent <br /> Transfer
                    </p>
                  </div>
                  <div className="cards">
                    <img src={Solution7} alt="icon" />
                    <p>
                      Dashboard & <br /> Reports
                    </p>
                  </div>
                </div>
              </div>
              <div className="empty_div"></div>
            </section>

            <section className="aboutNafia container-lg">
              <div className="aboutNafia_container">
                <div className="aboutNafia_text">
                  <h4>An AI Avatar to amplify your engagement!</h4>
                  <p style={{ width: "90%" }}>
                    Elevate your journey of pre-recovery using the innovative
                    prowess of our debt collection model based on AI. Unleash
                    its transformative capabilities to streamline your
                    operations and usher in a new era of improved outcomes.
                  </p>
                  <ul>
                    <li>
                      <h6>Goal:</h6> Our model of AI debt collection is your
                      compass to a brighter future. It sharpens effectiveness,
                      supercharges your efficiency and ensures compliance in
                      debt collection. By refining negotiation tactics,
                      streamlining operations and delivering actionable
                      insights, it is responsible for propelling agencies
                      towards a rate of peak recovery while decreasing
                      operational costs.
                    </li>
                    <li>
                      <h6>Target Audience:</h6> Our debt collection model based
                      on AI has a dual audience where agencies of debt
                      collection, portfolio managers and professionals are in
                      the spotlight but the ripple effect doesn’t stop there.
                      Lenders, legal professionals, compliance officers as well
                      as financial institutions also catch the wave of the
                      impact of Olivia. In this process, do not forget the
                      indirect beneficiaries- the debtors themselves who enjoy a
                      more transparent, considerable experience of debt
                      collection. All thanks to our commitment to equitable
                      negotiation and respectful communication.
                    </li>
                    <li>
                      <h6>Context:</h6> Within the debt collection and financial
                      industry, the AI debt collection model can be considered a
                      game-changer. It effectively tackles headaches like
                      compliance hurdles, negotiation struggles and
                      inefficiency. Powered by innovative machine learning and
                      natural language processing, it delves into the data of
                      the debtor, automates mundane tasks and serves up insights
                      that are data-driven.
                    </li>
                  </ul>
                </div>

                <div className="talkGpt">
                  <h4>Talk to Olivia</h4>
                  <p>
                    An advanced debt collection system, employs dynamic name
                    insertion for personalized interactions and handles various
                    debt collection scenarios. It ensures regulatory compliance,
                    maintains a professional approach, and optimizes user
                    satisfaction and operational efficiency.
                  </p>
                  <div className="lets_talk_btn">
                    <Link to="/Olivia">
                      <button>Lets’ Talk with Olivia</button>
                    </Link>
                  </div>
                </div>
                <div className="nafia_22 col-xl-2 col-lg-3 col-md-3 col-sm-4 col-3">
                  <img src={Nafia2} alt="avatar" />
                  <div className="shadowBottom">
                    <img src={shadowBottom} alt="icon" />
                  </div>
                </div>
              </div>
            </section>

            <section id="testimonials" className="testimonials container-lg">
              <div className="testimonials_container">
                <div className="testimonial_text">
                  <h2 className="chall_class">Challenges We Mitigate</h2>
                  <div className="testimonial_text_row row m-0">
                    <div className="head_col col-sm-4">
                      <div className="testi_head">
                        <h5>Compliance:</h5>
                      </div>
                    </div>
                    <div className="col-sm-8 testiCOl_col">
                      <p className="testi_text">
                        In the world of debt collection, it is imperative for
                        agencies to master the intricate dimensions of state,
                        federal and local laws related to debt collection. By
                        doing so, they not only stay at par with the laws but
                        also uphold the highest standard of ethics in their
                        operations.
                      </p>
                    </div>
                  </div>
                  <div className="testimonial_text_row m-0">
                    <div className="col-sm-4 head_col">
                      <div className="testi_head">
                        <h5 style={{ whiteSpace: "nowrap" }}>Communication:</h5>
                      </div>
                    </div>
                    <div className="col-sm-8 testiCOl_col">
                      <div className="testi_text">
                        <p>
                          Fostering respectful and effective communication with
                          the debtors stands as a pivotal cornerstone in this
                          industry. Collectors must wield transparent language,
                          exude professionalism and shun abusive conduct in
                          their debt interactions.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="testimonial_text_row m-0">
                    <div className="col-sm-4 head_col">
                      <div className="testi_head">
                        <h5>Documentation:</h5>
                      </div>
                    </div>
                    <div className="col-sm-8 testiCOl_col">
                      <div className="testi_text">
                        <p>
                          In debt collection, efficient record-keeping is
                          pivotal. From written exchanges to phone calls and
                          payment pacts, each and every interaction is etched
                          into documentation. This helps in crafting an
                          accountable, transparent and well-marked journey
                          through the process of debt collection.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="testimonial_text_row m-0">
                    <div className="col-sm-4 head_col">
                      <div className="testi_head">
                        <h5 style={{ whiteSpace: "nowrap" }}>
                          Negotiation
                          <br className="d-none d-sm-block" /> and Resolution:
                        </h5>
                      </div>
                    </div>
                    <div className="col-sm-8 testiCOl_col">
                      <div className="testi_text">
                        <p>
                          Debt collectors need to master the art of negotiation
                          while crafting settlements and plans that are
                          responsible for striking a harmonious chord between
                          creditor and debtor. In this way, they seek solutions
                          that help in the empowerment of debtors to honour
                          their fiscal responsibilities by taking into account
                          their individual circumstances and financial
                          well-being.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div ref={testimonialsRef} className="testi_slider_container">
                  <OwlCarousel className="owl-theme" {...options}>
                    <div className="item">
                      <div className="testiItem col-11 mx-auto">
                        <h5>TESTIMONIALS</h5>
                        <div className="testiItemCont">
                          <img
                            className="textiImg"
                            src={Testiimg0}
                            alt="Testimonial"
                          />
                          <h5>JOE</h5>
                          <h6>E-Commerce Giant</h6>
                          <div className="testiContent col-lg-7 col-md-8 col-sm-10 col-11">
                            <div className="left_qoute">
                              <img
                                className="qouteImg"
                                src={LeftQoute}
                                alt="icon"
                              />
                            </div>
                            <p>
                              Your support and efforts have certainly
                              contributed to our success so far. We still have a
                              long way to go but with your constant endeavors
                              85% of TNPS & BPO scores do not look like a
                              distant dream.
                            </p>
                            <div className="right_qoute">
                              <img
                                className="qouteImg"
                                src={RightQoute}
                                alt="icon"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="item">
                      <div className="testiItem col-11 mx-auto">
                        <h5>TESTIMONIALS</h5>
                        <div className="testiItemCont">
                          <img
                            className="textiImg"
                            src={TestiImg}
                            alt="Testimonial"
                          />
                          <h5>VARSHA</h5>
                          <h6>E-Commerce Giant</h6>
                          <div className="testiContent col-lg-7 col-md-8 col-sm-10 col-11">
                            <div className="left_qoute">
                              <img
                                className="qouteImg"
                                src={LeftQoute}
                                alt="icon"
                              />
                            </div>
                            <p>
                              The significant rise in productivity from the
                              operations team has been massive which has helped
                              us achieve our targeted answering numbers during
                              the BIG BOLD sale period and ensure the quality of
                              calls are not compromised, we have been able to
                              move from 30% to 78% now and marching towards 85%.
                            </p>
                            <div className="right_qoute">
                              <img
                                className="qouteImg"
                                src={RightQoute}
                                alt="icon"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="item">
                      <div className="testiItem col-11 mx-auto">
                        <h5>TESTIMONIALS1</h5>
                        <div className="testiItemCont">
                          <img
                            className="textiImg"
                            src={TestiImg2}
                            alt="Testimonial"
                          />
                          <h5>AFSANA</h5>
                          <h6>Founder of KSG Enterpirse</h6>
                          <div className="testiContent col-lg-7 col-md-8 col-sm-10 col-11">
                            <div className="left_qoute">
                              <img
                                className="qouteImg"
                                src={LeftQoute}
                                alt="icon"
                              />
                            </div>
                            <p>
                              Your continued dedication and hard work have
                              played a crucial role in our ongoing success.
                              While we still have a miles to go, your consistent
                              efforts have brought us closer to our goal of
                              achieving 85% satisfaction in TNPS and BPO scores.
                            </p>
                            <div className="right_qoute">
                              <img
                                className="qouteImg"
                                src={RightQoute}
                                alt="icon"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </OwlCarousel>
                </div>
              </div>
            </section>

            <section className="blog">
              <div className="blog_section container-lg">
                <h4 className="col-md-8 col-11 mx-auto">
                  AI Innovations are Catalyzing a Communicational Revolution
                </h4>
                <div className="card_container">
                  <div className="row">
                    <div className=" col-md-4 col-sm-5 mx-auto p-1">
                      <Link to="/Driving Efficiency and Elevated Experiences">
                        <div className="card">
                          <div className="image">
                            <img src={blog1} alt="Card 1" />
                          </div>
                          <div className="blogtext_container">
                            <h6 style={{ textAlign: "left" }} className="my-2">
                              Driving Efficiency and Elevated Experiences:
                              Insights into Cost-Effective Service Improvement
                            </h6>
                            <div className="blogtext">
                              <p>
                                Efficiency in the process of customer service
                                means the fulfilment of customer service goals
                                with the minimum amount of effort from both
                                sides. Efficiency can be considered intelligent
                                laziness and doing the right things with as
                                little effort as possible. It is not about
                                saving resources and money or simply doing
                                things fast. Fewer efforts mean, for example,
                                the agents of customer service operate with
                                minimal resource wastage but the consumer
                                achieves their goals in as few steps as possible
                                as well.
                              </p>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div className="col-md-4 col-sm-5 mx-auto p-1">
                      <Link to="/The Perfect Blend">
                        <div className="card">
                          <div className="image">
                            <img src={blog2} alt="Card 1" />
                          </div>
                          <div className="blogtext_container">
                            <h6 style={{ textAlign: "left" }} className="my-2">
                              The Perfect Blend: Why a Mix of Virtual and Real
                              Is Essential for Exceptional CX
                            </h6>
                            <div className="blogtext">
                              <p>
                                Digital customer experience or DCX refers to how
                                consumers engage and interact with business
                                brands online. It includes online platforms
                                across desktop and mobile and digitally mediated
                                experiences through social media content and own
                                applications. It also extends to environments
                                connected digitally like IoT and devices that
                                are voice-activated. Ultimately, wherever your
                                consumers are engaging with your brand in the
                                digital landscape becomes a digital experience.
                              </p>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div className="col-md-4 col-sm-5 mx-auto p-1">
                      <Link to="/AI and Global Reach">
                        <div className="card">
                          <div className="image">
                            <img src={blog3} alt="Card 1" />
                          </div>
                          <div className="blogtext_container">
                            <h6 style={{ textAlign: "left" }} className="my-2">
                              AI and Global Reach: The Key to Scalable Solutions
                              by Technotask
                            </h6>
                            <div className="blogtext">
                              <p>
                                AI or Artificial Intelligence has become one of
                                the transformative forces in today’s era of
                                technological advancements. The influence of AI
                                extends across various industries by
                                revolutionising the way we live, interact and
                                work. From healthcare and education to finance
                                and entertainment, AI is reshaping traditional
                                practices and opening up exciting and emerging
                                possibilities.
                              </p>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>

                  <div className="row mt-3 d-none d-sm-flex">
                    <div className="col-md-4 col-sm-5 mx-auto p-1">
                      <Link to="/Unleashing the Potential of Gen AI">
                        <div className="card">
                          <div className="image">
                            <img src={blog4} alt="Card 1" />
                          </div>
                          <div className="blogtext_container">
                            <h6 style={{ textAlign: "left" }} className="my-2">
                              Unleashing the Potential of Gen AI: Elevating
                              Customer Experiences Through Omni-Channel
                              Engagement Introduction
                            </h6>
                            <div className="blogtext">
                              <p>
                                Omnichannel means that customers can discover
                                one’s brand through multiple channels like
                                social media, search engines, email newsletters
                                and more. The primary objective of omnichannel
                                isn’t just about ensuring customer access to
                                your products. It is also about making sure they
                                know the value of those products along with
                                their cost and their availability in the market.
                                This is exactly where AI comes into play.
                              </p>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div className="col-md-4 col-sm-5 mx-auto p-1">
                      <Link to="/Innovations in Debt Collection">
                        <div className="card">
                          <div className="image">
                            <img src={blog5} alt="Card 1" />
                          </div>
                          <div className="blogtext_container">
                            <h6 style={{ textAlign: "left" }} className="my-2">
                              Innovations in Debt Collection: Enhancing Customer
                              Experiences and Recovery Rates
                            </h6>
                            <div className="blogtext">
                              <p>
                                Debt collection plays a pivotal part in the
                                financial sector. It helps in the measurement of
                                cash flow for any organisation. Track and
                                assessment will help in the identification and
                                avoiding slow-paying customers. In addition to
                                that, debt collection helps in avoiding paying
                                late fees to vendors and preventing opening a
                                line of credit. Furthermore, it helps to ensure
                                that debts are paid in full and on time which
                                helps in the maintenance of financial stability
                                for the lender as well as the borrower along
                                with the promotion of responsible borrowing
                                through reminding individuals and businesses of
                                their financial obligations.
                              </p>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div className="col-md-4 col-sm-5 mx-auto p-1">
                      <Link to="/AI Revolution in Customer Experience 2023">
                        <div className="card">
                          <div className="image">
                            <img src={blog6} alt="Card 1" />
                          </div>
                          <div className="blogtext_container">
                            <h6 style={{ textAlign: "left" }} className="my-2">
                              AI Revolution in Customer Experience 2023
                            </h6>
                            <div className="blogtext">
                              <p>
                                In the contemporary era’s business landscape,
                                which is highly competitive in nature,
                                organisations need to provide a great customer
                                experience to succeed and stand out from their
                                competitors. Customer experience can be
                                considered the sum of all customer experiences
                                with a particular brand from its first point of
                                contact to post-purchase support. CX or customer
                                experience encompasses every touchpoint an
                                organisation has with an organisation including
                                sales, customer service, marketing and the usage
                                of product.
                              </p>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="seeAll">
                  <Link to="/resources">
                    <button className="seeAllBtn">See All...</button>
                  </Link>
                </div>
              </div>
            </section>

            <section className="newsletter container-lg">
              <div className="outer-div">
                <div className="inner-div col-lg-7 col-md-9">
                  <h4 className="newsltrHeading">
                    Subscribe To Our Newsletter
                  </h4>
                  <p className="newsltrtext">
                    Discover exclusive insights into our irresistible deals and
                    newest offerings. Join our newsletter and be in the know!
                  </p>

                  <NewsLetter />
                </div>
              </div>
            </section>

            <footer className="footer_area section_padding_130_0 px-lg-5 px-md-4 px-sm-3 px-1">
              <Footer />
            </footer>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
