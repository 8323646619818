import React, { useState, useEffect, useRef } from "react";
import NAfiaAdminSideBar from "./NAfiaAdminSideBar";
import "../../Dasboard/Css/Dashboard.css";
import "../AdminSIdebar.css";
import "../NAfiaAdmin.css";
import Calender from "../../assets/images/calendar12.svg";
import { Line, Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, registerables } from "chart.js";
import "react-circular-progressbar/dist/styles.css";
import SearchImg from "../../assets/images/search-normal.svg";
import { Helmet } from "react-helmet";
import { URL_FOR_NAFIA_ADMIN_DASH } from "../../KeyConnection/KeysService";
import ProfileImg from "../../assets/images/Male_User21.svg";
import $ from "jquery";
import moment from "moment";
import "moment/locale/en-au";
import "daterangepicker";
import CenterNavNafiaAuperAdmin from "./CenterNavNafiaAuperAdmin";
import { Modal } from "react-bootstrap";
import {
  handleAphabetsChange,
  handleEmailChange,
  handleIaphabetnumberChange,
  handleNumbersChange,
} from "../../CommonJquery/CommonJquery.js";
function NafiaAdminPerformance() {
  const [Nafiaadmindata, setdataFromNafia] = useState([]);
  ChartJS.register(...registerables);

  const showNafiaData = async (url_for) => {
    // Fetch data from the API endpoint

    await fetch(url_for)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.status === "error") {
          alert(data.data);
        } else {
          setdataFromNafia(data); // Update state with fetched data
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    // Call the function to fetch data when the component mounts
    showNafiaData(URL_FOR_NAFIA_ADMIN_DASH);
  }, []);

  const [graphData, setGraphData] = useState({
    labels: ["January", "February", "March", "April", "May", "June"],
    datasets: [
      {
        label: "Total Collection",
        data: [30, 38, 26, 34, 43, 28, 27],
        borderColor: "#006AFF", // Set line color to #006AFF
        backgroundColor: "#006AFF40",
        tension: 0.37,
        pointRadius: 2,
        fill: true,
      },
    ],
  });
  // Configuration options
  const options = {
    scales: {
      x: {
        title: {
          display: false,
        },
        grid: {
          display: false, // Hide the grid lines for the y-axis
        },
      },
      y: {
        title: {
          display: false,
        },
        grid: {
          display: false, // Hide the grid lines for the y-axis
        },
      },
    },
    plugins: {
      tooltip: {
        mode: "nearest",
      },
    },
  };

  const [datarecovry, setDataRecovery] = useState({
    labels: [
      "Imp. Ticket Raised",
      "Happy",
      "Unsatisfied",
      "Feedback not submit",
    ],
    datasets: [
      {
        data: [10, 45, 13, 32],
        backgroundColor: ["#FF007A", "#4623E9", "#0070C0", "#DCD9E8"],
      },
    ],
  });

  const optionsrecovry = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: "60%",
    plugins: {
      legend: {
        display: false, // Hide legend
      },
    },
    scales: {
      x: {
        display: false, // Hide x-axis
      },
      y: {
        display: false, // Hide y-axis
      },
    },
    elements: {
      arc: {
        backgroundColor: "transparent", // Set the background color to transparent
      },
    },
  };

  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  return (
    <div className="dashboard">
      <Helmet>
        <title>Nafia Dashboard</title>
        <meta
          name="description"
          content="AI and Global Reach: The Key to Scalable Solutions in Customer
          Experience"
        />
      </Helmet>
      <div className="dashboard_container">
        <div className="dashboard_wrapper">
          <NAfiaAdminSideBar />
          <div id="content">
            <CenterNavNafiaAuperAdmin />
            <div className="Dashpages_contaioner">
              <div className="overview">
                <div className="overviewContaINER">
                  <div className="px-0 my-4">
                    <div className="kbmngmtCardsDiv flex-row m-0">
                      <div className="kbmngmtCard mb-2">
                        <div className="card p-2 px-3 mx-4 mx-lg-0 mx-md-auto">
                          <p className="gradientTextStyle">
                            Server Performance
                          </p>
                          <h4>254</h4>
                        </div>
                      </div>
                      <div className="kbmngmtCard mb-2">
                        <div className="card p-2 px-3 mx-4 mx-lg-0 mx-md-auto">
                          <p className="gradientTextStyle">Basic Test</p>
                          <h5>Pass/Fail/Alert</h5>
                        </div>
                      </div>
                      <div className="kbmngmtCard mb-2">
                        <div className="card p-2 px-3 mx-4 mx-lg-0 mx-md-auto">
                          <p className="gradientTextStyle">Advance Test</p>
                          <h5>Pass/Fail/Alert</h5>
                        </div>
                      </div>
                      <div className="kbmngmtCard mb-2">
                        <div className="card p-2 px-3 mx-4 mx-lg-0 mx-md-auto">
                          <p className="gradientTextStyle">Ticket</p>
                          <h4>2534</h4>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="nafiaAdminGraphsRow">
                    <div className="row m-0">
                      <div className="col-md-8">
                        <div className="areaCHArtDiv mt-0">
                          <div className="chartHeading">
                            <div className="chartHeadingtext-left">
                              <h4>Growth & Revenue</h4>
                            </div>
                            <div className="chartHeadingtext-right">
                              {/* <div className="dateDropdownform">
                                <select>
                                  <option value=" ">Current Year</option>
                                  <option value="Last">Last Month</option>
                                  <option value="Month to Date">
                                    Month to Date
                                  </option>
                                  <option value="Last 3">Last 3 Months</option>
                                  <option value="Last 6">Last 6 Months</option>
                                  <option value="Year to Date">
                                    Year to Date
                                  </option>
                                  <option value="Lifetime">Lifetime</option>
                                </select>
                              </div> */}
                            </div>
                          </div>
                          <div className="p-3">
                            <Line data={graphData} options={options} />
                          </div>
                        </div>
                      </div>
                      <div
                        className="col-md-4"
                        style={{
                          backgroundColor: "#ffffff",
                          borderRadius: "15px",
                        }}
                      >
                        <div className="piechatrDiv">
                          <p
                            className="gradientTextStyle"
                            style={{
                              fontWeight: "600",
                              textAlign: "center",
                              marginBottom: "3rem",
                            }}
                          >
                            Performance Index
                          </p>
                          <div
                            style={{
                              width: "100%",
                              height: "250px",
                              position: "relative",
                            }}
                          >
                            <Doughnut
                              data={datarecovry}
                              options={optionsrecovry}
                              style={{ width: "100%" }}
                            />
                            <div
                              style={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                textAlign: "center",
                                fontSize: "16px",
                              }}
                            >
                              Nafia-2023
                            </div>
                            <div className="recoveryPercent">
                              <p>{datarecovry.datasets[0].data[1]}%</p>
                            </div>
                            <div className="disputePercent">
                              <p>{datarecovry.datasets[0].data[2]}%</p>
                            </div>
                            <div className="L2Percent">
                              <p>{datarecovry.datasets[0].data[0]}%</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="nafiaADminDataTable mt-4">
                    <div className="nafiaADminDataTableHead">
                      <h5 style={{ opacity: "0" }}>Call Session Records</h5>
                      <div className="adminoverviewSearch">
                        <img src={SearchImg} alt="icon" />
                        <input type="text" placeholder="Client Name/ID" />
                      </div>
                    </div>
                    <div className="nafiaTAbleCOntainer">
                      <table className="table nafiaTAble">
                        <thead>
                          <tr>
                            <th scope="col">ID</th>
                            <th scope="col">Top Client</th>
                            <th scope="col">Performance Index</th>
                            <th scope="col">server</th>
                            <th scope="col">Interaction Duration</th>
                            <th scope="col">Ticket</th>
                            <th scope="col">Subscription</th>
                            <th scope="col">History</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <div className="tableData1 tabledata1radius">
                                <p>#0521</p>
                              </div>
                            </td>
                            <td>
                              <div className="tableData1">
                                <p>Nicholas Financial Services</p>
                              </div>
                            </td>
                            <td>
                              <div className="tableData1">
                                <p>Bhopal</p>
                              </div>
                            </td>
                            <td>
                              <div className="tableData2">
                                <p>05</p>
                              </div>
                            </td>
                            <td>
                              <div className="tableData1">
                                <p>2345 Hrs.</p>
                              </div>
                            </td>
                            <td>
                              <div className="tableData1">
                                <p>
                                  #025125 <br />
                                  10 Jan, 2023
                                </p>
                              </div>
                            </td>
                            <td>
                              <div className="tableData3">
                                <p>+Gold</p>
                              </div>
                            </td>
                            <td>
                              <div className="tableData1 tableData4">
                                <p style={{ color: "#F71D1D" }}>See Now</p>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className="tableData1 tabledata1radius">
                                <p>#0521</p>
                              </div>
                            </td>
                            <td>
                              <div className="tableData1">
                                <p>Nicholas Financial Services</p>
                              </div>
                            </td>
                            <td>
                              <div className="tableData1">
                                <p>Bhopal</p>
                              </div>
                            </td>
                            <td>
                              <div className="tableData2">
                                <p>05</p>
                              </div>
                            </td>
                            <td>
                              <div className="tableData1">
                                <p>2345 Hrs.</p>
                              </div>
                            </td>
                            <td>
                              <div className="tableData1">
                                <p>
                                  #025125 <br />
                                  10 Jan, 2023
                                </p>
                              </div>
                            </td>
                            <td>
                              <div className="tableData3">
                                <p>+Gold</p>
                              </div>
                            </td>
                            <td>
                              <div className="tableData1 tableData4">
                                <p style={{ color: "#F71D1D" }}>See Now</p>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className="tableData1 tabledata1radius">
                                <p>#0521</p>
                              </div>
                            </td>
                            <td>
                              <div className="tableData1">
                                <p>Nicholas Financial Services</p>
                              </div>
                            </td>
                            <td>
                              <div className="tableData1">
                                <p>Bhopal</p>
                              </div>
                            </td>
                            <td>
                              <div className="tableData2">
                                <p>05</p>
                              </div>
                            </td>
                            <td>
                              <div className="tableData1">
                                <p>2345 Hrs.</p>
                              </div>
                            </td>
                            <td>
                              <div className="tableData1">
                                <p>
                                  #025125 <br />
                                  10 Jan, 2023
                                </p>
                              </div>
                            </td>
                            <td>
                              <div className="tableData3">
                                <p>+Gold</p>
                              </div>
                            </td>
                            <td>
                              <div className="tableData1 tableData4">
                                <p style={{ color: "#F71D1D" }}>See Now</p>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div className="reportPieChart">
                    <div className="reportPieChartcontainer"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        dialogClassName="custom-modal3"
        centered
        size="xl"
      >
        <Modal.Header>
          <h5 className="mt-3">Add Customer</h5>
        </Modal.Header>
        <Modal.Body>
          <div className="mngUserContainer px-5">
            <form className="needs-validation" id="CarrerformData">
              <div className="row m-0">
                <div className="col-md-6 hidden">
                  <div className="mngUserInput">
                    <label>Admin Id</label>
                    <br />
                    <input
                      type="text"
                      className="form-control  trio_mendate"
                      name="main_id"
                      id="main_id"
                      maxLength={150}
                      placeholder="Enter Admin Id"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mngUserInput">
                    <label>Full Name</label>
                    <br />
                    <input
                      type="text"
                      className="form-control  trio_mendate"
                      name="full_name"
                      id="full_name"
                      maxLength={150}
                      onInput={handleAphabetsChange}
                      placeholder="Enter Full Name"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mngUserInput">
                    <label>User Name</label>
                    <br />
                    <input
                      type="text"
                      className="form-control  trio_mendate trio_email"
                      name="user_name"
                      id="user_name"
                      maxLength={150}
                      onInput={handleEmailChange}
                      placeholder="Enter User Name"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mngUserInput">
                    <label>Mobile No.</label>
                    <br />
                    <input
                      type="text"
                      className="form-control  trio_mendate trio_no"
                      name="user_mobile_no"
                      id="user_mobile_no"
                      maxLength={12}
                      onInput={handleNumbersChange}
                      placeholder="Enter Mobile No"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mngUserInput">
                    <label>Password</label>
                    <br />
                    <input
                      type="password"
                      className="form-control  trio_mendate trio_password"
                      name="user_password"
                      id="user_password"
                      onInput={handleIaphabetnumberChange}
                      maxLength={15}
                      placeholder="Enter Password"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mngUserInput">
                    <label>Confirm Password</label>
                    <br />
                    <input
                      type="password"
                      className="form-control  trio_mendate trio_password"
                      name="confirm_password"
                      id="confirm_password"
                      onInput={handleIaphabetnumberChange}
                      maxLength={15}
                      placeholder="Enter Confirm Password"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mngUserInput">
                    <label>Control</label>
                    <br />

                    <select
                      className="form-control trio_mendate controlSlct "
                      name="control_access"
                      id="control_access"
                    >
                      <option>Agent 1</option>
                      <option>Agent 2</option>
                      <option>Agent 3</option>
                    </select>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="updateCnclebtnDiv">
            <button className="updateCnclebtnCan" onClick={handleCloseModal}>
              Cancel
            </button>
            <button
              className="updateCnclebtnUp"
              type="button"
              onClick={handleCloseModal}
            >
              Add
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default NafiaAdminPerformance;
