import React, { useEffect, useState } from "react";
import "../Css/Home2.css";
import "../Css/loading.css";
import LogoMain from "../assets/images/logoNew.png";
import NafiaBtn from "../assets/video/newNafia.mp4";
// import NafiyaScreen from "./NafiyaScreen";
import Whats1 from "../assets/images/whats2.svg";
import Insta1 from "../assets/images/insta2.svg";
import Linked1 from "../assets/images/link2.svg";
import Email1 from "../assets/images/mail2.svg";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import "../CommonJquery/FunctionJquery.js";
import $ from "jquery";
function Header({ scrollToTestimonials }) {
  function sendMessageToWhatsApp() {
    const phoneNumber = "+919433032220"; // Replace with the recipient's phone number
    const message = "Hello, How may i help You!"; // Replace with your desired message

    // Create a WhatsApp URL with the phone number and message
    const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      message
    )}`;

    // Open the WhatsApp chat in a new tab or window
    window.open(whatsappURL, "_blank");
  }

  function sendEmail() {
    const recipientEmail = "info@technotask.co.in"; // Replace with the recipient's email address
    const subject = "Hello, Hello, How may i help You"; // Replace with your desired subject
    const body = "Your Name"; // Replace with your desired email body

    // Create a mailto: link with the recipient, subject, and body
    const mailtoLink = `mailto:${recipientEmail}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;

    // Open the default email client with the pre-filled email
    window.location.href = mailtoLink;
  }

  const location = useLocation();

  useEffect(() => {
    const navLinks = document.querySelectorAll(".nav-link");

    navLinks.forEach((link) => {
      link.classList.remove("active-linknav");
      if (link.getAttribute("to") === location.pathname) {
        link.classList.add("active-linknav");
      }
    });
  }, [location]);

  

  const [isButtonVisible, setIsButtonVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const footer = document.querySelector("footer");
      const observer = new IntersectionObserver((entries) => {
        setIsButtonVisible(!entries[0].isIntersecting);
      });

      if (footer) {
        observer.observe(footer);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <nav className="navbar navbar-expand-lg">
        <Link className="navbar-brand" to="/#">
          <img src={LogoMain} alt="icon" />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
          <div className="navbar-nav navbar-nav1">
            <li
              className={`nav-item nav-link ${
                location.pathname === "/" ? "navIemTextSelected" : ""
              }`}
            >
              <Link className="asd" to="/">
                Home
              </Link>
            </li>
            <li
              className={`nav-item dropdown ${
                location.pathname === "/Nafia_product" ||
                location.pathname === "/AmraHome"
                  ? "navIemTextSelected"
                  : ""
              }`}
            >
              <Link
                className="nav-link asd dropdown-toggle dropdownLinkNAv"
                href="#"
                id="navbarDropdownMenuLink"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Product
              </Link>
              <div
                className="dropdown-menu"
                aria-labelledby="navbarDropdownMenuLink"
              >
                <Link className="dropdown-item" to="/cx-software">
                  Call Center
                </Link>
                <Link className="dropdown-item" to="/debt-collection">
                  Debt Collection
                </Link>
                <Link className="dropdown-item" to="/appointment-management">
                Appointment
                </Link>
                <Link className="dropdown-item" to="https://chatnext.co/sign_in">
                  AI Chatbot
                </Link>
              </div>
            </li>

            <li
              className={`nav-item nav-link ${
                location.pathname === "/company" ? "navIemTextSelected" : ""
              }`}
            >
              <Link className="asd" to="/company">
                Company
              </Link>
            </li>
            <li
              className={`nav-item nav-link ${
                location.pathname === "/resources" ? "navIemTextSelected" : ""
              }`}
            >
              <Link className="asd" to="/resources">
                Resources
              </Link>
            </li>
            <li
              className="nav-link navIem nav-item textBtnHeader"
              onClick={scrollToTestimonials}
            >
              <Link className="asd">Testimonials</Link>
            </li>
          </div>
          <div className="navbar-nav navbar-nav2">
            <Link className="nav-item nav-link" to="/book_demo">
              <button className="bookBtn">
                Book Demo Now
                <span className="spanArrow">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    className="bi bi-arrow-right-short"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
                    />
                  </svg>
                </span>
              </button>
            </Link>
          </div>
        </div>
      </nav>
      {isButtonVisible && (
        <div className="nafia_btn_div">
          <Link to="/Nafia">
            <video autoPlay loop alt="btn">
              <source src={NafiaBtn} type="video/mp4" />
            </video>
          </Link>
        </div>
      )}
      <div className="sideIcons d-none d-lg-block">
        <ul>
          <li className="sideIcons1" onClick={sendMessageToWhatsApp}>
            <img src={Whats1} alt="icon" />
          </li>
          <li className="sideIcons2">
            <a href="https://instagram.com/technotaskglobal?igshid=MzRlODBiNWFlZA==">
              <img src={Insta1} alt="icon" />
            </a>
          </li>
          <li className="sideIcons3">
            <a href="https://www.linkedin.com/company/technotaskbusinesssolutions/">
              <img src={Linked1} alt="icon" />
            </a>
          </li>
          <li className="sideIcons4" onClick={sendEmail}>
            <img src={Email1} alt="icon" />
          </li>
        </ul>
      </div>
    </>
  );
}

export default Header;
