import React from "react";
import "../Css/QRPage.css";
import LogoCom from "../../assets/images/logoNew.png";
import QRCODE from "../../assets/images/qr-code1.png";

function QRPage() {
  return (
    <div className="qrpage">
      <div className="qr_contaianer">
        <div className="qr_wrapper col-xl-4 col-lg-11 m-auto">
          <div className="qrsrceen">
            <div className="qrHead col-md-9">
              <h5>Debt Collection Software with Dynamic Name Integration</h5>
            </div>
            <div className="comapnyLogo">
              <img src={LogoCom}  alt="technotask"/>
              <p>The Perfect Blend of People & Technology</p>
            </div>
            <div className="scanQR col-6 mx-auto">
              <h4>Scan Now</h4>
              <div className="QRCOde">
                <img src={QRCODE}  alt="technotask"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QRPage;
