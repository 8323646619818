import React, { useState, useEffect } from "react";
import "../Css/Dashboard.css";
import "../Css/Bucket30days.css";
import "../Css/SettingPage.css";
import "../Css/HelpSupport.css";
import SideBar from "./SideBar";
import CircleCheck from "../../assets/images/circle_check.svg";
import NotificationsImg from "../../assets/images/notification.svg";
import SideBarControl from "../../assets/images/rightLeftSIdebar.png";
import UserImgPrf from "../../assets/images/userimg45.svg";
import adm_img from "../../assets/images/adm_img.png";
import SearchIcon from "../../assets/images/search-normal.svg";
import Edit from "../../assets/images/message-edit.svg";
import SMS from "../../assets/images/sms.svg";
import SMSnot from "../../assets/images/allticketNot.svg";
import NewSMS from "../../assets/images/sms-notification.svg";
import NewSMSSelected from "../../assets/images/newselected.svg";
import OnGoingSMS from "../../assets/images/sms-tracking.svg";
import OnGoingSMSSelected from "../../assets/images/onselected.svg";
import ResolvedSMS from "../../assets/images/sms-star.svg";
import ResolvedSMSselected from "../../assets/images/resolvedSelected.svg";
import People from "../../assets/images/People.svg";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import Technotask from "../../assets/images/TechnotaskLogo12.png";
import Logout from "../../assets/images/giflogout.gif";
import CenterNavbar from "./CenterNavbar";
import { Helmet } from "react-helmet";

import {
  server_get_data_master,
  server_patch_data_master,
  URL_FOR_TICKETS,
  URL_FOR_SINGLE_TICKET,
  URL_FOR_TICKET_CONVERSATION,
} from "../../KeyConnection/KeysService"; // Adjust the path as needed

function DebtorProfile60() {
  const company_id = localStorage.getItem("company_id");
  const [isLoading, setLoading] = useState(false);
  const [filterValue, setfilterValue] = useState("");
  const [searchTicket, setSearchTicket] = useState("");
  const [userNumber, setuserNumber] = useState("");
  const [overviewShow, setOverviewShow] = useState(true); // Default to 'true'
  const [debtorProfile, setdebtorProfile] = useState(false);
  const [selectedpriority, setSelectedpriority] = useState(null);
  const priority = [
    { name: "New Ticket", code: "New", color: "#3B8AFF" },
    { name: "On-Going", code: "On", color: "#FAC885" },
    { name: "Resolved", code: "Resolved", color: "#54C104" },
  ];
  ///////////////////////////////////////
  const [ticketList, setTicketList] = useState([]);

  useEffect(() => {
    getAllTickets(URL_FOR_TICKETS);
  }, []);

  const getAllTickets = async (url_for) => {
    // const fd = new FormData();
    setLoading(true);
    try {
      const response = await server_get_data_master(url_for);
      const data = await response.json();
      // setshowLoaderWebsite(false);
      if (data.status === "error") {
        alert("Error fetching data");
      } else {
        let ticketsArr = [];
        for (let i = 0; i < data.data.length; i++) {
          let ticket = data.data[i];
          if (
            ticket.ticket_for_flag === "1" &&
            ticket.company_id === company_id
          ) {
            // convert time into required format
            const timestampString = ticket.created_at;
            const date = new Date(timestampString);
            // Date formatting options
            const dateFormatOptions = {
              year: "numeric",
              month: "long",
              day: "numeric",
            };

            // Time formatting options
            const timeFormatOptions = {
              hour: "numeric",
              minute: "numeric",
              hour12: true,
              timeZone: "IST", // Adjust this based on your actual timezone
            };

            // Format date and time separately
            const formattedDate = date.toLocaleDateString(
              "en-US",
              dateFormatOptions
            );

            const formattedTime = new Intl.DateTimeFormat(
              "en-US",
              timeFormatOptions
            ).format(date);

            let { replyVal, category } = await getTicketConversation(
              URL_FOR_TICKET_CONVERSATION + ticket.uuid_id
            );

            if (category === "1" && ticket.ticket_category === "2") {
              category = "2";
            }

            // setting category
            if (category === "0") {
              category = "NewTicket";
            } else if (category === "1") {
              category = "onGoingTicket";
            } else if (category === "2") {
              category = "ResolvedTicket";
            }

            // creating formated ticket object
            const obj = {
              status: category,
              ticketNo: ticket.uuid_id,
              ticketDate: formattedDate,
              ticketTime: formattedTime,
              ticketFrom: ticket.email,
              ticketImg: People,
              ticketQues: ticket.ticket_body,
              ticketRply: replyVal,
            };
            ticketsArr.push(obj);
          }
        }
        console.log(ticketsArr);
        setTicketList(ticketsArr);
      }
    } catch (error) {
      console.error(error);
      // setshowLoaderWebsite(false);
    }
    setLoading(false);
  };

  const getTicketConversation = async (url_for) => {
    setLoading(true);
    try {
      const response = await server_get_data_master(url_for);
      const data = await response.json();
      if (data.status === "error") {
        alert("Error fetching data");
      } else {
        if (data.data.length === 1) {
          return {
            replyVal: "",
            category: "0",
          };
        }
        return {
          replyVal: data.data[data.data.length - 1]?.ticket_body,
          category: "1",
        };
      }
    } catch (error) {
      console.error(error);
      // setshowLoaderWebsite(false);
    }
    setLoading(false);
  };
  //////////////////////////

  const customItemTemplate = (option) => {
    return (
      <div className="optionDIvPrio">
        <div
          className="optionBgDiv"
          style={{ backgroundColor: `${option.color}` }}
        ></div>
        {option.name}
      </div>
    );
  };

  const [selectedTicketNumber, setSelectedTicketNumber] = useState("");

  const show_confirmation_modal = (ticket_no) => {
    setShowModal(true);
    setSelectedTicketNumber(ticket_no);
  };

  const resolveTicket = async () => {
    setLoading(true);
    try {
      const response = await server_patch_data_master(
        URL_FOR_SINGLE_TICKET + selectedTicketNumber,
        { ticket_category: 2 }
      );
      const data = await response.json();
      if (data.status === "error") {
        alert("Error fetching data");
      } else {
        console.log(data);
        const updatedArray = ticketList.map((ticket) => {
          if (ticket.ticketNo === data.uuid_id) {
            return { ...ticket, status: "ResolvedTicket" };
          }
          return ticket;
        });

        setTicketList(updatedArray);
      }
    } catch (error) {
      console.error(error);
      // setshowLoaderWebsite(false);
    }
    closeModal();
    setLoading(false);
  };

  const [selectedTab, setSelectedTab] = useState("allTickets");
  const [selectedPriority, setSelectedPriority] = useState(null);

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };

  const handlePriorityChange = (selectedPriority) => {
    setSelectedPriority(selectedPriority ? selectedPriority.code : null);

    // Update selectedTab based on selectedPriority
    if (selectedPriority) {
      const tabForPriority = mapPriorityToTab(selectedPriority.code);
      setSelectedTab(tabForPriority);
    }
  };

  const mapPriorityToTab = (priorityCode) => {
    // You may need to customize this mapping based on your specific logic
    if (priorityCode === "New") {
      return "new";
    } else if (priorityCode === "On") {
      return "onGoing";
    } else if (priorityCode === "Resolved") {
      return "resolved";
    }
    // Default to 'allTickets' if no match
    return "allTickets";
  };

  const filteredTickets = () => {
    let filteredList = ticketList;

    // Filter further based on the selected tab
    if (selectedTab === "new") {
      filteredList = filteredList.filter(
        (ticket) => ticket.status === "NewTicket"
      );
    } else if (selectedTab === "onGoing") {
      filteredList = filteredList.filter(
        (ticket) => ticket.status === "onGoingTicket"
      );
    } else if (selectedTab === "resolved") {
      filteredList = filteredList.filter(
        (ticket) => ticket.status === "ResolvedTicket"
      );
    }

    filteredList = filteredList.filter((ticket) => {
      let ticket_date = new Date(ticket.ticketDate);
      // Check date range
      const isDateInRange =
        selectedtime.startDate <= ticket_date &&
        selectedtime.endDate >= ticket_date;

      // Check if the sender contains searchTicket (case insensitive)
      const isSenderMatch =
        searchTicket === "" ||
        ticket.ticketFrom.toLowerCase().includes(searchTicket.toLowerCase()) ||
        ticket.ticketNo.toLowerCase().includes(searchTicket.toLowerCase());

      // Return true if both conditions are met
      return isDateInRange && isSenderMatch;
    });
    return filteredList;
  };

  const [selectedtime, setSelectedselectedtime] = useState({
    value: "All",
    startDate: new Date("2023-01-01"),
    endDate: new Date(),
  });

  const timeList = [
    { name: "All", code: 0 },
    { name: "This Week", code: 1 },
    { name: "This Month", code: 2 },
    { name: "Last Month", code: 3 },
    { name: "Last 3 Months", code: 4 },
  ];

  const handleDurationFilterChange = (timePeriod) => {
    const today = new Date();
    const thisWeekStart = new Date(today);
    thisWeekStart.setDate(today.getDate() - today.getDay()); // Start of the week
    const thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
    const lastMonthStart = new Date(
      today.getFullYear(),
      today.getMonth() - 1,
      1
    );
    const lastMonthEnd = new Date(today.getFullYear(), today.getMonth(), 1);
    lastMonthEnd.setDate(thisMonthStart.getDate() - 1);
    const last3MonthsStart = new Date(
      today.getFullYear(),
      today.getMonth() - 3,
      1
    );

    const filters = [
      { value: "All", startDate: new Date("2023-01-01"), endDate: today },
      { value: "This Week", startDate: thisWeekStart, endDate: today },
      { value: "This Month", startDate: thisMonthStart, endDate: today },
      {
        value: "Last Month",
        startDate: lastMonthStart,
        endDate: lastMonthEnd,
      },
      { value: "Last 3 Months", startDate: last3MonthsStart, endDate: today },
    ];

    setSelectedselectedtime(filters[timePeriod.code]);
  };

  const [showModal, setShowModal] = useState(false);
  const closeModal = () => {
    setShowModal(false);
  };

  const searchTicketFromDB = async (input) => {
    setSearchTicket(input);
    filteredTickets();
  };

  return (
    <div className="dashboard">
      <Helmet>
        <title>Amra User Dashboard</title>
        <meta
          name="description"
          content="AI and Global Reach: The Key to Scalable Solutions in Customer
          Experience"
        />
      </Helmet>
      <div className="dashboard_container">
        <div className={isLoading ? "loader-overlay show" : "loader-overlay"}>
          <div className="loader23"></div>
        </div>
        <div className="dashboard_wrapper">
          <SideBar
            overviewShow={overviewShow}
            setOverviewShow={setOverviewShow}
            debtorProfile={debtorProfile}
            setdebtorProfile={setdebtorProfile}
          />
          <div id="content">
            <CenterNavbar />
            <div className="Suppotr_container">
              <div className="Suppotr_wrapper">
                <div className="Suppotr_Header">
                  <div className="searchInputHelp">
                    <img src={SearchIcon} alt="icon" />
                    <input
                      type="text"
                      placeholder="Search for ticket"
                      onChange={(e) => {
                        searchTicketFromDB(e.target.value);
                      }}
                      onInput={(e) => {
                        if (!/^[a-zA-Z0-9@.]*$/.test(e.target.value)) {
                          e.target.value = ""; // Clear the input if it contains any other character
                        }
                      }}
                    />
                  </div>

                  <div className="Suppotr_HeaderRight">
                    <div className="Suppotr_HeaderRightCont">
                      <div className="guideBtn">
                        <p>Self Help Guide Resources </p>
                      </div>
                      <div className="Suppotr_HeaderBtns">
                        <Dropdown
                          value={priority.find(
                            (p) => p.code === selectedPriority
                          )}
                          onChange={(e) => handlePriorityChange(e.value)}
                          options={priority}
                          optionLabel="name"
                          placeholder="Select Ticket Type"
                          className="priorityBtn"
                          itemTemplate={customItemTemplate}
                        />
                        <Dropdown
                          value={timeList.find(
                            (p) => p.name === selectedtime.value
                          )}
                          onChange={(e) => handleDurationFilterChange(e.value)}
                          options={timeList}
                          optionLabel="name"
                          placeholder="Select Duration"
                          className="priorityBtn"
                        />
                        <Link to="Create_new_ticket">
                          <button className="newTicketBtn">
                            <span>
                              <img src={Edit} alt="icon" />
                            </span>
                            <p>New Ticket</p>
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="ticketsTab">
                  <div className="ticketsTab_container">
                    <div
                      className={`ticketsTab_btn ${
                        selectedTab === "allTickets" && "selectedTicketTab"
                      }`}
                      onClick={() => handleTabClick("allTickets")}
                    >
                      {selectedTab !== "allTickets" && (
                        <img src={SMSnot} alt="icon" />
                      )}
                      {selectedTab === "allTickets" && (
                        <img src={SMS} alt="icon" />
                      )}
                      <p>All Tickets</p>
                    </div>
                    <div
                      className={`ticketsTab_btn ${
                        selectedTab === "new" && "selectedTicketTab"
                      }`}
                      onClick={() => handleTabClick("new")}
                    >
                      {selectedTab !== "new" && <img src={NewSMS} alt="icon" />}
                      {selectedTab === "new" && (
                        <img src={NewSMSSelected} alt="icon" />
                      )}
                      <p>New</p>
                    </div>
                    <div
                      className={`ticketsTab_btn ${
                        selectedTab === "onGoing" && "selectedTicketTab"
                      }`}
                      onClick={() => handleTabClick("onGoing")}
                    >
                      {selectedTab !== "onGoing" && (
                        <img src={OnGoingSMS} alt="icon" />
                      )}
                      {selectedTab === "onGoing" && (
                        <img src={OnGoingSMSSelected} alt="icon" />
                      )}
                      <p>On-Going</p>
                    </div>
                    <div
                      className={`ticketsTab_btn ${
                        selectedTab === "resolved" && "selectedTicketTab"
                      }`}
                      onClick={() => handleTabClick("resolved")}
                    >
                      {selectedTab !== "resolved" && (
                        <img src={ResolvedSMS} alt="icon" />
                      )}
                      {selectedTab === "resolved" && (
                        <img src={ResolvedSMSselected} alt="icon" />
                      )}
                      <p>Resolved</p>
                    </div>
                  </div>
                </div>

                <div className="ticketsCOntainer">
                  <div className="ticketsWrapper">
                    <div className="ticketslist">
                      {filteredTickets().map((ticket, index) => (
                        <div className="ticketsListItem" key={index}>
                          <div className="ticketsListItemHead">
                            <div className="ticketsListItemHeadLeft">
                              <div className={ticket.status}></div>
                              <p>Ticket# {ticket.ticketNo}</p>
                              {ticket.priority_status === "High" && (
                                <div className="priorityStatusHigh">
                                  <p className="highColor">High Priority</p>
                                </div>
                              )}
                              {ticket.priority_status === "Medium" && (
                                <div className="priorityStatusMedium">
                                  <p className="mediumColor">Medium Priority</p>
                                </div>
                              )}
                              {ticket.priority_status === "Low" && (
                                <div className="priorityStatusLow">
                                  <p className="lowColor">Low Priority</p>
                                </div>
                              )}
                            </div>
                            <div className="ticketsListItemHeadRight">
                              <div className="ticketTime">
                                <p>
                                  Posted on {ticket.ticketDate} at{" "}
                                  {ticket.ticketTime}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="ticketDetails">
                            <div className="ticketDetailsContainer">
                              <h6>{ticket.ticketQues}</h6>
                              <p>{ticket.ticketRply}</p>
                            </div>
                          </div>
                          <div className="ticketsListItemFOOTER">
                            <div className="ticketsListItemfootLeft">
                              <div className="cutomerImg">
                                <img src={ticket.ticketImg} alt="icon" />
                              </div>
                              <p>{ticket.ticketFrom}</p>
                            </div>
                            <div className="ticketsListItemfootRight flexBtns">
                              <div>
                                {ticket.status !== "ResolvedTicket" && (
                                  <div
                                    className="showTicketBtn"
                                    onClick={() =>
                                      show_confirmation_modal(ticket.ticketNo)
                                    }
                                  >
                                    <p>Resolve Ticket</p>
                                  </div>
                                )}
                              </div>
                              <div>
                                <Link
                                  to={`Reply_to_ticket/${ticket.ticketNo}/${ticket.status}`}
                                >
                                  <div className="showTicketBtn">
                                    <p>Open Ticket</p>
                                  </div>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="brandingContainer">
              <div>
                <h5>Powered by</h5>
                <img src={Technotask} alt="Logo" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        id="myModal"
        show={showModal}
        onHide={closeModal}
        centered
        backdrop="static"
      >
        <Modal.Body className="modal_body">
          <div className="success_img d-flex justify-content-center"></div>
          <p>Are you sure you want to resolve the ticket?</p>
        </Modal.Body>
        <Modal.Footer>
          <div className="btn go_to_login" onClick={resolveTicket}>
            Resolve
          </div>
          <div className="btn go_to_login" onClick={closeModal}>
            Cancel
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default DebtorProfile60;
