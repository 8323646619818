import React, { useState, useEffect } from "react";
import LogoCom from "../../assets/images/logoNew.png";
import Watch from "../../assets/images/Watch.png";
import { empty_form, check_vaild_save } from "../../CommonJquery/CommonJquery";
import {
  URL_FOR_FAQ_SAVE,
  server_post_data,
} from "../../KeyConnection/KeysService"; // Adjust the path as needed
import "../../Css/loading.css";
function FAQNAfia() {
  const [userForm, setuserForm] = useState(false);
  const [time, setTime] = useState(30);
  const [tokenno, settokenno] = useState("0");
  const [showLoaderWebsite, setshowLoaderWebsite] = useState(false);
  const [CompanyName, setCompanyName] = useState("");
  const [CompanyDetail, setCompanyDetail] = useState("");
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (time > 0) {
        setTime(time - 1);
      }
    }, 1000);

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [time]);

  const handleSaveUserData = async (form_data, url_for_save) => {
    let vaild_data = check_vaild_save(form_data);

    if (vaild_data) {
      setshowLoaderWebsite(true);
      const body_data = JSON.stringify({
        company_name: CompanyName,
        company_detail: CompanyDetail,
      });
      await server_post_data(url_for_save, body_data)
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          if (data.status === "error") {
            alert(data.data);
          } else {
            settokenno(data.data);
            empty_form(form_data);
            setuserForm(true);
          }
          setshowLoaderWebsite(false);
        })
        .catch((error) => {
          console.log(error);
          setshowLoaderWebsite(false);
        });
    }
  };

  return (
    <div className="qrpage">
      <div className="qr_contaianer">
        <div className={showLoaderWebsite ? "loading_website" : ""}></div>
        <div className="qr_wrapper col-xl-6 col-lg-6 m-auto">
          {!userForm && (
            <div className="qrsrceen">
              <div className="comapnyLogo1 col-xl-6 col-md-6 col-8 mx-auto">
                <img src={LogoCom} alt="technotask" />
              </div>
              <div className="fillInfoHead fillInfoHead2 col-md-8 col-sm-10">
                <h6>
                  Please fill the Company name and details about you want to ask
                  to Nafia.
                </h6>
              </div>
              <div className="QRFormDiv QRFaqDiv my-4 p-0">
                <form id="QRFaqFormData">
                  <div className="QRInputS">
                    <div className="QRInput QRInputFAQ">
                      <label htmlFor="company_name">Company</label>
                      <input
                        type="text"
                        id="company_name"
                        name="company_name"
                        placeholder="Company Name"
                        value={CompanyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                        className="trio_mendate"
                      />
                    </div>
                    <div className="QRInput QRInputFAQ">
                      <label htmlFor="company_details">Details</label>
                      <textarea
                        id="company_details"
                        name="company_details"
                        className="trio_mendate"
                        placeholder="Enter Details"
                        value={CompanyDetail}
                        onChange={(e) => setCompanyDetail(e.target.value)}
                        rows="4"
                      ></textarea>
                    </div>
                  </div>
                </form>

                <div className="SaveBtn">
                  <button
                    type="button"
                    onClick={() =>
                      handleSaveUserData("QRFaqFormData", URL_FOR_FAQ_SAVE)
                    }
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          )}
          {userForm && (
            <div className="qrsrceen">
              <div className="comapnyLogo">
                <img src={LogoCom} alt="technotask" />
              </div>
              <div className="scanQR scanQR2 col-sm-11 mx-auto">
                <h4>
                  Thank you for Showing Interest in me I am calling you in Next
                </h4>
                <div className="QRCOde col-lg-3 col-5 m-auto">
                  <img src={Watch} alt="technotask" />
                </div>
                <div className="timer30s">
                  <p>{time} seconds</p>
                </div>
              </div>
              <div className="tokenNumber">
                <h6>Your Token Number is :</h6>
                <h4>{tokenno}</h4>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default FAQNAfia;
