import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import NAfiaAdminSideBar from "./NAfiaAdminSideBar.js";
import CenterNavNafiaAuperAdmin from "./CenterNavNafiaAuperAdmin.js";
import $ from "jquery";
import { Modal } from "react-bootstrap";
import { Dropdown } from "primereact/dropdown";
import "moment/locale/en-au";

import {
  server_post_data_shubham,
  save_update_AgentData_nafia,
  get_all_agent_data_nafia,
} from "../../KeyConnection/KeysService.js";
import {
  check_vaild_save,
  combiled_form_data,
  empty_form,
  handleAphabetsChange,
} from "../../CommonJquery/CommonJquery.js";
function NafiaAgents() {
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [control, setControl] = useState(null);
  const [controlOptions, setControlOption] = useState([]);
  const [showModal1, setShowModal1] = useState(false);

  const [AmraadminData, setdataFromAmra] = useState([]);
  const company_id = localStorage.getItem("company_id");

  const handleSaveChangesdynamic = async (form_data, url_for_save) => {
    let vaild_data = check_vaild_save(form_data);
    if (vaild_data) {
      setshowLoaderAdmin(true);
      let fd_from = combiled_form_data(form_data, null);
      fd_from.append("admin_id", company_id);
      fd_from.append("company_id", control.id);
      fd_from.append("main_id", "0");
      await server_post_data_shubham(url_for_save, fd_from)
        .then((Response) => {
          console.log(Response.data.message);
          setshowLoaderAdmin(false);
          if (Response.data.error) {
            alert(Response.data.message);
          } else {
            alert(Response.data.message);
            empty_form(form_data);
            handleCloseModal1();
            master_data_get("", "", "1", "0");
          }
        })
        .catch((error) => {
          setshowLoaderAdmin(false);
        });
    }
  };

  useEffect(() => {
    const start_date = "";
    const end_date = "";
    const flag = "1";
    let call_id = "0";
    master_data_get(start_date, end_date, flag, call_id);
  }, []);

  useEffect(() => {
    if (AmraadminData && AmraadminData.length > 0) {
      // Destroy the DataTable if it already exists
      if ($.fn.DataTable.isDataTable("#myTable")) {
        $("#myTable").DataTable().destroy();
      }
      // Initialize DataTable
      $("#myTable").DataTable();
    }
  }, [AmraadminData]);

  const master_data_get = async (start_date, end_date, flag, call_id) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("admin_id", company_id);
    fd.append("start_date", start_date);
    fd.append("end_date", end_date);
    fd.append("flag", flag);
    fd.append("call_id", call_id);
    await server_post_data_shubham(get_all_agent_data_nafia, fd)
      .then((Response) => {
        if (Response.data.error) {
          alert(Response.data.message);
        } else {
          console.log(Response.data.message);
          setdataFromAmra(Response.data.message.data_slider);
          setControlOption(Response.data.message.data_silder_image2);
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };

  const handleControlChange = (value) => {
    console.log(value);
    setControl(value);
  };

  const handleShowModal1 = () => {
    setShowModal1(true);
  };

  const handleCloseModal1 = () => {
    setShowModal1(false);
  };

  return (
    <div className="dashboard">
      <div
        className={showLoaderAdmin ? "loader-overlay show" : "loader-overlay"}
      >
        <div className="loader23"></div>
      </div>
      <Helmet>
        <title>Nafia Dashboard</title>
        <meta
          name="description"
          content="AI and Global Reach: The Key to Scalable Solutions in Customer
          Experience"
        />
      </Helmet>
      <div className="dashboard_container">
        <div className="dashboard_wrapper">
          <NAfiaAdminSideBar />
          <div id="content">
            <CenterNavNafiaAuperAdmin />

            <div className="Dashpages_contaioner">
              <div className="overview">
                <div className="overviewContaINER">
                  <div className="amradataTable">
                    <div className="amradataTable_container">
                      <h4></h4>
                      <table id="myTable" className="display">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Agent Name</th>
                            <th> Company Name </th>
                          </tr>
                        </thead>
                        <tbody>
                          {AmraadminData?.map((item, index) => (
                            <tr key={index}>
                              <td>
                                <div className="tableData1 tableData21">
                                  <p style={{ color: "#000" }}>{index + 1}</p>
                                </div>
                              </td>
                              <td>
                                <div className="tableData1 tableData21">
                                  <p style={{ color: "#000" }}>{item.name}</p>
                                </div>
                              </td>
                              <td>
                                <div className="tableData1 tableData21">
                                  <p style={{ color: "#000" }}>
                                    {
                                      controlOptions.find(
                                        (itemqqqq) =>
                                          itemqqqq.id === item.company_id_id
                                      )?.company_name
                                    }
                                  </p>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div className="AddAgentBtn">
                    <button onClick={handleShowModal1}>Add Agent</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showModal1}
        onHide={handleCloseModal1}
        dialogClassName="custom-modal2"
        centered
        size="xl"
      >
        <Modal.Header>
          <h5 className="mt-3">Add Agent</h5>
        </Modal.Header>
        <Modal.Body>
          <div className="mngUserContainer px-5">
            <form className="needs-validation" id="CarrerformData">
              <div className="row m-0">
                <div className="col-md-6">
                  <div className="mngUserInput">
                    <label>Agent Name</label>
                    <br />
                    <input
                      type="text"
                      className="form-control  trio_mendate"
                      name="name"
                      id="name"
                      maxLength={150}
                      onInput={handleAphabetsChange}
                      placeholder="Enter Full Name"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mngUserInput">
                    <label>Company</label>
                    <br />
                    <Dropdown
                      value={control}
                      onChange={(e) => handleControlChange(e.value)}
                      options={controlOptions}
                      optionLabel="company_name"
                      placeholder="Select Company"
                      className="controlSlct"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="updateCnclebtnDiv">
            <button className="updateCnclebtnCan" onClick={handleCloseModal1}>
              Cancel
            </button>
            <button
              className="updateCnclebtnUp"
              onClick={() =>
                handleSaveChangesdynamic(
                  "CarrerformData",
                  save_update_AgentData_nafia
                )
              }
            >
              Add
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default NafiaAgents;
