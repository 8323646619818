import React, { useState, useEffect } from "react";
import "../Css/Dashboard.css";
import "../Css/CallDetailView.css";
import SideBar from "./SideBar";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, registerables } from "chart.js";
import "react-circular-progressbar/dist/styles.css";
import SearchIcon from "../../assets/images/searchIcon.svg";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import "react-datepicker/dist/react-datepicker.css";
import DataModal from "./DataModal";
import Technotask from "../../assets/images/TechnotaskLogo12.png";
import CenterNavbar from "./CenterNavbar";
import {
  csv_amra_user_upload,
  get_debtor,
} from "../../KeyConnection/KeysService.js";
import { Helmet } from "react-helmet";

function DebtorProfile60() {
  const company_id = localStorage.getItem("company_id");
  const [loanID, setLoanID] = useState("");
  const [userNumber, setuserNumber] = useState("");
  const [debtorDataOverall, setDebtorDataOverall] = useState({});
  const [debtorDataMonthWise, setDebtorDataForMonthWise] = useState();
  const [overviewShow, setOverviewShow] = useState(true); // Default to 'true'
  const [debtorProfile, setdebtorProfile] = useState(false);
  const [modalData, setModalData] = useState({
    customer_id: 0,
    name: "",
    mobile: 0,
    call_record: [],
  });
  const [sentimentAnalysisGraphData, setSentimentAnalysisGraphData] = useState({
    labels: [
      "Pre Scheduled Calls",
      "30 Days Bucket",
      "30 Days Bucket",
      "30 Days Bucket",
    ],
    datasets: [
      {
        label: "POSTIVE",
        data: [30, 38, 10, 15],
        borderColor: "rgb(75, 192, 192)",
        tension: 0.37,
        pointRadius: 2,
        fill: false,
      },
      {
        label: "NEGATIVE",
        data: [40, 48, 8, 43],
        borderColor: "rgb(255, 99, 132)",
        tension: 0.37,
        pointRadius: 2,
        fill: false,
      },
      {
        label: "NEUTRAL",
        data: [20, 24, 19, 42],
        borderColor: "rgb(255, 205, 86)",
        tension: 0.37,
        pointRadius: 2,
        fill: false,
      },
    ],
  });

  const options = {
    scales: {
      x: {
        title: {
          display: false,
        },
        grid: {
          display: false,
        },
      },
      y: {
        title: {
          display: false,
        },
        grid: {
          display: false,
        },
        ticks: {
          min: 30000,
          max: 110000,
          stepSize: 20000,
          precision: 0,
        },
      },
    },
    plugins: {
      tooltip: {
        mode: "nearest",
      },
    },
    legend: {
      display: false, // Set this to false to hide the legend
    },
  };

  useEffect(() => {
    showAmradataonDashboard(`${csv_amra_user_upload}${company_id}/`);
  }, []);

  const showAmradataonDashboard = async (url_for) => {
    // Fetch data from the API endpoint
    await fetch(url_for)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.status === "error") {
          alert(data.data);
        } else {
          console.log(url_for);
          const month_wise_data = {
            POSITIVE: [0, 0, 0, 0],
            NEGATIVE: [0, 0, 0, 0],
            NEUTRAL: [0, 0, 0, 0],
          };
          //   total_pending: 0,
          //   total_collection: 0,
          //   total_amount: 0,
          //   success_rate: 0,
          // };

          const obj = {
            pre_bucket_user_count: {
              name: "Pre Scheduled Calls",
              total_calls: 0,
              positive: 0,
              negative: 0,
              neutral: 0,
            },
            days_30_bucket_user_count: {
              name: "30 Days Bucket",
              total_calls: 0,
              positive: 0,
              negative: 0,
              neutral: 0,
            },
            days_60_bucket_user_count: {
              name: "60 Days Bucket",
              total_calls: 0,
              positive: 0,
              negative: 0,
              neutral: 0,
            },
            days_90_bucket_user_count: {
              name: "90 Days Bucket",
              total_calls: 0,
              positive: 0,
              negative: 0,
              neutral: 0,
            },
          };

          for (let i = 0; i < data.length; i++) {
            const debtor = data[i];
            // calculate bucket using due data
            let now = Date.now();
            let dueDate = new Date(debtor.due_date);
            if (debtor.last_payment_date) {
              dueDate = new Date(debtor.last_payment_date);
            }
            let days = Math.ceil((now - dueDate) / (1000 * 60 * 60 * 24));
            let bucket;
            if (days <= 0) {
              bucket = obj.pre_bucket_user_count;
            } else if (days > 0 && days <= 30) {
              bucket = obj.days_30_bucket_user_count;
            } else if (days > 30 && days <= 60) {
              bucket = obj.days_60_bucket_user_count;
            } else if (days > 60) {
              bucket = obj.days_90_bucket_user_count;
            }

            debtor.debtor_history.forEach((instance) => {
              if (debtor.debtor_history.length > 0) {
                if (instance.call_flag === 1 && debtor.live_call === 0) {
                  bucket.total_calls += 1;
                  bucket.negative += 1;
                }
                if (instance.call_flag === 3) {
                  bucket.total_calls += 1;
                  bucket.positive += 1;
                }
                if (instance.call_flag === 4) {
                  bucket.total_calls += 1;
                  bucket.negative += 1;
                }
                if (instance.call_flag === 5) {
                  bucket.total_calls += 1;
                  bucket.neutral += 1;
                }
                if (instance.call_flag === 6) {
                  bucket.total_calls += 1;
                  bucket.neutral += 1;
                }
                if (instance.call_flag === 7) {
                  bucket.total_calls += 1;
                  bucket.positive += 1;
                }
                if (instance.call_flag === 8) {
                  bucket.total_calls += 1;
                  bucket.neutral += 1;
                }
                if (instance.call_flag === 9) {
                  bucket.total_calls += 1;
                  bucket.negative += 1;
                }
                if (instance.call_flag === 10) {
                  bucket.total_calls += 1;
                  bucket.positive += 1;
                }
              }
            });
          }
          setDebtorDataOverall(obj);

          for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
              const entry = obj[key];
              const keysArray = Object.keys(obj);
              const index = keysArray.indexOf(key);
              console.log(index);
              month_wise_data.POSITIVE[index] += entry.positive;
              month_wise_data.NEGATIVE[index] += entry.negative;
              month_wise_data.NEUTRAL[index] += entry.neutral;
            }
          }
          setDebtorDataForMonthWise(month_wise_data);

          const sentimentData = {
            labels: [
              "Pre Scheduled Calls",
              "30 Days Bucket",
              "30 Days Bucket",
              "30 Days Bucket",
            ],
            datasets: [
              {
                label: "POSITIVE",
                data: month_wise_data["POSITIVE"],
                borderColor: "rgb(75, 192, 192)",
                tension: 0.37,
                pointRadius: 2,
                fill: false,
              },
              {
                label: "NEGATIVE",
                data: month_wise_data["NEGATIVE"],
                borderColor: "rgb(255, 99, 132)",
                tension: 0.37,
                pointRadius: 2,
                fill: false,
              },
              {
                label: "NEUTRAL",
                data: month_wise_data["NEUTRAL"],
                borderColor: "rgb(255, 205, 86)",
                tension: 0.37,
                pointRadius: 2,
                fill: false,
              },
            ],
          };
          setSentimentAnalysisGraphData(sentimentData);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  ChartJS.register(...registerables);

  const searchCallDetails = async () => {
    // Fetch data from the API endpoint
    if (loanID !== "" || userNumber !== "") {
      let url_for = get_debtor;
      if (userNumber.length === 0) {
        url_for += loanID;
      } else {
        url_for += userNumber;
      }
      setModalData({
        customer_id: 0,
        name: "",
        mobile: 0,
        call_record: [],
      });
      await fetch(url_for)
        .then((response) => response.json())
        .then((data) => {
          let debtor = data.data;
          const status = data.status_flag;

          const call_record = debtor.debtor_history.map((data, i) => {
            // calculate bucket using due data
            let bucket = "";
            let now = Date.now();
            let dueDate = new Date(debtor.due_date);
            if (debtor.last_payment_date) {
              dueDate = new Date(debtor.last_payment_date);
            }
            let days = Math.ceil((now - dueDate) / (1000 * 60 * 60 * 24));
            if (days <= 0) {
              bucket = "Pre-due";
            } else if (days >= 1 && days <= 30) {
              bucket = "30 days";
            } else if (days > 30 && days <= 60) {
              bucket = "60 days";
            } else if (days > 60) {
              bucket = "90 days";
            }


            const date = new Date(data.created_at);
            const formattedDate = date.toLocaleDateString("en-US", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            });
            const hours = date.getHours().toString().padStart(2, "0");
            const minutes = date.getMinutes().toString().padStart(2, "0");

            const formattedTime = `${hours}:${minutes}`;
            let details = "";
            if (data.remarks != "") {
              try {
                details = data.remarks.split("@@@")[0];
              } catch (err) {
                //err
              }
            }

            return {
              chat_id: data.chat_id,
              serial_no: i + 1,
              call_date: formattedDate,
              call_time: formattedTime,
              chat_id: data.chat_id,
              bucket,
              details,
              notes: status[data.call_flag],
              case: "N/A",
            };
          });

           
          const obj = {
            customer_id: debtor.debtor_id,
            name: debtor.debtor_name,
            mobile: debtor.debtor_no,
            email: debtor.debtor_email,
            call_record,
          };
          console.log(obj);
          
          if(debtor.debtor_history.length < 1){
            
            const obj = {
              customer_id: debtor.debtor_id,
              name: debtor.debtor_name,
              mobile: debtor.debtor_no,
              email: debtor.debtor_email,
              call_record:[],
            };
            console.log(obj);
            //obj['call_record'] = ''
          }
          if (obj.customer_id !== 0) {
            console.log(obj.customer_id);
            setModalData(obj);
            const button = document.getElementById("modelclick");
            if (button) {
              button.click();
            }
          }
        })
        .catch((err) => {
           alert(
             "Cannot find call details corresponding to given Loan ID or Mobile number"
           );
        });
    }
  };

  return (
    <div className="dashboard">
      <Helmet>
        <title>Amra User Dashboard</title>
        <meta
          name="description"
          content="AI and Global Reach: The Key to Scalable Solutions in Customer
          Experience"
        />
      </Helmet>
      <div className="dashboard_container">
        <div className="dashboard_wrapper">
          <SideBar
            overviewShow={overviewShow}
            setOverviewShow={setOverviewShow}
            debtorProfile={debtorProfile}
            setdebtorProfile={setdebtorProfile}
          />
          <div id="content">
            <CenterNavbar />

            <div className="AmraConversations">
              <div className="AmraConversations_container">
                <div className="AmraConversations_container_wrapper">
                  <div className="overviewContaINER">
                    <div className="overviewHead overviewHeaddp ">
                      <h4>Viewing Call Details</h4>
                    </div>

                    <div className="valueCards">
                      <div className="valueCardsrow row m-0">
                        {Object.keys(debtorDataOverall).map((key) => (
                          <div
                            key={key}
                            className="valueCardpad col-xl-3 col-md-6 m-auto"
                          >
                            <p className="bucketTag">
                              {debtorDataOverall[key]?.name}
                            </p>
                            <div className="valueCardCallview">
                              <div className="bucketCallDetail">
                                <div className="totalCall">
                                  <p>Total Call Records</p>
                                </div>
                                <div>
                                  <p>{debtorDataOverall[key]?.total_calls}</p>
                                </div>
                              </div>

                              <div className="bucketCallDetail">
                                <div className={`totalCall1 positive ${key}`}>
                                  <div className="ttlCallDiv">
                                    <div className="green-point "></div>
                                    <p>Positive</p>
                                  </div>
                                  <h4 className="">
                                    {debtorDataOverall[key]?.positive}
                                  </h4>
                                </div>
                                <div className={`totalCall1 positive ${key}`}>
                                  <div className="ttlCallDiv">
                                    <div className="red-point"></div>
                                    <p>Negative</p>
                                  </div>
                                  <h4>{debtorDataOverall[key]?.negative}</h4>
                                </div>
                                <div className={`totalCall1 positive ${key}`}>
                                  <div className="ttlCallDiv">
                                    <div className="neutral-point"></div>
                                    <p>Neutral</p>
                                  </div>
                                  <h4>{debtorDataOverall[key]?.neutral}</h4>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                        {/* <div className="valueCardpad col-xl-3 col-md-6 m-auto">
                          <p className="bucketTag">30 Days Bucket</p>
                          <div className="valueCardCallview">
                            <div className="bucketCallDetail">
                              <div className="totalCall">
                                <p>Total Call Records</p>
                              </div>
                              <div>
                                <p>895K</p>
                              </div>
                            </div>

                            <div className="bucketCallDetail">
                              <div className="totalCall1 positive ">
                                <div className="ttlCallDiv">
                                  <div className="green-point "></div>
                                  <p>Positive</p>
                                </div>
                                <h4 className="">500k</h4>
                              </div>
                              <div className="totalCall1 positive">
                                <div className="ttlCallDiv">
                                  <div className="red-point"></div>
                                  <p>Negative</p>
                                </div>
                                <h4>200k</h4>
                              </div>
                              <div className="totalCall1 positive">
                                <div className="ttlCallDiv">
                                  <div className="neutral-point"></div>
                                  <p>Neutral</p>
                                </div>
                                <h4>195k</h4>
                              </div>
                            </div>
                          </div>
                        </div> */}
                        {/* <div className="valueCardpad col-xl-3 col-md-6 m-auto">
                          <p className="bucketTag">60 Days Bucket</p>
                          <div className="valueCardCallview">
                            <div className="bucketCallDetail d-flex">
                              <div className="totalCall">
                                <p>Total Call Records</p>
                              </div>
                              <div>
                                <p>600K</p>
                              </div>
                            </div>

                            <div className="bucketCallDetail">
                              <div className="totalCall1 positive ">
                                <div className="ttlCallDiv">
                                  <div className="green-point "></div>
                                  <p>Positive</p>
                                </div>
                                <h4 className="">500k</h4>
                              </div>
                              <div className="totalCall1 positive">
                                <div className="ttlCallDiv">
                                  <div className="red-point"></div>
                                  <p>Negative</p>
                                </div>
                                <h4>200k</h4>
                              </div>
                              <div className="totalCall1 positive">
                                <div className="ttlCallDiv">
                                  <div className="neutral-point"></div>
                                  <p>Neutral</p>
                                </div>
                                <h4>195k</h4>
                              </div>
                            </div>
                          </div>
                        </div> */}
                        {/* <div className="valueCardpad col-xl-3 col-md-6 m-auto">
                          <p className="bucketTag">90 Days Bucket</p>
                          <div className="valueCardCallview">
                            <div className="bucketCallDetail d-flex">
                              <div className="totalCall">
                                <p>Total Call Records</p>
                              </div>
                              <div>
                                <p>600K</p>
                              </div>
                            </div>

                            <div className="bucketCallDetail">
                              <div className="totalCall1 positive ">
                                <div className="ttlCallDiv">
                                  <div className="green-point "></div>
                                  <p>Positive</p>
                                </div>
                                <h4 className="">500k</h4>
                              </div>
                              <div className="totalCall1 positive">
                                <div className="ttlCallDiv">
                                  <div className="red-point"></div>
                                  <p>Negative</p>
                                </div>
                                <h4>200k</h4>
                              </div>
                              <div className="totalCall1 positive">
                                <div className="ttlCallDiv">
                                  <div className="neutral-point"></div>
                                  <p>Neutral</p>
                                </div>
                                <h4>195k</h4>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="valueCardpad col-xl-3 col-md-6 m-auto">
                          <p className="bucketTag">Pre Scheduled Calls</p>
                          <div className="valueCardCallview">
                            <div className="bucketCallDetail d-flex">
                              <div className="totalCall">
                                <p>Total Call Records</p>
                              </div>
                              <div>
                                <p>700K</p>
                              </div>
                            </div>

                            <div className="bucketCallDetail">
                              <div className="totalCall1 positive ">
                                <div className="ttlCallDiv">
                                  <div className="green-point "></div>
                                  <p>Positive</p>
                                </div>
                                <h4 className="">500k</h4>
                              </div>
                              <div className="totalCall1 positive">
                                <div className="ttlCallDiv">
                                  <div className="red-point"></div>
                                  <p>Negative</p>
                                </div>
                                <h4>200k</h4>
                              </div>
                              <div className="totalCall1 positive">
                                <div className="ttlCallDiv">
                                  <div className="neutral-point"></div>
                                  <p>Neutral</p>
                                </div>
                                <h4>195k</h4>
                              </div>
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="debtorProfile">
                  <div className="debtorProfileContainer">
                    <div className="debtordetailCards">
                      <div className="row m-0">
                        <div className="col-md-8">
                          <div className="lineChartCOl lineChartCOlCall">
                            <div className="d-flex justify-content-between mb-3">
                              <h5 style={{ color: "#000" }}>
                                Sentiment Analysis
                              </h5>
                              <div className="barChartLegends mb-0">
                                <div className="barChartLegend">
                                  <div
                                    className="barChartLegendpink"
                                    style={{ backgroundColor: "#1FCE50" }}
                                  ></div>
                                  <p style={{ color: "#1FCE50" }}>Positive</p>
                                </div>
                                <div className="barChartLegend">
                                  <div
                                    className="barChartLegendblue"
                                    style={{ backgroundColor: "#FF5B5B" }}
                                  ></div>
                                  <p style={{ color: "#FF5B5B" }}>Negative</p>
                                </div>
                                <div className="barChartLegend">
                                  <div
                                    className="barChartLegendblue"
                                    style={{ backgroundColor: "#FFA471" }}
                                  ></div>
                                  <p style={{ color: "#FFA471" }}>Neutral</p>
                                </div>
                              </div>
                            </div>
                            <Line
                              className="canvas"
                              data={sentimentAnalysisGraphData}
                              options={options}
                              responsive={true}
                              maintainAspectRatio={false}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 ">
                          <div className="debtorSearch callSearchDetail mt-0">
                            <div className="debtorFilter">
                              <div className="debtorFilterContainer">
                                <h5>Search Call Details (Debtor)</h5>
                                <input
                                  className="inp"
                                  type="mobile no"
                                  placeholder="Loan Id"
                                  value={loanID}
                                  onChange={(e) => {
                                    setLoanID(e.target.value);
                                    setuserNumber("");
                                  }}
                                  onInput={(e) => {
                                    if (
                                      !/^[a-zA-Z0-9@.]*$/.test(e.target.value)
                                    ) {
                                      e.target.value = ""; // Clear the input if it contains any other character
                                    }
                                  }}
                                />
                                <h5>or</h5>

                                <input
                                  className="inp"
                                  type="mobile no"
                                  placeholder="Mobile No."
                                  value={userNumber}
                                  onChange={(e) => {
                                    setLoanID("");
                                    setuserNumber(e.target.value);
                                  }}
                                  maxLength="10"
                                  onInput={(e) => {
                                    e.target.value = e.target.value.replace(
                                      /[^0-9]/g,
                                      ""
                                    );
                                  }}
                                />
                                <button
                                  onClick={searchCallDetails}
                                  className="Search_btn  d-flex"
                                >
                                  <img src={SearchIcon} alt="icon" />
                                  <p>Search</p>
                                </button>
                                <button
                                  id="modelclick"
                                  className="hidden"
                                  data-bs-toggle="modal"
                                  data-bs-target="#staticBackdrop"
                                >
                                  <img src={SearchIcon} alt="icon" />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="valueCardpadCallView valueCardpad2CallView my-4">
                  <div className="valueCardCallView manualCAll justify-content-around">
                    <div className="col-md-8 mb-4">
                      <h5 style={{ color: "#ffffff" }}>
                        Manual Call Re-Schedule
                      </h5>
                    </div>
                    <div className="manualcallflex-container">
                      <div className="row m-0">
                        <div className="col-md-4" style={{ paddingLeft: "0" }}>
                          <div className="input-div">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Loan ID"
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div type="text" className="form-control Buckettext-div">
                            <div className="bucketButtonText">
                              <div className="">
                                <p>Bucket</p>
                              </div>
                              <div className="red daystocall selcteddaystocall">
                                30 Days
                              </div>
                              <div className="green daystocall">60 Days</div>
                              <div className="yellow daystocall">90 Days</div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="keywordButton-div">
                            <button className="keywordButton">Key Password</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
              <div className="brandingContainer">
                <div>
                  <h5>Powered by</h5>
                  <img src={Technotask} alt="Logo" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <DataModal modalData={modalData} />
      </div>
    </div>
  );
}

export default DebtorProfile60;
