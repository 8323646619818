import React, { useState, useEffect } from "react";
import "../Css/AmraDashboard.css";
import "../Css/BookDemoView.css";
import {
  URL_FOR_NEWS_SAVE,
  server_get_data_master,
} from "../../KeyConnection/KeysService"; // Adjust the path as needed
import SideBar2 from "./Sidebar2";
import NotificationsImg from "../../assets/images/notification.svg";
import SideBarControl from "../../assets/images/rightLeftSIdebar.png";
import UserImgPrf from "../../assets/images/userimg45.svg";
import adm_img from "../../assets/images/adm_img.png";

function NewsView() {
  const [contacts, setcontacts] = useState([]);
  const [selecteddata, setselecteddataa] = useState([]);
  const [showLoaderWebsite, setshowLoaderWebsite] = useState(false);

  const getUserData = async (url_for) => {
    const fd = new FormData();
    await server_get_data_master(url_for, fd)
      .then((response) => response.json())
      .then((data) => {
        setshowLoaderWebsite(false);
        console.log(data);
        if (data.status === "error") {
          alert(data.data);
        } else {
          setcontacts(data.data);
        }
      })
      .catch((error) => {
        console.log("asdsa");
        setshowLoaderWebsite(false);
      });
  };

  useEffect(() => {
    getUserData(URL_FOR_NEWS_SAVE);
  }, []);

  const handleGetId = (index) => {
    console.log(index);
    setselecteddataa([contacts[index]]);
  };

  return (
    <div className="dashboard">
      <div className="dashboard_container">
        <div className="dashboard_wrapper">
          <div className="sidebar_wrapper">
            <SideBar2 />
          </div>
          <div id="content">
            <nav className="navbar centerNav navbar-expand-lg navbar-light bg-light p-0">
              <div className="navbar_left">
                <div
                  type="button"
                  id="sidebarCollapse1"
                  className="btn_toggle1 btn_toggle1ani"
                >
                  <img src={SideBarControl} alt="img" />
                </div>

                <div className="logo_msgCount">
                  <div className="logo_div">
                    <h4 style={{ fontSize: "24px" }}>Dashboard</h4>
                  </div>
                </div>
              </div>

              <div className="navbar_right">
                {/* <div className="navbarRightNoti">
                  <img src={NotificationsImg} alt="icon" />
                </div> */}
                <div className="navbarRightAcc">
                  <img src={adm_img} alt="icon" />
                  <div className="nav-item dropdown">
                    <div
                      className="nav-link dropdown-toggle"
                      id="navbarDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Account
                    </div>
                    <div
                      className="dropdown-menu prdctList LEFTalINGdROPDOWN"
                      aria-labelledby="navbarDropdown"
                    >
                      <a className="dropdown-item" href="/">
                        Settings
                      </a>
                      <a className="dropdown-item" href="/">
                        Logout
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </nav>
            <div className="AmraConversations">
              <div className={showLoaderWebsite ? "loading_website" : ""}></div>
              <div className="AmraConversations_container">
                <div className="AmraConversations_container_wrapper">
                  <div className="overviewContaINER">
                    <div className="overviewHead">
                      <h4>News Letter Subscribers</h4>
                      <div className="AmraConversations_data">
                        <div className="BookdataTable">
                          <div className="BookdataTable_container">
                            <table className="BookdataTable">
                              <thead className="theadStyle">
                                <tr>
                                  <th className="th1" scope="col">
                                    S.No.
                                  </th>
                                  {/* <th scope="col">Name</th> */}
                                  <th scope="col">Email</th>
                                  {/* <th scope="col">Country</th>
                          <th className="th2" scope="col">
                            Details
                          </th> */}
                                </tr>
                              </thead>
                              <tbody className="tboday">
                                {contacts.map((contact, index) => (
                                  <tr
                                    key={index}
                                    onClick={() => handleGetId(index)}
                                  >
                                    <td className="th1">{index + 1}</td>
                                    <td>{contact.user_email}</td>
                                    {/* <td className="th2">
                              <button
                                className="seeMsgBtn"
                                data-toggle="modal"
                                data-target="#exampleModalCenter"
                              >
                                See Details
                              </button>
                            </td> */}
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="modal fade"
                id="exampleModalCenter"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered" role="document">
                  <div className="modal-content">
                    <div className="modal-header modalHeader23">
                      <button
                        type="button"
                        className="close modalCloseBtn23"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <div className="contactDetailsDiv">
                        {selecteddata.map((contact, index) => (
                          <div key={index}>
                            <p>
                              <span>Name :</span> {contact.userName}
                            </p>
                            <p>
                              <span>Email :</span> {contact.userEmail}
                            </p>
                            <p>
                              <span>Number :</span> {contact.userNumber}
                            </p>
                            <p>
                              <span>Country :</span> {contact.userCountry}
                            </p>
                            <p>
                              <span>Message :</span> {contact.userMsg}
                            </p>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewsView;
