import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import CenterNavbar from "./CenterNavbar.js";
import SideBar from "./SideBar";
import $ from "jquery";
import Success from "../../assets/images/success_gif.gif";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";

import "moment/locale/en-au";
import {
  server_post_data_shubham,
  CREATE_AGENT_FARIDA,
  GET_AGENT_FARIDA,
} from "../../KeyConnection/KeysService.js";
import {
  check_vaild_save,
  combiled_form_data,
  empty_form,
  handleAphabetsChange,
  handleEmailChange,
  handleIaphabetnumberChange,
  handleNumbersChange,
} from "../../CommonJquery/CommonJquery.js";

function AmraAdminCustomers() {
  const [userLandmarks, setuserLandmarks] = useState([""]);
  const [control, setControl] = useState(null);

  const handleAddLandmark = (e) => {
    e.preventDefault();
    setuserLandmarks([...userLandmarks, ""]);
  };
  const handleRemoveLandmark = (e) => {
    e.preventDefault();
    const updated = [...userLandmarks];
    updated.pop();
    setuserLandmarks(updated);
  };
  const [editoHostipallist, setEditHostipallist] = useState([]);
  const [Hostipallist, setHostipallist] = useState([]);
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const retrievedAdminId = "0";
  const [dynaicimage, setDynaicimage] = useState(null);
  const [showModal1, setShowModal1] = useState(false);
  const [editFlag, setEditFlag] = useState(0);
  const hospitalID = localStorage.getItem("hospital_id") || "14";
  const [showModal, setShowModal] = useState(false);
  const closeModal = () => {
    setShowModal(false);
  };

  const controlOptions = [
    { name: "Basic", code: "1" },
    { name: "Standard", code: "2" },
    { name: "Premium", code: "3" },
  ];

  const handleShowModal1 = (call_type) => {
    if (call_type === 0) {
      setEditHostipallist([]);
      setEditFlag(0);
    } else {
      setEditHostipallist(Hostipallist[call_type - 1]);
      setEditFlag(Hostipallist[call_type - 1].id);
    }
    setShowModal1(true);
  };

  const handleCloseModal1 = () => {
    setShowModal1(false);
  };

  useEffect(() => {
    const start_date = "";
    const end_date = "";
    const flag = "1";
    let call_id = "0";

    master_data_get(start_date, end_date, flag, call_id);
  }, []);

  useEffect(() => {
    if (Hostipallist.length > 0) {
      // Destroy the DataTable if it already exists
      if ($.fn.DataTable.isDataTable("#myTable")) {
        $("#myTable").DataTable().destroy();
      }
      // Initialize DataTable
      $("#myTable").DataTable();
    }
  }, [Hostipallist]);

  const master_data_get = async (start_date, end_date, flag, call_id) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("admin_id", retrievedAdminId);
    fd.append("start_date", start_date);
    fd.append("end_date", end_date);
    fd.append("flag", flag);
    fd.append("call_id", call_id);
    fd.append("hospital_id", hospitalID);
    await server_post_data_shubham(GET_AGENT_FARIDA, fd)
      .then((Response) => {
        if (Response.data.error) {
          alert(Response.data.message);
        } else {
          let hostiptal_list = Response.data.message.data_slider;
          console.log(hostiptal_list);
          setHostipallist(hostiptal_list);
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };

  const handleSaveChangesdynamic = async (form_data, url_for_save) => {
    let vaild_data = check_vaild_save(form_data);
    if (vaild_data) {
      setshowLoaderAdmin(true);
      let fd_from = combiled_form_data(form_data, dynaicimage);
      fd_from.append("edit_flag", editFlag);
      fd_from.append("hospital_id", hospitalID);
      await server_post_data_shubham(url_for_save, fd_from)
        .then((Response) => {
          console.log(Response.data);
          setshowLoaderAdmin(false);
          if (Response.data.error) {
            alert(Response.data.message);
          }
          if (!Response.data.error) {
            empty_form(form_data);
            handleCloseModal1();
            setShowModal(true);
            const start_date = "";
            const end_date = "";
            const flag = "1";
            let call_id = "0";

            master_data_get(start_date, end_date, flag, call_id);
          }
        })
        .catch((error) => {
          setshowLoaderAdmin(false);
        });
    }
  };

  const handleFileChangedynamic = (keyname) => (event) => {
    const file = event.target.files[0];

    let new_file_name = keyname + "_show";

    if (file) {
      const reader = new FileReader();

      reader.onload = () => {
        setDynaicimage((prevImages) => ({
          ...prevImages,
          [keyname]: file,
          [new_file_name]: reader.result,
        }));
      };
      reader.readAsDataURL(file);
    } else {
      setDynaicimage((prevImages) => ({
        ...prevImages,
        [keyname]: null,
        [new_file_name]: null,
      }));
    }
  };

  return (
    <div className="dashboard">
      <Helmet>
        <title>Farida User - Agents</title>
        <meta
          name="description"
          content="AI and Global Reach: The Key to Scalable Solutions in Customer
          Experience"
        />
      </Helmet>
      <div className="dashboard_container">
        <div
          className={showLoaderAdmin ? "loader-overlay show" : "loader-overlay"}
        >
          <div className="loader23"></div>
        </div>
        <div className="dashboard_wrapper">
          <SideBar />
          <div id="content">
            <CenterNavbar />

            <div className="Dashpages_contaioner">
              <div className="overview mt-3">
                <div className="overviewContaINER">
                  <div className="row m-0">
                    <div className="col-md-8"></div>
                    <div className="col-md-4">
                      <div className="AddAgentBtn w-100 m-0">
                        <button
                          className="w-100"
                          style={{ height: "45px" }}
                          onClick={() => handleShowModal1(0)}
                        >
                          Add Agent
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="revenueCall">
                    <div className="amradataTable">
                      <div className="amradataTable_container">
                        <h4>Agent List</h4>
                        <table id="myTable" className="display">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th> Name</th>
                              <th style={{ textAlign: "center" }}>
                                License No.
                              </th>
                              <th style={{ textAlign: "center" }}>
                                Date of Onboarding
                              </th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Hostipallist &&
                              Hostipallist.map((item, index) => (
                                <tr key={index}>
                                  <td>
                                    <p style={{ color: "#000" }}>{index + 1}</p>
                                  </td>
                                  <td>
                                    <p style={{ color: "#000" }}>{item.name}</p>
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    <p style={{ color: "#000" }}>
                                      {item.license_id}
                                    </p>
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    <p style={{ color: "#000" }}>
                                      {item.created_at}
                                    </p>
                                  </td>
                                  <td>
                                    <button
                                      className="editUsrContrlBtn"
                                      onClick={() =>
                                        handleShowModal1(index + 1)
                                      }
                                    >
                                      Edit
                                    </button>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showModal1}
        onHide={handleCloseModal1}
        dialogClassName="custom-modal2"
        centered
        size="xl"
      >
        <Modal.Header>
          <h5 className="mt-3">Add Agent</h5>
        </Modal.Header>
        <Modal.Body>
          <div className="mngUserContainer px-5">
            <form className="needs-validation" id="CarrerformData">
              <div className="row m-0">
                <div className="col-md-6">
                  <div className="mngUserInput">
                    <label>Agent Name</label>
                    <br />
                    <input
                      className="form-control  trio_mandatory"
                      name="name"
                      id="name"
                      maxLength={100}
                      onInput={handleAphabetsChange}
                      placeholder="Enter Agent Name"
                      defaultValue={editoHostipallist?.name || ""}
                      type="text"
                    />
                    <span className="condition_error"></span>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="updateCnclebtnDiv">
            <button className="updateCnclebtnCan" onClick={handleCloseModal1}>
              Cancel
            </button>
            <button
              className="updateCnclebtnUp"
              onClick={() =>
                handleSaveChangesdynamic("CarrerformData", CREATE_AGENT_FARIDA)
              }
            >
              {editoHostipallist?.name ? "Update" : "Add"}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      {/* second modal */}
      <Modal
        id="myModal"
        show={showModal}
        onHide={closeModal}
        centered
        backdrop="static"
      >
        <Modal.Body className="modal_body">
          <div className="success_img d-flex justify-content-center">
            <img src={Success} alt="icon" />
          </div>
          <p>Agent has been updated successfully</p>
        </Modal.Body>
        <Modal.Footer>
          <Link
            to="/burjeel_agents"
            onClick={() => window.location.reload(false)}
          >
            <div className="btn go_to_login">View</div>
          </Link>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default AmraAdminCustomers;
