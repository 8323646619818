import React, { useState, useEffect, useRef } from "react";
import NAfiaAdminSideBar from "./NAfiaAdminSideBar";
import "../../Dasboard/Css/Dashboard.css";
import "../AdminSIdebar.css";
import "../NAfiaAdmin.css";
import Calender from "../../assets/images/calendar12.svg";
import NotificationsImg from "../../assets/images/notification.svg";
import SideBarControl from "../../assets/images/rightLeftSIdebar.png";
import MoreIcon from "../../assets/images/more_vertical.svg";
import { Line, Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, registerables } from "chart.js";
import "react-circular-progressbar/dist/styles.css";
import SearchImg from "../../assets/images/search-normal.svg";
import MyChart from "../../Components/test";
import { Helmet } from "react-helmet";
import adm_img from "../../assets/images/adm_img.png";
import UsersImg from "../../assets/images/Users12.svg";
import arrowUp from "../../assets/images/ArrowRight12.svg";
import Chart1 from "../../assets/images/Chart12.svg";
import Chart2 from "../../assets/images/Chart123.svg";
import helpSign from "../../assets/images/Info123.svg";
import ReactApexChart from "react-apexcharts";
import gear1 from "../../assets/images/Gear1.png";
import Question from "../../assets/images/Question.png";
import Bell from "../../assets/images/Bell.png";
import Money from "../../assets/images/Money.png";
import {
  CompanyListNafia,
  server_get_data_master,
  URL_FOR_TICKETS,
  NAFIA_DATA_AGGREGATE,
} from "../../KeyConnection/KeysService";

import $ from "jquery";
import moment from "moment";
import "moment/locale/en-au";
import "daterangepicker";
import CenterNavNafiaAuperAdmin from "./CenterNavNafiaAuperAdmin";
function NafiaAdmin() {
  ChartJS.register(...registerables);
  const [isLoading, setLoading] = useState(false);
  const [companyID, setCompanyID] = useState(0);
  const [botID, setBotID] = useState(1);
  const [Nafiaadmindata, setdataFromNafia] = useState({
    total_customers: 0,
    for_renewals: 0,
    new_onboardings: 0,
    urgent_tickets: 0,
  });
  const [nafiaAggregateData, setNafiaAggregateData] = useState({
    avg_response_time: 0,
    escalations_count: 0,
    total_interaction_count: 0,
    total_message_count: 0,
  });

  const [companyListData, setCompanyListData] = useState([]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = new Intl.DateTimeFormat("en-US", { month: "long" }).format(
      date
    );
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  };

  const [interactiveGraphData, setInteractiveGraphData] = useState({
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
    datasets: [
      {
        label: "Total Interactions",
        data: [30, 38, 10, 15, 43, 10],
        borderColor: "rgba(167, 0, 255, 1)",
        tension: 0.37,
        pointRadius: 1,
        fill: false,
      },
      {
        label: "Total Messages",
        data: [40, 48, 8, 43, 10, 47],
        borderColor: "rgba(60, 216, 86, 1)",
        tension: 0.37,
        pointRadius: 1,
        fill: false,
      },
    ],
  });

  const showNafiadataonDashboard = async (URL_FOR, dateRange) => {
    setLoading(true);
    try {
      const res = await server_get_data_master(URL_FOR);
      const data = await res.json();
      const companyList = data.data;
      let adminData = {
        total_customers: companyList.length,
        accounts_for_renewals: 0,
        new_onboardings: 0,
        urgent_tickets: 0,
      };
      companyList.forEach(async (company) => {
        let now = Date.now();
        let onboarding_date = new Date(company.created_at);
        const sevenDaysAgo = now - 7 * 24 * 60 * 60 * 1000;
        if (onboarding_date >= sevenDaysAgo) {
          adminData.new_onboardings += 1;
        }

        let plan_expiry_date = new Date(company.plan_expiry_date);
        if (plan_expiry_date.getMonth() === new Date().getMonth()) {
          adminData.accounts_for_renewals += 1;
        }
        setdataFromNafia(adminData);

        let target_url = `${NAFIA_DATA_AGGREGATE}${dateRange.startDate}/${dateRange.endDate}/${company.id}/${botID}/`;
        const res = await server_get_data_master(target_url);
        const data = await res.json();
        setNafiaAggregateData(data.data);
        const months = [];
        const sessionCount = [];
        const messageCount = [];

        data.datewise_data.forEach((item) => {
          const abbreviatedMonth = item.month.substring(0, 3);
          months.push(abbreviatedMonth);
          sessionCount.push(item.unique_session_count);
          messageCount.push(item.total_message_count);
        });

        const updatedInteractiveData = {
          labels: months,
          datasets: [
            {
              label: "Total Interactions",
              data: sessionCount,
              borderColor: "rgba(167, 0, 255, 1)",
              tension: 0.37,
              pointRadius: 1,
              fill: false,
            },
            {
              label: "Total Messages",
              data: messageCount,
              borderColor: "rgba(60, 216, 86, 1)",
              tension: 0.37,
              pointRadius: 1,
              fill: false,
            },
          ],
        };
        setInteractiveGraphData(updatedInteractiveData);

        const obj = {
          id: company.id,
          name: company.name,
          email: company.email,
          plan_expiry_date: formatDate(plan_expiry_date),
          onboarding_date: formatDate(onboarding_date),
          avg_response_time: data.data.avg_response_time,
          escalations_count: data.data.escalations_count,
          total_interaction_count: data.data.total_interaction_count,
          total_message_count: data.data.total_message_count,
        };
        console.log(obj);

        let alreadyPresent = false;
        companyListData.forEach((comp) => {
          if (comp.id === obj.id) {
            alreadyPresent = true;
          }
        });
        if (!alreadyPresent) {
          setCompanyListData([...companyListData, obj]);
        }
      });

      const response = await server_get_data_master(URL_FOR_TICKETS);
      const data2 = await response.json();
      // setshowLoaderWebsite(false);
      if (data2.status === "error") {
        alert("Error fetching data");
      } else {
        data2.data.forEach((ticket) => {
          if (ticket.ticket_for_flag === "2") {
            if (ticket.priority_status === "High") {
              adminData.urgent_tickets += 1;
            }
          }
        });
      }

      setdataFromNafia(adminData);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  const data = {
    labels: ["2015", "2016", "2017", "2018", "2019", "2020"],
    datasets: [
      {
        label: "Revenue",
        data: [30, 38, 10, 15, 43, 10, 27],
        borderColor: "rgb(75, 192, 192)",
        tension: 0.37,
        pointRadius: 1,
        fill: false,
      },
      {
        label: "Customer",
        data: [40, 48, 8, 43, 10, 47, 30],
        borderColor: "rgb(255, 99, 132)",
        tension: 0.37,
        pointRadius: 1,
        fill: false,
      },
      {
        label: "Interaction",
        data: [20, 24, 19, 42, 14, 35, 32],
        borderColor: "rgb(255, 205, 86)",
        tension: 0.37,
        pointRadius: 1,
        fill: false,
      },
    ],
  };
  const updatedData = {
    ...data, // Retain the existing structure of the data object
    datasets: data.datasets.map((dataset) => {
      let newData = [...dataset.data]; // Copy the existing data array
      // Update data based on the label name
      if (dataset.label === "Customer") {
        newData.push(Nafiaadmindata.total_customers);
      } else if (dataset.label === "Interaction") {
        newData.push(Nafiaadmindata.total_interaction);
      }
      return {
        ...dataset,
        data: newData, // Set the updated data array
      };
    }),
  };

  const options = {
    scales: {
      x: {
        title: {
          display: false,
        },
        grid: {
          display: false,
        },
      },
      y: {
        title: {
          display: false,
        },
        ticks: {
          min: 0,
          max: 50,
          stepSize: 10,
          precision: 0,
        },
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      tooltip: {
        mode: "nearest",
      },
    },
  };

  const optionsRadial = {
    chart: {
      type: "radialBar",
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: "40%",
        },
        bar: {
          borderRadius: 10, // Set the border radius for the bars
        },
        dataLabels: {
          name: {
            fontSize: "14px",
          },
          value: {
            fontSize: "13px",
          },
          total: {
            show: false,
            formatter: function (w) {
              // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
              return 100;
            },
          },
        },
      },
    },
    labels: data.labels,
  };

  const chartDataRadial = {
    labels: ["Good", "Average", "Bad"],
    series: [70, 20, 10],
  };
  const customColors = ["#7E32CB", "#F29CF6", "#CF6269"];

  const series = chartDataRadial.series;

  const showNafiaData = async (url_for) => {
    // Fetch data from the API endpoint
    setLoading(true);
    await fetch(url_for)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.status === "error") {
          alert(data.data);
        } else {
          setdataFromNafia(data); // Update state with fetched data
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const inputRef = useRef(null);

  const handleDateRangeChange = (start, end, label) => {
    // Callback function for handling date range changes
    let startDate = start.format("DD-MM-YYYY").toString();
    let endDate = end.format("DD-MM-YYYY").toString();

    let dates = {
      startDate: start.format("YYYY-MM-DD").toString(),
      endDate: end.format("YYYY-MM-DD").toString(),
    };
    showNafiadataonDashboard(CompanyListNafia, dates);
    // showNafiaRecentInteractions(NAFIA_USER_LIST, dates, companyID, botID);
  };

  useEffect(() => {
    // Call the function to fetch data when the component mounts
    // showNafiaData(URL_FOR_NAFIA_ADMIN_DASH);

    const datepicker = $(inputRef.current);
    datepicker.daterangepicker(
      {
        timePicker: true,
        startDate: moment().subtract(7, "days").startOf("day"), // Set start date to 7 days ago
        endDate: moment().add(2, "days").endOf("day"), // Set end date to today
        locale: {
          format: "DD/MM/YYYY",
        },
      },
      handleDateRangeChange
    );

    // Call handleDateRangeChange on component load
    const startDate = datepicker.data("daterangepicker").startDate;
    const endDate = datepicker.data("daterangepicker").endDate;
    handleDateRangeChange(startDate, endDate);
  }, []);

  const renderCompanyData = () => {
    return companyListData.map((company, index) => (
      <tr key={index}>
        <td>
          <div className="tableData1 tabledata1radius">
            <p>{company.name}</p>
          </div>
        </td>
        <td>
          <div className="tableData2">
            <p>{company.onboarding_date}</p>
          </div>
        </td>
        <td>
          <div className="tableData1">
            <p>{company.total_interaction_count}</p>
          </div>
        </td>
        <td>
          <div className="tableData1">
            <p>{company.avg_response_time}</p>
          </div>
        </td>
        <td>
          <div className="tableData3">
            <p>{company.escalations_count}</p>
          </div>
        </td>
      </tr>
    ));
  };

  const [datarecovry, setDataRecovery] = useState({
    labels: ["Green", "Orange", "Red"],
    datasets: [
      {
        data: [10, 45, 13, 32],
        backgroundColor: ["#EB4550", "#43BE83", "#F7941D", "#DCD9E8"],
      },
    ],
  });

  const optionsrecovry = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: "60%",
    plugins: {
      legend: {
        display: false, // Hide legend
      },
    },
    scales: {
      x: {
        display: false, // Hide x-axis
      },
      y: {
        display: false, // Hide y-axis
      },
    },
    elements: {
      arc: {
        backgroundColor: "transparent", // Set the background color to transparent
      },
    },
  };

  return (
    <div className="dashboard">
      <div className={isLoading ? "loader-overlay show" : "loader-overlay"}>
        <div className="loader23"></div>
      </div>
      <Helmet>
        <title>Nafia Dashboard</title>
        <meta
          name="description"
          content="AI and Global Reach: The Key to Scalable Solutions in Customer
          Experience"
        />
      </Helmet>
      <div className="dashboard_container">
        <div className="dashboard_wrapper">
          <NAfiaAdminSideBar />
          <div id="content">
            <CenterNavNafiaAuperAdmin />
            <div className="Dashpages_contaioner">
              <div className="overview">
                <div className="overviewContaINER">
                  <div className="adminoverviewHead">
                    <div className="adminoverviewHead_conrTAINER">
                      <div
                        style={{ opacity: "0", pointerEvents: "none" }}
                        className="adminoverviewSearch"
                      >
                        <img src={SearchImg} alt="icon" />
                        <input
                          type="text"
                          placeholder="Search for file, folder, etc..."
                        />
                      </div>
                    </div>
                    <div className="date_filter_div">
                      <div className="date_filter">
                        <div className="calender_icon">
                          <label
                            style={{ cursor: "pointer" }}
                            htmlFor="datepicker"
                          >
                            <img src={Calender} alt="Image" />
                          </label>
                        </div>
                        <input
                          id="datepicker"
                          className="form-control form-control-solid"
                          placeholder="Pick date range"
                          ref={inputRef}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="revenueCall">
                    <div className="row m-0">
                      <div className="col-md-8 p-0  ">
                        <div className="row m-0">
                          <div
                            className="col-md-6"
                            style={{ paddingLeft: "0" }}
                          >
                            <div className="customersCard">
                              <div className="customersCardLeft">
                                <div className="customersCardLeftText">
                                  <img src={UsersImg} alt="icon" />
                                  <p>Customers</p>
                                </div>
                                <div className="customersCardrighttext">
                                  <h3>{Nafiaadmindata.total_customers}</h3>
                                  {/* <p>
                                    34.3%{" "}
                                    <span>
                                      <img src={arrowUp} alt="icon" />
                                    </span>
                                  </p> */}
                                </div>
                              </div>
                              <div className="customersCardright">
                                <img src={Chart1} alt="chat" />
                              </div>
                            </div>
                          </div>

                          <div
                            className="col-md-6"
                            style={{ paddingRight: "0" }}
                          >
                            <div className="customersCard">
                              <div className="customersCardLeft">
                                <div className="customersCardLeftText">
                                  <img src={Money} alt="icon" />
                                  <p>Total Interaction</p>
                                </div>
                                <div className="customersCardrighttext">
                                  <h3>
                                    {nafiaAggregateData.total_interaction_count}
                                  </h3>
                                  {/* <p>
                                    56.5%
                                    <span>
                                      <img src={arrowUp} alt="icon" />
                                    </span>
                                  </p> */}
                                </div>
                              </div>
                              <div className="customersCardright">
                                <img src={Chart2} alt="chat" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="lineChartCOl lineChartCOl12 m-auto">
                          <h5 style={{ color: "#04103B", padding: "0.5rem" }}>
                            Interactive Summary
                          </h5>
                          <Line
                            className="canvas"
                            data={interactiveGraphData}
                            options={options}
                            responsive={true}
                            maintainAspectRatio={false}
                          />
                        </div>

                        <div className="nafiaDataCArdsContainer">
                          <div className="row m-0">
                            <div
                              className="col-md-4"
                              style={{ paddingLeft: "0" }}
                            >
                              <div className="nafiaDataCard">
                                <h6>Average Response Time </h6>
                                <h4>
                                  {" "}
                                  {nafiaAggregateData.avg_response_time} sec
                                </h4>
                              </div>
                            </div>
                            <div
                              className="col-md-4"
                              style={{ paddingRight: "0", paddingLeft: "0" }}
                            >
                              <div className="nafiaDataCard">
                                <h6>Escalations</h6>
                                <h4>{nafiaAggregateData.escalations_count}</h4>
                              </div>
                            </div>
                            <div
                              className="col-md-4"
                              style={{ paddingRight: "0" }}
                            >
                              <div className="nafiaDataCard">
                                <h6>
                                  Knowledge Base Updation{" "}
                                  <span className="postMvp">Post MVP</span>
                                </h6>
                                <h4>005K</h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="mapCOntainer d-flex align-items-center justify-content-between mb-3">
                          <h6 className="m-0" style={{ color: "#6E6E91" }}>
                            Urgent Tickets{" "}
                          </h6>
                          <div className="px-2">
                            <hr
                              style={{
                                borderLeft: "1px solid #F1F1F4",
                                width: "2px",
                                height: "50px",
                                marginTop: "0px",
                                marginBottom: "0px",
                              }}
                            />
                          </div>
                          <h4 className="m-0">
                            {Nafiaadmindata.urgent_tickets}
                          </h4>
                        </div>
                        <div className="mapCOntainer d-flex align-items-center justify-content-between mb-3">
                          <h6 className="m-0" style={{ color: "#6E6E91" }}>
                            Renewals Account{" "}
                          </h6>
                          <div className="px-2">
                            <hr
                              style={{
                                borderLeft: "1px solid #F1F1F4",
                                width: "2px",
                                height: "50px",
                                marginTop: "0px",
                                marginBottom: "0px",
                              }}
                            />
                          </div>
                          <h4 className="m-0">
                            {Nafiaadmindata.accounts_for_renewals}
                          </h4>
                        </div>
                        <div className="mapCOntainer d-flex align-items-center justify-content-between mb-3">
                          <h6 className="m-0" style={{ color: "#6E6E91" }}>
                            New on Boardings{" "}
                          </h6>
                          <div className="px-2">
                            <hr
                              style={{
                                borderLeft: "1px solid #F1F1F4",
                                width: "2px",
                                height: "50px",
                                marginTop: "0px",
                                marginBottom: "0px",
                              }}
                            />
                          </div>
                          <h4 className="m-0">
                            {Nafiaadmindata.new_onboardings}
                          </h4>
                        </div>
                        <div className="radialChartDiv">
                          <div className="radialChartHead mt-3">
                            <h6>
                              User's Satisfaction Rating{" "}
                              <span className="postMvp">Post MVP</span>
                            </h6>
                            <p>View Details</p>
                          </div>
                          <div className="radialChartBody">
                            <div className="radialChartBodyText">
                              <div className="GoodRating">
                                <h6>Good</h6>
                                <div>
                                  <div className="goodCOlor"></div>
                                  <p>70%</p>
                                </div>
                              </div>
                              <div className="GoodRating">
                                <h6>Avarage</h6>
                                <div>
                                  <div className="AvarageCOlor"></div>
                                  <p>20%</p>
                                </div>
                              </div>
                              <div className="GoodRating">
                                <h6>Bad</h6>
                                <div>
                                  <div className="badColor"></div>
                                  <p>10%</p>
                                </div>
                              </div>
                            </div>
                            <ReactApexChart
                              options={optionsRadial}
                              series={series}
                              colors={customColors}
                              type="radialBar"
                              height="100%"
                            />
                          </div>
                        </div>

                        <div className="piechatrDiv mt-3 py-4">
                          <div className="d-flex align-items-center">
                            <div>
                              <p
                                style={{
                                  fontWeight: "500",
                                  whiteSpace: "nowrap",
                                  color: "#6E6E91",
                                  fontWeight: "500",
                                }}
                              >
                                Server Health{" "}
                                <span className="postMvp">Post MVP</span>
                              </p>
                              <div className="radialChartBodyText sevrerHeathData">
                                <div className="GoodRating">
                                  <p>Green</p>
                                  <div>
                                    <div
                                      className="goodCOlor"
                                      style={{ backgroundColor: "#43BE83" }}
                                    ></div>
                                    <p style={{ color: "#6E6E91" }}>68%</p>
                                  </div>
                                </div>
                                <div className="GoodRating">
                                  <p>Orange</p>
                                  <div>
                                    <div
                                      className="AvarageCOlor"
                                      style={{ backgroundColor: "#F7941D" }}
                                    ></div>
                                    <p style={{ color: "#6E6E91" }}>22%</p>
                                  </div>
                                </div>
                                <div className="GoodRating">
                                  <p>Red</p>
                                  <div>
                                    <div
                                      className="badColor"
                                      style={{ backgroundColor: "#EB4550" }}
                                    ></div>
                                    <p style={{ color: "#6E6E91" }}>10%</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              style={{
                                width: "100%",
                                // height: "250px",
                                position: "relative",
                              }}
                            >
                              <Doughnut
                                data={datarecovry}
                                options={optionsrecovry}
                                style={{ width: "100%" }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="nafiaADminDataTable">
                    <div className="row m-0">
                      <div className="nafiaTAbleCOntainer">
                        <table className="table">
                          <thead>
                            <tr>
                              <th scope="col">Company Name </th>
                              <th scope="col">Onboarding Date</th>
                              <th scope="col">Interactions</th>
                              <th scope="col">Avg Response Time </th>
                              <th scope="col">Escalations</th>
                            </tr>
                          </thead>
                          <tbody>{renderCompanyData()}</tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  <div className="reportPieChart">
                    <div className="reportPieChartcontainer"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NafiaAdmin;
