import React, { useState, useEffect } from "react";
import "../Css/Career.css";
import UploadCVImg from "../assets/images/uplaodCV.svg";
import "../CommonJquery/CommonJquery";
import {
  empty_form,
  check_vaild_save,
  show_popup_thanks,
  getRandomSixLetterString,
} from "../CommonJquery/CommonJquery";
import {
  URL_FOR_CAREER_SAVE,
  server_post_data,
} from "../KeyConnection/KeysService"; // Adjust the path as needed
import ModalImg from "../assets/images/modalImg.gif";

let captch_check = "";

function CareerForm() {
  const [userName, setuserName] = useState("");
  const [userEmail, setuserEmail] = useState("");
  const [userNumber, setuserNumber] = useState("");
  const [userExp, setuserExp] = useState("");
  const [userCmpny, setuserCmpny] = useState("");
  const [userCrntCtc, setuserCrntCtc] = useState("");
  const [userExpctdCtc, setuserExpctdCtc] = useState("");
  const [userNoticePeriod, setuserNoticePeriod] = useState("");
  const [userMsg, setuserMsg] = useState("");
  const [showLoaderWebsite, setshowLoaderWebsite] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    if (captch_check === "") {
      captch_check = getRandomSixLetterString();
    }
  }, []);
  const [fileName, setFileName] = useState("");

  const handleFileChange = (event) => {
    const file = event.target.files[0]; // Get the selected file
    if (file) {
      setFileName(file.name); // Set the file name in the state
    } else {
      setFileName(""); // Reset the file name if no file is selected
    }
  };

  const [userInputCpt, setUserInputCpt] = useState("");
  const [isCaptchaValid, setIsCaptchaValid] = useState(true);

  const handleSaveUserData = async (form_data, url_for_save) => {
    let vaild_data = check_vaild_save(form_data);
    if (vaild_data) {
      if (userInputCpt === captch_check) {
        setIsCaptchaValid(true);
        setshowLoaderWebsite(true);
        const body_data = JSON.stringify({
          user_name: userName,
          user_email: userEmail,
          user_no: userNumber,
          user_msg: userMsg,
          user_exp: userExp,
          user_cmpny: userCmpny,
          user_CrntCtc: userCrntCtc,
          user_ExpctdCtc: userExpctdCtc,
          user_NoticePeriod: userNoticePeriod,
          selectedFile: selectedFile,
        });
        console.log(body_data);
        await server_post_data(url_for_save, body_data)
          .then((response) => response.json())
          .then((data) => {
            setshowLoaderWebsite(false);

            if (data.status !== "error") {
              empty_form(form_data);
              show_popup_thanks("click_button");
            } else {
              alert(data.data);
            }
          })
          .catch((error) => {
            console.log("asdsa");
            setshowLoaderWebsite(false);
          });
      } else {
        setIsCaptchaValid(false);
        // alert("Please enter Correct Captcha");
      }
    }

    if (vaild_data) {
    }
  };

  const reloadWindow = () => {
    window.location.reload();
  };

  return (
    <div className="careerFormInputs container-lg mt-5">
      <div className={showLoaderWebsite ? "loading_website" : ""}></div>
      <form id="CarrerformData" className="col-lg-10 m-auto">
        <div className="row">
          <div className="inputFormCareer col-md-6">
            <div className="mb-3">
              <label htmlFor="name">
                <b>Name</b>
              </label>
              <input
                type="text"
                className="trio_mendate trio_name form-control"
                id="name"
                name="name"
                placeholder=""
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^A-Za-z\s]/g, "");
                }}
                onChange={(e) => {
                  setuserName(e.target.value);
                }}
              />
            </div>
          </div>
          <div className=" inputFormCareer col-md-6">
            <div className="mb-3">
              <label htmlFor="email">
                <b>Email Address</b>
              </label>
              <input
                type="test"
                className="trio_mendate trio_email form-control"
                id="email"
                name="email"
                onChange={(e) => {
                  setuserEmail(e.target.value);
                }}
                onInput={(e) => {
                  if (!/^[a-zA-Z0-9@.]*$/.test(e.target.value)) {
                    e.target.value = ""; // Clear the input if it contains any other character
                  }
                }}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="inputFormCareer col-md-6">
            <div className="mb-3">
              <label htmlFor="number">
                <b>Phone number</b>
              </label>
              <input
                type="text"
                className="trio_mendate trio_no form-control"
                id="number"
                name="number"
                onChange={(e) => {
                  setuserNumber(e.target.value);
                }}
                maxLength="10"
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                }}
              />
            </div>
          </div>
          <div className="inputFormCareer col-md-6">
            <div className="mb-3">
              <label htmlFor="Experience">
                <b>Experience in Years</b>
              </label>
              <input
                type="text"
                className="trio_mendate form-control"
                id="Experience"
                name="Experience"
                onChange={(e) => {
                  setuserExp(e.target.value);
                }}
                maxLength="3"
                step={2}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9.]/g, ""); // Allow only digits and decimal point
                  e.target.value = e.target.value.replace(
                    /(\d{0,3}(?:\.\d{0,2})?).*$/g,
                    "$1"
                  ); // Limit to 3 digits before the decimal and 2 digits after
                }}
              />
            </div>
          </div>
        </div>

        <div className="inputFormCareer CVuPLOAD col-md-12" hidden>
          <div className="uploadTextImg">
            <img src={UploadCVImg} alt="upload photo" />
            <div className="uploadText">
              {!fileName && (
                <>
                  <h6>UPload CV</h6>
                  <p>(Pdf. doc. PPT upto 1mb)</p>
                </>
              )}
              {fileName && <h6>{fileName}</h6>}
            </div>
          </div>
          <label htmlFor="profile_picture">Browse</label>
          <input
            className="d-none "
            type="file"
            name="profile_picture"
            id="profile_picture"
            required
            onChange={handleFileChange}
          />
        </div>

        <div className="row">
          <div className="inputFormCareer col-md-6">
            <div className="mb-3">
              <label htmlFor="company_name">
                <b>Company Name</b>
              </label>
              <input
                type="text"
                className="trio_mendate trio_name form-control"
                id="company_name"
                name="company_name"
                placeholder=""
                onChange={(e) => {
                  setuserCmpny(e.target.value);
                }}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^a-zA-Z0-9]/g, "");
                }}
              />
            </div>
          </div>
          <div className="inputFormCareer col-md-6">
            <div className="mb-3">
              <label htmlFor="Current_CTC">
                <b>Current CTC</b>
              </label>
              <input
                type="text"
                className="trio_mendate form-control"
                id="Current_CTC"
                name="Current_CTC"
                placeholder="In Lakhs"
                onChange={(e) => {
                  setuserCrntCtc(e.target.value);
                }}
                maxLength="10"
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9.]/g, ""); // Allow only digits and decimal point
                  e.target.value = e.target.value.replace(
                    /(\d{0,3}(?:\.\d{0,2})?).*$/g,
                    "$1"
                  ); // Limit to 3 digits before the decimal and 2 digits after
                }}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="inputFormCareer col-md-6">
            <div className="mb-3">
              <label htmlFor="expctd_CTC">
                <b>Expected CTC</b>
              </label>
              <input
                type="text"
                className=" trio_mendate form-control"
                id="expctd_CTC"
                name="expctd_CTC"
                placeholder="In Lakhs"
                onChange={(e) => {
                  setuserExpctdCtc(e.target.value);
                }}
                maxLength="10"
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9.]/g, ""); // Allow only digits and decimal point
                  e.target.value = e.target.value.replace(
                    /(\d{0,3}(?:\.\d{0,2})?).*$/g,
                    "$1"
                  ); // Limit to 3 digits before the decimal and 2 digits after
                }}
              />
            </div>
          </div>
          <div className="inputFormCareer col-md-6">
            <div className="mb-3">
              <label htmlFor="Notice_Period">
                <b>Notice Period</b>
              </label>
              <select
                id="Notice_Period"
                name="Notice_Period"
                className="form-select form-control trio_mendate"
                onChange={(e) => {
                  setuserNoticePeriod(e.target.value);  
                }}
              >
                <option selected>Select Days</option>
                <option value="15">15 Days</option>
                <option value="30">30 Days</option>
                <option value="45">45 Days</option>
                <option value="60">2 Months</option>
                <option value="90">3 Months</option>
              </select>
            </div>
          </div>
          <div className="inputFormCareer mb-3">
            <label htmlFor="message" className="form-label">
              <b>Your message to Hiring Manager</b>{" "}
            </label>
            <textarea
              className="trio_mendate form-control"
              id="message"
              rows="4"
              placeholder=""
              onChange={(e) => {
                setuserMsg(e.target.value);
              }}
            ></textarea>
          </div>
          <div className="inputFormCareer captchadIV col-md-12">
            <div>
              <div className="captchaText">
                <label htmlFor="captcha">Enter Captcha:</label>

                <h6>{captch_check}</h6>
              </div>
              <input
                type="text"
                className="form-control"
                id="captcha"
                name="captcha"
                onChange={(e) => {
                  setUserInputCpt(e.target.value);
                }}
                required
              />
              {isCaptchaValid ? (
                <p></p>
              ) : (
                <p className="text-danger mt-1 text-center">
                  CAPTCHA is Invalid.
                </p>
              )}
            </div>
            <button
              type="button"
              onClick={() =>
                handleSaveUserData("CarrerformData", URL_FOR_CAREER_SAVE)
              }
              className="btn btn-primary"
            >
              Submit
            </button>
            <button
              hidden
              type="button"
              id="click_button"
              data-toggle="modal"
              data-target="#exampleModalCenter1"
              className="btn btn-primary"
            >
              Submit
            </button>
          </div>
        </div>
      </form>

      <div
        className="modal fade"
        id="exampleModalCenter1"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close closeModay"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="text-center mb-2">
                <img src={ModalImg} alt="icon" />
              </div>
              Your profile has been received! We'll review it and keep an eye
              out for fantastic job opportunities that match your skills. Expect
              to hear from us soon!
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary closeFooterModal"
                data-dismiss="modal"
                onClick={reloadWindow}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CareerForm;
