import React, { useState, useRef, useEffect, useCallback } from "react";
import "../../Dasboard/Css/Dashboard.css";
import "../../Dasboard/Css/Bucket30days.css";
import "../../Dasboard/Css/SettingPage.css";
import "../../Dasboard/Css/HelpSupport.css";
import "../../Dasboard/Css/SideBar.css";
import "../../Dasboard/Css/HomePageNafia.css";
import "../../Dasboard/Css/KUKnowledgeBase.css";
import NafiaSideBar from "./NafiaSideBar.js";
import Success from "../../assets/images/success_gif.gif";
import Calender from "../../assets/images/calendar12.svg";
import searchCrawler from "../../assets/images/searchCrawl.png";
import Dltimg from "../../assets/images/Dltimg.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import InterNet from "../../assets/images/Internet.svg";
import FileIcon from "../../assets/images/file_icon.svg";
import { PhoneInput } from "react-international-phone";
import { ToastContainer, toast } from "react-toastify";
import $ from "jquery";
import moment from "moment";
import "moment/locale/en-au";
import { showErrorMsg, showSuccessMsg } from "./ToastNotifications";
import "daterangepicker";
import Technotask from "../../assets/images/TechnotaskLogo12.png";
import NafiaUserCenterNav from "./NafiaUserCenterNav.js";
import { Modal } from "react-bootstrap";
import axios from "axios";
import {
  domain_urls,
  url_scraped,
  chatboat_api,
  chatboat_api_update,
  chatboat_text_save,
} from "../../KeyConnection/KeysService.js"; // Adjust the path as needed
let setStopWebCrawl = "0";
let start_for_chatbotiddata = "";
let variable_for_test = "";

function CreateNewTicket() {
  const location = useLocation();
  const [overviewShow, setOverviewShow] = useState(true); // Default to 'true'
  const [debtorProfile, setdebtorProfile] = useState(false);
  const [userNumber, setuserNumber] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("");
  const [activeButton, setActiveButton] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showModalSave, setShowModalSave] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [characterCount, setCharacterCount] = useState(0);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const data_source = localStorage.getItem("data_source");
  const [dataArray, setDataArray] = useState([data_source]);
  const [activedatasrc, setactivedatasrc] = useState(data_source);
  const [loading, setLoader] = useState(false);
  const [linearLoader, setlinearLoader] = useState(false);
  const [showErrorModalData, setshowErrorModalData] = useState(
    "Remove data sources with no data."
  );

  // const [buttonDisabled, setButtonDisabled] = useState(false);

  const [filecharacter, setFileCharacter] = useState(0);
  const [websitecharacter, setWebsiteCharacter] = useState(0);
  const [page_found, setPage_found] = useState(0);
  const [page_crawled, setPage_crawled] = useState(0);
  const [url_characters_found, setUrl_characters_found] = useState(0);
  const [textcharacter, setTextCharacter] = useState(0);
  const [totalCharacterCount, setTotalCharacterCount] = useState(0);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [urls, setUrls] = useState([]);
  // const [stopwebcrawl, setStopWebCrawl] = useState(0);
  const [checkedBoxes, setCheckedBoxes] = useState([]);
  const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];
  const navigate = useNavigate();
  const handleInputChange = (index, e) => {
    const value = e.target.value;
    if (value.length === 1 && index < 3) {
      inputRefs[index + 1].current.focus();
    } else if (value.length === 0 && index > 0) {
      inputRefs[index - 1].current.focus();
    } else if (value.length === 0 && index === 0) {
    }
  };

  const handleKeyDown = (index, e) => {
    const input = e.target;

    if (e.key === "Backspace") {
      // If the cursor is at the beginning and not in the first input, move to the previous input
      if (input.selectionStart === 0 && index > 0) {
        inputRefs[index - 1].current.focus();
      }
    }
  };

  const handleFileSelect = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles((prevSelectedFiles) => [...prevSelectedFiles, ...files]);
  };

  function convertKBtoMB(sizeInKB) {
    const sizeInMB = sizeInKB / 1024;
    return sizeInMB.toFixed(2);
  }

  const [inputValue, setInputValue] = useState("");

  const addUrl = () => {
    if (inputValue.trim() === "") {
      alert("Please enter a URL.");
      return;
    }
    const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
    if (!urlPattern.test(inputValue.trim())) {
      alert("Please enter a URL.");
      return;
    }
    $(".crawl_btn").addClass("disabled");
    $(".save_chatboat").addClass("disabled");
    
    setLoader(true);
    setlinearLoader(true);
    //setButtonDisabled(true);
    setUrls("");
    setWebsiteCharacter(0);
    setCheckedBoxes("");
    setPage_found(0);
    setPage_crawled(0);
    setUrl_characters_found(0);
    const jsonData = {
      flg: "1",
      domain: inputValue,
    };
    console.log(jsonData);
    // https://ekonacademy.com/
    setStopWebCrawl = "0"; //crawl start
    axios.defaults.withCredentials = true;
    const csrftoken = getCookie("csrftoken");
    axios
      .post(domain_urls, jsonData, {
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrftoken, // Include the CSRF token in the header
        },
      })
      .then(function (response) {
        const data = response.data.data;
        url_showfn(data);
      })
      .catch(function (error) {
        console.log(error);
        console.error(error);
        alert("Something went wrong. Please try again later.");
        $(".crawl_btn").removeClass("disabled");
        setLoader(false);
        setlinearLoader(false);
      });
  };
  // Function to get the CSRF token from the cookie
  function getCookie(name) {
    const cookieValue = document.cookie.match(
      "(^|;)\\s*" + name + "\\s*=\\s*([^;]+)"
    );
    return cookieValue ? cookieValue.pop() : "";
  }

  const url_showfn = (data) => {
    if (setStopWebCrawl == 0) {
      const urlsArray = [
        {
          flg: "2",
          url: data.current_scraped,
          charctr: data.lengthvl,
          charctr_text: data.content,
          status: data.status,
        },
      ];

      setUrls((prevSelectedUrls) => [...prevSelectedUrls, ...urlsArray]);
      setPage_found((prevCount) => {
        const updatedCount = prevCount + 1;
        return updatedCount;
      });
      setPage_crawled((prevCount) => prevCount + 1);
      setUrl_characters_found((prevCount) => prevCount + data.lengthvl);
      if (data.next_scraped == "done") {
        $(".crawl_btn").removeClass("disabled");
        $(".save_chatboat").removeClass("disabled");
        setLoader(false);
        setlinearLoader(false);
      } else {
        //url scrapped done

        addUrl2();
      }
    } else {
      $(".crawl_btn").removeClass("disabled");
      $(".save_chatboat").removeClass("disabled");
      setLoader(false);
      setlinearLoader(false);
    }
  };

  const addUrl2 = () => {
    axios.defaults.withCredentials = true;
    axios
      .get(domain_urls)
      .then(function (response) {
        const data = response.data.data;
        url_showfn(data);
      })
      .catch(function (error) {
        console.log(error);
        console.error(error);
        $(".crawl_btn").removeClass("disabled");
        setLoader(false);
        setlinearLoader(false);
      });
  };

  const CheckUncheckBox = (index) => {
    // Check if the checkbox is already checked
    const updatedwebchr = [...urls];
    const slchk = updatedwebchr.splice(index, 1)[0]; // Remove the file at the specified index
    // console.log(slchk.charctr);
    const chtrlnts = slchk.charctr; // Get the character count of the deleted file
    if (checkedBoxes.includes(index)) {
      // Checkbox is already checked, so uncheck it
      setCheckedBoxes(checkedBoxes.filter((item) => item !== index));
      setWebsiteCharacter((prevCount) => prevCount - chtrlnts);
    } else {
      // Checkbox is unchecked, so check it
      setCheckedBoxes([...checkedBoxes, index]);
      setWebsiteCharacter((prevCount) => prevCount + chtrlnts);
    }
  };

  const countCharactersbyfile = async (event) => {
    const file = event.target.files[0];
    const extension = file.name.split(".").pop().toLowerCase();
    if (extension === "txt") {
      textfilecount(event);
    } else if (extension === "pdf") {
      //countCharacters(event);
    }
  };

  const textfilecount = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const contents = e.target.result;
        const count = contents.length;
        // filelist(event, count);
        setCharacterCount(count);
      };

      reader.readAsText(file);
    }
  };

  /* const addUrl = () => {
    if (inputValue.trim() === "") {
      alert("Please enter a URL.");
      return;
    }

    setUrls([...urls, inputValue]);
    setInputValue("");
  }; */

  const deleteUrl = (index) => {
    const updatedUrls = urls.filter((_, i) => i !== index);
    setUrls(updatedUrls);
  };

  useEffect(() => {
    // if (!data_source) {
    //   navigate("/create_chatbot_options");
    // } else {
    //datasoucecond();
    setTotalCharacterCount(filecharacter + textcharacter + websitecharacter);

    ["text", "file", "website"].forEach((className) => {
      let charactering;
      if (className === "text") {
        charactering = textcharacter;
      } else if (className === "file") {
        charactering = filecharacter;
      } else if (className === "website") {
        charactering = websitecharacter;
      }
      const dynma = "." + className + "_chractr";
      const dynma_snd = "." + className + "_cls";
      if (charactering < 11 && !$(dynma).hasClass("hidden_character")) {
        $(dynma).addClass("plain_text");
      } else {
        $(dynma).removeClass("plain_text");
      }
    });
    // }
  }, [
    filecharacter,
    textcharacter,
    websitecharacter,
    dataArray,
    data_source,
    navigate,
  ]); // Add dependencies as needed

  //Remove Data Souce
  const removedatasource = () => {
    const updatedArray = dataArray.filter((data) => data !== activedatasrc);
    handleTabClick(updatedArray[0]);
    setDataArray(updatedArray);
    // console.log('delete done');
    if (activedatasrc === "text") {
      setTextCharacter(0);
      $(".textinput").val("");
      $(".text_chractr").removeClass("plain_text");
    } else if (activedatasrc === "file") {
      setSelectedFiles("");
      setFileCharacter(0);
      $(".file_chractr").removeClass("plain_text");
    } else if (activedatasrc === "website") {
      setStopWebCrawl = "1";
      // console.log(setStopWebCrawl);
      setUrls("");
      setWebsiteCharacter(0);
      setCheckedBoxes("");
      setPage_found(0);
      setPage_crawled(0);
      setUrl_characters_found(0);
      $(".website_chractr").removeClass("plain_text");
    }
    setShowModal(false);
  };

  const datasoucecond = useCallback(() => {
    $(".comn_cls").addClass("hidden_tabs");
    $(".chr_cm_cls").addClass("hidden_character");
    $(".drop_cm_cls").removeClass("hidden_dropdown");
    if (dataArray.length === 1) {
      $(".remove_btn").addClass("disabled");
      //navigate("/create_chatbot_options");
    } else if (dataArray.length > 1) {
      $(".remove_btn").removeClass("disabled");
    }
    dataArray.forEach(function (value) {
      $(".comn_cls").removeClass("selected_tab");
      const dtsr = value + "_cls";
      $("." + dtsr).removeClass("hidden_tabs");
      //handleTabClick(value);
      charter_fnctn(value);
      const dtsr1 = value + "_drop_down";
      $("." + dtsr1).addClass("hidden_dropdown");
    });
    handleTabClick("");
  }, [dataArray, navigate]); // Add dependencies as needed

  const charter_fnctn = (value) => {
    const chrt_h = value + "_chractr";
    $("." + chrt_h).removeClass("hidden_character");
  };

  const [selectedtime, setSelectedselectedtime] = useState(null);
  const timeList = [
    { name: "This Week", code: "This Week" },
    { name: "Last Month", code: "Last Month" },
    { name: "last 3 Months ", code: "last 3 Months " },
    { name: "last 6 Months ", code: "last 6 Months " },
    { name: "last Year", code: "last Year" },
    { name: "Lifetime", code: "Lifetime" },
  ];

  const inputRef = useRef(null);

  const handleDateRangeChange = (start, end, label) => {
    // Callback function for handling date range changes
    let startDate = start.format("DD-MM-YYYY").toString();
    let endDate = end.format("DD-MM-YYYY").toString();

    // handlecallevnt(startDate, endDate);
    console.log(startDate, endDate);
  };

  useEffect(() => {
    // Call the function to fetch data when the component mounts

    const datepicker = $(inputRef.current);
    datepicker.daterangepicker(
      {
        timePicker: true,
        startDate: moment().subtract(7, "days").startOf("day"), // Set start date to 7 days ago
        endDate: moment().add(2, "days").endOf("day"), // Set end date to today
        locale: {
          format: "DD/MM/YYYY",
        },
      },
      handleDateRangeChange
    );

    // Call handleDateRangeChange on component load
    const startDate = datepicker.data("daterangepicker").startDate;
    const endDate = datepicker.data("daterangepicker").endDate;
    handleDateRangeChange(startDate, endDate);
  }, []);

  const handleDltFiles = () => {
    setSelectedFiles([]);
  };

  const handleDltUrls = () => {
    setUrls([]);
  };

  const handleModalOpen = () => {
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
    window.location.reload();
  };
  const handleButtonClick = (buttonId) => {
    setActiveButton(buttonId);
  };

  const [activeKBTab, setActiveKBTab] = useState("Website");
  const shouldRenderTabs = urls.length > 0 || selectedFiles.length > 0;

  const handleTabClick = (tab) => {
    setActiveKBTab(tab);
  };

  const save_chatboat = () => {
    const headers = {
      "Content-Type": "application/json", // Change the content type to handle file upload
    };

    let data_source_update = "";
    let stop_save = "0";

    ["text", "file", "website"].forEach((className) => {
      let charactering;
      if (className === "text") {
        charactering = textcharacter;
      } else if (className === "file") {
        charactering = filecharacter;
      } else if (className === "website") {
        charactering = websitecharacter;
      }
      const dynma = className + "_chractr";
      if (charactering >= 11) {
        if (data_source_update === "") {
          data_source_update = className;
        } else {
          data_source_update = data_source_update + "," + className;
        }
      }

      const o = $("." + dynma).hasClass("plain_text");
      if (o) {
        stop_save = "1";
      }
    });

    if (stop_save === "1") {
      setshowErrorModalData("Remove data sources with no data.");
      setShowErrorModal(true);
      return;
    }

    // Create a new FormData object
    const formData = new FormData();
    formData.append("chatboat_id", start_for_chatbotiddata);
    formData.append("data_source", data_source_update);
    formData.append("plan_types", "1");

    if (textcharacter > 0) {
      formData.append("text", "True");
      formData.append("text_content", $(".textinput").val());
    } else {
      formData.append("text", "False");
    }
    console.log(selectedFiles.length);
    if (selectedFiles.length > 0) {
      let olid = 0;
      formData.append("file", "True");
      selectedFiles.forEach((file) => {
        if (file.id == "0") {
          formData.append("files[]", file.evnt[0]);
        } else {
          console.log(file);
          const fileBlob = new Blob([JSON.stringify(file)], {
            type: "application/json",
          });
          formData.append("files_old[]", fileBlob);
        }
      });
    } else {
      formData.append("file", "False");
    }

    if (websitecharacter > 0) {
      formData.append("Web", "True");
      const datanyo = checkedBoxes.map((chkbx_lop, indexn) => {
        console.log(urls[chkbx_lop]);
        formData.append("weburl[]", JSON.stringify(urls[chkbx_lop]));
      });
    } else {
      formData.append("Web", "False");
    }
    formData.append("company_id", localStorage.getItem("company_id"));
    formData.append("user_id", localStorage.getItem("user_id"));
    console.log(formData);
    save_chatboat_final(formData);
    return "1";
    const jsonData = {};
    jsonData["content"] = $(".textinput").val();
    axios
      .post(chatboat_text_save, jsonData, headers)
      .then(function (response) {
        // console.log(response);
        // console.log(response.data);

        // setShowModalSave(true);

        // Session Storage
        if (response.data.status !== "success") {
          setLoading(false);
          $(".server_error").text(response.data.error.message);
        } else {
          const jsonDatanew = {};
          jsonDatanew["plan_types"] = "1";
          jsonDatanew["chatbot_name"] = $(".chatboat_name").val();
          jsonDatanew["customer_name"] = localStorage.getItem("user_id");
          jsonDatanew["file"] = "1";
          jsonDatanew["text"] = response.data.data.id;
          jsonDatanew["Web"] = "1";
          // console.log(jsonDatanew);
          save_chatboat_final(jsonDatanew);
        }
      })
      .catch(function (error) {
        //setShowModalSave(true);
        //console.error(error);
        /* if(error.response.data.msg){
            $('.server_error').text(error.response.data.msg);
            }else{
                $('.server_error').text(error.message);
            } */
        // Handle the error here
        setLoading(false);
        // You can display an error message or perform any necessary actions
      });
  };

  const save_chatboat_final = (jsonDatanew) => {
    // const headers = {
    //   "Content-Type": "application/json", // Change the content type to handle file upload
    // };
    // setLoading(true);
    setLoading(true);
    
    axios.defaults.withCredentials = true;
    const csrftoken = getCookie("csrftoken");
    axios
      .post(chatboat_api_update, jsonDatanew, {
        headers: {
          "X-CSRFToken": csrftoken, // Include the CSRF token in the header
        },
      })
      .then(function (response) {
        setLoading(false);
        if (response.data.status != "success") {
          showErrorMsg(response.data.message);
        } else {
          //showSuccessMsg();
          //setShowSuccessModal(true);
          handleModalOpen();
          // window.location.reload();
          //navigate('/my_chatbots/'+start_for_chatbotiddata+'/update_knowledge');
        }
      })
      .catch(function (error) {
        showErrorMsg(error.message);
        setLoading(false);
      });
  };

  return (
    <div className="dashboard">
      <div className="dashboard_container">
        <div className="dashboard_wrapper">
          <NafiaSideBar
            overviewShow={overviewShow}
            setOverviewShow={setOverviewShow}
            debtorProfile={debtorProfile}
            setdebtorProfile={setdebtorProfile}
          />
          <div id="content">
            <NafiaUserCenterNav />
            <div className="overviewContaINER">
              <div className="overviewHead overviewHeaddp ">
                <h4 style={{ color: "#343A40" }}>Knowledge Update</h4>
                <div className="overviewHeadLeft">
                  <div className="overviewHeadLeftContn">
                    <div className="date_filter_div">
                      <div className="date_filter">
                        <div className="calender_icon">
                          <label
                            style={{ cursor: "pointer" }}
                            htmlFor="datepicker"
                          >
                            <img src={Calender} alt="Image" />
                          </label>
                        </div>
                        <input
                          id="datepicker"
                          className="form-control form-control-solid"
                          placeholder="Pick date range"
                          ref={inputRef}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="knowUpdHead">
                <div className="col-xl-8 col-lg-10">
                  <div className="knowUpdHeadContainer">
                    <div
                      className={`headsOfKU ${
                        location.pathname === "/Knowledge"
                          ? "activeheadsOfKU"
                          : ""
                      }`}
                    >
                      <Link to="/Knowledge">
                        <h6>KB Update</h6>
                      </Link>
                    </div>
                    <div className="headsOfKU">
                      <Link to="/KnowledgeBase">
                        <h6>Knowledge Base</h6>
                      </Link>
                    </div>
                    <div className="headsOfKU">
                      <Link to="/KBhealth">
                        <h6>
                          KB Health &nbsp;
                          {/* <span className="postMvp">Post MVP</span> */}
                        </h6>
                      </Link>
                    </div>

                    <div className="headsOfKU">
                      <h6>
                        Manage KBS &nbsp;
                        {/* <span className="postMvp">Post MVP</span> */}
                      </h6>
                    </div>
                    <div className="headsOfKU">
                      <h6>
                        KB History &nbsp;
                        {/* <span className="postMvp">Post MVP</span> */}
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="knldgUpdateContainer">
              <div className="col-xl-10">
                <div className="crwalingDiv">
                  <div className="URLSercher">
                    <div className="urlText">
                      <p>website url:</p>
                      <div className="webInputDiv">
                        <img src={InterNet} alt="icon" />
                        <input
                          type="url"
                          value={inputValue}
                          onChange={(e) => setInputValue(e.target.value)}
                          placeholder="www.XYZCompany.in"
                        ></input>
                      </div>
                    </div>
                    <button
                      className={`btnCrawler crawl_btn button_crown ${
                        inputValue.trim() === "" ? "disabledbtnCrawler" : ""
                      }`}
                      onClick={addUrl}
                    >
                      {loading ? (
                        <div className="loader_round"></div>
                      ) : (
                        <>
                          <span>
                            <img src={searchCrawler} alt="icon" />
                          </span>
                          <p>Crawl</p>
                        </>
                      )}
                    </button>
                  </div>
                  <div className="addFILEBtnDiv d-flex align-items-end">
                    <span className="postMvp" style={{ height: "fit-content" }}>
                      Post MVP
                    </span>
                    &nbsp;
                    <label htmlFor="add_file" className={`addFILEBtn`}>
                      Add File
                    </label>
                    <input
                      id="add_file"
                      multiple
                      onChange={handleFileSelect}
                      type="file"
                      hidden
                    />
                  </div>
                </div>
                {shouldRenderTabs && (
                  <div className=" urlAndFileTabs">
                    <div className="urlAndFileTabsContainer">
                      {urls.length > 0 && (
                        <div
                          className={`urlTab ${
                            activeKBTab === "Website" ? "activeKBTab" : ""
                          }`}
                          onClick={() => handleTabClick("Website")}
                        >
                          <p>Website KB</p>
                        </div>
                      )}
                      {selectedFiles.length > 0 && (
                        <div
                          className={`urlTab ${
                            activeKBTab === "File" ? "activeKBTab" : ""
                          }`}
                          onClick={() => handleTabClick("File")}
                        >
                          <p>File KB</p>
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {urls.length == 0 && selectedFiles.length == 0 && (
                  <div className="nodataInput">
                    <h6 style={{ color: "#DA0E0E" }}>
                      Input company URL or Add Files to update knowledge.
                    </h6>
                  </div>
                )}
                {/* URL Crwal Design */}
                {urls.length > 0 && activeKBTab === "Website" && (
                  <>
                    <div className="crwalTableWrapper">
                      <div className="crwalTableContainer">
                        <div className="crwalTable addFileTableContainer2">
                          {linearLoader && <div className="moving-color"></div>}
                          <table>
                            <thead>
                              <tr>
                                <th>
                                  <div className="firstCol"></div>
                                </th>
                                <th>
                                  <div>
                                    <p>PAGE URL</p>
                                  </div>
                                </th>
                                <th>
                                  <div>
                                    <p>STATUS</p>
                                  </div>
                                </th>
                                <th>
                                  <div>
                                    <p>CHARACTER</p>
                                  </div>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {urls.map((url, index) => (
                                <tr key={index}>
                                  <td>
                                    {url.status == "200" ? (
                                      <div className="tdFIrstCol">
                                        <input
                                          type="checkbox"
                                          checked={checkedBoxes.includes(index)}
                                          onChange={() =>
                                            CheckUncheckBox(index)
                                          }
                                        />
                                      </div>
                                    ) : (
                                      <input type="checkbox" disabled />
                                    )}
                                  </td>
                                  <td>
                                    <div>
                                      <p>{url.url}</p>
                                    </div>
                                  </td>

                                  <td>
                                    {url.status == "200" ? (
                                      <div className="processed">
                                        <p className="statusSuccess">200</p>
                                        {/* <p className="statusError">500 Error</p> */}
                                      </div>
                                    ) : (
                                      <div className="notprocessed">
                                        <p>500</p>
                                      </div>
                                    )}
                                  </td>
                                  <td>
                                    <div>
                                      <p style={{ color: "#868e96" }}>
                                        {url.charctr}
                                      </p>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                        <div id="bottom-section" className="urlFOundtxt">
                          <p>
                            <span id="totalUrls">{page_found}</span> PAGES FOUND
                          </p>
                          <p>
                            <span id="text1">{page_crawled}</span> PAGES CRAWLED
                          </p>
                          <p>
                            <span id="text2">{url_characters_found}</span>{" "}
                            CHARACTER
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="dataSource">
                      <button className="RmvDataSource" onClick={handleDltUrls}>
                        <div className="dltimg">
                          <img src={Dltimg} />
                        </div>
                        <div
                          className="DStxt remove_btn"
                          onClick={handleModalOpen}
                        >
                          <p>Remove this Data Source</p>
                        </div>
                      </button>
                    </div>

                    <div className="TTLcharacter">
                      <div className="TTLtxt">
                        <p>Total Characters: {totalCharacterCount}</p>
                      </div>
                      <div
                        className="UPDationbtn update_knowledge_btn save_chatboat"
                        onClick={save_chatboat}
                      >
                        <button>
                          <h5>Update Knowledge</h5>
                        </button>
                      </div>
                    </div>
                  </>
                )}

                {/* Added File Design */}
                {selectedFiles.length > 0 && activeKBTab === "File" && (
                  <div className="addFileTableWrapper">
                    <div className="addFileTableContainer">
                      <div className="col-11 mx-auto">
                        <div className="addFileTableHead">
                          <div className="addFileTableHeadLeft">
                            <img src={FileIcon} alt="icon" />
                            <h4>File</h4>
                          </div>
                          <div className="addFileTableHeadRight">
                            <h5>KB Name</h5>
                            <input type="text" />
                            <select
                              style={{ cursor: "pointer" }}
                              name="category"
                              id="category"
                            >
                              <option value="">Category</option>
                              <option value="category1">Category 1</option>
                              <option value="category2">Category 2</option>
                              <option value="category3">Category 3</option>
                            </select>
                          </div>
                        </div>
                        <div className="addFileTable">
                          <div className="addFileTableContainer2">
                            <table className="scrollableBody">
                              <thead>
                                <tr>
                                  <th>
                                    <div className="filefirstCol">
                                      <p>NAME</p>
                                    </div>
                                  </th>
                                  <th>
                                    <div>
                                      <p>SIZE</p>
                                    </div>
                                  </th>
                                  <th>
                                    <div>
                                      <p>CHARACTERS</p>
                                    </div>
                                  </th>
                                  <th>
                                    <div className="filelastCol">
                                      <p>STATUS</p>
                                    </div>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {selectedFiles.map((file, index) => (
                                  <tr key={index}>
                                    <td>
                                      <div>
                                        <p>{file.name}</p>
                                      </div>
                                    </td>
                                    <td>
                                      <div>
                                        <p>{convertKBtoMB(file.size)} MB</p>
                                      </div>
                                    </td>
                                    <td>
                                      <div>
                                        <p>1837</p>
                                      </div>
                                    </td>
                                    <td>
                                      <div>
                                        <p className="processeDone">
                                          Processed
                                        </p>
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <div className="updateByFile">
                          <div className="updateByFileContainer">
                            <form>
                              <div className="updatebyContainer">
                                <div className="updateByInput">
                                  <h6>Updated By</h6>
                                  <input type="text" />
                                </div>
                                <div className="updateByphone">
                                  <div className="updateByphoneinput">
                                    <h6>Phone No.</h6>
                                    <div className="updateByphoneinputContainr">
                                      <PhoneInput
                                        id="phone"
                                        name="phone"
                                        defaultCountry="ae"
                                        value={userNumber}
                                        onChange={(phone) =>
                                          setuserNumber(phone)
                                        }
                                      />
                                      <p>Get OTP</p>
                                    </div>
                                  </div>
                                  <div className="getOTP">
                                    {[0, 1, 2, 3].map((index) => (
                                      <input
                                        key={index}
                                        ref={inputRefs[index]}
                                        type="text"
                                        maxLength="1"
                                        pattern="\d"
                                        inputMode="numeric"
                                        required
                                        onChange={(e) =>
                                          handleInputChange(index, e)
                                        }
                                        onKeyDown={(e) =>
                                          handleKeyDown(index, e)
                                        }
                                      />
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                          <div className="updateBtns">
                            <div className="updateBtnsContaienr">
                              <div className="updateBtnsLeft">
                                <h5 style={{ color: "#DA0E0E" }}>File: 02</h5>
                                <h5>Total Characters: 495</h5>
                                <button
                                  className="RmvDataSource p-0"
                                  onClick={handleDltFiles}
                                >
                                  <div className="dltimg">
                                    <img src={Dltimg} />
                                  </div>
                                  <div className="DStxt">
                                    <p>Remove this Data Source</p>
                                  </div>
                                </button>
                              </div>
                              <div className="updateBtnsRight">
                                <div className="UPDationbtn">
                                  <button>
                                    <h5>Update Knowledge</h5>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="brandingContainer">
              <div>
                <h5>Powered by</h5>
                <img src={Technotask} alt="Logo" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
                className={isLoading ? "loader-overlay show" : "loader-overlay"}
              >
                <div className="loader23"></div>
              </div>
      <Modal
        id="myModal"
        show={showModal}
        onHide={handleModalClose}
        centered
        backdrop="static"
      >
        <Modal.Body className="modal_body">
          <div className="success_img d-flex justify-content-center">
            <img src={Success} alt="icon" />
          </div>
          <p>Your Knowledge Has Been Updated Successfully. </p>
        </Modal.Body>
        <Modal.Footer>
          <div className="btn go_to_login" onClick={handleModalClose}>
            Close
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default CreateNewTicket;
