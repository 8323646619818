import React, { useState } from "react";
import "../../Dasboard/Css/Dashboard.css";
import "../../Dasboard/Css/Bucket30days.css";
import "../../Dasboard/Css/SettingPage.css";
import CircleCheck from "../../assets/images/circle_check.svg";
import NotificationsImg from "../../assets/images/notification.svg";
import SideBarControl from "../../assets/images/rightLeftSIdebar.png";
import UserImgPrf from "../../assets/images/userimg45.svg";
import adm_img from "../../assets/images/adm_img.png";
import "react-circular-progressbar/dist/styles.css";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import userBtn from "../../assets/images/usermngmt.svg";
import invBtn from "../../assets/images/invoicee.svg";
import invBtnWhite from "../../assets/images/invoiceeBlue.svg";
import subsButton from "../../assets/images/subscriptions.svg";
import RightaRROW from "../../assets/images/rightmorethan.svg";
import RightaRROWWhite from "../../assets/images/rightmorethanblue.svg";
import { Link, useLocation } from "react-router-dom";
import subsButtonWhite from "../../assets/images/subscriptionsblue.svg";
import Logout from "../../assets/images/giflogout.gif";
import Technotask from "../../assets/images/TechnotaskLogo12.png";
import SettingsSideBarAmra from "./SettingsSideBarAmra.js";
import NAfiaAdminSideBar from "./AmraAdminSideBar";
import CenterNavAmraAdmin from "./CenterNavAmraAdmin";

function AmraAdminInvoice() {
  const [overviewShow, setOverviewShow] = useState(true); // Default to 'true'
  const [debtorProfile, setdebtorProfile] = useState(false);
  const location = useLocation();
  return (
    <div className="dashboard">
      <div className="dashboard_container">
        <div className="dashboard_wrapper">
          <NAfiaAdminSideBar />
          <div id="content">
            <CenterNavAmraAdmin />

            <div className="AmraConversations">
              <div className="AmraConversations_container">
                <div className="AmraConversations_container_wrapper">
                  <div className="overviewContaINER pb-0"></div>
                  <div className="subscript">
                    <div className="row m-0 justify-content-center">
                      <div className="col-lg-4 col-md-5 column1 pt-0">
                        <SettingsSideBarAmra />
                      </div>
                      <div className="col-xl-4 col-lg-5 col-md-5 column2">
                        <h5 className="mb-3">
                          <b>Invoice</b>
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AmraAdminInvoice;
