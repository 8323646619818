import React, { useState, useEffect } from "react";
import pymtIcon from "../../assets/images/pymtIcon23.svg";
import redirectvector from "../../assets/images/redirectvector.png";
import { Link } from "react-router-dom";
import removeIcon from "../../assets/images/removeIcon23.svg";
import AddIcon from "../../assets/images/addIcon23.svg";
import UserProfileCompleteSideBar from "../../Dasboard/Componets/UserProfileCompleteSideBar";
import People from "../../assets/images/People.svg";
import Modal from "react-bootstrap/Modal";
import { Dropdown } from "primereact/dropdown";
import Technotask from "../../assets/images/TechnotaskLogo12.png";
import Logout from "../../assets/images/giflogout.gif";
import NAfiaAdminSideBar from "./AmraAdminSideBar";
import CenterNavAmraAdmin from "./CenterNavAmraAdmin";

import {
  server_get_data_master,
  server_patch_data_master,
  URL_FOR_TICKETS,
  URL_FOR_SINGLE_TICKET,
  URL_FOR_TICKET_CONVERSATION,
} from "../../KeyConnection/KeysService"; // Adjust the path as needed
import SettingsSideBarAmra from "./SettingsSideBarAmra";

function AmraAdminSettings() {
  const [isLoading, setLoading] = useState(false);
  const [filterValue, setfilterValue] = useState("");
  const [userName, setuserName] = useState("");
  const [userNumber, setuserNumber] = useState("");
  const [overviewShow, setOverviewShow] = useState(true); // Default to 'true'
  const [debtorProfile, setdebtorProfile] = useState(false);
  const [selectedpriority, setSelectedpriority] = useState(null);

  // const ticketList = [
  //   {
  //     status: "onGoingTicket",
  //     ticketNo: "2023-CS130",
  //     ticketTime: "12:59 AM",
  //     ticketFrom: "James Smith",
  //     ticketImg: People,
  //     ticketQues:
  //       "I need detailed information about the debts listed under my name. Can I get a statement or breakdown?",
  //     ticketRply:
  //       "Certainly, we've generated a detailed statement of your outstanding debts, including principal amounts, interest charges, and due dates. You should receive it on your registered email shortly.",
  //   },
  //   {
  //     status: "NewTicket",
  //     ticketNo: "2023-CS123",
  //     ticketTime: "12:59 AM",
  //     ticketFrom: "James Smith",
  //     ticketImg: People,
  //     ticketQues:
  //       "I need detailed information about the debts listed under my name. Can I get a statement or breakdown?",
  //     ticketRply: "",
  //   },
  //   {
  //     status: "ResolvedTicket",
  //     ticketNo: "2023-CS128",
  //     ticketTime: "12:59 AM",
  //     ticketFrom: "James Smith",
  //     ticketImg: People,
  //     ticketQues:
  //       "I need detailed information about the debts listed under my name. Can I get a statement or breakdown?",
  //     ticketRply:
  //       "Certainly, we've generated a detailed statement of your outstanding debts, including principal amounts, interest charges, and due dates. You should receive it on your registered email shortly.",
  //   },
  // ];

  return (
    <div className="dashboard">
      <div className="dashboard_container">
        <div className={isLoading ? "loader-overlay show" : "loader-overlay"}>
          <div className="loader23"></div>
        </div>
        <div className="dashboard_wrapper">
          <NAfiaAdminSideBar />
          <div id="content">
            <CenterNavAmraAdmin />
            <div className="AmraConversations">
              <div className="AmraConversations_container">
                <div className="AmraConversations_container_wrapper">
                  <div className="overviewContaINER pb-0">
                    {/* <div className="col-md-8 overviewHead overviewHeaddp mb-1">
                      <h4>Settings</h4>
                    </div> */}
                  </div>
                  <div className="subscript">
                    <div className="row m-0 justify-content-center">
                      <div className="col-lg-4 col-md-5 column1 pt-0">
                        <SettingsSideBarAmra />
                      </div>
                      <div className="col-xl-4 col-lg-5 col-md-5 column2">
                        <h5 className="mb-3">
                          <b>Subscriptions</b>
                        </h5>
                        <div className="currentPlan">
                          <p className="crntPlanHead">CURRENT PLAN</p>
                          <hr
                            className="mt-1"
                            style={{ borderTop: "2px solid" }}
                          />
                          <p className="teamPack">Team Package</p>
                          <h6 className="perMonth">$10 per month</h6>
                          <div className="d-flex" style={{ gap: "1rem" }}>
                            <Link to="/BillingForm">
                              <button className="upgbtn">Upgrade plan</button>
                            </Link>
                            <button className="canbtn">Cancel plan</button>
                          </div>
                          <p className="planRenew">
                            Your plan renews on October 1, 2021
                          </p>
                        </div>

                        <div className="pymtMethod">
                          <p className="crntPlanHead">PAYMENT METHOD</p>
                          <hr
                            className="mt-1"
                            style={{ borderTop: "2px solid" }}
                          />
                          <div className="paymentmthdContainer">
                            <div className="pymtmthdOld">
                              <img src={pymtIcon} alt="icon" />
                              <div className="expiryDiv">
                                <p>Expires 04/2022</p>
                                <img src={removeIcon} alt="icon" />
                              </div>
                            </div>
                            <div className="pymtmthdNew">
                              <button>
                                <img src={AddIcon} alt="icon" />
                                <p>Add payment method</p>
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className="billHistory">
                          <hp className="crntPlanHead">BILLING HISTORY</hp>
                          <hr
                            className="mt-1"
                            style={{ borderTop: "2px solid" }}
                          />

                          <table className="custom-table">
                            <tbody>
                              <tr>
                                <td>
                                  <div className="dateDivRow">
                                    <p>2023-11-14</p>
                                    <img src={redirectvector} />
                                  </div>
                                </td>
                                <td>
                                  <div>
                                    <p>$10</p>
                                  </div>
                                </td>
                                <td>
                                  <div>
                                    <p>Team Package</p>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="dateDivRow">
                                    <p>2023-11-14</p>
                                    <img src={redirectvector} />
                                  </div>
                                </td>
                                <td>
                                  <div>
                                    <p>$10</p>
                                  </div>
                                </td>
                                <td>
                                  <div>
                                    <p>Team Package</p>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="dateDivRow">
                                    <p>2023-11-14</p>
                                    <img src={redirectvector} />
                                  </div>
                                </td>
                                <td>
                                  <div>
                                    <p>$10</p>
                                  </div>
                                </td>
                                <td>
                                  <div>
                                    <p>Team Package</p>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AmraAdminSettings;
