import React, { useState, useEffect, useRef } from "react";
import "../Css/AmraScreen.css";
import RobotC from "../assets/images/Robot C.png";
import RobotCV from "../assets/images/amra002.gif";
import SteveB from "../assets/images/Steve B.png";
import SteveBV from "../assets/images/steve02.gif";
import newamraVi from "../assets/video/amranew66.mp4";
import LogoCom from "../assets/images/logoNew.png";
import { uid } from "uid";
import Logo from "../assets/images/logoNew.png";
import axios from "axios";
import {
  URL_FOR_LINK_FARIDA,
  URL_FOR_TOKEN_SEND,
  keywords_links_complete,
  keywords_hello,
  server_post_data,
  server_get_data_master,
} from "../KeyConnection/KeysService.js"; // Adjust the path as needed
import {
  changelanguage,
  check_vaild_save,
} from "../CommonJquery/CommonJquery.js";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import LoadrerTechnoTask from "./LoaderAnimation.js";
import { Helmet } from "react-helmet";
let language_for = "en-XA"; //englishh
let language_no = 0;
// Variables for server communication and flags
let start_server = true;
let uid_for_person = "";
let uid_for_person_fixed = "";
let front_website_word = "";

let flag_for_start = "1";

//timer flag
let isTimerRunning = false;
let unable_to_pay_load = false;
let start_audio = true;

let debtor_name = "";
let debtor_email = "";
let debtor_no = "";
let debtor_bucket = "";
let debtor_gender = "";
let response_book_for = 0;
let response_repeat = 0;
let emirates_id = "0";
let location_id = "0";
let hospital_id = "0";
let department_id = "0";
let doctor_id = "0";
let final_date_time = "0";
function FaridaScreen() {
  const audioRef = useRef(null);

  // State variable to manage the visibility of the Loading
  const [showLoader, setshowLoader] = useState(false);
  const [showSpeaker, setshowSpeaker] = useState(false);
  const [showMic, setshowMic] = useState(false);
  const [laodingScreen, setlaodingScreen] = useState(false);
  const [startAmra, setstartAmra] = useState(false);
  const [startAmra1, setstartAmra1] = useState(true);
  const [timer, setTimer] = useState(300);
  const [name_show, setname_show] = useState("Shameer");
  const [tokenno, settokenno] = useState(0);
  const [showLoaderWebsite, setshowLoaderWebsite] = useState(false);
  // Loading screen
  const { finalTranscript, resetTranscript, transcript } =
    useSpeechRecognition();

  const [isPlaying, setIsPlaying] = useState(false);
  const videoControl = true; // Set this variable to true or false as needed
  const videoRef = useRef(null);

  useEffect(() => {
    let interval;
    if (isTimerRunning) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
        console.log(timer);
      }, 1000);
    } else {
      clearInterval(interval);
    }

    // Check if the timer has reached 0
    if (timer < 2 && isTimerRunning) {
      handleTimerComplete();
    }
    return () => {
      clearInterval(interval);
    };
  }, [
    timer,
    isTimerRunning,
    showLoader,
    showSpeaker,
    showMic,
    startAmra,
    debtor_name,
    name_show,
    isPlaying,
  ]);

  const start_with_language = (language_word, no_langaus, ttsVoice_no) => {
    setstartAmra1(false);
    setlaodingScreen(true);
    language_no = no_langaus;
    if (language_word === "") {
      isTimerRunning = false;
    } else {
      setTokenBtn(true);
      language_for = language_word;
      first_time_function();
    }
  };

  const first_time_function = () => {
    if (uid_for_person_fixed === "") {
      uid_for_person = uid(25);
    } else {
      uid_for_person = uid_for_person_fixed;
    }
    flag_for_start = "1";
    setTimeout(() => {
      startSpeechRecognition(keywords_hello[language_no]);
    }, 2000);
  };

  // Function to start the timer
  const startTimer = () => {
    isTimerRunning = true;
    setTimer(30); // Reset the timer to 30 seconds when starting
  };

  // Function to perform an action when the timer completes
  const handleTimerComplete = () => {
    isTimerRunning = false;
    flag_for_start = "150";
    // Do something when the timer completes
    start_audio = true;

    endAudio(keywords_links_complete[language_no], false, true);
  };

  const webkitSpeechRecognition = () => {
    isTimerRunning = true;
    resetTranscript();
    SpeechRecognition.startListening({
      continuous: true,
      language: language_for,
    });
  };

  const stopwebkitSpeechRecognition = () => {
    isTimerRunning = false;
    SpeechRecognition.stopListening();
    resetTranscript();
    clearTimeout(null); // Clear the timeout if recognition is manually stopped
  };

  //when audio video middleplay play middle script data
  const middle_audio_video_link = (
    currentAudioTime,
    audioDuration,
    finish_loop
  ) => {
    //show_speech = true;
  };

  //when audio video end play end script data
  const end_audio_video_link = (end_audio) => {
    // When audio ends, set someone_speaking to true and enable speech recognition
    start_server = true;
    setIsPlaying(false);
    if (end_audio) {
      window.location.reload();
    }
    start_audio = true;
    show_loader_mic_speaker(false, true, false);
  };

  //shubham jain  code

  // Function to start speech recognition and handle user input
  const startSpeechRecognition = (word) => {
    if (word !== "") {
      conversationalTalk(word, flag_for_start);
    }
  };

  //shubham jain  code
  // Function to play audio and trigger speech recognition when it ends
  const endAudio = (musicLink, music_text, end_audio) => {
    if (start_audio) {
      start_audio = false;
      let only_one_time = true;
      try {
        if (audioRef.current) {
          audioRef.current.src = musicLink;
          audioRef.current.play();
          isTimerRunning = false;

          try {
            audioRef.current.addEventListener("timeupdate", () => {
              // Calculate the delay to execute the code block 3 seconds before the audio ends
              //loader,mic,specker
              show_loader_mic_speaker(false, false, true);
              let finish_loop = true;
              isTimerRunning = false;
              if (audioRef.current) {
                const currentAudioTime = audioRef.current.currentTime;
                const audioDuration = audioRef.current.duration;
                middle_audio_video_link(
                  currentAudioTime,
                  audioDuration,
                  finish_loop
                );
              }
            });
          } catch (error) {
            console.error("Error removing timeupdate event listener:", error);
          }

          audioRef.current.addEventListener("ended", () => {
            if (only_one_time) {
              only_one_time = false;
              end_audio_video_link(end_audio);
            }
          });
        }
      } catch {
        start_audio = true;
        start_with_language("", 0, "");
      }
    }
  };

  const show_loader_mic_speaker = (for_loader, for_mic, for_speaker) => {
    setshowLoader(for_loader);
    setshowMic(for_mic);
    setshowSpeaker(for_speaker);

    setIsPlaying(for_speaker);
    if (for_speaker) {
      const videoElement = videoRef.current;

      if (videoControl) {
        if (!isPlaying && videoElement) {
          videoElement.play();
        }
      } else {
        setIsPlaying(false);
        if (videoElement) {
          videoElement.pause();
          videoElement.currentTime = 0;
        }
      }
    }

    if (for_mic === true) {
      startTimer();
      webkitSpeechRecognition();
    }
  };

  const conversationalTalk = (userMessage, flag) => {
    stopwebkitSpeechRecognition();

    if (start_server) {
      //loader, mic, speaker
      show_loader_mic_speaker(true, false, false);
      start_server = false;

      // Send a request to the server with user input
      let lauage_word = changelanguage(language_for);
      const body_data = JSON.stringify({
        content_txt: userMessage,
        language_used: lauage_word,
        uid_id: uid_for_person,
        front_website: front_website_word,
        front_flag: flag,
        debtor_name: debtor_name,
        debtor_email: debtor_email,
        debtor_no: debtor_no,
        debtor_bucket: debtor_bucket,
        debtor_gender: debtor_gender,
        unable_to_pay: unable_to_pay_load,
        repeat: response_repeat,
        book_for: response_book_for,
        emirates_id: emirates_id,
        location_id: location_id,
        hospital_id: hospital_id,
        department_id: department_id,
        doctor_id: doctor_id,
        final_date_time: final_date_time,
        debtor_mobile_no: tokenno,
      });

      console.log(body_data);
      const api_key_vl =
        "fcf54f3d7e127f96925ac128adf86ef414029efa2950a37001f7b624bf2f159cde";
      const headers = {
        Authorization: `Bearer ${api_key_vl}`,
        "Content-Type": "application/json", // Change the content type to handle file upload
      };
      axios
        .post(URL_FOR_LINK_FARIDA, body_data, { headers: headers })
        //server_post_data(URL_FOR_LINK_FARIDA, body_data,{ headers: headers })
        //server_post_data("http://127.0.0.1:7998/api/dashboard/debtor_qrcode/", body_data,{ headers: headers })
        .then((response) => response)
        .then((data) => {
          //loader, mic, speaker
          show_loader_mic_speaker(false, false, true);
          if (startAmra === false) {
            setstartAmra(true);
            setlaodingScreen(false);
            let timerload = 3; // Initialize timer (adjust as needed)
            let interval_load = setInterval(() => {
              console.log(timerload); // Debugging: Check the timer value
              timerload -= 1;
              if (timerload < 1) {
                clearInterval(interval_load); // Clear the timer
                server_get_data("normal", data.data);
              }
            }, 1000);
          } else {
            server_get_data("normal", data.data);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          //loader, mic, speaker
          show_loader_mic_speaker(false, false, true);
          webkitSpeechRecognition();
          start_server = true;
        });
    }
  };

  // Function to handle server response
  const server_get_data = (fronm_where, data) => {
    console.log(fronm_where, data);
    let audio_off = false;
    flag_for_start = data.back_flag;
    let audio_file = data.file_path;
    unable_to_pay_load = data.unable_to_pay;
    response_repeat = 0;
    response_book_for = 0;
    if (data.repeat) {
      response_repeat = data.repeat;
    }
    if (data.book_for) {
      response_book_for = data.book_for;
    }

    if (flag_for_start === "150") {
      //stop the converstion
      audio_off = true;
    }
    endAudio(audio_file, false, audio_off);
  };

  const [tokenBtn, setTokenBtn] = useState(false);

  // const handleSaveUserData = async (form_data, url_for_save) => {
  //   let vaild_data = check_vaild_save(form_data);
  //   setTokenBtn(true);
  //   if (vaild_data) {
  //     if (start_server) {
  //       setshowLoaderWebsite(true);
  //       start_server = false;
  //       const body_data = JSON.stringify({
  //         token_id: tokenno,
  //       });
  //       let link_url = url_for_save + tokenno;
  //       console.log(body_data);
  //       server_get_data_master(link_url, body_data)
  //         .then((response) => response.json())
  //         .then((data) => {
  //           setshowLoaderWebsite(false);
  //           start_server = true;
  //           if (data.data === "error") {
  //             alert(data.data);
  //           } else {
  //             const closeButton = document.querySelector(
  //               "#" + form_data + ' [data-dismiss="modal"]'
  //             );

  //             if (closeButton) {
  //               closeButton.click();
  //             }

  //             debtor_name = data.data.debtor_name;
  //             debtor_email = data.data.debtor_email;
  //             debtor_no = data.data.debtor_no;
  //             debtor_bucket = data.data.debtor_bucket;
  //             debtor_gender = data.data.debtor_gender;
  //             uid_for_person_fixed = data.data.custom_otp;

  //             emirates_id=data.data.emirates_id;
  //             location_id=data.data.location_id;
  //             hospital_id=data.data.hospital_id;
  //             department_id=data.data.department_id;
  //             doctor_id=data.data.doctor_id;
  //             final_date_time=data.data.final_date_time;

  //             setname_show(debtor_name);
  //           }
  //         })
  //         .catch((error) => {
  //           setshowLoaderWebsite(false);
  //           start_server = true;
  //           console.error("Error:", error);
  //           //loader, mic, speaker
  //           show_loader_mic_speaker(false, false, true);
  //           webkitSpeechRecognition();
  //           start_server = true;
  //         });
  //     }
  //   }
  // };

  const handleSaveUserData = () => {
    const closeButton = document.querySelector(
      '#QRFaqFormData [data-dismiss="modal"]'
    );
    if (closeButton) {
      closeButton.click();
    }
  };

  return (
    <div className="amrascreen">
      <Helmet>
        <title>L B S Hospital</title>
        <meta
          name="description"
          content="Compliant and Effortless AI Voice Solutions for Streamlined L B S Hospital."
        />
      </Helmet>
      <div className="amrascreen_container">
        <div className={showLoaderWebsite ? "loading_website" : ""}></div>
        <div
          className={`amrascreen_wrapper amra_screen_color ${
            startAmra ? "amrascreen_wrapperFlex" : " "
          }`}
        >
          {startAmra1 === true && (
            <>
              <div className={`tokenBtnAmra `}>
                <button
                  className="startAmraBtn startAmraBtn1"
                  data-toggle="modal"
                  data-target="#helpcenter"
                >
                  Enter Mobile No.
                </button>
              </div>
              <div className="mainlogoAmra">
                <img src={Logo} alt="icon" />
              </div>
              <div className="startAmraDiv">
                <div className="amrascreen_wrapperHead">
                  <h1>LBS Hospital Voice AI Solution</h1>
                </div>
                <div className="clientImg01 col-lg-2 col-md-3 col-sm-4 col-6  m-auto">
                  <img src={SteveB} alt="icon" />
                </div>

                <div className="btnALngDiv">
                  <button
                    className="startAmraBtn"
                    onClick={() => start_with_language("en-XA", 0, "")}
                  >
                    <span className="button__label">English</span>
                  </button>
                  {/* hi-IN   1*/}
                  <button
                    className="startAmraBtn hidetokenbtn"
                    onClick={() => start_with_language("hi-XA", 1, "")}
                  >
                    <span className="button__label">हिंदी</span>
                  </button>
                  {/* ar-SA   2*/}
                  <button
                    className="startAmraBtn hidetokenbtn"
                    onClick={() => start_with_language("ar-XA", 2, "")}
                  >
                    <span className="button__label">عربي</span>
                  </button>
                </div>
                <div className="bottomAmraText">
                  <h6>
                    Hello, my name is Riya and I'm your virtual assistant at
                    L B S Hospital.
                    <br />
                    How can i assist you today?
                  </h6>
                </div>
              </div>
            </>
          )}
          {laodingScreen && (
            <div
              className={`loading-screen ${
                laodingScreen ? "" : "hiddenLoadingScreen"
              }`}
            >
              <LoadrerTechnoTask />
            </div>
          )}

          {startAmra === true && (
            <>
              <div className="mainlogoAmra">
                <img src={Logo} alt="icon" />
              </div>
              <div>
                <div className="amrascreen_wrapperHead">
                  <h2>Hospital Voice AI Solution</h2>
                </div>
                <div className="amarClient">
                  <div className="amarClientRow row">
                    <div className="amarClientLeft col-md-2 col-3 m-auto">
                      <div className="amarClientLeftImg">
                        {!showSpeaker && <img src={RobotC} alt="icon" />}
                        {showSpeaker && <img src={RobotCV} alt="icon" />}
                      </div>
                    </div>
                    <div className="amarClientCenter col-md-7 col-5 my-auto">
                      <div className="amarClientCenterImg">
                        <video ref={videoRef} controls={false}>
                          <source src={newamraVi} type="video/mp4" />
                        </video>
                      </div>
                    </div>
                    <div className="amarClientRight col-md-2 col-3 m-auto">
                      <div className="amarClientRightImg">
                        {!showMic && <img src={SteveB} alt="icon" />}
                        {showMic && <img src={SteveBV} alt="icon" />}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bottomAmraText">
                  <h5>{transcript}</h5>
                </div>
                <div hidden>
                  <audio ref={audioRef}>
                    <source type="audio/mpeg" />
                    Your browser does not support the audio element.
                  </audio>
                  <div hidden>{startSpeechRecognition(finalTranscript)}</div>
                </div>
              </div>
            </>
          )}
        </div>

        {/**popup screen */}
        <div
          className="modal fade"
          id="helpcenter"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="helpcenter"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <form id="QRFaqFormData">
                <div className="modal-header modal-header2">
                  <h5 className="modal-title" id="exampleModalLongTitle">
                    Enter Your Mobile Number
                  </h5>
                  <button
                    type="button"
                    className="close back_tra"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true" className="font_size_30">
                      &times;
                    </span>
                  </button>
                </div>

                <div className="qrsrceen ">
                  <div className="comapnyLogo1 col-xl-6 col-md-6 col-12 mx-auto">
                    <img src={LogoCom} alt="technotask" />
                  </div>
                  <div className="fillInfoHead fillInfoHead2 col-md-8 col-sm-5">
                    <h6>Please fill the Mobile Number below</h6>
                  </div>
                  <div className="QRFormDiv QRFaqDiv my-4 p-0">
                    <div className="QRInputS">
                      <div className="QRInput QRInputFAQ">
                        <input
                          type="text"
                          id="token_no"
                          name="token_no"
                          placeholder="Token Number"
                          value={tokenno}
                          onChange={(e) => settokenno(e.target.value)}
                          className="trio_mendate"
                          onInput={(e) => {
                            e.target.value = e.target.value.replace(/\D/g, ""); // Allow only digits
                            // e.target.value = e.target.value.substring(0, 10); // Limit to 4 digits
                          }}
                        />
                      </div>
                    </div>

                    <div className="SaveBtn">
                      <button type="button" onClick={handleSaveUserData}>
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/**popup screen */}
      </div>
    </div>
  );
}

export default FaridaScreen;
