import $ from "jquery";

const validateEmail = (email) => {
  const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
  return emailRegex.test(email);
};

const validateName = (name) => {
  const nameRegex = /^[A-Za-z\s]+$/;
  return nameRegex.test(name);
};

const validateMobile = (mobile) => {
  const mobileRegex = /^[0-9]{10}$/;
  return mobileRegex.test(mobile);
};

const validateZip = (zip) => {
  const mobileRegex = /^\d{5}(?:-\d{4})?$/;
  return mobileRegex.test(zip);
};

const check_vaild_save = (class_name) => {
  var isValid = true;

  $("#" + class_name + " .trio_mendate").each(function () {
    let trioEmailElement = $(this).attr("class").includes("trio_email");
    let trioMobnolElement = $(this).attr("class").includes("trio_no");
    let triopasswordlElement = $(this).attr("class").includes("trio_password");
    let triocountlElement = $(this).attr("class").includes("trio_count");
    let trioNamelElement = $(this).attr("class").includes("trio_name");
    let trioZiplElement = $(this).attr("class").includes("trio_zip");
    $(this).css({
      border: "",
      background: "",
    });
    $(this).nextAll(".condition_error:first").hide();
    if ($(this).val() === "" || $(this).val() === null) {
      isValid = false;
      $(this).css({
        border: "1px solid red",
        width: "50px !important",
      });
      $(this)
        .nextAll(".condition_error:first")
        .html("Please Fill The Mandatory Information")
        .show();
    } else {
      if (trioEmailElement) {
        let emailid = $(this).val();
        if (!validateEmail(emailid)) {
          $(this).css({
            border: "1px solid red",
            width: "50px !important",
          });
          isValid = false;
          $(this)
            .nextAll(".condition_error:first")
            .html("Please Fill Valid Email Id")
            .show();
        }
      }
      if (trioZiplElement) {
        let zipcode = $(this).val();
        if (!validateZip(zipcode)) {
          $(this).css({
            border: "1px solid red",
            width: "50px !important",
          });
          isValid = false;
          $(this)
            .nextAll(".condition_error:first")
            .html("Please Enter Valid Zip Code")
            .show();
        }
      }
      if (trioNamelElement) {
        let name = $(this).val();
        if (!validateName(name)) {
          isValid = false;
          $(this).css({
            border: "1px solid red",
            width: "50px !important",
          });
          $(this)
            .nextAll(".condition_error:first")
            .html("Please Fill Valid Name")
            .show();
        }
      }
      if (trioMobnolElement) {
        let mob_no = $(this).val();
        if (!validateMobile(mob_no)) {
          isValid = false;
          $(this).css({
            border: "1px solid red",
            width: "50px !important",
          });
          $(this)
            .nextAll(".condition_error:first")
            .html("Please Enter Valid Mobile No")
            .show();
        }
      }
      if (triopasswordlElement) {
        let pass_no = $(this).val();

        if (pass_no.length < Number(8)) {
          $(this).css({
            border: "1px solid red",
            width: "50px !important",
          });
          $(this)
            .nextAll(".condition_error:first")
            .html("Please Enter Valid Password")
            .show();
        }
      }
      if (triocountlElement) {
        let count_no = $(this).val();

        if (Number(count_no) === 0) {
          alert("Please Add Items");
        }
      }
    }
  });

  return isValid;
};

const combiled_form_data = (form_name, dynaicimage) => {
  const fd = new FormData(document.getElementById(form_name));
  for (const [key, value] of fd.entries()) {
    if (value.type && dynaicimage != null) {
      fd.append(key + "_new", dynaicimage[key]);
    }
  }
  return fd;
};

const fnExcelReport = () => {
  var tab_text = "<table border='1px'><tr bgcolor='#ffffff'>";
  var j = 0;
  var tab = document.getElementById("dynamic-table1"); // id of table
  if (tab.rows.length > 1) {
    for (j = 0; j < tab.rows.length; j++) {
      if (!tab.rows[j].innerHTML.includes("table-detail")) {
        tab_text = tab_text + tab.rows[j].innerHTML + "</tr>";
      }
    }
    tab_text = tab_text.replace(/<a[^>]*>|<\/a>/g, ""); // remove if you want links in your table
    tab_text = tab_text.replace(/<img[^>]*>/gi, ""); // remove if you want images in your table
    tab_text = tab_text.replace(/<input[^>]*>|<\/input>/gi, ""); // removes input params
    tab_text = tab_text + "</table>";

    // Create a Blob containing the table data
    var blob = new Blob([tab_text], {
      type: "application/vnd.ms-excel",
    });

    // Create a URL for the Blob
    var url = URL.createObjectURL(blob);

    // Create an anchor element and trigger a download
    var a = document.createElement("a");
    a.href = url;
    a.download = "table_data.xls";
    a.click();
    console.log("ddd");
    // Clean up the URL object to release resources
    URL.revokeObjectURL(url);
  }
};

const handleLinkClick = (link) => {
  // Reload the page when the link is clicked
  window.location.href = link;
};

const handleConfimDeleteClick = () => {
  // Display a confirmation dialog
  const isConfirmed = window.confirm("Are you sure you want to delete?");
  return isConfirmed;
};

const handleIaphabetnumberChange = (e) => {
  e.target.value = e.target.value.replace(/[^A-Za-z0-9\s]/g, "");
};
const handleEmailChange = (e) => {
  if (!/^[a-zA-Z0-9@.]*$/.test(e.target.value)) {
    e.target.value = ""; // Clear the input if it contains any other character
  }
};
const handleAphabetsChange = (e) => {
  e.target.value = e.target.value.replace(/[^A-Za-z\s]/g, "");
};

const handleNumbersChange = (e) => {
  e.target.value = e.target.value.replace(/[^0-9]/g, "");
};

const handleNumbersDecimalChange = (e) => {
  e.target.value = e.target.value.replace(/[^0-9.]/g, ""); // Allow only digits and decimal point
  e.target.value = e.target.value.replace(/(\d{0,9}(?:\.\d{0,2})?).*$/g, "$1"); // Limit to 3 digits before the decimal and 2 digits after
};
const handleURLChange = (e) => {
  const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
  e.target.value = e.target.value.match(urlRegex) ? e.target.value : "";
};

const handleAphabetswithhashChange = (e) => {
  e.target.value = e.target.value.replace(/[^A-Za-z/-]|\/{2,}|-{2,}/g, "");
};

const handleIaphabetnumberkeywordChange = (e) => {
  e.target.value = e.target.value.replace(/[^A-Za-z0-9\s,]/g, "");
};

const handleIaphabetnumbercommaChange = (e) => {
  e.target.value = e.target.value.replace(/[^A-Za-z0-9\s,]/g, "");
};

const handleNumbersRatingChange = (e) => {
  e.target.value = e.target.value.replace(/[^0-9.]/g, ""); // Allow only digits and decimal point
  e.target.value = e.target.value.replace(/^(\d{1,2}(?:\.\d{0,1})?).*$/g, "$1"); // Limit to 2 digits before the decimal and 1 digit after

  // Ensure the value is less than or equal to 5.0
  const ratingValue = parseFloat(e.target.value);
  if (ratingValue > 5.0) {
    e.target.value = "5.0";
  }
};

const handlePasswordChange = (e) => {
  e.target.value = e.target.value.replace(/[^A-Za-z0-9,]/g, "");
};

const computeTodayDate = () => {
  const today = new Date();
  const maxDate = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() + 1
  );
  return maxDate.toISOString().split("T")[0]; // Format as YYYY-MM-DD
};

const computeFutureDate = () => {
  const today = new Date();
  const maxDate = new Date(
    today.getFullYear() + 1,
    today.getMonth(),
    today.getDate() + 1
  );
  return maxDate.toISOString().split("T")[0]; // Format as YYYY-MM-DD
};
const dateformartmonth = (inputDateString) => {
  console.log(inputDateString)
  const inputDate = new Date(inputDateString);

  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  const formattedDate = inputDate.toLocaleDateString('en-US', options);
  console.log(formattedDate);
  return formattedDate; // Format as YYYY-MM-DD
};
const handleDateChange = (e) => {
  e.target.value = e.target.value.replace(/[^0-9-]/g, ""); // Assuming you want to allow only digits and hyphen for date
};

const inputdateformateChange = (input_data) => {
  const inputDate = new Date(input_data);
  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const dayOfWeek = daysOfWeek[inputDate.getDay()];

  const options = { year: "numeric", month: "short", day: "2-digit" };

  const formattedDate = `${dayOfWeek} ${inputDate.toLocaleDateString(
    "en-US",
    options
  )}`;

  return formattedDate;
};

const empty_form = (class_name) => {
  if (class_name !== "form_data_profile") {
    $("#" + class_name + " .trio_mendate").each(function () {
      $(this).val("");
      $(this).css({
        border: "",
        background: "",
      });
    });
  }
};

const validateWebsite = (website) => {
  // Regular expression pattern for validating website URLs
  const websitePattern =
    /^(https?:\/\/)?([a-zA-Z0-9.-]+(\.[a-zA-Z]{2,})+)(\/[^\s]*)?$/;

  // Test the website URL against the pattern
  return websitePattern.test(website);
};
const changelanguage = (laguage_give) => {
  let laguage_send = "en-US";

  if (laguage_give === "en-XA") {
    laguage_send = "en-US";
  } else if (laguage_give === "hi-XA") {
    laguage_send = "hi-IN";
  } else if (laguage_give === "ar-XA") {
    laguage_send = "ar-SA";
  }

  return laguage_send;
};

const getRandomSixLetterString = () => {
  const alphabet = "abcdefghijklmnopqrstuvwxyz";
  let randomString = "";
  for (let i = 0; i < 6; i++) {
    const randomIndex = Math.floor(Math.random() * alphabet.length);
    randomString += alphabet[randomIndex];
  }
  return randomString;
};
const show_popup_thanks = (class_id) => {
  $("#" + class_id).click();
};
export {
  validateWebsite,
  changelanguage,
  show_popup_thanks,
  empty_form,
  check_vaild_save,
  combiled_form_data,
  getRandomSixLetterString,
  handleLinkClick,
  handleIaphabetnumberChange,
  handleEmailChange,
  handleAphabetsChange,
  handleNumbersChange,
  handleNumbersDecimalChange,
  handleURLChange,
  fnExcelReport,
  handleConfimDeleteClick,
  handleAphabetswithhashChange,
  handleIaphabetnumberkeywordChange,
  handleIaphabetnumbercommaChange,
  handleNumbersRatingChange,
  computeTodayDate,
  handlePasswordChange,
  handleDateChange,
  computeFutureDate,
  inputdateformateChange,
  dateformartmonth,
};
