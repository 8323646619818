import React, { useState, useEffect } from "react";
import "../Css/AmraDashboard.css";
import dummyPDF from "../../assets/images/dummy.pdf";
import {
  URL_FOR_CAREER_SAVE,
  server_get_data_master,
} from "../../KeyConnection/KeysService"; // Adjust the path as needed
import SideBar2 from "./Sidebar2";
import NotificationsImg from "../../assets/images/notification.svg";
import SideBarControl from "../../assets/images/rightLeftSIdebar.png";
import UserImgPrf from "../../assets/images/userimg45.svg";
import adm_img from "../../assets/images/adm_img.png";
import CenterNavbar from "./CenterNavbar";
import { Helmet } from "react-helmet";
function CareerView() {
  const [CareerAppl, setCareerAppl] = useState([]);
  const [selecteddata, setselecteddataa] = useState([]);
  const [showLoaderWebsite, setshowLoaderWebsite] = useState(false);

  const getUserData = async (url_for) => {
    const fd = new FormData();
    await server_get_data_master(url_for, fd)
      .then((response) => response.json())
      .then((data) => {
        setshowLoaderWebsite(false);
        console.log(data);
        if (data.status === "error") {
          alert(data.data);
        } else {
          setCareerAppl(data.data);
        }
      })
      .catch((error) => {
        console.log("asdsa");
        setshowLoaderWebsite(false);
      });
  };

  useEffect(() => {
    getUserData(URL_FOR_CAREER_SAVE);
  }, []);

  const handleGetId = (index) => {
    console.log(index);
    setselecteddataa([CareerAppl[index]]);
  };

  return (
    <div className="dashboard">
      <Helmet>
        <title>Amra User Dashboard</title>
        <meta
          name="description"
          content="AI and Global Reach: The Key to Scalable Solutions in Customer
          Experience"
        />
      </Helmet>
      <div className="dashboard_container">
        <div className="dashboard_wrapper">
          <div className="sidebar_wrapper">
            <SideBar2 />
          </div>
          <div id="content">
            <CenterNavbar />
            
            <div className="AmraConversations">
              <div className={showLoaderWebsite ? "loading_website" : ""}></div>
              <div className="AmraConversations_container">
                <div className="AmraConversations_container_wrapper">
                  <div className="overviewContaINER">
                    <div className="overviewHead">
                      <h4>Career Applications</h4>
                      <div className="AmraConversations_data">
                        <div className="BookdataTable">
                          <div className="BookdataTable_container">
                            <table className="BookdataTable">
                              <thead className="theadStyle">
                                <tr>
                                  <th className="th1" scope="col">
                                    S.No.
                                  </th>
                                  <th scope="col">Name</th>
                                  <th scope="col">Email</th>
                                  <th scope="col">Phone</th>
                                  <th className="th2" scope="col">
                                    Details
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="tboday">
                                {CareerAppl.map((CareerAppl, index) => (
                                  <tr
                                    key={index}
                                    onClick={() => handleGetId(index)}
                                  >
                                    <td className="th1">{index + 1}</td>
                                    <td>{CareerAppl.user_name}</td>
                                    <td>{CareerAppl.user_email}</td>
                                    <td>{CareerAppl.user_no}</td>
                                    <td className="th2">
                                      <button
                                        className="seeMsgBtn"
                                        data-toggle="modal"
                                        data-target="#exampleModalCenter"
                                      >
                                        See Details
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="modal fade"
                id="exampleModalCenter"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered" role="document">
                  <div className="modal-content">
                    <div className="modal-header modalHeader23">
                      <button
                        type="button"
                        className="close modalCloseBtn23"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <div className="contactDetailsDiv">
                        {selecteddata.map((contact, index) => (
                          <div key={index}>
                            <p>
                              <span>Name :</span> {contact.user_name}
                            </p>
                            <p>
                              <span>Email :</span> {contact.user_email}
                            </p>
                            <p>
                              <span>Phone :</span> {contact.user_no}
                            </p>
                            <p>
                              <span>Experience :</span> {contact.user_exp}
                            </p>
                            <p>
                              <span>Company :</span> {contact.user_cmpny}
                            </p>
                            <p>
                              <span>Current CTC :</span> {contact.user_crntctc}
                            </p>
                            <p>
                              <span>Expected CTC :</span>{" "}
                              {contact.user_expctdctc}
                            </p>
                            <p>
                              <span>Notice Period :</span>{" "}
                              {contact.user_noticeperiod}
                            </p>
                            <p>
                              <span>Message :</span> {contact.user_msg}
                            </p>
                            <div>
                              <embed
                                src={dummyPDF}
                                type="application/pdf"
                                width="100%"
                                height="300px"
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CareerView;
