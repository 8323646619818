import React, { useState, useEffect } from 'react';
import LogoCom from "../../assets/images/logoNew.png";
import Watch from "../../assets/images/Watch.png";

function QRWaitPage() {
  const [time, setTime] = useState(30);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (time > 0) {
        setTime(time - 1);
      }
    }, 1000);

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [time]);

  return (
    <div className="qrpage">
      <div className="qr_contaianer">
        <div className="qr_wrapper col-xl-4 col-lg-11 m-auto">
          <div className="qrsrceen">
            <div className="comapnyLogo">
              <img src={LogoCom}  alt="technotask"/>
            </div>
            <div className="scanQR scanQR2 col-sm-11 mx-auto">
              <h4>
                Thank you for Showing Interest in me I am calling you in Next
              </h4>
              <div className="QRCOde col-5 m-auto">
                <img src={Watch}  alt="technotask"/>
              </div>
              <div className="timer30s">
                <p>{time} seconds</p>
              </div>
            </div>
            <div className='tokenNumber'>
                <h6>Your Token Number is</h6>
                <h4>4563</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QRWaitPage;
