import * as THREE from 'three';
import {
  OrbitControls
} from 'three/addons/controls/OrbitControls.js';
import {
  GLTFLoader
} from 'three/addons/loaders/GLTFLoader.js';
import {
  RoomEnvironment
} from 'three/addons/environments/RoomEnvironment.js';
import dompurify from 'dompurify';
import {
  marked
} from 'marked';


class TalkingHead {
  constructor(url, node, opt = null, onsuccess = null, onerror = null) {
    this.nodeAvatar = node;
    this.opt = opt;
    this.audio_link = "";
    // Animation templates for moods
    this.animMoods = {
      'happy': {
        baseline: {
          mouthSmile: 0.3,
          eyesLookDown: 0.1
        },
        speech: {
          deltaRate: 0,
          deltaPitch: 0.1,
          deltaVolume: 0
        },
        anims: [{
            name: 'breathing',
            delay: 1500,
            dt: [1200, 500, 1000],
            vs: {
              chest: [0.5, 0.5, 0]
            }
          },
          {
            name: 'legs',
            delay: [4000, 10000],
            dt: [1000],
            vs: {
              weight: [
                [-2, 2]
              ]
            }
          },
          {
            name: 'hands',
            delay: [500, 10000],
            dt: [2000, 4000],
            vs: {}
          },
          {
            name: 'head',
            dt: [
              [1000, 5000]
            ],
            vs: {
              headRotateX: [
                [-0.04, 0.10]
              ],
              headRotateY: [
                [-0.3, 0.3]
              ],
              headRotateZ: [
                [-0.08, 0.08]
              ]
            }
          },
          {
            name: 'eyes',
            delay: [100, 5000],
            dt: [
              [100, 500],
              [100, 5000, 2]
            ],
            vs: {
              eyesRotateY: [
                [-0.6, 0.6]
              ],
              eyesRotateX: [
                [-0.2, 0.6]
              ]
            }
          },
          {
            name: 'blink',
            delay: [2000, 10000],
            dt: [50, [100, 300], 100],
            vs: {
              eyeBlinkLeft: [1, 1, 0],
              eyeBlinkRight: [1, 1, 0]
            }
          },
          {
            name: 'mouth',
            delay: [1000, 5000],
            dt: [
              [100, 500],
              [100, 5000, 2]
            ],
            vs: {
              mouthLeft: [
                [0, 0.3, 2]
              ],
              mouthSmile: [
                [0, 0.2, 3]
              ],
              mouthRollLower: [
                [0, 0.3, 2]
              ],
              mouthRollUpper: [
                [0, 0.3, 2]
              ],
              mouthStretchLeft: [
                [0, 0.3]
              ],
              mouthStretchRight: [
                [0, 0.3]
              ],
              mouthPucker: [
                [0, 0.3]
              ]
            }
          },
          {
            name: 'misc',
            delay: [100, 5000],
            dt: [
              [100, 500],
              [100, 5000, 2]
            ],
            vs: {
              eyeSquintLeft: [
                [0, 0.3, 3]
              ],
              eyeSquintRight: [
                [0, 0.3, 3]
              ],
              browInnerUp: [
                [0, 0.3]
              ],
              browOuterUpLeft: [
                [0, 0.3]
              ],
              browOuterUpRight: [
                [0, 0.3]
              ]
            }
          }
        ]
      },
    };
    this.mood = this.animMoods["happy"];

    // Baseline/fixed morph targets
    this.animBaseline = {};
    this.animFixed = {};
    // Finnish letters to visemes. And yes, it is this SIMPLE in Finnish, more or less.
    this.visemes = {
      'a': 'aa',
      'e': 'E',
      'i': 'I',
      'o': 'O',
      'u': 'U',
      'y': 'Y',
      'ä': 'aa',
      'ö': 'O',
      'b': 'PP',
      'c': 'SS',
      'd': 'DD',
      'f': 'FF',
      'g': 'kk',
      'h': 'O',
      'j': 'I',
      'k': 'kk',
      'l': 'nn',
      'm': 'PP',
      'n': 'nn',
      'p': 'PP',
      'q': 'kk',
      'r': 'RR',
      's': 'SS',
      't': 'DD',
      'v': 'FF',
      'w': 'FF',
      'x': 'SS',
      'z': 'SS',
      ' ': 'sil',
      ',': 'sil',
      '-': 'sil'
    };

    // Pauses in relative units to visemes
    this.pauses = {
      ',': 3,
      '-': 0.5
    };

    // Animation queue
    this.animQueue = [];

    // Animation effects
    this.easing = this.sigmoidFactory(5); // Ease in and out

    // Setup Google text-to-speech
    this.ttsAudio = new Audio();
    this.ttsAudio.oncanplaythrough = this.ttsOnCanPlayThrough.bind(this);
    this.ttsAudio.ontimeupdate = this.ttsOnTimeUpdate.bind(this)
    this.ttsAudio.onended = this.ttsOnEnd.bind(this);
    this.ttsAudio.onerror = this.ttsOnError.bind(this);
    // Initialize a variable to store the result
    this.ttsOnEndResult = null;


    this.ttsQueue = [];
    this.ttsSpeaking = false;

    // Setup 3D Animation
    this.renderer = new THREE.WebGLRenderer({
      antialias: true,
      alpha: true
    });
    this.renderer.setPixelRatio(this.opt.modelPixelRatio * window.devicePixelRatio);
    this.renderer.setSize(this.nodeAvatar.clientWidth, this.nodeAvatar.clientHeight);
    this.renderer.outputColorSpace = THREE.SRGBColorSpace;
    this.renderer.outputEncoding = THREE.sRGBEncoding;
    this.renderer.toneMapping = THREE.ACESFilmicToneMapping;
    this.renderer.useLegacyLights = false;
    this.renderer.shadowMap.enabled = false;
    this.nodeAvatar.appendChild(this.renderer.domElement);
    this.camera = new THREE.PerspectiveCamera(18, 1, 1, 2000);
    this.scene = new THREE.Scene();
    const pmremGenerator = new THREE.PMREMGenerator(this.renderer);
    pmremGenerator.compileEquirectangularShader();
    this.scene.environment = pmremGenerator.fromScene(new RoomEnvironment()).texture;
    // Add a point light
    const pointLight = new THREE.PointLight(0xffffff, 1);
    pointLight.position.set(100, 100, 100); // Adjust the position as needed
    this.scene.add(pointLight);

    // Add a spotlight
    const spotLight = new THREE.SpotLight(0xffffff, 1);
    spotLight.position.set(200, 200, 10); // Adjust the position as needed
    this.scene.add(spotLight);

    new ResizeObserver(this.onResize.bind(this)).observe(this.nodeAvatar);
    this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    this.controls.enableZoom = this.opt.cameraZoomEnable;
    this.controls.enableRotate = this.opt.cameraRotateEnable;
    this.controls.enablePan = this.opt.cameraPanEnable;
    this.controls.minDistance = 2;
    this.controls.maxDistance = 2000;
    this.controls.update();

    // Load 3D Avatar
    this.loadModel(url, onsuccess, onerror);
  }

  /**
   * Clear 3D object.
   * @param {Object} obj Object
   */
  clearThree(obj) {
    while (obj.children.length) {
      this.clearThree(obj.children[0]);
      obj.remove(obj.children[0]);
    }
    if (obj.geometry) obj.geometry.dispose();

    if (obj.material) {
      Object.keys(obj.material).forEach(x => {
        if (obj.material[x] && obj.material[x] !== null && typeof obj.material[x].dispose === 'function') {
          obj.material[x].dispose();
        }
      });
      obj.material.dispose();
    }
  }


  loadModel(url, onsuccess = null, onerror = null) {

    this.stopAnimation();
    const loader = new GLTFLoader();
    loader.load(url, (gltf) => {

        // Clear previous scene, if avatar was previously loaded
        if (this.avatar) {
          this.clearThree(this.scene);
        }

        function notfound(x) {
          const msg = 'Avatar ' + x + ' not found';
          console.error(msg);
          if (onerror && typeof onerror === 'function') onerror(msg);
          throw new Error(msg);
        }

        // Avatar full-body
        this.avatar = gltf.scene.getObjectByName("Armature"); // Full-body
        if (!this.avatar) notfound("Armature");

        // Rig map
        this.rig = {
          hips: 'Hips',
          spine: 'Spine',
          chest: 'Spine1',
          upperChest: 'Spine2',
          neck: 'Neck',
          head: 'Head',
          leftShoulder: 'LeftShoulder',
          leftUpperArm: 'LeftArm',
          leftLowerArm: 'LeftForeArm',
          leftHand: 'LeftHand',
          rightShoulder: 'RightShoulder',
          rightUpperArm: 'RightArm',
          rightLowerArm: 'RightForeArm',
          rightHand: 'RightHand',

        };
        Object.keys(this.rig).map(x => {
          let name = this.rig[x];
          let y = this.avatar.getObjectByName(name);
          if (!y) notfound(name);
          this.rig[x] = y;
        });

        // Morphs
        this.morphs = ['EyeLeft', 'EyeRight', 'Wolf3D_Head', 'Wolf3D_Teeth'].map(x => {
          let y = this.avatar.getObjectByName(x);
          if (!y) notfound(x);
          return y;
        });

        // Add avatar to scene
        this.scene.add(this.avatar);


        // Add lights
        let hemiLight = new THREE.HemisphereLight(0xffffff, 0xffffff, 3.5);
        hemiLight.position.set(0, 50, 0);
        // Add hemisphere light to scene
        this.scene.add(hemiLight);



        // Set pose
        this.rig.leftUpperArm.rotation.set(1.2, 0, 0);
        this.rig.rightUpperArm.rotation.set(1.2, 0, 0);
        this.rig.leftLowerArm.rotation.set(0.06, 0, 0.2);
        this.rig.rightLowerArm.rotation.set(0.06, 0, -0.2);
        this.setValue('headRotateX', 0);
        this.setValue('headRotateY', 0);
        this.setValue('headRotateZ', 0);
        this.setValue('chest', 0);
        this.setValue('weight', 0);
        this.setMood(this.opt.avatarMood);


        // Fit avatar to screen
        this.setView(this.opt.cameraView);

        // Start animations
        this.startAnimation();

        // Callback
        if (onsuccess && typeof onsuccess === 'function') onsuccess();
      },
      null,
      (msg) => {
        console.error(msg);
        if (onerror && typeof onerror === 'function') onerror(msg);
        throw new Error(msg);
      });
  }

  setMood(s) {
    s = (s || '').trim().toLowerCase();
    if (!this.animMoods.hasOwnProperty(s)) throw new Error("Unknown mood.");
    this.opt.avatarMood = s;
    this.mood = this.animMoods[s];

    // Reset morph target baseline to 0
    for (let mt of Object.keys(this.morphs[0].morphTargetDictionary)) {
      this.setBaselineValue(mt, this.mood.baseline.hasOwnProperty(mt) ? this.mood.baseline[mt] : 0);
    }

    // Set/replace animations
    this.mood.anims.forEach(x => {
      let o = this.animQueue.find(y => y.template.name === x.name);
      if (o) {
        o.template = x;
      } else {
        this.animQueue.push(this.animFactory(x, -1));
      }
    });

  }

  setBaselineValue(mt, v) {
    if (mt === 'eyesRotateY') {
      this.setBaselineValue('eyeLookOutLeft', (v === null) ? null : (v > 0 ? v : 0));
      this.setBaselineValue('eyeLookInLeft', (v === null) ? null : (v > 0 ? 0 : -v));
      this.setBaselineValue('eyeLookOutRight', (v === null) ? null : (v > 0 ? 0 : -v));
      this.setBaselineValue('eyeLookInRight', (v === null) ? null : (v > 0 ? v : 0));
    } else if (mt === 'eyesRotateX') {
      this.setBaselineValue('eyesLookDown', (v === null) ? null : (v > 0 ? v : 0));
      this.setBaselineValue('eyesLookUp', (v === null) ? null : (v > 0 ? 0 : -v));
    }
    if (mt === 'eyeLookOutLeft' || mt === 'eyeLookInLeft' ||
      mt === 'eyeLookOutRight' || mt === 'eyeLookInRight' ||
      mt === 'eyesLookDown' || mt === 'eyesLookUp') {
      // skip these
    } else {
      if (this.morphs[0].morphTargetDictionary.hasOwnProperty(mt)) {
        if (v === null) {
          if (this.animBaseline.hasOwnProperty(mt)) {
            delete this.animBaseline[mt];
          }
        } else {
          this.animBaseline[mt] = {
            target: v
          };
        }
      }
    }
  }


  setView(view, opt = null) {
    if (!this.avatar) return;

    opt = opt || {}
    Object.assign(opt, this.opt);

    // Camera position
    const boundingBox = new THREE.Box3();
    boundingBox.setFromObject(this.avatar);
    var size = new THREE.Vector3();
    boundingBox.getSize(size);
    const fov = this.camera.fov * (Math.PI / 180);
    let x, y, z;
    let distance = 0.7 * (size.z / 2 + Math.abs(size.x / 2 / Math.tan(fov / 2)));
    let tx = 1,
      ty = 1,
      tz = 1;
    if (view === 'closeup') {
      x = -opt.cameraX;
      y = 1.6 - opt.cameraY;
      z = opt.cameraZ + distance;
      tz = 0;
    } else if (view === 'right') {
      x = -opt.cameraZ - distance;
      y = 1.6 - opt.cameraY;
      z = -opt.cameraX + 0.15;
      tx = 0;
    } else if (view === 'left') {
      x = opt.cameraZ + distance;
      y = 1.6 - opt.cameraY;
      z = opt.cameraX + 0.05;
      tx = 0;
    } else if (view === 'fullbody') {
      x = -(3.2 * opt.cameraX);
      y = 1 - opt.cameraY;
      z = 7.7 + opt.cameraZ + distance;
      tz = 0;
    }
    this.controls.reset();
    this.camera.position.set(x, y, z);
    this.controls.target.set(tx * x, ty * y, tz * z);
    this.controls.update();
    this.render();
  }


  render() {
    this.renderer.render(this.scene, this.camera);
  }

  onResize() {
    this.camera.aspect = this.nodeAvatar.clientWidth / this.nodeAvatar.clientHeight;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(this.nodeAvatar.clientWidth, this.nodeAvatar.clientHeight);
    this.controls.update();
    this.render();
  }


  getValue(mt) {
    if (mt === 'headRotateX') {
      return this.rig.head.rotation.x + 0.15;
    } else if (mt === 'headRotateY') {
      return this.rig.head.rotation.y;
    } else if (mt === 'headRotateZ') {
      return this.rig.head.rotation.z;
    } else if (mt === 'chest') {
      return (this.rig.chest.scale.x - 1) * 30;
    } else if (mt === 'weight') {
      return 12 * this.avatar.rotation.y;
    } else {
      return this.morphs[0].morphTargetInfluences[this.morphs[0].morphTargetDictionary[mt]];
    }
  }


  setValue(mt, v) {
    if (mt === 'headRotateX') {
      this.rig.head.rotation.x = v - 0.15;
      this.rig.chest.rotation.x = v / 2;
      this.rig.spine.rotation.x = v / 12;
      this.rig.hips.rotation.x = v / 32 - 0.06;
    } else if (mt === 'headRotateY') {
      let w = this.getValue('weight');
      this.rig.head.rotation.y = v;
      this.rig.chest.rotation.y = v / 2;
      this.rig.spine.rotation.y = v / 2;
      this.rig.hips.rotation.y = v / 4 + w / 12;
    } else if (mt === 'headRotateZ') {
      let w = this.getValue('weight');
      this.rig.head.rotation.z = v;
      this.rig.chest.rotation.z = v / 12;
      this.rig.hips.rotation.z = v / 24 + w / 18;
      this.rig.spine.rotation.z = v / 12 - w / 12;
    } else if (mt === 'chest') {
      const scale = v / 30;
      this.rig.chest.scale.set(1 + scale, 1 + scale / 2, 1 + 4 * scale);
      this.rig.head.scale.set(1 / (1 + scale), 1 / (1 + scale / 2), 1 / (1 + 4 * scale));
    } else if (mt === 'weight') {
      // Limit
      let w = Math.max(-1, Math.min(1, v));

      // Body
      let headRotateY = this.getValue('headRotateY');
      let headRotateZ = this.getValue('headRotateZ');
      this.avatar.position.y = -(w * w) / 128;
      this.avatar.rotation.x = -0.02;
      this.avatar.rotation.y = w / 12;
      this.avatar.rotation.z = 0;

      this.rig.hips.position.x = w / 64;
      this.rig.hips.rotation.y = headRotateY / 4 + w / 12;
      this.rig.hips.rotation.z = headRotateZ / 24 + w / 18;
      this.rig.spine.rotation.z = headRotateZ / 12 - w / 12;
    } else {
      this.morphs.forEach(x => x.morphTargetInfluences[x.morphTargetDictionary[mt]] = v);
    }
  }



  getMorphTargetNames() {
    return [
      'headRotateX', 'headRotateY', 'headRotateZ',
      'eyesRotateX', 'eyesRotateY', 'chest', 'weight',
      ...Object.keys(this.morphs[0].morphTargetDictionary)
    ].sort();
  }


  getBaselineValue(mt) {
    if (mt === 'eyesRotateY') {
      const ll = this.getBaselineValue('eyeLookOutLeft');
      if (ll === undefined) return undefined;
      const lr = this.getBaselineValue('eyeLookInLeft');
      if (lr === undefined) return undefined;
      const rl = this.getBaselineValue('eyeLookOurRight');
      if (rl === undefined) return undefined;
      const rr = this.getBaselineValue('eyeLookInRight');
      if (rr === undefined) return undefined;
      return ll - lr;
    } else if (mt === 'eyesRotateX') {
      const d = this.getBaselineValue('eyesLookDown');
      if (d === undefined) return undefined;
      const u = this.getBaselineValue('eyeLookUp');
      if (u === undefined) return undefined;
      return d - u;
    } else {
      return (this.animBaseline.hasOwnProperty(mt) ? this.animBaseline[mt].target : undefined);
    }
  }


  animFactory(t, loop = false, scaleTime = 1, scaleValue = 1) {
    const o = {
      template: t,
      ts: [0],
      vs: {}
    };

    // Time series
    const delay = t.delay ? (Array.isArray(t.delay) ? this.gaussianRandom(t.delay[0], t.delay[1], t.delay[2]) : t.delay) : 0;
    t.dt.forEach((x, i) => {
      o.ts[i + 1] = o.ts[i] + (Array.isArray(x) ? this.gaussianRandom(x[0], x[1], x[2]) : x);
    });
    o.ts = o.ts.map(x => performance.now() + delay + x * scaleTime);

    // Values
    for (let [mt, vs] of Object.entries(t.vs)) {
      const base = this.getBaselineValue(mt);
      const v = vs.map(x => (base === undefined ? 0 : base) + scaleValue * (Array.isArray(x) ? this.gaussianRandom(x[0], x[1], x[2]) : x));

      if (mt === 'eyesRotateY') {
        o.vs['eyeLookOutLeft'] = [null, ...v.map(x => (x > 0) ? x : 0)];
        o.vs['eyeLookInLeft'] = [null, ...v.map(x => (x > 0) ? 0 : -x)];
        o.vs['eyeLookOutRight'] = [null, ...v.map(x => (x > 0) ? 0 : -x)];
        o.vs['eyeLookInRight'] = [null, ...v.map(x => (x > 0) ? x : 0)];
      } else if (mt === 'eyesRotateX') {
        o.vs['eyesLookDown'] = [null, ...v.map(x => (x > 0) ? x : 0)];
        o.vs['eyesLookUp'] = [null, ...v.map(x => (x > 0) ? 0 : -x)];
      } else {
        o.vs[mt] = [null, ...v];
      }
    }
    for (let mt of Object.keys(o.vs)) {
      while ((o.vs[mt].length - 1) < o.ts.length) o.vs[mt].push(o.vs[mt][o.vs[mt].length - 1]);
    }

    // Mood
    if (t.hasOwnProperty("mood")) o.mood = t.mood.slice();

    if (loop) o.loop = loop;
    return o;
  }

  valueAnimationSeq(ts, vs, t, fun = null) {
    let iMin = 0;
    let iMax = ts.length - 1;
    if (t <= ts[iMin]) return vs[iMin];
    if (t >= ts[iMax]) return vs[iMax];
    while (t > ts[iMin + 1]) iMin++;
    iMax = iMin + 1;
    let k = (vs[iMax] - vs[iMin]) / (ts[iMax] - ts[iMin]);
    if (fun) k = fun((t - ts[iMin]) / (ts[iMax] - ts[iMin])) * k;
    const b = vs[iMin] - (k * ts[iMin]);
    return (k * t + b);
  }


  gaussianRandom(start, end, skew = 1) {
    let r = 0;
    for (let i = 0; i < 5; i++) r += Math.random();
    return start + Math.pow(r / 5, skew) * (end - start);
  }

  sigmoidFactory(k) {
    function base(t) {
      return (1 / (1 + Math.exp(-k * t))) - 0.5;
    }
    var corr = 0.5 / base(1);
    return function (t) {
      return corr * base(2 * Math.max(Math.min(t, 1), 0) - 1) + 0.5;
    };
  }

  convertRange(value, r1, r2) {
    return (value - r1[0]) * (r2[1] - r2[0]) / (r1[1] - r1[0]) + r2[0];
  }

  /**
   * Animate the avatar.
   * @param {number} t High precision timestamp in ms.
   */
  animate(t) {
    const o = {};

    // Start from baseline
    for (let [mt, x] of Object.entries(this.animBaseline)) {
      const v = this.getValue(mt);
      if (v !== x.target) {
        if (x.t0 === undefined) {
          x.t0 = performance.now();
          x.v0 = v;
        }
        o[mt] = this.valueAnimationSeq([x.t0, x.t0 + 1000], [x.v0, x.target], t, this.easing);
      } else {
        x.t0 = undefined;
      }
    }

    // Animations
    for (let i = 0; i < this.animQueue.length; i++) {
      const x = this.animQueue[i];
      if (t >= x.ts[0]) {
        for (let [mt, vs] of Object.entries(x.vs)) {
          if (mt === 'subtitles') {
            o[mt] = (x.isFirst ? '\n\n' : '') + (o.hasOwnProperty(mt) ? o[mt] + vs : vs);
            delete x.vs[mt];
          } else {
            if (vs[0] === null) vs[0] = this.getValue(mt);
            o[mt] = this.valueAnimationSeq(x.ts, vs, t, this.easing);
            if (this.animBaseline.hasOwnProperty(mt)) this.animBaseline[mt].t0 = undefined;
            for (let j = 0; j < i; j++) {
              if (this.animQueue[j].vs.hasOwnProperty(mt)) delete this.animQueue[j].vs[mt];
            }
          }
        }
        if (t >= x.ts[x.ts.length - 1]) {
          if (x.loop) {
            let restrain = (this.ttsSpeaking && (x.template.name === 'head' || x.template.name === 'eyes')) ? 4 : 1;
            this.animQueue[i] = this.animFactory(x.template, (x.loop > 0 ? x.loop - 1 : x.loop), 1, 1 / restrain);
          } else {
            this.animQueue.splice(i--, 1);
          }
        }
      }
    }

    // Set fixed
    for (let [mt, x] of Object.entries(this.animFixed)) {
      const v = this.getValue(mt);
      if (v !== x.target) {
        if (x.t0 === undefined) {
          x.t0 = performance.now();
          x.v0 = v;
        }
        o[mt] = this.valueAnimationSeq([x.t0, x.t0 + 1000], [x.v0, x.target], t, this.easing);
      } else {
        if (o.hasOwnProperty(mt)) delete o[mt];
        x.t0 = undefined;
      }
      if (this.animBaseline.hasOwnProperty(mt)) this.animBaseline[mt].t0 = undefined;
    }

    // Update values
    let changed = false;
    for (let [mt, target] of Object.entries(o)) {
      if (mt === 'subtitles') {
        let last = this.nodeSubtitles.lastElementChild;
        target.split('\n\n').forEach((p, i) => {
          if (p.length) {
            if (i > 0 || !last) {
              last = this.nodeSubtitles.appendChild(document.createElement('p'));
              last.dataset.markdown = '';
            }
            let markdown = last.dataset.markdown + p;
            last.outerHTML = dompurify.sanitize(marked.parse(markdown, this.opt.markedOptions));
            last = this.nodeSubtitles.lastElementChild;
            last.dataset.markdown = markdown;

          }
        });
      } else {
        this.setValue(mt, target);
        changed = true;
      }
    }
    if (changed) this.render();

    if (this.running) requestAnimationFrame(this.animate.bind(this));
  }

  /**
   * Reset all the visemes for lips.
   */
  resetLips() {
    Object.values(this.visemes).forEach(x => {
      this.morphs.forEach(y => y.morphTargetInfluences[y.morphTargetDictionary['viseme_' + x]]);
    });
  }


  ttsOnCanPlayThrough(event) {
    try {
      // Durations
      let d = 1000 * this.ttsAudio.duration; // Duration in ms
      if (d > 200) d = d - 200;
      const lastElement = this.ttsAudio.anim[this.ttsAudio.anim.length - 1];
      let t = lastElement.ts[lastElement.ts.length - 1] + 1;

      // Rescale and push to queue
      this.ttsAudio.anim.forEach(x => {
        for (let i = 0; i < x.ts.length; i++) {
          x.ts[i] = performance.now() + (x.ts[i] * d / t);
        }
        this.animQueue.push(x);
      });

      // Play
      this.ttsAudio.play();
    } catch {
      //err
    }

  }

  ttsOnEnd(event) {
    this.ttsSpeaking = false;
  }
  ttsOnTimeUpdate(event) {
    ///
  }
  ttsOnError(event) {
    console.log(event);
    this.ttsSpeaking = false;
    this.startSpeaking();
  }

  /**
   * Convert the number string into Finnish words.
   * @param {string} x Number string
   * @return {string} The number in words in Finnish
   */
  numberToWords(x) {
    const w = []; // Initialize an array to store the words.
    let n = parseFloat(x); // Parse the input number as a float
    if (isNaN(n)) return x; // If the input is not a valid number, return it as is.
    const dg = ['zero', 'one', 'two', 'three', 'four', 'five', 'six',
      'seven', 'eight', 'nine', "ten", "eleven", "twelve",
      "thirteen", "fourteen", "fifteen", "sixteen", "seventeen",
      'eighteen', 'nineteen'
    ]; // Array to represent digits 0-19.
    // Helper function to handle different units (e.g., million, thousand, etc.)

    let p = (n, z, w0, w1, w2) => {
      if (n < z) return n; // If the number is smaller than the unit, return it.
      const d = Math.floor(n / z); // Calculate the number of units.
      w.push(w0 + ((d === 1) ? w1 : this.numberToWords(d.toString()) + w2)); // Add words to the result array.
      return n - d * z; // Subtract the units from the number.
    }

    if (n < 0) {
      w.push('minus '); // If the number is negative, add 'minus' to the result.
      n = Math.abs(n); // Make the number positive for further processing.
    }
    // Process different units (billion, million, thousand, hundred)
    n = p(n, 1000000000, ' ', 'billion', ' billion');
    n = p(n, 1000000, ' ', 'million', ' million');
    n = p(n, 1000, '', 'thousand', ' thousand');
    n = p(n, 100, ' ', 'hundred', ' hundred');

    if (n > 20) n = p(n, 10, '', '', 'ty'); // Handle tens if greater than 20.
    if (n >= 1) {
      let d = Math.floor(n);
      w.push(dg[d]); // Add words for the ones digit.
      n -= d; // Subtract the ones digit.
    }

    if (n >= 0 && parseFloat(x) < 1) w.push('zero'); // Add 'zero' for fractions less than 1.

    if (n > 0) {
      let d = (n % 1).toFixed(1) * 10;
      if (d > 0) w.push(' point ' + dg[d]); // Add words for decimal places if they exist.
    }
    return w.join('').trim(); // Join the words and trim any leading/trailing spaces.
  }



  /**
   * Filter text to include only speech and emojis.
   * @param {string} s String
   */
  speechFilter(s) {
    return s.replace(/[#_*'":;]/g, '') // Remove special characters.
      .replaceAll('%', ' percent ') // Replace '%' with 'percent'.
      .replaceAll('€', ' euros ') // Replace '€' with 'euros'.
      .replaceAll('&', ' and ') // Replace '&' with 'and'.
      .replaceAll('+', ' plus ') // Replace '+' with 'plus'.
      .replace(/(\D)\1\1+/g, "$1$1") // Reduce repeating characters to a maximum of two.
      .replaceAll('  ', ' ') // Replace double spaces with a single space.
      .replace(/(\d)\,(\d)/g, '$1 comma $2') // Replace number separators with 'comma'.
      .replace(/\d+/g, this.numberToWords.bind(this)) // Convert numbers to words.
      .replaceAll('$', ' dollars ') // Replace '$' with 'dollars'.
      .replaceAll('د.إ', ' dirhams ') // Replace Dubai currency symbol with 'dirhams'.
      .replaceAll('AED', ' dirhams ') // Replace Dubai currency code with 'dirhams'.
      .replaceAll('₹', ' Rupees ') // Replace '₹' with 'Rupees' for Indian Rupee symbol.
      .replaceAll('INR', ' Rupees ') // Replace 'INR' with 'Indian Rupees' for currency code.
      .trim(); // Trim leading and trailing spaces.


  }


  speak(s, opt = null, audio_file_final) {
    opt = opt || {};
    this.audio_link = audio_file_final;

    // Classifiers
    const dividersSentence = /[!.\?\n\p{Extended_Pictographic}]/ug;
    const dividersWord = /[ !.\?\n\p{Extended_Pictographic}]/ug;
    const speakables = /[\p{L}\p{N},]/ug;




    let t = 0; // time counter
    let markdownWord = ''; // markdown word
    let textWord = ''; // text-to-speech word
    let textSentence = ''; // text-to-speech sentence
    let lipsyncAnim = []; // lip-sync animation sequence
    let isFirst = true; // Text begins
    const letters = [...s];
    for (let i = 0; i < letters.length; i++) {
      const isLast = i === (letters.length - 1);

      // Add letter to spoken word
      if (letters[i].match(speakables)) {
        textWord += letters[i];
      }


      // Add words to sentence and animations
      if (letters[i].match(dividersWord) || isLast) {

        // Add to text-to-speech sentence
        if (textWord.length) {
          textWord = this.speechFilter(textWord);
          textSentence += textWord;
        }

        // Push subtitles to animation queue
        if (markdownWord.length) {
          lipsyncAnim.push({
            template: {
              name: 'subtitles'
            },
            ts: [t - 0.2],
            vs: {
              subtitles: markdownWord
            },
            isFirst: isFirst
          });
          markdownWord = '';
          isFirst = false;
        }

        // Push visemes to animation queue
        if (textWord.length) {
          const chars = [...textWord];
          for (let j = 0; j < chars.length; j++) {
            const viseme = this.visemes[chars[j].toLowerCase()];
            if (viseme) {
              lipsyncAnim.push({
                template: {
                  name: 'viseme'
                },
                ts: [t - 0.5, t + 0.5, t + 1.5],
                vs: {
                  ['viseme_' + viseme]: [null, (viseme === 'PP' || viseme === 'FF') ? 1 : 0.6, 0]
                }
              });
              t += this.pauses[chars[j]] || 1;
            }
          }
          textWord = ' ';
        }
      }

      // Process sentences
      if (letters[i].match(dividersSentence) || isLast) {

        // Send sentence to Text-to-speech queue
        textSentence = textSentence.trim();
        if (textSentence.length || (isLast && lipsyncAnim.length)) {
          const o = {
            anim: lipsyncAnim
          };
          if (opt.avatarMood) o.mood = opt.avatarMood;
          if (!opt.avatarMute) o.text = textSentence;
          if (opt.ttsRate) o.rate = opt.ttsRate;
          if (opt.ttsPitch) o.pitch = opt.ttsPitch;
          this.ttsQueue.push(o);
          // Reset sentence and animation sequence
          textSentence = '';
          lipsyncAnim = [];
          t = 0;
        }
        this.ttsQueue.push({
          break: 300
        });
      }

    }

    this.ttsQueue.push({
      break: 1000
    });

    // Start speaking (if not already)
    this.startSpeaking();
  }

  /**
   * Take the next queue item from the speech queue, convert it to text, and
   * load the audio file.
   * @param {boolean} [force=false] If true, forces to proceed (e.g. after break)
   */
  async startSpeaking(force = false) {
    if (!this.avatar || !this.ttsAudio || (this.ttsSpeaking && !force)) return;
    this.ttsSpeaking = true;
    if (this.ttsQueue.length === 0) {
      this.ttsSpeaking = false;
      return;
    }
    let line = this.ttsQueue.shift();
    if (line.break) {
      // Break
      setTimeout(this.startSpeaking.bind(this), line.break, true);
    } else if (line.text) {
      // Spoken text
      try {
        this.ttsAudio.pause();
        this.ttsAudio.text = line.text;
        this.ttsAudio.anim = line.anim;
        this.ttsAudio.src = this.audio_link;
        this.resetLips();
        this.ttsAudio.load();
      } catch (error) {
        this.ttsSpeaking = false;
        this.startSpeaking();
      }
    } else if (line.anim) {
      // Only subtitles
      this.resetLips();
      line.anim.forEach((x, i) => {
        for (let j = 0; j < x.ts.length; j++) {
          x.ts[j] = performance.now() + 10 * i;
        }
        this.animQueue.push(x);
      });
      setTimeout(this.startSpeaking.bind(this), 10 * line.anim.length, true);
    } else {
      this.ttsSpeaking = false;
      this.startSpeaking();
    }
  }

  /**
   * Pause speaking.
   */
  pauseSpeaking() {
    if (this.ttsAudio) this.ttsAudio.pause();
    this.ttsSpeaking = false;
    this.animQueue = this.animQueue.filter(x => x.template.name !== 'viseme');
    if (this.avatar) {
      this.resetLips();
      this.render();
    }
  }

  /**
   * Stop speaking and clear the speech queue.
   */
  stopSpeaking() {
    if (this.ttsAudio) this.ttsAudio.pause();
    this.ttsQueue.length = 0;
    this.animQueue = this.animQueue.filter(x => x.template.name !== 'viseme');
    this.ttsSpeaking = false;
    if (this.avatar) {
      this.resetLips();
      this.render();
    }
  }

  /**
   * Start animation cycle.
   */
  startAnimation() {
    if (this.avatar && this.running === false) {
      this.running = true;
      requestAnimationFrame(this.animate.bind(this));
    }
  }

  /**
   * Stop animation cycle.
   */
  stopAnimation() {
    this.running = false;
  }

}

export {
  TalkingHead
};